import React from 'react'

// Components
import UIDashboardQuickSight from '../../UI/Dashboard/QuickSight'

const DashboardCummins = () => {
  return <UIDashboardQuickSight id="ec87e58e-3f9e-4d62-afa8-4b36677ff860" />
}

export default DashboardCummins
