// import React from 'react'
// import { Line } from 'react-chartjs-2'
import Chartjs from 'chart.js'

// Primer gráfico
//
// 23 : Viscosidad 40
// 24 : Viscosidad 100
//
// Últimos 10 correlativos

const GraphCentinela = ({ ref, graphs, config, type }) => {
  const [graph, label] = (() => {
    if (!graphs) return [null, null]

    switch (type) {
      case 1:
      case '1':
        return [graphs[5], 'Plomo']
      case 2:
      case '2':
        return [graphs[15], 'Silicio']
      case 3:
      case '3':
        return [graphs[4], 'Cobre ']
      case 4:
      case '4':
        return [graphs[4], 'Cobre ']
      default:
        return [null, null]
    }
  })()

  // --------------------------------------------------------------------------
  //
  // No data
  //
  // --------------------------------------------------------------------------

  if (!graph) {
    new Chartjs(ref.current, {
      type: 'line',
      data: {
        datasets: [
          {
            data: null,
          },
        ],
      },
      options: { ...config.options, legend: { display: false } },
    })

    return
  }

  const { h: minX, y: minY } = graph[0]
  const { h: maxX, y: maxY } = graph[graph.length - 1]

  // --------------------------------------------------------------------------
  //
  // Only 1 graph
  //
  // --------------------------------------------------------------------------

  const { lic, lim, lsm, lsc } = graphs

  const labels =
    graph.length === 1 ? ['', graph[0].h, ''] : graph.map(({ h }) => h)

  new Chartjs(ref.current, {
    type: 'line',
    data: {
      labels,
      datasets: [
        {
          label,
          data: graph.map(({ h, y, label }) => ({
            h,
            y,
            label,
          })),
          ...config.main0,
          showLabelsOverPoints: true,
        },
        {
          label: 'Máximo',
          data: graph.map(({ h }) => ({ h, y: lsc })),
          ...config.lsc,
        },
        {
          label: 'Mínimo',
          data: graph.map(({ h }) => ({ h, y: lsm })),
          ...config.lsm,
        },
      ],
    },
    options:
      graph.length === 1
        ? {
            ...config.options,
            scales: {
              xAxes: [{ ticks: { fontSize: 50 }, padding: 40 }],
              yAxes: [
                {
                  scaleLabel: {
                    display: true,
                  },
                  beginAtZero: false,
                  ticks: {
                    min: Math.min(minY, lic, lim, lsm, lsc) * 0.9,
                    max: Math.max(maxY, lic, lim, lsm, lsc) * 1.1,
                    fontSize: 50,
                    padding: 40,
                  },
                },
              ],
            },
          }
        : config.options,
    plugins: [
      {
        afterDatasetsDraw: function (chart) {
          var ctx = chart.ctx
          chart.data.datasets.forEach(function (dataset, index) {
            var datasetMeta = chart.getDatasetMeta(index)
            if (datasetMeta.hidden) return
            if (!dataset.showLabelsOverPoints) return

            datasetMeta.data.forEach(function (point, index) {
              const label = dataset.data[index].label

              if (!label) return

              var x = point.getCenterPoint().x,
                y = point.getCenterPoint().y,
                radius = point._model.radius,
                fontSize = 25,
                fontFamily = 'Sans',
                fontColor = '#228B22',
                fontStyle = 'bold'

              ctx.save()
              ctx.textBaseline = 'middle'
              ctx.textAlign = 'center'
              ctx.font = fontStyle + ' ' + fontSize + 'px' + ' ' + fontFamily
              ctx.fillStyle = fontColor
              ctx.fillText(label, x, y - radius - fontSize)
              ctx.restore()
            })
          })
        },
      },
    ],
  })
}

const Graph = ({ ref, graphs, config }) => {
  const graph = graphs && (graphs[24] || graphs[23] || null)

  // --------------------------------------------------------------------------
  //
  // No data
  //
  // --------------------------------------------------------------------------

  if (!graph) {
    new Chartjs(ref.current, {
      type: 'line',
      data: {
        datasets: [
          {
            data: null,
          },
        ],
      },
      options: { ...config.options, legend: { display: false } },
    })

    return
  }

  const { x: minX, y: minY } = graph[0]
  const { x: maxX, y: maxY } = graph[graph.length - 1]

  // --------------------------------------------------------------------------
  //
  // Only 1 graph
  //
  // --------------------------------------------------------------------------

  const { lic, lim, lsm, lsc } = graphs

  const labels = graph.length === 1 ? [0, 1, 2] : graph.map(({ x }) => x)

  new Chartjs(ref.current, {
    type: 'line',
    data: {
      labels,
      datasets: [
        {
          label: graph ? (graphs[24] ? 'V 100°C' : 'V 40°C') : '',
          data: graph.map(({ x, y, label }) => ({
            x,
            y,
            label,
          })),
          ...config.main0,
          showLabelsOverPoints: true,
        },
        {
          label: 'Máximo',
          data: [
            { y: lsc, x: graph.length === 1 ? 0 : minX },
            { y: lsc, x: graph.length === 1 ? 2 : maxX },
          ],
          ...config.lsc,
        },
        {
          label: 'Mínimo',
          data: [
            { y: lsm, x: graph.length === 1 ? 0 : minX },
            { y: lsm, x: graph.length === 1 ? 2 : maxX },
          ],
          ...config.lsm,
        },
      ],
    },
    options:
      graph.length === 1
        ? {
            ...config.options,
            scales: {
              xAxes: [{ ticks: { fontSize: 50 }, padding: 40 }],
              yAxes: [
                {
                  scaleLabel: {
                    display: true,
                  },
                  beginAtZero: false,
                  ticks: {
                    min: minY * 0.9,
                    max: maxY * 1.1,
                    fontSize: 50,
                    padding: 40,
                  },
                },
              ],
            },
          }
        : config.options,
    plugins: [
      {
        afterDatasetsDraw: function (chart) {
          var ctx = chart.ctx
          chart.data.datasets.forEach(function (dataset, index) {
            var datasetMeta = chart.getDatasetMeta(index)
            if (datasetMeta.hidden) return
            if (!dataset.showLabelsOverPoints) return

            datasetMeta.data.forEach(function (point, index) {
              const label = dataset.data[index].label

              if (!label) return

              var x = point.getCenterPoint().x,
                y = point.getCenterPoint().y,
                radius = point._model.radius,
                fontSize = 25,
                fontFamily = 'Sans',
                fontColor = '#228B22',
                fontStyle = 'bold'

              ctx.save()
              ctx.textBaseline = 'middle'
              ctx.textAlign = 'center'
              ctx.font = fontStyle + ' ' + fontSize + 'px' + ' ' + fontFamily
              ctx.fillStyle = fontColor
              ctx.fillText(label, x, y - radius - fontSize)
              ctx.restore()
            })
          })
        },
      },
    ],
  })
}

const Graph3 = ({ ref, graphs, config, laboratory, type }) => {
  if (laboratory === 'centinela') {
    GraphCentinela({ ref, graphs, config, type })
  } else {
    Graph({ ref, graphs, config })
  }
}

export default Graph3
