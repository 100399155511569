import { gql } from '../../../../../hooks'

export const LIST_SAMPLES_BY_FILTER = gql`
  query ListSamplesByFilter($filter: JSONObject!, $page: Int, $limit: Int) {
    t6SamplesByFilter(filter: $filter, page: $page, limit: $limit) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`
