import { gql } from '../../../../../hooks'

export const READ_ESSAY_UNITS = gql`
  query AdministrationReadSamplingEssayUnits($id: ID!) {
    aRead(path: "sampling/essay/units", id: $id) {
    }
  }
`

export const DELETE_ESSAY_UNITS = gql`
  mutation AdministrationDeleteSamplingEssayUnits($id: ID!) {
    aDelete(path: "sampling/essay/units", id: $id) {
    }
  }
`
