import React from 'react'

import AdministrationInfo from '../..'

// Queries
import { READ_BRANDS, DELETE_BRANDS } from './queries'

const AdministrationInfoMachineBrands = ({ ...rest }) => (
  <AdministrationInfo
    queries={{ read: READ_BRANDS, delete: DELETE_BRANDS }}
    title="Marca"
    {...rest}
  />
)

export default AdministrationInfoMachineBrands
