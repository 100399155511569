import { gql } from '../../../../hooks'

export const GET_INSPECTION_INFO = gql`
  query InspectionInfo($id: ID!) {
    i2InspectionInfo(id: $id) {
    }
  }
`

export const LIST_INSPECTIONS = gql`
  query ListInspections($id: ID!) {
    i2ListInspections(id: $id) {
    }
  }
`

export const PUT_INSPECTION_PHOTOS = gql`
  mutation PutInspectionPhotos($photos: [Upload!]!, $id_inspection: ID!) {
    i2PutInspectionPhotos(id_inspection: $id_inspection, photos: $photos) {
    }
  }
`

export const DELETE_PHOTO = gql`
  mutation DeletePhoto($id: ID!) {
    i2DeletePhoto(id: $id) {
    }
  }
`

export const PUT_INSPECTION_FILES = gql`
  mutation PutInspectionFiles($files: [Upload!]!, $id_inspection: ID!) {
    i2PutInspectionFiles(id_inspection: $id_inspection, files: $files) {
    }
  }
`

export const UPDATE_INSPECTION = gql`
  mutation UpdateInspections($id_inspection: ID!, $observation: String!, $criticality: String!, $photos: [String!]!, $files: [String!]!, $av_ots: [InputAVOT!]!) {
    i2UpdateInspection(id_inspection: $id_inspection, observation: $observation, criticality: $criticality, photos: $photos, files: $files, av_ots: $av_ots) {
    }
  }
`

export const GENERATE_REPORT = gql`
  mutation GenerateReport($id: ID!) {
    i2GenerateReport(id: $id) {
    }
  }
`
