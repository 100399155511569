import React, { useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLink,
  faPen,
  faClone,
  faTimes,
  faUndo,
  faFilter,
  faBroom,
} from '@fortawesome/free-solid-svg-icons'

// Hooks
import {
  useQuery,
  useMutation,
  useEffect,
  useTrigger,
  useGlobal,
} from '../../../hooks'

// Components
import UILoading from '../../UI/Loading'
import UIAlert from '../../UI/Alert'
import UIPanelInfo from '../../UI/Panel/Info'
import UIPanelInfoSeparation from '../../UI/Panel/Info/Separation'
import UIPanelInfoBlock from '../../UI/Panel/Info/Block'
import UIPanelInfoItem from '../../UI/Panel/Info/Item'
import UIPanelInfoTable from '../../UI/Panel/Info/Table'
import UIButton from '../../UI/Button'
import UIForm from '../../UI/FormReload'
import UIFormInputText from '../../UI/FormReload/Input/Text'
import UIFormInputInteger from '../../UI/FormReload/Input/Integer'
import UIFormSeparation from '../../UI/FormReload/Separation'
import UIModal from '../../UI/Modal'

// Common
import { oe } from '../../../common/object/extractor'

import { AdministrationContainerContext } from '../Container'

export const RefLink = ({ children, tab, subtab, id, action = 'view' }) => (
  <Link
    target="_blank"
    to={{
      pathname: `/administration/${tab}/${subtab}`,
      search: `?action=${action}&id=${id}`,
    }}
  >
    {children} <FontAwesomeIcon className="align-self-center" icon={faLink} />
  </Link>
)

const AdministrationInfo = ({
  queries = {},
  variables,
  skip,
  title = 'Información',
  views = {},
  noDuplicate,
  noUpdate,
  noDelete,
}) => {
  const {
    data: { id, pagination } = {},
    filter,
    setDataState,
    setFilterState,
    setActionState,
    //    noise,
    forceUpdate,
  } = useContext(AdministrationContainerContext)

  const history = useHistory()

  const [{ me }] = useGlobal()

  const [modalTrigger, setModalTrigger] = useTrigger()

  const { data, loading, error, refetch } = useQuery(queries.read, {
    fetchPolicy: 'no-cache', // Idially omit this one by refetching
    variables: variables || { id },
    skip: !id,
  })

  const [deleteMutation] = useMutation(queries.delete, {
    onErrorMessage: 'El elemento no pudo ser eliminado',
  })

  useEffect(() => data && refetch(), [me.id_laboratory])

  if (loading) {
    return (
      <UIPanelInfo centered>
        <UILoading />
      </UIPanelInfo>
    )
  }

  const handleBack = () => {
    setDataState()
    history.push({ search: undefined })
    setActionState('view')
  }

  if (error) {
    const { message, graphQLErrors } = error
    return (
      <UIPanelInfo>
        <UIPanelInfoBlock title="Error" />
        <div className="mt-3">
          <UIPanelInfoItem>
            <UIAlert style="danger" noDismissible className="text-center">
              {oe(
                graphQLErrors[0],
                ['extensions', 'response', 'body', 'error', 'message'],
                message,
              )}
            </UIAlert>
          </UIPanelInfoItem>
          <UIFormSeparation />
          <div className="mt-2">
            <UIButton onClick={handleBack} className="mr-2" variant="light">
              Atrás
            </UIButton>
          </div>
        </div>
      </UIPanelInfo>
    )
  }

  if (data && data.aRead && data.aRead.data) {
    const props = data.aRead.data

    const handleDelete = () => {
      setModalTrigger()
    }

    const handleUpdate = () => {
      history.push({ search: `?action=update&id=${id}` })
      setActionState('update')
    }

    const handleDuplicate = () => {
      setDataState({ id, pagination })
      setActionState('create')
    }

    // Modal handler
    const handleAcceptModal = () => {
      deleteMutation({ variables: { id } })
      // if (id && String(id) === idToDelete) setDataState({ pagination })
      setActionState('view')
      setDataState({ pagination })
      forceUpdate()
    }

    const hancleCancelModal = () => {}

    return (
      <UIPanelInfo>
        {views.info ? (
          React.cloneElement(<views.info />, props)
        ) : (
          <UIPanelInfoBlock title={title}>
            <UIPanelInfoTable>
              {[
                ['ID', id],
                ['Descripción', props.name],
              ]}
            </UIPanelInfoTable>
          </UIPanelInfoBlock>
        )}
        <UIPanelInfoSeparation className="my-1" />
        <UIPanelInfoBlock className="text-center">
          <UIButton
            variant="light"
            className="mr-2 px-3"
            onClick={handleBack}
            title="Atrás"
          >
            <FontAwesomeIcon
              className="align-self-center"
              style={{ width: '15px' }}
              icon={faUndo}
            />
          </UIButton>
          {!noDelete && (
            <>
              <UIButton
                variant="danger"
                className="mr-2 px-3"
                onClick={handleDelete}
                title="Eliminar"
              >
                <FontAwesomeIcon
                  className="align-self-center"
                  style={{ width: '15px' }}
                  icon={faTimes}
                />
              </UIButton>
              <UIModal
                trigger={modalTrigger}
                onAccept={handleAcceptModal}
                onCancel={hancleCancelModal}
                onClose={hancleCancelModal}
                head={'Confirmación'}
                body={<>Confirmación para eliminar el elemento</>}
                acceptText="Eliminar"
                acceptVariant="danger"
              />
            </>
          )}
          {!noUpdate && (
            <UIButton
              variant="stng"
              className="mr-2 px-3"
              onClick={handleUpdate}
              title="Editar"
            >
              <FontAwesomeIcon
                className="align-self-center"
                style={{ width: '15px' }}
                icon={faPen}
              />
            </UIButton>
          )}
          {!noDuplicate && (
            <UIButton
              variant="success"
              className="mr-2 px-3"
              onClick={handleDuplicate}
              title="Duplicar"
            >
              <FontAwesomeIcon
                className="align-self-center"
                style={{ width: '15px' }}
                icon={faClone}
              />
            </UIButton>
          )}
        </UIPanelInfoBlock>
      </UIPanelInfo>
    )
  }

  const handleSubmit = (values) => {
    setFilterState({ ...values })
  }

  const handleReset = () => {
    setFilterState()
  }

  return (
    <UIPanelInfo>
      <UIPanelInfoBlock title="Filtrar resultados">
        <UIForm
          onSubmit={handleSubmit}
          onReset={handleReset}
          initialValues={filter}
        >
          {views.form ? (
            <views.form />
          ) : (
            <>
              <UIFormInputInteger label="ID" name="id" />
              <UIFormInputText label="Descripción" name="name" />
            </>
          )}
          <UIPanelInfoSeparation className="my-1" />
          <div className="mr-2 text-center">
            <UIButton
              type="reset"
              variant="light"
              className="mr-2 px-3"
              title="Limpiar filtro"
            >
              <FontAwesomeIcon
                className="align-self-center"
                style={{ width: '15px' }}
                icon={faBroom}
              />
            </UIButton>
            <UIButton
              type="submit"
              variant="info"
              className="mr-2 px-3"
              title="Filtrar"
            >
              <FontAwesomeIcon
                className="align-self-center"
                style={{ width: '15px' }}
                icon={faFilter}
              />
            </UIButton>
          </div>
        </UIForm>
      </UIPanelInfoBlock>
    </UIPanelInfo>
  )
}

export default AdministrationInfo
