import { forwardRef } from 'react'
import React from 'react'
import Chartjs from 'chart.js'

// Hooks
import { useRef, useEffect } from '../../../../../../../../hooks'

const GraphsPieBig = forwardRef(({ data }, ref) => {
  const image = useRef()

  useEffect(() => {
    const count = [0, 0, 0]

    data.forEach((result) => {
      count[0] += result.count_normal
      count[1] += result.count_alerta
      count[2] += result.count_anormal
    })

    const { count_normal, count_alerta, count_anormal } = data

    new Chartjs(image.current, {
      type: 'doughnut',
      data: {
        labels: [count[0], count[1], count[2]],
        datasets: [
          {
            data: [count[0], count[1], count[2]],
            backgroundColor: [
              'rgba(40, 167, 69, 0.7)',
              'rgba(255, 193, 7, 0.7)',
              'rgba(220, 53, 69, 0.7)',
            ],
            //label: 'Dataset 1',
            borderColor: [
              'rgba(40, 167, 69, 1)',
              'rgba(255, 193, 7, 1)',
              'rgba(220, 53, 69, 1)',
            ],
            borderWidth: 15,
          },
        ],
      },
      options: {
        // aspectRatio: 1,
        layout: {
          padding: 0,
          margin: 0,
        },
        //   responsive: true,
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            fontSize: 50,
            padding: 130,
          },
        },
        animation: {
          duration: 0,
        },
      },
    })

    // I know, I know ... if using once Timeout wasn't horrible enough
    const timer = setTimeout(() => {
      ref.current = image.current.toDataURL()
    }, 25)
    return () => clearTimeout(timer)
  }, [data])

  return (
    <canvas
      style={{ display: 'none' }}
      ref={image}
      width="1300px"
      height="1100px"
    />
  )
})

export default GraphsPieBig
