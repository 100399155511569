import { gql } from '../../../../../hooks'

export const READ_LOGICS = gql`
  query AdministrationReadAnalysisLogics($id: ID!) {
    aRead(path: "analysis/logics", id: $id) {
    }
  }
`

export const UPDATE_LOGICS = gql`
  mutation AdministrationUpdateAnalysisLogics($id: ID!, $data: JSONObject) {
    aUpdate(path: "analysis/logics", id: $id, data: $data) {
    }
  }
`

export const CREATE_LOGICS = gql`
  mutation AdministrationCreateAnalysisLogics($data: JSONObject) {
    aCreate(path: "analysis/logics", data: $data) {
    }
  }
`

export const LIST_LOGICS = gql`
  query AdministrationListAnalysisLogics(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(
      path: "analysis/logics"
      filter: $filter
      page: $page
      limit: $limit
    ) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`

export const LIST_CONDITIONS = gql`
  query AdministrationListConditions {
    aConditions {
    }
  }
`

export const LIST_ANOMALIES = gql`
  query AdministrationListAnomalyTypes {
    aAnomalyTypes {
    }
  }
`

export const LIST_TRACKING_STATES = gql`
  query AdministrationListTrackingStates {
    aTrackingStates {
    }
  }
`

export const LIST_ESSAYS = gql`
  query AdministrationListEssays {
    aEssays {
    }
  }
`

export const SEARCH_PROTOCOLS = gql`
  query AdministrationSearchProtocol($text: String) {
    aSearch(path: "protocol", filter: { type: 2 }, text: $text) {
    }
  }
`
