import React from 'react'

// Components
import AdministrationListAnalysisAnomalyTypes from '../../../../Administration/List/Analysis/AnomalyTypes'
import AdministrationInfoAnalysisAnomalyTypes from '../../../../Administration/Info/Analysis/AnomalyTypes'
import AdministrationFormAnalysisAnomalyTypes from '../../../../Administration/Form/Analysis/AnomalyTypes'

import { AdministrationContainer } from '../..'

const AdministrationContainerAnalysisAnomalyTypes = () => (
  <AdministrationContainer
    form={AdministrationFormAnalysisAnomalyTypes}
    info={AdministrationInfoAnalysisAnomalyTypes}
    list={AdministrationListAnalysisAnomalyTypes}
  />
)

export default AdministrationContainerAnalysisAnomalyTypes
