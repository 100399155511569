import React from 'react'
import { Row, Col, Form } from 'react-bootstrap'

// Hooks
import { useGlobal, useState } from '../../../../../../../hooks'

// Components
import UIButton from '../../../../../../../components/UI/Button'
import UIForm from '../../../../../../../components/UI/FormReload'
import UIFormInputSelect from '../../../../../../../components/UI/FormReload/Input/Select'
import UIFormInputDate from '../../../../../../../components/UI/FormReload/Input/Date'
import UIFormInputFile from '../../../../../../../components/UI/FormReload/Input/File'
import UIFormSeparation from '../../../../../../../components/UI/FormReload/Separation'

// Queries
import { LIST_CLIENTS } from './queries'

const TribologySamplesInsertMassiveExcelFormFilter = ({ onDataChange }) => {
  // Global state
  const [{ me }] = useGlobal()

  const [file, setFileState] = useState()

  // --------------------------------------------------------------------------
  //
  // Handler
  //
  // --------------------------------------------------------------------------

  const handleSubmit = (values) => {
    onDataChange({ ...values, file })
  }

  const handleFileChange = (event) => {
    setFileState(event.target.files[0])
  }

  return (
    <UIForm onSubmit={handleSubmit}>
      <Form.Group as={Row} className="m-1">
        <Col className="d-flex">
          <UIButton disabled className="mr-3">
            Revisar cargas
          </UIButton>
          <UIButton disabled={!file} type="submit">
            Cargar archivo
          </UIButton>
        </Col>
      </Form.Group>
      <UIFormSeparation />
      <UIFormInputSelect
        label="Cliente"
        name="id_client"
        query={LIST_CLIENTS}
        variables={{ id: me.id_laboratory }}
      >
        <option value="">Seleccione un cliente</option>
      </UIFormInputSelect>
      <UIFormInputDate
        label="Fecha de retiro"
        name="date_withdraw"
        required
        showTime
      />
      <UIFormInputFile
        label="Seleccione archivo"
        name="file"
        onChange={handleFileChange}
      />
    </UIForm>
  )
}

export default TribologySamplesInsertMassiveExcelFormFilter
