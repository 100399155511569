import React from 'react'

// Hooks
import { useLocation } from '../../../../../hooks'

// Components
import ReportTableFleetStateExcel from '../../../Table/FleetState/Excel'

const ReportContainerFleetStateExcel = () => {
  const search = useLocation().search

  return (
    <ReportTableFleetStateExcel
      filter={Object.fromEntries(new URLSearchParams(search))}
    />
  )
}

export default ReportContainerFleetStateExcel
