import React from 'react'
import { Col, Row } from 'react-bootstrap'

import { useParams } from '../../../hooks'

import InspectionsReportList from '../../../components/Inspections/Report/List'
import InspectionsReportForm from '../../../components/Inspections/Report/Form'
// import InspectionsReportInfo from '../../../components/Inspections/Report/Info'

const PageInspectionsReport = () => {
  const { id_inspection } = useParams()

  if (id_inspection)
    return (
      <Row>
        <Col lg={12} className="mb-3">
          <InspectionsReportForm id_inspection={id_inspection} />
        </Col>
      </Row>
    )

  return (
    <Row>
      <Col lg={12} className="mb-3">
        <InspectionsReportList />
      </Col>
    </Row>
  )
}

export default PageInspectionsReport
