import React from 'react'

import { AdministrationList } from '../../../List'

// Queries
import { DELETE_COLLECTIONS, LIST_COLLECTIONS } from './queries'

const queries = { list: LIST_COLLECTIONS, delete: DELETE_COLLECTIONS }

const AdministrationListComponentCollections = () => (
  <AdministrationList queries={queries} />
)

export default AdministrationListComponentCollections
