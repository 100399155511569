import React from 'react'

// Hooks
import { useGlobal } from '../../../../../hooks'

import { AdministrationList } from '../../../List'

// Queries
import { DELETE_POINTS, LIST_POINTS } from './queries'

const queries = {
  list: LIST_POINTS,
  delete: DELETE_POINTS,
}

const AdministrationListSamplingPoints = () => {
  const [{ me }] = useGlobal()

  const no = ![1, 2].includes(me.id_role)

  return (
    <AdministrationList
      queries={queries}
      noCreate={no}
      noUpdate={no}
      noDelete={no}
    />
  )
}

export default AdministrationListSamplingPoints
