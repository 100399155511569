import { gql } from '../../../../../hooks'

export const READ_BRANDS = gql`
  query AdministrationReadLubricantBrands($id: ID!) {
    aRead(path: "lubricant/brands", id: $id) {
    }
  }
`

export const UPDATE_BRANDS = gql`
  mutation AdministrationUpdateLubricantBrands($id: ID!, $data: JSONObject) {
    aUpdate(path: "lubricant/brands", id: $id, data: $data) {
    }
  }
`

export const CREATE_BRANDS = gql`
  mutation AdministrationCreateLubricantBrands($data: JSONObject) {
    aCreate(path: "lubricant/brands", data: $data) {
    }
  }
`

export const LIST_BRANDS = gql`
  query AdministrationListLubricantBrands(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(
      path: "lubricant/brands"
      filter: $filter
      page: $page
      limit: $limit
    ) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`
