import React from 'react'
import { Row, Col, Form, Card, Breadcrumb, Badge } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage, faBan } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

// Hooks
import { useState, useQuery, useHistory, useGlobal } from '../../../../hooks'

// Components
import UIButton from '../../../UI/Button'
import UILoading from '../../../UI/Loading'
import UIError from '../../../UI/Error'
import UIEmpty from '../../../UI/Empty'
import UIPanelInfo from '../../../UI/Panel/Info'
import UIPanelInfoBlock from '../../../UI/Panel/Info/Block'
import UIFormSeparation from '../../../UI/FormReload/Separation'
import UITable from '../../../UI/Table'
import UITableHead from '../../../UI/Table/Head'
import UITableBody from '../../../UI/Table/Body'

// import GraphsPie from './Graphs/Pie'
import GraphsPieBig from './Graphs/Pie/Big'
import GraphsPieSmall from './Graphs/Pie/Small'
import PDFButton from './PDF'
import PDFNextLevelButton from './PDF/Next/Level'

import InfoHeader from '../../Container/InfoHeader'

// Queries
import { GET_REPORT } from './queries'

const ReportTableManaging = ({ filter, onFilterChange }) => {
  const [{ me }] = useGlobal()

  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false)
  const [isLoadingPDF, setIsLoadingPDF] = useState(false)

  const [isGeneratingPDFNextLevel, setIsGeneratingPDFNextLevel] =
    useState(false)
  const [isLoadingPDFNextLevel, setIsLoadingPDFNextLevel] = useState(false)

  const history = useHistory()

  const {
    emission_type = '0',
    id_client,
    id_site,
    id_machine_type,
    id_machine,
    id_component,
    from,
    to,
  } = filter

  // useEffect(() => {
  //   if (documentPDF) setDocumentPDF(false)
  // }, [])

  // --------------------------------------------------------------------------
  //
  // Query report
  //
  // --------------------------------------------------------------------------

  const variables = {
    ...filter,
    next_level:
      !id_component &&
      !!id_machine &&
      me.laboratory === 'centinela' &&
      Math.round(moment.duration(moment(to).diff(moment(from))).asDays()) === 1,
  }

  const { data, loading } = useQuery(GET_REPORT, {
    variables,
    onErrorMessage: `Error al intentar obtener reporte`,
  })

  // --------------------------------------------------------------------------
  //
  // Render
  //
  // --------------------------------------------------------------------------

  if (loading) return <UILoading />

  if (data && data.reportManaging) {
    const { error } = data.reportManaging

    // ------------------------------------------------------------------------
    //
    // Info Body
    //
    // ------------------------------------------------------------------------

    let InfoBody
    let showPDFButton

    // Errors?
    // ------------------------------------------------------------------------
    if (error) {
      showPDFButton = false
      InfoBody = (
        <div className="text-center  py-5 border rounded">
          <FontAwesomeIcon
            size="10x"
            color="lightgray"
            className="mr-2"
            icon={faBan}
          />

          <h5 className="text-muted font-weight-light mt-5">{error.message}</h5>
        </div>
      )
    }
    // No errors
    // ------------------------------------------------------------------------
    else {
      const { info, results } = data.reportManaging.data

      // Empty?
      // ----------------------------------------------------------------------
      if (results.length === 0) {
        showPDFButton = false
        InfoBody = <UIEmpty />
      }
      // Not empty
      // ----------------------------------------------------------------------
      else {
        showPDFButton = true
        const classNameHeader = 'bg-stng border text-white align-middle'

        let baseSearch = `?from=${from}&to=${to}&emission_type=${emission_type}`
        let breadCrumbs = []

        // ========================================================================
        // Results by component
        // ========================================================================
        if (id_component) {
          breadCrumbs = [
            {
              pathname: `/reports/managing`,
              search: `?from=${info.header.from}&to=${info.header.to}&id_client=${info.breadCrumbs.client_id}&emission_type=${info.header.emission_type}`,
              name: info.breadCrumbs.client_name,
            },
            {
              pathname: `/reports/managing`,
              search: `?from=${info.header.from}&to=${info.header.to}&id_site=${info.breadCrumbs.site_id}&emission_type=${info.header.emission_type}`,
              name: info.breadCrumbs.site_name,
            },
            {
              pathname: `/reports/managing`,
              search: `?from=${info.header.from}&to=${info.header.to}&id_site=${info.breadCrumbs.site_id}&id_machine_type=${info.breadCrumbs.machine_type_id}&emission_type=${info.header.emission_type}`,
              name: info.breadCrumbs.machine_type_name,
            },
            {
              pathname: `/reports/managing`,
              search: `?from=${info.header.from}&to=${info.header.to}&id_machine=${info.breadCrumbs.machine_id}&emission_type=${info.header.emission_type}`,
              name: info.breadCrumbs.machine_name,
            },
            {
              name: info.breadCrumbs.component_name,
            },
          ]
        }

        // ========================================================================
        // Results by machine
        // ========================================================================
        else if (id_machine) {
          baseSearch = `${baseSearch}&id_component=`
          breadCrumbs = [
            {
              pathname: `/reports/managing`,
              search: `?from=${info.header.from}&to=${info.header.to}&id_client=${info.breadCrumbs.client_id}&emission_type=${info.header.emission_type}`,
              name: info.breadCrumbs.client_name,
            },
            {
              pathname: `/reports/managing`,
              search: `?from=${info.header.from}&to=${info.header.to}&id_site=${info.breadCrumbs.site_id}&emission_type=${info.header.emission_type}`,
              name: info.breadCrumbs.site_name,
            },
            {
              pathname: `/reports/managing`,
              search: `?from=${info.header.from}&to=${info.header.to}&id_site=${info.breadCrumbs.site_id}&id_machine_type=${info.breadCrumbs.machine_type_id}&emission_type=${info.header.emission_type}`,
              name: info.breadCrumbs.machine_type_name,
            },
            {
              name: info.breadCrumbs.machine_name,
            },
          ]
        }

        // ========================================================================
        // Results by machine type
        // ========================================================================
        else if (id_machine_type && id_site) {
          baseSearch = `${baseSearch}&id_machine=`
          breadCrumbs = [
            {
              pathname: `/reports/managing`,
              search: `?from=${info.header.from}&to=${info.header.to}&id_client=${info.breadCrumbs.client_id}&emission_type=${info.header.emission_type}`,
              name: info.breadCrumbs.client_name,
            },
            {
              pathname: `/reports/managing`,
              search: `?from=${info.header.from}&to=${info.header.to}&id_site=${info.breadCrumbs.site_id}&emission_type=${info.header.emission_type}`,
              name: info.breadCrumbs.site_name,
            },
            {
              name: info.breadCrumbs.machine_type_name,
            },
          ]
        }

        // ========================================================================
        // Results by site
        // ========================================================================
        else if (id_site) {
          baseSearch = `${baseSearch}&id_site=${id_site}&id_machine_type=`
          breadCrumbs = [
            {
              pathname: `/reports/managing`,
              search: `?from=${info.header.from}&to=${info.header.to}&id_client=${info.breadCrumbs.client_id}&emission_type=${info.header.emission_type}`,
              name: info.breadCrumbs.client_name,
            },
            {
              name: info.breadCrumbs.site_name,
            },
          ]
        }

        // ========================================================================
        // Results by client
        // ========================================================================
        else if (id_client) {
          baseSearch = `${baseSearch}&id_site=`
          breadCrumbs = [{ name: info.breadCrumbs.client_name }]
        }

        // ------------------------------------------------------------------------
        //
        // Info Body
        //
        // ------------------------------------------------------------------------

        const totalCount = [0, 0, 0]
        results.forEach((result) => {
          totalCount[0] += result.count_normal
          totalCount[1] += result.count_alerta
          totalCount[2] += result.count_anormal
        })

        const handleLinkClick = (event) => {
          if (isGeneratingPDF && !isLoadingPDF) setIsGeneratingPDF(false)
          if (isGeneratingPDFNextLevel && !isLoadingPDFNextLevel)
            setIsGeneratingPDFNextLevel(false)
          if (isLoadingPDF || isLoadingPDFNextLevel) event.preventDefault()
        }

        InfoBody = (
          <Row>
            <Col xs={12}>
              <Breadcrumb>
                {breadCrumbs.map((breadCrumb, index) => {
                  const { pathname, search, name } = breadCrumb

                  if (breadCrumbs.length === index + 1)
                    return (
                      <Breadcrumb.Item active key={name}>
                        {name}
                      </Breadcrumb.Item>
                    )

                  return (
                    <Breadcrumb.Item key={name}>
                      <Link
                        to={{
                          pathname,
                          search,
                        }}
                        onClick={handleLinkClick}
                      >
                        {name}
                      </Link>
                    </Breadcrumb.Item>
                  )
                })}
              </Breadcrumb>
            </Col>
            {id_component ? (
              <Col xs={12}>
                <UITable borderless>
                  <UITableHead>
                    {[
                      'ID',
                      'Fecha Muestreo',
                      'TAG',
                      'Condición',
                      'Vista Previa',
                    ]}
                  </UITableHead>
                  <UITableBody>
                    {results.map((row) => {
                      return (
                        <tr key={`${row.sample_id}`}>
                          <td>{row.sample_id}</td>
                          <td>
                            {row.date_sampling}
                            {/*row.date_sampling &&
                            moment(row.report_date_sampling).format(
                              'DD/MM/YYYY',
                            )*/}
                          </td>
                          <td>{row.component_tag}</td>
                          <td>
                            <Badge
                              variant={
                                row.sample_condition_id === 1
                                  ? 'success'
                                  : row.sample_condition_id === 2
                                  ? 'warning'
                                  : 'danger'
                              }
                            >
                              {row.sample_condition_id === 1
                                ? 'Normal'
                                : row.sample_condition_id === 2
                                ? 'Alerta'
                                : 'Crítico'}
                            </Badge>
                          </td>
                          <td>
                            <Link
                              to={`/tribology/samples/report/pdf/${row.sample_id}`}
                              className={`text-decoration-none`}
                              target="_blank"
                            >
                              Ver Muestra N° {row.sample_id}
                            </Link>
                          </td>
                        </tr>
                      )
                    })}
                  </UITableBody>
                </UITable>
              </Col>
            ) : (
              <>
                <Col xl={8}>
                  <Row>
                    {results.map((result) => {
                      const { id, name } = result

                      const data = [
                        result.count_normal,
                        result.count_alerta,
                        result.count_anormal,
                      ]

                      const search = `${baseSearch}${id}`

                      return (
                        <Col md={6} key={id} className="mb-3">
                          <Card>
                            <Card.Header>{name}</Card.Header>
                            <Card.Body>
                              <Row>
                                <Col xs={6}>
                                  <Link
                                    to={{
                                      pathname: `/reports/managing`,
                                      search,
                                    }}
                                    className={`text-decoration-none text-stng-light`}
                                    onClick={handleLinkClick}
                                  >
                                    {/*<Image src="/camion.jpg" thumbnail />*/}
                                    <Card>
                                      <Card.Body className="text-center">
                                        <FontAwesomeIcon
                                          size="4x"
                                          icon={faImage}
                                          color="lightgray"
                                          className="my-4"
                                        />
                                      </Card.Body>
                                    </Card>
                                  </Link>
                                </Col>
                                <Col xs={6}>
                                  <div
                                    style={{ height: '200px', width: '100%' }}
                                  >
                                    <GraphsPieSmall data={data} />
                                  </div>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </Col>
                      )
                    })}
                  </Row>
                </Col>
                <Col xl={4}>
                  <UIPanelInfo>
                    <UIPanelInfoBlock title="Resumen global">
                      <div style={{ height: '400px' }}>
                        <GraphsPieBig data={totalCount} />
                      </div>
                    </UIPanelInfoBlock>
                  </UIPanelInfo>
                </Col>
              </>
            )}
          </Row>
        )
      }
    }

    // ------------------------------------------------------------------------
    //
    // Render
    //
    // ------------------------------------------------------------------------

    const handleBack = () => {
      onFilterChange()
      history.push('/reports/managing')
    }

    const handleClick = (value) => {
      setIsGeneratingPDF(value)
      if (value) setIsLoadingPDF(value)
    }

    const handleLoading = (value) => {
      setIsLoadingPDF(value)
    }

    const handleClickNextLevel = (value) => {
      setIsGeneratingPDFNextLevel(value)
      if (value) setIsLoadingPDFNextLevel(value)
    }

    const handleLoadingNextLevel = (value) => {
      setIsLoadingPDFNextLevel(value)
    }

    return (
      <>
        <Form.Group as={Row} className="m-1">
          <Col className="d-flex">
            <UIButton className="mx-1" onClick={handleBack}>
              Volver
            </UIButton>
            {showPDFButton &&
              !id_component &&
              (isGeneratingPDF ? (
                <PDFButton
                  className="mx-1"
                  data={data.reportManaging.data}
                  onClick={() => handleClick(false)}
                  onLoading={handleLoading}
                />
              ) : (
                <UIButton
                  className="mx-1"
                  variant="secondary"
                  onClick={() => handleClick(true)}
                >
                  Generar PDF
                </UIButton>
              ))}
            {showPDFButton &&
              !id_component &&
              id_machine &&
              me.laboratory === 'centinela' &&
              Math.round(
                moment.duration(moment(to).diff(moment(from))).asDays(),
              ) === 1 &&
              (isGeneratingPDFNextLevel ? (
                <PDFNextLevelButton
                  className="mx-1"
                  data={data.reportManaging.data}
                  onClick={() => handleClickNextLevel(false)}
                  onLoading={handleLoadingNextLevel}
                />
              ) : (
                <UIButton
                  className="mx-1"
                  variant="secondary"
                  onClick={() => handleClickNextLevel(true)}
                >
                  Generar PDF (Nivel siguiente)
                </UIButton>
              ))}
          </Col>
        </Form.Group>
        <Row className="m-1">
          <Col xs={12}>
            <UIFormSeparation />
          </Col>
          {!error && (
            <Col xs={12}>
              <InfoHeader
                info={{
                  ...data.reportManaging.data.info,
                  from,
                  to,
                }}
              />
            </Col>
          )}
          <Col xs={12}>{InfoBody}</Col>
        </Row>
      </>
    )
  }

  return <UIError />
}

export default ReportTableManaging
