import { gql } from '../../../../../../../hooks/useQuery'

export const RECEIVE_SAMPLES_MASSIVE = gql`
  mutation ReceiveSampleMassive($id: ID!) {
    t2ReceiveSampleMassive(id: $id) {
    }
  }
`

export const GET_MASSIVE_SAMPLES = gql`
  query GetMassiveSamples {
    t2MassiveSamples {
    }
  }
`
