import React from 'react'

// Hooks
import { useGlobal } from '../../../../../hooks'

// Components
import UIPanelInfoBlock from '../../../../UI/Panel/Info/Block'
import UIPanelInfoTable from '../../../../UI/Panel/Info/Table'
import UIFormInputText from '../../../../UI/FormReload/Input/Text'
import UIFormInputInteger from '../../../../UI/FormReload/Input/Integer'

// Common
import { oe } from '../../../../../common/object/extractor'

import AdministrationInfo from '../..'

// Queries
import { READ_CLIENTS, DELETE_CLIENTS } from './queries'

const Info = (data) => (
  <>
    <UIPanelInfoBlock title="Cliente">
      <UIPanelInfoTable>
        {[
          ['ID', oe(data, ['id'])],
          ['Nombre', oe(data, ['name'])],
          ['Razón social', oe(data, ['registeredName'])],
          ['RUT', `${oe(data, ['rut'])}-${oe(data, ['dv'])}`],
          ['Activo', oe(data, ['active']) === 1 ? 'Sí' : 'No'],
          ['Dirección', oe(data, ['legalAddress'])],
          ['Ciudad', oe(data, ['city', 'name'])],
          ['Región', oe(data, ['region', 'name'])],
          ['País', oe(data, ['country', 'name'])],
          [
            'Llave de acceso (token)',
            <span
              className="text-center w-100"
              style={{
                wordWrap: 'break-word',
                display: 'inline-block',
              }}
            >
              {oe(data, ['apiKey', 'name'], false) || 'Sin llave de acceso'}
            </span>,
          ],
        ]}
      </UIPanelInfoTable>
    </UIPanelInfoBlock>
  </>
)

const Form = () => (
  <>
    <UIFormInputInteger label="ID" name="id" />
    <UIFormInputInteger label="RUT" name="rut" />
    <UIFormInputText label="Razón social" name="name" />
  </>
)

const AdministrationInfoClientClients = ({ ...rest }) => {
  const [{ me }] = useGlobal()

  const no = ![1, 2].includes(me.id_role)

  return (
    <AdministrationInfo
      queries={{ read: READ_CLIENTS, delete: DELETE_CLIENTS }}
      views={{ form: Form, info: Info }}
      noDuplicate={no}
      noUpdate={no}
      noDelete={no}
      {...rest}
    />
  )
}

export default AdministrationInfoClientClients
