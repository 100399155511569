const essaysMap = {
  // --------------------------------------------------------------------------
  antofagasta: [
    { id: 1, name: 'Fierro' },
    { id: 2, name: 'Cromo' },
    { id: 3, name: 'Aluminio' },
    { id: 4, name: 'Cobre' },
    { id: 5, name: 'Plomo' },
    { id: 6, name: 'Nickel' },
    { id: 7, name: 'Plata' },
    { id: 8, name: 'Estaño' },
    { id: 9, name: 'Titanio' },
    { id: 10, name: 'Vanadio' },
    { id: 11, name: 'Cadmio' },
    { id: 12, name: 'Manganeso' },
    { id: 13, name: 'Sodio' },
    { id: 14, name: 'Potasio' },
    { id: 15, name: 'Silicio' },
    { id: 16, name: 'Zinc' },
    { id: 17, name: 'Bario' },
    { id: 18, name: 'Boro' },
    { id: 19, name: 'Calcio' },
    { id: 20, name: 'Molibdeno' },
    { id: 21, name: 'Magnesio' },
    { id: 22, name: 'Fósforo' },
    { id: 23, name: 'V 40' },
    { id: 24, name: 'V 100' },
    { id: 25, name: 'Índice viscosidad' },
    { id: 26, name: 'Contenido agua' },
    { id: 27, name: 'N° total básico' },
    { id: 28, name: 'N° total ácido' },
    { id: 29, name: 'Índice PQ' },
    { id: 30, name: 'Dilución por combustible' },
    { id: 31, name: 'Oxidación' },
    { id: 32, name: 'Sulfatación' },
    { id: 33, name: 'Nitración' },
    { id: 34, name: 'Hollín Absorbancia' },
    { id: 35, name: 'Agua Karl Fisher' },
    { id: 36, name: 'Consistencia trabajada' },
    { id: 37, name: 'Consistencia NO trabajada' },
    { id: 38, name: 'Consistencia 10000 golpes' },
    { id: 39, name: 'Punto de goteo' },
    { id: 40, name: 'Determinación pH' },
    { id: 41, name: 'Punto de congelamiento' },
    { id: 42, name: '% Anticongelantes' },
    { id: 43, name: 'Punto de inflamación PMCC' },
    { id: 44, name: 'Partículas >4um' },
    { id: 45, name: 'Partículas >6um' },
    { id: 46, name: 'Partículas >14um' },
    { id: 47, name: 'Código ISO' },
    { id: 49, name: 'Ester Breakdown I' },
    { id: 50, name: 'Ester Breakdown II' },
    { id: 55, name: 'Hollín % ' },
  ],
  // --------------------------------------------------------------------------
  collahuasi: [
    { id: 1, name: 'Fierro' },
    { id: 2, name: 'Cromo' },
    { id: 3, name: 'Aluminio' },
    { id: 4, name: 'Cobre' },
    { id: 5, name: 'Plomo' },
    { id: 6, name: 'Nickel' },
    { id: 7, name: 'Plata' },
    { id: 8, name: 'Estaño' },
    { id: 9, name: 'Titanio' },
    { id: 10, name: 'Vanadio' },
    { id: 11, name: 'Cadmio' },
    { id: 12, name: 'Manganeso' },
    { id: 13, name: 'Sodio' },
    { id: 14, name: 'Potasio' },
    { id: 15, name: 'Silicio' },
    { id: 16, name: 'Zinc' },
    { id: 17, name: 'Bario' },
    { id: 18, name: 'Boro' },
    { id: 19, name: 'Calcio' },
    { id: 20, name: 'Molibdeno' },
    { id: 21, name: 'Magnesio' },
    { id: 22, name: 'Fósforo' },
    { id: 23, name: 'V 40' },
    { id: 24, name: 'V 100' },
    { id: 25, name: 'Índice viscosidad' },
    { id: 26, name: 'Contenido agua' },
    { id: 27, name: 'N° total básico' },
    { id: 28, name: 'N° total ácido' },
    { id: 29, name: 'Índice PQ' },
    { id: 30, name: 'Dilución por combustible' },
    { id: 31, name: 'Oxidación' },
    { id: 32, name: 'Sulfatación' },
    { id: 33, name: 'Nitración' },
    { id: 34, name: 'Hollín Absorbancia' },
    { id: 35, name: 'Agua Karl Fisher' },
    { id: 36, name: 'Consistencia trabajada' },
    { id: 37, name: 'Consistencia NO trabajada' },
    { id: 38, name: 'Consistencia 10000 golpes' },
    { id: 39, name: 'Punto de goteo' },
    { id: 40, name: 'Determinación pH' },
    { id: 41, name: 'Punto de congelamiento' },
    { id: 42, name: '% Anticongelantes' },
    { id: 43, name: 'Punto de inflamación PMCC' },
    { id: 44, name: 'Partículas >4um' },
    { id: 45, name: 'Partículas >6um' },
    { id: 46, name: 'Partículas >10um' },
    { id: 47, name: 'Código ISO' },
    { id: 49, name: 'Ester Breakdown I' },
    { id: 50, name: 'Ester Breakdown II' },
    { id: 52, name: 'Hollín % ' },
  ],
  // --------------------------------------------------------------------------
  centinela: [
    { id: 1, name: 'Fierro' },
    { id: 2, name: 'Cromo' },
    { id: 3, name: 'Aluminio' },
    { id: 4, name: 'Cobre' },
    { id: 5, name: 'Plomo' },
    { id: 6, name: 'Nickel' },
    { id: 7, name: 'Plata' },
    { id: 8, name: 'Estaño' },
    { id: 9, name: 'Titanio' },
    { id: 10, name: 'Vanadio' },
    { id: 11, name: 'Cadmio' },
    { id: 12, name: 'Manganeso' },
    { id: 13, name: 'Sodio' },
    { id: 14, name: 'Potasio' },
    { id: 15, name: 'Silicio' },
    { id: 16, name: 'Zinc' },
    { id: 17, name: 'Bario' },
    { id: 18, name: 'Boro' },
    { id: 19, name: 'Calcio' },
    { id: 20, name: 'Molibdeno' },
    { id: 21, name: 'Magnesio' },
    { id: 22, name: 'Fósforo' },
    { id: 23, name: 'V 40' },
    { id: 24, name: 'V 100' },
    { id: 25, name: 'Índice viscosidad' },
    { id: 26, name: 'Contenido agua' },
    { id: 27, name: 'N° total básico' },
    { id: 28, name: 'N° total ácido' },
    { id: 29, name: 'Índice PQ' },
    { id: 30, name: 'Dilución por combustible' },
    { id: 31, name: 'Oxidación' },
    { id: 32, name: 'Sulfatación' },
    { id: 33, name: 'Nitración' },
    { id: 34, name: 'Hollín Absorbancia' },
    { id: 35, name: 'Agua Karl Fisher' },
    { id: 40, name: 'Determinación pH' },
    { id: 41, name: 'Punto de congelamiento' },
    { id: 42, name: '% Anticongelantes' },
    { id: 44, name: 'Partículas >4um' },
    { id: 45, name: 'Partículas >6um' },
    { id: 46, name: 'Partículas >14um' },
    { id: 48, name: 'Ester Breakdown I' },
    { id: 49, name: 'Ester Breakdown II' },
    { id: 51, name: 'Hollín % ' },
    { id: 52, name: 'Partículas >21um' },
    { id: 53, name: 'Partículas >38um' },
    { id: 54, name: 'Partículas >70um' },
    { id: 55, name: 'Código ISO' },
    { id: 56, name: 'Fatiga' },
    { id: 57, name: 'Corte' },
    { id: 58, name: 'No Metálica' },
    { id: 59, name: 'Deslizante' },
    { id: 60, name: 'Tapón Magnético' },
    { id: 62, name: 'PQ' },
  ],
  // --------------------------------------------------------------------------
  quebradablanca: [
    { id: 1, name: 'Fierro' },
    { id: 2, name: 'Cromo' },
    { id: 3, name: 'Aluminio' },
    { id: 4, name: 'Cobre' },
    { id: 5, name: 'Plomo' },
    { id: 6, name: 'Nickel' },
    { id: 7, name: 'Plata' },
    { id: 8, name: 'Estaño' },
    { id: 9, name: 'Titanio' },
    { id: 10, name: 'Vanadio' },
    { id: 11, name: 'Cadmio' },
    { id: 12, name: 'Manganeso' },
    { id: 13, name: 'Sodio' },
    { id: 14, name: 'Potasio' },
    { id: 15, name: 'Silicio' },
    { id: 16, name: 'Zinc' },
    { id: 17, name: 'Bario' },
    { id: 18, name: 'Boro' },
    { id: 19, name: 'Calcio' },
    { id: 20, name: 'Molibdeno' },
    { id: 21, name: 'Magnesio' },
    { id: 22, name: 'Fósforo' },
    { id: 23, name: 'V 40' },
    { id: 24, name: 'V 100' },
    { id: 25, name: 'Índice viscosidad' },
    { id: 26, name: 'Contenido agua' },
    { id: 27, name: 'N° total básico' },
    { id: 28, name: 'N° total ácido' },
    { id: 29, name: 'Índice PQ' },
    { id: 30, name: 'Dilución por combustible' },
    { id: 31, name: 'Oxidación' },
    { id: 32, name: 'Sulfatación' },
    { id: 33, name: 'Nitración' },
    { id: 34, name: 'Hollín Absorbancia' },
    { id: 35, name: 'Agua Karl Fisher' },
    { id: 36, name: 'Consistencia trabajada' },
    { id: 37, name: 'Consistencia NO trabajada' },
    { id: 38, name: 'Consistencia 10000 golpes' },
    { id: 39, name: 'Punto de goteo' },
    { id: 40, name: 'Determinación pH' },
    { id: 41, name: 'Punto de congelamiento' },
    { id: 42, name: '% Anticongelantes' },
    { id: 43, name: 'Punto de inflamación PMCC' },
    { id: 44, name: 'Partículas >4um' },
    { id: 45, name: 'Partículas >6um' },
    { id: 46, name: 'Partículas >14um' },
    { id: 47, name: 'Código ISO' },
    { id: 49, name: 'Ester Breakdown I' },
    { id: 50, name: 'Ester Breakdown II' },
    { id: 55, name: 'Hollín % ' },
  ],
  // --------------------------------------------------------------------------
  esmax: [
    { id: 1, name: 'Fierro' },
    { id: 2, name: 'Cromo' },
    { id: 3, name: 'Aluminio' },
    { id: 4, name: 'Cobre' },
    { id: 5, name: 'Plomo' },
    { id: 6, name: 'Nickel' },
    { id: 7, name: 'Plata' },
    { id: 8, name: 'Estaño' },
    { id: 9, name: 'Titanio' },
    { id: 10, name: 'Vanadio' },
    { id: 11, name: 'Cadmio' },
    { id: 12, name: 'Manganeso' },
    { id: 13, name: 'Sodio' },
    { id: 14, name: 'Potasio' },
    { id: 15, name: 'Silicio' },
    { id: 16, name: 'Zinc' },
    { id: 17, name: 'Bario' },
    { id: 18, name: 'Boro' },
    { id: 19, name: 'Calcio' },
    { id: 20, name: 'Molibdeno' },
    { id: 21, name: 'Magnesio' },
    { id: 22, name: 'Fósforo' },
    { id: 23, name: 'V 40' },
    { id: 24, name: 'V 100' },
    { id: 25, name: 'Índice viscosidad' },
    { id: 26, name: 'Contenido agua' },
    { id: 27, name: 'N° total básico' },
    { id: 28, name: 'N° total ácido' },
    { id: 29, name: 'Índice PQ' },
    { id: 30, name: 'Dilución por combustible' },
    { id: 31, name: 'Oxidación' },
    { id: 32, name: 'Sulfatación' },
    { id: 33, name: 'Nitración' },
    { id: 34, name: 'Hollín Absorbancia' },
    { id: 35, name: 'Agua Karl Fisher' },
    { id: 36, name: 'Consistencia trabajada' },
    { id: 37, name: 'Consistencia NO trabajada' },
    { id: 38, name: 'Consistencia 10000 golpes' },
    { id: 39, name: 'Punto de goteo' },
    { id: 40, name: 'Determinación pH' },
    { id: 41, name: 'Punto de congelamiento' },
    { id: 42, name: '% Anticongelantes' },
    { id: 43, name: 'Punto de inflamación PMCC' },
    { id: 44, name: 'Partículas >4um' },
    { id: 45, name: 'Partículas >6um' },
    { id: 46, name: 'Partículas >14um' },
    { id: 47, name: 'Código ISO' },
    { id: 49, name: 'Ester Breakdown I' },
    { id: 50, name: 'Ester Breakdown II' },
    { id: 55, name: 'Hollín % ' },
  ],
}

export default essaysMap
