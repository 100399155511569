import React from 'react'

// Components
import AdministrationListSamplingAnalysisMethods from '../../../../Administration/List/Sampling/AnalysisMethods'
import AdministrationInfoSamplingAnalysisMethods from '../../../../Administration/Info/Sampling/AnalysisMethods'
import AdministrationFormSamplingAnalysisMethods from '../../../../Administration/Form/Sampling/AnalysisMethods'

import { AdministrationContainer } from '../..'

const AdministrationContainerSamplingAnalysisMethods = () => (
  <AdministrationContainer
    form={AdministrationFormSamplingAnalysisMethods}
    info={AdministrationInfoSamplingAnalysisMethods}
    list={AdministrationListSamplingAnalysisMethods}
  />
)

export default AdministrationContainerSamplingAnalysisMethods
