import React from 'react'
import { Col, Row, Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

// Hooks
import {
  useState,
  useGlobal,
  useTrigger,
  useEffect,
  useMutation,
} from '../../../../hooks'

// Components
import UIPanelInfo from '../../../UI/Panel/Info'
import UIPanelInfoBlock from '../../../UI/Panel/Info/Block'
import UIPanelInfoItem from '../../../UI/Panel/Info/Item'
import UIEmpty from '../../../UI/Empty'
import UIButton from '../../../UI/Button'

// Common
import { oe } from '../../../../common/object/extractor'

// Queries
import { READ_USERS, DELETE_USERS, RESET_PASSWORD } from './queries'

const AdministrationInfoWaste = ({ values, onDelete }) => {
  return (
    <UIPanelInfo>
      <UIPanelInfoBlock title="Listado">
        {values.length ? (
          values.map(({ amount, id_lubricant }, index) => (
            <Card className="my-1">
              <Card.Body>
                <Row>
                  <Col lg={8}>
                    <UIPanelInfoItem name="Lubricante">
                      {id_lubricant}
                    </UIPanelInfoItem>
                    <UIPanelInfoItem name="Cantidad">{amount}</UIPanelInfoItem>
                  </Col>
                  <Col lg={4}>
                    <UIPanelInfoItem>
                      <UIButton
                        variant="outline-danger"
                        className="mr-2"
                        onClick={() => onDelete(index)}
                        title="Eliminar"
                      >
                        <FontAwesomeIcon
                          className="align-self-center"
                          style={{ width: '15px' }}
                          icon={faTimes}
                        />
                      </UIButton>
                    </UIPanelInfoItem>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          ))
        ) : (
          <UIEmpty />
        )}
      </UIPanelInfoBlock>
    </UIPanelInfo>
  )
}

export default AdministrationInfoWaste
