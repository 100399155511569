import { gql } from '../../../../../hooks'

export const LIST_RECEIVED_SAMPLES = gql`
  query ReceivedSamples($page: Int, $limit: Int, $idSite: Int) {
    t5SamplesByStage(page: $page, limit: $limit, idSite: $idSite) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`

export const CHANGE_PRIORITY_ORDER = gql`
  mutation ChangePriorityOrder($id: ID!, $order: Int!) {
    t5ChangePriorityOrder(id: $id, order: $order) {
    }
  }
`

export const CHANGE_PRIORITY_ORDER_BY_COMPONENT_TYPE = gql`
  mutation ChangePriorityOrderByComponentType($id: ID!, $order: Int!) {
    t5ChangePriorityOrderByComponentType(id: $id, order: $order) {
    }
  }
`
