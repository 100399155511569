import React from 'react'

// Components
import UIPanelInfo from '../../../../../../../components/UI/Panel/Info'
import UIPanelInfoBlock from '../../../../../../../components/UI/Panel/Info/Block'
import UIPanelInfoItem from '../../../../../../../components/UI/Panel/Info/Item'

// Hooks
// import { useStyle } from '../../../../../../../hooks'

const SampleInfoInsertMassiveWeb = () => {
  return (
    <UIPanelInfo>
      <UIPanelInfoBlock title="Búsqueda por correlativo">
        <UIPanelInfoItem name="Correlativo único">
          Por ejemplo <strong>340000</strong>
        </UIPanelInfoItem>
        <UIPanelInfoItem name="Listado de correlativos">
          {' '}
          Separados por coma, por ejemplo <strong>340000,340002,340004</strong>
        </UIPanelInfoItem>
        <UIPanelInfoItem name="Rango de correlativos">
          {' '}
          Separados por un guión, por ejemplo <strong>340000-340004</strong>
        </UIPanelInfoItem>
      </UIPanelInfoBlock>
      <UIPanelInfoBlock title="Búsqueda por correlativo masivo">
        <UIPanelInfoItem name="Correlativo masivo único">
          Por ejemplo <strong>45000</strong>
        </UIPanelInfoItem>
      </UIPanelInfoBlock>
    </UIPanelInfo>
  )
}

export default SampleInfoInsertMassiveWeb
