/**
 * State Management with React Hooks and Context API in 10 lines of code!
 * ( https://medium.com/simply/state-management-with-react-hooks-and-context-api-at-10-lines-of-code-baf6be8302c)
 *
 * Component implementation example:
 *
 *
 * import { useStateValue } from './state';
 *
 * const ThemedButton = () => {
 *   const [{ theme }, dispatch] = useGlobalReducer();
 *   return (
 *     <Button
 *       primaryColor={theme.primary}
 *       onClick={() => dispatch({
 *         type: 'changeTheme',
 *         newTheme: { primary: 'blue'}
 *       })}
 *     >
 *       Make me blue!
 *     </Button>
 *   );
 * }
 *
 */

import React, { createContext, useContext, useReducer } from 'react'

export const StateContext = createContext()

export const StateProvider = ({ reducer, initialState, children }) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
)
export const useGlobal = () => useContext(StateContext)

export { default as reducer } from './reducer'
export { default as initialState } from './initial_state'

export default useGlobal
