import { gql } from '../../../../../hooks'

export const READ_SAMPLES = gql`
  query AdministrationReadSamplingSamples($id: ID!) {
    aRead(path: "sampling/samples", id: $id) {
    }
  }
`

export const UPDATE_SAMPLES = gql`
  mutation AdministrationUpdateSamplingSamples($id: ID!, $data: JSONObject) {
    aUpdate(path: "sampling/samples", id: $id, data: $data) {
    }
  }
`

export const CHECK_COMPONENT_HISTORY = gql`
  query AdministrationCheckComponentHistory($id: ID!, $id_component: ID!) {
    aCheckComponentHistory(id: $id, id_component: $id_component) {
    }
  }
`

// export const CREATE_SAMPLES = gql`
//   mutation AdministrationCreateSamplingSamples($data: JSONObject) {
//     aCreate(path: "sampling/samples", data: $data) {
//       data
//       error {
//         id
//       }
//     }
//   }
// `

export const LIST_SAMPLES = gql`
  query AdministrationListSamplingSamples(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(
      path: "sampling/samples"
      filter: $filter
      page: $page
      limit: $limit
    ) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`

export const SEARCH_COMPONENT = gql`
  query AdministrationSearchComponent($text: String) {
    aSearch(path: "component", text: $text) {
    }
  }
`

export const SEARCH_TRACKING_STATE = gql`
  query AdministrationSearchSamplingTrackingState {
    aSearch(path: "tracking_state", limit: 0, text: "") {
    }
  }
`

export const SEARCH_ANOMALY_TYPE = gql`
  query AdministrationSearchSamplingAnomalyType {
    aSearch(path: "anomaly_type", limit: 0, text: "") {
    }
  }
`

export const SEARCH_CONDITION = gql`
  query AdministrationSearchSamplingCondition {
    aSearch(path: "condition", limit: 0, text: "") {
    }
  }
`

export const SEARCH_CHANGE_REFILL_MOTIVE = gql`
  query AdministrationSearchSamplingLubricantChangeRefillMotive {
    aSearch(path: "luibricant_change_refill_motive", limit: 0, text: "") {
    }
  }
`

export const SEARCH_CHANGE_REFILL = gql`
  query AdministrationSearchSamplingiLubricantChangeRefill {
    aSearch(path: "lubricant_change_refill", limit: 0, text: "") {
    }
  }
`

export const SEARCH_COMPONENT_CHANGE_MOTIVE = gql`
  query AdministrationSearchSamplingComponentChangeMotive {
    aSearch(path: "component_change_motive", limit: 0, text: "") {
    }
  }
`

export const SEARCH_PRIORITY = gql`
  query AdministrationSearchSamplingPriority {
    aSearch(path: "priority", limit: 0, text: "") {
    }
  }
`
