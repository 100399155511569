import { gql } from '../../../../../hooks'

export const READ_SITES = gql`
  query AdministrationReadClientSites($id: ID!) {
    aRead(path: "client/sites", id: $id) {
    }
  }
`

export const DELETE_SITES = gql`
  mutation AdministrationDeleteClientSites($id: ID!) {
    aDelete(path: "client/sites", id: $id) {
    }
  }
`
