import React from 'react'
import { Badge } from 'react-bootstrap'
import moment from 'moment'

// Hooks
import {
  useState,
  useQuery,
  useMutation,
  useGlobal,
} from '../../../../../hooks'

// Components
import UIEmpty from '../../../../UI/Empty'
import UILoading from '../../../../UI/Loading'
import UIError from '../../../../UI/Error'
import UIPagination from '../../../../UI/Pagination'
import UITable from '../../../../UI/Table'
import UITableBody from '../../../../UI/Table/Body'
import UITableHead from '../../../../UI/Table/Head'

// Queries
import { RECEIVE_SAMPLE, LIST_ENTERED_SAMPLES } from './queries'

// Mutation cache update
import { receiveSampleUpdate } from './cache'

const SampleContainerReciptProgrammed = ({ onIdChange }) => {
  const [{ me }] = useGlobal()

  // Pagination
  const [pagination, setPaginationState] = useState({ limit: 25, page: 1 })

  // --------------------------------------------------------------------------
  //
  // Query: Entered samples
  //
  // --------------------------------------------------------------------------

  // NOTE The 'id_client' variable is there to include a random variable
  // otherwise it ignores 'network-only' and uses the cache
  //
  // Related bug:
  // https://github.com/apollographql/react-apollo/issues/3315
  const { data, loading } = useQuery(LIST_ENTERED_SAMPLES, {
    fetchPolicy: 'network-only',
    variables: { ...pagination, noise: me.id_laboratory }, // noise to the query
    onSuccess: ({ pagination }) => {
      setPaginationState((prev) => ({ ...prev, ...pagination }))
    },
    onErrorMessage: `No se pudo obtener la lista de muestras.`,
  })

  // --------------------------------------------------------------------------
  //
  // Mutation: Receive sample
  //
  // --------------------------------------------------------------------------

  const [t3ReceiveSample] = useMutation(RECEIVE_SAMPLE, {
    // update: (cache, { data }) => receiveSampleUpdate(cache, data, pagination),
    update: (cache, { data: { t3ReceiveSample } }) => {
      const id = parseInt(t3ReceiveSample.data)

      const { t3SamplesByStage: samples } = cache.readQuery({
        query: LIST_ENTERED_SAMPLES,
        variables: { ...pagination },
      })

      cache.writeQuery({
        query: LIST_ENTERED_SAMPLES,
        variables: { ...pagination },
        data: {
          t3SamplesByStage: {
            ...samples,
            data: samples.data.filter((e) => e.sample.id !== id),
          },
        },
      })
    },
    onSuccessMessage: 'La muestra fue recepcionada con éxito',
    onSuccess: ({ id }) => onIdChange(id),
    onErrorMessage: `La muestra no pudo ser recepcionada`,
  })

  // --------------------------------------------------------------------------
  //
  // Render
  //
  // --------------------------------------------------------------------------

  // Loading
  if (loading) return <UILoading />

  // Data
  if (data && data.t3SamplesByStage) {
    const { data: samples, pagination } = data.t3SamplesByStage

    const handleReceive = (id) => {
      t3ReceiveSample({ variables: { id } })
    }

    const handlePageChange = (page) => {
      setPaginationState((prev) => ({ ...prev, ...pagination, page }))
    }

    // Main table with the sample list
    const TableWithSamples = (
      <>
        <UITable>
          <UITableHead>
            {[
              'Cliente',
              'Faena',
              'Equipo',
              'TAG',
              'Correlativo',
              'Fecha Ingreso',
              'Prioridad',
            ]}
          </UITableHead>
          <UITableBody>
            {samples.map(
              ({ client, site, machine, component, sample, priority }) => (
                <tr key={sample.id}>
                  <td>{client.name}</td>
                  <td>{site.name}</td>
                  <td>{machine.name}</td>
                  <td>{component.tag}</td>
                  <td>
                    <button
                      style={{
                        backgroundColor: 'rgb(0,0,0,0)',
                        border: 'none',
                      }}
                      className={`text-decoration-none ${
                        priority.id === 2 ? 'text-danger' : 'text-stng-darker'
                      }`}
                      onClick={() => handleReceive(sample.id)}
                    >
                      {sample.id}
                    </button>
                  </td>
                  <td>
                    {sample.date_entry &&
                      moment(sample.date_entry).format('DD/MM/YYYY')}
                  </td>
                  <td>
                    <Badge variant={priority.id === 2 ? 'danger' : 'stng'}>
                      {priority.name}
                    </Badge>
                  </td>
                </tr>
              ),
            )}
          </UITableBody>
        </UITable>
        <UIPagination pagination={pagination} onClick={handlePageChange} />
      </>
    )

    // Message with empty results
    const TableWithoutSamples = (
      <UIEmpty message="No hay nuevas muestras ingresadas" />
    )

    return samples.length > 0 ? TableWithSamples : TableWithoutSamples
  }

  // Error
  return <UIError />
}
export default SampleContainerReciptProgrammed
