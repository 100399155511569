import React from 'react'

// Components
import ProfileContainer from '../../components/Profile/Container'

const Profile = ({ auth }) => {
  return <ProfileContainer />
}

export default Profile
