import React from 'react'

// Hooks
import { useGlobal } from '../../../../../hooks'

import { AdministrationList } from '../../../List'

// Queries
import { DELETE_CLIENTS, LIST_CLIENTS } from './queries'

const table = [
  { th: 'ID', td: 'id' },
  { th: 'RUT', td: { pk1: 'rut', pk2: 'dv' } },
  { th: 'Razón social', td: 'registeredName' },
]

const queries = { list: LIST_CLIENTS, delete: DELETE_CLIENTS }

const AdministrationListClientClients = () => {
  const [{ me }] = useGlobal()

  const no = ![1, 2].includes(me.id_role)

  return (
    <AdministrationList
      table={table}
      queries={queries}
      noCreate={no}
      noUpdate={no}
      noDelete={no}
    />
  )
}

export default AdministrationListClientClients
