import React from 'react'

// Components
import AdministrationListLubricantChangeRefillMotives from '../../../../Administration/List/Lubricant/ChangeRefillMotives'
import AdministrationInfoLubricantChangeRefillMotives from '../../../../Administration/Info/Lubricant/ChangeRefillMotives'
import AdministrationFormLubricantChangeRefillMotives from '../../../../Administration/Form/Lubricant/ChangeRefillMotives'

import { AdministrationContainer } from '../..'

const AdministrationContainerLubricantChangeRefillMotives = () => (
  <AdministrationContainer
    form={AdministrationFormLubricantChangeRefillMotives}
    info={AdministrationInfoLubricantChangeRefillMotives}
    list={AdministrationListLubricantChangeRefillMotives}
  />
)

export default AdministrationContainerLubricantChangeRefillMotives
