import React, { forwardRef } from 'react'
import ReactDOMServer from 'react-dom/server'
// import { Tabs, Tab, Row, Col } from 'react-bootstrap'
import { Form, Col, Row } from 'react-bootstrap'
import moment from 'moment'
import Chartjs from 'chart.js'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
  Font,
  PDFDownloadLink,
} from '@react-pdf/renderer'

// Hooks
import {
  useMemo,
  useRef,
  useState,
  useEffect,
} from '../../../../../../../hooks'

// Components
import UIButton from '../../../../../../UI/Button'

// https://github.com/diegomura/react-pdf/issues/608

import GraphsPieSmall from './Graphs/Pie/Small'
import GraphsPieBig from './Graphs/Pie/Big'
import GraphsSamples from './Graphs/Samples'

import config from '../../../../../../../config'

const { AWS } = config

// import Samples from './samples'

const ReportPDF = ({ data, onClick, onLoading }) => {
  let { info, results, samples } = data

  const [isReady, setIsReady] = useState()

  const graphs = useRef({})
  const graph = useRef()
  const sampleGraphs = useRef({})

  //  const forceUpdate = useForceUpdate()

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsReady(true)
    }, 500)
    return () => clearTimeout(timer)
  }, [])

  // Font.register({
  //   family: 'Oswald',
  //   src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
  // })

  // Font.register({
  //   family: 'Roboto',
  //   fonts: [
  //     { src: '/Roboto-Regular.ttf' },
  //     { src: '/Roboto-Bold.ttf', fontWeight: 'bold' },
  //     { src: '/Roboto-Black.ttf', fontWeight: '900' },
  //   ],
  // })

  const styles = StyleSheet.create({
    page: {
      padding: 20,
      fontFamily: 'Roboto',
      fontSize: 7,
    },
    header: {
      color: 'orange',
      fontSize: 30,
      fontWeight: 'bold',
      paddingLeft: 10,
    },
    subHeader: {
      fontSize: 10,
      fontWeight: 'bold',
      paddingLeft: 10,
    },
    flexRow: {
      flex: 1,
      flexDirection: 'row',
      // fontSize: 10,
    },
    samples: {
      page: {
        paddingHorizontal: 20,
        paddingVertical: 10,
        margin: 0,
        fontFamily: 'Roboto',
        fontSize: 6,
      },
      header: {},
      flexRow: {
        flex: 1,
        flexDirection: 'row',
      },
      footer: {
        paddingHorizontal: 20,
        position: 'absolute',
        bottom: 10,
        left: 0,
        right: 0,
        textAlign: 'center',
      },
      isoLogo: {
        position: 'absolute',
        bottom: 0,
        left: 15,
      },
      separator: {
        width: '100%',
        backgroundColor: '#364f6b',
        paddingVertical: 0.5,
        marginVertical: 2,
      },
      table: {
        display: 'table',
        width: 'auto',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
      },
      tableRow: { margin: 'auto', flexDirection: 'row' },
      tableCol: {
        width: '15%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
      },
      tableCell: {
        marginRight: 'auto',
        marginLeft: 3,
        marginTop: 1.5,
        marginBottom: 1.5,
      },
      graphTitle: {
        marginLeft: 5,
        marginTop: 0,
        marginBottom: 0,
        fontSize: 9,
      },
      graph: {
        marginLeft: 5,
        // marginVertical: 5,
        // padding: 5,
        width: '100%',
        height: 90,
        // borderStyle: 'solid',
        // borderWidth: 1,
        // borderColor: '#CCCCCC',
      },
    },
  })

  const imageLogo = '/imgs/logo/report/centinela.png'
  const imageSignature = '/imgs/signatures/claudio_macaya.png'

  const imageApplusLogo = '/imgs/logo/report/applus_iso_9001-small.png'

  // --------------------------------------------------------------------------
  //
  // Graph
  //
  // --------------------------------------------------------------------------

  const Graph = () => {
    const count = [0, 0, 0]

    results.forEach((result) => {
      count[0] += result.count_normal
      count[1] += result.count_alerta
      count[2] += result.count_anormal
    })

    const total = count[0] + count[1] + count[2]

    const label_normal = parseFloat(((count[0] / total) * 100).toFixed(1))
    const label_alerta = parseFloat(((count[1] / total) * 100).toFixed(1))
    const label_anormal = parseFloat(((count[2] / total) * 100).toFixed(1))

    return (
      <View
        key={results.name}
        style={{
          textAlign: 'center',
          flex: '1 0 100%',
          width: '100%',
          margin: 0,
          padding: '10 10',
        }}
      >
        <Text style={{ fontSize: 10, borderBottom: '1pt solid gray' }}>
          Resumen global
        </Text>
        <View style={{ flexDirection: 'row', marginTop: 5 }}>
          <View style={{ flex: '1 0 100%', width: '100%', height: 200 }}>
            {graph.current ? (
              <Image
                style={{
                  width: 'auto',
                }}
                src={`${graph.current}`}
              />
            ) : (
              <Text style={{ textAlign: 'right' }}>cargando</Text>
            )}
          </View>
        </View>
        <Text style={{ fontSize: 7, marginTop: 15 }}>
          Normal: {label_normal}% <Text style={{ color: 'gray' }}> | </Text>{' '}
          Alerta: {label_alerta}% <Text style={{ color: 'gray' }}> | </Text>{' '}
          Crítico: {label_anormal}%
        </Text>
      </View>
    )
  }

  // --------------------------------------------------------------------------
  //
  // Graphs
  //
  // --------------------------------------------------------------------------

  const Graphs = () => {
    return (
      <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
        {results.map((result, index) => {
          const { id, name, count_normal, count_alerta, count_anormal } = result

          const total = count_normal + count_alerta + count_anormal

          const label_normal = parseFloat(
            ((count_normal / total) * 100).toFixed(1),
          )
          const label_alerta = parseFloat(
            ((count_alerta / total) * 100).toFixed(1),
          )
          const label_anormal = parseFloat(
            ((count_anormal / total) * 100).toFixed(1),
          )

          return (
            <View
              key={`graphs-${index}-${name}`}
              style={{
                flex: '1 0 50%',
                width: '50%',
                textAlign: 'center',
                margin: 0,
                padding: '10 5',
              }}
            >
              <Text style={{ fontSize: 10, borderBottom: '1pt solid gray' }}>
                {name}
              </Text>
              <View style={{ flexDirection: 'row', marginTop: 5 }}>
                <View style={{ flex: '1 0 50%', width: '50%', height: 60 }}>
                  {graphs.current && graphs.current[id] ? (
                    <Image
                      style={{
                        width: 'auto',
                      }}
                      src={`${graphs.current[id]}`}
                    />
                  ) : (
                    <Text style={{ textAlign: 'right' }}>cargando ...</Text>
                  )}
                </View>
                <View style={{ flex: '1 0 50%', width: '50%' }}>
                  <Image
                    style={{
                      width: 'auto',
                    }}
                    src="/camion.jpg"
                  />
                </View>
              </View>
              <Text style={{ fontSize: 7, marginTop: 5 }}>
                Normal: {label_normal}%{' '}
                <Text style={{ color: 'gray' }}> | </Text> Alerta:{' '}
                {label_alerta}% <Text style={{ color: 'gray' }}> | </Text>{' '}
                Crítico: {label_anormal}%
              </Text>
            </View>
          )
        })}
      </View>
    )
  }

  // --------------------------------------------------------------------------
  //
  // Samples graphs
  //
  // --------------------------------------------------------------------------

  // Graph 1
  //
  // Viscosidad 100ºC
  // Fierro
  // Viscosidad 40ºC
  // Viscosidad 100ºC
  //
  // Graph 2
  //
  // Fierro
  // Cobre
  // Fierro
  // Fierro
  //
  // Graph 3
  //
  // Plomo
  // Silicio
  // Cobre
  // Cobre
  //
  // Graph 4
  //
  // Cobre
  // Zinc/Calcio/Fosforo
  // Silicio
  // Silicio
  //
  // Graph 5
  //
  // Silicio
  // %anticongelantes
  // Fatica(ID56)/Corte(ID57)/No metalica(ID58)/ Deslizante(ID59)
  // Fatica(ID56)/Corte(ID57)/No metalica(ID58)/ Deslizante(ID59)
  //
  // Graph 6
  //
  // Sodio/Potasio
  // Cogido ISO (clases que concatenen los dos ultimos valores)
  // Cogido ISO (clases que concatenen los dos ultimos valores)

  const SampleGraphs = ({ id, type }) => {
    const names = [1, '1'].includes(type)
      ? [
          'Viscosidad 100ºC',
          'Desgaste Fierro',
          'Desgaste Plomo',
          'Desgaste Cobre',
          'Silicio',
          'Sodio/Potasio',
        ]
      : [2, '2'].includes(type)
      ? [
          'Desgaste Fierro',
          'Desgaste Cobre',
          'Silicio',
          'Zinc/Calcio/Fósforo',
          '% Anticongelantes',
        ]
      : [3, '3'].includes(type)
      ? [
          'Viscosidad 40ºC',
          'Desgaste Fierro',
          'Desgaste Cobre',
          'Silicio',
          'Tendencia desgaste',
          'Código ISO',
        ]
      : [4, '4'].includes(type)
      ? [
          'Viscosidad 100ºC',
          'Desgaste Fierro',
          'Desgaste Cobre',
          'Silicio',
          'Tendencia desgaste',
          'Código ISO',
        ]
      : []

    return (
      <>
        <View>
          <Text style={styles.samples.graphTitle}>{names[0]}</Text>
          <View style={styles.samples.graph}>
            {sampleGraphs.current &&
            sampleGraphs.current[id] &&
            sampleGraphs.current[id][0] ? (
              <Image src={`${sampleGraphs.current[id][0]}`} />
            ) : (
              <Text>cargando ...</Text>
            )}
          </View>
        </View>
        <View>
          <Text style={styles.samples.graphTitle}>{names[1]}</Text>
          <View style={styles.samples.graph}>
            {sampleGraphs.current &&
            sampleGraphs.current[id] &&
            sampleGraphs.current[id][1] ? (
              <Image src={`${sampleGraphs.current[id][1]}`} />
            ) : (
              <Text>cargando ...</Text>
            )}
          </View>
        </View>
        <View>
          <Text style={styles.samples.graphTitle}>{names[2]}</Text>
          <View style={styles.samples.graph}>
            {sampleGraphs.current &&
            sampleGraphs.current[id] &&
            sampleGraphs.current[id][2] ? (
              <Image src={`${sampleGraphs.current[id][2]}`} />
            ) : (
              <Text>cargando ...</Text>
            )}
          </View>
        </View>
        <View>
          <Text style={styles.samples.graphTitle}>{names[3]}</Text>
          <View style={styles.samples.graph}>
            {sampleGraphs.current &&
            sampleGraphs.current[id] &&
            sampleGraphs.current[id][3] ? (
              <Image src={`${sampleGraphs.current[id][3]}`} />
            ) : (
              <Text>cargando ...</Text>
            )}
          </View>
        </View>
        <View>
          <Text style={styles.samples.graphTitle}>{names[4]}</Text>
          <View style={styles.samples.graph}>
            {sampleGraphs.current &&
            sampleGraphs.current[id] &&
            sampleGraphs.current[id][4] ? (
              <Image src={`${sampleGraphs.current[id][4]}`} />
            ) : (
              <Text>cargando ...</Text>
            )}
          </View>
        </View>
        {type !== 2 && (
          <View>
            <Text style={styles.samples.graphTitle}>{names[5]}</Text>
            <View style={styles.samples.graph}>
              {sampleGraphs.current &&
              sampleGraphs.current[id] &&
              sampleGraphs.current[id][5] ? (
                <Image src={`${sampleGraphs.current[id][5]}`} />
              ) : (
                <Text>cargando ...</Text>
              )}
            </View>
          </View>
        )}
      </>
    )
  }

  // --------------------------------------------------------------------------
  //
  // Header
  //
  // --------------------------------------------------------------------------

  const Header = () => (
    <View>
      <Text style={styles.header}>Reporte de gestión</Text>
      {/*<Text style={styles.subHeader}>Cliente: {info.header.name}</Text>*/}
      <Text
        style={styles.subHeader}
      >{`Nivel: Componentes del Equipo --> ${info.header.machine}`}</Text>
      <Text style={styles.subHeader}>Tipo de emisión: {info.header.type}</Text>
      <Text style={styles.subHeader}>
        Periodo: {`Desde ${info.header.from} hasta ${info.header.to}`}
      </Text>
    </View>
  )

  const SampleHeader = ({
    client,
    site,
    responsible,
    component,
    machine,
    lubricant,
    sample,
  }) => (
    <View fixed style={styles.samples.header}>
      {/* Client info ------------------ */}
      <View style={styles.samples.flexRow}>
        <View
          style={{
            width: '38%',
          }}
        >
          <Image src={`${imageLogo}`} style={{ width: 100 }} />
        </View>
        <View
          style={{
            width: '8%',
            fontWeight: 'bold',
          }}
        >
          <Text>Cliente</Text>
          <Text>Faena</Text>
          <Text>Dirección</Text>
          <Text>Encargado</Text>
        </View>
        <View
          style={{
            width: '34%',
          }}
        >
          <Text>{client.name}</Text>
          <Text>{site.name}</Text>
          <Text>{client.address}</Text>
          <Text>{responsible}</Text>
        </View>
        <View
          style={{
            width: '20%',
            textAlign: 'right',
            fontWeight: 'bold',
          }}
        >
          <Text>Informe de Ensayo Nº{sample.id}</Text>
          <Text>Fecha {moment().format('DD-MM-YYYY')}</Text>
        </View>
      </View>
      <View style={styles.samples.separator} />
      {/* Sample info ------------------ */}
      <View style={styles.samples.flexRow}>
        <View
          style={{
            width: '12%',
            fontWeight: 'bold',
          }}
        >
          <Text>Componente</Text>
          <Text>Marca</Text>
          <Text>Modelo</Text>
          <Text>Lubricante en uso</Text>
          <Text>Capacidad sistema</Text>
        </View>
        <View
          style={{
            width: '26%',
          }}
        >
          <Text>{component.name}</Text>
          <Text>{component.brand}</Text>
          <Text>{component.model}</Text>
          <Text>{lubricant.name}</Text>
          <Text>{component.liters && `${parseInt(component.liters)} Lts`}</Text>
        </View>
        <View
          style={{
            width: '8%',
            fontWeight: 'bold',
          }}
        >
          <Text>Instalado en</Text>
          <Text>Marca</Text>
          <Text>Modelo</Text>
          <Text>TAG</Text>
        </View>
        <View
          style={{
            width: '26%',
          }}
        >
          <Text>{machine.name}</Text>
          <Text>{machine.brand}</Text>
          <Text>{machine.model}</Text>
          <Text>{component.tag}</Text>
        </View>
        <View
          style={{
            width: '15%',
            fontWeight: 'bold',
          }}
        >
          <Text>Condición</Text>
          <Text>Tipo anomalía</Text>
          <Text>N° muestra</Text>
          <Text>Punto muestreo</Text>
          {/*<Text>Procedimiento muestreo</Text>*/}
        </View>
        <View
          style={{
            width: '13%',
          }}
        >
          <Text
            style={{
              textAlign: 'center',
              backgroundColor:
                sample.condition === 'Anormal'
                  ? '#DC143C'
                  : sample.condition === 'Alerta'
                  ? '#FFD700	'
                  : '#9ACD32',
            }}
          >
            {sample.condition}
          </Text>
          <Text>{sample.anomalyType}</Text>
          <Text>{sample.id}</Text>
          <Text>{component.samplingPoint}</Text>
          {/*<Text>{component.samplingProcedure}</Text>*/}
        </View>
      </View>
      <View style={styles.samples.separator} />
    </View>
  )

  // --------------------------------------------------------------------------
  //
  // Signature
  //
  // --------------------------------------------------------------------------

  const SampleSignature = () => (
    <View style={{ marginTop: 40, marginLeft: 60 }}>
      <Image src={imageSignature} style={{ width: 80 }} />
    </View>
  )

  // --------------------------------------------------------------------------
  //
  // Sample Table
  //
  // --------------------------------------------------------------------------

  const SampleTable = ({ results, sample }) => (
    <>
      <View style={styles.samples.table}>
        {/* Date sampling */}
        <View style={styles.samples.tableRow}>
          <View style={[styles.samples.tableCol, { width: '63%' }]}>
            <Text style={styles.samples.tableCell}>Fecha muestreo</Text>
          </View>
          <View style={[styles.samples.tableCol, { width: '11%' }]}>
            <Text style={[styles.samples.tableCell, { margin: 'auto' }]}>
              {results.info.reference.dateSampling}
            </Text>
          </View>
          <View style={[styles.samples.tableCol, { width: '13%' }]}>
            <Text style={[styles.samples.tableCell, { margin: 'auto' }]}>
              {results.info.previous.dateSampling}
            </Text>
          </View>
          <View style={[styles.samples.tableCol, { width: '13%' }]}>
            <Text style={[styles.samples.tableCell, { margin: 'auto' }]}>
              {results.info.current.dateSampling}
            </Text>
          </View>
        </View>
        {/* Date receive */}
        <View style={styles.samples.tableRow}>
          <View style={[styles.samples.tableCol, { width: '63%' }]}>
            <Text style={styles.samples.tableCell}>Fecha recepción</Text>
          </View>
          <View style={[styles.samples.tableCol, { width: '11%' }]}>
            <Text style={[styles.samples.tableCell, { margin: 'auto' }]}>
              {results.info.reference.dateReceive}
            </Text>
          </View>
          <View style={[styles.samples.tableCol, { width: '13%' }]}>
            <Text style={[styles.samples.tableCell, { margin: 'auto' }]}>
              {results.info.previous.dateReceive}
            </Text>
          </View>
          <View style={[styles.samples.tableCol, { width: '13%' }]}>
            <Text style={[styles.samples.tableCell, { margin: 'auto' }]}>
              {results.info.current.dateReceive}
            </Text>
          </View>
        </View>
        {/* Date report */}
        <View style={styles.samples.tableRow}>
          <View style={[styles.samples.tableCol, { width: '63%' }]}>
            <Text style={styles.samples.tableCell}>Fecha informe</Text>
          </View>
          <View style={[styles.samples.tableCol, { width: '11%' }]}>
            <Text style={[styles.samples.tableCell, { margin: 'auto' }]}>
              {results.info.reference.dateReport}
            </Text>
          </View>
          <View style={[styles.samples.tableCol, { width: '13%' }]}>
            <Text style={[styles.samples.tableCell, { margin: 'auto' }]}>
              {results.info.previous.dateReport}
            </Text>
          </View>
          <View style={[styles.samples.tableCol, { width: '13%' }]}>
            <Text style={[styles.samples.tableCell, { margin: 'auto' }]}>
              {results.info.current.dateReport}
            </Text>
          </View>
        </View>
        {/* Machine continunuity */}
        <View style={styles.samples.tableRow}>
          <View style={[styles.samples.tableCol, { width: '63%' }]}>
            <Text style={styles.samples.tableCell}>Horas / Km Equipo</Text>
          </View>
          <View style={[styles.samples.tableCol, { width: '11%' }]}>
            <Text style={[styles.samples.tableCell, { margin: 'auto' }]}>
              {results.info.reference.machineContinuity}
            </Text>
          </View>
          <View style={[styles.samples.tableCol, { width: '13%' }]}>
            <Text style={[styles.samples.tableCell, { margin: 'auto' }]}>
              {results.info.previous.machineContinuity}
            </Text>
          </View>
          <View style={[styles.samples.tableCol, { width: '13%' }]}>
            <Text style={[styles.samples.tableCell, { margin: 'auto' }]}>
              {results.info.current.machineContinuity}
            </Text>
          </View>
        </View>
        {/* Component continunuity */}
        <View style={styles.samples.tableRow}>
          <View style={[styles.samples.tableCol, { width: '63%' }]}>
            <Text style={styles.samples.tableCell}>Horas / Km Componente</Text>
          </View>
          <View style={[styles.samples.tableCol, { width: '11%' }]}>
            <Text style={[styles.samples.tableCell, { margin: 'auto' }]}>
              {results.info.reference.componentContinuity}
            </Text>
          </View>
          <View style={[styles.samples.tableCol, { width: '13%' }]}>
            <Text style={[styles.samples.tableCell, { margin: 'auto' }]}>
              {results.info.previous.componentContinuity}
            </Text>
          </View>
          <View style={[styles.samples.tableCol, { width: '13%' }]}>
            <Text style={[styles.samples.tableCell, { margin: 'auto' }]}>
              {results.info.current.componentContinuity}
            </Text>
          </View>
        </View>
        {/* Lubricant continuity */}
        <View style={styles.samples.tableRow}>
          <View style={[styles.samples.tableCol, { width: '63%' }]}>
            <Text style={styles.samples.tableCell}>Horas / Km lubricante</Text>
          </View>
          <View style={[styles.samples.tableCol, { width: '11%' }]}>
            <Text style={[styles.samples.tableCell, { margin: 'auto' }]}>
              {results.info.reference.lubricantContinuity}
            </Text>
          </View>
          <View style={[styles.samples.tableCol, { width: '13%' }]}>
            <Text style={[styles.samples.tableCell, { margin: 'auto' }]}>
              {results.info.previous.lubricantContinuity}
            </Text>
          </View>
          <View style={[styles.samples.tableCol, { width: '13%' }]}>
            <Text style={[styles.samples.tableCell, { margin: 'auto' }]}>
              {results.info.current.lubricantContinuity}
            </Text>
          </View>
        </View>
        {/* --------------- header --------------- */}
        <View style={[styles.samples.tableRow, { backgroundColor: '#F5F5DC' }]}>
          <View style={[styles.samples.tableCol, { width: '29%' }]}>
            <Text style={styles.samples.tableCell}>Ensayo</Text>
          </View>
          <View style={[styles.samples.tableCol, { width: '14%' }]}>
            <Text style={styles.samples.tableCell}>Método análisis</Text>
          </View>
          <View style={[styles.samples.tableCol, { width: '10%' }]}>
            <Text style={[styles.samples.tableCell, { margin: 'auto' }]}>
              Alerta
            </Text>
          </View>
          <View style={[styles.samples.tableCol, { width: '10%' }]}>
            <Text style={[styles.samples.tableCell, { margin: 'auto' }]}>
              Máximo
            </Text>
          </View>
          <View style={[styles.samples.tableCol, { width: '11%' }]}>
            <Text style={[styles.samples.tableCell, { margin: 'auto' }]}>
              Referencia
            </Text>
          </View>
          <View style={[styles.samples.tableCol, { width: '13%' }]}>
            <Text style={styles.samples.tableCell}>
              Correlativo {results.info.previous.relativeId}
            </Text>
          </View>
          <View style={[styles.samples.tableCol, { width: '13%' }]}>
            <Text style={styles.samples.tableCell}>
              Correlativo {results.info.current.relativeId}
            </Text>
          </View>
        </View>
        {Object.keys(results.essays).map((key, index) => (
          <View key={`essay_class_${index}`}>
            <View
              style={[styles.samples.tableRow, { backgroundColor: '#F5F5DC' }]}
            >
              <View style={[styles.samples.tableCol, { width: '100%' }]}>
                <Text style={styles.samples.tableCell}>{key}</Text>
              </View>
            </View>
            {results.essays[key].map((essay, index) => (
              <View style={styles.samples.tableRow} key={`essay_${index}`}>
                <View style={[styles.samples.tableCol, { width: '29%' }]}>
                  <Text style={styles.samples.tableCell}>{essay.essay}</Text>
                </View>
                <View style={[styles.samples.tableCol, { width: '14%' }]}>
                  <Text style={styles.samples.tableCell}>
                    {essay.analysis_method}
                  </Text>
                </View>
                <View style={[styles.samples.tableCol, { width: '10%' }]}>
                  <Text style={[styles.samples.tableCell, { margin: 'auto' }]}>
                    {essay.min}
                  </Text>
                </View>
                <View style={[styles.samples.tableCol, { width: '10%' }]}>
                  <Text style={[styles.samples.tableCell, { margin: 'auto' }]}>
                    {essay.max}
                  </Text>
                </View>
                <View style={[styles.samples.tableCol, { width: '11%' }]}>
                  <Text style={[styles.samples.tableCell, { margin: 'auto' }]}>
                    {essay.value_ref}
                  </Text>
                </View>
                <View style={[styles.samples.tableCol, { width: '13%' }]}>
                  <Text style={[styles.samples.tableCell, { margin: 'auto' }]}>
                    {essay.value_prev}
                  </Text>
                </View>
                <View style={[styles.samples.tableCol, { width: '13%' }]}>
                  <Text style={[styles.samples.tableCell, { margin: 'auto' }]}>
                    {essay.value}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        ))}
      </View>
      <Text style={{ fontWeight: 'bold' }}>Recomendación</Text>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: '100%' }]}>
            <Text style={[styles.tableCell, { margin: '2 3' }]}>
              {sample.recommendation}
            </Text>
          </View>
        </View>
      </View>
    </>
  )

  // --------------------------------------------------------------------------
  //
  // Sample Test
  //
  // --------------------------------------------------------------------------

  const SampleTests = ({ results, laboratory }) => {
    if (
      (results.patch && results.patch.name) ||
      (results.magnetic && results.magnetic.name) ||
      (results.ferrography && results.ferrography.name)
    ) {
      return (
        <View break>
          {results.patch && results.patch.name ? (
            <View style={[styles.samples.flexRow, { marginTop: 7 }]}>
              <View
                style={{
                  width: '40%',
                }}
              >
                <Text style={{ fontSize: 12, fontWeight: 'bold' }}>
                  Test de parche
                </Text>
                <Text
                  style={{
                    padding: '10 15',
                    marginTop: 20,
                    marginRight: 15,
                    textAlign: 'justify',
                    textJustify: 'inter-word',
                    backgroundColor: 'gainsboro',
                  }}
                >
                  {results.patch.comment
                    ? results.patch.comment
                    : 'Sin comentarios'}
                </Text>
              </View>
              <View
                style={{
                  width: '60%',
                  maxHeight: '250px',
                }}
              >
                <Image
                  style={{
                    objectFit: 'contain',
                    padding: 2,
                    marginRight: 'auto',
                  }}
                  src={{
                    uri: `https://s3-sa-east-1.amazonaws.com/${AWS.BUCKET}/${laboratory}/results/images/${results.patch.name}`,
                    method: 'GET',
                    body: '',
                    headers: '',
                  }}
                />
              </View>
            </View>
          ) : null}
          {results.magnetic && results.magnetic.name ? (
            <View style={[styles.samples.flexRow, { marginTop: 7 }]}>
              <View
                style={{
                  width: '40%',
                }}
              >
                <Text style={{ fontSize: 12, fontWeight: 'bold' }}>
                  Tapón magnético
                </Text>
                <Text
                  style={{
                    borderRight: '1 solid grey',
                    padding: '10 15',
                    marginTop: 20,
                    marginRight: 15,
                    textAlign: 'justify',
                    textJustify: 'inter-word',
                  }}
                >
                  {results.magnetic.comment
                    ? results.magnetic.comment
                    : 'Sin comentarios'}
                </Text>
              </View>
              <View
                style={{
                  width: '60%',
                  maxHeight: '250px',
                }}
              >
                <Image
                  style={{
                    objectFit: 'contain',
                    padding: 2,
                    marginRight: 'auto',
                  }}
                  src={{
                    uri: `https://s3-sa-east-1.amazonaws.com/${AWS.BUCKET}/${laboratory}/results/images/${results.magnetic.name}`,
                    method: 'GET',
                    body: '',
                    headers: '',
                  }}
                />
              </View>
            </View>
          ) : null}
          {results.ferrography && results.ferrography.name ? (
            <View style={[styles.samples.flexRow, { marginTop: 7 }]}>
              <View
                style={{
                  width: '40%',
                }}
              >
                <Text style={{ fontSize: 12, fontWeight: 'bold' }}>
                  Imagen ferromagnética
                </Text>
                <Text
                  style={{
                    borderRight: '1 solid grey',
                    padding: '10 15',
                    marginTop: 20,
                    marginRight: 15,
                    textAlign: 'justify',
                    textJustify: 'inter-word',
                  }}
                >
                  {results.ferrography.comment
                    ? results.ferrography.comment
                    : 'Sin comentarios'}
                </Text>
              </View>
              <View
                style={{
                  width: '60%',
                  maxHeight: '250px',
                }}
              >
                <Image
                  style={{
                    objectFit: 'contain',
                    padding: 2,
                    marginRight: 'auto',
                  }}
                  src={{
                    uri: `https://s3-sa-east-1.amazonaws.com/${AWS.BUCKET}/${laboratory}/results/images/${results.ferrography.name}`,
                    method: 'GET',
                    body: '',
                    headers: '',
                  }}
                />
              </View>
            </View>
          ) : null}
        </View>
      )
    }

    return <></>
  }

  const Footer = () => (
    <View fixed style={styles.samples.footer}>
      <View style={styles.separator} />
      <Text>SERVICIOS TRIBOLOGICOS S.A.</Text>
      <Text>José Francisco Blumell 215, Antofagasta - Chile, www.stng.cl</Text>
      <Text style={{ paddingTop: 5, paddingLeft: 30 }}>
        La información en el presente informe constituye el resultado de un
        ensayo, acotado únicamente a las muestras analizadas, lo que no permite
        al solicitante reproducir en ninguna forma el logo, nombre o marca
        registrada de SERVICIOS TRIBOLOGICOS, salvo que exista una autorización
        previa y por escrito de SERVICIOS TRIBOLOGICOS
      </Text>
      <View fixed style={styles.samples.isoLogo}>
        <Image src={`${imageApplusLogo}`} style={{ width: '30px' }} />
      </View>
    </View>
  )

  //--------------------------------------------------------------------------
  //
  // Sample
  //
  // --------------------------------------------------------------------------

  const Sample = ({ data }) => {
    // client | name address
    // site
    // responsible
    // sample | id condition state suggestion date_proposed
    // component | name brand model liters tag samplingProcedure samplingPoint
    // machine | name brand model:
    // lubricant
    // laboratory
    // results: {
    //    info | reference previous current
    //    essays: {},
    //    patch |  name  comment
    //    magnetic |  name  comment
    //    ferrography | name  comment
    // }
    // graphs

    const {
      results,
      laboratory,
      sample,
      client,
      site,
      responsible,
      component,
      machine,
      lubricant,
      type,
    } = data

    return (
      <Page size="A4" style={styles.samples.page}>
        <SampleHeader
          client={client}
          site={site}
          responsible={responsible}
          component={component}
          machine={machine}
          lubricant={lubricant}
          sample={sample}
        />
        <View style={[styles.samples.flexRow, { marginTop: 3 }]}>
          <View
            style={{
              width: '70%',
            }}
          >
            <SampleTable results={results} sample={sample} />
          </View>
          <View
            style={{
              width: '30%',
            }}
          >
            <SampleGraphs id={sample.id} type={type.id} />
            {/*<SampleSignature />*/}
          </View>
        </View>
        <SampleTests results={results} laboratory={laboratory} />
        {/*<View fixed style={styles.isoLogo}>
          <Image
            src={`${imageApplusLogo}`}
            style={{ width: '30px', paddingBottom: '10px' }}
          />
        </View>]*/}
        <Footer />
      </Page>
    )
  }

  //--------------------------------------------------------------------------
  //
  // Samples
  //
  // --------------------------------------------------------------------------

  const Samples = () =>
    Object.keys(samples).map((id) => (
      <Sample key={`sample-${id}`} data={samples[id]} />
    ))

  // --------------------------------------------------------------------------
  //
  // Report document
  //
  // --------------------------------------------------------------------------

  const Report = () => (
    <Document title={`Reporte de Gestión`} author={`Servicios Tribológicos`}>
      <Page size="A4" style={styles.page}>
        <Header />
        <View style={{ marginTop: 10, width: '100%', flexDirection: 'row' }}>
          <View
            style={{
              flex: '1 0 40%',
              width: '40%',
            }}
          >
            <Graph />
          </View>
          <View
            style={{
              flex: '1 0 60%',
              width: '60%',
            }}
          >
            <Graphs />
          </View>
        </View>
      </Page>
      <Samples />
    </Document>
  )

  // --------------------------------------------------------------------------
  //
  // Render report download button
  //
  // --------------------------------------------------------------------------

  const fileName = []

  fileName.push(info.header.emission_type)
  if (info.breadCrumbs.client_id) fileName.push(info.breadCrumbs.client_id)
  if (info.breadCrumbs.site_id) fileName.push(info.breadCrumbs.site_id)
  if (info.breadCrumbs.machine_type_id)
    fileName.push(info.breadCrumbs.machine_type_id)
  if (info.breadCrumbs.machine_id) fileName.push(info.breadCrumbs.machine_id)
  fileName.push(info.header.from)
  fileName.push(info.header.to)

  return useMemo(
    () => (
      <>
        {results.map((result) => (
          <GraphsPieSmall key={result.name} data={result} ref={graphs} />
        ))}
        <GraphsPieBig data={results} ref={graph} />
        {Object.keys(samples).map((id, index) => {
          return (
            <GraphsSamples
              key={`sample-graph-${id}-${index}`}
              id={id}
              type={samples[id].type.id}
              data={samples[id].graphs}
              ref={sampleGraphs}
            />
          )
        })}
        {isReady ? (
          <PDFDownloadLink document={<Report />} fileName={fileName.join('_')}>
            {({ blob, url, loading, error }) => {
              if (loading)
                return (
                  <UIButton disable variant="secondary" className="mx-1">
                    Creando PDF (Nivel siguiente) ...
                  </UIButton>
                )

              onLoading(false)
              return (
                <UIButton onClick={onClick} className="mx-1">
                  Descargar PDF (Nivel siguiente)
                </UIButton>
              )
            }}
          </PDFDownloadLink>
        ) : (
          <UIButton disable variant="secondary" className="mx-1">
            Creando PDF (Nivel siguiente) ...
          </UIButton>
        )}
      </>
    ),
    [isReady],
  )
}

export default ReportPDF
