import { forwardRef } from 'react'
import React from 'react'
import Chartjs from 'chart.js'

// Hooks
import { useRef, useEffect } from '../../../../../../../../../hooks'

import Graph1 from './graph1'
import Graph2 from './graph2'
import Graph3 from './graph3'
import Graph4 from './graph4'
import Graph5 from './graph5'
import Graph6 from './graph6'

const config = {
  main0: {
    backgroundColor: `rgba(75, 192, 192, 0.1)`,
    borderColor: `rgb(75, 192, 192)`,
    borderWidth: 3,
    lineTension: 0.1,
    fill: true,
    pointRadius: 4,
  },
  main1: {
    backgroundColor: `rgba(192, 75, 75, 0.1)`,
    borderColor: `rgb(192, 192, 75)`,
    borderWidth: 3,
    lineTension: 0.1,
    fill: true,
    pointRadius: 4,
  },
  main2: {
    backgroundColor: `rgba(106, 205, 94, 0.1)`,
    borderColor: `rgb(106, 205, 94)`,
    borderWidth: 3,
    lineTension: 0.1,
    fill: true,
    pointRadius: 4,
  },
  main3: {
    backgroundColor: `rgba(255, 91, 43, 0.1)`,
    borderColor: `rgb(255, 91, 43)`,
    borderWidth: 3,
    lineTension: 0.1,
    fill: true,
    pointRadius: 4,
  },
  lsc: {
    borderColor: 'rgb(255, 0, 0)',
    borderWidth: 3,
    fill: false,
    type: 'line',
  },
  lsm: {
    borderColor: 'rgb(255, 173, 51)',
    borderWidth: 3,
    fill: false,
    type: 'line',
  },
  lim: {
    borderColor: 'rgb(255, 173, 51)',
    borderWidth: 3,
    fill: false,
    type: 'line',
  },
  lic: {
    borderColor: 'rgb(255, 0, 0)',
    borderWidth: 3,
    fill: false,
    type: 'line',
  },
  options: {
    animation: {
      duration: 0,
    },
    legend: {
      display: true,
      labels: {
        fontSize: 35,
        padding: 10,
      },
    },
    scales: {
      xAxes: [{ ticks: { fontSize: 35 }, padding: 10 }],
      yAxes: [
        {
          scaleLabel: {
            display: true,
          },
          beginAtZero: false,
          ticks: {
            fontSize: 35,
            padding: 10,
          },
        },
      ],
    },
  },
}

const GraphsSamples = forwardRef(({ id, data, type }, ref) => {
  const image1 = useRef()
  const image2 = useRef()
  const image3 = useRef()
  const image4 = useRef()
  const image5 = useRef()
  const image6 = useRef()

  useEffect(() => {
    Graph1({ ref: image1, graphs: data[1], config, type })
    Graph2({ ref: image2, graphs: data[2], config, type })
    Graph3({ ref: image3, graphs: data[3], config, type })
    Graph4({ ref: image4, graphs: data[4], config, type })
    Graph5({ ref: image5, graphs: data[5], config, type })
    Graph6({ ref: image6, graphs: data[6], config, type })

    // I know, I know ... if using once Timeout wasn't horrible enough
    const timer = setTimeout(() => {
      ref.current[id] = [
        image1.current.toDataURL(),
        image2.current.toDataURL(),
        image3.current.toDataURL(),
        image4.current.toDataURL(),
        image5.current.toDataURL(),
        image6.current.toDataURL(),
      ]
    }, 25)
    return () => clearTimeout(timer)
  }, [data])

  return (
    <>
      <canvas
        style={{ display: 'none' }}
        ref={image1}
        width="130px"
        height="100px"
      />
      <canvas
        style={{ display: 'none' }}
        ref={image2}
        width="130px"
        height="100px"
      />
      <canvas
        style={{ display: 'none' }}
        ref={image3}
        width="130px"
        height="100px"
      />
      <canvas
        style={{ display: 'none' }}
        ref={image4}
        width="130px"
        height="100px"
      />
      <canvas
        style={{ display: 'none' }}
        ref={image5}
        width="130px"
        height="100px"
      />
      <canvas
        style={{ display: 'none' }}
        ref={image6}
        width="130px"
        height="100px"
      />
    </>
  )
})

export default GraphsSamples
