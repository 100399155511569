import { gql } from '../../../../hooks'

export const PROPOSE_SAMPLE = gql`
  mutation ProposeSample($id: ID!, $values: JSONObject!, $logic: JSONObject!) {
    t4ProposeSample(id: $id, values: $values, logic: $logic) {
    }
  }
`

export const SAVE_SAMPLE = gql`
  mutation SaveSample($id: ID!, $values: JSONObject!, $logic: JSONObject!) {
    t4SaveSample(id: $id, values: $values, logic: $logic) {
    }
  }
`

export const GET_SAMPLE_LOGIC = gql`
  query GetSampleLogic($id: ID!, $filters: [ID!]!) {
    t4SampleLogics(id: $id, filters: $filters) {
    }
  }
`
