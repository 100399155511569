import React from 'react'
import { Row, Col } from 'react-bootstrap'

import UIGraphLine from '../../../../UI/Graph/Line'

const Graph = ({ data: components }) => {
  components = components.reverse()

  const data1 = [
    {
      data: components.map(({ essays }, index) => ({
        x: index + 1,
        y: essays[2] ? parseFloat(essays[2].value) : null,
      })),
      id: 'Cr',
    },
    {
      data: components.map(({ essays }, index) => ({
        x: index + 1,
        y: essays[4] ? parseFloat(essays[4].value) : null,
      })),
      id: 'Cu',
    },
    {
      data: components.map(({ essays }, index) => ({
        x: index + 1,
        y: essays[1] ? parseFloat(essays[1].value) : null,
      })),
      id: 'Fe',
    },
    {
      data: components.map(({ essays }, index) => ({
        x: index + 1,
        y: essays[5] ? parseFloat(essays[5].value) : null,
      })),
      id: 'Pb',
    },
    {
      data: components.map(({ essays }, index) => ({
        x: index + 1,
        y: essays[15] ? parseFloat(essays[15].value) : null,
      })),
      id: 'Si',
    },
    {
      data: components.map(({ essays }, index) => ({
        x: index + 1,
        y: essays[13] ? parseFloat(essays[13].value) : null,
      })),
      id: 'Na',
    },
  ]

  const data2 = [
    {
      data: components.map(({ essays }, index) => ({
        x: index + 1,
        y: essays[4] ? parseFloat(essays[4].value) : null,
      })),
      id: 'Cu',
    },
    {
      data: components.map(({ essays }, index) => ({
        x: index + 1,
        y: essays[1] ? parseFloat(essays[1].value) : null,
      })),
      id: 'Fe',
    },
    {
      data: components.map(({ essays }, index) => ({
        x: index + 1,
        y: essays[15] ? parseFloat(essays[15].value) : null,
      })),
      id: 'Si',
    },
  ]

  return (
    <Row className="justify-content-md-center">
      {[data1, data2].map((data, index) => (
        <Col xs={5} style={{ height: '300px' }} key={`graph-${index}`}>
          <UIGraphLine
            data={data}
            margin={{
              top: 50,
              right: 110,
              bottom: 50,
              left: 60,
            }}
            xScale={{ type: 'point' }}
            yScale={{
              type: 'linear',
              min: 'auto',
              max: 'auto',
              stacked: false,
              reverse: false,
            }}
            axisBottom={{
              orient: 'bottom',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Muestras',
              legendOffset: 36,
              legendPosition: 'middle',
            }}
            axisLeft={{
              orient: 'left',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Medición [ppm]',
              legendOffset: -40,
              legendPosition: 'middle',
            }}
            colors={{ scheme: 'category10' }}
            legends={[
              {
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemBackground: 'rgba(0, 0, 0, .03)',
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        </Col>
      ))}
    </Row>
  )
}

export default Graph
