import React from 'react'

// Components
import UIFormInputText from '../../../../UI/FormReload/Input/Text'
import UIFormInputSelect from '../../../../UI/FormReload/Input/Select'
import UIFormInputCheckbox from '../../../../UI/FormReload/Input/Checkbox'
import UIFormInputInteger from '../../../../UI/FormReload/Input/Integer'
import UIPanelInfoTable from '../../../../UI/Panel/Info/Table'

// Common
import { oe } from '../../../../../common/object/extractor'

import { AdministrationForm } from '../..'

// Queries
import {
  READ_ESSAYS,
  CREATE_ESSAYS,
  UPDATE_ESSAYS,
  LIST_ESSAYS,
  SEARCH_RESULT_UNIT,
  SEARCH_RESULT_TYPE,
  SEARCH_ESSAY_UNIT,
  SEARCH_ANALYSIS_METHOD,
  SEARCH_PROTOCOL_TYPE,
  SEARCH_ESSAY_CLASSIFICATION,
} from './queries'

const queries = {
  read: READ_ESSAYS,
  create: CREATE_ESSAYS,
  update: UPDATE_ESSAYS,
  list: LIST_ESSAYS,
}

const CustomForm = ({ action, data, id }) => (
  <UIPanelInfoTable>
    {[
      action === 'update'
        ? ['ID', <UIFormInputText inline name="id" placeholder={id} disabled />]
        : [],
      [
        'Descripción',
        <UIFormInputText
          name="name"
          initialValue={oe(data, ['name'])}
          placeholder={oe(data, ['name'])}
          required
        />,
      ],
      // [
      //   'ID Clasificación',
      //   <UIFormInputInteger
      //     inline
      //     name="id_essay_classification"
      //     initialValue={oe(data, ['classification', 'id'])}
      //     placeholder={oe(data, ['classification', 'id'])}
      //     required
      //   />,
      // ],
      [
        'Clasificación',
        <UIFormInputSelect
          name="id_essay_classification"
          query={SEARCH_ESSAY_CLASSIFICATION}
          idAs="value"
          initialValue={oe(data, ['classification', 'id'])}
          required
        >
          <option value="0">Seleccione una poción</option>
        </UIFormInputSelect>,
      ],
      // [
      //   'ID Tipo protocolo',
      //   <UIFormInputInteger
      //     inline
      //     name="id_protocol_type"
      //     initialValue={oe(data, ['protocolType', 'id'])}
      //     placeholder={oe(data, ['protocolType', 'id'])}
      //     required
      //   />,
      // ],
      [
        'Tipo protocolo',
        <UIFormInputSelect
          name="id_protocol_type"
          query={SEARCH_PROTOCOL_TYPE}
          idAs="value"
          initialValue={oe(data, ['protocolType', 'id'])}
          required
        >
          <option value="0">Seleccione una poción</option>
        </UIFormInputSelect>,
      ],
      // [
      //   'ID Método análisis',
      //   <UIFormInputInteger
      //     inline
      //     name="id_essay_analysis_method"
      //     initialValue={oe(data, ['analysisMethod', 'id'])}
      //     placeholder={oe(data, ['analysisMethod', 'id'])}
      //     required
      //   />,
      // ],
      [
        'Método análisis',
        <UIFormInputSelect
          name="id_essay_analysis_method"
          query={SEARCH_ANALYSIS_METHOD}
          idAs="value"
          initialValue={oe(data, ['analysisMethod', 'id'])}
          required
        >
          <option value="0">Seleccione una poción</option>
        </UIFormInputSelect>,
      ],
      // [
      //   'ID Unidad ensayo',
      //   <UIFormInputInteger
      //     inline
      //     name="id_essay_unit"
      //     initialValue={oe(data, ['unit', 'id'])}
      //     placeholder={oe(data, ['unit', 'id'])}
      //     required
      //   />,
      // ],
      [
        'Unidad ensayo',
        <UIFormInputSelect
          name="id_essay_unit"
          query={SEARCH_ESSAY_UNIT}
          idAs="value"
          initialValue={oe(data, ['unit', 'id'])}
          required
        >
          <option value="0">Seleccione una poción</option>
        </UIFormInputSelect>,
      ],
      // [
      //   'ID Tipo resultado',
      //   <UIFormInputInteger
      //     inline
      //     name="id_result_type"
      //     initialValue={oe(data, ['resultType', 'id'])}
      //     placeholder={oe(data, ['resultType', 'id'])}
      //     required
      //   />,
      // ],
      [
        'Tipo resultado',
        <UIFormInputSelect
          name="id_result_type"
          query={SEARCH_RESULT_TYPE}
          idAs="value"
          initialValue={oe(data, ['resultType', 'id'])}
          required
        >
          <option value="0">Seleccione una poción</option>
        </UIFormInputSelect>,
      ],
      // [
      //   'ID Unidad resultado',
      //   <UIFormInputInteger
      //     inline
      //     name="id_result_unit"
      //     initialValue={oe(data, ['resultUni', 'id'])}
      //     placeholder={oe(data, ['resultUni', 'id'])}
      //     required
      //   />,
      // ],
      // [
      //   'Unidad resultado',
      //   <UIFormInputSelect
      //     name="id_result_unit"
      //     query={SEARCH_RESULT_UNIT}
      //     idAs="value"
      //     initialValue={oe(data, ['resultUnit', 'id'])}
      //     required
      //   />,
      // ],
      [
        'Usa comentadio',
        <UIFormInputSelect
          name="use_comment"
          initialValue={oe(data, ['useComment'], 0)}
        >
          <option value={0}>No</option>
          <option value={1}>Sí</option>
        </UIFormInputSelect>,
      ],
      [
        'Usar en lógica',
        ,
        <UIFormInputCheckbox
          name={`use_in_logics`}
          initialValue={oe(data, ['use_in_logics'], 1)}
        />,
      ],
    ]}
  </UIPanelInfoTable>
)

const AdministrationFormSamplingEssays = () => (
  <AdministrationForm name={'ensayo'} queries={queries}>
    <CustomForm />
  </AdministrationForm>
)

export default AdministrationFormSamplingEssays
