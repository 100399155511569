import React from 'react'
import ExcelJS from 'exceljs/dist/es5/exceljs.browser'
// import * as ExcelJS from 'exceljs/dist/exceljs'

// Components
import UIButton from '../../../UI/Button'

// Hooks
import { useGlobal, useLazyQuery } from '../../../../hooks'

// Queries
import { GET_EXCEL } from './queries'

// ----------------------------------------------------------------------------
//
// Building Excel
//
// ----------------------------------------------------------------------------

const buildExcel = ({ data, filter }) => {
  const [{ me }] = useGlobal()

  const { laboratory } = me

  const { info, results } = data
  const { from, to } = filter

  const workbook = new ExcelJS.Workbook()

  workbook.creator = 'Servicios Tribológicos'

  // ------------------------------------------------------------------------
  // Helpers
  // ------------------------------------------------------------------------

  const border = (positions = {}) => {
    const { x, y, t, r, l, b } = positions

    const style = 'thin'
    const border = {}

    if (x) {
      border.left = { style }
      border.right = { style }
    }
    if (y) {
      border.top = { style }
      border.bottom = { style }
    }
    if (t) border.top = { style }
    if (r) border.right = { style }
    if (b) border.bottom = { style }
    if (l) border.left = { style }

    if (!Object.keys(positions).length) {
      border.top = { style: 'thin' }
      border.left = { style: 'thin' }
      border.bottom = { style: 'thin' }
      border.right = { style: 'thin' }
    }

    return border
  }

  const fill = ({ argb = 'FFF7F2E0' } = {}) => ({
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb },
  })

  results.forEach((result) => {
    // ------------------------------------------------------------------------
    // Excel buiilding
    // ------------------------------------------------------------------------

    const { name, rows } = result

    // Create sheet
    const sheet = workbook.addWorksheet(name.replace(/[:\\\/\?\*\[\]#]/, ''))

    // Insert info header
    let row
    const r = 0
    const c = 0

    row = sheet.getRow(r + 1)
    row.alignment = { vertical: 'middle', horizontal: 'center' }
    row.border = border({ b: true })
    row.fill = fill()
    row.getCell(c + 1).value = 'Tipo Equipo'
    row.getCell(c + 2).value = 'Equipo'
    row.getCell(c + 3).value = 'Desc Componente'
    row.getCell(c + 4).value = 'Tag'
    row.getCell(c + 5).value = 'Numero muestra'
    row.getCell(c + 6).value = 'HK Equipo'
    row.getCell(c + 7).value = 'HK Componente'
    row.getCell(c + 8).value = 'Hk Lubricante'
    row.getCell(c + 9).value = 'Fecha'
    row.getCell(c + 10).value = 'Fierro'
    row.getCell(c + 11).value = 'Cromo'
    row.getCell(c + 12).value = 'Aluminio'
    row.getCell(c + 13).value = 'Cobre'
    row.getCell(c + 14).value = 'Plomo'
    row.getCell(c + 15).value = 'Nickel'
    row.getCell(c + 16).value = 'Plata'
    row.getCell(c + 17).value = 'Estano'
    row.getCell(c + 18).value = 'Titanio'
    row.getCell(c + 19).value = 'Vanadio'
    row.getCell(c + 20).value = 'Cadmio'
    row.getCell(c + 21).value = 'Manganeso'
    row.getCell(c + 22).value = 'Sodio'
    row.getCell(c + 23).value = 'Potasio'
    row.getCell(c + 24).value = 'Silicio'
    row.getCell(c + 25).value = 'Zinc'
    row.getCell(c + 26).value = 'Bario'
    row.getCell(c + 27).value = 'Boro'
    row.getCell(c + 28).value = 'Calcio'
    row.getCell(c + 29).value = 'Molibdeno'
    row.getCell(c + 30).value = 'Magnesio'
    row.getCell(c + 31).value = 'Fosforo'
    row.getCell(c + 32).value = 'v 40'
    row.getCell(c + 33).value = 'v 100'
    row.getCell(c + 34).value = 'Índice viscosidad'
    row.getCell(c + 35).value = 'Contenido agua'
    row.getCell(c + 36).value = 'Nº total Básico'
    row.getCell(c + 37).value = 'Nº total Ácido'
    row.getCell(c + 38).value = 'Índice PQ'
    row.getCell(c + 39).value = 'Dilucion por Combustible'
    row.getCell(c + 40).value = 'Oxidacion'
    row.getCell(c + 41).value = 'Sulfatacion'
    row.getCell(c + 42).value = 'Nitración'
    row.getCell(c + 43).value = 'Hollín'
    row.getCell(c + 44).value = 'Agua Karl Fisher'
    row.getCell(c + 45).value = 'Consistencia Trabajada'
    row.getCell(c + 46).value = 'Consistencia NO Trabajada'
    row.getCell(c + 47).value = 'Consistencia 10000 Golpes'
    row.getCell(c + 48).value = 'Punteo de goteo'
    row.getCell(c + 49).value = 'Determinación PH'
    row.getCell(c + 50).value = 'Punto congelamiento'
    row.getCell(c + 51).value = '% anticongelantes'
    row.getCell(c + 52).value = 'Punto de inflamacion PMCC'
    row.getCell(c + 53).value = 'Partículas > 4um'
    row.getCell(c + 54).value = 'Partículas > 6um'
    row.getCell(c + 55).value = 'Partículas > 14um'
    row.getCell(c + 56).value = 'Codigo ISO'
    row.getCell(c + 57).value = 'Ester Breakdown I'
    row.getCell(c + 58).value = 'Ester Breakdown II'
    row.getCell(c + 59).value = 'Fº Retiro'
    row.getCell(c + 60).value = 'Fº Ingreso'
    row.getCell(c + 61).value = 'Fº Recepción'
    row.getCell(c + 62).value = 'Fº Ensayo'
    row.getCell(c + 63).value = 'Lubricante'
    row.getCell(c + 64).value = 'TAG Componente'
    row.getCell(c + 65).value = 'Fº Reporte'
    row.getCell(c + 66).value = 'Tiempo Transporte (horas)'
    row.getCell(c + 67).value = 'Tiempo Análisis (horas)'
    row.getCell(c + 68).value = 'Tiempo Interpretación (horas)'
    row.getCell(c + 69).value =
      'Tiempo Total Laboratorio (horas) / No incluye transporte'
    row.getCell(c + 70).value =
      'Tiempo Total Proceso (horas) / incluye transporte'
    row.getCell(c + 71).value = 'PI'
    row.getCell(c + 72).value = 'Flota'
    row.getCell(c + 73).value = 'I'
    row.getCell(c + 74).value = 'Condición'
    row.getCell(c + 75).value = 'Comentario'

    rows.forEach((row, index) => {
      const {
        time_transport,
        time_analysis,
        time_interpretation,
        sample_id,
        machine_continuity,
        component_continuity,
        lubricant_continuity,
        date_sampling,
        date_withdraw,
        date_entry,
        date_receive,
        date_essay,
        date_proposed,
        machine_type_name,
        machine_name,
        component_tag,
        component_name,
        lubricant_name,
        sample_suggestion,
        sample_recommendation,
        sample_condition_name,
        time_total_lab,
        time_total_process,
        essays,
      } = row

      row = sheet.getRow(r + 2 + index)
      row.alignment = { vertical: 'middle', horizontal: 'center' }
      row.getCell(c + 1).value = machine_type_name
      row.getCell(c + 2).value = machine_name
      row.getCell(c + 3).value = component_name
      row.getCell(c + 4).value = component_tag
      row.getCell(c + 5).value = sample_id
      row.getCell(c + 6).value = machine_continuity
      row.getCell(c + 7).value = component_continuity
      row.getCell(c + 8).value = lubricant_continuity
      row.getCell(c + 9).value = date_sampling
      row.getCell(c + 10).value = essays['1'] ? essays['1'].value : ''
      row.getCell(c + 11).value = essays['2'] ? essays['2'].value : ''
      row.getCell(c + 12).value = essays['3'] ? essays['3'].value : ''
      row.getCell(c + 13).value = essays['4'] ? essays['4'].value : ''
      row.getCell(c + 14).value = essays['5'] ? essays['5'].value : ''
      row.getCell(c + 15).value = essays['6'] ? essays['6'].value : ''
      row.getCell(c + 16).value = essays['7'] ? essays['7'].value : ''
      row.getCell(c + 17).value = essays['8'] ? essays['8'].value : ''
      row.getCell(c + 18).value = essays['9'] ? essays['9'].value : ''
      row.getCell(c + 19).value = essays['10'] ? essays['10'].value : ''
      row.getCell(c + 20).value = essays['11'] ? essays['11'].value : ''
      row.getCell(c + 21).value = essays['12'] ? essays['12'].value : ''
      row.getCell(c + 22).value = essays['13'] ? essays['13'].value : ''
      row.getCell(c + 23).value = essays['14'] ? essays['14'].value : ''
      row.getCell(c + 24).value = essays['15'] ? essays['15'].value : ''
      row.getCell(c + 25).value = essays['16'] ? essays['16'].value : ''
      row.getCell(c + 26).value = essays['17'] ? essays['17'].value : ''
      row.getCell(c + 27).value = essays['18'] ? essays['18'].value : ''
      row.getCell(c + 28).value = essays['19'] ? essays['19'].value : ''
      row.getCell(c + 29).value = essays['20'] ? essays['20'].value : ''
      row.getCell(c + 30).value = essays['21'] ? essays['21'].value : ''
      row.getCell(c + 31).value = essays['22'] ? essays['22'].value : ''
      row.getCell(c + 32).value = essays['23'] ? essays['23'].value : ''
      row.getCell(c + 33).value = essays['24'] ? essays['24'].value : ''
      row.getCell(c + 34).value = essays['25'] ? essays['25'].value : ''
      row.getCell(c + 35).value = essays['26'] ? essays['26'].value : ''
      row.getCell(c + 36).value = essays['27'] ? essays['27'].value : ''
      row.getCell(c + 37).value = essays['28'] ? essays['28'].value : ''
      row.getCell(c + 38).value = essays['29'] ? essays['29'].value : ''
      row.getCell(c + 39).value = essays['30'] ? essays['30'].value : ''
      row.getCell(c + 40).value = essays['31'] ? essays['31'].value : ''
      row.getCell(c + 41).value = essays['32'] ? essays['32'].value : ''
      row.getCell(c + 42).value = essays['33'] ? essays['33'].value : ''
      row.getCell(c + 43).value = essays['34'] ? essays['34'].value : ''
      row.getCell(c + 44).value = essays['35'] ? essays['35'].value : ''
      row.getCell(c + 45).value = essays['36'] ? essays['36'].value : ''
      row.getCell(c + 46).value = essays['37'] ? essays['37'].value : ''
      row.getCell(c + 47).value = essays['38'] ? essays['38'].value : ''
      row.getCell(c + 48).value = essays['39'] ? essays['39'].value : ''
      row.getCell(c + 49).value = essays['40'] ? essays['40'].value : ''
      row.getCell(c + 50).value = essays['41'] ? essays['41'].value : ''
      row.getCell(c + 51).value = essays['42'] ? essays['42'].value : ''
      row.getCell(c + 52).value = essays['43'] ? essays['43'].value : ''
      row.getCell(c + 53).value = essays['44'] ? essays['44'].value : ''
      row.getCell(c + 54).value = essays['45'] ? essays['45'].value : ''
      row.getCell(c + 55).value = essays['46'] ? essays['46'].value : ''
      row.getCell(c + 56).value = essays['47'] ? essays['47'].value : ''
      row.getCell(c + 57).value = essays['49'] ? essays['49'].value : ''
      row.getCell(c + 58).value = essays['50'] ? essays['50'].value : ''
      row.getCell(c + 59).value = date_withdraw
      row.getCell(c + 60).value = date_entry
      row.getCell(c + 61).value = date_receive
      row.getCell(c + 62).value = date_essay
      row.getCell(c + 63).value = lubricant_name
      row.getCell(c + 64).value = component_tag
      row.getCell(c + 65).value = date_proposed
      row.getCell(c + 66).value = time_transport
      row.getCell(c + 67).value = time_analysis
      row.getCell(c + 68).value = time_interpretation
      row.getCell(c + 69).value = time_total_lab
      row.getCell(c + 70).value = time_total_process
      row.getCell(c + 71).value = '0'
      row.getCell(c + 72).value = machine_type_name
      row.getCell(c + 73).value = '1'
      row.getCell(c + 74).value = sample_condition_name
      if (sample_recommendation)
        row.getCell(c + 75).value = sample_recommendation
      else row.getCell(c + 75).value = sample_suggestion
    })

    // Make the cells width fit automátically
    sheet.columns.forEach((column) => {
      var dataMax = 0
      column.eachCell({ includeEmpty: true }, (cell) => {
        var columnLength = cell && cell.value ? cell.value.length + 5 : 0
        if (columnLength > dataMax) {
          dataMax = columnLength
        }
      })
      column.width = dataMax < 10 ? 10 : dataMax
    })

    // const myBase64Image = 'data:image/png;base64,iVBORw0KG...'
    // const imageId2 = workbook.addImage({
    //   base64: myBase64Image,
    //   extension: 'png',
    // })
  })

  // Download excel file!
  workbook.xlsx.writeBuffer().then(function (data) {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const url = window.URL.createObjectURL(blob)
    const anchor = document.createElement('a')
    anchor.href = url
    anchor.download = 'Reporte - PowerBI.xlsx'
    anchor.click()
    // anchor.dispatchEvent(new MouseEvent('click')) // NOTE https://github.com/exceljs/exceljs/issues/354
    window.URL.revokeObjectURL(url)
  })
}

// ----------------------------------------------------------------------------
//
// Main render
//
// ----------------------------------------------------------------------------

const ButtonDownload = ({ filter }) => {
  const [getExcel, { loading, data }] = useLazyQuery(GET_EXCEL, {
    variables: filter,
  })

  if (data && data.reportPowerBIExcel && data.reportPowerBIExcel.data) {
    buildExcel({ data: data.reportPowerBIExcel.data, filter })
  }

  const handleClick = () => {
    getExcel()
  }

  return (
    <UIButton onClick={handleClick} disabled={loading}>
      <img src="/excel.png" height="20" alt="Excel" />
    </UIButton>
  )
}

export default ButtonDownload
