import React from 'react'
import { Badge, Col, Row } from 'react-bootstrap'
import moment from 'moment'

// Hooks
import {
  useEffect,
  useHistory,
  useState,
  useQuery,
  useGlobal,
} from '../../../../../../hooks'

// Components
import UIEmpty from '../../../../../UI/Empty'
import UILoading from '../../../../../UI/Loading'
import UIError from '../../../../../UI/Error'
import UIPagination from '../../../../../UI/Pagination'
import UITable from '../../../../../UI/Table'
import UITableBody from '../../../../../UI/Table/Body'
import UITableHead from '../../../../../UI/Table/Head'
import UIForm from '../../../../../UI/FormReload'
import UIButton from '../../../../../UI/Button'

// Queries
import { LIST_RECEIVED_SAMPLES } from './queries'

const TribologySamplesResultsList = () => {
  const [{ me }] = useGlobal()

  const [pagination, setPaginationState] = useState({
    limit: 25,
    page: 1,
    idSite: 0,
  })

  const history = useHistory()

  // --------------------------------------------------------------------------
  //
  // Query: Received samples
  //
  // --------------------------------------------------------------------------

  const { data, loading, refetch } = useQuery(LIST_RECEIVED_SAMPLES, {
    fetchPolicy: 'network-only',
    variables: { ...pagination },
    onSuccess: ({ pagination }) => {
      setPaginationState((prev) => ({ ...prev, ...pagination }))
    },
    onErrorMessage: `No se pudo obtener la lista de muestras.`,
  })

  useEffect(() => {
    refetch()
  }, [me.laboratory])

  // --------------------------------------------------------------------------
  //
  // Render
  //
  // --------------------------------------------------------------------------

  // Loading
  if (loading) return <UILoading />

  // Data
  if (data && data.t1MassiveIscaaSamples && data.t1MassiveIscaaSamples.data) {
    const { total, received, archived } = data.t1MassiveIscaaSamples.data
    const pagination = data.t1MassiveIscaaSamples.pagination

    const handlePageChange = (page) => {
      setPaginationState((prev) => ({ ...prev, ...pagination, page }))
    }

    const handleClick = (id_massive) => {
      history.push(`/tribology/samples/insert/iscaa/${id_massive}`)
    }

    return (
      <UIForm>
        <Row className="mb-4">
          <Col className="text-left">
            <Badge
              className="px-2 py-2"
              variant="secondary"
              style={{ fontSize: '11pt' }}
            >
              Total muestras: {total}
            </Badge>
          </Col>
        </Row>
        {received.length > 0 ? (
          <>
            <UITable>
              <UITableHead>
                {['ID masivo', 'Faena', 'Fecha', 'Número de muestras', '']}
              </UITableHead>
              <UITableBody>
                {received.map(
                  ({ id, date_created, site_name, number_of_samples }) => (
                    <tr key={id}>
                      <td>{id}</td>
                      <td>{site_name}</td>
                      <td>{moment(date_created).format('DD/MM/YYYY')}</td>
                      <td>{number_of_samples}</td>
                      <td>
                        <UIButton
                          variant="outline-secondary font-weight-light"
                          onClick={() => handleClick(id)}
                        >
                          Abrir
                        </UIButton>
                      </td>
                    </tr>
                  ),
                )}
              </UITableBody>
            </UITable>
            <UIPagination pagination={pagination} onClick={handlePageChange} />
          </>
        ) : (
          <UIEmpty message="No hay nuevas muestras" />
        )}
        {archived.length > 0 && (
          <>
            <h2>Muestras archivadas</h2>
            <UITable>
              <UITableHead>
                {['ID masivo', 'Faena', 'Fecha', 'Número de muestras', '']}
              </UITableHead>
              <UITableBody>
                {archived.map(
                  ({ id, date_created, site_name, number_of_samples }) => (
                    <tr key={id}>
                      <td>{id}</td>
                      <td>{site_name}</td>
                      <td>{moment(date_created).format('DD/MM/YYYY')}</td>
                      <td>{number_of_samples}</td>
                      <td>
                        <UIButton
                          variant="outline-secondary font-weight-light"
                          onClick={() => handleClick(id)}
                        >
                          Abrir
                        </UIButton>
                      </td>
                    </tr>
                  ),
                )}
              </UITableBody>
            </UITable>
          </>
        )}
      </UIForm>
    )
  }

  // Error
  return <UIError />
}
export default TribologySamplesResultsList
