// import React from 'react'
import Chartjs from 'chart.js'

// Primer gráfico
//
// 1 : Fierro
// 29 : Índice PQ
//
// Últimos 10 correlativos

const GraphCentinela = ({ ref, graphs, config, type }) => {
  const [graph, label] = (() => {
    if (!graphs) return [null, null]

    switch (type) {
      case 1:
      case '1':
        return [graphs[24], 'V 100°C']
      case 2:
      case '2':
        return [graphs[1], 'Fierro']
      case 3:
      case '3':
        return [graphs[23], 'V 40°C']
      case 4:
      case '4':
        return [graphs[24], 'V 100°C']
      default:
        return [null, null]
    }
  })()

  // --------------------------------------------------------------------------
  //
  // No data
  //
  // --------------------------------------------------------------------------

  if (!graph) {
    new Chartjs(ref.current, {
      type: 'line',
      data: {
        datasets: [
          {
            data: null,
          },
        ],
      },
      options: { ...config.options, legend: { display: false } },
    })

    return
  }

  const { x: minX, y: minY } = graph[0]
  const { x: maxX, y: maxY } = graph[graph.length - 1]

  // --------------------------------------------------------------------------
  //
  // Only 1 graph
  //
  // --------------------------------------------------------------------------

  const { lic, lim, lsm, lsc } = graphs

  const labels =
    graph.length === 1 ? ['', graph[0].h, ''] : graph.map(({ h }) => h)
  // graph.length === 1 ? ['', graph[0].h, ''] : graph.map(({ x, h }) => [h, x])

  new Chartjs(ref.current, {
    type: 'line',
    data: {
      labels,
      datasets: [
        {
          label,
          data: graph.map(({ h, y, label }) => ({
            h,
            y,
            label,
          })),
          ...config.main0,
          showLabelsOverPoints: true,
        },
        {
          label: 'Máximo',
          data: graph.map(({ h }) => ({ h, y: lsc })),
          ...config.lsc,
        },
        {
          label: 'Mínimo',
          data: graph.map(({ h }) => ({ h, y: lsm })),
          ...config.lsm,
        },
      ],
    },
    options:
      graph.length === 1
        ? {
            ...config.options,
            scales: {
              xAxes: [
                {
                  ticks: {
                    fontSize: 50,
                  },
                  padding: 40,
                },
              ],
              yAxes: [
                {
                  scaleLabel: {
                    display: true,
                  },
                  beginAtZero: false,
                  ticks: {
                    min: Math.min(minY, lic, lim, lsm, lsc) * 0.9,
                    max: Math.max(maxY, lic, lim, lsm, lsc) * 1.1,
                    fontSize: 50,
                    padding: 40,
                  },
                },
              ],
            },
            responsive: true,
          }
        : config.options,
    plugins: [
      {
        afterDatasetsDraw: function (chart) {
          var ctx = chart.ctx
          chart.data.datasets.forEach(function (dataset, index) {
            var datasetMeta = chart.getDatasetMeta(index)
            if (datasetMeta.hidden) return
            if (!dataset.showLabelsOverPoints) return

            datasetMeta.data.forEach(function (point, index) {
              const label = dataset.data[index].label

              if (!label) return

              var x = point.getCenterPoint().x,
                y = point.getCenterPoint().y,
                radius = point._model.radius,
                fontSize = 25,
                fontFamily = 'Sans',
                fontColor = '#228B22',
                fontStyle = 'bold'

              ctx.save()
              ctx.textBaseline = 'middle'
              ctx.textAlign = 'center'
              ctx.font = fontStyle + ' ' + fontSize + 'px' + ' ' + fontFamily
              ctx.fillStyle = fontColor
              ctx.fillText(label, x, y - radius - fontSize)
              ctx.restore()
            })
          })
        },
      },
    ],
  })
}

const Graph = ({ ref, graphs, config, laboratory, type }) => {
  const graph0 = graphs && graphs[1]
  const graph1 = graphs && graphs[29]

  // --------------------------------------------------------------------------
  //
  // No data
  //
  // --------------------------------------------------------------------------

  if (!graph0 && !graph1) {
    new Chartjs(ref.current, {
      type: 'line',
      labels: [1],
      data: {
        datasets: [
          {
            data: null,
          },
        ],
      },
      options: { ...config.options, legend: { display: false } },
    })

    return
  }

  const { x: x00, y: y00 } = graph0[0]
  const { x: x01, y: y01 } = graph0[graph0.length - 1]
  const { x: x10, y: y10 } = graph1[0]
  const { x: x11, y: y11 } = graph1[graph1.length - 1]

  const minX = Math.min(x00, x10)
  const maxX = Math.max(x01, x11)
  const minY = Math.min(y00, y10)
  const maxY = Math.max(y01, y11)

  // --------------------------------------------------------------------------
  //
  // The 2 graphs
  //
  // --------------------------------------------------------------------------

  // Get the larget one
  const graph = x01 > x11 ? graph0 : graph1
  const labels = graph.length === 1 ? ['', 1, ''] : graph.map(({ x }) => x)

  new Chartjs(ref.current, {
    type: 'line',
    data: {
      labels,
      datasets: [
        {
          label: 'Fierro',
          data: graph0.map(({ x, y, label }) => ({
            x,
            y,
            label,
          })),
          ...config.main0,
          showLabelsOverPoints: true,
        },
        {
          label: 'Indice PQ',
          data: graph1.map(({ x, y, label }) => ({
            x,
            y,
            label,
          })),
          ...config.main1,
          showLabelsOverPoints: true,
        },
      ],
    },
    options:
      graph.length === 1
        ? {
            ...config.options,
            scales: {
              xAxes: [{ ticks: { fontSize: 50 }, padding: 40 }],
              yAxes: [
                {
                  scaleLabel: {
                    display: true,
                  },
                  beginAtZero: false,
                  ticks: {
                    min: minY * 0.9,
                    max: maxY * 1.1,
                    fontSize: 50,
                    padding: 40,
                  },
                },
              ],
            },
          }
        : config.options,
    plugins: [
      {
        afterDatasetsDraw: function (chart) {
          var ctx = chart.ctx
          chart.data.datasets.forEach(function (dataset, index) {
            var datasetMeta = chart.getDatasetMeta(index)
            if (datasetMeta.hidden) return
            if (!dataset.showLabelsOverPoints) return

            datasetMeta.data.forEach(function (point, index) {
              const label = dataset.data[index].label

              if (!label) return

              var x = point.getCenterPoint().x,
                y = point.getCenterPoint().y,
                radius = point._model.radius,
                fontSize = 25,
                fontFamily = 'Sans',
                fontColor = '#228B22',
                fontStyle = 'bold'

              ctx.save()
              ctx.textBaseline = 'middle'
              ctx.textAlign = 'center'
              ctx.font = fontStyle + ' ' + fontSize + 'px' + ' ' + fontFamily
              ctx.fillStyle = fontColor
              ctx.fillText(label, x, y - radius - fontSize)
              ctx.restore()
            })
          })
        },
      },
    ],
  })
}

const Graph1 = ({ ref, graphs, config, laboratory, type }) => {
  if (laboratory === 'centinela') {
    GraphCentinela({ ref, graphs, config, type })
  } else {
    Graph({ ref, graphs, config })
  }
}

export default Graph1
