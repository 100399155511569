import React from 'react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

// Hooks
import { useQuery } from '../../../../../hooks'

// Components
import UIPanelInfo from '../../../../UI/Panel/Info'
import UIPanelInfoBlock from '../../../../UI/Panel/Info/Block'
import UIPanelInfoItem from '../../../../UI/Panel/Info/Item'
import UILoading from '../../../../UI/Loading'
import UIError from '../../../../UI/Error'

// Queries
import { GET_RECEIVED_SAMPLE_INFO } from './queries'

const SampleInfoReciptProgrammed = ({ id }) => {
  // --------------------------------------------------------------------------
  //
  // Query: Received samples info
  //
  // --------------------------------------------------------------------------

  const { data, loading } = useQuery(GET_RECEIVED_SAMPLE_INFO, {
    fetchPolicy: 'network-only',
    variables: { id },
    skip: !id,
  })

  // --------------------------------------------------------------------------
  //
  // Render
  //
  // --------------------------------------------------------------------------

  // Loading
  if (loading)
    return (
      <UIPanelInfo centered>
        <UIPanelInfoBlock>
          <UILoading />
        </UIPanelInfoBlock>
      </UIPanelInfo>
    )

  if (data) {
    const {
      sample,
      client,
      site,
      component,
      component_type,
      component_model,
      component_brand,
      machine_type,
      machine_model,
      machine_brand,
      lubricant,
    } = data.t3ReceivedSampleInfo.data

    return (
      <UIPanelInfo>
        <UIPanelInfoBlock title="Datos muestra">
          <UIPanelInfoItem name="N° Muestra">{sample.id}</UIPanelInfoItem>
          <UIPanelInfoItem name="Fecha muestreo">
            {sample.date_sampling}
          </UIPanelInfoItem>
          <UIPanelInfoItem name="Fecha retiro">
            {sample.date_withdraw}
          </UIPanelInfoItem>
          <UIPanelInfoItem name="Fecha ingreso">
            {sample.date_entry}
          </UIPanelInfoItem>
          <UIPanelInfoItem name="Fecha recepción">
            {sample.date_receive}
          </UIPanelInfoItem>
        </UIPanelInfoBlock>
        <UIPanelInfoBlock title="Cliente">
          <UIPanelInfoItem name="Nombre">{client.name}</UIPanelInfoItem>
          <UIPanelInfoItem name="Faena">{site.name}</UIPanelInfoItem>
        </UIPanelInfoBlock>

        <UIPanelInfoBlock title="Equipo">
          <UIPanelInfoItem name="Tipo">{machine_type.name}</UIPanelInfoItem>
          <UIPanelInfoItem name="Marca">{machine_brand.name}</UIPanelInfoItem>
          <UIPanelInfoItem name="Modelo">{machine_model.name}</UIPanelInfoItem>
          {/*<UIPanelInfoItem name="N° de serie">???</UIPanelInfoItem>*/}
        </UIPanelInfoBlock>

        <UIPanelInfoBlock title="Componente">
          {/*<UIPanelInfoItem name="Código">{component.tag}</UIPanelInfoItem>*/}
          <UIPanelInfoItem name="Tipo">{component_type.name}</UIPanelInfoItem>
          <UIPanelInfoItem name="Marca">{component_brand.name}</UIPanelInfoItem>
          <UIPanelInfoItem name="Modelo">
            {component_model.name}
          </UIPanelInfoItem>
          <UIPanelInfoItem name="N° de serie">
            {component.serial_number}
          </UIPanelInfoItem>
        </UIPanelInfoBlock>

        <UIPanelInfoBlock title="Lubricante">
          <UIPanelInfoItem name="Nombre">{lubricant.name}</UIPanelInfoItem>
        </UIPanelInfoBlock>
      </UIPanelInfo>
    )
  }

  // Error
  return (
    <UIPanelInfo centered>
      <UIPanelInfoBlock>
        <UIError />
      </UIPanelInfoBlock>
    </UIPanelInfo>
  )
}

export default SampleInfoReciptProgrammed
