import { gql } from '../../../../../hooks'

export const DELETE_BRANDS = gql`
  mutation AdministrationDeleteMachineBrands($id: ID!) {
    aDelete(path: "machine/brands", id: $id) {
    }
  }
`

export const LIST_BRANDS = gql`
  query AdministrationListMachineBrands(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(path: "machine/brands", filter: $filter, page: $page, limit: $limit) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`
