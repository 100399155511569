import React from 'react'

// Hooks
import { useGlobal } from '../../../../../hooks'

import AdministrationInfo from '../..'

// Queries
import { READ_PROCEDURES, DELETE_PROCEDURES } from './queries'

const AdministrationInfoSamplingProcedures = ({ ...rest }) => {
  const [{ me }] = useGlobal()

  const no = ![1, 2].includes(me.id_role)

  return (
    <AdministrationInfo
      queries={{ read: READ_PROCEDURES, delete: DELETE_PROCEDURES }}
      title="Procedimiento de muestreo"
      {...rest}
      noDuplicate={no}
      noUpdate={no}
      noDelete={no}
    />
  )
}

export default AdministrationInfoSamplingProcedures
