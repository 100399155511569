import React from 'react'

const UIPanelInfoTable = ({ children = [], headers = [] }) => {
  return (
    <table
      class="table table-sm table-bordered "
      style={{
        fontSize: '12px',
      }}
    >
      {!headers.length && (
        <thead className="table-dark text-center">
          <tr>
            {headers.map((head, head_index) => (
              <th key={`head-${head_index}`} scope="col">
                {head}
              </th>
            ))}
          </tr>
        </thead>
      )}
      <tbody>
        {children.map((rows, row_index) => (
          <tr key={`row-${row_index}`}>
            {rows.map((cell, cell_index) => {
              const style = { verticalAlign: 'middle' }

              if (cell_index === 0) style.backgroundColor = '#F3F3F3'

              return (
                <td
                  key={`cell-${cell_index}`}
                  className={cell_index === 0 ? 'text-right w-30' : ''}
                  style={style}
                >
                  {cell}
                </td>
              )
            })}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
export default UIPanelInfoTable
