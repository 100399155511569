import React from 'react'
import { Row, Col } from 'react-bootstrap'

import UIPanelInfo from '../../../UI/Panel/Info'

const InfoHeader = ({ info }) => {
  const styleKey = { fontSize: '11px' }
  const styleValue = { fontSize: '11px' }
  const classNameKey = 'mb-0 pb-0 text-muted font-weight-bold'
  const classNameValue = 'mb-0 pb-0 text-muted'

  const RowInfo = ({ ckey, value, kc = 2, vc = 10 }) => (
    <Row>
      <Col xs={kc}>
        <p className={classNameKey} style={styleKey}>
          {ckey}
        </p>
      </Col>
      <Col xs={vc}>
        <p className={classNameValue} style={styleValue}>
          {value}
        </p>
      </Col>
    </Row>
  )

  const {
    client_name,
    site_name,
    machine_type_name,
    machine_name,
    component_name,
    responsible,
    from,
    to,
  } = info

  return (
    <UIPanelInfo style="stng">
      {client_name && <RowInfo ckey="Cliente" value={client_name} />}
      {site_name && <RowInfo ckey="Faena" value={site_name} />}
      {machine_type_name && (
        <RowInfo ckey="Tipo de equipo" value={machine_type_name} />
      )}
      {machine_name && <RowInfo ckey="Equipo" value={machine_name} />}
      {component_name && <RowInfo ckey="Equipo" value={component_name} />}
      {responsible && <RowInfo ckey="Encargado" value={responsible} />}
      {from && <RowInfo ckey="Periodo desde" value={from} />}
      {to && <RowInfo ckey="Periodo hasta" value={to} />}
    </UIPanelInfo>
  )
}

export default InfoHeader
