import { gql } from '../../../../../hooks'

export const DELETE_USERS = gql`
  mutation AdministrationDeleteUserUsers($id: ID!) {
    aDelete(path: "user/users", id: $id) {
    }
  }
`

export const LIST_USERS = gql`
  query AdministrationListUserUsers(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(path: "user/users", filter: $filter, page: $page, limit: $limit) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`
