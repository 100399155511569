import React from 'react'

import { AdministrationContainerTab } from '..'

import Users from './Users'

const subtabs = [{ name: 'Usuarios', subtab: 'users', component: Users }]

const AdministrationContainerUser = () => (
  <AdministrationContainerTab subtabs={subtabs} />
)

export default AdministrationContainerUser
