import { gql } from '../../../../../hooks'

export const READ_ANALYSIS_METHODS = gql`
  query AdministrationReadSamplingAnalysisMethods($id: ID!) {
    aRead(path: "sampling/analysis/methods", id: $id) {
    }
  }
`

export const UPDATE_ANALYSIS_METHODS = gql`
  mutation AdministrationUpdateSamplingAnalysisMethods(
    $id: ID!
    $data: JSONObject
  ) {
    aUpdate(path: "sampling/analysis/methods", id: $id, data: $data) {
    }
  }
`

export const CREATE_ANALYSIS_METHODS = gql`
  mutation AdministrationCreateSamplingAnalysisMethods($data: JSONObject) {
    aCreate(path: "sampling/analysis/methods", data: $data) {
    }
  }
`

export const LIST_ANALYSIS_METHODS = gql`
  query AdministrationListSamplingAnalysisMethods(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(
      path: "sampling/analysis/methods"
      filter: $filter
      page: $page
      limit: $limit
    ) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`
