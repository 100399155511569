import React, { forwardRef } from 'react'

import {
  useRef,
  useEffect,
  useMemo,
  useCallback,
  useState,
} from '../../../../../hooks'

import Input from '..'
import { useFormContext } from '../..'

const UIFormInputText = forwardRef((props, ref) => {
  const {
    value: propValue,
    initialValue: propInitialValue,
    defaultValue: propDefaultValue,
    onChange: propOnChange,
    onReset: propOnReset,
    ...rest
  } = props

  const isFirstRender = useRef(true)
  const context = useFormContext()

  const [value, setValueState] = useState(
    propValue !== undefined
      ? propValue
      : propInitialValue !== undefined
      ? propInitialValue
      : propDefaultValue !== undefined
      ? propDefaultValue
      : context.initialValues[props.name] !== undefined
      ? context.initialValues[props.name]
      : context.defaultValues[props.name] != undefined
      ? context.defaultValues[props.name]
      : '',
  )

  useEffect(() => {
    if (isFirstRender.current) isFirstRender.current = false
    else setValueState(propValue)
  }, [propValue])

  const defaultValue = useMemo(
    () =>
      propDefaultValue !== undefined
        ? propDefaultValue
        : context.defaultValues[props.name] !== undefined
        ? context.defaultValues[props.name]
        : '',
    [],
  )

  const handleChange = (event) => {
    setValueState(event.target.value)
    propOnChange && propOnChange(event)
  }

  const handleReset = () => {
    setValueState(defaultValue)
    propOnReset && propOnReset()
  }

  return (
    <Input
      {...rest}
      ref={ref}
      defaultValue={defaultValue}
      value={value}
      onChange={handleChange}
      onReset={handleReset}
    />
  )
})

export default UIFormInputText
