import React from 'react'

// Hooks
// import { useGlobal } from '../../../../../hooks'

import { AdministrationList } from '../../../List'

// Queries
import { DELETE_RE_SAMPLING, LIST_RE_SAMPLING } from './queries'

const queries = {
  list: LIST_RE_SAMPLING,
  delete: DELETE_RE_SAMPLING,
}

const table = [
  { th: 'ID', td: 'id' },
  { th: 'ID muestra', td: ['sample', 'id'] },
  { th: 'Número externo', td: ['sample', 'external_number'] },
  { th: 'Laboratorio', td: ['laboratory', 'name'] },
]

const AdministrationListSamplingReSampling = () => {
  // const [{ me }] = useGlobal()

  // const no = ![1, 2].includes(me.id_role)

  // return (
  //   <AdministrationList
  //     queries={queries}
  //     noCreate={no}
  //     noUpdate={no}
  //     noDelete={no}
  //   />
  // )
  return <AdministrationList table={table} queries={queries} noUpdate />
}

export default AdministrationListSamplingReSampling
