import React, { forwardRef } from 'react'
import { Row, Col } from 'react-bootstrap'
// import moment from 'moment'
import ExcelJS from 'exceljs/dist/es5/exceljs.browser'
import Chartjs from 'chart.js'
// import * as ExcelJS from 'exceljs/dist/exceljs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCogs } from '@fortawesome/free-solid-svg-icons'

// Components
// import UIButton from '../../../UI/Button'

// Hooks
import { useRef, useEffect, useQuery } from '../../../../../hooks'

// Queries
import { GET_EXCEL } from './queries'

// ----------------------------------------------------------------------------
//
// Building Excel
//
// ----------------------------------------------------------------------------

const buildExcel = ({ data, filter, graphs }) => {
  // const [{ me }] = useGlobal()

  // const { laboratory } = me

  const { info, results, total } = data
  const { id_site, id_machine_type, id_machine, from, to } = filter

  const workbook = new ExcelJS.Workbook()

  workbook.creator = 'Servicios Tribológicos'

  // ------------------------------------------------------------------------
  // Helpers
  // ------------------------------------------------------------------------

  const border = (positions = {}) => {
    const { x, y, t, r, l, b } = positions

    const style = 'thin'
    const border = {}

    if (x) {
      border.left = { style }
      border.right = { style }
    }
    if (y) {
      border.top = { style }
      border.bottom = { style }
    }
    if (t) border.top = { style }
    if (r) border.right = { style }
    if (b) border.bottom = { style }
    if (l) border.left = { style }

    if (!Object.keys(positions).length) {
      border.top = { style: 'thin' }
      border.left = { style: 'thin' }
      border.bottom = { style: 'thin' }
      border.right = { style: 'thin' }
    }

    return border
  }

  const fill = ({ argb = 'FFF7F2E0' } = {}) => ({
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb },
  })

  const anomalyMap = [
    { id: 1, name: 'Normal' },
    { id: 2, name: 'Desgaste de Componentes' },
    { id: 3, name: 'Contaminación Sílice - Desgaste' },
    { id: 4, name: 'Dilución Combustible' },
    { id: 5, name: 'Contaminación Agua' },
    { id: 6, name: 'Combustión Deficiente - Desgaste' },
    { id: 7, name: 'Código ISO 4406 - Sílice' },
    { id: 8, name: 'Contaminación Lubricante' },
  ]

  // Create sheet
  const sheet = workbook.addWorksheet(name)

  // ------------------------------------------------------------------------
  // Excel buiilding
  // ------------------------------------------------------------------------

  // Insert info header
  let row
  let o = 0 // offset
  const r = 2
  const c = 2

  // sheet.mergeCells(r + 0, c + 1, r + 0, c + 2)
  // sheet.mergeCells(r + 1, c + 1, r + 1, c + 2)
  // sheet.mergeCells(r + 2, c + 1, r + 2, c + 2)
  // sheet.mergeCells(r + 3, c + 1, r + 3, c + 2)
  // sheet.mergeCells(r + 5, c + 4, r + 5, c + 3 + numComponents)

  row = sheet.getRow(r + 0)
  row.getCell(c + 0).value = 'Cliente :'
  row.getCell(c + 0).border = border({ t: true, l: true })
  row.getCell(c + 0).fill = fill()
  row.getCell(c + 0).alignment = { horizontal: 'right' }
  row.getCell(c + 0).font = { bold: true }
  row.getCell(c + 1).value = info.client_name
  row.getCell(c + 1).border = border({ t: true, r: true })
  row.getCell(c + 1).fill = fill()

  if (info.site_name) {
    row = sheet.getRow(r + 1 + o)
    row.getCell(c + 0).value = 'Faena :'
    row.getCell(c + 0).border = border({ l: true })
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 0).alignment = { horizontal: 'right' }
    row.getCell(c + 0).font = { bold: true }
    row.getCell(c + 1).value = info.site_name
    row.getCell(c + 1).border = border({ r: true })
    row.getCell(c + 1).fill = fill()
    o += 1
  }

  if (info.machine_type_name) {
    row = sheet.getRow(r + 1 + o)
    row.getCell(c + 0).value = 'Flota :'
    row.getCell(c + 0).border = border({ l: true })
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 0).alignment = { horizontal: 'right' }
    row.getCell(c + 0).font = { bold: true }
    row.getCell(c + 1).value = info.machine_type_name
    row.getCell(c + 1).border = border({ r: true })
    row.getCell(c + 1).fill = fill()
    o += 1
  }

  if (info.machine_name) {
    row = sheet.getRow(r + 1 + o)
    row.getCell(c + 0).value = 'Equipo :'
    row.getCell(c + 0).border = border({ l: true })
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 0).alignment = { horizontal: 'right' }
    row.getCell(c + 0).font = { bold: true }
    row.getCell(c + 1).value = info.machine_name
    row.getCell(c + 1).border = border({ r: true })
    row.getCell(c + 1).fill = fill()
    o += 1
  }

  row = sheet.getRow(r + 1 + o)
  row.getCell(c + 0).value = 'Periodo :'
  row.getCell(c + 0).border = border({ l: true })
  row.getCell(c + 0).fill = fill()
  row.getCell(c + 0).alignment = { horizontal: 'right' }
  row.getCell(c + 0).font = { bold: true }
  row.getCell(c + 1).value = `${from} ~ ${to}`
  row.getCell(c + 1).border = border({ r: true })
  row.getCell(c + 1).fill = fill()
  row = sheet.getRow(r + 2 + o)
  row.getCell(c + 0).value = 'Encargado :'
  row.getCell(c + 0).border = border({ l: true, b: true })
  row.getCell(c + 0).fill = fill()
  row.getCell(c + 0).alignment = { horizontal: 'right' }
  row.getCell(c + 0).font = { bold: true }
  row.getCell(c + 1).value = info.responsible
  row.getCell(c + 1).border = border({ r: true, b: true })
  row.getCell(c + 1).fill = fill()

  sheet.mergeCells(r + 4 + o, c + 2, r + 4 + o, c + 4)
  sheet.mergeCells(r + 4 + o, c + 5, r + 4 + o, c + 12)

  row = sheet.getRow(r + 4 + o)
  row.alignment = { vertical: 'middle', horizontal: 'center' }
  row.getCell(c + 2).value = 'Condición'
  row.getCell(c + 2).border = border()
  row.getCell(c + 2).fill = fill()
  //     row.getCell(c + 0).alignment = { horizontal: 'right' }
  //  row.getCell(c + 0).font = { bold: true }
  row.getCell(c + 5).value = 'Anomalía'
  row.getCell(c + 5).border = border()
  row.getCell(c + 5).fill = fill()

  sheet.mergeCells(r + 5 + o, c + 0, r + 5 + o, c + 1)

  row = sheet.getRow(r + 5 + o)
  row.alignment = { vertical: 'middle', horizontal: 'center' }
  row.getCell(c + 0).value = id_machine
    ? 'Tipo componentes'
    : id_machine_type
    ? 'Equipos'
    : id_site
    ? 'Tiop equipos'
    : 'Faenas'
  row.getCell(c + 0).border = border()
  row.getCell(c + 0).fill = fill()
  // row.getCell(c + 1).value = 'Total flota'
  // row.getCell(c + 1).border = border()
  // row.getCell(c + 1).fill = fill()
  row.getCell(c + 2).value = 'Normal'
  row.getCell(c + 2).border = border()
  row.getCell(c + 2).fill = fill()
  row.getCell(c + 3).value = 'Alertas'
  row.getCell(c + 3).border = border()
  row.getCell(c + 3).fill = fill()
  row.getCell(c + 4).value = 'Críticos'
  row.getCell(c + 4).border = border()
  row.getCell(c + 4).fill = fill()
  anomalyMap.forEach(({ name }, index) => {
    row.getCell(c + 5 + index).value = name
    row.getCell(c + 5 + index).border = border()
    row.getCell(c + 5 + index).fill = fill()
  })

  results.forEach((result, index) => {
    const {
      total,
      count_normal,
      count_alerta,
      count_anormal,
      anomaly_1,
      anomaly_2,
      anomaly_3,
      anomaly_4,
      anomaly_5,
      anomaly_6,
      anomaly_7,
      anomaly_8,
      id,
      name,
    } = result

    sheet.mergeCells(r + 6 + o + index, c + 0, r + 6 + o + index, c + 1)

    row = sheet.getRow(r + 6 + o + index)
    row.alignment = { vertical: 'middle', horizontal: 'center' }
    row.getCell(c + 0).value = name
    row.getCell(c + 0).border = border()
    // row.getCell(c + 1).value = total
    // row.getCell(c + 1).border = border()
    row.getCell(c + 2).value = count_normal
    row.getCell(c + 2).border = border()
    row.getCell(c + 3).value = count_alerta
    row.getCell(c + 3).border = border()
    row.getCell(c + 4).value = count_anormal
    row.getCell(c + 4).border = border()
    row.getCell(c + 5).value = anomaly_1
    row.getCell(c + 5).border = border()
    row.getCell(c + 6).value = anomaly_2
    row.getCell(c + 6).border = border()
    row.getCell(c + 7).value = anomaly_3
    row.getCell(c + 7).border = border()
    row.getCell(c + 8).value = anomaly_4
    row.getCell(c + 8).border = border()
    row.getCell(c + 9).value = anomaly_5
    row.getCell(c + 9).border = border()
    row.getCell(c + 10).value = anomaly_6
    row.getCell(c + 10).border = border()
    row.getCell(c + 11).value = anomaly_7
    row.getCell(c + 11).border = border()
    row.getCell(c + 12).value = anomaly_8
    row.getCell(c + 12).border = border()
  })

  sheet.mergeCells(
    r + 6 + o + results.length,
    c + 0,
    r + 6 + o + results.length,
    c + 1,
  )

  row = sheet.getRow(r + 6 + o + results.length)
  row.alignment = { vertical: 'middle', horizontal: 'center' }
  row.getCell(c + 0).value = 'Totales'
  row.getCell(c + 0).border = border()
  row.getCell(c + 0).fill = fill()
  // row.getCell(c + 1).value = total.total
  // row.getCell(c + 1).border = border()
  // row.getCell(c + 1).fill = fill()
  row.getCell(c + 2).value = total.count_normal
  row.getCell(c + 2).border = border()
  row.getCell(c + 2).fill = fill()
  row.getCell(c + 3).value = total.count_alerta
  row.getCell(c + 3).border = border()
  row.getCell(c + 3).fill = fill()
  row.getCell(c + 4).value = total.count_anormal
  row.getCell(c + 4).border = border()
  row.getCell(c + 4).fill = fill()
  anomalyMap.forEach(({ id }, index) => {
    row.getCell(c + 5 + index).value = total[`anomaly_${id}`]
    row.getCell(c + 5 + index).border = border()
    row.getCell(c + 5 + index).fill = fill()
  })

  // Make the cells width fit automátically
  sheet.columns.forEach((column) => {
    var dataMax = 0
    column.eachCell({ includeEmpty: true }, (cell) => {
      var columnLength = cell && cell.value ? cell.value.length + 5 : 0
      if (columnLength > dataMax) {
        dataMax = columnLength
      }
    })
    column.width = dataMax < 10 ? 10 : dataMax
  })

  const imageId = workbook.addImage({
    base64: graphs.current,
    extension: 'png',
  })

  sheet.mergeCells(
    r + 8 + o + results.length,
    c + 0,
    r + 20 + o + results.length,
    c + 2,
  )
  row = sheet.getRow(r + 8 + o + results.length)
  row.getCell(c + 0).border = border()
  sheet.addImage(imageId, {
    tl: { col: c - 0.05, row: r + 7 + o + results.length },
    ext: { width: 520, height: 250 },
  })

  // Download excel file!
  workbook.xlsx.writeBuffer().then(function (data) {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const url = window.URL.createObjectURL(blob)
    const anchor = document.createElement('a')
    anchor.href = url
    anchor.download = 'Reporte - Fallas.xlsx'
    anchor.click()
    // anchor.dispatchEvent(new MouseEvent('click')) // NOTE https://github.com/exceljs/exceljs/issues/354
    window.URL.revokeObjectURL(url)
  })

  const timer = setTimeout(() => {
    window.open('', '_self')
    window.close()
  }, 700)
}

// ----------------------------------------------------------------------------
//
// Canvas
//
// ----------------------------------------------------------------------------

const Canvas = forwardRef(({ data }, ref) => {
  const graph = useRef()

  useEffect(() => {
    const {
      // total,
      // count_normal,
      // count_alerta,
      // count_anormal,
      anomaly_1,
      anomaly_2,
      anomaly_3,
      anomaly_4,
      anomaly_5,
      anomaly_6,
      anomaly_7,
      anomaly_8,
    } = data

    const total =
      anomaly_1 +
      anomaly_2 +
      anomaly_3 +
      anomaly_4 +
      anomaly_5 +
      anomaly_6 +
      anomaly_7 +
      anomaly_8

    new Chartjs(graph.current, {
      type: 'doughnut',
      data: {
        labels: [
          `Normal ${parseFloat((anomaly_1 / total) * 100).toFixed(1)}%`,
          `Desgaste de Componentes ${parseFloat(
            (anomaly_2 / total) * 100,
          ).toFixed(1)}%`,
          `Contaminación Sílice - Desgaste ${parseFloat(
            (anomaly_3 / total) * 100,
          ).toFixed(1)}%`,
          `Dilución Combustible ${parseFloat((anomaly_4 / total) * 100).toFixed(
            1,
          )}%`,
          `Contaminación Agua ${parseFloat((anomaly_5 / total) * 100).toFixed(
            1,
          )}%`,
          `Combustión Deficiente - Desgaste ${parseFloat(
            (anomaly_6 / total) * 100,
          ).toFixed(1)}%`,
          `Código ISO 4406 - Sílice ${parseFloat(
            (anomaly_7 / total) * 100,
          ).toFixed(1)}%`,
          `Contaminación Lubricante ${parseFloat(
            (anomaly_8 / total) * 100,
          ).toFixed(1)}%`,
        ],
        datasets: [
          {
            data: [
              anomaly_1,
              anomaly_2,
              anomaly_3,
              anomaly_4,
              anomaly_5,
              anomaly_6,
              anomaly_7,
              anomaly_8,
            ],
            backgroundColor: [
              'rgba(39, 174, 96, 0.7)',
              'rgba(192, 57, 43, 0.7)',
              'rgba(155, 89, 182, 0.7)',
              'rgba(41, 128, 185, 0.7)',
              'rgba(26, 188, 156, 0.7)',
              'rgba(241, 196, 15, 0.7)',
              'rgba(230, 126, 34, 0.7)',
              'rgba(149, 165, 166, 0.7)',
            ],
            //label: 'Dataset 1',
            borderColor: [
              'rgba(39, 174, 96, 1)',
              'rgba(192, 57, 43, 1)',
              'rgba(155, 89, 182, 1)',
              'rgba(41, 128, 185, 1)',
              'rgba(26, 188, 156, 1)',
              'rgba(241, 196, 15, 1)',
              'rgba(230, 126, 34, 1)',
              'rgba(149, 165, 166, 1)',
            ],
            borderWidth: 4,
          },
        ],
      },
      options: {
        // aspectRatio: 1,
        layout: {
          padding: 0,
          margin: 0,
        },
        responsive: true,
        legend: {
          display: true,
          position: 'right',
          labels: {
            fontSize: 30,
            //            padding: 20,
          },
        },
        animation: {
          duration: 0,
        },
      },
    })

    // I know, I know ... if using once Timeout wasn't horrible enough
    const timer = setTimeout(() => {
      ref.current = graph.current.toDataURL()
    }, 50)
    return () => clearTimeout(timer)
  }, [data])

  return (
    <div
      style={{
        width: '1000px',
        height: '500px',
        //      border: '1px solid red',
        opacity: 0,
      }}
    >
      <canvas ref={graph} />
    </div>
  )
})

// ----------------------------------------------------------------------------
//
// Main render
//
// ----------------------------------------------------------------------------

const ButtonDownload = ({ filter }) => {
  const graphs = useRef()

  const { data, loading } = useQuery(GET_EXCEL, {
    variables: filter,
  })

  useEffect(() => {
    if (data && data.reportFailsExcel && data.reportFailsExcel.data) {
      const timer = setTimeout(() => {
        buildExcel({
          data: data.reportFailsExcel.data,
          filter,
          graphs,
        })
      }, 500)
      return () => clearTimeout(timer)
    }
  }, [data, filter, graphs])

  return (
    <Row>
      <Col xs={12} className="text-center align-self-center mt-5">
        <FontAwesomeIcon
          size="10x"
          icon={faCogs}
          color="lightgray"
          className="mb-5"
        />
        <h1 className="text-secondary font-weight-light">
          Generando archivo Excel
        </h1>
        <hr className="w-50" />
        <p className="text-black-50 font-weight-lighter">
          Si en 5 segundos no se descarga el archivo por favor refresque el
          navegador
        </p>
      </Col>
      {data && data.reportFailsExcel && data.reportFailsExcel.data && (
        <Canvas data={data.reportFailsExcel.data.total} ref={graphs} />
      )}
    </Row>
  )
}

export default ButtonDownload
