import gql from 'graphql-tag'

export const GET_REPORT = gql`
  query GetReportReSampled($from: String!, $to: String!) {
    reportReSampled(from: $from, to: $to)
  }
`

export const GET_EXCEL = gql`
  query GetReportReSampledExcel($from: String!, $to: String!) {
    reportReSampledExcel(from: $from, to: $to)
  }
`
