import { gql } from '../../../../../hooks'

export const READ_CLIENTS = gql`
  query AdministrationReadClientClients($id: ID!) {
    aRead(path: "client/clients", id: $id) {
    }
  }
`

export const UPDATE_CLIENTS = gql`
  mutation AdministrationUpdateClientClients($id: ID!, $data: JSONObject) {
    aUpdate(path: "client/clients", id: $id, data: $data) {
    }
  }
`

export const CREATE_CLIENTS = gql`
  mutation AdministrationCreateClientClients($data: JSONObject) {
    aCreate(path: "client/clients", data: $data) {
    }
  }
`

export const LIST_CLIENTS = gql`
  query AdministrationListClientClients(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(path: "client/clients", filter: $filter, page: $page, limit: $limit) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`

export const LIST_COUNTRIES = gql`
  query {
    countries {
    }
  }
`

export const LIST_REGIONS = gql`
  query ListRegions($id: ID!) {
    regionsByCountry(id: $id) {
    }
  }
`

export const LIST_CITIES = gql`
  query ListCities($id: ID!) {
    citiesByRegion(id: $id) {
    }
  }
`
