import React, { useContext, createContext } from 'react'

import { useRef, useState, useEffect } from '../../../hooks'

// Hook definition
export const FormContext = createContext()
export const FormProvider = FormContext.Provider
export const FormConsumer = FormContext.Consumer

export function useFormContext() {
  return useContext(FormContext)
}

// Actual component
const UIForm = (props = {}) => {
  const {
    ignoreValidation: propIgnoreValidation,
    validateOnChange: propValidateOnChange,
    onSubmit: propOnSubmit,
    onReset: propOnReset,
    onChange: propOnChange,
    action: propAction,
    children: propChildren,
    initialStatus: propInitialStatus,
    initialValues: propInitialValues = {},
    defaultValues: propDefaultValues = {},
    triggerReset: propTriggerReset,
    triggerSubmit: propTriggerSubmit,
    ...rest
    // onChange
  } = props
  const isFirstRender = useRef(true)

  // Following Formik's sh*t
  const [status, setStatus] = useState(propInitialStatus)

  // Triggers so the inputs can act accordanly
  const [reset, setResetState] = useState(false)
  const [submit, setSubmitState] = useState(false)

  // Final values and errors
  const values = useRef({ ...propDefaultValues, ...propInitialValues })
  const errors = useRef({})

  const handleSubmit = (event) => {
    event && event.preventDefault()

    if (!Object.keys(errors.current).length) {
      if (propOnSubmit) propOnSubmit(values.current)
    }

    setSubmitState((prev) => !prev)
  }

  const handleOnChange = () => {
    if (propOnChange) propOnChange(values.current)
  }

  useEffect(() => {
    if (!isFirstRender.current) handleSubmit()
  }, [propTriggerSubmit])

  const handleReset = () => {
    values.current = propDefaultValues
    propOnReset && propOnReset()
    setResetState((prev) => !prev)
  }

  useEffect(() => {
    if (!isFirstRender.current) {
      handleReset()
    }
  }, [propTriggerReset])

  const handleSetValue = (name, value) => {
    if (value === undefined) {
      delete values.current[name]
    } else {
      values.current[name] = value
    }
  }

  const handleSetError = (name, error) => {
    if (error === undefined) {
      delete errors.current[name]
    } else {
      errors.current[name] = error
    }
  }

  useEffect(() => {
    isFirstRender.current = false
  }, [])

  return (
    <FormProvider
      value={{
        reset,
        submit,
        initialValues: propInitialValues,
        defaultValues: propDefaultValues,
        values: values.current,
        errors: errors.current,
        ignoreValidation: propIgnoreValidation,
        validateOnChange: propValidateOnChange,

        setError: handleSetError,
        setValue: handleSetValue,

        status,
        setStatus,

        // onChange: handleChange,

        // ...state,
        // initialValues: initialValues.current,
        // initialErrors: initialErrors.current,
        // initialTouched: initialTouched.current,
        // initialStatus: initialStatus.current,
        // handleBlur,
        // handleChange,
        // handleReset,
        // handleSubmit,
        // resetForm,
        // setErrors,
        // setFormikState,
        // setFieldTouched,
        // setFieldValue,
        // setFieldError,
        // setStatus,
        // setSubmitting,
        // setTouched,
        // setValues,
        // submitForm,
        // validateForm: validateFormWithHighPriority,
        // validateField,
        // isValid,
        // dirty,
        // unregisterField,
        // registerField,
        // getFieldProps,
        // getFieldMeta,
        // getFieldHelpers,
        // validateOnBlur,
        // validateOnChange,
        // validateOnMount,
      }}
    >
      <form
        onSubmit={handleSubmit}
        onReset={handleReset}
        action={propAction || '#'}
        onChange={handleOnChange}
        {...rest}
      >
        {/*({ children, ...props }) => {
          React.isValidElement(children) &&
            React.cloneElement(children, props, [...children])
        }*/}
        {propChildren}
      </form>
    </FormProvider>
  )
}

export default UIForm
