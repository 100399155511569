import { gql } from '../../../../../hooks'

export const READ_LIMITS = gql`
  query AdministrationReadAnalysisLimits($id: ID!) {
    aRead(path: "analysis/limits", id: $id) {
    }
  }
`

export const UPDATE_LIMITS = gql`
  mutation AdministrationUpdateAnalysisLimits($id: ID!, $data: JSONObject) {
    aUpdate(path: "analysis/limits", id: $id, data: $data) {
    }
  }
`

export const CREATE_LIMITS = gql`
  mutation AdministrationCreateAnalysisLimits($data: JSONObject) {
    aCreate(path: "analysis/limits", data: $data) {
    }
  }
`

export const LIST_LIMITS = gql`
  query AdministrationListAnalysisLimits(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(
      path: "analysis/limits"
      filter: $filter
      page: $page
      limit: $limit
    ) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`

export const SEARCH_PROTOCOL = gql`
  query AdministrationSearchProtocol($text: String, $type: Int) {
    aSearch(path: "protocol", filter: { type: $type }, text: $text) {
    }
  }
`

export const SEARCH_CLIENT = gql`
  query AdministrationSearchComponentClient {
    aSearch(path: "client", text: "", filter: { active: 1 }) {
    }
  }
`
