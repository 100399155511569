import React, { forwardRef } from 'react'
import { Col, Row } from 'react-bootstrap'
import ExcelJS from 'exceljs/dist/es5/exceljs.browser'
import Chartjs from 'chart.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCogs } from '@fortawesome/free-solid-svg-icons'

import moment from 'moment'

// Hooks
import { useRef, useEffect, useGlobal, useQuery } from '../../../../../../hooks'

// Queries
import { GET_EXCEL } from './queries'

// Config
import config from '../../../../../../config'

const { APP } = config

// ----------------------------------------------------------------------------
//
// Building Excel
//
// ----------------------------------------------------------------------------

const buildExcel = ({ data, filter, graphs, laboratory }) => {
  const { info, results } = data
  const { from, to } = filter

  const workbook = new ExcelJS.Workbook()

  workbook.creator = 'Servicios Tribológicos'

  // ------------------------------------------------------------------------
  // Helpers
  // ------------------------------------------------------------------------

  const border = (positions = {}) => {
    const { x, y, t, r, l, b } = positions

    const style = 'thin'
    const border = {}

    if (x) {
      border.left = { style }
      border.right = { style }
    }
    if (y) {
      border.top = { style }
      border.bottom = { style }
    }
    if (t) border.top = { style }
    if (r) border.right = { style }
    if (b) border.bottom = { style }
    if (l) border.left = { style }

    if (!Object.keys(positions).length) {
      border.top = { style: 'thin' }
      border.left = { style: 'thin' }
      border.bottom = { style: 'thin' }
      border.right = { style: 'thin' }
    }

    return border
  }

  const fill = ({ argb = 'FFF7F2E0' } = {}) => ({
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb },
  })

  const essaysMap = [
    { id: 3, name: 'Al' },
    { id: 17, name: 'Ba' },
    { id: 19, name: 'Ca' },
    { id: 2, name: 'Cr' },
    { id: 4, name: 'Cu' },
    { id: 1, name: 'Fe' },
    { id: 5, name: 'Pb' },
    { id: 21, name: 'Mg' },
    { id: 12, name: 'Mn' },
    { id: 20, name: 'Mo' },
    { id: 6, name: 'Ni' },
    { id: 22, name: 'P' },
    { id: 14, name: 'K' },
    { id: 15, name: 'Si' },
    { id: 7, name: 'Ag' },
    { id: 13, name: 'Na' },
    { id: 8, name: 'Sn' },
    { id: 9, name: 'Ti' },
    { id: 16, name: 'Zn' },
    { id: 18, name: 'Br' },
    { id: 29, name: 'PQ' },
    { id: 24, name: 'V100' },
    { id: 23, name: 'V40' },
    { id: laboratory === 'centinela' ? 55 : 47, name: 'ISO' },
    { id: 34, name: 'Holl' },
  ]

  results.forEach((result) => {
    // ------------------------------------------------------------------------
    // Excel buiilding
    // ------------------------------------------------------------------------

    const { id, name, components } = result

    const {
      machine_name,
      component_serial_number,
      lubricant_name,
      component_name,
    } = components[0] || {}

    const worst_condition = Math.max(
      ...components.map(({ sample_condition_id }) => sample_condition_id),
    )

    // Create sheet
    const sheet = workbook.addWorksheet(name)

    // Insert info header
    let row
    const r = 2
    const c = 2
    // sheet.mergeCells(r + 0, c + 1, r + 0, c + 2)
    // sheet.mergeCells(r + 1, c + 1, r + 1, c + 2)
    // sheet.mergeCells(r + 2, c + 1, r + 2, c + 2)
    // sheet.mergeCells(r + 3, c + 1, r + 3, c + 2)

    row = sheet.getRow(r + 0)
    row.getCell(c + 0).value = 'Cliente:'
    row.getCell(c + 0).border = border({ t: true, l: true })
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 0).font = { bold: true }
    row.getCell(c + 1).value = info.client_name
    row.getCell(c + 1).border = border({ t: true, r: true })
    row.getCell(c + 1).fill = fill()
    row = sheet.getRow(r + 1)
    row.getCell(c + 0).value = 'Faena:'
    row.getCell(c + 0).border = border({ l: true })
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 0).font = { bold: true }
    row.getCell(c + 1).value = info.site_name
    row.getCell(c + 1).border = border({ r: true })
    row.getCell(c + 1).fill = fill()
    row = sheet.getRow(r + 2)
    row.getCell(c + 0).value = 'Periodo:'
    row.getCell(c + 0).border = border({ l: true })
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 0).font = { bold: true }
    row.getCell(c + 1).value = `${from} ~ ${to}`
    row.getCell(c + 1).border = border({ r: true })
    row.getCell(c + 1).fill = fill()
    row = sheet.getRow(r + 3)
    row.getCell(c + 0).value = 'Encargado:'
    row.getCell(c + 0).border = border({ l: true, b: true })
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 0).font = { bold: true }
    row.getCell(c + 1).value = info.responsible
    row.getCell(c + 1).border = border({ r: true, b: true })
    row.getCell(c + 1).fill = fill()

    row = sheet.getRow(r + 5)
    row.getCell(c + 0).value = 'Equipo:'
    row.getCell(c + 0).border = border()
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 0).font = { bold: true }
    row.getCell(c + 0).border = border({ t: true, l: true })
    row.getCell(c + 1).value = machine_name
    row.getCell(c + 1).border = border({ t: true, r: true })
    row.getCell(c + 1).fill = fill()
    row = sheet.getRow(r + 6)
    row.getCell(c + 0).value = 'Serie:'
    row.getCell(c + 0).border = border({ l: true })
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 0).font = { bold: true }
    row.getCell(c + 1).value = component_serial_number
    row.getCell(c + 1).border = border({ r: true })
    row.getCell(c + 1).fill = fill()
    row = sheet.getRow(r + 7)
    row.getCell(c + 0).value = 'Lubricante:'
    row.getCell(c + 0).border = border({ l: true })
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 0).font = { bold: true }
    row.getCell(c + 1).value = lubricant_name
    row.getCell(c + 1).border = border({ r: true })
    row.getCell(c + 1).fill = fill()
    row = sheet.getRow(r + 8)
    row.getCell(c + 0).value = 'Comp/Sistema:'
    row.getCell(c + 0).border = border({ l: true })
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 0).font = { bold: true }
    row.getCell(c + 1).value = component_name
    row.getCell(c + 1).border = border({ r: true })
    row.getCell(c + 1).fill = fill()
    row = sheet.getRow(r + 9)
    row.getCell(c + 0).value = 'Condición:'
    row.getCell(c + 0).border = border({ l: true })
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 0).font = { bold: true }
    //    row.getCell(c + 1).value = sample_condition_id
    row.getCell(c + 1).border = border({ r: true })
    row.getCell(c + 1).fill = fill()
    switch (worst_condition) {
      case 1:
        row.getCell(c + 1).fill = fill({ argb: 'FF00EE00' })
        break
      case 2:
        row.getCell(c + 1).fill = fill({ argb: 'FFEEEE00' })
        break
      case 3:
        row.getCell(c + 1).fill = fill({ argb: 'FFEE0000' })
        row.getCell(c + 1).font = { color: { argb: 'FFEEEEEE' } }
        break
    }
    row = sheet.getRow(r + 10)
    row.getCell(c + 0).value = 'Acción:'
    row.getCell(c + 0).border = border({ l: true, b: true })
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 0).font = { bold: true }
    row.getCell(c + 1).value = '.'
    row.getCell(c + 1).border = border({ r: true, b: true })
    row.getCell(c + 1).fill = fill()

    row = sheet.getRow(r + 12)
    row.alignment = { vertical: 'middle', horizontal: 'center' }
    row.getCell(c + 0).value = 'Correlativo'
    row.getCell(c + 0).border = border()
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 1).value = 'Nº Muestra'
    row.getCell(c + 1).border = border()
    row.getCell(c + 1).fill = fill()
    row.getCell(c + 2).value = 'Equipo'
    row.getCell(c + 2).border = border()
    row.getCell(c + 2).fill = fill()
    row.getCell(c + 3).value = 'Componente'
    row.getCell(c + 3).border = border()
    row.getCell(c + 3).fill = fill()
    row.getCell(c + 4).value = 'Tag'
    row.getCell(c + 4).border = border()
    row.getCell(c + 4).fill = fill()
    row.getCell(c + 5).value = 'Fecha muestreo'
    row.getCell(c + 5).border = border()
    row.getCell(c + 5).fill = fill()
    row.getCell(c + 6).value = 'Fecha Aprobación'
    row.getCell(c + 6).border = border()
    row.getCell(c + 6).fill = fill()
    row.getCell(c + 7).value = 'Hk Equipo'
    row.getCell(c + 7).border = border()
    row.getCell(c + 7).fill = fill()
    row.getCell(c + 8).value = 'Hk Componente'
    row.getCell(c + 8).border = border()
    row.getCell(c + 8).fill = fill()
    row.getCell(c + 9).value = 'Hk Lubricante'
    row.getCell(c + 9).border = border()
    row.getCell(c + 9).fill = fill()
    row.getCell(c + 10).value = 'Cambio Componente/ Reparación Externa'
    row.getCell(c + 10).border = border()
    row.getCell(c + 10).fill = fill()
    {
      essaysMap.map(({ name }, index) => {
        row.getCell(c + 11 + index).value = name
        row.getCell(c + 11 + index).border = border()
        row.getCell(c + 11 + index).fill = fill()
      })
    }
    row.getCell(c + 11 + essaysMap.length).value = 'Acción'
    row.getCell(c + 11 + essaysMap.length).border = border()
    row.getCell(c + 11 + essaysMap.length).fill = fill()

    // Insert results
    components.forEach((component, index) => {
      const {
        sample_id,
        machine_name,
        component_name,
        component_type_name,
        component_tag,
        sample_condition_id,
        date_sampling,
        date_proposed,
        component_change,
        lubricant_change_refill,
        component_continuity,
        machine_continuity,
        lubricant_continuity,
        suggestion,
        recommendation,
        essays,
      } = component

      row = sheet.getRow(r + 13 + index)
      row.alignment = { vertical: 'middle', horizontal: 'center' }
      row.getCell(c + 0).value = sample_id
      row.getCell(c + 0).border = border()
      row.getCell(c + 1).value = {
        text: sample_id,
        // hyperlink: `https://app.stng.cl//tribology/samples/report/pdf/${sample_id}`,
        hyperlink: `${window.location.origin}/tribology/samples/report/pdf/${sample_id}`,
        tooltip: `Ver informe ${sample_id}`,
      }
      row.getCell(c + 1).border = border()
      row.getCell(c + 2).value = machine_name
      switch (sample_condition_id) {
        case 1:
          row.getCell(c + 2).fill = fill({ argb: 'FF00EE00' })
          break
        case 2:
          row.getCell(c + 2).fill = fill({ argb: 'FFEEEE00' })
          break
        case 3:
          row.getCell(c + 2).fill = fill({ argb: 'FFEE0000' })
          row.getCell(c + 2).font = { color: { argb: 'FFEEEEEE' } }
          break
      }
      row.getCell(c + 2).border = border()
      row.getCell(c + 3).value = component_name
      row.getCell(c + 3).border = border()
      row.getCell(c + 4).value = component_tag
      row.getCell(c + 4).border = border()
      row.getCell(c + 5).value = moment(date_sampling).format(
        'YYYY/MM/DD HH:mm:ss',
      )
      row.getCell(c + 5).border = border()
      row.getCell(c + 6).value = moment(date_proposed).format(
        'YYYY/MM/DD HH:mm:ss',
      )
      row.getCell(c + 6).border = border()
      row.getCell(c + 7).value = machine_continuity
      row.getCell(c + 7).border = border()
      row.getCell(c + 8).value = component_continuity
      row.getCell(c + 8).border = border()
      row.getCell(c + 9).value = lubricant_continuity
      row.getCell(c + 9).border = border()
      row.getCell(c + 10).value = component_change ? 'CC' : '--'
      row.getCell(c + 10).border = border()
      essaysMap.forEach(({ id }, index) => {
        // Special case since Centinela has 2 PQ
        if (id === 29) id = essays[62] ? 62 : id

        if (essays[id]) {
          let { value, lsm, lsc, lim, lic } = essays[id]

          const isISO =
            (laboratory === 'centinela' && id === 55) ||
            (['antofagasta', 'collahuasi', 'quebradablanca'].includes(
              laboratory,
            ) &&
              id === 47)

          if (!isISO) value = parseFloat(value)

          const hasLsc =
            !isISO && !['', null, undefined].includes(lsc) && value > lsc
          const hasLsm =
            !isISO && !['', null, undefined].includes(lsm) && value > lsm
          const hasLim =
            !isISO && !['', null, undefined].includes(lim) && value < lim
          const hasLic =
            !isISO && !['', null, undefined].includes(lic) && value < lic

          row.getCell(c + 11 + index).value = isNaN(value)
            ? value
            : parseFloat(value).toFixed(1)

          if (hasLsc || hasLic) {
            row.getCell(c + 11 + index).fill = fill({ argb: 'FFEE0000' })
            row.getCell(c + 11 + index).font = { color: { argb: 'FFEEEEEE' } }
          } else if (hasLsm || hasLim) {
            row.getCell(c + 11 + index).fill = fill({ argb: 'FFEEEE00' })
          }
        }
        row.getCell(c + 11 + index).border = border()
      })
      if (recommendation)
        row.getCell(c + 11 + essaysMap.length).value = recommendation
      else row.getCell(c + 11 + essaysMap.length).value = suggestion
      row.getCell(c + 11 + essaysMap.length).alignment = { wrapText: true }
      row.getCell(c + 11 + essaysMap.length).border = border()
    })

    // Make the cells width fit automátically
    sheet.columns.forEach((column) => {
      var dataMax = 0
      column.eachCell({ includeEmpty: true }, (cell) => {
        var columnLength = cell && cell.value ? cell.value.length + 5 : 0
        if (columnLength > dataMax) {
          dataMax = columnLength
        }
      })
      column.width = dataMax < 10 ? 10 : dataMax
    })
    sheet.getColumn(c + 11 + essaysMap.length).width = 30

    sheet.mergeCells(r + 0, c + 3, r + 10, c + 8)

    // First graph
    const imageId1 = workbook.addImage({
      base64: graphs.current[`${id}_1`],
      extension: 'png',
    })

    row = sheet.getRow(r + 0)
    row.getCell(c + 3).border = border()
    sheet.addImage(imageId1, {
      tl: { col: c + 2, row: r + 0 },
      ext: { width: 430, height: 200 },
    })

    // Second graph
    const imageId2 = workbook.addImage({
      base64: graphs.current[`${id}_2`],
      extension: 'png',
    })

    row = sheet.getRow(r + 0)
    sheet.addImage(imageId2, {
      tl: { col: c + 5, row: r + 0 },
      ext: { width: 430, height: 200 },
    })
  })

  // Download excel file!
  workbook.xlsx.writeBuffer().then(function (data) {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const url = window.URL.createObjectURL(blob)
    const anchor = document.createElement('a')
    anchor.href = url
    anchor.download = 'Reporte - Estado de Flotas - Equipo.xlsx'
    anchor.click()
    // anchor.dispatchEvent(new MouseEvent('click')) // NOTE https://github.com/exceljs/exceljs/issues/354
    window.URL.revokeObjectURL(url)
  })

  const timer = setTimeout(() => {
    window.open('', '_self')
    window.close()
  }, 700)
}

// ----------------------------------------------------------------------------
//
// Canvas
//
// ----------------------------------------------------------------------------

const Canvas = forwardRef(({ data, order }, ref) => {
  const graph = useRef()

  useEffect(() => {
    const { id, components } = data

    new Chartjs(graph.current, {
      type: 'line',
      data:
        order === 1
          ? {
              // labels: ['Cr', 'Cu', 'Fe', 'Pb', 'Si', 'Na'],
              datasets: [
                {
                  label: 'Cr',
                  data: components.map(({ essays }, index) => ({
                    x: index + 1,
                    y: essays[2] ? parseFloat(essays[2].value) : null,
                  })),
                  borderWidth: 4,
                  borderColor: 'rgba(192, 57, 43, 1)',
                  backgroundColor: 'rgba(192, 57, 43, 0.7)',
                  fill: false,
                  pointRadius: 5,
                },
                {
                  label: 'Cu',
                  data: components.map(({ essays }, index) => ({
                    x: index + 1,
                    y: essays[4] ? parseFloat(essays[4].value) : null,
                  })),
                  borderWidth: 4,
                  borderColor: 'rgba(41, 128, 185, 1)',
                  backgroundColor: 'rgba(41, 128, 185, 0.7)',
                  fill: false,
                  pointRadius: 5,
                },
                {
                  label: 'Fe',
                  data: components.map(({ essays }, index) => ({
                    x: index + 1,
                    y: essays[1] ? parseFloat(essays[1].value) : null,
                  })),
                  borderWidth: 4,
                  borderColor: 'rgba(212, 172, 13, 1)',
                  backgroundColor: 'rgba(212, 172, 13, 0.7)',
                  fill: false,
                  pointRadius: 5,
                },
                {
                  label: 'Pb',
                  data: components.map(({ essays }, index) => ({
                    x: index + 1,
                    y: essays[5] ? parseFloat(essays[5].value) : null,
                  })),
                  borderWidth: 4,
                  borderColor: 'rgba(34, 153, 84, 1)',
                  backgroundColor: 'rgba(34, 153, 84, 0.7)',
                  fill: false,
                  pointRadius: 5,
                },
                {
                  label: 'Si',
                  data: components.map(({ essays }, index) => ({
                    x: index + 1,
                    y: essays[15] ? parseFloat(essays[15].value) : null,
                  })),
                  borderWidth: 4,
                  borderColor: 'rgba(136, 78, 160, 1)',
                  backgroundColor: 'rgba(136, 78, 160, 0.7)',
                  fill: false,
                  pointRadius: 5,
                },
                {
                  label: 'Na',
                  data: components.map(({ essays }, index) => ({
                    x: index + 1,
                    y: essays[13] ? parseFloat(essays[13].value) : null,
                  })),
                  borderWidth: 4,
                  borderColor: 'rgba(127, 140, 141, 1)',
                  backgroundColor: 'rgba(127, 140, 141, 0.7)',
                  fill: false,
                  pointRadius: 5,
                },
              ],
            }
          : {
              datasets: [
                {
                  label: 'Cu',
                  data: components.map(({ essays }, index) => ({
                    x: index + 1,
                    y: essays[4] ? parseFloat(essays[4].value) : null,
                  })),
                  borderWidth: 4,
                  borderColor: 'rgba(41, 128, 185, 1)',
                  backgroundColor: 'rgba(41, 128, 185, 0.7)',
                  fill: false,
                  pointRadius: 5,
                },
                {
                  label: 'Fe',
                  data: components.map(({ essays }, index) => ({
                    x: index + 1,
                    y: essays[1] ? parseFloat(essays[1].value) : null,
                  })),
                  borderWidth: 4,
                  borderColor: 'rgba(212, 172, 13, 1)',
                  backgroundColor: 'rgba(212, 172, 13, 0.7)',
                  fill: false,
                  pointRadius: 5,
                },
                {
                  label: 'Si',
                  data: components.map(({ essays }, index) => ({
                    x: index + 1,
                    y: essays[15] ? parseFloat(essays[15].value) : null,
                  })),
                  borderWidth: 4,
                  borderColor: 'rgba(136, 78, 160, 1)',
                  backgroundColor: 'rgba(136, 78, 160, 0.7)',
                  fill: false,
                  pointRadius: 5,
                },
              ],
            },
      options: {
        scales: {
          xAxes: [
            {
              display: true,
              type: 'linear',
              position: 'bottom',
              scaleLabel: {
                display: true,
              },
              ticks: {
                stepSize: 1,
                fontSize: 30,
                padding: 20,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                fontSize: 30,
                padding: 20,
              },
              beginAtZero: false,
              scaleLabel: {
                display: true,
              },
            },
          ],
        },
        legend: {
          display: true,
          position: 'right',
          labels: {
            fontSize: 30,
            padding: 10,
          },
        },
        animation: {
          duration: 0,
        },
      },
    })

    // I know, I know ... if using once Timeout wasn't horrible enough
    const timer = setTimeout(() => {
      ref.current[`${id}_${order}`] = graph.current.toDataURL()
    }, 50)
    return () => clearTimeout(timer)
  }, [data])

  return (
    <div
      style={{
        width: '1000px',
        height: '500px',
        // border: '1px solid red',
        opacity: 0,
      }}
    >
      <canvas ref={graph} />
    </div>
  )
})

// ----------------------------------------------------------------------------
//
// Main render
//
// ----------------------------------------------------------------------------

const ReportExcel = ({ filter }) => {
  const [{ me }] = useGlobal()

  const { laboratory } = me

  const graphs = useRef({})

  const { data, loading } = useQuery(GET_EXCEL, {
    variables: filter,
  })

  useEffect(() => {
    if (
      data &&
      data.reportFleetStateMachineExcel &&
      data.reportFleetStateMachineExcel.data
    ) {
      const timer = setTimeout(() => {
        buildExcel({
          data: data.reportFleetStateMachineExcel.data,
          filter,
          graphs,
          laboratory,
        })
      }, 500)
      return () => clearTimeout(timer)
    }
  }, [data, filter, graphs, laboratory])

  return (
    <Row>
      <Col xs={12} className="text-center align-self-center mt-5">
        <FontAwesomeIcon
          size="10x"
          icon={faCogs}
          color="lightgray"
          className="mb-5"
        />
        <h1 className="text-secondary font-weight-light">
          Generando archivo Excel
        </h1>
        <hr className="w-50" />
        <p className="text-black-50 font-weight-lighter">
          Si en 5 segundos no se descarga el archivo por favor refresque el
          navegador
        </p>
      </Col>
      {data &&
        data.reportFleetStateMachineExcel &&
        data.reportFleetStateMachineExcel.data &&
        data.reportFleetStateMachineExcel.data.results.map((result, index) => (
          <>
            <Canvas
              key={`canvas-${index}-1`}
              data={result}
              ref={graphs}
              order={1}
            />
            <Canvas
              key={`canvas-${index}-2`}
              data={result}
              ref={graphs}
              order={2}
            />
          </>
        ))}
    </Row>
  )
}

export default ReportExcel
