import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'

// Hooks
// import { useEffect, useLocation, useParams, useState } from '../../../../hooks'
import { useEffect, useLocation, useState } from '../../../../hooks'

// Components
import ReportFormFilter from '../../Form/Filter'
import ReportTableManaging from '../../Table/Managing'
import UILoading from '../../../UI/Loading'

const ReportContainerManaging = () => {
  const [filter, setFilterState] = useState()

  const search = useLocation().search

  // Just finished filtering
  if (filter && search === '')
    return (
      <Redirect
        to={{
          pathname: '/reports/managing',
          search: new URLSearchParams(filter).toString(),
        }}
      />
    )

  const handleFilterChange = (filter) => {
    if (filter) delete filter.emissionType // ???
    setFilterState(filter)
  }

  return (
    <Row>
      <Col className="mb-3">
        {search !== '' ? (
          <ReportTableManaging
            filter={Object.fromEntries(new URLSearchParams(search))}
            onFilterChange={handleFilterChange}
          />
        ) : (
          <ReportFormFilter
            onFilterChange={handleFilterChange}
            name="managing"
          />
        )}
      </Col>
    </Row>
  )
}

export default ReportContainerManaging
