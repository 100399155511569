import React from 'react'
import moment from 'moment'

// Hooks
import { useState, useQuery } from '../../../../../hooks'

// Components
import UIEmpty from '../../../../UI/Empty'
import UILoading from '../../../../UI/Loading'
import UIError from '../../../../UI/Error'
import UIPagination from '../../../../UI/Pagination'
import UITable from '../../../../UI/Table'
import UITableBody from '../../../../UI/Table/Body'
import UITableHead from '../../../../UI/Table/Head'

// Queries
import { LIST_SAMPLES_BY_FILTER } from './queries'

const TribologySamplesSearchList = ({ filter, onIdChange }) => {
  // Pagination
  const [pagination, setPaginationState] = useState({ limit: 25, page: 1 })

  // --------------------------------------------------------------------------
  //
  // Query: Entered samples
  //
  // --------------------------------------------------------------------------

  // if (!filter) filter = {}

  const { data, loading } = useQuery(LIST_SAMPLES_BY_FILTER, {
    fetchPolicy: 'network-only',
    variables: { ...pagination, filter },
    onSuccess: ({ pagination }) => {
      setPaginationState((prev) => ({ ...prev, ...pagination }))
    },
  })

  // --------------------------------------------------------------------------
  //
  // Render
  //
  // --------------------------------------------------------------------------

  // Loading
  if (loading) return <UILoading />

  // Data
  if (data && data.t6SamplesByFilter && data.t6SamplesByFilter.data) {
    const { total, samples } = data.t6SamplesByFilter.data
    const pagination = data.t6SamplesByFilter.pagination

    const handleClick = (id) => {
      onIdChange(id)
    }

    const handlePageChange = (page) => {
      setPaginationState((prev) => ({ ...prev, ...pagination, page }))
    }

    // Main table with the sample list
    return samples.length > 0 ? (
      <>
        <h4 className="text-center mt-0 mb-3">
          <span className="badge badge-secondary px-4">Total: {total}</span>
        </h4>
        <UITable>
          <UITableHead>
            {[
              'Descripción',
              'Estado',
              'Fecha estado',
              'Correlativo',
              'Externo',
              'RUT cliente',
              'Razón social',
            ]}
          </UITableHead>
          <UITableBody>
            {samples.map(({ site, client, stage, sample }) => (
              <tr key={sample.id}>
                <td>{site.name}</td>
                <td>{stage.name}</td>
                <td>
                  {sample.date && moment(sample.date).format('DD/MM/YYYY')}
                </td>
                <td>
                  <button
                    style={{ backgroundColor: 'rgb(0,0,0,0)', border: 'none' }}
                    className="text-decoration-none text-stng-darker"
                    onClick={() => handleClick(sample.id)}
                  >
                    {sample.id}
                  </button>
                </td>
                <td>{sample.external_number}</td>
                <td>
                  {client.rut}-{client.dv}
                </td>
                <td>{client.name}</td>
                {/*<td>
                  <Badge variant={priority.id === '2' ? 'danger' : 'stng'}>
                    {priority.name}
                  </Badge>
                </td>*/}
              </tr>
            ))}
          </UITableBody>
        </UITable>
        <UIPagination pagination={pagination} onClick={handlePageChange} />
      </>
    ) : (
      <UIEmpty />
    )
  }

  // Error
  return <UIError />
}
export default TribologySamplesSearchList
