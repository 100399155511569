import React from 'react'

import { AdministrationContainerTab } from '..'

import Machines from './Machines'
import Brands from './Brands'
import Models from './Models'
import Types from './Types'

const subtabs = [
  { name: 'Equipos', subtab: 'machines', component: Machines },
  { name: 'Marcas', subtab: 'brands', component: Brands },
  { name: 'Modelos', subtab: 'models', component: Models },
  { name: 'Tipo', subtab: 'types', component: Types },
]

const AdministrationContainerMachine = () => (
  <AdministrationContainerTab subtabs={subtabs} />
)

export default AdministrationContainerMachine
