import { gql } from '../../../../../hooks'

export const READ_POINTS = gql`
  query AdministrationReadSamplingPoints($id: ID!) {
    aRead(path: "sampling/points", id: $id) {
    }
  }
`

export const DELETE_POINTS = gql`
  mutation AdministrationDeleteSamplingPoints($id: ID!) {
    aDelete(path: "sampling/points", id: $id) {
    }
  }
`
