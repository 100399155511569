import React from 'react'
import { Link } from 'react-router-dom'
import { Navbar, Image } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAlignLeft } from '@fortawesome/free-solid-svg-icons'
// import logo from './img/tribo-logo-2.png'
import logo from './img/coddi.png'

// Global state
// import { useGlobal } from '../../../../../../hooks'

// Components
import Menu from './Menu'
import UIButton from '../../../../../UI/Button'

const UIWrapperPageFrameNavigationBar = ({ onToggleMenu, toggleMenu }) => {
  // Global state
  //   const [{ isSideMenuShown }, dispatch] = useGlobal()

  // --------------------------------------------------------------------------
  //
  // Handlers
  //
  // --------------------------------------------------------------------------

  const handleClick = () => {
    // dispatch({ type: 'toggleSideMenu' })
    onToggleMenu()
  }

  // --------------------------------------------------------------------------
  //
  // Render
  //
  // --------------------------------------------------------------------------

  return (
    <Navbar variant="dark" bg="stng" expand="lg" id="topNavBar">
      <UIButton variant="stng" onClick={handleClick}>
        <FontAwesomeIcon icon={faAlignLeft} />
      </UIButton>
      {!toggleMenu && (
        <Navbar.Brand className="ml-1 my-0 p-0">
          <Link className="text-decoration-none" to={'/'}>
            <Image src={logo} style={{ height: '2.5rem' }} />
          </Link>
        </Navbar.Brand>
      )}
      <Menu />
    </Navbar>
  )
}
export default UIWrapperPageFrameNavigationBar
