import gql from 'graphql-tag'

export const GET_EXCEL = gql`
  query GetReportFleetStateMachineExcel(
    $id: ID!
    $from: String!
    $to: String!
  ) {
    reportFleetStateMachineExcel(id: $id, from: $from, to: $to)
  }
`
