import { gql } from '../../../../../hooks'

export const READ_IMAGES = gql`
  query AdministrationReadReportImages($id: ID!) {
    aRead(path: "other/images", id: $id) {
    }
  }
`

export const UPDATE_IMAGES = gql`
  mutation AdministrationUpdateReportImages($id: ID!, $data: JSONObject) {
    aUpdate(path: "other/images", id: $id, data: $data) {
    }
  }
`

export const CREATE_IMAGES = gql`
  mutation AdministrationCreateReportImages($data: JSONObject) {
    aCreate(path: "other/images", data: $data) {
    }
  }
`

export const LIST_IMAGES = gql`
  query AdministrationListReportImages(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(path: "other/images", filter: $filter, page: $page, limit: $limit) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`
