import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHammer } from '@fortawesome/free-solid-svg-icons'

const UILogo = ({ message = 'Bienvenido' }) => {
  // src="/imgs/logo/stng-light.png"
  return (
    <div className="text-center py-5 border rounded my-3">
      <img src="/imgs/logo/coddi-light.png" height="100%" alt="CODDI" />

      {message && (
        <p className="text-muted font-weight-light mt-5">{message}</p>
      )}
    </div>
  )
}

export default UILogo
