import React from 'react'

// Hooks
import { useGlobal } from '../../../../../../hooks'

// Components
import UIPanelInfoBlock from '../../../../../UI/Panel/Info/Block'
import UIPanelInfoTable from '../../../../../UI/Panel/Info/Table'
import UIFormInputText from '../../../../../UI/FormReload/Input/Text'
import UIFormInputInteger from '../../../../../UI/FormReload/Input/Integer'

// Common
import { oe } from '../../../../../../common/object/extractor'

import AdministrationInfo, { RefLink } from '../../..'

// Queries
import { READ_CLIENTS_CASES, DELETE_CLIENTS_CASES } from './queries'

const Info = (data) => (
  <UIPanelInfoBlock title="Cliente-Caso">
    <UIPanelInfoTable>
      {[
        [
          'Cliente',
          <RefLink
            id={oe(data, ['client', 'id'])}
            tab={'client'}
            subtab={'clients'}
          >
            {oe(data, ['client', 'name'])}
          </RefLink>,
        ],
        ['Caso', oe(data, ['case', 'name'])],
        ['Correos', oe(data, ['case', 'emails'])],
      ]}
    </UIPanelInfoTable>
  </UIPanelInfoBlock>
)

const Form = () => (
  <>
    <UIFormInputInteger label="ID Cliente" name="client_id" />
    <UIFormInputText label="Nombre Cliente" name="client_name" />
    <UIFormInputInteger label="ID Caso" name="case_id" />
    <UIFormInputText label="Nombre Caso" name="case_name" />
  </>
)

const AdministrationInfoClientClientsCases = ({ ...rest }) => {
  const [{ me }] = useGlobal()

  const no = ![1, 2].includes(me.id_role)

  return (
    <AdministrationInfo
      queries={{ read: READ_CLIENTS_CASES, delete: DELETE_CLIENTS_CASES }}
      views={{ form: Form, info: Info }}
      {...rest}
      noDuplicate={no}
      noUpdate={no}
      noDelete={no}
    />
  )
}

export default AdministrationInfoClientClientsCases
