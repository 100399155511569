import React from 'react'
import { Col, Row } from 'react-bootstrap'

// Hooks
import { useState } from '../../../../hooks'

// Side info
import TribologySamplesListList from '../../../Tribology/Samples/Search/List'
import TribologySamplesListForm from '../../../Tribology/Samples/Search/Form'
import TribologySamplesListInfo from '../../../Tribology/Samples/Search/Info'

const SampleContainerSearch = (props) => {
  const [filter, setFilterState] = useState({})
  const [id, setIdState] = useState()

  const handleIdChange = (id) => {
    setIdState(id)
  }

  const handleFilterChange = (filter) => {
    setFilterState(filter)
  }

  if (id)
    return (
      <Row className="m-4">
        <Col lg={12} className="mb-3">
          <TribologySamplesListForm onFilterChange={handleFilterChange} />
        </Col>
        <Col lg={7} className="mb-3">
          <TribologySamplesListList
            filter={filter}
            onIdChange={handleIdChange}
          />
        </Col>
        <Col lg={5}>
          <TribologySamplesListInfo id={id} />
        </Col>
      </Row>
    )

  return (
    <Row className="m-4">
      <Col lg={12} className="mb-3">
        <TribologySamplesListForm onFilterChange={handleFilterChange} />
      </Col>
      <Col lg={12} className="mb-3">
        <TribologySamplesListList filter={filter} onIdChange={handleIdChange} />
      </Col>
    </Row>
  )
}

export default SampleContainerSearch
