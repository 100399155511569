import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'

// Global state
import { useState, useGlobal } from '../../../../../hooks'

// Components
import SideMenu from './SideMenu'
import NavigationBar from './NavigationBar'
import UIBreadCrumb from '../../../BreadCrumb'
// import UIAlert from '../../../Alert'

const UIWrapperPageFrame = ({ children }) => {
  //  const [{ alerts }, dispatch] = useGlobal()

  const [toggleMenu, setToggleMenuState] = useState(
    window.innerWidth > 920 ? true : false,
  )

  if (!children) return <Redirect to="/" />

  const handleClick = () => {
    setToggleMenuState((prev) => !prev)
  }

  return (
    <>
      <div className="d-flex w-100 h-100 align-items-stretch">
        <SideMenu toggle={toggleMenu} />
        <div className="w-100">
          <NavigationBar onToggleMenu={handleClick} toggleMenu={toggleMenu} />
          <Container fluid className="p-3">
            <Row>
              <Col>
                {/*alerts &&
                  alerts.map(({ message, header, style }, index) => (
                    <UIAlert
                      key={index}
                      header={header}
                      style={style}
                      onClose={() => dispatch({ type: 'removeAlert', index })}
                    >
                      {message}
                    </UIAlert>
                  ))*/}
              </Col>
            </Row>
            <Row>
              <Col>
                <UIBreadCrumb />
              </Col>
            </Row>
            {children}
          </Container>
        </div>
      </div>
    </>
  )
}

export default UIWrapperPageFrame
