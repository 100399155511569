import { gql } from '../../../../../hooks'

export const LIST_CLIENTS = gql`
  query ListClients($id: ID!) {
    t6ClientsByLaboratory(id: $id) {
    }
  }
`

export const LIST_SITES = gql`
  query ListSites($id: ID!) {
    t6SitesByClient(id: $id) {
    }
  }
`

export const LIST_STAGES = gql`
  query ListStages {
    t6Stages {
    }
  }
`
