import React from 'react'
import { Col, Row } from 'react-bootstrap'

// Hooks
import { useState } from '../../../../hooks'

// Components
import ReportFormFilter from '../../Form/Filter'
import ReportTableCritic from '../../Table/Critic'

const ReportContainerCritic = () => {
  const [filter, setFilterState] = useState()

  const handleFilterChange = (filter) => {
    setFilterState(filter)
  }

  return (
    <Row>
      <Col className="mb-3">
        {filter ? (
          <ReportTableCritic
            filter={filter}
            onFilterChange={handleFilterChange}
          />
        ) : (
          <ReportFormFilter onFilterChange={handleFilterChange} name="critic" />
        )}
      </Col>
    </Row>
  )
}

export default ReportContainerCritic
