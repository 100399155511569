import React from 'react'
import { Row, Col, Form } from 'react-bootstrap'

// Hooks
import { useTrigger } from '../../../../hooks'

// Components
import UIButton from '../../../UI/Button'
import UIForm from '../../../UI/FormReload'
import UIFormInputSelect from '../../../UI/FormReload/Input/Select'
import UIFormInputNumber from '../../../UI/FormReload/Input/Number'
import UIFormInputText from '../../../UI/FormReload/Input/Text'
import UIFormSeparation from '../../../UI/FormReload/Separation'
import UIPanelInfoTable from '../../../UI/Panel/Info/Table'

// // Queries
// import {
//   READ_BRANDS,
//   CREATE_BRANDS,
//   UPDATE_BRANDS,
//   LIST_BRANDS,
// } from './queries'
//
// const queries = {
//   read: READ_BRANDS,
//   create: CREATE_BRANDS,
//   update: UPDATE_BRANDS,
//   list: LIST_BRANDS,
// }

const AdministrationFormWaste = ({ onValuesChange, onSubmit }) => {
  const [triggerReset, setTriggerReset] = useTrigger()

  // --------------------------------------------------------------------------
  //
  // Render
  //
  // --------------------------------------------------------------------------

  const handleSubmit = (values) => {
    onValuesChange(values)
    setTriggerReset()
  }

  return (
    <UIForm onSubmit={handleSubmit} triggerReset={triggerReset}>
      <Form.Group as={Row} className="my-1">
        <Col className="d-flex">
          <UIButton onClick={onSubmit}>Ingresar</UIButton>
          <UIButton variant="success" className="ml-2" type="submit">
            Agregar desecho
          </UIButton>
        </Col>
      </Form.Group>
      <Row>
        <Col xs={12}>
          <UIFormSeparation text="Registro de desechos" />
          <UIPanelInfoTable>
            {[
              [
                'Tipo desechos',
                <UIFormInputSelect name="id_waste_type">
                  <option value={1}>Tipo 1</option>
                  <option value={2}>Tipo 2</option>
                </UIFormInputSelect>,
              ],
              [
                'Nombre Lubricante',
                <UIFormInputSelect name="id_lubricant">
                  <option value={1}>Aceite A</option>
                  <option value={2}>Aceite B</option>
                </UIFormInputSelect>,
              ],
              ['Cantidad', <UIFormInputNumber name="amount" required />],
              [
                'Unidad de medida',
                <UIFormInputSelect name="id_unit">
                  <option value={1}>Litros</option>
                  <option value={2}>Galones</option>
                </UIFormInputSelect>,
              ],
            ]}
          </UIPanelInfoTable>
        </Col>
      </Row>
    </UIForm>
  )
}

export default AdministrationFormWaste
