import React from 'react'

// Components
import AdministrationListComponentCollections from '../../../../Administration/List/Component/Collections'
import AdministrationInfoComponentCollections from '../../../../Administration/Info/Component/Collections'
import AdministrationFormComponentCollections from '../../../../Administration/Form/Component/Collections'

import { AdministrationContainer } from '../..'

const AdministrationContainerComponentCollections = () => (
  <AdministrationContainer
    form={AdministrationFormComponentCollections}
    info={AdministrationInfoComponentCollections}
    list={AdministrationListComponentCollections}
  />
)

export default AdministrationContainerComponentCollections
