import { gql } from '../../../../../hooks'

export const READ_POINTS = gql`
  query AdministrationReadSamplingPoints($id: ID!) {
    aRead(path: "sampling/points", id: $id) {
    }
  }
`

export const UPDATE_POINTS = gql`
  mutation AdministrationUpdateSamplingPoints($id: ID!, $data: JSONObject) {
    aUpdate(path: "sampling/points", id: $id, data: $data) {
    }
  }
`

export const CREATE_POINTS = gql`
  mutation AdministrationCreateSamplingPoints($data: JSONObject) {
    aCreate(path: "sampling/points", data: $data) {
    }
  }
`

export const LIST_POINTS = gql`
  query AdministrationListSamplingPoints(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(
      path: "sampling/points"
      filter: $filter
      page: $page
      limit: $limit
    ) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`
