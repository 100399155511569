import { gql } from '../../../../../hooks'

export const DELETE_BRANDS = gql`
  mutation AdministrationDeleteLubricantBrands($id: ID!) {
    aDelete(path: "lubricant/brands", id: $id) {
    }
  }
`

export const LIST_BRANDS = gql`
  query AdministrationListLubricantBrands(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(
      path: "lubricant/brands"
      filter: $filter
      page: $page
      limit: $limit
    ) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`
