import React from 'react'

// Hooks
import { useMutation, useState, useQuery } from '../../../hooks'

// Components
import UILoading from '../../UI/Loading'
import UIError from '../../UI/Error'
import UIPanelInfo from '../../UI/Panel/Info'
import UIPanelInfoSeparation from '../../UI/Panel/Info/Separation'
import UIPanelInfoBlock from '../../UI/Panel/Info/Block'
import UIPanelInfoItem from '../../UI/Panel/Info/Item'
import UIButton from '../../UI/Button'
import UIForm from '../../UI/FormReload'
import UIFormInputText from '../../UI/FormReload/Input/Text'
import UIFormInputSelect from '../../UI/FormReload/Input/Select'

// Common
import { oe } from '../../../common/object/extractor'

// Queries
import { READ_ME, UPDATE_ME } from './queries'

const ProfileForm = ({ onEditToggle }) => {
  const [edit, setEditState] = useState(false)

  // --------------------------------------------------------------------------
  //
  // Read me
  //
  // --------------------------------------------------------------------------

  const { data, loading, error } = useQuery(READ_ME)

  // --------------------------------------------------------------------------
  //
  // Update me
  //
  // --------------------------------------------------------------------------

  const [updateMe] = useMutation(UPDATE_ME, {
    update: (
      cache,
      {
        data: {
          pUpdateMe: { data },
        },
      },
    ) => {
      const { pMe } = cache.readQuery({
        query: READ_ME,
      })

      // Write the cache
      cache.writeQuery({
        query: READ_ME,
        data: { pMe: { data } },
      })
    },
    onSuccessMessage: 'El elemento se creó existosamente',
    onSuccess: () => setEditState(false),
    onErrorMessage: 'No se pudo actualizar el elemento',
  })

  // --------------------------------------------------------------------------
  //
  // Render
  //
  // --------------------------------------------------------------------------

  if (loading) {
    return (
      <UIPanelInfo centered>
        <UILoading />
      </UIPanelInfo>
    )
  }

  if (error) {
    return (
      <UIPanelInfo centered>
        <UIPanelInfoItem>
          <UIError message={error.message} />
        </UIPanelInfoItem>
      </UIPanelInfo>
    )
  }

  if (data && data.pMe) {
    const user = data.pMe.data

    const handleBackClick = () => {
      setEditState(false)
    }

    const handleEditClick = () => {
      setEditState(true)
    }

    const handleSubmit = (values) => {
      updateMe({ variables: { data: values } })
    }

    return (
      <UIPanelInfo style={'stng'}>
        {edit ? (
          <UIForm onSubmit={handleSubmit}>
            <UIPanelInfoBlock title="Perfil">
              <UIFormInputText
                label="Nombre"
                name="name"
                disabled
                placeholder={oe(user, ['name'])}
              />
              <UIFormInputText
                label="Correo"
                name="email"
                initialValue={oe(user, ['email'])}
                placeholder={oe(user, ['email'])}
              />
              <UIFormInputSelect
                label="Sexo"
                name="sex"
                initialValue={oe(user, ['sex'])}
              >
                <option value={'M'}>Masculino</option>
                <option value={'F'}>Femenino</option>
              </UIFormInputSelect>
              <UIFormInputText
                label="RUT"
                name="rut"
                disabled
                placeholder={oe(user, ['rut'])}
              />
              <UIFormInputText
                label="Nombre de usuario"
                name="username"
                disabled
                placeholder={oe(user, ['username'])}
              />
            </UIPanelInfoBlock>
            <UIPanelInfoBlock title="Permisos">
              <UIFormInputText
                label="Rol"
                name="rol"
                disabled
                placeholder={oe(user, ['role', 'name'])}
              />
              <UIFormInputText
                label="Laboratorio"
                name="laboratory"
                disabled
                placeholder={oe(user, ['laboratory', 'name'])}
              />
              <UIFormInputText
                label="Cliente"
                name="client"
                disabled
                placeholder={oe(user, ['client', 'name'])}
              />
              {user.role.id === 5 && (
                <UIFormInputText
                  label="Faena"
                  name="site"
                  disabled
                  placeholder={oe(user, ['site', 'name'])}
                />
              )}
            </UIPanelInfoBlock>
            <UIPanelInfoSeparation />
            <UIPanelInfoBlock>
              <UIButton
                onClick={handleBackClick}
                variant="light"
                className="mr-2"
              >
                Cancelar
              </UIButton>
              <UIButton type="submit" variant="outline-stng">
                Guardar
              </UIButton>
            </UIPanelInfoBlock>
          </UIForm>
        ) : (
          <>
            <UIPanelInfoBlock title="Perfil">
              <UIPanelInfoItem name="Nombre">
                {oe(user, ['name'])}
              </UIPanelInfoItem>
              <UIPanelInfoItem name="Correo">
                {oe(user, ['email'])}
              </UIPanelInfoItem>
              <UIPanelInfoItem name="Sexo">{oe(user, ['sex'])}</UIPanelInfoItem>
              <UIPanelInfoItem name="RUT">{oe(user, ['rut'])}</UIPanelInfoItem>
              <UIPanelInfoItem name="Nombre de usuario">
                {oe(user, ['username'])}
              </UIPanelInfoItem>
            </UIPanelInfoBlock>
            <UIPanelInfoBlock title="Permisos">
              <UIPanelInfoItem name="Rol">
                {oe(user, ['role', 'name'])}
              </UIPanelInfoItem>
              <UIPanelInfoItem name="Laboratorio">
                {oe(user, ['laboratory', 'name'])}
              </UIPanelInfoItem>
              <UIPanelInfoItem name="Cliente">
                {oe(user, ['client', 'name'])}
              </UIPanelInfoItem>
              {user.role.id === 5 && (
                <UIPanelInfoItem name="Faena">
                  {oe(user, ['site', 'name'])}
                </UIPanelInfoItem>
              )}
            </UIPanelInfoBlock>
            <UIPanelInfoSeparation />
            <UIPanelInfoBlock>
              <UIButton onClick={handleEditClick} variant="outline-stng">
                Editar
              </UIButton>
            </UIPanelInfoBlock>
          </>
        )}
      </UIPanelInfo>
    )
  }
}

export default ProfileForm
