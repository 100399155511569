import React from 'react'

// Components
import AdministrationListComponentModels from '../../../../Administration/List/Component/Models'
import AdministrationInfoComponentModels from '../../../../Administration/Info/Component/Models'
import AdministrationFormComponentModels from '../../../../Administration/Form/Component/Models'

import { AdministrationContainer } from '../..'

const AdministrationContainerComponentModels = () => (
  <AdministrationContainer
    form={AdministrationFormComponentModels}
    info={AdministrationInfoComponentModels}
    list={AdministrationListComponentModels}
  />
)

export default AdministrationContainerComponentModels
