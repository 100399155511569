import React from 'react'

// Hooks
import { useState } from '../../../../../hooks'

// Components
import UIFormInputText from '../../../../UI/FormReload/Input/Text'
import UIFormInputSelect from '../../../../UI/FormReload/Input/Select'
import UIFormInputSearch from '../../../../UI/FormReload/Input/Search'
import UIPanelInfoTable from '../../../../UI/Panel/Info/Table'

// Common
import { oe } from '../../../../../common/object/extractor'

import { AdministrationForm } from '../..'

// Queries
import {
  READ_LUBRICANTS,
  CREATE_LUBRICANTS,
  UPDATE_LUBRICANTS,
  LIST_LUBRICANTS,
  LIST_BRANDS,
  LIST_PROTOCOLS,
  LIST_MODELS_BY_BRAND,
  SEARCH_PROTOCOL,
} from './queries'

const queries = {
  read: READ_LUBRICANTS,
  create: CREATE_LUBRICANTS,
  update: UPDATE_LUBRICANTS,
  list: LIST_LUBRICANTS,
}

const CustomForm = ({ action, data, id }) => {
  const [idBrand, setIdBrandState] = useState(
    oe(data, ['brand', 'id'], undefined),
  )

  const handleChange = (event) => {
    setIdBrandState(event.target.value)
  }

  return (
    <UIPanelInfoTable>
      {[
        action === 'update'
          ? [
              'ID',
              <UIFormInputText
                name="id"
                initialValue={id}
                placeholder={id}
                disabled
              />,
            ]
          : [],

        [
          'Marca',
          <UIFormInputSelect
            name="id_lubricant_brand"
            query={LIST_BRANDS}
            onChange={handleChange}
            initialValue={oe(data, ['brand', 'id'])}
            required
          >
            <option value={0}>Seleccione una marca</option>
          </UIFormInputSelect>,
        ],
        [
          'Modelo',
          <UIFormInputSelect
            name="id_lubricant_model"
            query={LIST_MODELS_BY_BRAND}
            variables={{ id: idBrand }}
            skip={!idBrand}
            disabled={!idBrand}
            initialValue={oe(data, ['model', 'id'])}
            required
          >
            <option value={0}>Seleccione una marca</option>
          </UIFormInputSelect>,
        ],
        [
          'Protocolo',
          <UIFormInputSearch
            name="id_protocol"
            query={SEARCH_PROTOCOL}
            initialValue={{
              value: oe(data, ['protocol', 'id']),
              name: oe(data, ['protocol', 'name']),
            }}
            required
          />,
        ],
        // [
        //   'Protocolo',
        //   <UIFormInputSelect
        //     inline
        //     name="id_protocol"
        //     query={LIST_PROTOCOLS}
        //     initialValue={oe(data, ['protocol', 'id'])}
        //     required
        //   >
        //     <option>Seleccione un protocolo</option>
        //   </UIFormInputSelect>,
        // ],
        [
          'Descripción',
          <UIFormInputText
            name="name"
            initialValue={oe(data, ['name'])}
            placeholder={oe(data, ['name'])}
            required
          />,
        ],
      ]}
    </UIPanelInfoTable>
  )
}

const AdministrationFormLubricantLubricants = () => (
  <AdministrationForm name={'lubricante'} queries={queries}>
    <CustomForm />
  </AdministrationForm>
)

export default AdministrationFormLubricantLubricants
