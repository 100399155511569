import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const UILoading = () => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <FontAwesomeIcon className="text-stng-lighter" icon={faSpinner} spin />
    </div>
  )
}

export default UILoading
