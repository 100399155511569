import { gql } from '../../../../../hooks'

export const READ_BRANDS = gql`
  query AdministrationReadLubricantBrands($id: ID!) {
    aRead(path: "lubricant/brands", id: $id) {
    }
  }
`

export const DELETE_BRANDS = gql`
  mutation AdministrationDeleteLubricantBrands($id: ID!) {
    aDelete(path: "lubricant/brands", id: $id) {
    }
  }
`
