import React from 'react'

import { AdministrationList } from '../../../List'

// Queries
import { DELETE_MACHINES, LIST_MACHINES } from './queries'

const table = [
  { th: 'ID', td: 'id' },
  { th: 'Faena', td: ['site', 'name'] },
  { th: 'Descripción', td: 'name' },
]

const queries = { list: LIST_MACHINES, delete: DELETE_MACHINES }

const AdministrationListMachineMachines = () => (
  <AdministrationList table={table} queries={queries} />
)

export default AdministrationListMachineMachines
