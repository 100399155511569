import React from 'react'

// Hooks
import { useParams } from '../../hooks'

// Components
import ReportContainerManaging from '../../components/Report/Container/Managing'
import ReportContainerCritic from '../../components/Report/Container/Critic'
import ReportContainerData from '../../components/Report/Container/Data'
import ReportContainerFails from '../../components/Report/Container/Fails'
import ReportContainerFleetState from '../../components/Report/Container/FleetState'
import ReportContainerInvoicing from '../../components/Report/Container/Invoicing'
import ReportContainerLaboratoryKPI from '../../components/Report/Container/LaboratoryKPI'
import ReportContainerPowerBI from '../../components/Report/Container/PowerBI'
import ReportContainerConcatenator from '../../components/Report/Container/Concatenator'
import ReportContainerReSampled from '../../components/Report/Container/ReSampled'
import ReportContainerUnSampled from '../../components/Report/Container/UnSampled'
import PageNotFound from '../NotFound'

const PageReports = () => {
  const { name } = useParams()

  switch (name) {
    case 'managing':
      return <ReportContainerManaging />
    case 'critic':
      return <ReportContainerCritic />
    case 'data':
      return <ReportContainerData />
    case 'fails':
      return <ReportContainerFails />
    case 'fleet_state':
      return <ReportContainerFleetState />
    case 'invoicing':
      return <ReportContainerInvoicing />
    case 'laboratory_kpi':
      return <ReportContainerLaboratoryKPI />
    case 'power_bi':
      return <ReportContainerPowerBI />
    case 'concatenator':
      return <ReportContainerConcatenator />
    case 're_sampled':
      return <ReportContainerReSampled />
    case 'unsampled':
      return <ReportContainerUnSampled />
    default:
      return <PageNotFound />
  }
}

export default PageReports
