import React from 'react'
import { Form, Col, Row } from 'react-bootstrap'
import moment from 'moment'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
} from '@react-pdf/renderer'

// Hooks
import {
  useGlobal,
  useHistory,
  useRef,
  useState,
  useEffect,
} from '../../../../../hooks'

// Components
import UIButton from '../../../../UI/Button'
import UIFormSeparation from '../../../../UI/FormReload/Separation'

import Graph1 from './graph1'
import Graph2 from './graph2'
import Graph3 from './graph3'
import Graph4 from './graph4'
import Graph5 from './graph5'
import Graph6 from './graph6'

import config from '../../../../../config'

const { AWS } = config

const config2 = {
  main0: {
    backgroundColor: `rgba(75, 192, 192, 0.1)`,
    borderColor: `rgb(75, 192, 192)`,
    borderWidth: 3,
    lineTension: 0.1,
    fill: true,
    pointRadius: 4,
  },
  main1: {
    backgroundColor: `rgba(192, 75, 75, 0.1)`,
    borderColor: `rgb(192, 192, 75)`,
    borderWidth: 3,
    lineTension: 0.1,
    fill: true,
    pointRadius: 4,
  },
  main2: {
    backgroundColor: `rgba(106, 205, 94, 0.1)`,
    borderColor: `rgb(106, 205, 94)`,
    borderWidth: 3,
    lineTension: 0.1,
    fill: true,
    pointRadius: 4,
  },
  main3: {
    backgroundColor: `rgba(255, 91, 43, 0.1)`,
    borderColor: `rgb(255, 91, 43)`,
    borderWidth: 3,
    lineTension: 0.1,
    fill: true,
    pointRadius: 4,
  },
  lsc: {
    borderColor: 'rgb(255, 0, 0)',
    borderWidth: 3,
    fill: false,
    type: 'line',
  },
  lsm: {
    borderColor: 'rgb(255, 173, 51)',
    borderWidth: 3,
    fill: false,
    type: 'line',
  },
  lim: {
    borderColor: 'rgb(255, 173, 51)',
    borderWidth: 3,
    fill: false,
    type: 'line',
  },
  lic: {
    borderColor: 'rgb(255, 0, 0)',
    borderWidth: 3,
    fill: false,
    type: 'line',
  },
  options: {
    animation: {
      duration: 0,
    },
    legend: {
      display: true,
      labels: {
        fontSize: 35,
        padding: 10,
      },
    },
    scales: {
      xAxes: [{ ticks: { fontSize: 35 }, padding: 10 }],
      yAxes: [
        {
          scaleLabel: {
            display: true,
          },
          beginAtZero: false,
          ticks: {
            fontSize: 35,
            padding: 10,
          },
        },
      ],
    },
  },
}

const ReportPDF = ({ data }) => {
  const [{ me }] = useGlobal()

  const history = useHistory()

  const [isReady, setIsReady] = useState()

  const ref1 = useRef()
  const ref2 = useRef()
  const ref3 = useRef()
  const ref4 = useRef()
  const ref5 = useRef()
  const ref6 = useRef()

  const {
    client,
    site,
    responsible,
    sample,
    component,
    machine,
    lubricant,
    results,
    graphs,
    laboratory,
    type,
  } = data

  useEffect(() => {
    Graph1({
      ref: ref1,
      graphs: graphs[1],
      config: config2,
      laboratory: me.laboratory,
      type: type.id,
    })
    Graph2({
      ref: ref2,
      graphs: graphs[2],
      config: config2,
      laboratory: me.laboratory,
      type: type.id,
    })
    Graph3({
      ref: ref3,
      graphs: graphs[3],
      config: config2,
      laboratory: me.laboratory,
      type: type.id,
    })
    Graph4({
      ref: ref4,
      graphs: graphs[4],
      config: config2,
      laboratory: me.laboratory,
      type: type.id,
    })

    if (me.laboratory === 'centinela') {
      Graph5({
        ref: ref5,
        graphs: graphs[5],
        config: config2,
        type: type.id,
      })
      Graph6({
        ref: ref6,
        graphs: graphs[6],
        config: config2,
        type: type.id,
      })
    }

    // Font.register({
    //   family: 'Oswald',
    //   src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
    // })

    // Font.register({
    //   family: 'Roboto',
    //   fonts: [
    //     { src: '/Roboto-Regular.ttf' },
    //     { src: '/Roboto-Bold.ttf', fontWeight: 'bold' },
    //     { src: '/Roboto-Black.ttf', fontWeight: '900' },
    //   ],
    // })

    const timer = setTimeout(() => {
      setIsReady(true)
    }, 500)
    return () => clearTimeout(timer)
  }, [graphs])

  const styles = StyleSheet.create({
    page: {
      paddingHorizontal: 20,
      paddingVertical: 10,
      margin: 0,
      fontFamily: 'Roboto',
      fontSize: 6,
    },
    header: {},
    flexRow: {
      flex: 1,
      flexDirection: 'row',
    },
    footer: {
      paddingHorizontal: 20,
      position: 'absolute',
      bottom: 10,
      left: 0,
      right: 0,
      textAlign: 'center',
    },
    isoLogo: {
      position: 'absolute',
      bottom: 0,
      left: 15,
    },
    separator: {
      width: '100%',
      backgroundColor: '#364f6b',
      paddingVertical: 0.5,
      marginVertical: 2,
    },
    table: {
      display: 'table',
      width: 'auto',
      borderStyle: 'solid',
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
    },
    tableRow: { margin: 'auto', flexDirection: 'row' },
    tableCol: {
      width: '15%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableCell: {
      marginRight: 'auto',
      marginLeft: 3,
      marginTop: 1.5,
      marginBottom: 1.5,
    },
    graphTitle: {
      marginLeft: 5,
      marginTop: me.laboratory === 'centinela' ? 0 : 5,
      marginBottom: me.laboratory === 'centinela' ? 0 : 10,
      fontSize: 9,
    },
    graph: {
      marginLeft: 5,
      // marginVertical: 5,
      // padding: 5,
      width: '100%',
      height: me.laboratory === 'centinela' ? 90 : 100,
      // borderStyle: 'solid',
      // borderWidth: 1,
      // borderColor: '#CCCCCC',
    },
  })

  const pathSignature = '/imgs/signatures'
  const pathLogo = '/imgs/logo/report'

  let imageLogo
  let imageSignature
  let imageApplusLogo = `${pathLogo}/applus_iso_9001-small.png`

  switch (laboratory) {
    case 'antofagasta':
      imageLogo = `${pathLogo}/stng-2.png`
      imageSignature = `${pathSignature}/pedro_cancino_with_logo.png`
      break
    case 'collahuasi':
      imageLogo = `${pathLogo}/collahuasi.jpg`
      imageSignature = `${pathSignature}/pedro_cancino_with_logo.png`
      break
    case 'centinela':
      imageLogo = `${pathLogo}/centinela.png`
      imageSignature = `${pathSignature}/claudio_macaya.png`
      break
    case 'quebradablanca':
      imageLogo = `${pathLogo}/quebrada-blanca.jpg`
      imageSignature = `${pathSignature}/rodrigo_gonzalez_camus.jpg`
      break
    case 'esmax':
      imageLogo = `${pathLogo}/esmax.png`
      imageSignature = `${pathSignature}/esmax-signature.png`
      break
    case 'capstonecopper':
      imageLogo = `${pathLogo}/capstone_copper.jpeg`
      imageSignature = `${pathSignature}/luis_pasten.png`
      break
    default:
      imageLogo = `${pathLogo}/stng-2.png`
      imageSignature = `${pathSignature}/pedro_cancino_with_logo.png`
  }

  const Header = () => (
    <View fixed style={styles.header}>
      {/* Client info ------------------ */}
      <View style={styles.flexRow}>
        <View
          style={{
            width: '38%',
          }}
        >
          <Image src={`${imageLogo}`} style={{ width: 100 }} />
        </View>
        <View
          style={{
            width: '8%',
            fontWeight: 'bold',
          }}
        >
          <Text>Cliente</Text>
          <Text>Faena</Text>
          <Text>Dirección</Text>
          <Text>Encargado</Text>
        </View>
        <View
          style={{
            width: '34%',
          }}
        >
          <Text>{client.name}</Text>
          <Text>{site.name}</Text>
          <Text>{client.address}</Text>
          <Text>{responsible}</Text>
        </View>
        <View
          style={{
            width: '20%',
            textAlign: 'right',
            fontWeight: 'bold',
          }}
        >
          <Text>Informe de Ensayo Nº{sample.id}</Text>
          <Text>Fecha {moment().format('DD-MM-YYYY')}</Text>
        </View>
      </View>
      <View style={styles.separator} />
      {/* Sample info ------------------ */}
      <View style={styles.flexRow}>
        <View
          style={{
            width: '12%',
            fontWeight: 'bold',
          }}
        >
          <Text>Componente</Text>
          <Text>Marca</Text>
          <Text>Modelo</Text>
          <Text>Lubricante en uso</Text>
          <Text>Capacidad sistema</Text>
        </View>
        <View
          style={{
            width: '26%',
          }}
        >
          <Text>{component.name}</Text>
          <Text>{component.brand}</Text>
          <Text>{component.model}</Text>
          <Text>{lubricant.name}</Text>
          <Text>{component.liters && `${parseInt(component.liters)} Lts`}</Text>
        </View>
        <View
          style={{
            width: '8%',
            fontWeight: 'bold',
          }}
        >
          <Text>Instalado en</Text>
          <Text>Marca</Text>
          <Text>Modelo</Text>
          <Text>TAG</Text>
        </View>
        <View
          style={{
            width: '26%',
          }}
        >
          <Text>{machine.name}</Text>
          <Text>{machine.brand}</Text>
          <Text>{machine.model}</Text>
          <Text>{component.tag}</Text>
        </View>
        <View
          style={{
            width: '15%',
            fontWeight: 'bold',
          }}
        >
          <Text>Condición</Text>
          <Text>Tipo de anomalía</Text>
          <Text>N° muestra</Text>
          <Text>Punto muestreo</Text>
          {/*<Text>Procedimiento muestreo</Text>*/}
        </View>
        <View
          style={{
            width: '13%',
          }}
        >
          <Text
            style={{
              textAlign: 'center',
              backgroundColor:
                sample.condition === 'Anormal'
                  ? '#DC143C'
                  : sample.condition === 'Alerta'
                  ? '#FFD700	'
                  : '#9ACD32',
            }}
          >
            {sample.condition}
          </Text>
          <Text>{sample.anomalyType}</Text>
          <Text>{sample.id}</Text>
          <Text>{component.samplingPoint}</Text>
          {/*<Text>{component.samplingProcedure}</Text>*/}
        </View>
      </View>
      <View style={styles.separator} />
    </View>
  )

  const Footer = () => (
    <View fixed style={styles.footer}>
      <View style={styles.separator} />
      <Text>CODDI S.A.</Text>
      <Text>
        José Francisco Blumell 215, Antofagasta - Chile, https://app.coddi.ai
      </Text>
      <Text style={{ paddingTop: 5, paddingLeft: 30 }}>
        La información en el presente informe constituye el resultado de un
        ensayo, acotado únicamente a las muestras analizadas, lo que no permite
        al solicitante reproducir en ninguna forma el logo, nombre o marca
        registrada de CODDI, salvo que exista una autorización previa y por
        escrito de CODDI
      </Text>
      <View fixed style={styles.isoLogo}>
        <Image src={`${imageApplusLogo}`} style={{ width: '30px' }} />
      </View>
    </View>
  )

  const Table = () => (
    <>
      <View style={styles.table}>
        {/* Date sampling */}
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: '63%' }]}>
            <Text style={styles.tableCell}>Fecha muestreo</Text>
          </View>
          <View style={[styles.tableCol, { width: '11%' }]}>
            <Text style={[styles.tableCell, { margin: 'auto' }]}>
              {results.info.reference.dateSampling}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: '13%' }]}>
            <Text style={[styles.tableCell, { margin: 'auto' }]}>
              {results.info.previous.dateSampling}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: '13%' }]}>
            <Text style={[styles.tableCell, { margin: 'auto' }]}>
              {results.info.current.dateSampling}
            </Text>
          </View>
        </View>
        {/* Date receive */}
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: '63%' }]}>
            <Text style={styles.tableCell}>Fecha recepción</Text>
          </View>
          <View style={[styles.tableCol, { width: '11%' }]}>
            <Text style={[styles.tableCell, { margin: 'auto' }]}>
              {results.info.reference.dateReceive}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: '13%' }]}>
            <Text style={[styles.tableCell, { margin: 'auto' }]}>
              {results.info.previous.dateReceive}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: '13%' }]}>
            <Text style={[styles.tableCell, { margin: 'auto' }]}>
              {results.info.current.dateReceive}
            </Text>
          </View>
        </View>
        {/* Date report */}
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: '63%' }]}>
            <Text style={styles.tableCell}>Fecha informe</Text>
          </View>
          <View style={[styles.tableCol, { width: '11%' }]}>
            <Text style={[styles.tableCell, { margin: 'auto' }]}>
              {results.info.reference.dateReport}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: '13%' }]}>
            <Text style={[styles.tableCell, { margin: 'auto' }]}>
              {results.info.previous.dateReport}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: '13%' }]}>
            <Text style={[styles.tableCell, { margin: 'auto' }]}>
              {results.info.current.dateReport}
            </Text>
          </View>
        </View>
        {/* Machine continunuity */}
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: '63%' }]}>
            <Text style={styles.tableCell}>Horas / Km Equipo</Text>
          </View>
          <View style={[styles.tableCol, { width: '11%' }]}>
            <Text style={[styles.tableCell, { margin: 'auto' }]}>
              {results.info.reference.machineContinuity}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: '13%' }]}>
            <Text style={[styles.tableCell, { margin: 'auto' }]}>
              {results.info.previous.machineContinuity}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: '13%' }]}>
            <Text style={[styles.tableCell, { margin: 'auto' }]}>
              {results.info.current.machineContinuity}
            </Text>
          </View>
        </View>
        {/* Component continunuity */}
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: '63%' }]}>
            <Text style={styles.tableCell}>Horas / Km Componente</Text>
          </View>
          <View style={[styles.tableCol, { width: '11%' }]}>
            <Text style={[styles.tableCell, { margin: 'auto' }]}>
              {results.info.reference.componentContinuity}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: '13%' }]}>
            <Text style={[styles.tableCell, { margin: 'auto' }]}>
              {results.info.previous.componentContinuity}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: '13%' }]}>
            <Text style={[styles.tableCell, { margin: 'auto' }]}>
              {results.info.current.componentContinuity}
            </Text>
          </View>
        </View>
        {/* Lubricant continuity */}
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: '63%' }]}>
            <Text style={styles.tableCell}>Horas / Km lubricante</Text>
          </View>
          <View style={[styles.tableCol, { width: '11%' }]}>
            <Text style={[styles.tableCell, { margin: 'auto' }]}>
              {results.info.reference.lubricantContinuity}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: '13%' }]}>
            <Text style={[styles.tableCell, { margin: 'auto' }]}>
              {results.info.previous.lubricantContinuity}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: '13%' }]}>
            <Text style={[styles.tableCell, { margin: 'auto' }]}>
              {results.info.current.lubricantContinuity}
            </Text>
          </View>
        </View>
        {/* --------------- header --------------- */}
        <View style={[styles.tableRow, { backgroundColor: '#F5F5DC' }]}>
          <View style={[styles.tableCol, { width: '29%' }]}>
            <Text style={styles.tableCell}>Ensayo</Text>
          </View>
          <View style={[styles.tableCol, { width: '14%' }]}>
            <Text style={styles.tableCell}>Método análisis</Text>
          </View>
          <View style={[styles.tableCol, { width: '10%' }]}>
            <Text style={[styles.tableCell, { margin: 'auto' }]}>Alerta</Text>
          </View>
          <View style={[styles.tableCol, { width: '10%' }]}>
            <Text style={[styles.tableCell, { margin: 'auto' }]}>Máximo</Text>
          </View>
          <View style={[styles.tableCol, { width: '11%' }]}>
            <Text style={[styles.tableCell, { margin: 'auto' }]}>
              Referencia
            </Text>
          </View>
          <View style={[styles.tableCol, { width: '13%' }]}>
            <Text
              style={[
                styles.tableCell,
                {
                  textAlign: 'center',
                },
              ]}
            >
              {results.info.previous.relativeId}
              {/*
              #{results.info.previous.relativeId}
              {results.info.previous.id ? ` (${results.info.previous.id})` : ''}
              */}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: '13%' }]}>
            <Text
              style={[
                styles.tableCell,
                {
                  textAlign: 'center',
                },
              ]}
            >
              {results.info.current.relativeId}
              {/*
              #{results.info.current.relativeId}
              {results.info.current.id ? ` (${results.info.current.id})` : ''}
              */}
            </Text>
          </View>
        </View>
        {Object.keys(results.essays).map((key, index) => (
          <View key={`essay_class_${index}`}>
            <View style={[styles.tableRow, { backgroundColor: '#F5F5DC' }]}>
              <View style={[styles.tableCol, { width: '100%' }]}>
                <Text style={styles.tableCell}>{key}</Text>
              </View>
            </View>
            {results.essays[key].map((essay, index) => (
              <View style={styles.tableRow} key={`essay_${index}`}>
                <View style={[styles.tableCol, { width: '29%' }]}>
                  <Text style={styles.tableCell}>{essay.essay}</Text>
                </View>
                <View style={[styles.tableCol, { width: '14%' }]}>
                  <Text style={styles.tableCell}>{essay.analysis_method}</Text>
                </View>
                <View style={[styles.tableCol, { width: '10%' }]}>
                  <Text style={[styles.tableCell, { margin: 'auto' }]}>
                    {essay.min}
                  </Text>
                </View>
                <View style={[styles.tableCol, { width: '10%' }]}>
                  <Text style={[styles.tableCell, { margin: 'auto' }]}>
                    {essay.max}
                  </Text>
                </View>
                <View style={[styles.tableCol, { width: '11%' }]}>
                  <Text style={[styles.tableCell, { margin: 'auto' }]}>
                    {essay.value_ref}
                  </Text>
                </View>
                <View style={[styles.tableCol, { width: '13%' }]}>
                  <Text style={[styles.tableCell, { margin: 'auto' }]}>
                    {essay.value_prev}
                  </Text>
                </View>
                <View style={[styles.tableCol, { width: '13%' }]}>
                  <Text style={[styles.tableCell, { margin: 'auto' }]}>
                    {essay.value}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        ))}
      </View>
      <Text style={{ fontWeight: 'bold' }}>Recomendación</Text>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: '100%' }]}>
            <Text style={[styles.tableCell, { margin: '2 3' }]}>
              {sample.recommendation}
            </Text>
          </View>
        </View>
      </View>
    </>
  )

  const Graphs = () => {
    if (me.laboratory === 'centinela') {
      const names = [1, '1'].includes(type.id)
        ? [
            'Viscosidad 100ºC',
            'Desgaste Fierro',
            'Desgaste Plomo',
            'Desgaste Cobre',
            'Silicio',
            'Sodio/Potasio',
          ]
        : [2, '2'].includes(type.id)
        ? [
            'Desgaste Fierro',
            'Desgaste Cobre',
            'Silicio',
            'Zinc/Calcio/Fósforo',
            '% Anticongelantes',
          ]
        : [3, '3'].includes(type.id)
        ? [
            'Viscosidad 40ºC',
            'Desgaste Fierro',
            'Desgaste Cobre',
            'Silicio',
            'Tendencia desgaste',
            'Código ISO',
          ]
        : [4, '4'].includes(type.id)
        ? [
            'Viscosidad 100ºC',
            'Desgaste Fierro',
            'Desgaste Cobre',
            'Silicio',
            'Tendencia desgaste',
            'Código ISO',
          ]
        : []

      return (
        <>
          <View>
            <Text style={styles.graphTitle}>{names[0]}</Text>
            <View style={styles.graph}>
              {isReady ? (
                <Image src={ref1.current.toDataURL()} />
              ) : (
                <Text>cargando ...</Text>
              )}
            </View>
          </View>
          <View>
            <Text style={styles.graphTitle}>{names[1]}</Text>
            <View style={styles.graph}>
              {isReady ? (
                <Image src={ref2.current.toDataURL()} />
              ) : (
                <Text>cargando ...</Text>
              )}
            </View>
          </View>
          <View>
            <Text style={styles.graphTitle}>{names[2]}</Text>
            <View style={styles.graph}>
              {isReady ? (
                <Image src={ref3.current.toDataURL()} />
              ) : (
                <Text>cargando ...</Text>
              )}
            </View>
          </View>
          <View>
            <Text style={styles.graphTitle}>{names[3]}</Text>
            <View style={styles.graph}>
              {isReady ? (
                <Image src={ref4.current.toDataURL()} />
              ) : (
                <Text>cargando ...</Text>
              )}
            </View>
          </View>
          <View>
            <Text style={styles.graphTitle}>{names[4]}</Text>
            <View style={styles.graph}>
              {isReady ? (
                <Image src={ref5.current.toDataURL()} />
              ) : (
                <Text>cargando ...</Text>
              )}
            </View>
          </View>
          {type !== 2 && (
            <View>
              <Text style={styles.graphTitle}>{names[5]}</Text>
              <View style={styles.graph}>
                {isReady ? (
                  <Image src={ref6.current.toDataURL()} />
                ) : (
                  <Text>cargando ...</Text>
                )}
              </View>
            </View>
          )}
        </>
      )
    } else {
      return (
        <>
          <View>
            <Text style={styles.graphTitle}>Desgaste Fierro e Índice PQ</Text>
            <View style={styles.graph}>
              {isReady ? (
                <Image src={ref1.current.toDataURL()} />
              ) : (
                <Text>cargando ...</Text>
              )}
            </View>
          </View>
          <View>
            <Text style={styles.graphTitle}>Desgaste Cromo y Plomo</Text>
            <View style={styles.graph}>
              {isReady ? (
                <Image src={ref2.current.toDataURL()} />
              ) : (
                <Text>cargando ...</Text>
              )}
            </View>
          </View>
          <View>
            <Text style={styles.graphTitle}>
              Viscosidad {graphs[3]['24'] ? '100°C' : '40°C'}
            </Text>
            <View style={styles.graph}>
              {isReady ? (
                <Image src={ref3.current.toDataURL()} />
              ) : (
                <Text>cargando ...</Text>
              )}
            </View>
          </View>
          <View>
            <Text style={styles.graphTitle}>Silicio y Aluminio</Text>
            <View style={styles.graph}>
              {isReady ? (
                <Image src={ref4.current.toDataURL()} />
              ) : (
                <Text>cargando ...</Text>
              )}
            </View>
          </View>
        </>
      )
    }
  }

  // <Image src={MyResponsiveLine} />

  const Signature = () => (
    <View
      style={{
        marginTop: 40,
        marginLeft: ['quebradablanca'].includes(laboratory)
          ? 30
          : laboratory === 'esmax'
          ? 0
          : 60,
      }}
    >
      <Image
        src={imageSignature}
        style={{
          width: ['quebradablanca', 'esmax'].includes(laboratory) ? 160 : 80,
        }}
      />
    </View>
  )

  const Tests = () => (
    <View break>
      {results.patch && results.patch.name ? (
        <View style={[styles.flexRow, { marginTop: 7 }]}>
          <View
            style={{
              width: '40%',
            }}
          >
            <Text style={{ fontSize: 12, fontWeight: 'bold' }}>
              Test de parche
            </Text>
            <Text
              style={{
                padding: '10 15',
                marginTop: 20,
                marginRight: 15,
                textAlign: 'justify',
                textJustify: 'inter-word',
                backgroundColor: 'gainsboro',
              }}
            >
              {results.patch.comment
                ? results.patch.comment
                : 'Sin comentarios'}
            </Text>
          </View>
          <View
            style={{
              width: '60%',
              maxHeight: '250px',
            }}
          >
            <Image
              style={{
                objectFit: 'contain',
                padding: 2,
                marginRight: 'auto',
              }}
              src={{
                uri: `https://s3-sa-east-1.amazonaws.com/${AWS.BUCKET}/${laboratory}/results/images/${results.patch.name}`,
                method: 'GET',
                body: '',
                headers: '',
              }}
            />
          </View>
        </View>
      ) : null}
      {results.magnetic && results.magnetic.name ? (
        <View style={[styles.flexRow, { marginTop: 7 }]}>
          <View
            style={{
              width: '40%',
            }}
          >
            <Text style={{ fontSize: 12, fontWeight: 'bold' }}>
              Tapón magnético
            </Text>
            <Text
              style={{
                borderRight: '1 solid grey',
                padding: '10 15',
                marginTop: 20,
                marginRight: 15,
                textAlign: 'justify',
                textJustify: 'inter-word',
              }}
            >
              {results.magnetic.comment
                ? results.magnetic.comment
                : 'Sin comentarios'}
            </Text>
          </View>
          <View
            style={{
              width: '60%',
              maxHeight: '250px',
            }}
          >
            <Image
              style={{
                objectFit: 'contain',
                padding: 2,
                marginRight: 'auto',
              }}
              src={{
                uri: `https://s3-sa-east-1.amazonaws.com/${AWS.BUCKET}/${laboratory}/results/images/${results.magnetic.name}`,
                method: 'GET',
                body: '',
                headers: '',
              }}
            />
          </View>
        </View>
      ) : null}
      {results.ferrography && results.ferrography.name ? (
        <View style={[styles.flexRow, { marginTop: 7 }]}>
          <View
            style={{
              width: '40%',
            }}
          >
            <Text style={{ fontSize: 12, fontWeight: 'bold' }}>
              Imagen ferromagnética
            </Text>
            <Text
              style={{
                borderRight: '1 solid grey',
                padding: '10 15',
                marginTop: 20,
                marginRight: 15,
                textAlign: 'justify',
                textJustify: 'inter-word',
              }}
            >
              {results.ferrography.comment
                ? results.ferrography.comment
                : 'Sin comentarios'}
            </Text>
          </View>
          <View
            style={{
              width: '60%',
              maxHeight: '250px',
            }}
          >
            <Image
              style={{
                objectFit: 'contain',
                padding: 2,
                marginRight: 'auto',
              }}
              src={{
                uri: `https://s3-sa-east-1.amazonaws.com/${AWS.BUCKET}/${laboratory}/results/images/${results.ferrography.name}`,
                method: 'GET',
                body: '',
                headers: '',
              }}
            />
          </View>
        </View>
      ) : null}
    </View>
  )

  const Report = () => {
    if (me.laboratory === 'centinela') {
      return (
        <Document
          title={`Información de Laboratorio`}
          author={`Servicios Tribológicos`}
        >
          <Page size="LETTER" style={styles.page}>
            <Header />
            <View style={[styles.flexRow, { marginTop: 3 }]}>
              <View
                style={{
                  width: '70%',
                }}
              >
                <Table />
              </View>
              <View
                style={{
                  width: '30%',
                }}
              >
                <Graphs />
              </View>
            </View>
            <Tests />
            {/*<View fixed style={styles.isoLogo}>
              <Image
                src={`${imageApplusLogo}`}
                style={{ width: '30px', paddingBottom: '10px' }}
              />
            </View>*/}
            <Footer />
          </Page>
        </Document>
      )
    } else {
      return (
        <Document
          title={`Información de Laboratorio`}
          author={`Servicios Tribológicos`}
        >
          <Page size="LETTER" style={styles.page}>
            <Header />
            <View style={[styles.flexRow, { marginTop: 3 }]}>
              <View
                style={{
                  width: '70%',
                }}
              >
                <Table />
              </View>
              <View
                style={{
                  width: '30%',
                }}
              >
                <Graphs />
                <Signature />
              </View>
            </View>
            <Tests />
            <Footer />
          </Page>
        </Document>
      )
    }
  }

  const handleBack = () => {
    history.push(`/tribology/samples/report`)
  }

  const fileName = `${sample.id}_${component.tag
    .replace(/ /g, '_')
    .replace(/[^\x00-\x7F]/g, '')}_${site.name
    .replace(/ /g, '_')
    .replace(/[^\x00-\x7F]/g, '')}.pdf`

  return (
    <>
      {isReady && (
        <Row>
          <Col xs={12}>
            <Form.Group as={Row} className="m-1">
              <Col className="d-flex">
                {me.id_role !== 5 && (
                  <UIButton onClick={handleBack} className="mr-2">
                    Volver a la lista
                  </UIButton>
                )}
                {/*<PDFDownloadLink document={<Report />} fileName={fileName}>
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      <UIButton disable variant="secondary">
                        Creando PDF ...
                      </UIButton>
                    ) : (
                      <UIButton>Descargar PDF</UIButton>
                    )
                  }
                      </PDFDownloadLink>*/}
              </Col>
            </Form.Group>
            <UIFormSeparation />
          </Col>
          <Col xs={12}>
            <PDFViewer style={{ width: '100%', height: '700px' }}>
              <Report />
            </PDFViewer>
          </Col>
        </Row>
      )}
      <div style={{ width: '1300px', height: '700px', opacity: 0 }}>
        <canvas ref={ref1} />
      </div>
      <div style={{ width: '1300px', height: '700px', opacity: 0 }}>
        <canvas ref={ref2} />
      </div>
      <div style={{ width: '1300px', height: '700px', opacity: 0 }}>
        <canvas ref={ref3} />
      </div>
      <div style={{ width: '1300px', height: '700px', opacity: 0 }}>
        <canvas ref={ref4} />
      </div>
      <div style={{ width: '1300px', height: '700px', opacity: 0 }}>
        <canvas ref={ref5} />
      </div>
      <div style={{ width: '1300px', height: '700px', opacity: 0 }}>
        <canvas ref={ref6} />
      </div>
    </>
  )
}

export default ReportPDF
