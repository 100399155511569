import React from 'react'
import { Accordion, Card, Tabs, Tab, Badge } from 'react-bootstrap'

// Hooks
import { useState, useQuery } from '../../../../../hooks'

// Components
import UIFormInputText from '../../../../UI/FormReload/Input/Text'
import UIFormInputTextarea from '../../../../UI/FormReload/Input/Textarea'
import UIFormInputSelect from '../../../../UI/FormReload/Input/Select'
import UIFormInputSearch from '../../../../UI/FormReload/Input/Search'
import UIFormInputCheckbox from '../../../../UI/FormReload/Input/Checkbox'
import UIPanelInfoTable from '../../../../UI/Panel/Info/Table'
import UITable from '../../../../UI/Table'
import UITableBody from '../../../../UI/Table/Body'
import UITableHead from '../../../../UI/Table/Head'

// Common
import { oe } from '../../../../../common/object/extractor'

import { AdministrationForm } from '../..'

// Queries
import {
  READ_LOGICS,
  CREATE_LOGICS,
  UPDATE_LOGICS,
  LIST_LOGICS,
  LIST_CONDITIONS,
  LIST_ANOMALIES,
  LIST_TRACKING_STATES,
  LIST_ESSAYS,
  SEARCH_PROTOCOLS,
} from './queries'

const queries = {
  read: READ_LOGICS,
  create: CREATE_LOGICS,
  update: UPDATE_LOGICS,
  list: LIST_LOGICS,
}

const CustomForm = ({ action, data, id }) => {
  const [rules, setRulesState] = useState(
    data
      ? Object.keys(data.essays).map((id) => ({
          id,
          name: data.essays[id],
          limit: data.rules[id]
            ? { id: data.rules[id].rule.id, name: data.rules[id].rule.name }
            : { id: 4, name: 'LSC' },
          active: data.rules[id] ? 1 : 0,
        }))
      : [],
  )

  useQuery(LIST_ESSAYS, {
    //     fetchPolicy: 'network-only',
    skip: id,
    onSuccess: ({ data }) => {
      setRulesState(
        data
          ? Object.keys(data).map((id) => {
              const name = data[id]
              return {
                id,
                name,
                active: 0,
              }
            })
          : [],
      )
    },
  })

  const handleChange = (event, id) => {
    setRulesState(
      rules.map((s) => {
        if (s.id === id) s.active = event.target.checked
        return s
      }),
    )
  }

  const Syntax = ({ text }) => (
    <Badge
      variant="dark"
      style={{ fontSize: '9pt', padding: '5px 6px', margin: '0 2px' }}
    >
      {text}
    </Badge>
  )

  return (
    <>
      <UIPanelInfoTable>
        {[
          action === 'update'
            ? [
                'ID',
                <UIFormInputText inline name="id" placeholder={id} disabled />,
              ]
            : [],
          [
            'Descripción',
            <UIFormInputTextarea
              className="mb-1"
              name="name"
              initialValue={oe(data, ['name'])}
              placeholder={oe(data, ['name'])}
              required
            />,
          ],
          [
            'Protocolo',
            <UIFormInputSearch
              name="id_protocol"
              query={SEARCH_PROTOCOLS}
              initialValue={{
                value: oe(data, ['protocol', 'id']),
                name: oe(data, ['protocol', 'name']),
              }}
              required
            />,
          ],
          [
            'Condisión',
            <UIFormInputSelect
              name="id_sample_condition"
              query={LIST_CONDITIONS}
              defaultValue={oe(data, ['condition', 'id'], 1)}
              required
            />,
          ],
          [
            'Tipo anomalía',
            <UIFormInputSelect
              name="id_anomaly_type"
              query={LIST_ANOMALIES}
              defaultValue={oe(data, ['anomaly', 'id'], 1)}
              required
            />,
          ],
          [
            'Estado de seguimiento',
            <UIFormInputSelect
              name="id_sample_tracking_state"
              query={LIST_TRACKING_STATES}
              defaultValue={oe(data, ['tracking', 'id'], 1)}
              required
            />,
          ],
          [
            'Acción',
            <UIFormInputTextarea
              className="mb-1"
              name="state"
              rows={7}
              initialValue={oe(data, ['state', 'name'])}
              placeholder={oe(data, ['state', 'name'])}
            />,
          ],
          [
            'Comentario',
            <UIFormInputTextarea
              className="mb-1"
              name="suggestion"
              rows={7}
              initialValue={oe(data, ['suggestion', 'name'])}
              placeholder={oe(data, ['suggestion', 'name'])}
            />,
          ],
        ]}
      </UIPanelInfoTable>
      <Accordion>
        <Card>
          <Accordion.Toggle
            as={Card.Header}
            eventKey="0"
            style={{ cursor: 'pointer' }}
            className="text-center"
          >
            Info: Sintaxis sustituible
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <small>
                Tanto las <strong>acciones</strong> como los{' '}
                <strong>comentarios</strong> pueden contener las siguientes
                sintaxis sustituibles:
              </small>
              <UITable bordered className="mt-2">
                <UITableHead>{['Recurso', 'Sintaxis']}</UITableHead>
                <UITableBody>
                  <tr>
                    <td>Nombre ensayo</td>
                    <td>
                      <Syntax
                        text={
                          <>
                            {'{'}EN-<i>ID</i>
                            {'}'}
                          </>
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Valor ensayo</td>
                    <td>
                      <Syntax
                        text={
                          <>
                            {'{'}EV-<i>ID</i>
                            {'}'}
                          </>
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Valor ensayo previo</td>
                    <td>
                      <Syntax
                        text={
                          <>
                            {'{'}EV-P-<i>ID</i>
                            {'}'}
                          </>
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Continuidad componente</td>
                    <td>
                      <Syntax text="{HK-C}" />
                    </td>
                  </tr>
                  <tr>
                    <td>Continuidad lubricante</td>
                    <td>
                      <Syntax text="{HK-L}" />
                    </td>
                  </tr>
                  <tr>
                    <td>Continuidad equipo</td>
                    <td>
                      <Syntax text="{HK-E}" />
                    </td>
                  </tr>
                  <tr>
                    <td>Continuidad previa componente</td>
                    <td>
                      <Syntax text="{HK-P-C}" />
                    </td>
                  </tr>
                  <tr>
                    <td>Continuidad previa lubricante</td>
                    <td>
                      <Syntax text="{HK-P-L}" />
                    </td>
                  </tr>
                  <tr>
                    <td>Continuidad previa equipo</td>
                    <td>
                      <Syntax text="{HK-P-E}" />
                    </td>
                  </tr>
                </UITableBody>
              </UITable>
              <small>
                Donde
                <Syntax text={<i>ID</i>} />
                corresponde al ID del ensayo en <i>Nombre ensayo</i>,{' '}
                <i>Valor ensayo</i> y <i>Valor ensayo previo</i> :
              </small>
              <UITable bordered className="mt-2">
                <UITableHead>{['ID', 'Ensayo']}</UITableHead>
                <UITableBody>
                  {data &&
                    Object.keys(data.essays).map((id_essay, index) => (
                      <tr key={`essay-${index}`}>
                        <td>
                          <Syntax text={id_essay} />
                        </td>
                        <td>{data.essays[id_essay]}</td>
                      </tr>
                    ))}
                </UITableBody>
              </UITable>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      <Tabs className="mt-4">
        <Tab eventKey="active" title="Activas">
          <UITable bordered className="mt-2">
            <UITableHead>{['Ensayo', 'Límite', 'Activo']}</UITableHead>
            <UITableBody>
              {rules.filter(({ active }) => active).length ? (
                rules
                  .filter(({ active }) => active)
                  .map(({ id, name, limit }) => (
                    <tr key={id}>
                      <th scope="row">{name}</th>
                      <td>
                        <UIFormInputSelect
                          name={`${id}_limit`}
                          initialValue={oe(limit, ['id'])}
                        >
                          <option value={4}>LSC</option>
                          <option value={3}>LSM</option>
                          <option value={2}>LIM</option>
                          <option value={1}>LIC</option>
                        </UIFormInputSelect>
                      </td>
                      <td>
                        <UIFormInputCheckbox
                          name={`${id}_active`}
                          initialValue={true}
                          onChange={(v) => handleChange(v, id)}
                        />
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td></td>
                  <td>Normal</td>
                  <td></td>
                </tr>
              )}
            </UITableBody>
          </UITable>
        </Tab>
        <Tab eventKey="inactive" title="Inactivas">
          <UITable bordered className="mt-2">
            <UITableHead>{['Ensayo', 'Activo']}</UITableHead>
            <UITableBody>
              {rules
                .filter(({ active }) => !active)
                .map(({ id, name }) => (
                  <tr key={id}>
                    <th scope="row">{name}</th>
                    <td>
                      <UIFormInputCheckbox
                        name={`${id}_active`}
                        initialValue={false}
                        onChange={(v) => handleChange(v, id)}
                      />
                    </td>
                  </tr>
                ))}
            </UITableBody>
          </UITable>
        </Tab>
      </Tabs>
    </>
  )
}

const AdministrationFormAnalysisLogics = () => {
  const handleTransformValuesOnSubmit = (values) => {
    const {
      id,
      name,
      id_protocol,
      id_sample_condition = 1,
      id_anomaly_type = 1,
      id_sample_tracking_state = 1,
      id_state,
      state = '',
      id_suggestion,
      suggestion = '',
      ...rest
    } = values

    const limit = {}
    const active = {}

    for (const key in rest) {
      const [id, col] = key.split('_')

      if (col === 'active') {
        active[id] = rest[key]
      } else if (col === 'limit') {
        limit[id] = rest[key]
      }
    }

    let logic = Object.keys(limit)
      .map((id) => (active[id] ? `${id};${limit[id]}` : null))
      .filter((v) => v)
      .join(',')

    if (logic === '') logic = '0' // Normal

    return {
      name,
      logic,
      id_protocol,
      id_sample_condition,
      id_anomaly_type,
      id_sample_tracking_state,
      //id_state,
      state,
      //      id_suggestion,
      suggestion,
    }
  }

  return (
    <AdministrationForm
      name={'lógica'}
      queries={queries}
      transformValuesOnSubmit={handleTransformValuesOnSubmit}
    >
      <CustomForm />
    </AdministrationForm>
  )
}

export default AdministrationFormAnalysisLogics
