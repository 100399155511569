import React from 'react'

import { AdministrationList } from '../../../List'

// Queries
import { DELETE_PROTOCOLS, LIST_PROTOCOLS } from './queries'

const table = [
  { th: 'ID', td: 'id' },
  { th: 'Cliente', td: ['client', 'name'] },
  { th: 'Descripción', td: 'name' },
]

const queries = { list: LIST_PROTOCOLS, delete: DELETE_PROTOCOLS }

const AdministrationListComponentProtocols = () => (
  <AdministrationList table={table} queries={queries} />
)

export default AdministrationListComponentProtocols
