import React from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import ReactPDF, { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer'

// Hooks
import { useHistory, useQuery, useGlobal } from '../../../../../hooks'

// Components
import UIButton from '../../../../UI/Button'
import UILoading from '../../../../UI/Loading'
import UIError from '../../../../UI/Error'
import UIEmpty from '../../../../UI/Empty'
import UIForm from '../../../../UI/FormReload'
import UIFormSeparation from '../../../../UI/FormReload/Separation'

// Queries
import { GET_SHIFT_VIEW } from './queries'

import Report from './report'

// --------------------------------------------------------------------------
//
// Render
//
// --------------------------------------------------------------------------

const TribologySamplesShiftChangeForm = ({ queries }) => {
  const [{ me }] = useGlobal()

  const history = useHistory()

  const id_role = queries.get('id_role')
  const date_from = queries.get('date_from')
  const date_to = queries.get('date_to')

  // ------------------------------------------------------------------------
  //
  // Query
  //
  // ------------------------------------------------------------------------

  const { data, loading } = useQuery(GET_SHIFT_VIEW, {
    fetchPolicy: 'network-only',
    variables: { filters: { id_role, date_from, date_to } },
    onErrorMessage: `Error al intentar obtener información `,
  })

  if (loading) return <UILoading />

  const handleBack = () => {
    history.push(`/tribology/samples/shift_change/filter`)
  }

  if (data && data.t7ShiftChangeView && data.t7ShiftChangeView.data) {
    // const { counts, names, comments, manuals, pending } =
    // data.t7ShiftChangeView.data

    // const handleDownload = () => {
    //   ReactPDF.render(
    //     <Report
    //       data={{
    //         ...data.t7ShiftChangeView.data,
    //         id_role,
    //         date_from,
    //         date_to,
    //       }}
    //     />,
    //     ['Entrega_de_turno ', date_from, date_to].join('_'),
    //   )
    // }

    return (
      <UIForm>
        <Form.Group as={Row} className="my-1">
          <Col className="d-flex">
            <UIButton className="mr-1" onClick={handleBack}>
              Atrás
            </UIButton>
            {/*<UIButton onClick={handleDownload}>Descargar PDF</UIButton>
            <PDFDownloadLink
              target="_blank"
              rel="noopener noreferrer"
              document={
                <Report
                  data={{
                    ...data.t7ShiftChangeView.data,
                    id_role,
                    date_from,
                    date_to,
                  }}
                />
              }
              fileName={['Entrega_de_turno ', date_from, date_to].join('_')}
            >
              {({ loading, error }) => {
                if (loading)
                  return (
                    <UIButton disable variant="secondary">
                      Creando PDF ...
                    </UIButton>
                  )

                return <UIButton>Descargar PDF</UIButton>
              }}
            </PDFDownloadLink>*/}
          </Col>
        </Form.Group>
        <UIFormSeparation />
        <Row>
          <Col xs={12}>
            <PDFViewer style={{ width: '100%', height: '700px' }}>
              <Report
                me={me}
                data={{
                  ...data.t7ShiftChangeView.data,
                  id_role,
                  date_from,
                  date_to,
                }}
              />
            </PDFViewer>
          </Col>
        </Row>
      </UIForm>
    )
  }

  return (
    <>
      <UIButton className="mr-1" onClick={handleBack}>
        Atrás
      </UIButton>
      <UIFormSeparation />
      <UIEmpty />
    </>
  )
}

export default TribologySamplesShiftChangeForm
