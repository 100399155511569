import React from 'react'

// Hooks
import { useQuery, useState, useGlobal } from '../../../../../../../hooks'

// Components
import UIForm from '../../../../../../UI/FormReload'
import UIFormInputSelect from '../../../../../../UI/FormReload/Input/Select'

// Queries
import {
  LIST_CLIENTS,
  LIST_SITES,
  LIST_MACHINE_TYPES,
  LIST_MACHINES,
  LIST_COMPONENTS,
} from './queries'

const SampleFormInsertMassiveTabWebFilter = ({ onComponentsChange }) => {
  // Global state
  const [{ me }] = useGlobal()

  const [idClient, setIdClientState] = useState()
  const [idSite, setIdSiteState] = useState()
  const [idMachineType, setIdMachineTypeState] = useState()
  const [idMachine, setIdMachineState] = useState()

  // --------------------------------------------------------------------------
  //
  // Query: Component list
  //
  // --------------------------------------------------------------------------

  useQuery(LIST_COMPONENTS, {
    skip: !idMachine,
    variables: { id: idMachine },
    onSuccess: ({ data }) => {
      onComponentsChange(data)
    },
    onErrorMessage: `Error al intentar obtener lista de componentes`,
  })

  // --------------------------------------------------------------------------
  //
  // Generate client filter
  //
  // --------------------------------------------------------------------------

  const handleClientChange = (event) => {
    setIdClientState(event.target.value)
    setIdSiteState()
    setIdMachineTypeState()
    setIdMachineState()
  }

  const handleSiteChange = (event) => {
    setIdSiteState(event.target.value)
    setIdMachineTypeState()
    setIdMachineState()
  }

  const handleMachineTypeChange = (event) => {
    setIdMachineTypeState(event.target.value)
    setIdMachineState()
  }

  const handleMachineChange = (event) => {
    setIdMachineState(event.target.value)
  }

  // --------------------------------------------------------------------------
  //
  // Render
  //
  // --------------------------------------------------------------------------

  return (
    <UIForm>
      <UIFormInputSelect
        label="Cliente"
        name="id_client"
        query={LIST_CLIENTS}
        variables={{ id: me.id_laboratory }}
        onChange={handleClientChange}
      >
        <option value="">Seleccione un cliente</option>
      </UIFormInputSelect>
      <UIFormInputSelect
        label="Faena"
        name="id_site"
        query={LIST_SITES}
        variables={{ id: idClient }}
        skip={!idClient}
        onChange={handleSiteChange}
        disabled={!idClient}
      >
        <option value="">Seleccione una faena</option>
      </UIFormInputSelect>
      <UIFormInputSelect
        label="Tipo de equipo"
        name="id_machine_type"
        query={LIST_MACHINE_TYPES}
        variables={{ id: idSite }}
        skip={!idSite}
        onChange={handleMachineTypeChange}
        disabled={!idSite}
      >
        <option value="">Seleccione un tipo de equipo</option>
      </UIFormInputSelect>
      <UIFormInputSelect
        label="Equipo"
        name="id_machine"
        query={LIST_MACHINES}
        variables={{ id_site: idSite, id_machine_type: idMachineType }}
        skip={!idMachineType}
        onChange={handleMachineChange}
        disabled={!idMachineType}
      >
        <option value="">Seleccione un equipo</option>
      </UIFormInputSelect>
    </UIForm>
  )
}

export default SampleFormInsertMassiveTabWebFilter
