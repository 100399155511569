import React from 'react'
import moment from 'moment'
import ExcelJS from 'exceljs/dist/es5/exceljs.browser'
// import * as ExcelJS from 'exceljs/dist/exceljs'

// Components
import UIButton from '../../../UI/Button'

// Hooks
import { useGlobal, useLazyQuery } from '../../../../hooks'

// Queries
import { GET_EXCEL } from './queries'

// ----------------------------------------------------------------------------
//
// Building Excel
//
// ----------------------------------------------------------------------------

const buildExcel = ({ data, filter }) => {
  const [{ me }] = useGlobal()

  const { laboratory } = me

  const { info, results } = data
  const { from, to } = filter

  const workbook = new ExcelJS.Workbook()

  workbook.creator = 'Servicios Tribológicos'

  // ------------------------------------------------------------------------
  // Helpers
  // ------------------------------------------------------------------------

  const border = (positions = {}) => {
    const { x, y, t, r, l, b } = positions

    const style = 'thin'
    const border = {}

    if (x) {
      border.left = { style }
      border.right = { style }
    }
    if (y) {
      border.top = { style }
      border.bottom = { style }
    }
    if (t) border.top = { style }
    if (r) border.right = { style }
    if (b) border.bottom = { style }
    if (l) border.left = { style }

    if (!Object.keys(positions).length) {
      border.top = { style: 'thin' }
      border.left = { style: 'thin' }
      border.bottom = { style: 'thin' }
      border.right = { style: 'thin' }
    }

    return border
  }

  const fill = ({ argb = 'FFF7F2E0' } = {}) => ({
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb },
  })

  results.forEach((result) => {
    // ------------------------------------------------------------------------
    // Excel buiilding
    // ------------------------------------------------------------------------

    const { name, rows } = result

    // Create sheet
    const sheet = workbook.addWorksheet(name.replace(/[:\\\/\?\*\[\]#]/, ''))

    // Insert info header
    let row
    let o = 0 // offset
    const r = 2
    const c = 2

    row = sheet.getRow(r + 0)
    row.getCell(c + 0).value = 'Cliente :'
    row.getCell(c + 0).border = border({ t: true, l: true })
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 0).alignment = { horizontal: 'right' }
    row.getCell(c + 0).font = { bold: true }
    row.getCell(c + 1).value = info.client_name
    row.getCell(c + 1).border = border({ t: true, r: true })
    row.getCell(c + 1).fill = fill()

    if (info.site_name) {
      row = sheet.getRow(r + 1 + o)
      row.getCell(c + 0).value = 'Faena :'
      row.getCell(c + 0).border = border({ l: true })
      row.getCell(c + 0).fill = fill()
      row.getCell(c + 0).alignment = { horizontal: 'right' }
      row.getCell(c + 0).font = { bold: true }
      row.getCell(c + 1).value = info.site_name
      row.getCell(c + 1).border = border({ r: true })
      row.getCell(c + 1).fill = fill()
      o += 1
    }

    if (info.machine_type_name) {
      row = sheet.getRow(r + 1 + o)
      row.getCell(c + 0).value = 'Flota :'
      row.getCell(c + 0).border = border({ l: true })
      row.getCell(c + 0).fill = fill()
      row.getCell(c + 0).alignment = { horizontal: 'right' }
      row.getCell(c + 0).font = { bold: true }
      row.getCell(c + 1).value = info.machine_type_name
      row.getCell(c + 1).border = border({ r: true })
      row.getCell(c + 1).fill = fill()
      o += 1
    }

    if (info.machine_name) {
      row = sheet.getRow(r + 1 + o)
      row.getCell(c + 0).value = 'Equipo :'
      row.getCell(c + 0).border = border({ l: true })
      row.getCell(c + 0).fill = fill()
      row.getCell(c + 0).alignment = { horizontal: 'right' }
      row.getCell(c + 0).font = { bold: true }
      row.getCell(c + 1).value = info.machine_name
      row.getCell(c + 1).border = border({ r: true })
      row.getCell(c + 1).fill = fill()
      o += 1
    }

    row = sheet.getRow(r + 1 + o)
    row.getCell(c + 0).value = 'Periodo :'
    row.getCell(c + 0).border = border({ l: true })
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 0).alignment = { horizontal: 'right' }
    row.getCell(c + 0).font = { bold: true }
    row.getCell(c + 1).value = `${from} ~ ${to}`
    row.getCell(c + 1).border = border({ r: true })
    row.getCell(c + 1).fill = fill()
    row = sheet.getRow(r + 2 + o)
    row.getCell(c + 0).value = 'Encargado :'
    row.getCell(c + 0).border = border({ l: true, b: true })
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 0).alignment = { horizontal: 'right' }
    row.getCell(c + 0).font = { bold: true }
    row.getCell(c + 1).value = info.responsible
    row.getCell(c + 1).border = border({ r: true, b: true })
    row.getCell(c + 1).fill = fill()

    row = sheet.getRow(r + 4 + o)
    row.alignment = { vertical: 'middle', horizontal: 'center' }
    row.getCell(c + 0).value = 'Cliente'
    row.getCell(c + 0).border = border()
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 1).value = 'Faena'
    row.getCell(c + 1).border = border()
    row.getCell(c + 1).fill = fill()
    row.getCell(c + 2).value = 'Tipo equipo'
    row.getCell(c + 2).border = border()
    row.getCell(c + 2).fill = fill()
    row.getCell(c + 3).value = 'Equipo'
    row.getCell(c + 3).border = border()
    row.getCell(c + 3).fill = fill()
    row.getCell(c + 4).value = 'Nº de muestra'
    row.getCell(c + 4).border = border()
    row.getCell(c + 4).fill = fill()
    row.getCell(c + 5).value = 'Nº SCAA'
    row.getCell(c + 5).border = border()
    row.getCell(c + 5).fill = fill()
    row.getCell(c + 6).value = 'Fecha muestreo'
    row.getCell(c + 6).border = border()
    row.getCell(c + 6).fill = fill()
    row.getCell(c + 7).value = 'Fecha retiro'
    row.getCell(c + 7).border = border()
    row.getCell(c + 7).fill = fill()
    row.getCell(c + 8).value = 'Fecha ingreso'
    row.getCell(c + 8).border = border()
    row.getCell(c + 8).fill = fill()
    row.getCell(c + 9).value = 'Fecho recepción'
    row.getCell(c + 9).border = border()
    row.getCell(c + 9).fill = fill()
    row.getCell(c + 10).value = 'Fecha ensayo'
    row.getCell(c + 10).border = border()
    row.getCell(c + 10).fill = fill()
    row.getCell(c + 11).value = 'Lubricante'
    row.getCell(c + 11).border = border()
    row.getCell(c + 11).fill = fill()
    row.getCell(c + 12).value = 'TAG Componente'
    row.getCell(c + 12).border = border()
    row.getCell(c + 12).fill = fill()
    row.getCell(c + 13).value = 'Fecha reporte'
    row.getCell(c + 13).border = border()
    row.getCell(c + 13).fill = fill()
    row.getCell(c + 14).value = 'Tiempo Transporte (días)'
    row.getCell(c + 14).border = border()
    row.getCell(c + 14).fill = fill()
    row.getCell(c + 15).value = 'Tiempo Análisis (horas)'
    row.getCell(c + 15).border = border()
    row.getCell(c + 15).fill = fill()
    row.getCell(c + 16).value = 'Tiempo Interpretación (horas)'
    row.getCell(c + 16).border = border()
    row.getCell(c + 16).fill = fill()
    row.getCell(c + 17).value = 'Tiempo Total Laboratorio (horas)'
    row.getCell(c + 17).border = border()
    row.getCell(c + 17).fill = fill()
    row.getCell(c + 18).value = 'Tiempo Total Proceso (horas)'
    row.getCell(c + 18).border = border()
    row.getCell(c + 18).fill = fill()

    rows.forEach((row, index) => {
      const {
        time_transport,
        time_analysis,
        time_interpretation,
        sample_id,
        sample_scaa_number,
        date_sampling,
        date_withdraw,
        date_entry,
        date_receive,
        date_essay,
        date_proposed,
        machine_type_name,
        machine_name,
        client_id,
        client_name,
        lubricant_name,
        component_tag,
        site_name,
        time_total_lab,
        time_total_process,
      } = row

      row = sheet.getRow(r + 5 + o + index)
      row.alignment = { vertical: 'middle', horizontal: 'center' }
      row.getCell(c + 0).value = client_name
      row.getCell(c + 0).border = border()
      row.getCell(c + 1).value = site_name
      row.getCell(c + 1).border = border()
      row.getCell(c + 2).value = machine_type_name
      row.getCell(c + 2).border = border()
      row.getCell(c + 3).value = machine_name
      row.getCell(c + 3).border = border()
      row.getCell(c + 4).value = sample_id
      row.getCell(c + 4).border = border()
      row.getCell(c + 5).value = sample_scaa_number
      row.getCell(c + 5).border = border()
      row.getCell(c + 6).value = date_sampling
      row.getCell(c + 6).border = border()
      row.getCell(c + 7).value = date_withdraw
      row.getCell(c + 7).border = border()
      row.getCell(c + 8).value = date_entry
      row.getCell(c + 8).border = border()
      row.getCell(c + 9).value = date_receive
      row.getCell(c + 9).border = border()
      row.getCell(c + 10).value = date_essay
      row.getCell(c + 10).border = border()
      row.getCell(c + 11).value = lubricant_name
      row.getCell(c + 11).border = border()
      row.getCell(c + 12).value = component_tag
      row.getCell(c + 12).border = border()
      row.getCell(c + 13).value = date_proposed
      row.getCell(c + 13).border = border()
      row.getCell(c + 14).value = Math.round(time_transport)
      row.getCell(c + 14).border = border()
      row.getCell(c + 15).value = Math.round(time_analysis)
      row.getCell(c + 15).border = border()
      row.getCell(c + 16).value = Math.round(time_interpretation)
      row.getCell(c + 16).border = border()
      row.getCell(c + 17).value = Math.round(time_total_lab)
      row.getCell(c + 17).border = border()
      row.getCell(c + 18).value = Math.round(time_total_process)
      row.getCell(c + 18).border = border()
    })

    // Make the cells width fit automátically
    sheet.columns.forEach((column) => {
      var dataMax = 0
      column.eachCell({ includeEmpty: true }, (cell) => {
        var columnLength = cell && cell.value ? cell.value.length + 5 : 0
        if (columnLength > dataMax) {
          dataMax = columnLength
        }
      })
      column.width = dataMax < 10 ? 10 : dataMax
    })

    // const myBase64Image = 'data:image/png;base64,iVBORw0KG...'
    // const imageId2 = workbook.addImage({
    //   base64: myBase64Image,
    //   extension: 'png',
    // })
  })

  // Download excel file!
  workbook.xlsx.writeBuffer().then(function (data) {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const url = window.URL.createObjectURL(blob)
    const anchor = document.createElement('a')
    anchor.href = url
    anchor.download = 'Reporte - KPI Laboratorio.xlsx'
    anchor.click()
    // anchor.dispatchEvent(new MouseEvent('click')) // NOTE https://github.com/exceljs/exceljs/issues/354
    window.URL.revokeObjectURL(url)
  })
}

// ----------------------------------------------------------------------------
//
// Main render
//
// ----------------------------------------------------------------------------

const ButtonDownload = ({ filter }) => {
  const [getExcel, { loading, data }] = useLazyQuery(GET_EXCEL, {
    variables: filter,
  })

  if (
    data &&
    data.reportKPILaboratoryExcel &&
    data.reportKPILaboratoryExcel.data
  ) {
    buildExcel({ data: data.reportKPILaboratoryExcel.data, filter })
  }

  const handleClick = () => {
    getExcel()
  }

  return (
    <UIButton onClick={handleClick} disabled={loading}>
      <img src="/excel.png" height="20" alt="Excel" />
    </UIButton>
  )
}

export default ButtonDownload
