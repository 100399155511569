import gql from 'graphql-tag'

export const GET_EXCEL = gql`
  query GetReportFleetStateExcel(
    $id_client: ID
    $id_site: ID!
    $id_machine_type: ID
    $from: String!
    $to: String!
  ) {
    reportFleetStateExcel(
      id_client: $id_client
      id_site: $id_site
      id_machine_type: $id_machine_type
      from: $from
      to: $to
    )
  }
`
