import React from 'react'

// Components
import AdministrationListComponentBrands from '../../../../Administration/List/Component/Brands'
import AdministrationInfoComponentBrands from '../../../../Administration/Info/Component/Brands'
import AdministrationFormComponentBrands from '../../../../Administration/Form/Component/Brands'

import { AdministrationContainer } from '../..'

const AdministrationContainerComponentBrands = () => (
  <AdministrationContainer
    form={AdministrationFormComponentBrands}
    info={AdministrationInfoComponentBrands}
    list={AdministrationListComponentBrands}
  />
)

export default AdministrationContainerComponentBrands
