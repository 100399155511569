import React from 'react'

// Hooks
import { useGlobal } from '../../../../../hooks'

import { AdministrationList } from '../../../List'

// Queries
import { DELETE_ESSAYS, LIST_ESSAYS } from './queries'

const table = [
  { th: 'ID', td: 'id' },
  { th: 'Clasificación', td: ['classification', 'name'] },
  { th: 'Descripción', td: 'name' },
]

const queries = { list: LIST_ESSAYS, delete: DELETE_ESSAYS }

const AdministrationListSamplingEssays = () => {
  const [{ me }] = useGlobal()

  const no = ![1, 2].includes(me.id_role)

  return (
    <AdministrationList
      table={table}
      queries={queries}
      noCreate={no}
      noUpdate={no}
      noDelete={no}
    />
  )
}

export default AdministrationListSamplingEssays
