import React from 'react'

// Components
import UIPanelInfoBlock from '../../../../UI/Panel/Info/Block'
import UIPanelInfoTable from '../../../../UI/Panel/Info/Table'
import UIFormInputText from '../../../../UI/FormReload/Input/Text'
import UIFormInputInteger from '../../../../UI/FormReload/Input/Integer'

// Common
import { oe } from '../../../../../common/object/extractor'

import AdministrationInfo, { RefLink } from '../..'

// Queries
import { READ_RE_SAMPLING, DELETE_RE_SAMPLING } from './queries'

const Info = (data) => (
  <>
    <UIPanelInfoBlock title="Muestra">
      <UIPanelInfoTable>
        {[
          ['ID', oe(data, ['id'])],
          ['Estado', oe(data, ['status'])],
          ['Laboratorio', oe(data, ['laboratory', 'name'])],
          [
            'ID Muestra',
            <RefLink
              id={oe(data, ['sample', 'id'])}
              tab={'sampling'}
              subtab={'samples'}
            >
              {oe(data, ['sample', 'id'])}
            </RefLink>,
          ],
          ['Número externo', oe(data, ['sample', 'external_number'])],
          ['TAG', oe(data, ['component', 'tag'])],
          ['Descripción', oe(data, ['component', 'name'])],
          [
            'Equipo',
            <RefLink
              id={oe(data, ['machine', 'id'])}
              tab={'machine'}
              subtab={'machines'}
            >
              {oe(data, ['machine', 'name'])}
            </RefLink>,
          ],
          [
            'Faena',
            <RefLink
              id={oe(data, ['site', 'id'])}
              tab={'client'}
              subtab={'sites'}
            >
              {oe(data, ['site', 'name'])}
            </RefLink>,
          ],
          [
            'Cliente',
            <RefLink
              id={oe(data, ['client', 'id'])}
              tab={'client'}
              subtab={'clients'}
            >
              {oe(data, ['client', 'name'])}
            </RefLink>,
          ],
        ]}
      </UIPanelInfoTable>
    </UIPanelInfoBlock>
  </>
)

const Form = () => (
  <>
    <UIFormInputText label="ID" name="id" />
    <UIFormInputText label="Laboratorio" name="id_laboratory" />
    <UIFormInputInteger label="ID Muestra" name="id_sample" />
    <UIFormInputInteger label="Número externo" name="external_number" />
  </>
)

const AdministrationInfoComponentComponents = ({ ...rest }) => (
  <AdministrationInfo
    queries={{ read: READ_RE_SAMPLING, delete: DELETE_RE_SAMPLING }}
    views={{ form: Form, info: Info }}
    noUpdate
    noDuplicate
    {...rest}
  />
)

export default AdministrationInfoComponentComponents
