import { gql } from '../../../../../hooks'

export const READ_PROCEDURES = gql`
  query AdministrationReadSamplingProcedures($id: ID!) {
    aRead(path: "sampling/procedures", id: $id) {
    }
  }
`

export const DELETE_PROCEDURES = gql`
  mutation AdministrationDeleteSamplingProcedures($id: ID!) {
    aDelete(path: "sampling/procedures", id: $id) {
    }
  }
`
