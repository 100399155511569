import React from 'react'
import { Col, Row } from 'react-bootstrap'

import { useState, useParams } from '../../../hooks'

import InspectionsFieldList from '../../../components/Inspections/Field/List'
import InspectionsFieldForm from '../../../components/Inspections/Field/Form'
import InspectionsFieldInfo from '../../../components/Inspections/Field/Info'

const PageInspectionsField = () => {
  const { id_inspection } = useParams()
  const [showCreateForm, setShowCreateForm] = useState(false)

  if (id_inspection)
    return (
      <Row>
        <Col lg={12} className="mb-3">
          <InspectionsFieldForm id_inspection={id_inspection} />
        </Col>
      </Row>
    )

  return (
    <Row>
      <Col lg={showCreateForm ? 8 : 12} className="mb-3">
        <InspectionsFieldList
          setShowCreateForm={setShowCreateForm}
          showCreateForm={showCreateForm}
        />
      </Col>
      {showCreateForm && (
        <Col lg={4}>
          <InspectionsFieldInfo setShowCreateForm={setShowCreateForm} />
        </Col>
      )}
    </Row>
  )
}

export default PageInspectionsField
