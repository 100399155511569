import React from 'react'
import { Form, Col, Row } from 'react-bootstrap'

// Hooks
import {
  useGlobal,
  useState,
  useQuery,
  useTrigger,
  useMutation,
} from '../../../../../hooks'

// Forms
import UIButton from '../../../../UI/Button'
import UIForm from '../../../../UI/FormReload'
import UIFormInputDate from '../../../../UI/FormReload/Input/Date'
import UIFormInputNumber from '../../../../UI/FormReload/Input/Number'
import UIFormInputSearch from '../../../../UI/FormReload/Input/Search'
import UIFormInputSelect from '../../../../UI/FormReload/Input/Select'
import UIFormInputText from '../../../../UI/FormReload/Input/Text'
import UIFormInputTextarea from '../../../../UI/FormReload/Input/Textarea'
import UIFormInputCheckboxSwitch from '../../../../UI/FormReload/Input/Checkbox/Switch'
import UIFormSeparation from '../../../../UI/FormReload/Separation'

// Queries
import {
  LIST_LUBRICANT_CHANGE_REFILL_MOTIVES,
  LIST_COMPONENT_CHANGE_MOTIVES,
  LIST_LUBIRCANT_CHANGE_REFILLS,
  COMPONENT_SEARCH,
  ENTER_SAMPLE,
  LIST_CLIENTS,
  LIST_SITES,
  GET_SAMPLE_LABEL_INFO,
} from './queries'

const SampleFormInsertProgrammed = ({
  onIdComponentChange,
  onSampleChange,
  sample,
}) => {
  const [{ me }] = useGlobal()

  const [triggerReset, setTriggerReset] = useTrigger()

  const [idClient, setIdClient] = useState()
  const [idSite, setIdSite] = useState()
  const [idLubricantChangeRefill, setIdLubricantChangeRefill] = useState()
  const [dateInterCheck, setDateInterCheck] = useState({})

  // --------------------------------------------------------------------------
  //
  // Query: Transform excel to the right format
  //
  // --------------------------------------------------------------------------

  const { data: dataLabel } = useQuery(GET_SAMPLE_LABEL_INFO, {
    skip: !(sample && sample.sample && sample.sample.id),
    variables: { id: sample && sample.sample && sample.sample.id },
    onErrorMessage: `Error al intentar obtener la información en las muestras`,
  })

  // --------------------------------------------------------------------------
  //
  // Mutation: Insert sample
  //
  // --------------------------------------------------------------------------

  const [t1EnterSample] = useMutation(ENTER_SAMPLE, {
    fetchPolicy: 'no-cache',
    onSuccess: ({ data }) => onSampleChange(data),
  })

  // --------------------------------------------------------------------------
  //
  // Handlers
  //
  // --------------------------------------------------------------------------

  const handleSubmit = async (values) => {
    try {
      await t1EnterSample({ variables: { data: values } })
      setTriggerReset()
      setIdLubricantChangeRefill()
    } catch (error) {
      console.log(error)
    }
  }

  const handleIdComponentChange = (id) => {
    onIdComponentChange(id)
  }

  const handleClick = () => {
    const { sample, component, lubricant, client } =
      dataLabel.t1SampleLabelInfo.data

    const text = `ON
Q383,16
q799
S3
D10
ZT
JF
oR

N
I8,A,001
A33,131,0,1,1,2,N,"Número Muestra:"
A33,201,0,1,1,2,N,"Lubricante:  "
A435,77,0,1,2,2,N,"${sample.priority}"
B640,353,3,E30,3,0,117,B,"${'0'.repeat(
      12 - sample.id.toString().length,
    )}${sample.id.toString()}"
A34,269,0,1,1,2,N,"Cliente:"
A209,124,0,1,2,3,N,"${sample.id}"
A33,167,0,1,1,2,N,"TAG:  "
A208,160,0,1,2,3,N,"${component.tag}"
A455,32,0,1,1,2,N,"${sample.date}"
A209,228,0,1,2,3,N,""
A211,272,0,3,1,1,N,"${client}"
A211,293,0,3,1,1,N,""
A209,194,0,1,2,3,N,"${lubricant.name}"
P1

`

    const now = new Date()
    const fileName = `Impresion_dia_${now.getDate()}_hora_${Math.round(
      now.getTime() / 1000,
    )}.txt`

    const element = document.createElement('a')
    const file = new Blob([text], {
      type: 'text/plain;charset=utf-8',
    })
    element.href = URL.createObjectURL(file)
    element.download = fileName
    document.body.appendChild(element)
    element.click()
  }

  // --------------------------------------------------------------------------
  //
  // Render
  //
  // --------------------------------------------------------------------------

  const handleClientChange = (event) => {
    setIdClient(parseInt(event.target.value) || undefined)
    setIdSite()
  }

  const handleSiteChange = (event) => {
    setIdSite(parseInt(event.target.value) || undefined)
  }

  const handleLubricantChangeRefillChange = (event) => {
    setIdLubricantChangeRefill(parseInt(event.target.value))
  }

  const handleOnSamplingDateChange = (samplingDate, withdrawDate) => {
    const now = new Date()

    if (samplingDate.getTime() > now.getTime())
      return 'La fecha de muestreo no puede ser mayor a la fecha actual'

    if (withdrawDate && samplingDate.getTime() > withdrawDate.getTime())
      return 'La fecha de muestreo no puede ser mayor a fecha de retiro'

    setDateInterCheck((prev) => ({
      ...prev,
      sampling: samplingDate,
    }))
  }

  const handleOnWithdrawDateChange = (withdrawDate, samplingDate) => {
    const now = new Date()

    if (withdrawDate.getTime() > now.getTime())
      return 'La fecha de retiro no puede ser mayor a la fecha actual'

    if (samplingDate && withdrawDate.getTime() < samplingDate.getTime())
      return 'La fecha de retiro no puede ser menor a fecha de muestreo'

    setDateInterCheck((prev) => ({
      ...prev,
      withdraw: withdrawDate,
    }))
  }

  return (
    <UIForm onSubmit={handleSubmit} triggerReset={triggerReset}>
      <Form.Group as={Row} className="m-1">
        <Col className="d-flex">
          <UIButton type="submit">Insertar</UIButton>
          <UIButton
            disabled={!sample}
            onClick={handleClick}
            className="ml-2"
            variant="secondary"
          >
            Descargar etiquetas
          </UIButton>
        </Col>
      </Form.Group>
      <UIFormSeparation />

      <UIFormInputSelect
        label="Cliente"
        name="id_client"
        query={LIST_CLIENTS}
        variables={{ id: me.id_laboratory }}
        onChange={handleClientChange}
      >
        <option value={0}>Seleccione un cliente</option>
      </UIFormInputSelect>
      <UIFormInputSelect
        label="Faena"
        name="id_site"
        query={LIST_SITES}
        variables={{ id: idClient }}
        onChange={handleSiteChange}
        disabled={!idClient}
      />
      <UIFormInputSearch
        required
        label="Tag"
        name="id_component"
        query={COMPONENT_SEARCH}
        variables={{
          filter: {
            id_client: idClient || '',
            id_site: idSite || '',
          },
        }}
        onClick={handleIdComponentChange}
      />
      <UIFormSeparation text="Información general" />
      <UIFormInputText label="Código externo" name="external_number" />
      <UIFormInputText label="Orden de trabajo" name="work_order" />
      <UIFormInputDate
        required
        label="Fecha de muestreo"
        name="date_sampling"
        validator={(date) =>
          handleOnSamplingDateChange(new Date(date), dateInterCheck.withdraw)
        }
        triggerErrorCheck={dateInterCheck.withdraw}
      />
      <UIFormInputDate
        required
        showTime
        label="Fecha de retiro"
        name="date_withdraw"
        validator={(date) =>
          handleOnWithdrawDateChange(new Date(date), dateInterCheck.sampling)
        }
        triggerErrorCheck={dateInterCheck.sampling}
      />
      <UIFormInputNumber label="H/K Equipo" name="machine_continuity" />
      <UIFormInputNumber label="H/K Componente" name="component_continuity" />
      <UIFormInputNumber label="H/K Lubricante" name="lubricant_continuity" />
      <UIFormInputCheckboxSwitch
        label="Prioridad"
        name="id_sample_priority"
        on="Urgente"
        off="Normal"
      />
      <UIFormInputTextarea label="Observaciones" name="comment" />
      <UIFormSeparation text="Cambio de componente" />
      <UIFormInputSelect
        label="Motivo"
        name="id_component_change_motive"
        query={LIST_COMPONENT_CHANGE_MOTIVES}
      />
      <UIFormSeparation text="Lubricante" />
      <UIFormInputSelect
        label="Cambio o relleno"
        name="id_lubricant_change_refill"
        query={LIST_LUBIRCANT_CHANGE_REFILLS}
        onChange={handleLubricantChangeRefillChange}
      />
      {idLubricantChangeRefill > 1 && (
        <>
          <UIFormInputSelect
            label="Motivo"
            name="id_lubricant_change_refill_motive"
            query={LIST_LUBRICANT_CHANGE_REFILL_MOTIVES}
          />
          <UIFormInputNumber
            label="Litros"
            name="lubricant_change_refill_liters"
          />
          <UIFormInputNumber
            label="Horas"
            name="lubricant_change_refill_hours"
          />
        </>
      )}
    </UIForm>
  )
}

export default SampleFormInsertProgrammed
