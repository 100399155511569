import React from 'react'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

// Hooks
import { useGlobal } from '../../../../../hooks'

// Components
import UIPanelInfoBlock from '../../../../UI/Panel/Info/Block'
import UIPanelInfoTable from '../../../../UI/Panel/Info/Table'
import UIFormInputText from '../../../../UI/FormReload/Input/Text'
import UIFormInputInteger from '../../../../UI/FormReload/Input/Integer'

// Common
import { oe } from '../../../../../common/object/extractor'

import AdministrationInfo, { RefLink } from '../..'

// Queries
import { READ_SITES, DELETE_SITES } from './queries'

const popoverTitle = (
  <Popover id="popover-basic">
    <Popover.Title as="h3">Título</Popover.Title>
    <Popover.Content>
      Nombre de la faena utilizado en el título del archivo Excel. Es un valor
      único y sensible a mayúsculas, dado que identifica de manera inequívoca a
      la faena.
    </Popover.Content>
  </Popover>
)

const popoverAcronymous = (
  <Popover id="popover-basic">
    <Popover.Title as="h3">Acrónimo</Popover.Title>
    <Popover.Content>
      Identificador de 2 letras usado para construir y validar el TAG
    </Popover.Content>
  </Popover>
)

const Info = (data) => (
  <>
    <UIPanelInfoBlock title="Faena">
      <UIPanelInfoTable>
        {[
          ['ID', oe(data, ['id'])],
          [
            'Cliente',
            <RefLink
              id={oe(data, ['client', 'id'])}
              tab={'client'}
              subtab={'clients'}
            >
              {oe(data, ['client', 'name'])}
            </RefLink>,
          ],
          ['Descripción', oe(data, ['name'])],
          ['Latitud', oe(data, ['latitude'])],
          ['Longitud', oe(data, ['longitude'])],
          [
            <>
              Título{' '}
              <OverlayTrigger placement="right" overlay={popoverTitle}>
                <FontAwesomeIcon
                  className="align-self-center"
                  icon={faInfoCircle}
                />
              </OverlayTrigger>
            </>,
            oe(data, ['massive_name']),
          ],
          [
            <>
              Acrónimo{' '}
              <OverlayTrigger placement="right" overlay={popoverAcronymous}>
                <FontAwesomeIcon
                  className="align-self-center"
                  icon={faInfoCircle}
                />
              </OverlayTrigger>
            </>,
            oe(data, ['massive_short_name']),
          ],
        ]}
      </UIPanelInfoTable>
    </UIPanelInfoBlock>
  </>
)

const Form = () => (
  <>
    <UIFormInputInteger label="ID" name="id" />
    <UIFormInputText label="Descripción" name="name" />
    <UIFormInputInteger label="ID cliente" name="client_id" />
    <UIFormInputText label="Descripción cliente" name="client_name" />
  </>
)

const AdministrationInfoClientSites = ({ ...rest }) => {
  const [{ me }] = useGlobal()

  const no = ![1, 2].includes(me.id_role)

  return (
    <AdministrationInfo
      queries={{ read: READ_SITES, delete: DELETE_SITES }}
      views={{ form: Form, info: Info }}
      {...rest}
      noDuplicate={no}
      noUpdate={no}
      noDelete={no}
    />
  )
}

export default AdministrationInfoClientSites
