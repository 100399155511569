import React from 'react'

import { AdministrationContainerTab } from '..'

import Anomalies from './Anomalies'
import Limits from './Limits'
import Logics from './Logics'
import Suggestions from './Suggestions'
import States from './States'

const subtabs = [
  { name: 'Tipos de anomalía', subtab: 'anomalies', component: Anomalies },
  { name: 'Límites', subtab: 'limits', component: Limits },
  { name: 'Lógicas', subtab: 'logics', component: Logics },
  { name: 'Acciones', subtab: 'suggestions', component: Suggestions },
  { name: 'Comentarios', subtab: 'states', component: States },
]

const AdministrationContainerAnalysis = () => (
  <AdministrationContainerTab subtabs={subtabs} />
)

export default AdministrationContainerAnalysis
