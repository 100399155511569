import React from 'react'

import { AdministrationContainerTab } from '..'

import Components from './Components'
import Protocols from './Protocols'
import Brands from './Brands'
import Models from './Models'
import Types from './Types'
import ChangeMotives from './ChangeMotives'
import Collections from './Collections'

const subtabs = [
  { name: 'Componentes', subtab: 'components', component: Components },
  { name: 'Marcas', subtab: 'brands', component: Brands },
  { name: 'Modelos', subtab: 'models', component: Models },
  { name: 'Tipo', subtab: 'types', component: Types },
  { name: 'Motivo cambio', subtab: 'changeMotives', component: ChangeMotives },
  { name: 'Protocolos (Metal)', subtab: 'protocols', component: Protocols },
  { name: 'Cobranza', subtab: 'collections', component: Collections },
]

const AdministrationContainerComponent = () => (
  <AdministrationContainerTab subtabs={subtabs} />
)

export default AdministrationContainerComponent
