import { gql } from '../../../../../hooks'

export const READ_CHANGE_REFILL_MOTIVES = gql`
  query AdministrationReadLubricantChangeRefillMotives($id: ID!) {
    aRead(path: "lubricant/change/refill/motives", id: $id) {
    }
  }
`

export const UPDATE_CHANGE_REFILL_MOTIVES = gql`
  mutation AdministrationUpdateLubricantChangeRefillMotives(
    $id: ID!
    $data: JSONObject
  ) {
    aUpdate(path: "lubricant/change/refill/motives", id: $id, data: $data) {
    }
  }
`

export const CREATE_CHANGE_REFILL_MOTIVES = gql`
  mutation AdministrationCreateLubricantChangeRefillMotives($data: JSONObject) {
    aCreate(path: "lubricant/change/refill/motives", data: $data) {
    }
  }
`

export const LIST_CHANGE_REFILL_MOTIVES = gql`
  query AdministrationListLubricantChangeRefillMotives(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(
      path: "lubricant/change/refill/motives"
      filter: $filter
      page: $page
      limit: $limit
    ) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`
