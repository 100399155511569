import React, { forwardRef } from 'react'
import { Row, Col, Form, InputGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFileImage,
  faEye,
  faEyeSlash,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'

import UIButton from '../../../../../UI/Button'
import UIModal from '../../../../../UI/Modal'

// https://medium.com/web-dev-survey-from-kyoto/how-to-customize-the-file-upload-button-in-react-b3866a5973d8

import {
  useRef,
  useMemo,
  useCallback,
  useEffect,
  useState,
  useCrossedRefs,
  useTrigger,
  useGlobal,
  useMutation,
} from '../../../../../../hooks'

import Input from '../..'
import { useFormContext } from '../../..'

// Queries
import { UPLOAD_TO_S3 } from './queries'

import config from '../../../../../../config'

const { AWS } = config

const UIFormInputFileImage = forwardRef((props, ref) => {
  let {
    value: propValue,
    initialValue: propInitialValue,
    defaultValue: propDefaultValue,
    onChange: propOnChange,
    onReset: propOnReset,
    onSubmit: propOnSubmit,
    onDelete: propOnDelete,
    bucketKey: propBucketKey = '',
    ...rest
  } = props

  propBucketKey = propBucketKey.replace(/(\/$|^\/)/g, '')

  // --------------------------------------------------------------------------
  //
  // Mutation: Update results
  //
  // --------------------------------------------------------------------------

  const [uploadToS3] = useMutation(UPLOAD_TO_S3, { fetchPolicy: 'no-cache' })

  const [{ me }] = useGlobal()

  const context = useFormContext()

  const innerRef = useRef()
  const crossedRef = useCrossedRefs(ref, innerRef)

  const [modalTrigger, setModalTrigger] = useTrigger()
  const [errorTrigger, setErrorTrigger] = useTrigger()

  const [show, setShowState] = useState(false)

  const [image, setImageState] = useState()

  const [value, setValueState] = useState(
    propValue !== undefined
      ? propValue
      : propInitialValue !== undefined
      ? propInitialValue
      : propDefaultValue !== undefined
      ? propDefaultValue
      : context.initialValues[props.name] !== undefined
      ? context.initialValues[props.name]
      : context.defaultValues[props.name] != undefined
      ? context.defaultValues[props.name]
      : '',
  )

  const defaultValue = useMemo(
    () =>
      propDefaultValue !== undefined
        ? propDefaultValue
        : context.defaultValues[props.name] !== undefined
        ? context.defaultValues[props.name]
        : '',
    [],
  )

  const handleClick = () => crossedRef.current.click()

  const handleChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setImageState(event.target.files[0])
      setValueState(event.target.files[0].name)
      setErrorTrigger()
    }
    propOnChange && propOnChange(event)
  }

  const handleReset = () => {
    setImageState()
    setShowState(false)
    setValueState(defaultValue)
    propOnReset && propOnReset()
  }

  const handleSubmit = (values) => {
    if (image && value !== '') {
      uploadToS3({
        variables: {
          file: image,
          bucketKey: propBucketKey,
        },
      })
    }
    propOnSubmit && propOnSubmit(values)
  }

  const handleShowClick = () => setShowState((prev) => !prev)

  const handleAcceptModal = () => {
    setImageState()
    setValueState('')
    setShowState(false)
    setErrorTrigger()
    propOnDelete && propOnDelete()
  }

  const handleDelete = () => {
    setModalTrigger()
  }

  const CustomInput = forwardRef((props, ref) => {
    const { value, ...rest } = props

    return (
      <>
        <input
          className="form-control form-control-sm"
          readOnly={value !== ''}
          onClick={handleClick}
          value={value}
          onChange={() => {}}
        />
        <input ref={ref} {...rest} accept="image/*" />
      </>
    )
  })

  const span = useMemo(
    () => (props.span || props.label ? 4 : 0),
    [props.span, props.label],
  )

  return (
    <>
      <Input
        {...rest}
        ref={crossedRef}
        type="file"
        defaultValue={defaultValue}
        value={value}
        onChange={handleChange}
        onReset={handleReset}
        onSubmit={handleSubmit}
        customInput={CustomInput}
        triggerErrorCheck={errorTrigger}
        className={`d-none`}
        prepend={
          <UIButton
            active={show}
            disabled={value === ''}
            onClick={handleShowClick}
            variant="outline-secondary font-weight-light"
          >
            <FontAwesomeIcon
              className="align-self-center"
              size="xs"
              icon={show ? faEyeSlash : faEye}
            />
          </UIButton>
        }
        append={
          <UIButton
            disabled={value === ''}
            onClick={handleDelete}
            variant="outline-secondary font-weight-light"
          >
            <FontAwesomeIcon
              className="align-self-center"
              size="xs"
              icon={faTrash}
            />
          </UIButton>
        }
      />
      {show && (image || value) && (
        <Form.Group as={Row}>
          <Col lg={{ span: 12 - span, offset: span }}>
            <img
              className="img-thumbnail"
              src={
                image
                  ? URL.createObjectURL(image)
                  : `https://s3-sa-east-1.amazonaws.com/${AWS.BUCKET}/${me.laboratory}/${propBucketKey}/${value}`
              }
              alt="La imagen no se pudo cargar"
            />
          </Col>
        </Form.Group>
      )}
      <UIModal
        trigger={modalTrigger}
        onAccept={handleAcceptModal}
        body={
          <>
            ¿Está seguro que desea eliminar la imagen <strong>{value}</strong>?
          </>
        }
      />
    </>
  )
})

export default UIFormInputFileImage
