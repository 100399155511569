import React from 'react'

// Components
import UIGraphPie from '../../../../../../UI/Graph/Pie'

const GraphsPie = ({ data }) => {
  const total = data[0] + data[1] + data[2]

  let pieData = [
    {
      //id: 'Normal',
      id: `${parseFloat(((data[0] / total) * 100).toFixed(1))}%`,
      // id_report: 'normal',
      label: `Normal (${data[0]})`,
      //value: parseFloat(((data[0] / total) * 100).toFixed(1)),
      value: data[0],
      color: 'rgba(40, 167, 69, 0.7)',
    },
    {
      // id: 'Alerta',
      id: `${parseFloat(((data[1] / total) * 100).toFixed(1))}%`,
      // id_report: 'alert',
      label: `Alerta (${data[1]})`,
      //value: parseFloat(((data[1] / total) * 100).toFixed(1)),
      value: data[1],
      color: 'rgba(255, 193, 7, 0.7)',
    },
    {
      // id: 'Crítico',
      id: `${parseFloat(((data[2] / total) * 100).toFixed(1))}%`,
      // id_report: 'abnormal',
      label: `Crítico (${data[2]})`,
      // value: parseFloat(((data[2] / total) * 100).toFixed(1)),
      value: data[2],
      color: 'rgba(220, 53, 69, 0.7)',
    },
  ]

  const options = {
    colors: (c) => pieData.filter(({ id }) => id === c.id)[0].color,
    //     margin: { top: 40, right: 80, bottom: 80, left: 80 },
    enableSliceLabels: false,
    legends: [
      {
        anchor: 'bottom-left',
        direction: 'column',
        translateX: -50,
        translateY: 56,
        itemHeight: 20,
        symbolShape: 'circle',
      },
    ],
  }

  return <UIGraphPie data={pieData} {...options} />
}

export default GraphsPie
