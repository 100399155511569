import gql from 'graphql-tag'

export const GET_REPORT = gql`
  query GetReportFails(
    $id_client: ID
    $id_site: ID
    $id_machine_type: ID
    $id_machine: ID
    $from: String!
    $to: String!
  ) {
    reportFails(
      id_client: $id_client
      id_site: $id_site
      id_machine_type: $id_machine_type
      id_machine: $id_machine
      from: $from
      to: $to
    )
  }
`
