import React from 'react'

import { AdministrationForm } from '../..'

// Queries
import { READ_TYPES, CREATE_TYPES, UPDATE_TYPES, LIST_TYPES } from './queries'

const queries = {
  read: READ_TYPES,
  create: CREATE_TYPES,
  update: UPDATE_TYPES,
  list: LIST_TYPES,
}

const AdministrationFormMachineTypes = () => (
  <AdministrationForm name={'tipo equipo'} queries={queries} />
)

export default AdministrationFormMachineTypes
