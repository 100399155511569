import React from 'react'

// Components
import AdministrationListMachineTypes from '../../../../Administration/List/Machine/Types'
import AdministrationInfoMachineTypes from '../../../../Administration/Info/Machine/Types'
import AdministrationFormMachineTypes from '../../../../Administration/Form/Machine/Types'

import { AdministrationContainer } from '../..'

const AdministrationContainerMachineTypes = () => (
  <AdministrationContainer
    form={AdministrationFormMachineTypes}
    info={AdministrationInfoMachineTypes}
    list={AdministrationListMachineTypes}
  />
)

export default AdministrationContainerMachineTypes
