import React from 'react'
import { useParams } from 'react-router-dom'

import ReportContainerFleetStateMachine from '../../../../components/Report/Container/FleetState/Machine'

const PageReportsFleetStateMachine = ({ auth }) => {
  const { id } = useParams()

  return <ReportContainerFleetStateMachine id={id} />
}

export default PageReportsFleetStateMachine
