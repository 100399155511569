import { gql } from '../../../hooks'

export const READ_ME = gql`
  query ProfileMe {
    pMe {
    }
  }
`

export const UPDATE_ME = gql`
  mutation ProfileUpdateMe($data: JSONObject) {
    pUpdateMe(data: $data) {
    }
  }
`
