import React from 'react'
import moment from 'moment'
import ExcelJS from 'exceljs/dist/es5/exceljs.browser'
// import * as ExcelJS from 'exceljs/dist/exceljs'

// Components
import UIButton from '../../../UI/Button'

// Hooks
import { useLazyQuery } from '../../../../hooks'

// Queries
import { GET_EXCEL } from './queries'

// Config
import config from '../../../../config'

const { APP } = config

// ----------------------------------------------------------------------------
//
// Building Excel
//
// ----------------------------------------------------------------------------

const buildExcel = ({ data, filter }) => {
  const { info, results, essays, resume } = data
  const { from, to } = filter

  const workbook = new ExcelJS.Workbook()

  workbook.creator = 'Servicios Tribológicos'

  // ------------------------------------------------------------------------
  // Helpers
  // ------------------------------------------------------------------------

  const border = (positions = {}) => {
    const { x, y, t, r, l, b } = positions

    const style = 'thin'
    const border = {}

    if (x) {
      border.left = { style }
      border.right = { style }
    }
    if (y) {
      border.top = { style }
      border.bottom = { style }
    }
    if (t) border.top = { style }
    if (r) border.right = { style }
    if (b) border.bottom = { style }
    if (l) border.left = { style }

    if (!Object.keys(positions).length) {
      border.top = { style: 'thin' }
      border.left = { style: 'thin' }
      border.bottom = { style: 'thin' }
      border.right = { style: 'thin' }
    }

    return border
  }

  const fill = ({ argb = 'FFF7F2E0' } = {}) => ({
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb },
  })

  // ------------------------------------------------------------------------
  // Resume sheet
  // ------------------------------------------------------------------------

  const sheet = workbook.addWorksheet('Resumen')

  // Insert info header
  let row
  let o = 0 // offset
  const r = 2
  const c = 2

  row = sheet.getRow(r + 0)
  row.getCell(c + 0).value = 'Cliente :'
  row.getCell(c + 0).border = border({ t: true, l: true })
  row.getCell(c + 0).fill = fill()
  row.getCell(c + 0).alignment = { horizontal: 'right' }
  row.getCell(c + 0).font = { bold: true }
  row.getCell(c + 1).value = info.client_name
  row.getCell(c + 1).border = border({ t: true, r: true })
  row.getCell(c + 1).fill = fill()

  if (info.site_name) {
    row = sheet.getRow(r + 1 + o)
    row.getCell(c + 0).value = 'Faena :'
    row.getCell(c + 0).border = border({ l: true })
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 0).alignment = { horizontal: 'right' }
    row.getCell(c + 0).font = { bold: true }
    row.getCell(c + 1).value = info.site_name
    row.getCell(c + 1).border = border({ r: true })
    row.getCell(c + 1).fill = fill()
    o += 1
  }

  if (info.machine_type_name) {
    row = sheet.getRow(r + 1 + o)
    row.getCell(c + 0).value = 'Flota :'
    row.getCell(c + 0).border = border({ l: true })
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 0).alignment = { horizontal: 'right' }
    row.getCell(c + 0).font = { bold: true }
    row.getCell(c + 1).value = info.machine_type_name
    row.getCell(c + 1).border = border({ r: true })
    row.getCell(c + 1).fill = fill()
    o += 1
  }

  if (info.machine_name) {
    row = sheet.getRow(r + 1 + o)
    row.getCell(c + 0).value = 'Equipo :'
    row.getCell(c + 0).border = border({ l: true })
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 0).alignment = { horizontal: 'right' }
    row.getCell(c + 0).font = { bold: true }
    row.getCell(c + 1).value = info.machine_name
    row.getCell(c + 1).border = border({ r: true })
    row.getCell(c + 1).fill = fill()
    o += 1
  }

  row = sheet.getRow(r + 1 + o)
  row.getCell(c + 0).value = 'Dirección :'
  row.getCell(c + 0).border = border({ l: true })
  row.getCell(c + 0).fill = fill()
  row.getCell(c + 0).alignment = { horizontal: 'right' }
  row.getCell(c + 0).font = { bold: true }
  row.getCell(c + 1).value = info.address
  row.getCell(c + 1).border = border({ r: true })
  row.getCell(c + 1).fill = fill()
  row = sheet.getRow(r + 2 + o)
  row.getCell(c + 0).value = 'Periodo :'
  row.getCell(c + 0).border = border({ l: true })
  row.getCell(c + 0).fill = fill()
  row.getCell(c + 0).alignment = { horizontal: 'right' }
  row.getCell(c + 0).font = { bold: true }
  row.getCell(c + 1).value = `${from} ~ ${to}`
  row.getCell(c + 1).border = border({ r: true })
  row.getCell(c + 1).fill = fill()
  row = sheet.getRow(r + 3 + o)
  row.getCell(c + 0).value = 'Encargado :'
  row.getCell(c + 0).border = border({ l: true, b: true })
  row.getCell(c + 0).fill = fill()
  row.getCell(c + 0).alignment = { horizontal: 'right' }
  row.getCell(c + 0).font = { bold: true }
  row.getCell(c + 1).value = info.responsible
  row.getCell(c + 1).border = border({ r: true, b: true })
  row.getCell(c + 1).fill = fill()

  // Row titles
  row = sheet.getRow(r + 5 + o)
  row.alignment = { vertical: 'middle', horizontal: 'center' }
  row.getCell(c + 2).value = 'Condición'
  row.getCell(c + 2).border = border({ x: true, y: true })
  row.getCell(c + 2).fill = fill()
  sheet.mergeCells(r + 5 + o, c + 2, r + 5 + o, c + 4)
  row.getCell(c + 5).value = 'Anomalía'
  row.getCell(c + 5).border = border()
  row.getCell(c + 5).fill = fill()
  sheet.mergeCells(
    r + 5 + o,
    c + 5,
    r + 5 + o,
    c + 5 + resume.headers.length - 1,
  )

  row = sheet.getRow(r + 6 + o)
  row.alignment = { vertical: 'middle', horizontal: 'center' }
  row.getCell(c + 0).value = info.machine_name
    ? 'Componentes'
    : info.machine_type_name
    ? 'Equipos'
    : info.site_name
    ? 'Flotas'
    : 'Faenas'
  row.getCell(c + 0).border = border()
  row.getCell(c + 0).fill = fill()
  sheet.mergeCells(r + 6 + o, c + 0, r + 6 + o, c + 1)
  row.getCell(c + 2).value = 'Normal'
  row.getCell(c + 2).border = border()
  row.getCell(c + 2).fill = fill()
  row.getCell(c + 3).value = 'Alerta'
  row.getCell(c + 3).border = border()
  row.getCell(c + 3).fill = fill()
  row.getCell(c + 4).value = 'Anormal'
  row.getCell(c + 4).border = border()
  row.getCell(c + 4).fill = fill()
  resume.headers.forEach((anomaly_type, index) => {
    row.getCell(c + 5 + index).value = anomaly_type
    row.getCell(c + 5 + index).border = border()
    row.getCell(c + 5 + index).fill = fill()
  })

  resume.body.forEach(({ name, conditions, anomaly_types }, index) => {
    row = sheet.getRow(r + 7 + o + index)
    row.alignment = { vertical: 'middle', horizontal: 'center' }

    // Condition
    row.getCell(c + 0).value = name
    row.getCell(c + 0).border = border()
    sheet.mergeCells(r + 7 + o + index, c + 0, r + 7 + o + index, c + 1)
    row.getCell(c + 2).value = conditions[0]
    row.getCell(c + 2).border = border()
    row.getCell(c + 2).fill = fill({ argb: 'FF00EE00' })
    row.getCell(c + 3).value = conditions[1]
    row.getCell(c + 3).border = border()
    row.getCell(c + 3).fill = fill({ argb: 'FFEEEE00' })
    row.getCell(c + 4).value = conditions[2]
    row.getCell(c + 4).border = border()
    row.getCell(c + 4).fill = fill({ argb: 'FFEE0000' })

    // Anomaly type
    anomaly_types.forEach((anomaly_type, index2) => {
      row.getCell(c + 5 + index2).value = anomaly_type
      row.getCell(c + 5 + index2).border = border()
    })
  })

  // Total
  row = sheet.getRow(r + 7 + o + resume.body.length)
  row.alignment = { vertical: 'middle', horizontal: 'center' }
  row.getCell(c + 0).value = 'Totales'
  row.getCell(c + 0).border = border()
  row.getCell(c + 0).fill = fill()
  sheet.mergeCells(
    r + 7 + o + resume.body.length,
    c + 0,
    r + 7 + o + resume.body.length,
    c + 1,
  )
  row.getCell(c + 2).value = resume.total.condition_counter[0]
  row.getCell(c + 2).border = border()
  row.getCell(c + 2).fill = fill()
  row.getCell(c + 3).value = resume.total.condition_counter[1]
  row.getCell(c + 3).border = border()
  row.getCell(c + 3).fill = fill()
  row.getCell(c + 4).value = resume.total.condition_counter[2]
  row.getCell(c + 4).border = border()
  row.getCell(c + 4).fill = fill()
  resume.total.anomaly_type_counter.forEach((count, index) => {
    row.getCell(c + 5 + index).value = count
    row.getCell(c + 5 + index).border = border()
    row.getCell(c + 5 + index).fill = fill()
  })

  // Make the cells width fit automátically
  sheet.columns.forEach((column) => {
    var dataMax = 0
    column.eachCell({ includeEmpty: true }, (cell) => {
      var columnLength = cell && cell.value ? cell.value.length + 5 : 0
      if (columnLength > dataMax) {
        dataMax = columnLength
      }
    })
    column.width = dataMax < 10 ? 10 : dataMax
  })

  results.body.forEach((result) => {
    // ------------------------------------------------------------------------
    // Excel buiilding
    // ------------------------------------------------------------------------
    const { name, rows } = result
    console.dir(rows)

    // Create sheet
    const sheet = workbook.addWorksheet(name.replace(/[:\\\/\?\*\[\]#]/, ''))

    // Insert info header
    let row
    let o = 0 // offset
    const r = 2
    const c = 2

    row = sheet.getRow(r + 0)
    row.getCell(c + 0).value = 'Cliente :'
    row.getCell(c + 0).border = border({ t: true, l: true })
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 0).alignment = { horizontal: 'right' }
    row.getCell(c + 0).font = { bold: true }
    row.getCell(c + 1).value = info.client_name
    row.getCell(c + 1).border = border({ t: true, r: true })
    row.getCell(c + 1).fill = fill()

    if (info.site_name) {
      row = sheet.getRow(r + 1 + o)
      row.getCell(c + 0).value = 'Faena :'
      row.getCell(c + 0).border = border({ l: true })
      row.getCell(c + 0).fill = fill()
      row.getCell(c + 0).alignment = { horizontal: 'right' }
      row.getCell(c + 0).font = { bold: true }
      row.getCell(c + 1).value = info.site_name
      row.getCell(c + 1).border = border({ r: true })
      row.getCell(c + 1).fill = fill()
      o += 1
    }

    if (info.machine_type_name) {
      row = sheet.getRow(r + 1 + o)
      row.getCell(c + 0).value = 'Flota :'
      row.getCell(c + 0).border = border({ l: true })
      row.getCell(c + 0).fill = fill()
      row.getCell(c + 0).alignment = { horizontal: 'right' }
      row.getCell(c + 0).font = { bold: true }
      row.getCell(c + 1).value = info.machine_type_name
      row.getCell(c + 1).border = border({ r: true })
      row.getCell(c + 1).fill = fill()
      o += 1
    }

    if (info.machine_name) {
      row = sheet.getRow(r + 1 + o)
      row.getCell(c + 0).value = 'Equipo :'
      row.getCell(c + 0).border = border({ l: true })
      row.getCell(c + 0).fill = fill()
      row.getCell(c + 0).alignment = { horizontal: 'right' }
      row.getCell(c + 0).font = { bold: true }
      row.getCell(c + 1).value = info.machine_name
      row.getCell(c + 1).border = border({ r: true })
      row.getCell(c + 1).fill = fill()
      o += 1
    }

    row = sheet.getRow(r + 1 + o)
    row.getCell(c + 0).value = 'Dirección :'
    row.getCell(c + 0).border = border({ l: true })
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 0).alignment = { horizontal: 'right' }
    row.getCell(c + 0).font = { bold: true }
    row.getCell(c + 1).value = info.address
    row.getCell(c + 1).border = border({ r: true })
    row.getCell(c + 1).fill = fill()
    row = sheet.getRow(r + 2 + o)
    row.getCell(c + 0).value = 'Periodo :'
    row.getCell(c + 0).border = border({ l: true })
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 0).alignment = { horizontal: 'right' }
    row.getCell(c + 0).font = { bold: true }
    row.getCell(c + 1).value = `${from} ~ ${to}`
    row.getCell(c + 1).border = border({ r: true })
    row.getCell(c + 1).fill = fill()
    row = sheet.getRow(r + 3 + o)
    row.getCell(c + 0).value = 'Encargado :'
    row.getCell(c + 0).border = border({ l: true, b: true })
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 0).alignment = { horizontal: 'right' }
    row.getCell(c + 0).font = { bold: true }
    row.getCell(c + 1).value = info.responsible
    row.getCell(c + 1).border = border({ r: true, b: true })
    row.getCell(c + 1).fill = fill()

    // Row titles
    row = sheet.getRow(r + 5 + o)
    row.alignment = { vertical: 'middle', horizontal: 'center' }
    results.headers.forEach((header, index) => {
      row.getCell(c + 0 + index).value = header
      row.getCell(c + 0 + index).border = border()
      row.getCell(c + 0 + index).fill = fill()
    })

    rows.forEach((row2, index) => {
      row = sheet.getRow(r + 6 + o + index)
      row.alignment = { vertical: 'middle', horizontal: 'center' }

      row2.forEach((cell, index2) => {
        row.getCell(c + 0 + index2).value = cell
        row.getCell(c + 0 + index2).border = border()
      })
    })

    // Make the cells width fit automátically
    sheet.columns.forEach((column) => {
      var dataMax = 0
      column.eachCell({ includeEmpty: true }, (cell) => {
        var columnLength = cell && cell.value ? cell.value.length + 5 : 0
        if (columnLength > dataMax) {
          dataMax = columnLength
        }
      })
      column.width = dataMax < 10 ? 10 : dataMax
    })
  })

  // Download excel file!
  workbook.xlsx.writeBuffer().then(function (data) {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const url = window.URL.createObjectURL(blob)
    const anchor = document.createElement('a')
    anchor.href = url
    anchor.download = 'Reporte - Concatenador.xlsx'
    anchor.click()
    // anchor.dispatchEvent(new MouseEvent('click')) // NOTE https://github.com/exceljs/exceljs/issues/354
    window.URL.revokeObjectURL(url)
  })
}

// ----------------------------------------------------------------------------
//
// Main render
//
// ----------------------------------------------------------------------------

const ButtonDownload = ({ filter }) => {
  const [getExcel, { loading, data }] = useLazyQuery(GET_EXCEL, {
    variables: filter,
  })

  if (
    data &&
    data.reportConcatenatorExcel &&
    data.reportConcatenatorExcel.data
  ) {
    buildExcel({ data: data.reportConcatenatorExcel.data, filter })
  }

  const handleClick = () => {
    getExcel()
  }

  return (
    <UIButton onClick={handleClick} disabled={loading}>
      <img src="/excel.png" height="20" alt="Excel" />
    </UIButton>
  )
}

export default ButtonDownload
