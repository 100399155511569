import { useState } from 'react'

export const useTrigger = () => {
  const [state, setState] = useState({})

  const handleTrigger = () => setState({})

  return [state, handleTrigger]
}

export default useTrigger
