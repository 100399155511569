import { gql } from '../../../../../hooks'

export const READ_COLLECTIONS = gql`
  query AdministrationReadComponentCollections($id: ID!) {
    aRead(path: "component/collections", id: $id) {
    }
  }
`

export const DELETE_COLLECTIONS = gql`
  mutation AdministrationDeleteComponentCollections($id: ID!) {
    aDelete(path: "component/collections", id: $id) {
    }
  }
`
