import React from 'react'
import { Link } from 'react-router-dom'
import { Badge } from 'react-bootstrap'
import moment from 'moment'

// Hooks
import { useHistory, useState, useQuery, useGlobal } from '../../../../../hooks'

// Components
import UIEmpty from '../../../../UI/Empty'
import UILoading from '../../../../UI/Loading'
import UIError from '../../../../UI/Error'
import UIPagination from '../../../../UI/Pagination'
import UITable from '../../../../UI/Table'
import UITableBody from '../../../../UI/Table/Body'
import UITableHead from '../../../../UI/Table/Head'

// Queries
import { LIST_ESSAYED_SAMPLES } from './queries'

const TribologySamplesReportList = () => {
  const [{ me }] = useGlobal()

  const [pagination, setPaginationState] = useState({ limit: 25, page: 1 })

  const history = useHistory()

  // --------------------------------------------------------------------------
  //
  // Query: Received samples
  //
  // --------------------------------------------------------------------------

  // NOTE The 'id_client' variable is there to include a random variable
  // otherwise it ignores 'network-only' and uses the cache
  //
  // Related bug:
  // https://github.com/apollographql/react-apollo/issues/3315
  const { data, loading } = useQuery(LIST_ESSAYED_SAMPLES, {
    fetchPolicy: 'network-only',
    variables: { ...pagination, noise: me.id_laboratory }, // noise to the query
    onSuccess: ({ pagination }) => {
      setPaginationState((prev) => ({ ...prev, ...pagination }))
    },
    onErrorMessage: `No se pudo obtener la lista de muestras.`,
  })

  // --------------------------------------------------------------------------
  //
  // Render
  //
  // --------------------------------------------------------------------------

  // Loading
  if (loading) return <UILoading />

  // Data
  if (data && data.t4SamplesByStage && data.t4SamplesByStage.data) {
    const { total, samples } = data.t4SamplesByStage.data
    const pagination = data.t4SamplesByStage.pagination

    const handlePageChange = (page) => {
      setPaginationState((prev) => ({ ...prev, ...pagination, page }))
    }

    return samples.length > 0 ? (
      <>
        <h4 className="text-center mt-0 mb-3">
          <span className="badge badge-secondary px-4">Total: {total}</span>
        </h4>
        <UITable>
          <UITableHead>
            {[
              'Tag',
              'Fecha Muestreo',
              'Fecha Recepción',
              'Correlativo',
              'N° Externo',
              'Prioridad',
            ]}
          </UITableHead>
          <UITableBody>
            {samples.map(({ sample, component, priority }) => (
              <tr key={sample.id}>
                <td>{component.tag}</td>
                <td>
                  {sample.date_sampling &&
                    moment(sample.date_sampling).format('DD/MM/YYYY')}
                </td>
                <td>
                  {sample.date_receive &&
                    moment(sample.date_receive).format('DD/MM/YYYY')}
                </td>
                <td>
                  <Link
                    className={`text-decoration-none ${
                      priority.id === 2 ? 'text-danger' : 'text-stng-darker'
                    }`}
                    to={`/tribology/samples/report/${sample.id}`}
                  >
                    {sample.id}
                  </Link>
                </td>
                <td>{sample.external_number}</td>
                <td>
                  <Badge variant={priority.id === 2 ? 'danger' : 'stng'}>
                    {priority.name}
                  </Badge>
                </td>
              </tr>
            ))}
          </UITableBody>
        </UITable>
        <UIPagination pagination={pagination} onClick={handlePageChange} />
      </>
    ) : (
      <UIEmpty message="No hay nuevas muestras recepcionadas" />
    )
  }

  // Error
  return <UIError />
}
export default TribologySamplesReportList
