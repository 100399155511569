import React, { forwardRef } from 'react'

import {
  useRef,
  useEffect,
  useMemo,
  useCallback,
  useState,
} from '../../../../../hooks'

import Input from '..'
import { useFormContext } from '../..'

const UIFormInputEmail = forwardRef((props, ref) => {
  const {
    value: propValue,
    initialValue: propInitialValue,
    defaultValue: propDefaultValue,
    onChange: propOnChange,
    onReset: propOnReset,
    ...rest
  } = props

  const isFirstRender = useRef(true)

  const context = useFormContext()

  const [value, setValueState] = useState(
    propValue !== undefined
      ? propValue
      : propInitialValue !== undefined
      ? propInitialValue
      : propDefaultValue !== undefined
      ? propDefaultValue
      : context.initialValues[props.name] !== undefined
      ? context.initialValues[props.name]
      : context.defaultValues[props.name] != undefined
      ? context.defaultValues[props.name]
      : '',
  )

  useEffect(() => {
    if (isFirstRender.current) isFirstRender.current = false
    else setValueState(propValue)
  }, [propValue])

  const defaultValue = useMemo(
    () =>
      propDefaultValue !== undefined
        ? propDefaultValue
        : context.defaultValues[props.name] !== undefined
        ? context.defaultValues[props.name]
        : '',
    [],
  )

  const handleChange = (event) => {
    setValueState(event.target.value)
    propOnChange && propOnChange(event)
  }

  const handleReset = () => {
    setValueState(defaultValue)
    propOnReset && propOnReset()
  }

  const validator = useCallback((value) => {
    if (
      ![null, undefined, ''].includes(value) &&
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value,
      )
    )
      return 'Correo inválido'
  }, [])

  return (
    <Input
      {...rest}
      ref={ref}
      validator={validator}
      defaultValue={defaultValue}
      value={value}
      onChange={handleChange}
      onReset={handleReset}
    />
  )
})

export default UIFormInputEmail
