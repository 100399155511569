import React from 'react'

// Components
import AdministrationListMachineMachines from '../../../../Administration/List/Machine/Machines'
import AdministrationInfoMachineMachines from '../../../../Administration/Info/Machine/Machines'
import AdministrationFormMachineMachines from '../../../../Administration/Form/Machine/Machines'

import { AdministrationContainer } from '../..'

const AdministrationContainerMachineMachines = () => (
  <AdministrationContainer
    form={AdministrationFormMachineMachines}
    info={AdministrationInfoMachineMachines}
    list={AdministrationListMachineMachines}
  />
)

export default AdministrationContainerMachineMachines
