// Hooks
import { useEffect, useRef } from '../../hooks'

// Patch solution taken from
// https://itnext.io/reusing-the-ref-from-forwardref-with-react-hooks-4ce9df693dd
export const useCrossedRefs = (...refs) => {
  const targetRef = useRef()

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return

      if (typeof ref === 'function') {
        ref(targetRef.current)
      } else {
        ref.current = targetRef.current
      }
    })
  }, [refs])

  return targetRef
}
