import React from 'react'

import AdministrationInfo from '../..'

// Queries
import { READ_TYPES, DELETE_TYPES } from './queries'

const AdministrationInfoComponentTypes = ({ ...rest }) => (
  <AdministrationInfo
    queries={{ read: READ_TYPES, delete: DELETE_TYPES }}
    title="Tipo de componente"
    {...rest}
  />
)

export default AdministrationInfoComponentTypes
