import { gql } from '../../../../../hooks'

export const READ_MODELS = gql`
  query AdministrationReadComponentModels($id: ID!) {
    aRead(path: "component/models", id: $id) {
    }
  }
`

export const DELETE_MODELS = gql`
  mutation AdministrationDeleteComponentModels($id: ID!) {
    aDelete(path: "component/models", id: $id) {
    }
  }
`

export const LIST_BRANDS = gql`
  query AdministrationMiscComponentBrands {
    aComponentBrands {
    }
  }
`
