import React from 'react'
import moment from 'moment'

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer'

// Hooks
import { useMemo, useGlobal } from '../../../../../hooks'

const Report = ({ me, data }) => {
  // --------------------------------------------------------------------------
  //
  // Style
  //
  // --------------------------------------------------------------------------

  const styles = useMemo(() =>
    StyleSheet.create({
      page: {
        padding: 20,
        fontFamily: 'Roboto',
        fontSize: 9,
      },
      header: {
        color: '#535353',
        fontSize: 15,
        fontWeight: 'bold',
        flexDirection: 'row',
      },
      headerCell: {
        paddingVertical: '10px',
        borderColor: '#E0E0E0',
        borderStyle: 'solid',
        borderWidth: 1,
        textAlign: 'center',
      },
      subHeader: {
        fontSize: 10,
        fontWeight: 'bold',
      },
      flexRow: {
        flex: 1,
        flexDirection: 'row',
      },
      table: {
        marginTop: '10px',
        display: 'table',
        width: 'auto',
      },
      tableRow: { margin: 'auto', flexDirection: 'row' },
      tableCol: {
        paddingVertical: '5px',
        borderStyle: 'solid',
        borderColor: '#E0E0E0',
        borderWidth: 1,
        textAlign: 'center',
      },
    }),
  )

  const {
    counts,
    names,
    comments,
    manuals,
    machines,
    pending,
    id_role,
    date_from,
    date_to,
  } = data

  const imageLogo =
    me.laboratory === 'centinela'
      ? '/imgs/logo/report/centinela.png'
      : '/imgs/logo/report/stng-2.png'

  // --------------------------------------------------------------------------
  //
  // Header
  //
  // --------------------------------------------------------------------------

  const Header = () => (
    <View fixed style={styles.header}>
      <View style={[styles.headerCell, { width: '35%' }]}>
        <Image src={`${imageLogo}`} style={{ width: '100%' }} />
      </View>
      <View style={[styles.headerCell, { width: '65%' }]}>
        <Text style={[styles.header, { paddingTop: '15px' }]}>
          Entrega de turno
        </Text>
        <Text style={styles.subHeader}>{`${moment(
          date_from,
          'YYYY/MM/DD',
        ).format('DD-MM-YYYY')} - ${moment(date_to, 'YYYY/MM/DD').format(
          'DD-MM-YYYY',
        )}`}</Text>
      </View>
    </View>
  )

  // --------------------------------------------------------------------------
  //
  // Shift
  //
  // --------------------------------------------------------------------------

  const Shift = () => (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View
          style={[
            styles.tableCol,
            { width: '20%', backgroundColor: '#EAEAEA' },
          ]}
        >
          <Text style={styles.tableCell}>Turno</Text>
        </View>
        <View style={[styles.tableCol, { width: '80%' }]}>
          <Text style={styles.tableCell}>{names.join(' - ')}</Text>
        </View>
      </View>
    </View>
  )

  // --------------------------------------------------------------------------
  //
  // Counts
  //
  // --------------------------------------------------------------------------

  const Counts = () => (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View
          style={[
            styles.tableCol,
            { width: '20%', backgroundColor: '#EAEAEA' },
          ]}
        >
          <Text style={[styles.tableCell, { marginVertical: 'auto' }]}>
            Registro actividades
          </Text>
        </View>
        <View style={[styles.tableCol, { width: '80%' }]}>
          {/* Essayed */}
          <View style={[styles.table, { paddingHorizontal: '10px' }]}>
            {/* Table header */}
            <View
              style={[
                styles.tableRow,
                {
                  backgroundColor: '#DFDFDF',
                },
              ]}
            >
              {[3, '3'].includes(id_role) ? (
                <>
                  <View style={[styles.tableCol, { width: '40%' }]}>
                    <Text style={styles.tableCell}>Fecha ensayo</Text>
                  </View>
                  <View style={[styles.tableCol, { width: '60%' }]}>
                    <Text style={styles.tableCell}>Muestras ensayadas</Text>
                  </View>
                </>
              ) : (
                <>
                  <View style={[styles.tableCol, { width: '40%' }]}>
                    <Text style={styles.tableCell}>Fecha propuesta</Text>
                  </View>
                  <View style={[styles.tableCol, { width: '20%' }]}>
                    <Text style={styles.tableCell}>
                      Muestras {'\n'} propuestas {'\n'} Antofagasta
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { width: '20%' }]}>
                    <Text style={styles.tableCell}>
                      Muestras {'\n'} propuestas {'\n'} Collahuasi
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { width: '20%' }]}>
                    <Text style={styles.tableCell}>
                      Muestras {'\n'} propuestas {'\n'} Quebrada Blanca
                    </Text>
                  </View>
                </>
              )}
            </View>
            {/* Counts */}
            {counts.map(({ count, date }, index) => (
              <View style={styles.tableRow} key={`count-${index}`}>
                <View
                  style={[
                    styles.tableCol,
                    { width: '40%', backgroundColor: '#EFEFEF' },
                  ]}
                >
                  <Text style={styles.tableCell}>{date}</Text>
                </View>
                {count.map((c, index) => (
                  <View
                    style={[
                      styles.tableCol,
                      { width: `${60 / count.length}%` },
                    ]}
                    key={`count-2-${index}`}
                  >
                    <Text style={styles.tableCell}>{c}</Text>
                  </View>
                ))}
              </View>
            ))}
            {/* Manuals */}
            <View style={styles.tableRow}>
              <View
                style={[
                  styles.tableCol,
                  { width: '40%', backgroundColor: '#EFEFEF' },
                ]}
              >
                <Text style={styles.tableCell}>Muestras manuales</Text>
              </View>
              {manuals.map((m, index) => (
                <View
                  style={[
                    styles.tableCol,
                    { width: `${60 / manuals.length}%` },
                  ]}
                  key={`manuals-${index}`}
                >
                  <Text style={styles.tableCell}>{m}</Text>
                </View>
              ))}
            </View>
            {/* Total */}
            <View style={[styles.tableRow, { backgroundColor: '#CFCFCF' }]}>
              <View style={[styles.tableCol, { width: '40%' }]}>
                <Text style={styles.tableCell}>Muestras totales</Text>
              </View>
              {counts
                .reduce((p, { count }) => {
                  if (!p.length) p = Array(count.length).fill(0)
                  return count.map((c, index) => p[index] + c)
                }, manuals)
                .map((c, index) => (
                  <View
                    style={[
                      styles.tableCol,
                      { width: `${60 / manuals.length}%` },
                    ]}
                    key={`total-${index}`}
                  >
                    <Text style={styles.tableCell}>{c}</Text>
                  </View>
                ))}
            </View>
            {/* Pending */}
            <View style={styles.tableRow}>
              <View
                style={[
                  styles.tableCol,
                  { width: '40%', backgroundColor: '#EFEFEF' },
                ]}
              >
                <Text style={styles.tableCell}>Muestras pendientes</Text>
              </View>
              {pending.map((p, index) => (
                <View
                  style={[
                    styles.tableCol,
                    { width: `${60 / pending.length}%` },
                  ]}
                  key={`pending-${index}`}
                >
                  <Text style={styles.tableCell}>{p}</Text>
                </View>
              ))}
            </View>
          </View>
        </View>
      </View>
    </View>
  )

  // --------------------------------------------------------------------------
  //
  // Comments
  //
  // --------------------------------------------------------------------------

  const Comments = () => (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View
          style={[
            styles.tableCol,
            { width: '20%', backgroundColor: '#EAEAEA' },
          ]}
        >
          <Text style={styles.tableCell}>Comentarios</Text>
        </View>
        <View
          style={[
            styles.tableCol,
            { width: '80%', textAlign: 'left', paddingHorizontal: '10px' },
          ]}
        >
          <Text style={styles.tableCell}>{comments}</Text>
        </View>
      </View>
    </View>
  )

  // --------------------------------------------------------------------------
  //
  // Machines
  //
  // --------------------------------------------------------------------------

  const Machines = () => {
    const lab_machines_names = {
      antofagasta: [
        'Spectroil M',
        'Viscosímetro automático',
        null, // 'LaserNet Fines (LNF-C)',
        'Contador de partículas LNF',
        'Titulador Potenciómetro',
        null, // 'Titulador Potenciómetro (CMDIC)',
        'PQ Analex paL',
        'FT IR PerkinElmer',
        'Medidor de dilución de combustible',
        'Microscopio',
        'Placa calefactora/Motor calefactor',
        'Agitador de muestras',
        'Medidor de pH/Refractometro',
      ],
      collahuasi: [
        'Spectroil M',
        'Viscosímetro automático',
        null, // 'LaserNet Fines (LNF-C)',
        'Contador de partículas LNF',
        null, //'Titulador Potenciómetro',
        null, // 'Titulador Potenciómetro (CMDIC)',
        'PQ Poseidon',
        'FT IR Bruker',
        'Medidor de dilución de combustible',
        'Microscopio',
        'Placa calefactora/Motor calefactor',
        'Agitador de muestras',
      ],
      centinela: [
        'Spectroil M',
        'Viscosímetro automático Mini AV',
        null, // 'LaserNet Fines (LNF-C)',
        'Contador de partículas LNF',
        null, // 'Titulador Potenciómetro',
        null, // 'Titulador Potenciómetro (CMDIC)',
        'PQ Analex pqL',
        'FT IR (Fluid Scan)',
        'Medidor de dilución de combustible',
        'Microscopio',
        'Coolcheck',
        'Agitador de muestras',
        'Kit de filtración al vacío',
      ],
      quebradablanca: [
        'Spectroil 100',
        'Viscosímetro MiniVisc',
        null, // 'LaserNet Fines (LNF-C)',
        'Contador de partículas LNF',
        null, // 'Titulador Potenciómetro',
        null, // 'Titulador Potenciómetro (CMDIC)',
        'PQ Ferrocheck',
        'FT IR (Fluid Scan)',
        'Medidor de dilución de combustible',
        'Microscopio',
        'Coolcheck',
        'Viscosímetro SimpleVisc',
      ],
      esmax: [
        'Spectroil M',
        'Viscosímetro automático',
        null, // 'LaserNet Fines (LNF-C)',
        'Contador de partículas LNF',
        null, // 'Titulador Potenciómetro',
        null, // 'Titulador Potenciómetro (CMDIC)',
        'PQ',
        'FT IR',
        'Medidor de dilución de combustible',
        null, // 'Microscopio',
        'Placa calefactora/Motor calefactor',
        'Agitador de muestras',
        'Medidor de pH/Refractometro',
      ],
    }
    if (!machines) return <></>

    const states = { 1: 'Operativo', 2: 'Fuera de Servicio' }

    return (
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View
            style={[
              styles.tableCol,
              { width: '20%', backgroundColor: '#EAEAEA' },
            ]}
          >
            <Text style={[styles.tableCell, { marginVertical: 'auto' }]}>
              Equipos
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { width: '80%', textAlign: 'left', paddingHorizontal: '10px' },
            ]}
          >
            <View
              style={[
                styles.tableRow,
                {
                  backgroundColor: '#DFDFDF',
                },
              ]}
            >
              <View style={[styles.tableCol, { width: '40%' }]}>
                <Text style={styles.tableCell}>Equipo</Text>
              </View>
              <View style={[styles.tableCol, { width: '20%' }]}>
                <Text style={styles.tableCell}>Estado</Text>
              </View>
              <View style={[styles.tableCol, { width: '40%' }]}>
                <Text style={styles.tableCell}>Observaciones</Text>
              </View>
            </View>
            {lab_machines_names[me.laboratory].map((title, index) => {
              if (title === null) return <></>

              return (
                <View style={styles.tableRow} key={`machine-${index}`}>
                  <View
                    style={[
                      styles.tableCol,
                      { width: '40%', backgroundColor: '#EFEFEF' },
                    ]}
                  >
                    <Text style={styles.tableCell}>{title}</Text>
                  </View>
                  <View
                    style={[styles.tableCol, { width: `20%` }]}
                    key={`machine-state-${index}`}
                  >
                    <Text style={styles.tableCell}>
                      {machines[index] &&
                      ['1', '2'].includes(machines[index].state)
                        ? states[machines[index].state]
                        : ''}
                    </Text>
                  </View>
                  <View
                    style={[styles.tableCol, { width: `40%` }]}
                    key={`machine-comment-${index}`}
                  >
                    <Text style={styles.tableCell}>
                      {machines[index] ? machines[index].comment : ''}
                    </Text>
                  </View>
                </View>
              )
            })}
          </View>
        </View>
      </View>
    )
  }

  return (
    <Document title={`Entrega de turno`} author={`Servicios Tribológicos`}>
      <Page size="A4" style={styles.page}>
        <Header />
        <Shift />
        <Counts />
        <Comments />
        {[3, '3'].includes(id_role) && <Machines />}
      </Page>
    </Document>
  )
}

export default Report
