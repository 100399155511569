import React from 'react'

// Components
import AdministrationListClientClients from '../../../../Administration/List/Client/Clients'
import AdministrationInfoClientClients from '../../../../Administration/Info/Client/Clients'
import AdministrationFormClientClients from '../../../../Administration/Form/Client/Clients'

import { AdministrationContainer } from '../..'

const AdministrationContainerClientClients = () => (
  <AdministrationContainer
    form={AdministrationFormClientClients}
    info={AdministrationInfoClientClients}
    list={AdministrationListClientClients}
  />
)

export default AdministrationContainerClientClients
