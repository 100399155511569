import React, { forwardRef } from 'react'
import { Row, Col, Form, InputGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFile,
  faEye,
  faEyeSlash,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'

import UIButton from '../../../../UI/Button'
import UIModal from '../../../../UI/Modal'

// https://3body-net.medium.com/how-to-style-a-file-input-in-react-b6a141418341

import {
  useRef,
  useMemo,
  useCallback,
  useEffect,
  useState,
  useCrossedRefs,
  useTrigger,
  useMutation,
} from '../../../../../hooks'

import Input from '..'
import { useFormContext } from '../..'

// Queries
import { UPLOAD_TO_S3 } from './queries'

const UIFormInputFile = forwardRef((props, ref) => {
  let {
    value: propValue,
    initialValue: propInitialValue,
    defaultValue: propDefaultValue,
    onChange: propOnChange,
    onReset: propOnReset,
    onSubmit: propOnSubmit,
    onDelete: propOnDelete,
    bucketKey: propBucketKey = '',
    ...rest
  } = props

  propBucketKey = propBucketKey.replace(/(\/$|^\/)/g, '')

  // --------------------------------------------------------------------------
  //
  // Mutation: Update results
  //
  // --------------------------------------------------------------------------

  const [uploadToS3] = useMutation(UPLOAD_TO_S3, { fetchPolicy: 'no-cache' })

  const context = useFormContext()

  const innerRef = useRef()
  const crossedRef = useCrossedRefs(ref, innerRef)

  const [modalTrigger, setModalTrigger] = useTrigger()
  const [errorTrigger, setErrorTrigger] = useTrigger()

  const [file, setFileState] = useState()

  const [value, setValueState] = useState(
    propValue !== undefined
      ? propValue
      : propInitialValue !== undefined
      ? propInitialValue
      : propDefaultValue !== undefined
      ? propDefaultValue
      : context.initialValues[props.name] !== undefined
      ? context.initialValues[props.name]
      : context.defaultValues[props.name] != undefined
      ? context.defaultValues[props.name]
      : '',
  )

  const defaultValue = useMemo(
    () =>
      propDefaultValue !== undefined
        ? propDefaultValue
        : context.defaultValues[props.name] !== undefined
        ? context.defaultValues[props.name]
        : '',
    [],
  )

  const handleClick = () => crossedRef.current.click()

  const handleChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setFileState(event.target.files[0])
      setValueState(event.target.files[0].name)
      setErrorTrigger()
    }
    propOnChange && propOnChange(event)
  }

  const handleReset = () => {
    setFileState()
    setValueState(defaultValue)
    propOnReset && propOnReset()
  }

  const handleSubmit = (values) => {
    if (file && value !== '') {
      uploadToS3({
        variables: {
          file,
          bucketKey: propBucketKey,
        },
      })
    }
    propOnSubmit && propOnSubmit(values)
  }

  const handleAccept = () => {
    setFileState()
    setValueState('')
    setErrorTrigger()
    propOnDelete && propOnDelete()
  }

  const handleDelete = () => {
    setModalTrigger()
  }

  const CustomInput = forwardRef((props, ref) => {
    const { value, ...rest } = props

    return (
      <>
        <input
          className="form-control form-control-sm"
          readOnly={value !== ''}
          onClick={handleClick}
          value={value}
          onChange={() => {}}
        />
        <input ref={ref} {...rest} />
      </>
    )
  })

  const span = useMemo(
    () => (props.span || props.label ? 4 : 0),
    [props.span, props.label],
  )

  return (
    <>
      <Input
        {...rest}
        ref={crossedRef}
        type="file"
        defaultValue={defaultValue}
        value={value}
        onChange={handleChange}
        onReset={handleReset}
        onSubmit={handleSubmit}
        customInput={CustomInput}
        triggerErrorCheck={errorTrigger}
        className="d-none"
        append={
          <>
            <UIButton
              disabled={value === ''}
              onClick={handleDelete}
              variant="outline-secondary font-weight-light"
            >
              <FontAwesomeIcon
                className="align-self-center"
                size="xs"
                icon={faTrash}
              />
            </UIButton>
          </>
        }
      />
      <UIModal
        trigger={modalTrigger}
        onAccept={handleAccept}
        body={
          <>
            ¿Está seguro que desea eliminar el archivo <strong>{value}</strong>?
          </>
        }
      />
    </>
  )
})

export default UIFormInputFile
