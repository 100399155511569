import React from 'react'

// Components
import AdministrationListSamplingSamples from '../../../../Administration/List/Sampling/Samples'
import AdministrationInfoSamplingSamples from '../../../../Administration/Info/Sampling/Samples'
import AdministrationFormSamplingSamples from '../../../../Administration/Form/Sampling/Samples'

import { AdministrationContainer } from '../..'

const AdministrationContainerSamplingSamples = () => (
  <AdministrationContainer
    form={AdministrationFormSamplingSamples}
    info={AdministrationInfoSamplingSamples}
    list={AdministrationListSamplingSamples}
  />
)

export default AdministrationContainerSamplingSamples
