import { gql } from '../../../../../hooks'

export const READ_LIMITS = gql`
  query AdministrationReadAnalysisLimits($id: ID!) {
    aRead(path: "analysis/limits", id: $id) {
    }
  }
`

export const DELETE_LIMITS = gql`
  mutation AdministrationDeleteAnalysisLimits($id: ID!) {
    aDelete(path: "analysis/limits", id: $id) {
    }
  }
`
