import { gql } from '../../../../../../hooks'

// TODO
export const GET_SAMPLE_INFO = gql`
  query GetSampleInfo($id: ID!) {
    t4SampleInfo(id: $id) {
    }
  }
`

export const GET_SAMPLE_CONDITIONS = gql`
  query {
    t4Conditions {
    }
  }
`
