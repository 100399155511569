import React from 'react'

import { AdministrationList } from '../../../List'

// Queries
import { DELETE_COMPONENTS, LIST_COMPONENTS } from './queries'

const table = [
  { th: 'ID', td: 'id' },
  { th: 'TAG', td: 'tag' },
]

const queries = { list: LIST_COMPONENTS, delete: DELETE_COMPONENTS }

const AdministrationListComponentComponents = () => (
  <AdministrationList table={table} queries={queries} />
)

export default AdministrationListComponentComponents
