import querystring from 'querystring'

/*
 * Examples
 *
 * Alerts:
 *
 *   {
 *     header: 'Title',
 *     message: 'Error: Something went wrong',
 *     style: 'danger',
 *   },
 */

const initialState = {
  alerts: [],
  style: 'stng',
  // isLoggedIn: document.cookie.match(/sid=/) !== null,
  me: null, // querystring.decode(localStorage.getItem('me')), // cli, lab, role, name, username, permissions
}

export default initialState
