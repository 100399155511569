import { gql } from '../../../../../hooks'

export const READ_BRANDS = gql`
  query AdministrationReadComponentBrands($id: ID!) {
    aRead(path: "component/brands", id: $id) {
    }
  }
`

export const DELETE_BRANDS = gql`
  mutation AdministrationDeleteComponentBrands($id: ID!) {
    aDelete(path: "component/brands", id: $id) {
    }
  }
`
