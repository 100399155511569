import { gql } from '../../../../../../hooks'

export const READ_SITES_CASES = gql`
  query AdministrationReadClientSitesCases($id: ID!) {
    aRead(path: "client/sites/cases", id: $id) {
    }
  }
`

export const DELETE_SITES_CASES = gql`
  mutation AdministrationDeleteClientSitesCases($id: ID!) {
    aDelete(path: "client/sites/cases", id: $id) {
    }
  }
`
