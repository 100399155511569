import { gql } from '../../../../../../hooks'

// TODO
export const GET_SAMPLE_INFO = gql`
  query GetSampleInfo($id: ID!) {
    t4SampleInfo(id: $id) {
    }
  }
`

export const GET_SAMPLE_TRACKING_STATES = gql`
  query TrackingStates {
    t4TrackingStates {
    }
  }
`

export const GET_SAMPLE_ANOMALIES = gql`
  query Anomalies {
    t4Anomalies {
    }
  }
`

export const GET_OPENAI = gql`
  query OpenAI($id: ID!, $params: JSONObject) {
    openAI(id: $id, params: $params) {
    }
  }
`
