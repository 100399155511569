import React from 'react'
import { Route, Switch } from 'react-router-dom'

// Hooks
import { useEffect, useGlobal, useLocation, useQuery } from '../hooks'

// Deleted views
// import PageThermography from './Thermography'

// Pages tribology
import PageTribologySamplesInsert from './Tribology/Samples/Insert'
import PageTribologySamplesInsertIscaa from './Tribology/Samples/Insert/Iscaa'
// import PageTribologySamplesInsertLabel from './Tribology/Samples/Insert/Label'
import PageTribologySamplesInsertMassive from './Tribology/Samples/Insert/Massive'
import PageTribologySamplesRecipt from './Tribology/Samples/Recipt'
import PageTribologySamplesResults from './Tribology/Samples/Results'
import PageTribologySamplesResultsMassive from './Tribology/Samples/Results/Massive'
import PageTribologySamplesReport from './Tribology/Samples/Report' // Informe propuesto
import PageTribologySamplesReportPDF from './Tribology/Samples/Report/PDF' // Informe PDF
import PageTribologySamplesSearch from './Tribology/Samples/Search'
import PageTribologySamplesShiftChange from './Tribology/Samples/ShiftChange'

// Pages substance
import PageSubstance from './Substance'

// Pages administration
import PageAdministration from './Administration'

// Pages dashboards
import PageDashboards from './Dashboards'

// Pages reports
import PageReports from './Reports'
import PageReportsExcel from './Reports/Excel'
import PageReportsFleetStateMachine from './Reports/FleetState/Machine'
import PageReportsFleetStateMachineExcel from './Reports/FleetState/Machine/Excel'

// Pages inspections
import PageInspectionsField from './Inspections/Field'
import PageInspectionsDesktop from './Inspections/Desktop'
import PageInspectionsReport from './Inspections/Report'

// Shift change
// import PageShiftChange from './ShiftChange'

// Pages misc
import PageWelcome from './Welcome'
import PageProfile from './Profile'
import PageNotFound from './NotFound'
import PageLoading from './Loading'
import PageForbidden from './Forbidden'
import PageSignIn from './SignIn'
import PageResetPassword from './ResetPassword'

import { GET_ME } from './queries'

// Page wrapper
import UIWrapperPageFrame from '../components/UI/Wrapper/Page/Frame'
import UIWrapperAuth from '../components/UI/Wrapper/Auth'

import { Font } from '@react-pdf/renderer'

const PagesRoot = () => {
  const [{ me }, dispatch] = useGlobal()

  const location = useLocation()

  useEffect(() => {
    Font.register({
      family: 'Roboto',
      fonts: [
        { src: '/Roboto-Regular.ttf' },
        { src: '/Roboto-Bold.ttf', fontWeight: 'bold' },
        { src: '/Roboto-Black.ttf', fontWeight: '900' },
      ],
    })
  }, [])

  // --------------------------------------------------------------------------
  //
  // Get me
  //
  // --------------------------------------------------------------------------

  // This is just here if the page is refreshed, since the global variables are
  // deleted (it'd only show the login screen)
  const { loading } = useQuery(GET_ME, {
    // fetchPolicy: 'network-only',
    skip: me,
    onCompleted: (data) => {
      if (data && data.me && data.me.data) {
        dispatch({ type: 'updateMe', me: data.me.data })
      }
    },
  })

  if (loading) return <PageLoading />

  // --------------------------------------------------------------------------
  //
  // Route
  //
  // --------------------------------------------------------------------------

  if (me) {
    if (me.reset_password)
      return (
        <Switch>
          <Route path="*">
            <UIWrapperAuth>
              <PageResetPassword />
            </UIWrapperAuth>
          </Route>
        </Switch>
      )

    return (
      <Switch>
        <Route path="/" exact>
          <UIWrapperPageFrame>
            <UIWrapperAuth>
              <PageWelcome />
            </UIWrapperAuth>
          </UIWrapperPageFrame>
        </Route>
        {/*************************************
            Tribología
          ************************************/}
        <Route exact path="/tribology/samples/insert">
          <UIWrapperPageFrame>
            <UIWrapperAuth roles={[1, 2, 3, 4]}>
              <PageTribologySamplesInsert />
            </UIWrapperAuth>
          </UIWrapperPageFrame>
        </Route>
        <Route exact path="/tribology/samples/insert/iscaa/:id_massive?">
          <UIWrapperPageFrame>
            <UIWrapperAuth roles={[1, 2, 3, 4]}>
              <PageTribologySamplesInsertIscaa />
            </UIWrapperAuth>
          </UIWrapperPageFrame>
        </Route>
        <Route exact path="/tribology/samples/insert/massive/:tab?">
          <UIWrapperPageFrame>
            <UIWrapperAuth roles={[1, 2, 3, 4]}>
              <PageTribologySamplesInsertMassive />
            </UIWrapperAuth>
          </UIWrapperPageFrame>
        </Route>
        <Route exact path="/tribology/samples/recipt">
          <UIWrapperPageFrame>
            <UIWrapperAuth roles={[1, 2, 3, 4]}>
              <PageTribologySamplesRecipt />
            </UIWrapperAuth>
          </UIWrapperPageFrame>
        </Route>
        <Route exact path="/tribology/samples/results/massive">
          <UIWrapperPageFrame>
            <UIWrapperAuth roles={[1, 2, 3, 4]}>
              <PageTribologySamplesResultsMassive />
            </UIWrapperAuth>
          </UIWrapperPageFrame>
        </Route>
        <Route exact path="/tribology/samples/results/:id_sample?">
          <UIWrapperPageFrame>
            <UIWrapperAuth roles={[1, 2, 3, 4]}>
              <PageTribologySamplesResults />
            </UIWrapperAuth>
          </UIWrapperPageFrame>
        </Route>
        <Route exact path="/tribology/samples/report/:id_sample?">
          <UIWrapperPageFrame>
            <UIWrapperAuth roles={[1, 2, 3, 4]}>
              <PageTribologySamplesReport />
            </UIWrapperAuth>
          </UIWrapperPageFrame>
        </Route>
        <Route exact path="/tribology/samples/report/pdf/:id">
          <UIWrapperPageFrame>
            <UIWrapperAuth roles={[1, 2, 3, 4, 5]}>
              <PageTribologySamplesReportPDF />
            </UIWrapperAuth>
          </UIWrapperPageFrame>
        </Route>
        <Route exact path="/tribology/samples/search">
          <UIWrapperPageFrame>
            <UIWrapperAuth roles={[1, 2, 3, 4]}>
              <PageTribologySamplesSearch />
            </UIWrapperAuth>
          </UIWrapperPageFrame>
        </Route>
        <Route exact path="/tribology/samples/shift_change/:action?">
          <UIWrapperPageFrame>
            <UIWrapperAuth roles={[1, 2, 3, 4]}>
              <PageTribologySamplesShiftChange />
            </UIWrapperAuth>
          </UIWrapperPageFrame>
        </Route>
        {/*************************************
            Inspections
          ************************************/}
        <Route exact path="/inspections/field/:id_inspection?">
          <UIWrapperPageFrame>
            <UIWrapperAuth roles={[1, 2, 3, 4]}>
              <PageInspectionsField />
            </UIWrapperAuth>
          </UIWrapperPageFrame>
        </Route>
        <Route exact path="/inspections/desktop/:id_inspection?">
          <UIWrapperPageFrame>
            <UIWrapperAuth roles={[1, 2, 3, 4]}>
              <PageInspectionsDesktop />
            </UIWrapperAuth>
          </UIWrapperPageFrame>
        </Route>
        <Route exact path="/inspections/report/:id_inspection?">
          <UIWrapperPageFrame>
            <UIWrapperAuth roles={[1, 2, 3, 4]}>
              <PageInspectionsReport />
            </UIWrapperAuth>
          </UIWrapperPageFrame>
        </Route>
        {/*************************************
            Termografía
          ************************************/}
        {/*<Route exact path="/thermography">
        <UIWrapperPageFrame>
          <UIWrapperAuth>
          <PageThermography params={params} hash={hash} />
          </UIWrapperAuth>
        </UIWrapperPageFrame>
        </Route>*/}
        {/*************************************
            Dashboards
          ************************************/}
        <Route path="/dashboards/:name" exact>
          <UIWrapperPageFrame>
            <UIWrapperAuth>
              <PageDashboards />
            </UIWrapperAuth>
          </UIWrapperPageFrame>
        </Route>
        {/*************************************
            Reportes
          ************************************/}
        <Route path="/reports/fleet_state/machine/:id/excel">
          {/*<UIWrapperPageFrame>*/}
          <UIWrapperAuth roles={[1, 2, 3, 4, 5]}>
            <PageReportsFleetStateMachineExcel />
          </UIWrapperAuth>
          {/*</UIWrapperPageFrame>*/}
        </Route>
        <Route path="/reports/fleet_state/machine/:id">
          <UIWrapperPageFrame>
            <UIWrapperAuth roles={[1, 2, 3, 4, 5]}>
              <PageReportsFleetStateMachine />
            </UIWrapperAuth>
          </UIWrapperPageFrame>
        </Route>
        <Route path="/reports/:name/excel">
          {/*<UIWrapperPageFrame>*/}
          <UIWrapperAuth roles={[1, 2, 3, 4, 5]}>
            <PageReportsExcel />
          </UIWrapperAuth>
          {/*</UIWrapperPageFrame>*/}
        </Route>
        <Route path="/reports/:name">
          <UIWrapperPageFrame>
            <UIWrapperAuth roles={[1, 2, 3, 4, 5]}>
              <PageReports />
            </UIWrapperAuth>
          </UIWrapperPageFrame>
        </Route>
        {/*************************************
            Substance
          ************************************/}
        <Route path="/substance/:tab?">
          <UIWrapperPageFrame>
            <UIWrapperAuth roles={[1, 2, 4]}>
              <PageSubstance />
            </UIWrapperAuth>
          </UIWrapperPageFrame>
        </Route>
        {/*************************************
            Administración
          ************************************/}
        <Route path="/administration/:tab?/:subtab?">
          <UIWrapperPageFrame>
            <UIWrapperAuth roles={[1, 2, 4]}>
              <PageAdministration />
            </UIWrapperAuth>
          </UIWrapperPageFrame>
        </Route>
        {/*<Route path="/administration">
          <UIWrapperAuth roles={[1, 2, 4]}>
            <Redirect to="/administration/analysis" />
          </UIWrapperAuth>
        </Route>*/}
        {/*************************************
            Shift change
          ************************************/}
        {/*}<Route path="/shift_change/:name" exact>
          <UIWrapperPageFrame>
            <UIWrapperAuth>
              <PageShiftChange />
            </UIWrapperAuth>
          </UIWrapperPageFrame>
        </Route>*/}
        {/*************************************
            Profile
          ************************************/}
        <Route path="/profile" exact>
          <UIWrapperPageFrame>
            <UIWrapperAuth>
              <PageProfile />
            </UIWrapperAuth>
          </UIWrapperPageFrame>
        </Route>
        {/*************************************
            Forbidden
          ************************************/}
        <Route path="/forbidden" exact>
          <UIWrapperPageFrame>
            <UIWrapperAuth>
              <PageForbidden />
            </UIWrapperAuth>
          </UIWrapperPageFrame>
        </Route>
        {/*************************************
            Page not found
          ************************************/}
        <Route path="*">
          <UIWrapperPageFrame>
            <PageNotFound />
          </UIWrapperPageFrame>
        </Route>
      </Switch>
    )
  }

  return (
    <Switch>
      <Route path="*">
        <PageSignIn />
      </Route>
    </Switch>
  )
}

export default PagesRoot
