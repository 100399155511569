import React from 'react'
// import moment from 'moment'
import ExcelJS from 'exceljs/dist/es5/exceljs.browser'
// import * as ExcelJS from 'exceljs/dist/exceljs'

// Components
import UIButton from '../../../UI/Button'

// Hooks
import { useLazyQuery } from '../../../../hooks'

// Queries
import { GET_EXCEL } from './queries'

// ----------------------------------------------------------------------------
//
// Building Excel
//
// ----------------------------------------------------------------------------

const buildExcel = ({ data, filter }) => {
  const { info, results, details } = data
  const { id_site, id_machine_type, id_machine, from, to, uf_value } = filter

  const workbook = new ExcelJS.Workbook()

  workbook.creator = 'Servicios Tribológicos'

  // ------------------------------------------------------------------------
  // Helpers
  // ------------------------------------------------------------------------

  const border = (positions = {}) => {
    const { x, y, t, r, l, b } = positions

    const style = 'thin'
    const border = {}

    if (x) {
      border.left = { style }
      border.right = { style }
    }
    if (y) {
      border.top = { style }
      border.bottom = { style }
    }
    if (t) border.top = { style }
    if (r) border.right = { style }
    if (b) border.bottom = { style }
    if (l) border.left = { style }

    if (!Object.keys(positions).length) {
      border.top = { style: 'thin' }
      border.left = { style: 'thin' }
      border.bottom = { style: 'thin' }
      border.right = { style: 'thin' }
    }

    return border
  }

  const fill = ({ argb = 'FFF7F2E0' } = {}) => ({
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb },
  })

  const formatMoney = (value) => {
    if (isNaN(value) || [null, undefined].includes(value)) return value

    return `$ ${parseInt(value)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`
  }

  // ------------------------------------------------------------------------
  // Excel buiilding
  // ------------------------------------------------------------------------

  // Insert info header
  let row
  let o = 0 // offset
  const r = 2
  const c = 2
  let total_samples
  let total_costs

  // Resumen
  // --------------------------------------------------------------------------

  let sheet = workbook.addWorksheet('Resumen')

  row = sheet.getRow(r + 0)
  row.getCell(c + 0).value = 'Cliente :'
  row.getCell(c + 0).border = border({ t: true, l: true })
  row.getCell(c + 0).fill = fill()
  row.getCell(c + 0).alignment = { horizontal: 'right' }
  row.getCell(c + 0).font = { bold: true }
  row.getCell(c + 1).value = info.client_name
  row.getCell(c + 1).border = border({ t: true, r: true })
  row.getCell(c + 1).fill = fill()

  if (info.site_name) {
    row = sheet.getRow(r + 1 + o)
    row.getCell(c + 0).value = 'Faena :'
    row.getCell(c + 0).border = border({ l: true })
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 0).alignment = { horizontal: 'right' }
    row.getCell(c + 0).font = { bold: true }
    row.getCell(c + 1).value = info.site_name
    row.getCell(c + 1).border = border({ r: true })
    row.getCell(c + 1).fill = fill()
    o += 1
  }

  if (info.machine_type_name) {
    row = sheet.getRow(r + 1 + o)
    row.getCell(c + 0).value = 'Flota :'
    row.getCell(c + 0).border = border({ l: true })
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 0).alignment = { horizontal: 'right' }
    row.getCell(c + 0).font = { bold: true }
    row.getCell(c + 1).value = info.machine_type_name
    row.getCell(c + 1).border = border({ r: true })
    row.getCell(c + 1).fill = fill()
    o += 1
  }

  if (info.machine_name) {
    row = sheet.getRow(r + 1 + o)
    row.getCell(c + 0).value = 'Equipo :'
    row.getCell(c + 0).border = border({ l: true })
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 0).alignment = { horizontal: 'right' }
    row.getCell(c + 0).font = { bold: true }
    row.getCell(c + 1).value = info.machine_name
    row.getCell(c + 1).border = border({ r: true })
    row.getCell(c + 1).fill = fill()
    o += 1
  }

  row = sheet.getRow(r + 1 + o)
  row.getCell(c + 0).value = 'Periodo :'
  row.getCell(c + 0).border = border({ l: true })
  row.getCell(c + 0).fill = fill()
  row.getCell(c + 0).alignment = { horizontal: 'right' }
  row.getCell(c + 0).font = { bold: true }
  row.getCell(c + 1).value = `${from} ~ ${to}`
  row.getCell(c + 1).border = border({ r: true })
  row.getCell(c + 1).fill = fill()
  row = sheet.getRow(r + 2 + o)
  row.getCell(c + 0).value = 'Encargado :'
  row.getCell(c + 0).border = border({ l: true, b: true })
  row.getCell(c + 0).fill = fill()
  row.getCell(c + 0).alignment = { horizontal: 'right' }
  row.getCell(c + 0).font = { bold: true }
  row.getCell(c + 1).value = info.responsible
  row.getCell(c + 1).border = border({ r: true, b: true })
  row.getCell(c + 1).fill = fill()

  sheet.mergeCells(r + 4 + o, c + 2, r + 4 + o, c + 8)

  row = sheet.getRow(r + 4 + o)
  row.alignment = { vertical: 'middle', horizontal: 'center' }
  row.getCell(c + 0).value = 'Nivel'
  row.getCell(c + 0).border = border()
  row.getCell(c + 0).fill = fill()
  row.getCell(c + 1).value = 'N'
  row.getCell(c + 1).border = border()
  row.getCell(c + 1).fill = fill()
  row.getCell(c + 2).value = 'Detalle'
  row.getCell(c + 2).border = border()
  row.getCell(c + 2).fill = fill()
  row.getCell(c + 9).value = 'Total'
  row.getCell(c + 9).border = border()
  row.getCell(c + 9).fill = fill()

  total_samples = 0
  total_costs = 0

  results.forEach((result, index) => {
    const i = index * 2

    const { name, total, cost_name, cost_type, cost_value, cost_total } = result

    total_samples += total
    total_costs += cost_total

    sheet.mergeCells(r + 5 + o + i, c + 0, r + 6 + o + i, c + 0)
    sheet.mergeCells(r + 5 + o + i, c + 1, r + 6 + o + i, c + 1)
    sheet.mergeCells(r + 5 + o + i, c + 9, r + 6 + o + i, c + 9)

    row = sheet.getRow(r + 5 + o + i)
    row.alignment = { vertical: 'middle', horizontal: 'center' }
    row.getCell(c + 0).value = name
    row.getCell(c + 0).border = border()
    row.getCell(c + 1).value = total
    row.getCell(c + 1).border = border()
    row.getCell(c + 2).value = 'Tipo de costo'
    row.getCell(c + 2).border = border()
    row.getCell(c + 2).fill = fill({ argb: 'FFD7D7D7' })
    row.getCell(c + 3).value = 'N'
    row.getCell(c + 3).border = border()
    row.getCell(c + 3).fill = fill({ argb: 'FFD7D7D7' })
    row.getCell(c + 4).value = '--'
    row.getCell(c + 4).border = border()
    row.getCell(c + 4).fill = fill({ argb: 'FFD7D7D7' })
    row.getCell(c + 5).value = 'Costo'
    row.getCell(c + 5).border = border()
    row.getCell(c + 5).fill = fill({ argb: 'FFD7D7D7' })
    row.getCell(c + 6).value = 'Valor UF'
    row.getCell(c + 6).border = border()
    row.getCell(c + 6).fill = fill({ argb: 'FFD7D7D7' })
    row.getCell(c + 7).value = 'Valor muestra'
    row.getCell(c + 7).border = border()
    row.getCell(c + 7).fill = fill({ argb: 'FFD7D7D7' })
    row.getCell(c + 8).value = 'Total item'
    row.getCell(c + 8).border = border()
    row.getCell(c + 8).fill = fill({ argb: 'FFD7D7D7' })
    row.getCell(c + 9).value = formatMoney(cost_total)
    row.getCell(c + 9).border = border()

    row = sheet.getRow(r + 6 + o + i)
    row.alignment = { vertical: 'middle', horizontal: 'center' }

    row.getCell(c + 2).value = cost_name
    row.getCell(c + 2).border = border()
    row.getCell(c + 3).value = total
    row.getCell(c + 3).border = border()
    row.getCell(c + 4).value = cost_type
    row.getCell(c + 4).border = border()
    row.getCell(c + 5).value = formatMoney(cost_value)
    row.getCell(c + 5).border = border()
    row.getCell(c + 6).value = uf_value ? formatMoney(uf_value) : '--'
    row.getCell(c + 6).border = border()
    row.getCell(c + 7).value = formatMoney(cost_value)
    row.getCell(c + 7).border = border()
    row.getCell(c + 8).value = formatMoney(cost_total)
    row.getCell(c + 8).border = border()
  })

  sheet.mergeCells(
    r + 5 + o + results.length * 2,
    c + 2,
    r + 5 + o + results.length * 2,
    c + 8,
  )

  row = sheet.getRow(r + 5 + o + results.length * 2)
  row.alignment = { vertical: 'middle', horizontal: 'center' }
  row.getCell(c + 0).value = 'Total muestras :'
  row.getCell(c + 0).border = border({ b: true, l: true })
  row.getCell(c + 0).fill = fill()
  row.getCell(c + 0).alignment = { vertical: 'middle', horizontal: 'right' }
  row.getCell(c + 1).value = total_samples
  row.getCell(c + 1).border = border({ b: true, r: true })
  row.getCell(c + 1).fill = fill()
  row.getCell(c + 2).value = 'Total Precio :'
  row.getCell(c + 2).border = border({ b: true })
  row.getCell(c + 2).fill = fill()
  row.getCell(c + 2).alignment = { vertical: 'middle', horizontal: 'right' }
  row.getCell(c + 9).value = formatMoney(total_costs)
  row.getCell(c + 9).border = border({ b: true, r: true })
  row.getCell(c + 9).fill = fill()

  // Make the cells width fit automátically
  sheet.columns.forEach((column) => {
    var dataMax = 0
    column.eachCell({ includeEmpty: true }, (cell) => {
      var columnLength = cell && cell.value ? cell.value.length + 5 : 0
      if (columnLength > dataMax) {
        dataMax = columnLength
      }
    })
    column.width = dataMax < 10 ? 10 : dataMax
  })

  // Details
  // --------------------------------------------------------------------------

  sheet = workbook.addWorksheet('Detalle')

  row = sheet.getRow(r + 0)
  row.getCell(c + 0).value = 'Cliente :'
  row.getCell(c + 0).border = border({ t: true, l: true })
  row.getCell(c + 0).fill = fill()
  row.getCell(c + 0).alignment = { horizontal: 'right' }
  row.getCell(c + 0).font = { bold: true }
  row.getCell(c + 1).value = info.client_name
  row.getCell(c + 1).border = border({ t: true, r: true })
  row.getCell(c + 1).fill = fill()

  if (info.site_name) {
    row = sheet.getRow(r + 1 + o)
    row.getCell(c + 0).value = 'Faena :'
    row.getCell(c + 0).border = border({ l: true })
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 0).alignment = { horizontal: 'right' }
    row.getCell(c + 0).font = { bold: true }
    row.getCell(c + 1).value = info.site_name
    row.getCell(c + 1).border = border({ r: true })
    row.getCell(c + 1).fill = fill()
    o += 1
  }

  if (info.machine_type_name) {
    row = sheet.getRow(r + 1 + o)
    row.getCell(c + 0).value = 'Flota :'
    row.getCell(c + 0).border = border({ l: true })
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 0).alignment = { horizontal: 'right' }
    row.getCell(c + 0).font = { bold: true }
    row.getCell(c + 1).value = info.machine_type_name
    row.getCell(c + 1).border = border({ r: true })
    row.getCell(c + 1).fill = fill()
    o += 1
  }

  if (info.machine_name) {
    row = sheet.getRow(r + 1 + o)
    row.getCell(c + 0).value = 'Equipo :'
    row.getCell(c + 0).border = border({ l: true })
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 0).alignment = { horizontal: 'right' }
    row.getCell(c + 0).font = { bold: true }
    row.getCell(c + 1).value = info.machine_name
    row.getCell(c + 1).border = border({ r: true })
    row.getCell(c + 1).fill = fill()
    o += 1
  }

  row = sheet.getRow(r + 1 + o)
  row.getCell(c + 0).value = 'Periodo :'
  row.getCell(c + 0).border = border({ l: true })
  row.getCell(c + 0).fill = fill()
  row.getCell(c + 0).alignment = { horizontal: 'right' }
  row.getCell(c + 0).font = { bold: true }
  row.getCell(c + 1).value = `${from} ~ ${to}`
  row.getCell(c + 1).border = border({ r: true })
  row.getCell(c + 1).fill = fill()
  row = sheet.getRow(r + 2 + o)
  row.getCell(c + 0).value = 'Encargado :'
  row.getCell(c + 0).border = border({ l: true, b: true })
  row.getCell(c + 0).fill = fill()
  row.getCell(c + 0).alignment = { horizontal: 'right' }
  row.getCell(c + 0).font = { bold: true }
  row.getCell(c + 1).value = info.responsible
  row.getCell(c + 1).border = border({ r: true, b: true })
  row.getCell(c + 1).fill = fill()

  row = sheet.getRow(r + 4 + o)
  row.alignment = { vertical: 'middle', horizontal: 'center' }
  row.getCell(c + 0).value = 'Fecha recepción'
  row.getCell(c + 0).border = border()
  row.getCell(c + 0).fill = fill()
  row.getCell(c + 1).value = 'Nombre cliente'
  row.getCell(c + 1).border = border()
  row.getCell(c + 1).fill = fill()
  row.getCell(c + 2).value = 'Nombre nivel'
  row.getCell(c + 2).border = border()
  row.getCell(c + 2).fill = fill()
  row.getCell(c + 3).value = 'Tipo equipo'
  row.getCell(c + 3).border = border()
  row.getCell(c + 3).fill = fill()
  row.getCell(c + 4).value = 'Equipo'
  row.getCell(c + 4).border = border()
  row.getCell(c + 4).fill = fill()
  row.getCell(c + 5).value = 'Tag'
  row.getCell(c + 5).border = border()
  row.getCell(c + 5).fill = fill()
  row.getCell(c + 6).value = 'Lubricante en uso'
  row.getCell(c + 6).border = border()
  row.getCell(c + 6).fill = fill()
  row.getCell(c + 7).value = 'N° muestra'
  row.getCell(c + 7).border = border()
  row.getCell(c + 7).fill = fill()
  row.getCell(c + 8).value = 'N° SCAA'
  row.getCell(c + 8).border = border()
  row.getCell(c + 8).fill = fill()
  row.getCell(c + 9).value = 'Código cobranza'
  row.getCell(c + 9).border = border()
  row.getCell(c + 9).fill = fill()

  details.forEach((detail, index) => {
    const {
      sample_id,
      date_receive,
      sample_scaa_number,
      component_tag,
      name,
      client_name,
      machine_name,
      machine_type_name,
      cost_name,
      lubricant_name,
    } = detail

    row = sheet.getRow(r + 5 + o + index)
    row.alignment = { vertical: 'middle', horizontal: 'center' }
    row.getCell(c + 0).value = date_receive
    row.getCell(c + 0).border = border()
    row.getCell(c + 1).value = client_name
    row.getCell(c + 1).border = border()
    row.getCell(c + 2).value = name
    row.getCell(c + 2).border = border()
    row.getCell(c + 3).value = machine_type_name
    row.getCell(c + 3).border = border()
    row.getCell(c + 4).value = machine_name
    row.getCell(c + 4).border = border()
    row.getCell(c + 5).value = component_tag
    row.getCell(c + 5).border = border()
    row.getCell(c + 6).value = lubricant_name
    row.getCell(c + 6).border = border()
    row.getCell(c + 7).value = sample_id
    row.getCell(c + 7).border = border()
    row.getCell(c + 8).value = sample_scaa_number
    row.getCell(c + 8).border = border()
    row.getCell(c + 9).value = cost_name
    row.getCell(c + 9).border = border()
  })

  // Make the cells width fit automátically
  sheet.columns.forEach((column) => {
    var dataMax = 0
    column.eachCell({ includeEmpty: true }, (cell) => {
      var columnLength = cell && cell.value ? cell.value.length + 5 : 0
      if (columnLength > dataMax) {
        dataMax = columnLength
      }
    })
    column.width = dataMax < 10 ? 10 : dataMax
  })

  // Datos OFE
  // --------------------------------------------------------------------------

  // sheet = workbook.addWorksheet('Datos OFE')

  // row = sheet.getRow(r + 0)
  // row.getCell(c + 0).value = 'Cliente :'
  // row.getCell(c + 0).border = border({ t: true, l: true })
  // row.getCell(c + 0).fill = fill()
  // row.getCell(c + 0).alignment = { horizontal: 'right' }
  // row.getCell(c + 0).font = { bold: true }
  // row.getCell(c + 1).value = info.client_name
  // row.getCell(c + 1).border = border({ t: true, r: true })
  // row.getCell(c + 1).fill = fill()

  // if (info.site_name) {
  //   row = sheet.getRow(r + 1 + o)
  //   row.getCell(c + 0).value = 'Faena :'
  //   row.getCell(c + 0).border = border({ l: true })
  //   row.getCell(c + 0).fill = fill()
  //   row.getCell(c + 0).alignment = { horizontal: 'right' }
  //   row.getCell(c + 0).font = { bold: true }
  //   row.getCell(c + 1).value = info.site_name
  //   row.getCell(c + 1).border = border({ r: true })
  //   row.getCell(c + 1).fill = fill()
  //   o += 1
  // }

  // if (info.machine_type_name) {
  //   row = sheet.getRow(r + 1 + o)
  //   row.getCell(c + 0).value = 'Flota :'
  //   row.getCell(c + 0).border = border({ l: true })
  //   row.getCell(c + 0).fill = fill()
  //   row.getCell(c + 0).alignment = { horizontal: 'right' }
  //   row.getCell(c + 0).font = { bold: true }
  //   row.getCell(c + 1).value = info.machine_type_name
  //   row.getCell(c + 1).border = border({ r: true })
  //   row.getCell(c + 1).fill = fill()
  //   o += 1
  // }

  // if (info.machine_name) {
  //   row = sheet.getRow(r + 1 + o)
  //   row.getCell(c + 0).value = 'Equipo :'
  //   row.getCell(c + 0).border = border({ l: true })
  //   row.getCell(c + 0).fill = fill()
  //   row.getCell(c + 0).alignment = { horizontal: 'right' }
  //   row.getCell(c + 0).font = { bold: true }
  //   row.getCell(c + 1).value = info.machine_name
  //   row.getCell(c + 1).border = border({ r: true })
  //   row.getCell(c + 1).fill = fill()
  //   o += 1
  // }

  // row = sheet.getRow(r + 1 + o)
  // row.getCell(c + 0).value = 'Periodo :'
  // row.getCell(c + 0).border = border({ l: true })
  // row.getCell(c + 0).fill = fill()
  // row.getCell(c + 0).alignment = { horizontal: 'right' }
  // row.getCell(c + 0).font = { bold: true }
  // row.getCell(c + 1).value = `${from} ~ ${to}`
  // row.getCell(c + 1).border = border({ r: true })
  // row.getCell(c + 1).fill = fill()
  // row = sheet.getRow(r + 2 + o)
  // row.getCell(c + 0).value = 'Encargado :'
  // row.getCell(c + 0).border = border({ l: true, b: true })
  // row.getCell(c + 0).fill = fill()
  // row.getCell(c + 0).alignment = { horizontal: 'right' }
  // row.getCell(c + 0).font = { bold: true }
  // row.getCell(c + 1).value = info.responsible
  // row.getCell(c + 1).border = border({ r: true, b: true })
  // row.getCell(c + 1).fill = fill()

  // row = sheet.getRow(r + 4 + o)
  // row.alignment = { vertical: 'middle', horizontal: 'left' }
  // row.getCell(c + 0).value = 'Razon Social :'
  // row.getCell(c + 0).border = border({ l: true, b: true, t: true })
  // row.getCell(c + 0).fill = fill()
  // row.getCell(c + 0).alignment = { horizontal: 'right' }
  // row.getCell(c + 1).value = ofe.client_name
  // row.getCell(c + 1).border = border({ r: true, b: true, t: true })
  // //  row.getCell(c + 1).fill = fill()
  // row = sheet.getRow(r + 5 + o)
  // row.alignment = { vertical: 'middle', horizontal: 'left' }
  // row.getCell(c + 0).value = 'Rut Cliente :'
  // row.getCell(c + 0).border = border({ l: true, b: true })
  // row.getCell(c + 0).fill = fill()
  // row.getCell(c + 0).alignment = { horizontal: 'right' }
  // row.getCell(c + 1).value = ofe.client_rut
  // row.getCell(c + 1).border = border({ r: true, b: true })
  // //  row.getCell(c + 1).fill = fill()
  // row = sheet.getRow(r + 6 + o)
  // row.alignment = { vertical: 'middle', horizontal: 'left' }
  // row.getCell(c + 0).value = 'N° OFE :'
  // row.getCell(c + 0).border = border({ l: true, b: true })
  // row.getCell(c + 0).fill = fill()
  // row.getCell(c + 0).alignment = { horizontal: 'right' }
  // row.getCell(c + 1).value = ''
  // row.getCell(c + 1).border = border({ r: true, b: true })
  // //  row.getCell(c + 1).fill = fill()
  // row = sheet.getRow(r + 7 + o)
  // row.alignment = { vertical: 'middle', horizontal: 'left' }
  // row.getCell(c + 0).value = 'Detalle :'
  // row.getCell(c + 0).border = border({ l: true, b: true })
  // row.getCell(c + 0).fill = fill()
  // row.getCell(c + 0).alignment = { horizontal: 'right' }
  // row.getCell(c + 1).value = `${ofe.total} ${ofe.cost_name} ${formatMoney(
  //   ofe.cost_value,
  // )} C/U`
  // row.getCell(c + 1).border = border({ r: true, b: true })
  // // row.getCell(c + 1).fill = fill()

  // // Make the cells width fit automátically
  // sheet.columns.forEach((column) => {
  //   var dataMax = 0
  //   column.eachCell({ includeEmpty: true }, (cell) => {
  //     var columnLength = cell && cell.value ? cell.value.length + 5 : 0
  //     if (columnLength > dataMax) {
  //       dataMax = columnLength
  //     }
  //   })
  //   column.width = dataMax < 10 ? 10 : dataMax
  // })

  // // const myBase64Image = 'data:image/png;base64,iVBORw0KG...'
  // // const imageId2 = workbook.addImage({
  // //   base64: myBase64Image,
  // //   extension: 'png',
  // // })
  // // })

  // Download excel file!
  workbook.xlsx.writeBuffer().then(function (data) {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const url = window.URL.createObjectURL(blob)
    const anchor = document.createElement('a')
    anchor.href = url
    anchor.download = 'Reporte - Facturacion.xlsx'
    anchor.click()
    // anchor.dispatchEvent(new MouseEvent('click')) // NOTE https://github.com/exceljs/exceljs/issues/354
    window.URL.revokeObjectURL(url)
  })
}

// ----------------------------------------------------------------------------
//
// Main render
//
// ----------------------------------------------------------------------------

const ButtonDownload = ({ filter }) => {
  const [getExcel, { loading, data }] = useLazyQuery(GET_EXCEL, {
    variables: filter,
  })

  if (data && data.reportInvoicingExcel && data.reportInvoicingExcel.data) {
    buildExcel({ data: data.reportInvoicingExcel.data, filter })
  }

  const handleClick = () => {
    getExcel()
  }

  return (
    <UIButton onClick={handleClick} disabled={loading}>
      <img src="/excel.png" height="20" alt="Excel" />
    </UIButton>
  )
}

export default ButtonDownload
