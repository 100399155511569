import React from 'react'

import AdministrationInfo from '../..'

// Queries
import { READ_TYPES, DELETE_TYPES } from './queries'

const AdministrationInfoMachineTypes = ({ ...rest }) => (
  <AdministrationInfo
    queries={{ read: READ_TYPES, delete: DELETE_TYPES }}
    title="Tipo de equipo"
    {...rest}
  />
)

export default AdministrationInfoMachineTypes
