import React from 'react'

// Hooks
import { useState } from '../../../../../../hooks'

// Components
import UIFormInputText from '../../../../../UI/FormReload/Input/Text'
import UIFormInputSelect from '../../../../../UI/FormReload/Input/Select'
import UIFormInputSearch from '../../../../../UI/FormReload/Input/Search'
import UIPanelInfoTable from '../../../../../UI/Panel/Info/Table'

// Common
import { oe } from '../../../../../../common/object/extractor'

import { AdministrationForm } from '../../..'

// Queries
import {
  READ_SITES_CASES,
  CREATE_SITES_CASES,
  UPDATE_SITES_CASES,
  LIST_SITES_CASES,
  SEARCH_SITE,
  SEARCH_MACHINE_TYPE,
} from './queries'

const queries = {
  read: READ_SITES_CASES,
  create: CREATE_SITES_CASES,
  update: UPDATE_SITES_CASES,
  list: LIST_SITES_CASES,
}

const CustomForm = ({ action, data }) => {
  const [idSite, setIdSite] = useState()

  const handleSiteClick = (id) => {
    setIdSite(id)
  }

  return (
    <UIPanelInfoTable>
      {[
        [
          'Faena',
          <UIFormInputSearch
            name="id_site"
            query={SEARCH_SITE}
            onClick={handleSiteClick}
            initialValue={{
              value: oe(data, ['site', 'id']),
              name: oe(data, ['site', 'name']),
            }}
          />,
        ],
        [
          'Tipo Equipo',
          <UIFormInputSearch
            name="id_machine_type"
            query={SEARCH_MACHINE_TYPE}
            variables={{ id_site: idSite }}
            initialValue={{
              value: oe(data, ['machine', 'type', 'id']),
              name: oe(data, ['machine', 'type', 'name']),
            }}
          />,
        ],
        [
          'Caso',
          <UIFormInputSelect
            name="id_case"
            initialValue={oe(data, ['case', 'id'], '1')}
            disabled={action === 'update'}
            required
          >
            <option value="1">Caso 1</option>
            <option value="2">Caso 2</option>
            <option value="3">Caso 3</option>
            <option value="4">Caso 4</option>
            <option value="5">Caso 5</option>
            <option value="6">Caso 6</option>
            <option value="7">Caso 7</option>
            <option value="8">Caso 8</option>
          </UIFormInputSelect>,
        ],
        [
          'Correos',
          <UIFormInputText
            text="Separados por coma"
            name="emails"
            initialValue={oe(data, ['case', 'emails'])}
            placeholder={oe(data, ['case', 'emails'])}
            required
          />,
        ],
        [
          null,
          <UIFormInputText
            name="old_ids"
            value={`${oe(data, ['site', 'id'])}-${oe(data, ['case', 'id'])}-${
              oe(data, ['machine', 'type', 'id']) || ''
            }`}
            hidden
          />,
        ],
      ]}
    </UIPanelInfoTable>
  )
}

const AdministrationFormClientSitesCases = () => (
  <AdministrationForm name={'caso faena'} queries={queries}>
    <CustomForm />
  </AdministrationForm>
)

export default AdministrationFormClientSitesCases
