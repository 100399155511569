import React from 'react'

// Components
import UIPanelInfoBlock from '../../../../UI/Panel/Info/Block'
import UIPanelInfoTable from '../../../../UI/Panel/Info/Table'

// Common
import { oe } from '../../../../../common/object/extractor'

import AdministrationInfo, { RefLink } from '../..'

// Queries
import { READ_PROTOCOLS, DELETE_PROTOCOLS } from './queries'

const Info = (data) => (
  <UIPanelInfoBlock title="Protocolo (Metal)">
    <UIPanelInfoTable>
      {[
        ['ID', oe(data, ['id'])],
        ['Descripción', oe(data, ['name'])],
        [
          'Cliente',
          oe(data, ['client', 'id']) ? (
            <RefLink
              id={oe(data, ['client', 'id'])}
              tab={'client'}
              subtab={'clients'}
            >
              {oe(data, ['client', 'name'])}
            </RefLink>
          ) : (
            'Genérico'
          ),
        ],
        [
          'Límites',
          <RefLink id={oe(data, ['id'])} tab={'analysis'} subtab={'limits'}>
            Ver límites
          </RefLink>,
        ],
      ]}
    </UIPanelInfoTable>
  </UIPanelInfoBlock>
)

const AdministrationInfoComponentProtocols = ({ ...rest }) => (
  <AdministrationInfo
    queries={{ read: READ_PROTOCOLS, delete: DELETE_PROTOCOLS }}
    views={{ info: Info }}
    {...rest}
  />
)

export default AdministrationInfoComponentProtocols
