import { gql } from '../../../../../../hooks'

export const LIST_RECEIVED_SAMPLES = gql`
  query MassiveIscaaSamples($page: Int, $limit: Int) {
    t1MassiveIscaaSamples(page: $page, limit: $limit) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`
