import gql from 'graphql-tag'

// export const GET_ME = gql`
//   query GetMe {
//     me {
//       id
//       email
//       run
//       dv
//       username
//       name
//       surname_1
//       surname_2
//       sex
//       id_role
//       id_laboratory
//       id_client
//       id_site
//       active
//       laboratories
//       clients
//       sites
//       laboratory
//       reset_password
//     }
//   }
// `

export const GET_ME = gql`
  query GetMe {
    me {
      data
    }
  }
`
