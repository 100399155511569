import React, { forwardRef } from 'react'

import {
  useRef,
  useEffect,
  useMemo,
  useState,
  useTrigger,
} from '../../../../../hooks'

import Input from '..'
import { useFormContext } from '../..'

const UIFormInputCheckbox = forwardRef((props, ref) => {
  const {
    value: propValue,
    initialValue: propInitialValue,
    defaultValue: propDefaultValue,
    onChange: propOnChange,
    onReset: propOnReset,
    className: propClassName,
    ...rest
  } = props

  const isFirstRender = useRef(true)

  const context = useFormContext()

  const [checked, setCheckedState] = useState(
    propValue !== undefined
      ? !!props.value
      : propInitialValue !== undefined
      ? !!propInitialValue
      : propDefaultValue !== undefined
      ? !!propDefaultValue
      : context.initialValues[props.name] !== undefined
      ? !!context.initialValues[props.name]
      : context.defaultValues[props.name] != undefined
      ? !!context.defaultValues[props.name]
      : false,
  )

  useEffect(() => {
    if (isFirstRender.current) isFirstRender.current = false
    else setCheckedState(!!propValue)
  }, [propValue])

  const defaultChecked = useMemo(
    () =>
      propDefaultValue !== undefined
        ? !!propDefaultValue
        : context.defaultValues[props.name] !== undefined
        ? !!context.defaultValues[props.name]
        : false,
    [],
  )

  const [trigger, setTrigger] = useTrigger()

  const handleChange = (event) => {
    setCheckedState((prev) => !prev)
    propOnChange && propOnChange(event)
  }

  const handleReset = () => {
    setCheckedState(defaultChecked)
    setTrigger()
    propOnReset && propOnReset()
  }

  return (
    <Input
      {...rest}
      ref={ref}
      type="checkbox"
      overwriteClassName
      className={['mt-2', propClassName].join(' ')}
      checked={checked}
      value={checked}
      defaultValue={defaultChecked}
      onChange={handleChange}
      onReset={handleReset}
      triggerValueCheck={trigger}
    />
  )
})

export default UIFormInputCheckbox
