import React, { forwardRef } from 'react'
import { Row, Col } from 'react-bootstrap'
import ExcelJS from 'exceljs/dist/es5/exceljs.browser'
import Chartjs from 'chart.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCogs } from '@fortawesome/free-solid-svg-icons'

import moment from 'moment'

// Hooks
import { useRef, useEffect, useQuery } from '../../../../../hooks'

// Queries
import { GET_EXCEL } from './queries'

// Config
import config from '../../../../../config'

const { APP } = config

// ----------------------------------------------------------------------------
//
// Building Excel
//
// ----------------------------------------------------------------------------

const buildExcel = ({ data, filter, graphs }) => {
  const { info, results } = data
  const { from, to } = filter

  const workbook = new ExcelJS.Workbook()

  workbook.creator = 'Servicios Tribológicos'

  results.forEach((result) => {
    // ------------------------------------------------------------------------
    // Helpers
    // ------------------------------------------------------------------------

    const border = (positions = {}) => {
      const { x, y, t, r, l, b } = positions

      const style = 'thin'
      const border = {}

      if (x) {
        border.left = { style }
        border.right = { style }
      }
      if (y) {
        border.top = { style }
        border.bottom = { style }
      }
      if (t) border.top = { style }
      if (r) border.right = { style }
      if (b) border.bottom = { style }
      if (l) border.left = { style }

      if (!Object.keys(positions).length) {
        border.top = { style: 'thin' }
        border.left = { style: 'thin' }
        border.bottom = { style: 'thin' }
        border.right = { style: 'thin' }
      }

      return border
    }

    const fill = ({ argb = 'FFF7F2E0' } = {}) => ({
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb },
    })

    const { id, name, machines } = result

    const numComponents = Math.max(
      ...machines.map(({ components }) => components.length),
    )

    // ------------------------------------------------------------------------
    // Excel buiilding
    // ------------------------------------------------------------------------

    // Create sheet
    const sheet = workbook.addWorksheet(name)

    // Insert info header
    let row
    const r = 2
    const c = 2

    // sheet.mergeCells(r + 0, c + 1, r + 0, c + 2)
    // sheet.mergeCells(r + 1, c + 1, r + 1, c + 2)
    // sheet.mergeCells(r + 2, c + 1, r + 2, c + 2)
    // sheet.mergeCells(r + 3, c + 1, r + 3, c + 2)
    sheet.mergeCells(r + 5, c + 4, r + 5, c + 3 + numComponents)

    row = sheet.getRow(r + 0)
    row.getCell(c + 0).value = 'Cliente:'
    row.getCell(c + 0).border = border({ t: true, l: true })
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 0).font = { bold: true }
    row.getCell(c + 1).value = info.client_name
    row.getCell(c + 1).border = border({ t: true, r: true })
    row.getCell(c + 1).fill = fill()
    row = sheet.getRow(r + 1)
    row.getCell(c + 0).value = 'Faena:'
    row.getCell(c + 0).border = border({ l: true })
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 0).font = { bold: true }
    row.getCell(c + 1).value = info.site_name
    row.getCell(c + 1).border = border({ r: true })
    row.getCell(c + 1).fill = fill()
    row = sheet.getRow(r + 2)
    row.getCell(c + 0).value = 'Periodo:'
    row.getCell(c + 0).border = border({ l: true })
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 0).font = { bold: true }
    row.getCell(c + 1).value = `${from} ~ ${to}`
    row.getCell(c + 1).border = border({ r: true })
    row.getCell(c + 1).fill = fill()
    row = sheet.getRow(r + 3)
    row.getCell(c + 0).value = 'Encargado:'
    row.getCell(c + 0).border = border({ l: true, b: true })
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 0).font = { bold: true }
    row.getCell(c + 1).value = info.responsible
    row.getCell(c + 1).border = border({ r: true, b: true })
    row.getCell(c + 1).fill = fill()

    row = sheet.getRow(r + 5)
    row.alignment = { vertical: 'middle', horizontal: 'center' }
    row.getCell(c + 0).value = 'Faena'
    row.getCell(c + 0).border = border()
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 1).value = 'Flota'
    row.getCell(c + 1).border = border()
    row.getCell(c + 1).fill = fill()
    row.getCell(c + 2).value = 'Equipo'
    row.getCell(c + 2).border = border()
    row.getCell(c + 2).fill = fill()
    row.getCell(c + 3).value = 'Último muestreo'
    row.getCell(c + 3).border = border()
    row.getCell(c + 3).fill = fill()
    row.getCell(c + 4).value = 'Componentes'
    row.getCell(c + 4).border = border()
    row.getCell(c + 4).fill = fill()

    // Insert results
    machines.forEach((machine, index) => {
      const {
        machine_type_name,
        machine_name,
        machine_id,
        max_condition,
        max_date_sampling,
        components,
      } = machine

      row = sheet.getRow(r + 6 + index)
      row.alignment = { vertical: 'middle', horizontal: 'center' }
      row.getCell(c + 0).value = info.site_name
      row.getCell(c + 0).border = border()
      row.getCell(c + 1).value = machine_type_name
      row.getCell(c + 1).border = border()
      row.getCell(c + 2).value = {
        text: machine_name,
        // hyperlink: `https://app.stng.cl/reports/fleet_state/machine/${machine_id}?from=${from}&to=${to}`,
        hyperlink: `${window.location.origin}/reports/fleet_state/machine/${machine_id}?from=${from}&to=${to}`,
        tooltip: 'Ver reporte de equipo',
      }
      switch (max_condition) {
        case 1:
          row.getCell(c + 2).fill = fill({ argb: 'FF00EE00' })
          break
        case 2:
          row.getCell(c + 2).fill = fill({ argb: 'FFEEEE00' })
          break
        case 3:
          row.getCell(c + 2).fill = fill({ argb: 'FFEE0000' })
          row.getCell(c + 2).font = { color: { argb: 'FFEEEEEE' } }
          break
      }
      row.getCell(c + 2).border = border()
      row.getCell(c + 3).value = moment(max_date_sampling).format('DD/MM/YYYY')
      row.getCell(c + 3).border = border()

      components.forEach((component, index) => {
        const { id_condition, name } = component

        row.getCell(c + 4 + index).value = name
        switch (id_condition) {
          case 1:
            row.getCell(c + 4 + index).fill = fill({ argb: 'FF00EE00' })
            break
          case 2:
            row.getCell(c + 4 + index).fill = fill({ argb: 'FFEEEE00' })
            break
          case 3:
            row.getCell(c + 4 + index).fill = fill({ argb: 'FFEE0000' })
            row.getCell(c + 4 + index).font = { color: { argb: 'FFEEEEEE' } }
            break
        }
        row.getCell(c + 4 + index).border = border()
      })
    })

    // Make the cells width fit automátically
    sheet.columns.forEach((column) => {
      var dataMax = 0
      column.eachCell({ includeEmpty: true }, (cell) => {
        var columnLength = cell && cell.value ? cell.value.length + 5 : 0
        if (columnLength > dataMax) {
          dataMax = columnLength
        }
      })
      column.width = dataMax < 10 ? 10 : dataMax
    })

    const imageId = workbook.addImage({
      base64: graphs.current[id],
      extension: 'png',
    })

    sheet.mergeCells(
      r + 7 + machines.length,
      c + 0,
      r + 21 + machines.length,
      c + 2,
    )
    row = sheet.getRow(r + 7 + machines.length)
    row.getCell(c + 0).border = border()
    sheet.addImage(imageId, {
      tl: { col: c - 1, row: r + 7 + machines.length },
      ext: { width: 520, height: 250 },
    })
  })

  // Download excel file!
  workbook.xlsx.writeBuffer().then(function (data) {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const url = window.URL.createObjectURL(blob)
    const anchor = document.createElement('a')
    anchor.href = url
    anchor.download = 'Reporte - Estado de Flotas.xlsx'
    anchor.click()
    // anchor.dispatchEvent(new MouseEvent('click')) // NOTE https://github.com/exceljs/exceljs/issues/354
    window.URL.revokeObjectURL(url)
  })

  const timer = setTimeout(() => {
    window.open('', '_self')
    window.close()
  }, 700)
}

// ----------------------------------------------------------------------------
//
// Canvas
//
// ----------------------------------------------------------------------------

const Canvas = forwardRef(({ data }, ref) => {
  const graph = useRef()

  useEffect(() => {
    const {
      id,
      graph: { count_normal, count_alerta, count_anormal },
    } = data

    const total = count_normal + count_alerta + count_anormal

    new Chartjs(graph.current, {
      type: 'doughnut',
      data: {
        labels: [
          `Normal ${parseFloat((count_normal / total) * 100).toFixed(1)}%`,
          `Alerta ${parseFloat((count_alerta / total) * 100).toFixed(1)}%`,
          `Anormal ${parseFloat((count_anormal / total) * 100).toFixed(1)}%`,
        ],
        datasets: [
          {
            data: [count_normal, count_alerta, count_anormal],
            backgroundColor: [
              'rgba(40, 167, 69, 0.7)',
              'rgba(255, 193, 7, 0.7)',
              'rgba(220, 53, 69, 0.7)',
            ],
            //label: 'Dataset 1',
            borderColor: [
              'rgba(40, 167, 69, 1)',
              'rgba(255, 193, 7, 1)',
              'rgba(220, 53, 69, 1)',
            ],
            borderWidth: 4,
          },
        ],
      },
      options: {
        // aspectRatio: 1,
        layout: {
          padding: 0,
          margin: 0,
        },
        responsive: true,
        legend: {
          display: true,
          position: 'right',
          labels: {
            fontSize: 30,
            // padding: 10,
          },
        },
        animation: {
          duration: 0,
        },
      },
    })

    // I know, I know ... if using once Timeout wasn't horrible enough
    const timer = setTimeout(() => {
      ref.current[id] = graph.current.toDataURL()
    }, 50)
    return () => clearTimeout(timer)
  }, [data])

  return (
    <div
      style={{
        width: '1000px',
        height: '500px',
        //  border: '1px solid red',
        opacity: 0,
      }}
    >
      <canvas ref={graph} />
    </div>
  )
})

// ----------------------------------------------------------------------------
//
// Main render
//
// ----------------------------------------------------------------------------

const ReportExcel = ({ filter }) => {
  const graphs = useRef({})

  const { data, loading } = useQuery(GET_EXCEL, {
    variables: filter,
  })

  useEffect(() => {
    if (data && data.reportFleetStateExcel && data.reportFleetStateExcel.data) {
      const timer = setTimeout(() => {
        buildExcel({
          data: data.reportFleetStateExcel.data,
          filter,
          graphs,
        })
      }, 500)
      return () => clearTimeout(timer)
    }
  }, [data, filter, graphs])

  return (
    <Row>
      <Col xs={12} className="text-center align-self-center mt-5">
        <FontAwesomeIcon
          size="10x"
          icon={faCogs}
          color="lightgray"
          className="mb-5"
        />
        <h1 className="text-secondary font-weight-light">
          Generando archivo Excel
        </h1>
        <hr className="w-50" />
        <p className="text-black-50 font-weight-lighter">
          Si en 5 segundos no se descarga el archivo por favor refresque el
          navegador
        </p>
      </Col>
      {data &&
        data.reportFleetStateExcel &&
        data.reportFleetStateExcel.data &&
        data.reportFleetStateExcel.data.results.map((result, index) => (
          <Canvas key={`canvas-${index}`} data={result} ref={graphs} />
        ))}
    </Row>
  )
}

export default ReportExcel
