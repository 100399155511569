import React from 'react'

// Components
import AdministrationListLubricantLubricants from '../../../../Administration/List/Lubricant/Lubricants'
import AdministrationInfoLubricantLubricants from '../../../../Administration/Info/Lubricant/Lubricants'
import AdministrationFormLubricantLubricants from '../../../../Administration/Form/Lubricant/Lubricants'

import { AdministrationContainer } from '../..'

const AdministrationContainerLubricantLubricants = () => (
  <AdministrationContainer
    form={AdministrationFormLubricantLubricants}
    info={AdministrationInfoLubricantLubricants}
    list={AdministrationListLubricantLubricants}
  />
)

export default AdministrationContainerLubricantLubricants
