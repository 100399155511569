import { gql } from '../../../../../hooks'

export const READ_SAMPLES = gql`
  query AdministrationReadSamplingSamples($id: ID!) {
    aRead(path: "sampling/samples", id: $id) {
    }
  }
`

export const DELETE_SAMPLES = gql`
  mutation AdministrationDeleteSamplingSamples($id: ID!) {
    aDelete(path: "sampling/samples", id: $id) {
    }
  }
`
