import { gql } from '../../../../../hooks'

export const LIST_ENTERED_SAMPLES = gql`
  query EnteredSamples($page: Int, $limit: Int) {
    t3SamplesByStage(page: $page, limit: $limit) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`

export const RECEIVE_SAMPLE = gql`
  mutation ReiciveSample($id: ID!) {
    t3ReceiveSample(id: $id) {
    }
  }
`
