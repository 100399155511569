import React from 'react'
import { Button } from 'react-bootstrap'

// Global reducer
import { useQuery, useGlobal } from '../../../../../../../hooks'

// Components
import UILoading from '../../../../../../../components/UI/Loading'
import UIEmpty from '../../../../../../../components/UI/Empty'
import UITable from '../../../../../../../components/UI/Table'
import UITableHead from '../../../../../../../components/UI/Table/Head'
import UITableBody from '../../../../../../../components/UI/Table/Body'

// Queries
import { GET_MASSIVE_SAMPLES } from './queries'

const SampleFormInsertMassiveReception = ({ onDataChange }) => {
  const [{ me }] = useGlobal()

  // --------------------------------------------------------------------------
  //
  // Query get everything
  //
  // --------------------------------------------------------------------------

  // NOTE The 'id_client' variable is there to include a random variable
  // otherwise it ignores 'network-only' and uses the cache
  //
  // Related bug:
  // https://github.com/apollographql/react-apollo/issues/3315
  const { data } = useQuery(GET_MASSIVE_SAMPLES, {
    fetchPolicy: 'network-only',
    variables: { noise: me.id_laboratory }, // noise to the query
    onErrorMessage: `Error al intentar obtener la lista de muestras masivas`,
  })

  if (data && data.t2MassiveSamples) {
    const results = data.t2MassiveSamples.data

    if (results.length === 0)
      return <UIEmpty message="No hay muestras masivas a recepcionar" />

    const handleClick = (values) => {
      onDataChange(values)
    }

    return (
      <UITable borderless className="text-center font-weight-light my-4">
        <UITableHead>
          <tr>
            <td className="bg-stng border text-white align-middle">
              <small>Correlativo ingreso</small>
            </td>
            <td className="bg-stng border text-white align-middle">
              <small>Fecha ingreso</small>
            </td>
            <td className="bg-stng border text-white align-middle">
              <small>Responsable</small>
            </td>
            <td className="bg-stng border text-white align-middle">
              <small>Estado</small>
            </td>
          </tr>
        </UITableHead>
        <UITableBody>
          {results.map(({ id, date_entry, user, samples }) => (
            <tr key={id}>
              <td>
                <Button
                  variant="link"
                  className="my-0 py-0"
                  onClick={() => handleClick({ id, samples })}
                >
                  <small>{id}</small>
                </Button>
              </td>
              <td>{date_entry}</td>
              <td>{user}</td>
              <td>Ingresado</td>
            </tr>
          ))}
        </UITableBody>
      </UITable>
    )
  }

  return <UILoading />
}

export default SampleFormInsertMassiveReception
