import { gql } from '../../../../hooks'

export const READ_USERS = gql`
  query AdministrationReadUserUsers($id: ID!) {
    aRead(path: "user/users", id: $id) {
    }
  }
`

export const DELETE_USERS = gql`
  mutation AdministrationDeleteUserUsers($id: ID!) {
    aDelete(path: "user/users", id: $id) {
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation AdministrationResetPassword($id: ID!) {
    aResetPassword(id: $id) {
    }
  }
`
