import React from 'react'
import { Row, Col } from 'react-bootstrap'

// Hooks
import { useHistory, useParams, useMemo } from '../../hooks'

// Components
import AdministrationContainerAnalysis from '../../components/Administration/Container/Analysis'
import AdministrationContainerClient from '../../components/Administration/Container/Client'
import AdministrationContainerComponent from '../../components/Administration/Container/Component'
import AdministrationContainerLubricant from '../../components/Administration/Container/Lubricant'
import AdministrationContainerMachine from '../../components/Administration/Container/Machine'
import AdministrationContainerSampling from '../../components/Administration/Container/Sampling'
import AdministrationContainerUser from '../../components/Administration/Container/User'
// import AdministrationContainerReport from '../../components/Administration/Container/Report'
import AdministrationContainerOther from '../../components/Administration/Container/Other'

const tabs = [
  {
    name: 'Análisis',
    tab: 'analysis',
    component: AdministrationContainerAnalysis,
  },
  {
    name: 'Clientes',
    tab: 'client',
    component: AdministrationContainerClient,
  },
  {
    name: 'Componentes',
    tab: 'component',
    component: AdministrationContainerComponent,
  },
  {
    name: 'Lubricantes',
    tab: 'lubricant',
    component: AdministrationContainerLubricant,
  },
  {
    name: 'Equipos',
    tab: 'machine',
    component: AdministrationContainerMachine,
  },
  {
    name: 'Muestreo',
    tab: 'sampling',
    component: AdministrationContainerSampling,
  },
  { name: 'Usuarios', tab: 'user', component: AdministrationContainerUser },
  // { name: 'Reportes', tab: 'report', component: AdministrationContainerReport },
  // { name: 'Otros', tab: 'other', component: AdministrationContainerOther },
]

const PageAdministration = () => {
  const params = useParams()

  const history = useHistory()

  const activeTab = useMemo(() => {
    if (!params.tab || !tabs.map(({ tab }) => tab).includes(params.tab)) {
      history.replace({
        pathname: `/administration/${tabs[0].tab}`,
      })

      return tabs[0].tab
    }

    return params.tab
  }, [params.tab])

  const Content = useMemo(
    () => tabs.filter(({ tab }) => tab === activeTab)[0].component,
    [activeTab],
  )

  const handleClick = (tab) => {
    history.push(`/administration/${tab}`)
  }

  return (
    <Row>
      <Col>
        <ul className="nav nav-tabs font-weight-light" role="tablist">
          {tabs.map(({ name, tab }, index) => (
            <li
              className="nav-item"
              role="presentation"
              key={`page-admin-${index}`}
            >
              <a
                data-toggle="tab"
                role="button"
                className={`nav-link ${tab === activeTab ? 'active' : ''}`}
                onClick={() => handleClick(tab)}
              >
                {name}
              </a>
            </li>
          ))}
        </ul>
        <div className="tab-content">
          <div className="tab-pane fade show active" role="tabpanel">
            <Content />
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default PageAdministration
