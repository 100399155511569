import React from 'react'
import { Link } from 'react-router-dom'
import { Badge, Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faHourglass,
  faClock,
  faArrowAltCircleUp,
  faArrowAltCircleDown,
} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

// Hooks
import {
  useEffect,
  useHistory,
  useState,
  useQuery,
  useMutation,
  useGlobal,
} from '../../../../../hooks'

// Components
import UIEmpty from '../../../../UI/Empty'
import UILoading from '../../../../UI/Loading'
import UIError from '../../../../UI/Error'
import UIPagination from '../../../../UI/Pagination'
import UITable from '../../../../UI/Table'
import UITableBody from '../../../../UI/Table/Body'
import UITableHead from '../../../../UI/Table/Head'
import UIForm from '../../../../UI/FormReload'
import UIFormInputInteger from '../../../../UI/FormReload/Input/Integer'
import UIFormInputSelect from '../../../../UI/FormReload/Input/Select'
import UIButton from '../../../../UI/Button'

// Queries
import {
  // LIST_SITES_BY_RESULTS,
  LIST_RECEIVED_SAMPLES,
  CHANGE_PRIORITY_ORDER,
  CHANGE_PRIORITY_ORDER_BY_COMPONENT_TYPE,
} from './queries'

const TribologySamplesResultsList = ({ filterBySiteId, setFilterBySiteId }) => {
  const [{ me }] = useGlobal()

  const [pagination, setPaginationState] = useState({
    limit: 25,
    page: 1,
    idSite: 0,
  })

  const [isChangingPriotiryOrder, setIsChangingPriotiryOrder] = useState(false)
  const [componentTypeId, setComponentTypeId] = useState(0)
  // const [filterBySiteId, setFilterBySiteId] = useState(0)

  const history = useHistory()

  // --------------------------------------------------------------------------
  //
  // Query: Received samples
  //
  // --------------------------------------------------------------------------

  const { data, loading, refetch } = useQuery(LIST_RECEIVED_SAMPLES, {
    fetchPolicy: 'network-only',
    variables: { ...pagination, idSite: filterBySiteId },
    onSuccess: ({ pagination }) => {
      setPaginationState((prev) => ({ ...prev, ...pagination }))
      // setIsChangingPriotiryOrder(false)
    },
    onErrorMessage: `No se pudo obtener la lista de muestras.`,
  })

  // const {
  //   data: dataSites,
  //   loading: loadingSites,
  //   refetch: refetchSites,
  // } = useQuery(LIST_SITES_BY_RESULTS, {
  //   fetchPolicy: 'network-only',
  //   onErrorMessage: `No se pudo obtener la lista de faenas`,
  // })

  useEffect(() => {
    refetch()
  }, [me.laboratory])

  useEffect(() => {
    if (data) setIsChangingPriotiryOrder(false)
  }, [data])

  // --------------------------------------------------------------------------
  //
  // Mutation: Change priority order
  //
  // --------------------------------------------------------------------------

  const [changePriorityOrder] = useMutation(CHANGE_PRIORITY_ORDER, {
    onCompleted: () => {
      refetch()
    },
  })

  // --------------------------------------------------------------------------
  //
  // Mutation: Change priority order by component type
  //
  // --------------------------------------------------------------------------

  const [changePriorityOrderByComponentType] = useMutation(
    CHANGE_PRIORITY_ORDER_BY_COMPONENT_TYPE,
    {
      onCompleted: () => {
        refetch()
      },
    },
  )

  // --------------------------------------------------------------------------
  //
  // Render
  //
  // --------------------------------------------------------------------------

  // Loading
  if (loading) return <UILoading />

  // Data
  if (data && data.t5SamplesByStage && data.t5SamplesByStage.data) {
    const { total, sites, samples, component_types } =
      data.t5SamplesByStage.data
    const pagination = data.t5SamplesByStage.pagination

    const handleChangePriorityOrder = (id, order) => {
      return (event) => {
        changePriorityOrder({
          variables: {
            id,
            order: order === 99 ? 50 : 99,
          },
        })
        setIsChangingPriotiryOrder(true)
      }
    }

    const handleChangePriorityOrderByComponentType = (order) => {
      return (event) => {
        if (![0, '0'].includes(componentTypeId))
          changePriorityOrderByComponentType({
            variables: { id: componentTypeId, order },
          })
        setIsChangingPriotiryOrder(true)
      }
    }

    const handlePageChange = (page) => {
      setPaginationState((prev) => ({ ...prev, ...pagination, page }))
    }

    const handleFilterBySiteId = (event) => {
      setFilterBySiteId(parseInt(event.target.value))
    }

    const handleSubmit = ({ id_sample }) => {
      if (id_sample) history.push(`/tribology/samples/results/${id_sample}`)
    }

    return (
      <UIForm onSubmit={handleSubmit}>
        <Row className="mb-4">
          <Col className="text-left">
            <UIFormInputInteger
              name="id_sample"
              placeholder="Correlativo muestra"
              style={{ maxWidth: '200px' }}
              append={
                <UIButton
                  variant="outline-secondary font-weight-light"
                  type="submit"
                >
                  Actualizar
                </UIButton>
              }
            />
          </Col>
          <Col className="text-left">
            <UIFormInputSelect
              name="id_component_type"
              style={{ maxWidth: '200px' }}
              onClick={(event) => setComponentTypeId(event.target.value)}
              append={
                <UIButton
                  disabled={isChangingPriotiryOrder}
                  variant="outline-secondary font-weight-light"
                  onClick={handleChangePriorityOrderByComponentType(50)}
                >
                  Priorizar
                </UIButton>
              }
              prepend={
                <UIButton
                  disabled={isChangingPriotiryOrder}
                  variant="outline-secondary font-weight-light"
                  onClick={handleChangePriorityOrderByComponentType(99)}
                >
                  Despriorizar
                </UIButton>
              }
            >
              <option value="0">Tipo de componente</option>
              {component_types.map(({ id, name }) => (
                <option value={id}>{name}</option>
              ))}
            </UIFormInputSelect>
          </Col>
          <Col className="text-left">
            <UIFormInputSelect
              name="site_filter"
              style={{ maxWidth: '200px' }}
              onChange={handleFilterBySiteId}
              value={filterBySiteId}
            >
              <option value={0}>Filtrar faenas</option>
              {sites.map(({ id, name }) => (
                <option value={id} key={id}>
                  {name}
                </option>
              ))}
            </UIFormInputSelect>
          </Col>
          <Col className="text-right">
            <Badge
              className="px-2 py-2"
              variant="secondary"
              style={{ fontSize: '11pt' }}
            >
              Total muestras: {total}
            </Badge>
          </Col>
        </Row>
        {samples.length > 0 ? (
          <>
            <UITable>
              <UITableHead>
                {[
                  [1, 2, 3, 4].includes(me.id_role) ? ' ' : null,
                  'Equipo',
                  'Tipo componente',
                  'Faena',
                  <OverlayTrigger
                    overlay={<Tooltip id="tooltip-disabled">Desgaste</Tooltip>}
                  >
                    <span>D</span>
                  </OverlayTrigger>,
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="tooltip-disabled">Contaminantes</Tooltip>
                    }
                  >
                    <span>C</span>
                  </OverlayTrigger>,
                  <OverlayTrigger
                    overlay={<Tooltip id="tooltip-disabled">Aditivos</Tooltip>}
                  >
                    <span>A</span>
                  </OverlayTrigger>,
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="tooltip-disabled">Físico Químico</Tooltip>
                    }
                  >
                    <span>FQ</span>
                  </OverlayTrigger>,
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        Conteo de Partículas
                      </Tooltip>
                    }
                  >
                    <span>CP</span>
                  </OverlayTrigger>,
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="tooltip-disabled">Test de Parche</Tooltip>
                    }
                  >
                    <span>TP</span>
                  </OverlayTrigger>,
                  'Correlativo',
                  'Fecha Recepción',
                  'Prioridad',
                ].filter((h) => !!h)}
              </UITableHead>
              <UITableBody>
                {samples.map(
                  ({
                    sample,
                    component,
                    machine,
                    site,
                    priority,
                    essay_classifications,
                  }) => (
                    <tr key={sample.id}>
                      {[1, 2, 3, 4].includes(me.id_role) ? (
                        <td>
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-disabled">
                                {priority.order === 99
                                  ? 'Dar prioridad'
                                  : 'Restaurar prioridad normal'}
                              </Tooltip>
                            }
                          >
                            <UIButton
                              onClick={handleChangePriorityOrder(
                                sample.id,
                                priority.order,
                              )}
                              variant={
                                priority.order === 99 ? 'primary' : 'danger'
                              }
                              style={{ fontSize: '9pt' }}
                              className="btn-sm p-1"
                              disabled={isChangingPriotiryOrder}
                            >
                              <FontAwesomeIcon
                                icon={
                                  priority.order === 99
                                    ? faArrowAltCircleUp
                                    : faArrowAltCircleDown
                                }
                                color="white"
                              />
                            </UIButton>
                          </OverlayTrigger>
                        </td>
                      ) : null}
                      <td>{machine.name}</td>
                      <td>{component.type.name}</td>
                      <td>{site.name}</td>
                      {[...Array(6).keys()].map((index) => {
                        const essay_classification =
                          essay_classifications[index + 1]

                        return (
                          <td key={`class-${index}`}>
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-disabled">
                                  <div className="text-left py-3 pr-3">
                                    {!essay_classification ? (
                                      <p>No aplica</p>
                                    ) : (
                                      <>
                                        <ul>
                                          {essay_classification.essays.map(
                                            (essay) => (
                                              <li key={`essay-${essay.name}`}>
                                                <Badge
                                                  className="px-1 py-1 mr-2"
                                                  variant={
                                                    essay.status === 'ready'
                                                      ? 'success'
                                                      : 'secondary'
                                                  }
                                                  style={{
                                                    fontSize: '4pt',
                                                    width: '12px',
                                                  }}
                                                >
                                                  <FontAwesomeIcon
                                                    icon={
                                                      essay.status === 'ready'
                                                        ? faCheck
                                                        : faClock
                                                    }
                                                    color="white"
                                                  />
                                                </Badge>
                                                {essay.name}
                                              </li>
                                            ),
                                          )}
                                        </ul>
                                      </>
                                    )}
                                  </div>
                                </Tooltip>
                              }
                            >
                              <Badge
                                className="px-2 py-2"
                                variant={
                                  !essay_classification
                                    ? 'warning'
                                    : essay_classification.status === 'ready'
                                    ? 'success'
                                    : 'secondary'
                                }
                                style={{ fontSize: '7pt', width: '24px' }}
                              >
                                {!essay_classification ? (
                                  <small>N/A</small>
                                ) : (
                                  <FontAwesomeIcon
                                    icon={
                                      essay_classification.status === 'ready'
                                        ? faCheck
                                        : faClock
                                    }
                                    color="white"
                                  />
                                )}
                              </Badge>
                            </OverlayTrigger>
                          </td>
                        )
                      })}
                      <td>
                        <Link
                          className={`text-decoration-none ${
                            priority.id === 2
                              ? 'text-danger'
                              : 'text-stng-darker'
                          }`}
                          to={`/tribology/samples/results/${sample.id}`}
                        >
                          {sample.id}
                        </Link>
                      </td>
                      <td>
                        {sample.date_receive &&
                          moment(sample.date_receive).format('DD/MM/YYYY')}
                      </td>
                      <td>
                        <Badge variant={priority.id === 2 ? 'danger' : 'stng'}>
                          {priority.name}
                        </Badge>
                      </td>
                    </tr>
                  ),
                )}
              </UITableBody>
            </UITable>
            <UIPagination pagination={pagination} onClick={handlePageChange} />
          </>
        ) : (
          <UIEmpty message="No hay nuevas muestras recepcionadas" />
        )}
      </UIForm>
    )
  }

  // Error
  return <UIError />
}
export default TribologySamplesResultsList
