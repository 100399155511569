import React from 'react'

// Components
import UIPanelInfoBlock from '../../../../UI/Panel/Info/Block'
import UIPanelInfoTable from '../../../../UI/Panel/Info/Table'
import UIFormInputText from '../../../../UI/FormReload/Input/Text'
import UIFormInputInteger from '../../../../UI/FormReload/Input/Integer'

// Common
import { oe } from '../../../../../common/object/extractor'

import AdministrationInfo, { RefLink } from '../..'

// Queries
import { READ_MACHINES, DELETE_MACHINES } from './queries'

const Info = (data) => (
  <UIPanelInfoBlock title="Equipo">
    <UIPanelInfoTable>
      {[
        ['ID', oe(data, ['id'])],
        ['Descripción', oe(data, ['name'])],
        [
          'Faena',
          <RefLink
            id={oe(data, ['site', 'id'])}
            tab={'client'}
            subtab={'sites'}
          >
            {oe(data, ['site', 'name'])}
          </RefLink>,
        ],
        [
          'Cliente',
          <RefLink
            id={oe(data, ['client', 'id'])}
            tab={'client'}
            subtab={'clients'}
          >
            {oe(data, ['client', 'name'])}
          </RefLink>,
        ],
        [
          'Tipo equipo',
          <RefLink
            id={oe(data, ['type', 'id'])}
            tab={'machine'}
            subtab={'types'}
          >
            {oe(data, ['type', 'name'])}
          </RefLink>,
        ],
        [
          'Marca',
          <RefLink
            id={oe(data, ['brand', 'id'])}
            tab={'machine'}
            subtab={'brands'}
          >
            {oe(data, ['brand', 'name'])}
          </RefLink>,
        ],
        [
          'Modelo',
          <RefLink
            id={oe(data, ['model', 'id'])}
            tab={'machine'}
            subtab={'models'}
          >
            {oe(data, ['model', 'name'])}
          </RefLink>,
        ],
      ]}
    </UIPanelInfoTable>
  </UIPanelInfoBlock>
)

const Form = () => (
  <>
    <UIFormInputInteger label="ID" name="id" />
    <UIFormInputText label="Descripción" name="name" />
    <UIFormInputInteger label="ID cliente" name="client_id" />
    <UIFormInputText label="Descripción cliente" name="client_name" />
    <UIFormInputInteger label="ID faena" name="site_id" />
    <UIFormInputText label="Descripción faena" name="site_name" />
    <UIFormInputInteger label="ID tipo equipo" name="machine_type_id" />
    <UIFormInputText label="Descripción tipo equipo" name="machine_type_name" />
  </>
)

const AdministrationInfoMachineMachines = ({ ...rest }) => (
  <AdministrationInfo
    queries={{ read: READ_MACHINES, delete: DELETE_MACHINES }}
    views={{ form: Form, info: Info }}
    {...rest}
  />
)

export default AdministrationInfoMachineMachines
