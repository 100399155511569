export const oe = (object, array, defaultt = '') => {
  if (!Array.isArray(array)) return array

  try {
    return array.reduce((o, a) => o[a], object)
  } catch (e) {
    //console.error(e)

    return defaultt
  }
}

export default oe
