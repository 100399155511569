import { gql } from '../../../../../hooks'

export const READ_CHANGE_MOTIVES = gql`
  query AdministrationReadComponentChangeMotives($id: ID!) {
    aRead(path: "component/change/motives", id: $id) {
    }
  }
`

export const DELETE_CHANGE_MOTIVES = gql`
  mutation AdministrationDeleteComponentChangeMotives($id: ID!) {
    aDelete(path: "component/change/motives", id: $id) {
    }
  }
`
