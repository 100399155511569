import React from 'react'

// Components
import AdministrationListAnalysisLogics from '../../../../Administration/List/Analysis/Logics'
import AdministrationInfoAnalysisLogics from '../../../../Administration/Info/Analysis/Logics'
import AdministrationFormAnalysisLogics from '../../../../Administration/Form/Analysis/Logics'

import { AdministrationContainer } from '../..'

const AdministrationContainerAnalysisLogics = () => (
  <AdministrationContainer
    form={AdministrationFormAnalysisLogics}
    info={AdministrationInfoAnalysisLogics}
    list={AdministrationListAnalysisLogics}
  />
)

export default AdministrationContainerAnalysisLogics
