import React from 'react'
import { Col, Row } from 'react-bootstrap'

import { useState, useParams } from '../../../../hooks'

import TribologySamplesResultsList from '../../../../components/Tribology/Samples/Results/List'
import TribologySamplesResultsForm from '../../../../components/Tribology/Samples/Results/Form'
import TribologySamplesResultsInfo from '../../../../components/Tribology/Samples/Results/Info'
import TribologySamplesResultsGraph from '../../../../components/Tribology/Samples/Results/Graph'

const PageTribologySamplesResults = () => {
  const { id_sample } = useParams()
  const [filterBySiteId, setFilterBySiteId] = useState(0)

  if (id_sample)
    return (
      <Row className="m-4">
        <Col lg={8} className="mb-3">
          <TribologySamplesResultsForm id={id_sample} />
        </Col>
        <Col lg={4}>
          <TribologySamplesResultsInfo id={id_sample} />
          <TribologySamplesResultsGraph id={id_sample} />
        </Col>
      </Row>
    )

  return (
    <Row className="m-4">
      <Col lg={12} className="mb-3">
        <TribologySamplesResultsList
          filterBySiteId={filterBySiteId}
          setFilterBySiteId={setFilterBySiteId}
        />
      </Col>
    </Row>
  )
}

export default PageTribologySamplesResults
