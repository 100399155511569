import { gql } from '../../../../../hooks'

export const READ_COMPONENTS = gql`
  query AdministrationReadComponentComponents($id: ID!) {
    aRead(path: "component/components", id: $id) {
    }
  }
`

export const UPDATE_COMPONENTS = gql`
  mutation AdministrationUpdateComponentComponents(
    $id: ID!
    $data: JSONObject
  ) {
    aUpdate(path: "component/components", id: $id, data: $data) {
    }
  }
`

export const CREATE_COMPONENTS = gql`
  mutation AdministrationCreateComponentComponents($data: JSONObject) {
    aCreate(path: "component/components", data: $data) {
    }
  }
`

export const LIST_COMPONENTS = gql`
  query AdministrationListComponentComponents(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(
      path: "component/components"
      filter: $filter
      page: $page
      limit: $limit
    ) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`

export const SEARCH_BRAND = gql`
  query AdministrationSearchComponentBrand {
    aSearch(path: "component_brand", limit: 0, text: "") {
    }
  }
`

export const SEARCH_MODEL = gql`
  query AdministrationSearchComponentModel($id_brand: Int) {
    aSearch(path: "component_model", filter: { id_brand: $id_brand }, limit: 0, text: "") {
    }
  }
`

export const SEARCH_COMPONENT_TYPE = gql`
  query AdministrationSearchComponentType($text: String) {
    aSearch(path: "component_type", text: $text) {
    }
  }
`

export const SEARCH_MACHINE_TYPE = gql`
  query AdministrationSearchMachineType($text: String, $id_site: Int) {
    aSearch(path: "machine_type", filter: { id_site: $id_site },  text: $text) {
    }
  }
`

export const SEARCH_SITE = gql`
  query AdministrationSearchSite($text: String) {
    aSearch(path: "site", text: $text) {
    }
  }
`

export const SEARCH_MACHINE = gql`
  query AdministrationSearchMachine($text: String, $id_machine_type: Int, $id_site: Int) {
    aSearch(path: "machine",  filter: { id_machine_type: $id_machine_type, id_site: $id_site }, text: $text) {
    }
  }
`

export const SEARCH_LUBRICANT = gql`
  query AdministrationSearchLubricant($text: String) {
    aSearch(path: "lubricant", text: $text) {
    }
  }
`

export const SEARCH_PROTOCOL = gql`
  query AdministrationSearchProtocol($text: String) {
    aSearch(path: "protocol", filter: { type: 2 }, text: $text) {
    }
  }
`

export const SEARCH_SAMPLING_POINT = gql`
  query AdministrationSearchSamplingPoint {
    aSearch(path: "sampling_point", limit: 0, text: "") {
    }
  }
`

export const SEARCH_SAMPLING_PROCEDURE = gql`
  query AdministrationSearchSamplingProcedure {
    aSearch(path: "sampling_procedure", limit: 0, text: "") {
    }
  }
`

export const SEARCH_COLLECTION = gql`
  query AdministrationSearchCollection($text: String) {
    aSearch(path: "collection", text: $text) {
    }
  }
`
