import React from 'react'

// Components
import AdministrationListClientClientsCases from '../../../../../Administration/List/Client/Clients/Cases'
import AdministrationInfoClientClientsCases from '../../../../../Administration/Info/Client/Clients/Cases'
import AdministrationFormClientClientsCases from '../../../../../Administration/Form/Client/Clients/Cases'

import { AdministrationContainer } from '../../..'

const AdministrationContainerClientClientsCases = () => (
  <AdministrationContainer
    form={AdministrationFormClientClientsCases}
    info={AdministrationInfoClientClientsCases}
    list={AdministrationListClientClientsCases}
  />
)

export default AdministrationContainerClientClientsCases
