import React from 'react'

// Components
import AdministrationListSamplingProcedures from '../../../../Administration/List/Sampling/Procedures'
import AdministrationInfoSamplingProcedures from '../../../../Administration/Info/Sampling/Procedures'
import AdministrationFormSamplingProcedures from '../../../../Administration/Form/Sampling/Procedures'

import { AdministrationContainer } from '../..'

const AdministrationContainerSamplingProcedures = () => (
  <AdministrationContainer
    form={AdministrationFormSamplingProcedures}
    info={AdministrationInfoSamplingProcedures}
    list={AdministrationListSamplingProcedures}
  />
)

export default AdministrationContainerSamplingProcedures
