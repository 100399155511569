import React from 'react'
import { Row, Col, Form } from 'react-bootstrap'

// Hooks
import { useTrigger } from '../../../../hooks'

// Components
import UIButton from '../../../UI/Button'
import UIForm from '../../../UI/FormReload'
import UIFormInputSelect from '../../../UI/FormReload/Input/Select'
import UIFormInputNumber from '../../../UI/FormReload/Input/Number'
import UIFormInputText from '../../../UI/FormReload/Input/Text'
import UIFormSeparation from '../../../UI/FormReload/Separation'
import UIPanelInfoTable from '../../../UI/Panel/Info/Table'

// // Queries
// import {
//   READ_BRANDS,
//   CREATE_BRANDS,
//   UPDATE_BRANDS,
//   LIST_BRANDS,
// } from './queries'
//
// const queries = {
//   read: READ_BRANDS,
//   create: CREATE_BRANDS,
//   update: UPDATE_BRANDS,
//   list: LIST_BRANDS,
// }

const AdministrationFormGrease = () => {
  const [triggerReset, setTriggerReset] = useTrigger()

  // --------------------------------------------------------------------------
  //
  // Render
  //
  // --------------------------------------------------------------------------

  const handleSubmit = (values) => {
    // onFilterChange(values)
    setTriggerReset()
  }

  return (
    <Row className="m-4">
      <Col className="mb-3">
        <UIForm onSubmit={handleSubmit} triggerReset={triggerReset}>
          <Form.Group as={Row} className="my-1">
            <Col className="d-flex">
              <UIButton type="submit">Ingresar</UIButton>
            </Col>
          </Form.Group>
          <Row>
            <Col xs={12}>
              <UIFormSeparation text="Formulario grasas" />
              <UIPanelInfoTable>
                {[
                  [
                    'Orden de trabajo',
                    <UIFormInputText name="work_order" required />,
                  ],
                  [
                    'OT Tak',
                    <UIFormInputText name="work_order_task" required />,
                  ],
                  [
                    'Grupo de trabajo',
                    <UIFormInputText name="work_group" required />,
                  ],
                  [
                    'Nombre Lubricante',
                    <UIFormInputSelect name="id_lubricant">
                      <option value={1}>Aceite A</option>
                      <option value={2}>Aceite B</option>
                    </UIFormInputSelect>,
                  ],
                  ['Cantidad', <UIFormInputNumber name="amount" required />],
                  [
                    'Unidad de medida',
                    <UIFormInputSelect name="id_unit">
                      <option value={1}>Litros</option>
                      <option value={2}>Galones</option>
                    </UIFormInputSelect>,
                  ],
                  [
                    'Equipo',
                    <UIFormInputSelect name="id_machine">
                      <option value={1}>Equipo 1</option>
                      <option value={2}>Equipo 2</option>
                      <option value={3}>Equipo 3</option>
                    </UIFormInputSelect>,
                  ],
                ]}
              </UIPanelInfoTable>
            </Col>
          </Row>
        </UIForm>
      </Col>
    </Row>
  )
}

export default AdministrationFormGrease
