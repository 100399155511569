import React from 'react'

// Components
import UIDashboardQuickSight from '../../UI/Dashboard/QuickSight'

const DashboardCummins = () => {
  return <UIDashboardQuickSight id="cc5a4fc9-2bdd-4942-b8c3-7c296a422ddf" />
}

export default DashboardCummins
