import React from 'react'

// Components
import AdministrationListSamplingEssays from '../../../../Administration/List/Sampling/Essays'
import AdministrationInfoSamplingEssays from '../../../../Administration/Info/Sampling/Essays'
import AdministrationFormSamplingEssays from '../../../../Administration/Form/Sampling/Essays'

import { AdministrationContainer } from '../..'

const AdministrationContainerSamplingEssays = () => (
  <AdministrationContainer
    form={AdministrationFormSamplingEssays}
    info={AdministrationInfoSamplingEssays}
    list={AdministrationListSamplingEssays}
  />
)

export default AdministrationContainerSamplingEssays
