import React from 'react'
import { Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

// Hooks
import {
  useState,
  useGlobal,
  useTrigger,
  useEffect,
  useMutation,
} from '../../../../../hooks'

// Components
import UIPanelInfoBlock from '../../../../UI/Panel/Info/Block'
import UIPanelInfoTable from '../../../../UI/Panel/Info/Table'
import UIPanelInfoSeparation from '../../../../UI/Panel/Info/Separation'
import UIFormInputText from '../../../../UI/FormReload/Input/Text'
import UIFormInputInteger from '../../../../UI/FormReload/Input/Integer'
import UIButton from '../../../../UI/Button'
import UIModal from '../../../../UI/Modal'

// Common
import { oe } from '../../../../../common/object/extractor'

import AdministrationInfo, { RefLink } from '../..'

// Queries
import { READ_USERS, DELETE_USERS, RESET_PASSWORD } from './queries'

const Info = (data) => {
  const [{ me }] = useGlobal()

  const [newPass, setNewPass] = useState()

  const [modalTrigger, setModalTrigger] = useTrigger()

  useEffect(() => {
    setNewPass()
  }, [data.id])

  const [resetPassword] = useMutation(RESET_PASSWORD, {
    onSuccess: ({ data }) => setNewPass(data.password),
    onErrorMessage: 'No se pudo resetear la contraseña',
  })

  const handleResetPassword = () => {
    setModalTrigger()
    // setShowModalState(id)
  }

  const handleAcceptModal = () => {
    resetPassword({ variables: { id: oe(data, ['id']) } })
  }

  return (
    <>
      <UIPanelInfoBlock title="Usuario">
        <UIPanelInfoTable>
          {[
            ['ID', oe(data, ['id'])],
            [
              'Nombre',
              `${oe(data, ['name'])} ${oe(data, ['surname_1'])} ${oe(data, [
                'surname_2',
              ])} `,
            ],
            ['RUT', `${oe(data, ['run'])}-${oe(data, ['dv'])}`],
            ['Correo', oe(data, ['email'], '--')],
            ['Sexo', oe(data, ['sex'], '--')],
            ['Nombre de usuario', oe(data, ['username'])],
            ['Rol', oe(data, ['role', 'name'])],
            ['Laboratorio', oe(data, ['laboratory', 'name'], 'Todos')],
            [
              'Cliente',
              oe(data, ['clients'], false) !== '0' ? (
                <RefLink
                  id={oe(data, ['client', 'id'])}
                  tab={'client'}
                  subtab={'clients'}
                >
                  {oe(data, ['client', 'name'])}
                </RefLink>
              ) : (
                'Todos'
              ),
            ],
            ...(oe(data, ['role', 'id']) === 5
              ? [
                  [
                    'Faena',
                    oe(data, ['sites'], false) !== '0' ? (
                      <RefLink
                        id={oe(data, ['site', 'id'])}
                        tab={'client'}
                        subtab={'sites'}
                      >
                        {oe(data, ['site', 'name'])}
                      </RefLink>
                    ) : (
                      'Todos'
                    ),
                  ],
                  [
                    'Tipo de equipo',
                    oe(data, ['machine_types'], false) !== '0' ? (
                      <RefLink
                        id={oe(data, ['machine_type', 'id'])}
                        tab={'machine'}
                        subtab={'types'}
                      >
                        {oe(data, ['machine_type', 'name'])}
                      </RefLink>
                    ) : (
                      'Todos'
                    ),
                  ],
                ]
              : []),
            ['Activo', oe(data, ['active'], false) ? 'Sí' : 'No'],
          ]}
        </UIPanelInfoTable>
      </UIPanelInfoBlock>

      {newPass && (
        <>
          <UIPanelInfoSeparation />
          <Card bg={'danger'} text={'white'} className="my-2">
            <Card.Header className="mr-4 text-center">
              <p>Contraseña:</p>
              <h2>
                <strong>
                  <em>{newPass}</em>
                </strong>
              </h2>
            </Card.Header>
            <Card.Body>
              <Card.Text className="text-center">
                <p>
                  <FontAwesomeIcon
                    className="align-self-center mr-2"
                    icon={faExclamationCircle}
                  />
                  Contraseña temporal autogenerada
                </p>
                <small>
                  Ésta es la única vez que la contraseña será visible. Porfavor
                  copiarla y entregarla al nuevo usuario.
                </small>
              </Card.Text>
            </Card.Body>
          </Card>
        </>
      )}
      {[1, 2].includes(me.id_role) && (
        <UIButton
          className="ml-2"
          variant="outline-danger"
          onClick={handleResetPassword}
        >
          Resetear contraseña
        </UIButton>
      )}
      <UIModal
        title={'Confirmación'}
        trigger={modalTrigger}
        onAccept={handleAcceptModal}
        body={'¿Realmente desea generar una nueva contraseña para el usuario?'}
      />
    </>
  )
}

const Form = () => (
  <>
    <UIFormInputInteger label="ID" name="id" />
    <UIFormInputText label="RUT" name="run" />
    <UIFormInputText label="Nombre" name="name" />
    <UIFormInputText label="Apellido paterno" name="surname_1" />
    <UIFormInputText label="Apellido materno" name="surname_2" />
  </>
)

const AdministrationInfoUserUsers = ({ ...rest }) => {
  const [{ me }] = useGlobal()

  const no = ![1, 2].includes(me.id_role)

  return (
    <AdministrationInfo
      queries={{ read: READ_USERS, delete: DELETE_USERS }}
      views={{ form: Form, info: Info }}
      {...rest}
      noDuplicate={no}
      noUpdate={no}
      noDelete={no}
    />
  )
}

export default AdministrationInfoUserUsers
