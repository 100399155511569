import React from 'react'
import { Col, Row } from 'react-bootstrap'

import TribologySamplesInsertMassivePrintForm from '../../../../../Tribology/Samples/Insert/Massive/Print/Form'
import TribologySamplesInsertMassivePrintInfo from '../../../../../Tribology/Samples/Insert/Massive/Print/Info'

const TribologySamplesInsertMassivePrint = (props) => {
  return (
    <Row className="my-4">
      <Col lg={8} className="mb-3">
        <TribologySamplesInsertMassivePrintForm />
      </Col>
      <Col lg={4}>
        <TribologySamplesInsertMassivePrintInfo />
      </Col>
    </Row>
  )
}

export default TribologySamplesInsertMassivePrint
