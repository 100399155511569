import React from 'react'
import { Col, Row } from 'react-bootstrap'

// Hooks
import { useState } from '../../../../hooks'

// Components
import ReportFormFilter from '../../Form/Filter'
import ReportTableData from '../../Table/Data'

const ReportContainerData = () => {
  const [filter, setFilterState] = useState()

  const handleFilterChange = (filter) => {
    setFilterState(filter)
  }

  return (
    <Row>
      <Col className="mb-3">
        {filter ? (
          <ReportTableData
            filter={filter}
            onFilterChange={handleFilterChange}
          />
        ) : (
          <ReportFormFilter onFilterChange={handleFilterChange} name="data" />
        )}
      </Col>
    </Row>
  )
}

export default ReportContainerData
