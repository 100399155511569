import React from 'react'
import { Row, Col, Form, Modal } from 'react-bootstrap'
import * as XLSX from 'xlsx'

// Hooks
import {
  useAlert,
  useState,
  useEffect,
  useQuery,
  useMutation,
} from '../../../../../../../hooks'

// Components
import UILoading from '../../../../../../../components/UI/Loading'
import UIButton from '../../../../../../../components/UI/Button'
import UIForm from '../../../../../../../components/UI/FormReload'
import UIFormSeparation from '../../../../../../../components/UI/FormReload/Separation'
import UITable from '../../../../../../../components/UI/Table'
import UITableHead from '../../../../../../../components/UI/Table/Head'
import UITableBody from '../../../../../../../components/UI/Table/Body'

// Queries
import {
  ENTER_SAMPLES_MASSIVE_EXCEL,
  GET_SAMPLE_MASSIVE_EXCEL_INFO,
  GET_MASSIVE_SAMPLES,
} from './queries'

const SampleFormInsertMassiveTabEscelTable = ({
  data,
  // onDataChange,
  onIdChange,
  onBack,
}) => {
  const { id_client, date_withdraw, file } = data

  const [excel, setExcelState] = useState()
  const [modalShow, setModalShowState] = useState({})

  const alert = useAlert()

  // --------------------------------------------------------------------------
  //
  // Extract file
  //
  // --------------------------------------------------------------------------

  useEffect(() => {
    const reader = new FileReader()
    reader.onload = (event) => {
      const data = event.target.result
      let readedData = XLSX.read(data, { type: 'binary' })
      const wsname = readedData.SheetNames[0]
      const ws = readedData.Sheets[wsname]

      /* Convert array to json*/
      setExcelState(XLSX.utils.sheet_to_json(ws, { header: 1 }))
    }
    reader.readAsBinaryString(file)
  }, [file])

  // --------------------------------------------------------------------------
  //
  // Query: Transform excel to the right format
  //
  // --------------------------------------------------------------------------

  const { data: samples } = useQuery(GET_SAMPLE_MASSIVE_EXCEL_INFO, {
    fetchPolicy: 'network-only',
    skip: !excel,
    variables: { data: { id_client, excel } },
    onSuccess: ({ warning }) => {
      if (warning && warning.length) {
        warning.forEach((message) => alert(message, { style: 'danger' }))

        onBack()
      }
    },
    onError: onBack,
  })

  // --------------------------------------------------------------------------
  //
  // Mutation: Insert samples
  //
  // --------------------------------------------------------------------------

  const [t2EnterSampleMassiveExcel] = useMutation(ENTER_SAMPLES_MASSIVE_EXCEL, {
    update: (cache, { data }) => {
      // Get anomalies
      const { t2MassiveSamples } = cache.readQuery({
        query: GET_MASSIVE_SAMPLES,
      })

      if (
        data &&
        data.t2EnterSampleMassiveExcel &&
        data.t2EnterSampleMassiveExcel.data &&
        data.t2EnterSampleMassiveExcel.data.info
      )
        t2MassiveSamples.data.push(data.t2EnterSampleMassiveExcel.data.info)

      // Write the cache
      cache.writeQuery({
        query: GET_MASSIVE_SAMPLES,
        data: {
          t2MassiveSamples,
        },
      })
    },
    onSuccessMessage: 'Las muestras fueron ingresadas con éxito',
    onSuccess: ({ id }) => {
      onIdChange(id)
      onBack()
    },
    onErrorMessage: `La muestra no pudo ser ingresada`,
  })

  // --------------------------------------------------------------------------
  //
  // Render
  //
  // --------------------------------------------------------------------------

  if (
    samples &&
    samples.t2SampleMassiveExcelInfo &&
    samples.t2SampleMassiveExcelInfo.data &&
    samples.t2SampleMassiveExcelInfo.data.components
  ) {
    const { components } = samples.t2SampleMassiveExcelInfo.data

    const handleBack = () => {
      onBack()
    }

    const handleSubmit = () => {
      const samples = components.map((component) => ({
        ...component,
        date_withdraw,
      }))

      t2EnterSampleMassiveExcel({
        variables: { samples },
      })
    }

    return (
      <UIForm onSubmit={handleSubmit}>
        <Form.Group as={Row} className="my-4">
          <Col className="d-flex">
            <UIButton className="mx-2" onClick={handleBack}>
              Atrás
            </UIButton>
            <UIButton type="submit" className="mx-2">
              Ingresar muestras
            </UIButton>
          </Col>
        </Form.Group>
        <UIFormSeparation />
        <UITable borderless className="text-center font-weight-light my-4">
          <UITableHead>
            <tr>
              <td className="bg-stng border text-white align-middle">
                <small>Tag</small>
              </td>
              <td className="bg-stng border text-white align-middle">
                <small>Código externo</small>
              </td>
              <td className="bg-stng border text-white align-middle">
                <small>Fecha muestreo</small>
              </td>
              <td className="bg-stng border text-white align-middle">
                <small>H/K equipo</small>
              </td>
              <td className="bg-stng border text-white align-middle">
                <small>H/K componente</small>
              </td>
              <td className="bg-stng border text-white align-middle">
                <small>H/K lubricante</small>
              </td>
              <td className="bg-stng border text-white align-middle">
                <small>Comentario</small>
              </td>
              <td className="bg-stng border text-white align-middle">
                <small>Cambio aceite</small>
              </td>
              <td className="bg-stng border text-white align-middle">
                <small>Fecha retiro</small>
              </td>
            </tr>
          </UITableHead>
          <UITableBody>
            {components.map(
              (
                {
                  component_name,
                  component_tag,
                  lubricant_name,
                  machine_brand_name,
                  machine_model_name,
                  machine_name,
                  machine_type_name,
                  external_number,
                  date_sampling,
                  machine_continuity,
                  component_continuity,
                  lubricant_continuity,
                  comment,
                  id_lubricant_change_refill,
                  // TODO add date_retiro ??
                },
                index,
              ) => (
                <tr key={`table-body-${index}`}>
                  <td>
                    <small
                      onClick={() =>
                        setModalShowState((prev) => ({
                          ...prev,
                          [index]: true,
                        }))
                      }
                    >
                      {component_tag}
                    </small>
                    <Modal
                      show={modalShow[index]}
                      onHide={() =>
                        setModalShowState((prev) => ({
                          ...prev,
                          [index]: false,
                        }))
                      }
                      animation={false}
                    >
                      <Modal.Header className="text-center" closeButton>
                        <Modal.Title>{component_tag}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <p>
                          <strong>Tipo Equipo:</strong> {machine_type_name}
                        </p>
                        <p>
                          <strong>Equipo:</strong> {machine_name}
                        </p>
                        <p>
                          <strong>Marca:</strong> {machine_brand_name}
                        </p>
                        <p>
                          <strong>Modelo:</strong> {machine_model_name}
                        </p>
                        <p>
                          <strong>Componente:</strong> {component_name}
                        </p>
                        <p>
                          <strong>Lubricante:</strong> {lubricant_name}
                        </p>
                      </Modal.Body>
                    </Modal>
                  </td>
                  <td>{external_number}</td>
                  <td>{date_sampling}</td>
                  <td>{machine_continuity}</td>
                  <td>{component_continuity}</td>
                  <td>{lubricant_continuity}</td>
                  <td>{comment}</td>
                  <td>{id_lubricant_change_refill}</td>
                  <td>{date_withdraw}</td>
                </tr>
              ),
            )}
          </UITableBody>
        </UITable>
      </UIForm>
    )
  }

  return <UILoading />
}

export default SampleFormInsertMassiveTabEscelTable
