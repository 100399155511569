import React from 'react'
import { Col, Row } from 'react-bootstrap'

// Hooks
import { useState } from '../../../../hooks'

import SubstanceFormWaste from '../../Form/Waste'
import SubstanceInfoWaste from '../../Info/Waste'

const SubstanceContainerWaste = () => {
  const [values, setValuesState] = useState([])

  const handleValuesChange = (values) => {
    setValuesState((prev) => {
      return [...prev, { ...values }]
    })
  }

  const handleValueDelete = (id) => {
    setValuesState((prev) => {
      prev.splice(id, 1)
      return [...prev]
    })
  }

  const handleSubmit = () => {
    // TODO
    setValuesState([])
  }

  return (
    <>
      <Row className="m-4">
        <Col lg={8} className="mb-3">
          <SubstanceFormWaste
            onValuesChange={handleValuesChange}
            onSubmit={handleSubmit}
          />
        </Col>
        <Col lg={4}>
          <SubstanceInfoWaste values={values} onDelete={handleValueDelete} />
        </Col>
      </Row>
    </>
  )
}

export default SubstanceContainerWaste
