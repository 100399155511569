import { gql } from '../../../../../hooks'

export const READ_ESSAY_CLASSIFICATIONS = gql`
  query AdministrationReadSamplingEssayClassifications($id: ID!) {
    aRead(path: "sampling/essay/classifications", id: $id) {
    }
  }
`

export const DELETE_ESSAY_CLASSIFICATIONS = gql`
  mutation AdministrationDeleteSamplingEssayClassifications($id: ID!) {
    aDelete(path: "sampling/essay/classifications", id: $id) {
    }
  }
`
