import { gql } from '../../../../hooks'

export const LIST_CLIENTS = gql`
  query ListClients($id: ID!) {
    rClientsByLaboratory(id: $id) {
    }
  }
`

export const LIST_SITES = gql`
  query ListSites($id: ID!) {
    rSitesByClient(id: $id) {
    }
  }
`

export const LIST_MACHINE_TYPES = gql`
  query ListMachineTypes($id: ID!) {
    rMachineTypesBySite(id: $id) {
    }
  }
`

export const LIST_MACHINES = gql`
  query ListMachinesBySiteAndType($id_site: ID!, $id_machine_type: ID!) {
    rMachinesBySiteAndType(
      id_site: $id_site
      id_machine_type: $id_machine_type
    ) {
    }
  }
`
