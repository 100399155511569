import { gql } from '../../../../../hooks'

export const READ_PROTOCOLS = gql`
  query AdministrationReadLubricantProtocols($id: ID!) {
    aRead(path: "lubricant/protocols", id: $id) {
    }
  }
`

export const DELETE_PROTOCOLS = gql`
  mutation AdministrationDeleteLubricantProtocols($id: ID!) {
    aDelete(path: "lubricant/protocols", id: $id) {
    }
  }
`
