import React from 'react'

// Hooks
import { useState } from '../../../../../hooks'

// Components
import UIPanelInfoBlock from '../../../../UI/Panel/Info/Block'
import UIPanelInfoTable from '../../../../UI/Panel/Info/Table'
import UIFormInputText from '../../../../UI/FormReload/Input/Text'
import UIFormInputInteger from '../../../../UI/FormReload/Input/Integer'
import UIFormInputSelect from '../../../../UI/FormReload/Input/Select'

// Common
import { oe } from '../../../../../common/object/extractor'

import AdministrationInfo, { RefLink } from '../..'

// Queries
import {
  READ_LUBRICANTS,
  DELETE_LUBRICANTS,
  LIST_BRANDS,
  LIST_PROTOCOLS,
  LIST_MODELS_BY_BRAND,
} from './queries'

const Info = (data) => (
  <UIPanelInfoBlock title="Lubricante">
    <UIPanelInfoTable>
      {[
        ['ID', oe(data, ['id'])],
        ['Descripción', oe(data, ['name'])],
        [
          'Marca',
          <RefLink
            id={oe(data, ['brand', 'id'])}
            tab={'lubricant'}
            subtab={'brands'}
          >
            {oe(data, ['brand', 'name'])}
          </RefLink>,
        ],
        [
          'Modelo',
          <RefLink
            id={oe(data, ['model', 'id'])}
            tab={'lubricant'}
            subtab={'models'}
          >
            {oe(data, ['model', 'name'])}
          </RefLink>,
        ],
        [
          'Protocolo (AFQ)',
          <RefLink
            id={oe(data, ['protocol', 'id'])}
            tab={'lubricant'}
            subtab={'protocols'}
          >
            {oe(data, ['protocol', 'name'])}
          </RefLink>,
        ],
      ]}
    </UIPanelInfoTable>
  </UIPanelInfoBlock>
)

const Form = () => {
  const [id, setIdState] = useState()

  const handleChange = (event) => {
    setIdState(event.target.value)
  }

  return (
    <>
      <UIFormInputInteger label="ID" name="id" />
      <UIFormInputText label="Descripción" name="name" />
      <UIFormInputSelect
        label="Marca"
        name="brand_id"
        query={LIST_BRANDS}
        onChange={handleChange}
      >
        <option>Todos</option>
      </UIFormInputSelect>
      <UIFormInputText label="Descripción marca" name="brand_name" />
      <UIFormInputSelect
        label="Modelo"
        name="model_id"
        query={LIST_MODELS_BY_BRAND}
        variables={{ id }}
        skip={!id}
      >
        <option>Todos</option>
      </UIFormInputSelect>
      <UIFormInputText label="Descripción modelo" name="model_name" />
      <UIFormInputSelect
        label="Protocolo"
        name="protocol_id"
        query={LIST_PROTOCOLS}
      >
        <option>Todos</option>
      </UIFormInputSelect>
      <UIFormInputText label="Descripción protocolo" name="protocol_name" />
    </>
  )
}

const AdministrationInfoLubricantLubricants = ({ ...rest }) => (
  <AdministrationInfo
    queries={{ read: READ_LUBRICANTS, delete: DELETE_LUBRICANTS }}
    views={{ form: Form, info: Info }}
    {...rest}
  />
)

export default AdministrationInfoLubricantLubricants
