import { gql } from '../../../../../hooks'

export const READ_LABORATORIES = gql`
  query AdministrationReadReportLaboratories($id: ID!) {
    aRead(path: "other/laboratories", id: $id) {
    }
  }
`

export const UPDATE_LABORATORIES = gql`
  mutation AdministrationUpdateReportLaboratories($id: ID!, $data: JSONObject) {
    aUpdate(path: "other/laboratories", id: $id, data: $data) {
    }
  }
`

export const CREATE_LABORATORIES = gql`
  mutation AdministrationCreateReportLaboratories($data: JSONObject) {
    aCreate(path: "other/laboratories", data: $data) {
    }
  }
`

export const LIST_LABORATORIES = gql`
  query AdministrationListReportLaboratories(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(
      path: "other/laboratories"
      filter: $filter
      page: $page
      limit: $limit
    ) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`
