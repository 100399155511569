import React from 'react'
import { Link } from 'react-router-dom'
import {
  Table,
  OverlayTrigger,
  Tooltip,
  Form,
  Col,
  Row,
  Accordion,
  Card,
  Alert,
  Badge,
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faVial,
  faCheckCircle,
  faPen,
  faEquals,
  faComment,
  faExclamationCircle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons'

// Hooks
import { useHistory, useQuery, useGlobal } from '../../../../../hooks'

// Components
import UILoading from '../../../../UI/Loading'
import UIError from '../../../../UI/Error'
import UIButton from '../../../../UI/Button'
import UIPanelInfoSeparation from '../../../../UI/Panel/Info/Separation'

// Queries
import { GET_RESULTS_BY_SAMPLE } from './queries'

import config from '../../../../../config'

const { AWS } = config

const TribologySamplesReportForm = ({ id, onSave, values }) => {
  const history = useHistory()

  const [{ me }] = useGlobal()

  // --------------------------------------------------------------------------
  //
  // Query: Results
  //
  // --------------------------------------------------------------------------

  const { data, loading } = useQuery(GET_RESULTS_BY_SAMPLE, {
    fetchPolicy: 'network-only',
    variables: { id, noise: me.id_laboratory },
    onErrorMessage: `No se pudo cargar los resultados de la muestra`,
  })

  // --------------------------------------------------------------------------
  //
  // Render
  //
  // --------------------------------------------------------------------------

  // Loading
  if (loading) return <UILoading />

  // Data
  if (data && data.t4ResultsBySample && data.t4ResultsBySample.data) {
    const { info, essays, tests } = data.t4ResultsBySample.data

    const handleBack = () => {
      history.push(`/tribology/samples/report`)
    }

    // 1 | Ingresada
    // 2 | Recepcionada
    // 3 | Ensayada
    // 4 | Propuesta
    // 5 | Enviada
    // 6 | Rechazada
    // 7 | Anulada

    const isDisabled = [1, 2, 4, 5, 6, 7].includes(info.current.stage.id)

    const handleSave = () => {
      onSave()
    }

    return (
      <>
        {isDisabled && (
          <Alert variant={'danger'} className="text-center">
            <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" /> El
            estado actual de la muestra es:{' '}
            <strong>{info.current.stage.name}</strong>
          </Alert>
        )}
        <Form.Group as={Row} className="my-4">
          <Col className="d-flex">
            <UIButton onClick={handleBack} className="mr-auto">
              Volver a la lista
            </UIButton>
            <UIButton
              variant="success"
              onClick={handleSave}
              disabled={isDisabled || !values}
              className="mr-2"
            >
              Guardar
            </UIButton>
            <UIButton type="submit" disabled={isDisabled}>
              Aprobar informe
            </UIButton>
          </Col>
        </Form.Group>
        <Table
          responsive
          borderless
          className="text-center font-weight-light"
          size="sm"
        >
          <tbody>
            {/******* fecha muestreo *******/}
            <tr>
              <td
                className="text-left align-middle my-0 py-0"
                colSpan={
                  [
                    'antofagasta',
                    'collahuasi',
                    'quebradablanca',
                    'esmax',
                  ].includes(me.laboratory)
                    ? 7
                    : 6
                }
              >
                <small>Fecha muestreo</small>
              </td>
              {/*<td className="border-left border-right my-0 py-0">
                <small>{info.reference.date_sampling}</small>
              </td>*/}
              <td className="border-left border-right my-0 py-0">
                <small>{info.previous.date_sampling}</small>
              </td>
              <td className="border-left border-right my-0 py-0">
                <small>{info.current.date_sampling}</small>
              </td>
            </tr>

            {/******* fecha recepción *******/}
            <tr>
              <td
                className="text-left align-middle my-0 py-0"
                colSpan={
                  [
                    'antofagasta',
                    'collahuasi',
                    'quebradablanca',
                    'esmax',
                  ].includes(me.laboratory)
                    ? 7
                    : 6
                }
              >
                <small>Fecha recepción</small>
              </td>
              {/*<td className="border-left border-right my-0 py-0">
                <small>{info.reference.date_receive}</small>
              </td>*/}
              <td className="border-left border-right my-0 py-0">
                <small>{info.previous.date_receive}</small>
              </td>
              <td className="border-left border-right my-0 py-0">
                <small>{info.current.date_receive}</small>
              </td>
            </tr>

            {/******* H/K Equipo *******/}
            <tr>
              <td
                className="text-left align-middle my-0 py-0"
                colSpan={
                  [
                    'antofagasta',
                    'collahuasi',
                    'quebradablanca',
                    'esmax',
                  ].includes(me.laboratory)
                    ? 7
                    : 6
                }
              >
                <small>Horas / Km Equipo</small>
              </td>
              {/*<td className="border-left border-right my-0 py-0">
                <small>{info.reference.component_continuity}</small>
              </td>*/}
              <td className="border-left border-right my-0 py-0">
                <small>{info.previous.machine_continuity}</small>
              </td>
              <td className="border-left border-right my-0 py-0">
                <small>{info.current.machine_continuity}</small>
              </td>
            </tr>

            {/******* H/K Componente *******/}
            <tr>
              <td
                className="text-left align-middle my-0 py-0"
                colSpan={
                  [
                    'antofagasta',
                    'collahuasi',
                    'quebradablanca',
                    'esmax',
                  ].includes(me.laboratory)
                    ? 7
                    : 6
                }
              >
                <small>Horas / Km Componente</small>
              </td>
              {/*<td className="border-left border-right my-0 py-0">
                <small>{info.reference.component_continuity}</small>
              </td>*/}
              <td className="border-left border-right my-0 py-0">
                <small>{info.previous.component_continuity}</small>
              </td>
              <td className="border-left border-right my-0 py-0">
                <small>{info.current.component_continuity}</small>
              </td>
            </tr>

            {/******* H/K Lubricante *******/}
            <tr>
              <td
                className="text-left align-middle mt-0 pt-0"
                colSpan={
                  [
                    'antofagasta',
                    'collahuasi',
                    'quebradablanca',
                    'esmax',
                  ].includes(me.laboratory)
                    ? 7
                    : 6
                }
              >
                <small>Horas / Km Lubricante</small>
              </td>
              {/*<td className="border-left border-right mt-0 pt-0">
                <small>{info.reference.lubricant_continuity}</small>
              </td>*/}
              <td className="border-left border-right mt-0 pt-0">
                <small>{info.previous.lubricant_continuity}</small>
              </td>
              <td className="border-left border-right mt-0 pt-0">
                <small>{info.current.lubricant_continuity}</small>
              </td>
            </tr>

            {/******* First row *******/}
            <tr>
              {[
                'antofagasta',
                'collahuasi',
                'quebradablanca',
                'esmax',
              ].includes(me.laboratory) ? (
                <td className="bg-stng border text-white align-middle px-2">
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        Repetición de ensayos
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon icon={faVial} />
                  </OverlayTrigger>
                </td>
              ) : (
                ''
              )}
              <td className="bg-stng border text-white align-middle">
                <small>Ensayo</small>
              </td>
              <td className="bg-stng border text-white align-middle">
                <small>Método de Análisis</small>
              </td>
              <td
                className="bg-stng border text-white align-middle"
                colSpan={4}
              >
                <small>Límites</small>
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-disabled">
                      <div className="text-left py-3 px-5">
                        <Badge
                          className="mx-1"
                          style={{ minWidth: '50px' }}
                          variant="danger"
                        >
                          LIC
                        </Badge>
                        {' < '}
                        <Badge
                          className="mx-1"
                          style={{ minWidth: '50px' }}
                          variant="warning"
                        >
                          LIM
                        </Badge>
                        {' < '}
                        <Badge
                          className="mx-1"
                          style={{ minWidth: '50px' }}
                          variant="warning"
                        >
                          LSM
                        </Badge>
                        {' < '}
                        <Badge
                          className="mx-1"
                          style={{ minWidth: '50px' }}
                          variant="danger"
                        >
                          LSC
                        </Badge>
                        <p className="pt-4">Leyenda:</p>
                        <ul className="px-0 mt-0 pt-0">
                          <li>
                            <Badge
                              className="mx-1"
                              style={{ minWidth: '50px' }}
                              variant="danger"
                            >
                              LSC
                            </Badge>
                            : Límite Superior Condenatorio
                          </li>
                          <li>
                            <Badge
                              className="mx-1"
                              style={{ minWidth: '50px' }}
                              variant="warning"
                            >
                              LSM
                            </Badge>
                            : Límite Superior Marginal
                          </li>
                          <li>
                            <Badge
                              className="mx-1"
                              style={{ minWidth: '50px' }}
                              variant="warning"
                            >
                              LIM
                            </Badge>
                            : Límite Inferior Marginal
                          </li>
                          <li>
                            <Badge
                              className="mx-1"
                              style={{ minWidth: '50px' }}
                              variant="danger"
                            >
                              LIC
                            </Badge>
                            : Límite Inferior Condenatorio
                          </li>
                          <li>
                            <Badge
                              className="mx-1"
                              style={{ minWidth: '50px' }}
                              variant="light"
                            >
                              --
                            </Badge>
                            : Límite no especificado
                          </li>
                        </ul>
                      </div>
                    </Tooltip>
                  }
                >
                  <FontAwesomeIcon icon={faInfoCircle} className="ml-2" />
                </OverlayTrigger>
              </td>
              {/*<td className="bg-stng border text-white align-middle">
                <small>Alerta</small>
              </td>
              <td className="bg-stng border text-white align-middle">
                <small>Máximo</small>
              </td>
              <td className="bg-stng-lighter border text-stng-darker align-middle">
                <small>Referencia</small>
              </td>*/}
              <td className="bg-stng-lighter border text-stng-darker align-middle">
                <small className="text-stng-dark">
                  {info.previous.relative_id ? (
                    <>
                      (Correlativo#{info.previous.relative_id})
                      <br />
                      <Link
                        to={`/tribology/samples/report/${info.previous.id}`}
                        className={`text-decoration-none text-stng-dark`}
                      >
                        <strong>{info.previous.id}</strong>
                      </Link>
                    </>
                  ) : (
                    ''
                  )}
                  {/*<button
                    style={{ backgroundColor: 'rgb(0,0,0,0)', border: 'none' }}
                    className={`text-decoration-none text-stng-darker`}
                    onClick={handleIdChange}
                  >
                    <strong>{info.previous.id}</strong>
                    </button>*/}
                  {info.previous.comment && (
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-disabled">
                          {info.previous.comment}
                        </Tooltip>
                      }
                    >
                      <FontAwesomeIcon icon={faComment} className="ml-2" />
                    </OverlayTrigger>
                  )}
                </small>
              </td>
              <td className="bg-stng-lighter border text-stng-darker align-middle">
                <small className="text-stng-dark">
                  {`(Correlativo#${info.current.relative_id})`}
                  <br />
                  <strong>{info.current.id}</strong>
                  {info.current.comment && (
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-disabled">
                          {info.current.comment}
                        </Tooltip>
                      }
                    >
                      <FontAwesomeIcon icon={faComment} className="ml-2" />
                    </OverlayTrigger>
                  )}
                </small>
              </td>
            </tr>

            {/* Map for each classification ********************************************************/}
            {essays.map(({ info, data }, index_classification) => {
              const {
                id: id_essay_classification,
                name: name_essay_classification,
              } = info

              return (
                <React.Fragment key={`${index_classification}-classifications`}>
                  <tr className="text-left">
                    <td colSpan={9}>
                      <UIPanelInfoSeparation text={name_essay_classification} />
                    </td>
                  </tr>
                  {/* Map for each essay ***********************************************************/}
                  {data.map(
                    (
                      {
                        id_essay,
                        is_resampled,
                        name,
                        value,
                        value_prev,
                        // value_ref,
                        analysis_method,
                        lsm,
                        lsc,
                        lim,
                        lic,
                      },
                      index_result,
                    ) => {
                      let baseClassName = [
                        'text-center',
                        'align-middle',
                        'm-0',
                        'p-0',
                      ]

                      // Get style
                      // -----------------------------------
                      const getStyle = (value) => {
                        if (value && !isNaN(value)) {
                          value = parseFloat(value)

                          const hasLsc = lsc && lsc !== '' && value > lsc
                          const hasLsm = lsm && lsm !== '' && value > lsm
                          const hasLim = lim && lim !== '' && value < lim
                          const hasLic = lic && lic !== '' && value < lic

                          return hasLsc || hasLic
                            ? {
                                backgroundColor: '#dc3545',
                                color: 'white',
                              }
                            : hasLsm || hasLim
                            ? {
                                backgroundColor: '#ffc107',
                                color: 'white',
                              }
                            : index_result % 2 === 0
                            ? {
                                backgroundColor: 'rgba(0,0,0,0.05)',
                              }
                            : {}
                        }

                        return index_result % 2 === 0
                          ? {
                              backgroundColor: 'rgba(0,0,0,0.05)',
                            }
                          : {}
                      }

                      // Get style limit
                      // -----------------------------------
                      const limitStyle = {
                        ...(index_result % 2 === 0
                          ? { backgroundColor: 'rgba(0,0,0,0.15)' }
                          : { backgroundColor: 'rgba(0,0,0,0.05)' }),
                        borderRight: '1px dashed grey',
                      }

                      // Limit badge
                      // -----------------------------------
                      const LimitBadge = ({ variant, value }) =>
                        value ? (
                          <Badge
                            className="mx-0"
                            style={{ minWidth: '42px' }}
                            variant={variant}
                          >
                            {value}
                          </Badge>
                        ) : (
                          <Badge
                            className="mx-0"
                            style={{ minWidth: '42px' }}
                            variant="light"
                          >
                            --
                          </Badge>
                        )

                      // Prev value
                      // -----------------------------------
                      const PreviousValue = ({ value }) => {
                        if (value && !isNaN(value)) {
                          value = parseFloat(value)

                          const hasLsc = lsc && lsc !== '' && value > lsc
                          const hasLsm = lsm && lsm !== '' && value > lsm
                          const hasLim = lim && lim !== '' && value < lim
                          const hasLic = lic && lic !== '' && value < lic

                          return hasLsc || hasLic ? (
                            <Badge
                              className="mx-1 px-3 py-2"
                              style={{ minWidth: '50px' }}
                              variant="danger"
                            >
                              <strong>{value}</strong>
                            </Badge>
                          ) : hasLsm || hasLim ? (
                            <Badge
                              className="mx-1 px-3 py-2"
                              style={{ minWidth: '50px' }}
                              variant="warning"
                            >
                              <strong>{value}</strong>
                            </Badge>
                          ) : (
                            <small>{value}</small>
                          )
                        } else if (['NSD', 'NA'].includes(value)) {
                          return (
                            <Badge
                              className="mx-1 px-3 py-2"
                              style={{ minWidth: '50px' }}
                              variant="secondary"
                            >
                              {value}
                            </Badge>
                          )
                        }

                        return <small>{value}</small>
                      }

                      // Render
                      // -----------------------------------
                      return (
                        <tr
                          key={`essays-${index_classification}-${index_result}`}
                        >
                          {[
                            'antofagasta',
                            'collahuasi',
                            'quebradablanca',
                            'esmax',
                          ].includes(me.laboratory) ? (
                            <td
                              style={{
                                ...getStyle(),
                                borderRight: '1px dashed grey',
                              }}
                              className={baseClassName}
                            >
                              {[null, undefined, 0].includes(is_resampled) ? (
                                ''
                              ) : (
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      {is_resampled === 1
                                        ? 'Ensayo repetido - Valor modificado'
                                        : 'Ensayo repetido - Valor mantenido'}
                                    </Tooltip>
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={is_resampled === 1 ? faPen : faEquals}
                                  />
                                </OverlayTrigger>
                              )}
                            </td>
                          ) : (
                            ''
                          )}
                          <td
                            style={{
                              ...getStyle(),
                              borderRight: '1px dashed grey',
                            }}
                            className={baseClassName}
                          >
                            <small>{name}</small>
                          </td>
                          <td style={getStyle()} className={baseClassName}>
                            <small>{analysis_method}</small>
                          </td>
                          <td style={limitStyle} className={baseClassName}>
                            <LimitBadge variant="danger" value={lic} />
                          </td>
                          <td style={limitStyle} className={baseClassName}>
                            <LimitBadge variant="warning" value={lim} />
                          </td>
                          <td style={limitStyle} className={baseClassName}>
                            <LimitBadge variant="warning" value={lsm} />
                          </td>
                          <td style={limitStyle} className={baseClassName}>
                            <LimitBadge variant="danger" value={lsc} />
                          </td>
                          <td style={getStyle()} className={baseClassName}>
                            <PreviousValue value={value_prev} />
                          </td>
                          <td style={getStyle()} className={baseClassName}>
                            <PreviousValue value={value} />
                          </td>
                        </tr>
                      )
                    },
                  )}
                </React.Fragment>
              )
            })}

            {[tests.patchTest, tests.magneticPlug, tests.ferrography].map(
              (test, index) => {
                if (!(test && test.value)) return null

                const { name, value, comment } = test

                return (
                  <>
                    <tr className="text-left">
                      <td colSpan={9}>
                        <UIPanelInfoSeparation text={name} />
                      </td>
                    </tr>
                    <tr className="text-left">
                      <td
                        style={{
                          borderRight: '1px dashed grey',
                        }}
                        className="text-center align-middle m-0 p-0"
                      >
                        <small>{name}</small>
                      </td>
                      <td
                        colSpan={7}
                        className="text-center align-middle m-0 p-0"
                      >
                        <Accordion defaultActiveKey={index}>
                          <Card>
                            <Accordion.Toggle
                              as={Card.Header}
                              style={{ cursor: 'pointer' }}
                              eventKey="0"
                            >
                              <small>Ver imagen y comentario</small>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                              <Card.Body>
                                <img
                                  src={`https://s3-sa-east-1.amazonaws.com/${AWS.BUCKET}/${me.laboratory}/results/images/${value}`}
                                  style={{ width: '100%', height: 'auto' }}
                                  className="img-fluid"
                                  alt="Test de parche"
                                />
                                {comment ? (
                                  <Card className="m-3">
                                    <Card.Body>
                                      <small>{comment}</small>
                                    </Card.Body>
                                  </Card>
                                ) : null}
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </td>
                    </tr>
                  </>
                )
              },
            )}
          </tbody>
        </Table>
      </>
    )
  }

  return <UIError />
}

export default TribologySamplesReportForm
