import React from 'react'

// Components
import AdministrationListSamplingEssayClassifications from '../../../../Administration/List/Sampling/EssayClassifications'
import AdministrationInfoSamplingEssayClassifications from '../../../../Administration/Info/Sampling/EssayClassifications'
import AdministrationFormSamplingEssayClassifications from '../../../../Administration/Form/Sampling/EssayClassifications'

import { AdministrationContainer } from '../..'

const AdministrationContainerSamplingEssayClassifications = () => (
  <AdministrationContainer
    form={AdministrationFormSamplingEssayClassifications}
    info={AdministrationInfoSamplingEssayClassifications}
    list={AdministrationListSamplingEssayClassifications}
  />
)

export default AdministrationContainerSamplingEssayClassifications
