import React from 'react'

// Components
import AdministrationListComponentComponents from '../../../../Administration/List/Component/Components'
import AdministrationInfoComponentComponents from '../../../../Administration/Info/Component/Components'
import AdministrationFormComponentComponents from '../../../../Administration/Form/Component/Components'

import { AdministrationContainer } from '../..'

const AdministrationContainerComponentComponents = () => (
  <AdministrationContainer
    form={AdministrationFormComponentComponents}
    info={AdministrationInfoComponentComponents}
    list={AdministrationListComponentComponents}
  />
)

export default AdministrationContainerComponentComponents
