import { gql } from '../../../../../hooks'

export const READ_RE_SAMPLES = gql`
  query AdministrationReadSamplingReSampling($id: ID!) {
    aRead(path: "sampling/resampling", id: $id) {
    }
  }
`

export const UPDATE_RE_SAMPLE = gql`
  mutation AdministrationUpdateSamplingReSampling($id: ID!, $data: JSONObject) {
    aUpdate(path: "sampling/resampling", id: $id, data: $data) {
    }
  }
`

export const CREATE_RE_SAMPLINGS = gql`
  mutation AdministrationCreateSamplingReSampling($data: JSONObject) {
    aCreate(path: "sampling/resampling", data: $data) {
    }
  }
`

export const LIST_RE_SAMPLES = gql`
  query AdministrationListSamplingReSampling(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(
      path: "sampling/resampling"
      filter: $filter
      page: $page
      limit: $limit
    ) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`
