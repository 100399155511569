import React from 'react'
import { Card } from 'react-bootstrap'

// Components
import UIFormInputText from '../../../../UI/FormReload/Input/Text'
import UIFormInputSelect from '../../../../UI/FormReload/Input/Select'
import UIFormInputTextarea from '../../../../UI/FormReload/Input/Textarea'
import UIPanelInfoTable from '../../../../UI/Panel/Info/Table'

// Common
import { oe } from '../../../../../common/object/extractor'

import { AdministrationForm } from '../..'

// Queries
import {
  READ_RE_SAMPLES,
  UPDATE_RE_SAMPLE,
  CREATE_RE_SAMPLINGS,
  LIST_RE_SAMPLES,
} from './queries'

const queries = {
  read: READ_RE_SAMPLES,
  create: CREATE_RE_SAMPLINGS,
  update: UPDATE_RE_SAMPLE,
  list: LIST_RE_SAMPLES,
}

const CustomForm = ({ action, data, id }) => (
  <UIPanelInfoTable>
    {[
      action === 'update'
        ? ['ID', <UIFormInputText inline name="id" placeholder={id} disabled />]
        : [],
      [
        'Laboratorio',
        <UIFormInputSelect
          name="laboratory"
          initialValue="antofagasta"
          required
        >
          <option value="antofagasta">Antofagasta</option>
          <option value="collahuasi">Collahuasi</option>
        </UIFormInputSelect>,
      ],
      [
        'Números externos',
        <UIFormInputTextarea
          name="external_numbers"
          text="Números externos separados por commas, puntos, espacios, tabs o saltos de línea"
        />,
      ],
      [
        'ID muestras',
        <UIFormInputTextarea
          name="id_samples"
          text="ID de muestras separados por commas, puntos, espacios, tabs o saltos de línea"
        />,
      ],
    ]}
  </UIPanelInfoTable>
)

const AdministrationFormSamplingReSampling = () => (
  <>
    <Card bg={'info'} text={'white'} className="my-2">
      <Card.Body>
        <Card.Text className="text-center">
          <p>
            Las muestras ingresadas en este formulario serán puestas a
            disposición del cubo de información de Komatsu en la madrugada del
            día diguiente, entre las 1:00 am y las 4:00 am
          </p>
        </Card.Text>
      </Card.Body>
    </Card>
    <AdministrationForm name={'ensayo'} queries={queries}>
      <CustomForm />
    </AdministrationForm>
  </>
)

export default AdministrationFormSamplingReSampling
