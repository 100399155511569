import React from 'react'

import { AdministrationList } from '../../../List'

// Queries
import { DELETE_BRANDS, LIST_BRANDS } from './queries'

const queries = { list: LIST_BRANDS, delete: DELETE_BRANDS }

const AdministrationListMachineBrands = () => (
  <AdministrationList queries={queries} />
)

export default AdministrationListMachineBrands
