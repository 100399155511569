import React from 'react'

import { AdministrationForm } from '../..'

// Queries
import {
  READ_PROCEDURES,
  CREATE_PROCEDURES,
  UPDATE_PROCEDURES,
  LIST_PROCEDURES,
} from './queries'

const queries = {
  read: READ_PROCEDURES,
  create: CREATE_PROCEDURES,
  update: UPDATE_PROCEDURES,
  list: LIST_PROCEDURES,
}

const AdministrationFormSamplingProcedures = () => (
  <AdministrationForm name={'procedimiento de muestreo'} queries={queries} />
)

export default AdministrationFormSamplingProcedures
