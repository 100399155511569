import React from 'react'
import { Col } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan } from '@fortawesome/free-solid-svg-icons'

const PageNotFound = () => {
  return (
    <Col className="text-center align-self-center mt-5">
      <FontAwesomeIcon
        size="10x"
        icon={faBan}
        color="lightgray"
        className="mb-5"
      />
      <h1 className="text-secondary font-weight-light">Acceso denegado</h1>
      <hr className="w-50" />
      <p className="text-black-50 font-weight-lighter">
        Lo sentimos, no posée las credenciales necesarias para acceder este
        contenido
      </p>
    </Col>
  )
}

export default PageNotFound
