import { gql } from '../../../../../hooks'

export const READ_MODELS = gql`
  query AdministrationReadMachineModels($id: ID!) {
    aRead(path: "machine/models", id: $id) {
    }
  }
`

export const DELETE_MODELS = gql`
  mutation AdministrationDeleteMachineModels($id: ID!) {
    aDelete(path: "machine/models", id: $id) {
    }
  }
`

export const LIST_BRANDS = gql`
  query AdministrationMiscMachineBrands {
    aMachineBrands {
    }
  }
`
