import React from 'react'

// Hooks
import { useGlobal } from '../../../../../../hooks'

import { AdministrationList } from '../../../../List'

// Queries
import { DELETE_CLIENTS_CASES, LIST_CLIENTS_CASES } from './queries'

const table = [
  { th: 'ID', td: ['client', 'id'] },
  { th: 'Cliente', td: ['client', 'name'] },
  { th: 'Caso', td: ['case', 'name'] },
]

const queries = { list: LIST_CLIENTS_CASES, delete: DELETE_CLIENTS_CASES }

const AdministrationListClientClientsCases = () => {
  const [{ me }] = useGlobal()

  const no = ![1, 2].includes(me.id_role)

  return (
    <AdministrationList
      table={table}
      queries={queries}
      noCreate={no}
      noUpdate={no}
      noDelete={no}
      refKey={{ pk1: ['client', 'id'], pk2: ['case', 'id'] }}
    />
  )
}

export default AdministrationListClientClientsCases
