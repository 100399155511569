import React from 'react'
import moment from 'moment'
import { Col, Row, Form, Modal } from 'react-bootstrap'

// Hooks
import { useState, useQuery, useMutation } from '../../../../../hooks'

// Components
import UIPanelInfo from '../../../../UI/Panel/Info'
import UIPanelInfoBlock from '../../../../UI/Panel/Info/Block'
import UIPanelInfoItem from '../../../../UI/Panel/Info/Item'
import UITable from '../../../../UI/Table'
import UITableHead from '../../../../UI/Table/Head'
import UITableBody from '../../../../UI/Table/Body'
import UILoading from '../../../../UI/Loading'
import UIError from '../../../../UI/Error'
import UIForm from '../../../../UI/FormReload'
import UIButton from '../../../../UI/Button'

// Queries
import { GET_SAMPLE_LOG, NULL_SAMPLE, REJECT_SAMPLE } from './queries'

const TribologySamplesSearchInfo = ({ id }) => {
  // Modal delete confirmation
  const [showModal, setShowModalState] = useState(false)

  // --------------------------------------------------------------------------
  //
  // Query: Received samples info
  //
  // --------------------------------------------------------------------------

  const { data, loading } = useQuery(GET_SAMPLE_LOG, {
    fetchPolicy: 'network-only',
    variables: { id },
    skip: !id,
  })

  // --------------------------------------------------------------------------
  //
  // Mutation
  //
  // --------------------------------------------------------------------------

  const [t6RejectSample] = useMutation(REJECT_SAMPLE, {
    update: (cache, { data: { t6RejectSample } }) => {
      // Get anomalies
      const { t6SampleLogs } = cache.readQuery({
        query: GET_SAMPLE_LOG,
        variables: { id },
      })

      const { info, logs } = t6SampleLogs.data

      // Write the cache
      cache.writeQuery({
        query: GET_SAMPLE_LOG,
        variables: { id },
        data: {
          t6SampleLogs: {
            data: { info, logs: [...logs, t6RejectSample.data] },
          },
        },
      })
    },
    onSuccessMessage: 'La muestra se rechazó con éxito',
    onErrorMessage: 'La muestra no pudo ser rechazada',
  })

  const [t6NullSample] = useMutation(NULL_SAMPLE, {
    update: (cache, { data: { t6NullSample } }) => {
      // Get anomalies
      const { t6SampleLogs } = cache.readQuery({
        query: GET_SAMPLE_LOG,
        variables: { id },
      })

      const { info, logs } = t6SampleLogs.data

      // Write the cache
      cache.writeQuery({
        query: GET_SAMPLE_LOG,
        variables: { id },
        data: {
          t6SampleLogs: { data: { info, logs: [...logs, t6NullSample.data] } },
        },
      })
    },
    onSuccessMessage: 'La muestra se anuló con éxito',
    onErrorMessage: 'La muestra no pudo ser anulada',
  })

  // --------------------------------------------------------------------------
  //
  // Render
  //
  // --------------------------------------------------------------------------

  // Loading
  if (loading)
    return (
      <UIPanelInfo centered>
        <UIPanelInfoBlock>
          <UILoading />
        </UIPanelInfoBlock>
      </UIPanelInfo>
    )

  if (data && data.t6SampleLogs) {
    const { info, logs } = data.t6SampleLogs.data

    const handleSubmit = () => {}

    // Modal handler
    const handleCloseModal = () => setShowModalState(false)
    const handleAcceptModal = ({ id, type }) => {
      if (type === 'null') {
        t6NullSample({ variables: { id } })
      } else {
        t6RejectSample({ variables: { id } })
      }
      // deleteAnomaly({ variables: { id } })
      setShowModalState(false)
    }

    // Handlers
    const handleReject = (id) => {
      setShowModalState({ id, type: 'reject' })
      // onDataChange({ action: 'info' })
    }

    const handleNull = (id) => {
      setShowModalState({ id, type: 'null' })
      // onDataChange({ action: 'info' })
    }

    const isRejected = logs[logs.length - 1].id_stage === 5 // !!logs.filter(({ id_stage }) => id_stage === 5).length
    const isNulled = logs[logs.length - 1].id_stage === 6 // !!logs.filter(({ id_stage }) => id_stage === 6).length

    return (
      <UIForm onSubmit={handleSubmit}>
        <UIPanelInfo>
          <UIPanelInfoBlock title="Historial">
            <UIPanelInfoItem name="Correlativo">{id}</UIPanelInfoItem>
            <UIPanelInfoItem name="Descripción">
              {`${info.client.name} - ${info.site.name}`}
            </UIPanelInfoItem>
            <br />
            <UITable>
              <UITableHead>
                {['Estado', 'Fecha estado', 'Responsable']}
              </UITableHead>
              <UITableBody>
                {logs.map(({ stage, date, responsible, comment }, index) => (
                  <tr key={index}>
                    <td>{stage}</td>
                    <td>
                      {date && moment(date).format('DD/MM/YYYY HH:mm:ss')}
                    </td>
                    <td>{responsible}</td>
                  </tr>
                ))}
              </UITableBody>
            </UITable>
          </UIPanelInfoBlock>
          <UIPanelInfoBlock title="Acciones">
            <Form.Group as={Row} className="my-4">
              <Col className="d-flex">
                <UIButton
                  variant="danger"
                  onClick={() => handleReject(id)}
                  disabled={isNulled || isRejected}
                >
                  Rechazar muestra
                </UIButton>
                <UIButton
                  variant="danger"
                  className="ml-2"
                  onClick={() => handleNull(id)}
                  disabled={isNulled}
                >
                  Anular muestra
                </UIButton>
              </Col>
            </Form.Group>
          </UIPanelInfoBlock>
        </UIPanelInfo>
        <Modal show={!!showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmación</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              ¿Realmente desea{' '}
              {showModal.type === 'null' ? 'anular' : 'rechazar'} la muestra{' '}
              <strong>{showModal.id}</strong>?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <UIButton variant="secondary" onClick={handleCloseModal}>
              Cancelar
            </UIButton>
            <UIButton
              variant="danger"
              onClick={() => handleAcceptModal(showModal)}
            >
              {showModal.type === 'null' ? 'Anular' : 'Rechazar'}
            </UIButton>
          </Modal.Footer>
        </Modal>
      </UIForm>
    )
  }

  // Error
  return (
    <UIPanelInfo centered>
      <UIPanelInfoBlock>
        <UIError />
      </UIPanelInfoBlock>
    </UIPanelInfo>
  )
}

export default TribologySamplesSearchInfo
