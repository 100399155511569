import React from 'react'

// Components
import AdministrationListComponentChangeMotives from '../../../../Administration/List/Component/ChangeMotives'
import AdministrationInfoComponentChangeMotives from '../../../../Administration/Info/Component/ChangeMotives'
import AdministrationFormComponentChangeMotives from '../../../../Administration/Form/Component/ChangeMotives'

import { AdministrationContainer } from '../..'

const AdministrationContainerComponentChangeMotives = () => (
  <AdministrationContainer
    form={AdministrationFormComponentChangeMotives}
    info={AdministrationInfoComponentChangeMotives}
    list={AdministrationListComponentChangeMotives}
  />
)

export default AdministrationContainerComponentChangeMotives
