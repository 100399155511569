// import React from 'react'
// import { Line } from 'react-chartjs-2'
import Chartjs from 'chart.js'

// Quinto gráfico
//
// Tipo 1: Silicio
// Tipo 2: %anticongelantes

const Graph = ({ ref, graphs, config, type }) => {
  const [graph, label] = (() => {
    if (!graphs) return [null, null]

    switch (type) {
      case 1:
      case '1':
        return [graphs[15], 'Silicio']
      case 2:
      case '2':
        return [graphs[42], 'Anticongelantes']
      // case 3:
      // case '3':
      // case 4:
      // case '4':
      default:
        return [null, null]
    }
  })()

  // --------------------------------------------------------------------------
  //
  // No data
  //
  // --------------------------------------------------------------------------

  if (!graph) {
    new Chartjs(ref.current, {
      type: 'line',
      data: {
        datasets: [
          {
            data: null,
          },
        ],
      },
      options: { ...config.options, legend: { display: false } },
    })

    return
  }

  const { x: minX, y: minY } = graph[0]
  const { x: maxX, y: maxY } = graph[graph.length - 1]

  // --------------------------------------------------------------------------
  //
  // Only 1 graph
  //
  // --------------------------------------------------------------------------

  const { lic, lim, lsm, lsc } = graphs

  const labels =
    graph.length === 1 ? ['', graph[0].h, ''] : graph.map(({ h }) => h)

  new Chartjs(ref.current, {
    type: 'line',
    data: {
      labels,
      datasets: [
        {
          label,
          data: graph.map(({ h, y, label }) => ({
            h,
            y,
            label,
          })),
          ...config.main0,
          showLabelsOverPoints: true,
        },
        {
          label: 'Máximo',
          data: graph.map(({ h }) => ({ h, y: lsc })),
          ...config.lsc,
        },
        {
          label: 'Mínimo',
          data: graph.map(({ h }) => ({ h, y: lsm })),
          ...config.lsm,
        },
      ],
    },
    options:
      graph.length === 1
        ? {
            ...config.options,
            scales: {
              xAxes: [{ ticks: { fontSize: 50 }, padding: 40 }],
              yAxes: [
                {
                  scaleLabel: {
                    display: true,
                  },
                  beginAtZero: false,
                  ticks: {
                    min: Math.min(minY, lic, lim, lsm, lsc) * 0.9,
                    max: Math.max(maxY, lic, lim, lsm, lsc) * 1.1,
                    fontSize: 50,
                    padding: 40,
                  },
                },
              ],
            },
          }
        : config.options,
    plugins: [
      {
        afterDatasetsDraw: function (chart) {
          var ctx = chart.ctx
          chart.data.datasets.forEach(function (dataset, index) {
            var datasetMeta = chart.getDatasetMeta(index)
            if (datasetMeta.hidden) return
            if (!dataset.showLabelsOverPoints) return

            datasetMeta.data.forEach(function (point, index) {
              const label = dataset.data[index].label

              if (!label) return

              var x = point.getCenterPoint().x,
                y = point.getCenterPoint().y,
                radius = point._model.radius,
                fontSize = 25,
                fontFamily = 'Sans',
                fontColor = '#228B22',
                fontStyle = 'bold'

              ctx.save()
              ctx.textBaseline = 'middle'
              ctx.textAlign = 'center'
              ctx.font = fontStyle + ' ' + fontSize + 'px' + ' ' + fontFamily
              ctx.fillStyle = fontColor
              ctx.fillText(label, x, y - radius - fontSize)
              ctx.restore()
            })
          })
        },
      },
    ],
  })
}

// Graphs
//
// Tipo 3: Fatica(ID56)/Corte(ID57)/No metalica(ID58)/ Deslizante(ID59)
// Tipo 4: Fatica(ID56)/Corte(ID57)/No metalica(ID58)/ Deslizante(ID59)

const Graphs = ({ ref, graphs, config, type }) => {
  const graph0 = graphs && graphs[56]
  const graph1 = graphs && graphs[57]
  const graph2 = graphs && graphs[58]
  const graph3 = graphs && graphs[59]

  // --------------------------------------------------------------------------
  //
  // No data
  //
  // --------------------------------------------------------------------------

  if (!graph0 && !graph1 && !graph2 && !graph3) {
    new Chartjs(ref.current, {
      type: 'line',
      labels: [1],
      data: {
        datasets: [
          {
            data: null,
          },
        ],
      },
      options: { ...config.options, legend: { display: false } },
    })

    return
  }

  const { x: x00, y: y00 } = graph0[0]
  const { x: x01, y: y01 } = graph0[graph0.length - 1]
  const { x: x10, y: y10 } = graph1[0]
  const { x: x11, y: y11 } = graph1[graph1.length - 1]
  const { x: x20, y: y20 } = graph2[0]
  const { x: x21, y: y21 } = graph2[graph2.length - 1]
  const { x: x30, y: y30 } = graph3[0]
  const { x: x31, y: y31 } = graph3[graph3.length - 1]

  const minX = Math.min(x00, x10, x20, x30)
  const maxX = Math.max(x01, x11, x21, x31)
  const minY = Math.min(y00, y10, y20, y30)
  const maxY = Math.max(y01, y11, y21, y31)

  // --------------------------------------------------------------------------
  //
  // The 4 graphs
  //
  // --------------------------------------------------------------------------

  // Get the larget one
  const graph = [
    { x: x01, graph: graph0 },
    { x: x11, graph: graph1 },
    { x: x21, graph: graph2 },
    { x: x31, graph: graph3 },
  ].sort((a, b) => b.x - a.x)[0].graph

  const labels = graph.length === 1 ? ['', 1, ''] : graph.map(({ x }) => x)

  new Chartjs(ref.current, {
    type: 'line',
    data: {
      labels,
      datasets: [
        {
          label: 'Fatiga',
          data: graph0.map(({ x, y, label }) => ({
            x,
            y,
            label,
          })),
          ...config.main0,
          showLabelsOverPoints: true,
        },
        {
          label: 'Corte',
          data: graph1.map(({ x, y, label }) => ({
            x,
            y,
            label,
          })),
          ...config.main1,
          showLabelsOverPoints: true,
        },
        {
          label: 'No metálica',
          data: graph2.map(({ x, y, label }) => ({
            x,
            y,
            label,
          })),
          ...config.main2,
          showLabelsOverPoints: true,
        },
        {
          label: 'Deslizante',
          data: graph3.map(({ x, y, label }) => ({
            x,
            y,
            label,
          })),
          ...config.main3,
          showLabelsOverPoints: true,
        },
      ],
    },
    options:
      graph.length === 1
        ? {
            ...config.options,
            scales: {
              xAxes: [{ ticks: { fontSize: 50 }, padding: 40 }],
              yAxes: [
                {
                  scaleLabel: {
                    display: true,
                  },
                  beginAtZero: false,
                  ticks: {
                    min: minY * 0.9,
                    max: maxY * 1.1,
                    fontSize: 50,
                    padding: 40,
                  },
                },
              ],
            },
          }
        : config.options,
    plugins: [
      {
        afterDatasetsDraw: function (chart) {
          var ctx = chart.ctx
          chart.data.datasets.forEach(function (dataset, index) {
            var datasetMeta = chart.getDatasetMeta(index)
            if (datasetMeta.hidden) return
            if (!dataset.showLabelsOverPoints) return

            datasetMeta.data.forEach(function (point, index) {
              const label = dataset.data[index].label

              if (!label) return

              var x = point.getCenterPoint().x,
                y = point.getCenterPoint().y,
                radius = point._model.radius,
                fontSize = 25,
                fontFamily = 'Sans',
                fontColor = '#228B22',
                fontStyle = 'bold'

              ctx.save()
              ctx.textBaseline = 'middle'
              ctx.textAlign = 'center'
              ctx.font = fontStyle + ' ' + fontSize + 'px' + ' ' + fontFamily
              ctx.fillStyle = fontColor
              ctx.fillText(label, x, y - radius - fontSize)
              ctx.restore()
            })
          })
        },
      },
    ],
  })
}

const Graph5 = ({ ref, graphs, config, type }) => {
  //
  // Tipo 1: Silicio
  // Tipo 2: %anticongelantes
  // Tipo 3: Fatica(ID56)/Corte(ID57)/No metalica(ID58)/ Deslizante(ID59)
  // Tipo 4: Fatica(ID56)/Corte(ID57)/No metalica(ID58)/ Deslizante(ID59)

  switch (type) {
    case 1:
    case '1':
    case 2:
    case '2':
      Graph({ ref, graphs, config, type })
      break
    case 3:
    case '3':
    case 4:
    case '4':
      Graphs({ ref, graphs, config, type })
      break
  }
}

export default Graph5
