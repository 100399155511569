import React from 'react'
import { useParams, useLocation } from 'react-router-dom'

// Hooks
import { useMemo, useState, useEffect } from '../../../../hooks'

// Components
import TribologySamplesShiftChangeView from './View'
import TribologySamplesShiftChangeForm from './Form'
import TribologySamplesShiftChangeFilter from './Filter'

const TribologySamplesShiftChange = () => {
  const { search } = useLocation()

  const params = useParams()

  // const [queries, setQueriesState] = useState(new URLSearchParams(search))
  const queries = useMemo(() => new URLSearchParams(search), [search])

  // useEffect(() => {
  //   setQueriesState(new URLSearchParams(search))
  // }, [search])

  switch (params.action) {
    case 'view':
      return <TribologySamplesShiftChangeView queries={queries} />
    case 'form':
      return <TribologySamplesShiftChangeForm queries={queries} />
    case 'filter':
    default:
      return <TribologySamplesShiftChangeFilter />
  }
}

export default TribologySamplesShiftChange
