import React from 'react'

// Hooks
import { useParams } from '../../../hooks'

// Components
// import ReportContainerManagingExcel from '../../../components/Report/Container/Managing/Excel'
// import ReportContainerCriticExcel from '../../../components/Report/Container/Critic/Excel'
// import ReportContainerDataExcel from '../../../components/Report/Container/Data/Excel'
import ReportContainerFailsExcel from '../../../components/Report/Container/Fails/Excel'
import ReportContainerFleetStateExcel from '../../../components/Report/Container/FleetState/Excel'
// import ReportContainerInvoicingExcel from '../../../components/Report/Container/Invoicing/Excel'
// import ReportContainerLaboratoryKPIExcel from '../../../components/Report/Container/LaboratoryKPI/Excel'
import PageNotFound from '../../NotFound'

const PageReports = ({ auth }) => {
  const { name } = useParams()

  switch (name) {
    // case 'managing':
    //   return <ReportContainerManagingExcel />
    //   break
    // case 'critic':
    //   return <ReportContainerCriticExcel />
    //   break
    // case 'data':
    //   return <ReportContainerDataExcel />
    //   break
    case 'fails':
      return <ReportContainerFailsExcel />
      break
    case 'fleet_state':
      return <ReportContainerFleetStateExcel />
      break
    // case 'invoicing':
    //   return <ReportContainerInvoicingExcel />
    //   break
    // case 'laboratory_kpi':
    //   return <ReportContainerLaboratoryKPIExcel />
    //   break
    default:
      return <PageNotFound />
  }
}

export default PageReports
