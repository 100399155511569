import React from 'react'

// Components
import UIPanelInfoBlock from '../../../../UI/Panel/Info/Block'
import UIPanelInfoTable from '../../../../UI/Panel/Info/Table'
import UIFormInputText from '../../../../UI/FormReload/Input/Text'
import UIFormInputInteger from '../../../../UI/FormReload/Input/Integer'
import UIFormInputSelect from '../../../../UI/FormReload/Input/Select'

// Common
import { oe } from '../../../../../common/object/extractor'

import AdministrationInfo, { RefLink } from '../..'

// Queries
import { READ_MODELS, DELETE_MODELS, LIST_BRANDS } from './queries'

const Info = (data) => (
  <UIPanelInfoBlock title="Modelo">
    <UIPanelInfoTable>
      {[
        ['ID', oe(data, ['id'])],
        ['Descripción', oe(data, ['name'])],
        [
          'Marca',
          <RefLink
            id={oe(data, ['brand', 'id'])}
            tab={'lubricant'}
            subtab={'brands'}
          >
            {oe(data, ['brand', 'name'])}
          </RefLink>,
        ],
      ]}
    </UIPanelInfoTable>
  </UIPanelInfoBlock>
)

const Form = () => (
  <>
    <UIFormInputInteger label="ID" name="id" />
    <UIFormInputText label="Descripción" name="name" />
    <UIFormInputSelect label="Marca" name="brand_id" query={LIST_BRANDS} />
    <UIFormInputText label="Descripción marca" name="brand_name" />
  </>
)

const AdministrationInfoLubricantModels = ({ ...rest }) => (
  <AdministrationInfo
    queries={{ read: READ_MODELS, delete: DELETE_MODELS }}
    views={{ form: Form, info: Info }}
    {...rest}
  />
)

export default AdministrationInfoLubricantModels
