import React from 'react'

// Components
import UIDashboardQuickSight from '../UI/Dashboard/QuickSight'

const Welcome = () => {
  return <UIDashboardQuickSight id="050aed09-7382-4862-a281-44009f582877" />
}

export default Welcome
