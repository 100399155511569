import React from 'react'

// Lubricants
import AdministrationListLubricantBrands from '../../../../Administration/List/Lubricant/Brands'
import AdministrationInfoLubricantBrands from '../../../../Administration/Info/Lubricant/Brands'
import AdministrationFormLubricantBrands from '../../../../Administration/Form/Lubricant/Brands'

import { AdministrationContainer } from '../..'

const AdministrationContainerLubricantBrands = () => (
  <AdministrationContainer
    form={AdministrationFormLubricantBrands}
    info={AdministrationInfoLubricantBrands}
    list={AdministrationListLubricantBrands}
  />
)

export default AdministrationContainerLubricantBrands
