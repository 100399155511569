// import { useHistory } from 'react-router-dom'
import { useLazyQuery as useApolloLazyQuery } from '@apollo/react-hooks'

// Hooks
import { useGlobal } from '../../hooks'

// Doc about apollo errors
//
// https://www.apollographql.com/docs/react/api/link/apollo-link-error/

export const useLazyQuery = (query, options = {}) => {
  const [, dispatch] = useGlobal()
  //  const history = useHistory()

  const results = useApolloLazyQuery(query, {
    ...options,
    errorPolicy: 'all',
    onError: (error) => {
      const { message, graphQLErrors, networkError } = error

      // if (graphQLErrors)
      //   graphQLErrors.forEach(({ extensions }) => {
      //     if (extensions.code === 'UNAUTHENTICATED') history.push('/login')
      //   })

      if (graphQLErrors)
        graphQLErrors.forEach(({ extensions }) => {
          if (extensions.code === 'UNAUTHENTICATED') {
            dispatch({ type: 'updateMe', me: null })
          }
        })

      // if (networkError) {
      //   history.push('/login')
      // }

      // Forward error
      options.onError && options.onError(error)
    },
  })

  return results
}

export default useLazyQuery
