import React from 'react'

import { AdministrationForm } from '../..'

// Queries
import {
  READ_POINTS,
  CREATE_POINTS,
  UPDATE_POINTS,
  LIST_POINTS,
} from './queries'

const queries = {
  read: READ_POINTS,
  create: CREATE_POINTS,
  update: UPDATE_POINTS,
  list: LIST_POINTS,
}

const AdministrationFormSamplingPoints = () => (
  <AdministrationForm name={'punto de muestreo'} queries={queries} />
)

export default AdministrationFormSamplingPoints
