import { gql } from '../../../../hooks'

export const LIST_GENERATED_INSPECTIONS = gql`
  query ListGeneratedInspections($page: Int, $limit: Int, $id_system: ID!) {
    i3InspectionsByStage(page: $page, limit: $limit, id_system: $id_system) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`

export const LIST_SYSTEMS = gql`
  query ListSystems {
    i3InspectionsSystems {
    }
  }
`
