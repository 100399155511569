import { gql } from '../../../../../hooks'

export const READ_TYPES = gql`
  query AdministrationReadComponentTypes($id: ID!) {
    aRead(path: "component/types", id: $id) {
    }
  }
`

export const UPDATE_TYPES = gql`
  mutation AdministrationUpdateComponentTypes($id: ID!, $data: JSONObject) {
    aUpdate(path: "component/types", id: $id, data: $data) {
    }
  }
`

export const CREATE_TYPES = gql`
  mutation AdministrationCreateComponentTypes($data: JSONObject) {
    aCreate(path: "component/types", data: $data) {
    }
  }
`

export const LIST_TYPES = gql`
  query AdministrationListComponentTypes(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(
      path: "component/types"
      filter: $filter
      page: $page
      limit: $limit
    ) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`
