import React from 'react'

// Hooks
import { useParams } from '../../hooks'

// Components
import DashboardCummins from '../../components/Dashboard/Cummins'
import DashboardDemo from '../../components/Dashboard/Demo'
import DashboardLaboratory from '../../components/Dashboard/Laboratory'
import DashboardPelambres from '../../components/Dashboard/Pelambres'
import PageNotFound from '../NotFound'

const PageDashboards = ({ auth }) => {
  const { name } = useParams()

  switch (name) {
    case 'cummins':
      return <DashboardCummins />
    case 'demo':
      return <DashboardDemo />
    case 'laboratory':
      return <DashboardLaboratory />
    case 'pelambres':
      return <DashboardPelambres />
    default:
      return <PageNotFound />
  }
}

export default PageDashboards
