import { useQuery as useApolloQuery } from '@apollo/react-hooks'

import { gql as graphqlTag } from 'graphql-tag'

// Hooks
import { useGlobal, useHistory, useAlert } from '../../hooks'

// Doc about apollo errors
//
// https://www.apollographql.com/docs/react/api/link/apollo-link-error/

export const useQuery = (query = graphqlTag`query { dummy }`, options = {}) => {
  const alert = useAlert()

  const [, dispatch] = useGlobal()

  const history = useHistory()

  const results = useApolloQuery(query, {
    ...options,
    onCompleted: (data) => {
      if (data) {
        const errorMessage =
          options.onErrorMessage && typeof options.onErrorMessage === 'string'
            ? options.onErrorMessage
            : null

        const errors = []
        const keys = Object.keys(data)

        // Show alerts for every error in the response
        keys.forEach((key) => {
          const d = key && data[key]

          if (d) {
            // Show infos
            if (d.info && d.info.length) {
              d.info.forEach((message) => alert(message, { style: 'info' }))
            }
            // Show warnings
            if (d.warning && d.warning.length) {
              d.info.forEach((message) => alert(message, { style: 'warning' }))
            }
            // Show errors
            if (d.error) {
              errors.push(data[key].error)
              alert(errorMessage ? errorMessage : d.error.message, {
                style: 'danger',
              })
            }
          }
        })

        // Trigger onError
        if (errors.length) {
          if (
            options.onError &&
            {}.toString.call(options.onError) === '[object Function]'
          )
            options.onError(errors)
        }
        // Trigger onSuccess
        else {
          const successMessage =
            options.onSuccessMessage &&
            (typeof options.onSuccessMessage === 'string' ||
              React.isValidElement(options.onSuccessMessage))
              ? options.onSuccessMessage
              : null

          if (successMessage) {
            alert(successMessage, { style: 'success' })
          }

          if (
            options.onSuccess &&
            {}.toString.call(options.onSuccess) === '[object Function]'
          )
            options.onSuccess(keys.length > 1 ? data : data[keys[0]])
        }
      }

      // Trigger onCompleted
      if (
        options.onCompleted &&
        {}.toString.call(options.onCompleted) === '[object Function]'
      )
        options.onCompleted(data)
    },
    errorPolicy: 'all',
    onError: (error) => {
      const { message, graphQLErrors, networkError } = error

      if (graphQLErrors && graphQLErrors.length > 0) {
        for (const { error } of graphQLErrors) {
          if (error.code === 'UNAUTHENTICATED') {
            dispatch({ type: 'updateMe', me: null })
            break
          }
          if (error.code === 'FORBIDDEN') {
            history.push('/forbidden')
            break
          }
        }
      }

      // alert(options.error ? options.error : message)

      // Trigger onError
      if (
        options.onError &&
        {}.toString.call(options.onError) === '[object Function]'
      )
        options.onError(error)
    },
  })

  return results
}

export const gql = (strings) => {
  const tag = [
    ...strings[0].split('\n').slice(0, -3),
    '      data',
    '      info',
    '      warning',
    '      error {',
    '        id',
    '        code',
    '        message',
    '      }',
    '    }',
    '  }',
  ].join('\n')
  return graphqlTag(tag)
}

export default useQuery
