import React from 'react'

import { AdministrationList } from '../../../List'

// Queries
import { DELETE_LUBRICANTS, LIST_LUBRICANTS } from './queries'

const queries = { list: LIST_LUBRICANTS, delete: DELETE_LUBRICANTS }

const AdministrationListLubricantLubricants = () => (
  <AdministrationList queries={queries} />
)

export default AdministrationListLubricantLubricants
