import { gql } from '../../../../../hooks'

export const GET_RESULTS_BY_SAMPLE = gql`
  query ResultsBySample($id: ID!) {
    t4ResultsBySample(id: $id) {
    }
  }
`

// export const PROPOSE_SAMPLE = gql`
//   mutation ProposeSample($id: ID!, $values: JSONObject!) {
//     t4ProposeSample(id: $id, values: $values) {
//     }
//   }
// `
