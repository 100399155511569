import React from 'react'

// Components
import UIPanelInfoBlock from '../../../../UI/Panel/Info/Block'
import UIPanelInfoTable from '../../../../UI/Panel/Info/Table'
import UIFormInputInteger from '../../../../UI/FormReload/Input/Integer'
import UIFormSeparation from '../../../../UI/FormReload/Separation'

// Common
import { oe } from '../../../../../common/object/extractor'

import AdministrationInfo, { RefLink } from '../..'

// Queries
import { READ_SAMPLES, DELETE_SAMPLES } from './queries'

const Info = (data) => {
  return (
    <>
      <UIPanelInfoBlock title="Muestra">
        <UIPanelInfoTable>
          {[
            ['Correlativo', oe(data, ['id'])],
            ['Estado', oe(data, ['stage', 'name'])],
            [
              'Prioridad',
              <span
                className={
                  oe(data, ['priority', 'id']) === 2
                    ? 'bg-danger text-white'
                    : ''
                }
              >
                {oe(data, ['priority', 'name'])}
              </span>,
            ],
            ['HK Equipo', oe(data, ['machine_continuity'])],
            ['HK Componente', oe(data, ['component_continuity'])],
            ['HK Lubricante', oe(data, ['lubricant_continuity'])],
            ['Número externo', oe(data, ['external_number'])],
            ['Orden de trabajo', oe(data, ['work_order'])],
            ['Cantidad de rellenos', oe(data, ['number_of_refills'])],
            ['Comentario', oe(data, ['comment'])],
            ['Fecha muestreo', oe(data, ['date_sampling'])],
            ['Fecha retiro', oe(data, ['date_withdraw'])],
            ['Fecha ingreso', oe(data, ['date_entry'])],
            ['Fecha recepción', oe(data, ['date_receive'])],
            ['Fecha ensayo', oe(data, ['date_essay'])],
            ['Fecha propuesto', oe(data, ['date_proposed'])],
            ['Rut ingreso', oe(data, ['run_entry'])],
            ['Rut recepción', oe(data, ['run_receive'])],
            ['Rut ensayo', oe(data, ['run_essay'])],
            [
              'Componente',
              <RefLink
                id={oe(data, ['component', 'id'])}
                tab={'component'}
                subtab={'components'}
              >
                {oe(data, ['component', 'name'])}
              </RefLink>,
            ],
            [
              'Tag',
              <RefLink
                id={oe(data, ['component', 'id'])}
                tab={'component'}
                subtab={'components'}
              >
                {oe(data, ['component', 'tag'])}
              </RefLink>,
            ],
            [
              'Protocolo (Metal)',
              <RefLink
                id={oe(data, ['component', 'protocol', 'id'])}
                tab={'component'}
                subtab={'protocols'}
              >
                {oe(data, ['component', 'protocol', 'name'])}
              </RefLink>,
            ],
            [
              'Motivo cambio',
              oe(data, ['component', 'change_motive', 'id'], null) ? (
                <RefLink
                  id={oe(data, ['component', 'change_motive', 'id'])}
                  tab={'component'}
                  subtab={'changeMotives'}
                >
                  {oe(data, ['component', 'change_motive', 'name'])}
                </RefLink>
              ) : (
                '--'
              ),
            ],
            // ["cambi",
            //             {oe(data, ['component.change && oe(data, ['component.change.name}
            //           ],*/}
            //         <UIFormSeparation />
            [
              'Lubricante',
              <RefLink
                id={oe(data, ['lubricant', 'id'])}
                tab={'lubricant'}
                subtab={'lubricants'}
              >
                {oe(data, ['lubricant', 'name'])}
              </RefLink>,
            ],
            [
              'Protocolo (AFQ)',
              <RefLink
                id={oe(data, ['lubricant', 'protocol', 'id'])}
                tab={'lubricant'}
                subtab={'protocols'}
              >
                {oe(data, ['lubricant', 'protocol', 'name'])}
              </RefLink>,
            ],
            [
              'Cambio o relleno',
              oe(data, ['lubricant', 'change_refill', 'name']),
            ],
            ...(oe(data, ['lubricant', 'change_refill', 'id ']) !== 1 &&
            oe(data, ['lubricant', 'change_refill_motive', 'id'], null)
              ? [
                  [
                    oe(data, ['lubricant', 'change_refill', 'id ']) === 2
                      ? 'Motivo relleno'
                      : 'Motivo cambio',
                    <RefLink
                      id={oe(data, ['lubricant', 'change_refill_motive', 'id'])}
                      tab={'lubricant'}
                      subtab={'changeRefillMotives'}
                    >
                      {oe(data, ['lubricant', 'change_refill_motive', 'name'])}
                    </RefLink>,
                  ],
                  [
                    oe(data, ['lubricant', 'change_refill', 'id']) === 2
                      ? 'Litros relleno'
                      : 'Litros cambio',
                    oe(data, ['lubricant', 'change_refill_liters']) || '--',
                  ],
                  [
                    oe(data, ['lubricant', 'change_refill', 'id']) === 2
                      ? 'Horas relleno'
                      : 'Horas cambio',
                    oe(data, ['lubricant', 'change_refill_hours']) || '--',
                  ],
                ]
              : []),
          ]}
        </UIPanelInfoTable>
      </UIPanelInfoBlock>
      {oe(data, ['stage', 'id']) >= 4 && (
        <UIPanelInfoBlock title="Seguimiento">
          <UIPanelInfoTable>
            {[
              [
                'Condición',
                <span
                  className={[
                    oe(data, ['condition'], false)
                      ? oe(data, ['condition', 'id']) === 1
                        ? 'bg-success text-white'
                        : oe(data, ['condition', 'id']) === 2
                        ? 'bg-warning'
                        : 'bg-danger text-white'
                      : '',
                    'p-1',
                  ].join(' ')}
                >
                  {oe(data, ['condition', 'name'], '--')}
                </span>,
              ],
              [
                'Tipo de anomalía',
                <RefLink
                  id={oe(data, ['anomaly_type', 'id'])}
                  tab={'analysis'}
                  subtab={'anomalies'}
                >
                  {oe(data, ['anomaly_type', 'name'])}
                </RefLink>,
              ],
              [
                'Estado seguimiento',
                oe(data, ['tracking_state', 'name'], '--'),
              ],
              oe(data, ['recommendation'])
                ? ['Recomendación', oe(data, ['recommendation'])]
                : null,
              oe(data, ['recommendation'])
                ? null
                : ['Comentario', oe(data, ['state'])],
              oe(data, ['recommendation'])
                ? null
                : ['Acción', oe(data, ['suggestion'])],
            ].filter((v) => !!v)}
          </UIPanelInfoTable>
        </UIPanelInfoBlock>
      )}
    </>
  )
}

const Form = () => <UIFormInputInteger label="Correlativo" name="id" />

const AdministrationInfoSamplingSamples = ({ ...rest }) => (
  <AdministrationInfo
    noDuplicate
    noDelete
    queries={{ read: READ_SAMPLES, delete: DELETE_SAMPLES }}
    views={{ form: Form, info: Info }}
    {...rest}
  />
)

export default AdministrationInfoSamplingSamples
