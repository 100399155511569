import React from 'react'

// Global reducer
import { useState } from '../../../../../../../hooks'

import Filter from './Filter'
import Table from './Table'

const SampleContainerInsertMassiveTabWeb = () => {
  const [data, setDataState] = useState()

  const handleDataChange = (data) => {
    setDataState(data)
  }

  const handleBack = () => {
    setDataState()
  }

  if (data) return <Table data={data} onBack={handleBack} />

  return <Filter onDataChange={handleDataChange} />
}

export default SampleContainerInsertMassiveTabWeb
