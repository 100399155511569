import { gql } from '../../../../../hooks'

export const READ_TYPES = gql`
  query AdministrationReadMachineTypes($id: ID!) {
    aRead(path: "machine/types", id: $id) {
    }
  }
`

export const UPDATE_TYPES = gql`
  mutation AdministrationUpdateMachineTypes($id: ID!, $data: JSONObject) {
    aUpdate(path: "machine/types", id: $id, data: $data) {
    }
  }
`

export const CREATE_TYPES = gql`
  mutation AdministrationCreateMachineTypes($data: JSONObject) {
    aCreate(path: "machine/types", data: $data) {
    }
  }
`

export const LIST_TYPES = gql`
  query AdministrationListMachineTypes(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(path: "machine/types", filter: $filter, page: $page, limit: $limit) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`
