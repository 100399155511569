import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan } from '@fortawesome/free-solid-svg-icons'
import {
  Row,
  Col,
  Form,
  Accordion,
  Card,
  Table,
  Tabs,
  Tab,
} from 'react-bootstrap'
// import moment from 'moment'
import moment from 'moment-timezone'

// Hooks
import { useQuery } from '../../../../hooks'

// Components
import UIButton from '../../../UI/Button'
import UILoading from '../../../UI/Loading'
import UIError from '../../../UI/Error'
import UIFormSeparation from '../../../UI/FormReload/Separation'

import InfoHeader from '../../Container/InfoHeader'

// Excel export
import ButtonDownload from './download'

// Queries
import { GET_REPORT } from './queries'

const ReportTableLaboratoryKPI = ({ filter, onFilterChange }) => {
  const { id_client, id_site, id_machine_type, from, to } = filter

  // --------------------------------------------------------------------------
  //
  // Query report
  //
  // --------------------------------------------------------------------------

  const { data, loading } = useQuery(GET_REPORT, {
    variables: { ...filter },
    onErrorMessage: `Error al intentar obtener reporte`,
  })

  // --------------------------------------------------------------------------
  //
  // Render
  //
  // --------------------------------------------------------------------------

  if (loading) return <UILoading />

  if (data && data.reportKPILaboratory) {
    const { error } = data.reportKPILaboratory

    // ------------------------------------------------------------------------
    //
    // Info Body
    //
    // ------------------------------------------------------------------------

    let InfoBody
    let showExcelButton

    // Errors?
    // ------------------------------------------------------------------------
    if (error) {
      showExcelButton = false
      InfoBody = (
        <div className="text-center  py-5 border rounded">
          <FontAwesomeIcon
            size="10x"
            color="lightgray"
            className="mr-2"
            icon={faBan}
          />

          <h5 className="text-muted font-weight-light mt-5">{error.message}</h5>
        </div>
      )
    }
    // No errors
    // ------------------------------------------------------------------------
    else {
      showExcelButton = true
      InfoBody = <></>
    }

    const handleBack = () => onFilterChange()

    return (
      <>
        <Form.Group as={Row} className="m-1">
          <Col className="d-flex">
            <UIButton type="submit" className="mx-2" onClick={handleBack}>
              Atrás
            </UIButton>
            {showExcelButton && <ButtonDownload filter={filter} />}
          </Col>
        </Form.Group>
        <Row className="m-1">
          <Col xs={12}>
            <UIFormSeparation />
          </Col>
          {!error && (
            <Col xs={12}>
              <InfoHeader
                info={{
                  ...data.reportKPILaboratory.data.info,
                  from,
                  to,
                }}
              />
            </Col>
          )}
          <Col xs={12}>{InfoBody}</Col>
        </Row>
      </>
    )
  }

  return <UIError />
}

export default ReportTableLaboratoryKPI
