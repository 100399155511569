import React from 'react'

// Components
import AdministrationListClientSitesCases from '../../../../../Administration/List/Client/Sites/Cases'
import AdministrationInfoClientSitesCases from '../../../../../Administration/Info/Client/Sites/Cases'
import AdministrationFormClientSitesCases from '../../../../../Administration/Form/Client/Sites/Cases'

import { AdministrationContainer } from '../../..'

const AdministrationContainerClientSitesCases = () => (
  <AdministrationContainer
    form={AdministrationFormClientSitesCases}
    info={AdministrationInfoClientSitesCases}
    list={AdministrationListClientSitesCases}
  />
)

export default AdministrationContainerClientSitesCases
