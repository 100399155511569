import React from 'react'
import { Modal } from 'react-bootstrap'

// Hooks
import { useEffect, useState, useRef } from '../../../hooks'

import UIButton from '../../UI/Button'

const UIModal = (props) => {
  const {
    defaultShow: propDefaultShow = false,
    onShow: propOnShow,
    onClose: propOnClose,
    onCancel: propOnCancel,
    onAccept: propOnAccept,
    cancelText: propCancelText = 'Cancelar',
    acceptText: propAcceptText = 'Aceptar',
    cancelVariant: propCancelVariant = 'secondary',
    acceptVariant: propAcceptVariant = 'primary',
    closeButton: propCloseButton = true,
    title: propTitle,
    body: propBody,
    trigger: propTrigger,
  } = props

  const isFirst = useRef(true)

  const [show, setShowState] = useState(propDefaultShow)

  useEffect(() => {
    if (isFirst.current) isFirst.current = false
    else setShowState((prev) => !prev)
  }, [propTrigger])

  const handleClose = () => {
    setShowState(false)
    propOnClose && propOnClose()
  }
  const handleShow = () => {
    setShowState(true)
    propOnShow && propOnShow()
  }

  const handleCancel = () => {
    setShowState(false)
    propOnCancel && propOnCancel()
  }

  const handleAccept = () => {
    setShowState(false)
    propOnAccept && propOnAccept()
  }

  return (
    <Modal show={show} onHide={handleClose} centered>
      {propTitle && (
        <Modal.Header closeButton={propCloseButton}>
          <Modal.Title>{propTitle}</Modal.Title>
        </Modal.Header>
      )}
      {propBody && <Modal.Body>{propBody}</Modal.Body>}
      <Modal.Footer>
        <UIButton variant={propCancelVariant} onClick={handleCancel}>
          {propCancelText}
        </UIButton>
        <UIButton variant={propAcceptVariant} onClick={handleAccept}>
          {propAcceptText}
        </UIButton>
      </Modal.Footer>
    </Modal>
  )
}

export default UIModal
