import { gql } from '../../../../../hooks'

export const DELETE_ANALYSIS_METHODS = gql`
  mutation AdministrationDeleteSamplingAnalysisMethods($id: ID!) {
    aDelete(path: "sampling/analysis/methods", id: $id) {
    }
  }
`

export const LIST_ANALYSIS_METHODS = gql`
  query AdministrationListSamplingAnalysisMethods(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(
      path: "sampling/analysis/methods"
      filter: $filter
      page: $page
      limit: $limit
    ) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`
