import { gql } from '../../../../../../../hooks/useQuery'

export const LIST_CLIENTS = gql`
  query ListClients($id: ID!) {
    t2ClientsByLaboratory(id: $id) {
    }
  }
`

export const LIST_SITES = gql`
  query ListSites($id: ID!) {
    t2SitesByClient(id: $id) {
    }
  }
`

export const LIST_MACHINE_TYPES = gql`
  query ListMachineTypes($id: ID!) {
    t2MachineTypesBySite(id: $id) {
    }
  }
`

export const LIST_MACHINES = gql`
  query ListMachinesBySiteAndType($id_site: ID!, $id_machine_type: ID!) {
    t2MachinesBySiteAndType(
      id_site: $id_site
      id_machine_type: $id_machine_type
    ) {
    }
  }
`

export const LIST_COMPONENTS = gql`
  query ListComponents($id: ID!) {
    t2ComponentsByMachine(id: $id) {
    }
  }
`

export const LIST_LUBRICANT_CHANGE_REFILL_MOTIVES = gql`
  query LubricantChangeRefillMotives {
    t2LubricantChangeRefillMotives {
    }
  }
`

export const LIST_COMPONENT_CHANGE_MOTIVES = gql`
  query ComponentChangeMotives {
    t2ComponentChangeMotives {
    }
  }
`

export const LIST_LUBIRCANT_CHANGE_REFILLS = gql`
  query LubricantChangeRefills {
    t2LubricantChangeRefills {
    }
  }
`

export const ENTER_SAMPLES_MASSIVE = gql`
  mutation EnterSampleMassive($samples: JSONObject!) {
    t2EnterSampleMassive(samples: $samples) {
    }
  }
`

export const GET_MASSIVE_SAMPLES = gql`
  query GetMassiveSamples {
    t2MassiveSamples {
    }
  }
`
