import React from 'react'

// Hooks
import { useGlobal } from '../../../../../hooks'

import AdministrationInfo from '../..'

// Queries
import { READ_ANOMALY_TYPES, DELETE_ANOMALY_TYPES } from './queries'

const AdministrationInfoAnalysisAnomalyTypes = ({ ...rest }) => {
  const [{ me }] = useGlobal()

  const no = ![1, 2].includes(me.id_role)

  return (
    <AdministrationInfo
      title={'Tipo de anomalía'}
      queries={{ read: READ_ANOMALY_TYPES, delete: DELETE_ANOMALY_TYPES }}
      {...rest}
      noDuplicate={no}
      noUpdate={no}
      noDelete={no}
    />
  )
}

export default AdministrationInfoAnalysisAnomalyTypes
