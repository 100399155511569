import React from 'react'

// Hooks
import { useGlobal } from '../../../../../hooks'

import AdministrationInfo from '../..'

// Queries
import {
  READ_CHANGE_REFILL_MOTIVES,
  DELETE_CHANGE_REFILL_MOTIVES,
} from './queries'

const AdministrationInfoLubricantChangeRefillMotives = ({ ...rest }) => {
  const [{ me }] = useGlobal()

  const no = ![1, 2].includes(me.id_role)

  return (
    <AdministrationInfo
      queries={{
        read: READ_CHANGE_REFILL_MOTIVES,
        delete: DELETE_CHANGE_REFILL_MOTIVES,
      }}
      title="Motivo cambio o relleno"
      {...rest}
      noDuplicate={no}
      noUpdate={no}
      noDelete={no}
    />
  )
}

export default AdministrationInfoLubricantChangeRefillMotives
