import { gql } from '../../../../../hooks'

export const DELETE_CLIENTS = gql`
  mutation AdministrationDeleteClientClients($id: ID!) {
    aDelete(path: "client/clients", id: $id) {
    }
  }
`

export const LIST_CLIENTS = gql`
  query AdministrationListClientClients(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(path: "client/clients", filter: $filter, page: $page, limit: $limit) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`
