import { gql } from '../../../../../hooks'

export const DELETE_ESSAY_CLASSIFICATIONS = gql`
  mutation AdministrationDeleteSamplingEssayClassifications($id: ID!) {
    aDelete(path: "sampling/essay/classifications", id: $id) {
    }
  }
`

export const LIST_ESSAY_CLASSIFICATIONS = gql`
  query AdministrationListSamplingEssayClassifications(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(
      path: "sampling/essay/classifications"
      filter: $filter
      page: $page
      limit: $limit
    ) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`
