import React from 'react'

import { AdministrationList } from '../../../List'

// Queries
import { DELETE_STATES, LIST_STATES } from './queries'

const queries = { list: LIST_STATES, delete: DELETE_STATES }

const AdministrationListAnalysisStates = () => (
  <AdministrationList queries={queries} noCreate noDelete />
)

export default AdministrationListAnalysisStates
