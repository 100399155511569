import { gql } from '../../../../../hooks'

export const READ_LOGICS = gql`
  query AdministrationReadAnalysisLogics($id: ID!) {
    aRead(path: "analysis/logics", id: $id) {
    }
  }
`

export const DELETE_LOGICS = gql`
  mutation AdministrationDeleteAnalysisLogics($id: ID!) {
    aDelete(path: "analysis/logics", id: $id) {
    }
  }
`
