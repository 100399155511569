import React from 'react'
import { Form } from 'react-bootstrap'

const UIFormInputHelperLabel = ({
  span,
  style = { fontSize: '14px' },
  classes = ['form-label', 'font-weight-light', 'text-right'],
  label,
}) => {
  return (
    <Form.Label column lg={span} style={style} bsPrefix={classes.join(' ')}>
      {label}
    </Form.Label>
  )
}

export default UIFormInputHelperLabel
