import React from 'react'
import { Image, Card, Container, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faKey } from '@fortawesome/free-solid-svg-icons'

// Hooks
import { useGlobal, useHistory, useState, useMutation } from '../../hooks'

// Components
import UIButton from '../UI/Button'
import UIForm from '../UI/FormReload'
import UIFormInputPassword from '../UI/FormReload/Input/Password'
import UIFormSeparation from '../UI/FormReload/Separation'

// Queries
import { RESET_PASSWORD } from './queries'

const ResetPassword = () => {
  const [password, setPasswordState] = useState()
  const [passwordVerify, setPasswordVerifyState] = useState()

  const history = useHistory()
  const [{ me }, dispatch] = useGlobal()

  // --------------------------------------------------------------------------
  //
  // Mutation
  //
  // --------------------------------------------------------------------------

  const [resetPassword] = useMutation(RESET_PASSWORD, {
    onSuccess: () =>
      dispatch({ type: 'updateMe', me: { ...me, reset_password: 0 } }),
    onErrorMessage: 'No se pudo crear una nueva contraseña',
  })

  const handleSubmit = ({ password }) => {
    resetPassword({ variables: { password } })
  }

  const handlePasswordChange = (event) => {
    setPasswordState(event.target.value)
  }

  const handlePasswordVerifyChange = (event) => {
    setPasswordVerifyState(event.target.value)
  }

  const validator = (value) => {
    if ([null, undefined, ''].includes(value)) return 'Valor requerido'

    if (/[^a-zA-Z0-9-!\?+,\.=\$]/.test(value))
      return 'Sólo valores alfanuméricos y símbolos ! ? - + , . = $'

    if (
      !/[a-z]/.test(value) ||
      !/[A-Z]/.test(value) ||
      !/[0-9]/.test(value) ||
      !/[-!\?+,\.=\$]/.test(value)
    )
      return 'Se requiere al menos una minúsculas, una mayúsculas, un número y un símbolo'

    if (String(value).length < 8 || String(value).length > 20)
      return 'El largo debe ser entre 8 y 20 caracteres'
  }

  return (
    <Container fluid className="h-100">
      <Row className="align-items-center h-100">
        <Col className="offset-lg-2 offset-xl-3" lg={8} xl={6}>
          <Card className="mb-3">
            <Card.Body className="text-center">
              <Image
                alt="logo"
                src={'/imgs/logo/coddi-dark.png'}
                fluid
                style={{ width: '300px' }}
              />
              <hr />
              <h2 className="my-5">
                Cambio de contraseña
                <FontAwesomeIcon
                  size="1x"
                  icon={faKey}
                  color="gray"
                  className="ml-3"
                />
              </h2>
              <p className="text-left ml-5 text-muted">
                ¿Por qué debo cambiar mi contraseña?
                <ul className="">
                  <li className="">Es la primera vez que ingresa al sitio</li>
                  <li className="">
                    Algún administrador indicó explícitamente que debe renovar
                    su contraseña
                  </li>
                </ul>
              </p>
            </Card.Body>
          </Card>
          <UIForm onSubmit={handleSubmit}>
            <Card>
              <Card.Body>
                <UIFormInputPassword
                  label="Nueva contraseña"
                  className="my-1"
                  onChange={handlePasswordChange}
                  name="password"
                  autoComplete="off"
                  validator={validator}
                />
                <UIFormInputPassword
                  label="Verificar nueva contraseña"
                  className="my-1"
                  onChange={handlePasswordVerifyChange}
                  name="password_verify"
                  autoComplete="off"
                  text={
                    password && passwordVerify && password !== passwordVerify
                      ? 'Las contraseñas deben ser iguales'
                      : null
                  }
                />
                <UIFormSeparation />
                <div className="mt-2">
                  <UIButton
                    variant="stng"
                    className="btn btn-lg btn-block"
                    type="submit"
                    disabled={
                      !password ||
                      !passwordVerify ||
                      password !== passwordVerify
                    }
                  >
                    Guardar
                  </UIButton>
                </div>
              </Card.Body>
            </Card>
          </UIForm>
        </Col>
      </Row>
    </Container>
  )
}

export default ResetPassword
