import React from 'react'

import { AdministrationForm } from '../..'

// Queries
import {
  READ_BRANDS,
  CREATE_BRANDS,
  UPDATE_BRANDS,
  LIST_BRANDS,
} from './queries'

const queries = {
  read: READ_BRANDS,
  create: CREATE_BRANDS,
  update: UPDATE_BRANDS,
  list: LIST_BRANDS,
}

const AdministrationFormMachineBrands = () => (
  <AdministrationForm name={'marca'} queries={queries} />
)

export default AdministrationFormMachineBrands
