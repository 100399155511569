import React from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

// Hooks
import { useQuery } from '../../../../hooks'

// Components
import UIButton from '../../../UI/Button'
import UILoading from '../../../UI/Loading'
import UIError from '../../../UI/Error'
import UIEmpty from '../../../UI/Empty'
import UIPanelInfo from '../../../UI/Panel/Info'
import UIPanelInfoBlock from '../../../UI/Panel/Info/Block'
import UIPanelInfoItem from '../../../UI/Panel/Info/Item'
import UIFormSeparation from '../../../UI/FormReload/Separation'
import UITable from '../../../UI/Table'
import UITableHead from '../../../UI/Table/Head'
import UITableBody from '../../../UI/Table/Body'

import InfoHeader from '../../Container/InfoHeader'

// Excel export
import ButtonDownload from './download'

// Queries
import { GET_REPORT } from './queries'

const ReportTableInvoicing = ({ filter, onFilterChange }) => {
  const {
    uf_value,
    id_client,
    id_site,
    id_machine_type,
    id_machine,
    from,
    to,
  } = filter

  // filter.uf_value = parseFloat(filter.uf_value)

  // --------------------------------------------------------------------------
  //
  // Query report
  //
  // --------------------------------------------------------------------------

  const { data, loading } = useQuery(GET_REPORT, {
    variables: { ...filter },
    onErrorMessage: `Error al intentar obtener reporte`,
  })

  // --------------------------------------------------------------------------
  //
  // Render
  //
  // --------------------------------------------------------------------------

  if (loading) return <UILoading />

  if (data && data.reportInvoicing) {
    const { error } = data.reportInvoicing

    const formatMoney = (value) => {
      if (isNaN(value) || [null, undefined].includes(value)) return value

      let decimal = (parseFloat(value) % 1).toFixed(3)

      if (parseFloat(decimal)) decimal = `,${decimal.substring(2)}`
      else decimal = ''

      return `$ ${parseInt(value)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}${decimal}`
    }

    let InfoBody
    let showExcelButton
    let totalSamples = 0
    let totalCost = 0

    // Errors?
    // ------------------------------------------------------------------------
    if (error) {
      showExcelButton = false
      InfoBody = (
        <div className="text-center  py-5 border rounded">
          <FontAwesomeIcon
            size="10x"
            color="lightgray"
            className="mr-2"
            icon={faBan}
          />

          <h5 className="text-muted font-weight-light mt-5">{error.message}</h5>
        </div>
      )
    }
    // No errors
    // ------------------------------------------------------------------------
    else {
      const { info, results } = data.reportInvoicing.data

      // Empty?
      // ----------------------------------------------------------------------
      if (!results || !results.length) {
        showExcelButton = false
        InfoBody = <UIEmpty />
      }
      // Not empty
      // ----------------------------------------------------------------------
      else {
        showExcelButton = true
        const classNameHeader = 'bg-stng border text-white align-middle'

        InfoBody = (
          <UITable borderless className="mb-5">
            <UITableHead className={classNameHeader} fontSize="small">
              {['Nivel', 'N° muestra', 'Detalle', 'Total']}
            </UITableHead>
            <UITableBody>
              {results.map((result, index) => {
                const {
                  total,
                  id,
                  name,
                  cost_name,
                  cost_type,
                  cost_value,
                  cost_total,
                } = result

                totalSamples += parseFloat(total)
                totalCost += parseFloat(cost_total)

                return (
                  <tr key={`tr-${index}`}>
                    <td className="align-middle border-right">{name}</td>
                    <td className="align-middle">{total}</td>
                    <td>
                      <UITable borderless>
                        <UITableHead className={classNameHeader}>
                          {[
                            'Tipo de Costo',
                            'Nº Muestra',
                            '--',
                            'Costo',
                            'Valor UF',
                            'Valor Muestreo',
                            'Total Item',
                          ].map((name) => (
                            <small>{name}</small>
                          ))}
                        </UITableHead>
                        <UITableBody>
                          <tr>
                            <td>{cost_name}</td>
                            <td>{total}</td>
                            <td>{cost_type}</td>
                            <td>{formatMoney(cost_value)}</td>
                            <td>{uf_value ? formatMoney(uf_value) : '--'}</td>
                            <td>{formatMoney(cost_value)}</td>
                            <td>{formatMoney(cost_total)}</td>
                          </tr>
                        </UITableBody>
                      </UITable>
                    </td>
                    <td className="align-middle">{formatMoney(cost_total)}</td>
                  </tr>
                )
              })}
              <tr className="bg-stng text-white font-weight-bold">
                <td className="text-right">Total muestras:</td>
                <td>{totalSamples}</td>
                <td className="text-right">Total Precio:</td>
                <td>{formatMoney(totalCost)}</td>
              </tr>
            </UITableBody>
          </UITable>
        )
      }
    }

    const handleBack = () => onFilterChange()

    return (
      <>
        <Form.Group as={Row} className="m-1">
          <Col className="d-flex">
            <UIButton type="submit" className="mx-2" onClick={handleBack}>
              Atrás
            </UIButton>
            {showExcelButton && <ButtonDownload filter={filter} />}
          </Col>
        </Form.Group>
        <Row className="m-1">
          <Col xs={12}>
            <UIFormSeparation />
          </Col>
          {!error && (
            <>
              <Col xs={6}>
                <InfoHeader
                  info={{
                    ...data.reportInvoicing.data.info,
                    from,
                    to,
                  }}
                />
              </Col>
              <Col xs={6}>
                <UIPanelInfo>
                  <UIPanelInfoBlock title="Detalle según código cobranza">
                    <UIPanelInfoItem name="N° de muestras " className="mt-2">
                      {totalSamples}
                    </UIPanelInfoItem>
                    <UIPanelInfoItem name="Tipo de costo">
                      {data.reportInvoicing.data.results &&
                      data.reportInvoicing.data.results.length
                        ? data.reportInvoicing.data.results[0].cost_name
                        : '-'}
                    </UIPanelInfoItem>
                    <UIPanelInfoItem name="Costo por muestra">
                      {data.reportInvoicing.data.results &&
                      data.reportInvoicing.data.results.length
                        ? formatMoney(
                            data.reportInvoicing.data.results[0].cost_value,
                          )
                        : '-'}
                    </UIPanelInfoItem>
                  </UIPanelInfoBlock>
                </UIPanelInfo>
              </Col>
            </>
          )}
          <Col xs={12}>{InfoBody}</Col>
        </Row>
      </>
    )
  }
  return <UIError />
}

export default ReportTableInvoicing
