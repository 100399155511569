import { gql } from '../../../../../hooks'

export const READ_STATES = gql`
  query AdministrationReadAnalysisStates($id: ID!) {
    aRead(path: "analysis/states", id: $id) {
    }
  }
`

export const UPDATE_STATES = gql`
  mutation AdministrationUpdateAnalysisStates($id: ID!, $data: JSONObject) {
    aUpdate(path: "analysis/states", id: $id, data: $data) {
    }
  }
`

export const CREATE_STATES = gql`
  mutation AdministrationCreateAnalysisStates($data: JSONObject) {
    aCreate(path: "analysis/states", data: $data) {
    }
  }
`

export const LIST_STATES = gql`
  query AdministrationListAnalysisStates(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(
      path: "analysis/states"
      filter: $filter
      page: $page
      limit: $limit
    ) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`

export const LIST_ESSAYS = gql`
  query AdministrationListEssays {
    aEssays {
    }
  }
`
