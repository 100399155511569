import React from 'react'

// Components
import AdministrationListAnalysisSuggestions from '../../../../Administration/List/Analysis/Suggestions'
import AdministrationInfoAnalysisSuggestions from '../../../../Administration/Info/Analysis/Suggestions'
import AdministrationFormAnalysisSuggestions from '../../../../Administration/Form/Analysis/Suggestions'

import { AdministrationContainer } from '../..'

const AdministrationContainerAnalysisSuggestions = () => (
  <AdministrationContainer
    form={AdministrationFormAnalysisSuggestions}
    info={AdministrationInfoAnalysisSuggestions}
    list={AdministrationListAnalysisSuggestions}
  />
)

export default AdministrationContainerAnalysisSuggestions
