import { gql } from '../../../../../../hooks'

export const READ_SITES_CASES = gql`
  query AdministrationReadClientSitesCases($id: ID!) {
    aRead(path: "client/sites/cases", id: $id) {
    }
  }
`

export const UPDATE_SITES_CASES = gql`
  mutation AdministrationUpdateClientSitesCases($id: ID!, $data: JSONObject) {
    aUpdate(path: "client/sites/cases", id: $id, data: $data) {
    }
  }
`

export const CREATE_SITES_CASES = gql`
  mutation AdministrationCreateClientSitesCases($data: JSONObject) {
    aCreate(path: "client/sites/cases", data: $data) {
    }
  }
`

export const LIST_SITES_CASES = gql`
  query AdministrationListClientSitesCases(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(
      path: "client/sites/cases"
      filter: $filter
      page: $page
      limit: $limit
    ) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`

// export const SEARCH_SITE = gql`
//   query AdministrationSearchSite {
//     aSearch(path: "site", limit: 0, text: "") {
//     }
//   }
// `

export const SEARCH_SITE = gql`
  query AdministrationSearchSite($text: String) {
    aSearch(path: "site", text: $text) {
    }
  }
`

// export const SEARCH_MACHINE_TYPE = gql`
//   query AdministrationSearchSite($id_site: Int) {
//     aSearch(path: "machine_type", limit: 0, filter: {id_site: $id_site }, text: "") {
//     }
//   }
// `
export const SEARCH_MACHINE_TYPE = gql`
  query AdministrationSearchMachineType($text: String, $id_site: Int) {
    aSearch(path: "machine_type", filter: { id_site: $id_site },  text: $text) {
    }
  }
`
