import React from 'react'

// Components
import UIFormInputSelect from '../../../../UI/FormReload/Input/Select'
import UIFormInputSearch from '../../../../UI/FormReload/Input/Search'
import UIFormInputText from '../../../../UI/FormReload/Input/Text'
import UIFormInputTextarea from '../../../../UI/FormReload/Input/Textarea'
import UIFormInputNumber from '../../../../UI/FormReload/Input/Number'
import UIFormInputInteger from '../../../../UI/FormReload/Input/Integer'
import UIFormInputDate from '../../../../UI/FormReload/Input/Date'
import UIPanelInfoTable from '../../../../UI/Panel/Info/Table'

// Common
import { oe } from '../../../../../common/object/extractor'

import { AdministrationForm } from '../..'

// Hooks
import { useAlert, useState, useLazyQuery } from '../../../../../hooks'

// Queries
import {
  READ_SAMPLES,
  UPDATE_SAMPLES,
  SEARCH_COMPONENT,
  SEARCH_TRACKING_STATE,
  SEARCH_ANOMALY_TYPE,
  SEARCH_CONDITION,
  SEARCH_CHANGE_REFILL_MOTIVE,
  SEARCH_CHANGE_REFILL,
  SEARCH_COMPONENT_CHANGE_MOTIVE,
  SEARCH_PRIORITY,
  CHECK_COMPONENT_HISTORY,
} from './queries'

const queries = {
  read: READ_SAMPLES,
  update: UPDATE_SAMPLES,
}

const CustomForm = ({ action, data, id }) => {
  const alert = useAlert()

  const [isComponentValid, setIsComponentValid] = useState(true)

  const [checkComponentHistory] = useLazyQuery(CHECK_COMPONENT_HISTORY, {
    onCompleted: (data) => {
      if (
        data &&
        data.aCheckComponentHistory &&
        data.aCheckComponentHistory.data
      ) {
        const { isValid, message } = data.aCheckComponentHistory.data

        setIsComponentValid(isValid)

        if (!isValid && message) alert(message, { style: 'danger' })
      }
    },
  })

  const handleComponentChange = (id_component) => {
    checkComponentHistory({ variables: { id, id_component } })
  }

  return (
    <UIPanelInfoTable>
      {[
        action === 'update'
          ? [
              'ID',
              <UIFormInputText
                name="id"
                initialValue={id}
                placeholder={id}
                disabled
              />,
            ]
          : [],
        ['Estado', oe(data, ['stage', 'name'])],
        ...([1, 2, 3, 4].includes(oe(data, ['stage', 'id']))
          ? [
              [
                'Fecha muestreo',
                <UIFormInputDate
                  showTime
                  name="date_sampling"
                  initialValue={oe(data, ['date_sampling'])}
                />,
              ],
              [
                'Fecha retiro',
                <UIFormInputDate
                  showTime
                  name="date_withdraw"
                  initialValue={oe(data, ['date_withdraw'])}
                  placeholder={oe(data, ['date_withdraw'])}
                />,
              ],
              [
                'Fecha ingreso',
                <UIFormInputDate
                  showTime
                  name="date_entry"
                  initialValue={oe(data, ['date_entry'])}
                  placeholder={oe(data, ['date_entry'])}
                />,
              ],
            ]
          : []),
        ...([2, 3, 4].includes(oe(data, ['stage', 'id']))
          ? [
              [
                'Fecha recepción',
                <UIFormInputDate
                  showTime
                  name="date_receive"
                  initialValue={oe(data, ['date_receive'])}
                  placeholder={oe(data, ['date_receive'])}
                />,
              ],
            ]
          : []),
        ...([3, 4].includes(oe(data, ['stage', 'id']))
          ? [
              [
                'Fecha ensayo',
                <UIFormInputDate
                  showTime
                  name="date_essay"
                  initialValue={oe(data, ['date_essay'])}
                  placeholder={oe(data, ['date_essay'])}
                />,
              ],
            ]
          : []),
        ...([4].includes(oe(data, ['stage', 'id']))
          ? [
              [
                'Fecha propuesto',
                <UIFormInputDate
                  showTime
                  name="date_proposed"
                  initialValue={oe(data, ['date_proposed'])}
                  placeholder={oe(data, ['date_proposed'])}
                />,
              ],
            ]
          : []),
        /*[
      "Rut ingreso"
, <UIFormInputText

      name="run_entry"
      disabled
      placeholder={oe(data, ['run_entry'])}
    />],
    [
      "Rut recepción"
, <UIFormInputText

      name="run_receive"
      disabled
      placeholder={oe(data, ['run_receive'])}
    />],
    [
      "Rut ensayo"
, <UIFormInputText

      name="run_essay"
      disabled
      placeholder={oe(data, ['run_essay'])}
    />],
    [
      "ID estado"
, <UIFormInputInteger

      name="id_stage"
      disabled
      placeholder={oe(data, ['id_stage'])}
  />], */
        // [
        //   'ID prioridad',

        //   <UIFormInputInteger
        //     inline
        //     name="id_sample_priority"
        //     initialValue={oe(data, ['id_sample_priority'])}
        //     placeholder={oe(data, ['id_sample_priority'])}
        //   />,
        // ],
        [
          'Prioridad',
          <UIFormInputSelect
            name="id_sample_priority"
            query={SEARCH_PRIORITY}
            idAs="value"
            initialValue={oe(data, ['priority', 'id'])}
          />,
        ],
        [
          'HK Lubricante',
          <UIFormInputNumber
            name="lubricant_continuity"
            initialValue={oe(data, ['lubricant_continuity'])}
            placeholder={oe(data, ['lubricant_continuity'])}
          />,
        ],
        [
          'HK Equipo',
          <UIFormInputNumber
            name="machine_continuity"
            initialValue={oe(data, ['machine_continuity'])}
            placeholder={oe(data, ['machine_continuity'])}
          />,
        ],
        [
          'HK Componente',
          <UIFormInputNumber
            name="component_continuity"
            initialValue={oe(data, ['component_continuity'])}
            placeholder={oe(data, ['component_continuity'])}
          />,
        ],
        [
          'Número externo',
          <UIFormInputText
            name="external_number"
            initialValue={oe(data, ['external_number'])}
            placeholder={oe(data, ['external_number'])}
          />,
        ],
        [
          'Orden de trabajo',
          <UIFormInputText
            name="work_order"
            initialValue={oe(data, ['work_order'])}
            placeholder={oe(data, ['work_order'])}
          />,
        ],
        [
          'Cantidad de rellenos',
          <UIFormInputInteger
            name="number_of_refills"
            initialValue={oe(data, ['number_of_refills'])}
            placeholder={oe(data, ['number_of_refills'])}
          />,
        ],
        [
          'Comentario global',
          <UIFormInputTextarea
            name="comment"
            initialValue={oe(data, ['comment'])}
            placeholder={oe(data, ['comment'])}
          />,
        ],
        // [
        //   'ID Componente',
        //   <UIFormInputInteger
        //     inline
        //     name="id_component"
        //     placeholder={oe(data, ['id_component'])}
        //   />,
        // ],
        [
          'Componente',
          <UIFormInputSearch
            name="id_component"
            query={SEARCH_COMPONENT}
            onClick={handleComponentChange}
            placeholder={{
              value: oe(data, ['component', 'id']),
              name: oe(data, ['component', 'tag']),
            }}
            initialValue={{
              value: oe(data, ['component', 'id']),
              name: oe(data, ['component', 'tag']),
            }}
            text={
              isComponentValid
                ? undefined
                : 'Componente inválido - será ignorado'
            }
          />,
        ],
        /*[
      "ID Protocolo metal"
, <UIFormInputInteger

      name="id_component_protocol"
      disabled
      placeholder={oe(data, ['id_component_protocol'])}
  />], */
        // [
        //   'ID Motivo cambio componente',

        //   <UIFormInputInteger
        //     inline
        //     name="id_component_change_motive"
        //     initialValue={oe(data, ['id_component_change_motive'])}
        //     placeholder={oe(data, ['id_component_change_motive'])}
        //   />,
        // ],
        [
          'Motivo cambio componente',
          <UIFormInputSelect
            name="id_component_change_motive"
            query={SEARCH_COMPONENT_CHANGE_MOTIVE}
            idAs="value"
            initialValue={oe(data, ['component', 'change_motive', 'id'])}
          >
            <option>No especificado</option>
          </UIFormInputSelect>,
        ],
        /*[
      "ID Lubricante"
, <UIFormInputInteger

      name="id_lubricant"
      disabled
      initialValue={oe(data, ['id_lubricant'])}
      placeholder={oe(data, ['id_lubricant'])}
    />],
      [
      "ID Protocolo lubricante"
, <UIFormInputInteger

      name="id_lubricant_protocol"
      disabled
      initialValue={oe(data, ['id_lubricant_protocol'])}
      placeholder={oe(data, ['id_lubricant_protocol'])}
    />], */
        // [
        //   'ID Lubricante cambio/relleno',

        //   <UIFormInputInteger
        //     inline
        //     name="id_lubricant_change_refill"
        //     initialValue={oe(data, ['id_lubricant_change_refill'])}
        //     placeholder={oe(data, ['id_lubricant_change_refill'])}
        //   />,
        // ],
        [
          'Lubricante cambio/relleno',

          <UIFormInputSelect
            name="id_lubricant_change_refill"
            query={SEARCH_CHANGE_REFILL}
            idAs="value"
            initialValue={oe(data, ['lubricant', 'change_refill', 'id'])}
          >
            <option>No especificado</option>
          </UIFormInputSelect>,
        ],
        // [
        //   'ID Motivo cambio/relleno lubricante',
        //   <UIFormInputInteger
        //     inline
        //     name="id_lubricant_change_refill_motive"
        //     initialValue={oe(data, ['id_lubricant_change_refill_motive'])}
        //     placeholder={oe(data, ['id_lubricant_change_refill_motive'])}
        //   />,
        // ],
        [
          'Motivo cambio/relleno lubricante',
          <UIFormInputSelect
            name="id_lubricant_change_refill_motive"
            query={SEARCH_CHANGE_REFILL_MOTIVE}
            idAs="value"
            initialValue={oe(data, ['lubricant', 'change_refill_motive', 'id'])}
          >
            <option>No especificado</option>
          </UIFormInputSelect>,
          ,
        ],
        [
          'Litros cambio/relleno lubricante',
          <UIFormInputNumber
            name="lubricant_change_refill_liters"
            initialValue={oe(data, ['lubricant', 'change_refill_liters'])}
            placeholder={oe(data, ['lubricant', 'change_refill_liters'])}
          />,
        ],
        [
          'Horas cambio/relleno lubricante',
          <UIFormInputNumber
            name="lubricant_change_refill_hours"
            initialValue={oe(data, ['lubricant_change_refill_hours'])}
            placeholder={oe(data, ['lubricant_change_refill_hours'])}
          />,
        ],
        // [
        //   'ID Condición',
        //   <UIFormInputInteger
        //     inline
        //     name="id_sample_condition"
        //     initialValue={oe(data, ['id_sample_condition'])}
        //     placeholder={oe(data, ['id_sample_condition'])}
        //   />,
        // ],
        ...([4].includes(oe(data, ['stage', 'id']))
          ? [
              [
                'Condición',
                <UIFormInputSelect
                  name="id_sample_condition"
                  query={SEARCH_CONDITION}
                  idAs="value"
                  initialValue={oe(data, ['condition', 'id'])}
                >
                  <option>No especificado</option>
                </UIFormInputSelect>,
              ],
              // [
              //   'ID Tipo de anomalía',
              //   <UIFormInputInteger
              //     inline
              //     name="id_anomaly_type"
              //     initialValue={oe(data, ['id_anomaly_type'])}
              //     placeholder={oe(data, ['id_anomaly_type'])}
              //   />,
              // ],
              [
                'Tipo de anomalía',
                <UIFormInputSelect
                  name="id_anomaly_type"
                  query={SEARCH_ANOMALY_TYPE}
                  idAs="value"
                  initialValue={oe(data, ['anomaly_type', 'id'])}
                >
                  <option>No especificado</option>
                </UIFormInputSelect>,
              ],
              // [
              //   'ID Estado de seguimiento',
              //   <UIFormInputInteger
              //     inline
              //     name="id_sample_tracking_state"
              //     initialValue={oe(data, ['id_sample_tracking_state'])}
              //     placeholder={oe(data, ['id_sample_tracking_state'])}
              //   />,
              // ],
              [
                'Estado de seguimiento',
                <UIFormInputSelect
                  name="id_sample_tracking_state"
                  query={SEARCH_TRACKING_STATE}
                  idAs="value"
                  initialValue={oe(data, ['tracking_state', 'id'])}
                >
                  <option>No especificado</option>
                </UIFormInputSelect>,
                ,
              ],
              oe(data, ['recommendation'])
                ? [
                    'Recomendación',
                    <UIFormInputTextarea
                      name="recommendation"
                      initialValue={oe(data, ['recommendation'])}
                      placeholder={oe(data, ['recommendation'])}
                    />,
                  ]
                : null,
              oe(data, ['recommendation'])
                ? null
                : [
                    'Comentario',
                    <UIFormInputTextarea
                      name="state"
                      initialValue={oe(data, ['state'])}
                      placeholder={oe(data, ['state'])}
                    />,
                  ],
              oe(data, ['recommendation'])
                ? null
                : [
                    'Acción',
                    <UIFormInputTextarea
                      name="suggestion"
                      initialValue={oe(data, ['suggestion'])}
                      placeholder={oe(data, ['suggestion'])}
                    />,
                  ],
              [
                null,
                <UIFormInputTextarea
                  hidden
                  name="is_component_valid"
                  initialValue={isComponentValid}
                />,
              ],
            ]
          : []),
      ].filter((input) => !!input)}
    </UIPanelInfoTable>
  )
}

const AdministrationFormSamplingSamples = () => (
  <AdministrationForm name={'muestra'} queries={queries}>
    <CustomForm />
  </AdministrationForm>
)

export default AdministrationFormSamplingSamples
