import React from 'react'
import { Col, Row } from 'react-bootstrap'

import { useParams } from '../../../../hooks'

import TribologySamplesReoprtList from '../../../../components/Tribology/Samples/Report/List'
import TribologySamplesReport from '../../../../components/Tribology/Samples/Report'

const PageTribologySamplesReport = () => {
  const { id_sample } = useParams()

  if (id_sample) return <TribologySamplesReport id={id_sample} />

  return (
    <Row className="m-4">
      <Col lg={12} className="mb-3">
        <TribologySamplesReoprtList />
      </Col>
    </Row>
  )
}

export default PageTribologySamplesReport
