import React from 'react'
import { Col, Row } from 'react-bootstrap'

// Hooks
import { useState } from '../../../../../../hooks'

import TribologySamplesInsertMassiveExcelForm from '../../../../../Tribology/Samples/Insert/Massive/Excel/Form'
import TribologySamplesInsertMassiveExcelInfo from '../../../../../Tribology/Samples/Insert/Massive/Excel/Info'

const TribologySamplesInsertMassiveExcel = () => {
  const [id, setIdState] = useState()

  const handleIdChange = (id) => {
    setIdState(id)
  }

  if (id)
    return (
      <Row className="my-4">
        <Col lg={8} className="mb-3">
          <TribologySamplesInsertMassiveExcelForm onIdChange={handleIdChange} />
        </Col>
        <Col lg={4}>
          <TribologySamplesInsertMassiveExcelInfo id={id} />
        </Col>
      </Row>
    )

  return (
    <Row className="my-4">
      <Col lg={12} className="mb-3">
        <TribologySamplesInsertMassiveExcelForm onIdChange={handleIdChange} />
      </Col>
    </Row>
  )
}

export default TribologySamplesInsertMassiveExcel
