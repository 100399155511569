import { gql } from '../../../../hooks'

export const LIST_CREATED_INSPECTIONS = gql`
  query CreatedInspections($page: Int, $limit: Int) {
    i1InspectionsByStage(page: $page, limit: $limit) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`
