import { gql } from '../../../../../hooks'

export const GET_RESULTS_BY_SAMPLE = gql`
  query ResultsBySample($id: ID!) {
    t5ResultsBySample(id: $id) {
    }
  }
`

export const ESSAY_SAMPLE = gql`
  mutation EssaySample($id: ID!) {
    t5EssaySample(id: $id) {
    }
  }
`

// export const UPDATE_RESULTS = gql`
//   mutation UpdateResults($id: ID!, $results: [ResultInput!]!) {
//     t5UpdateResult(id: $id, results: $results) {
//     }
//   }
// `
export const UPDATE_RESULTS = gql`
  mutation UpdateResults($id: ID!, $results: JSONObject!) {
    t5UpdateResult(id: $id, results: $results) {
    }
  }
`

export const UPDATE_RESULTS_RESAMPLED = gql`
  mutation UpdateResultsResampled($id_sample: ID!, $id_essay: ID!, $is_resampled: Int!) {
    t5UpdateResultResampled(id_sample: $id_sample, id_essay: $id_essay, is_resampled: $is_resampled) {
    }
  }
`

// export const UPLOAD_IMAGE = gql`
//   mutation UploadImageResults($file: Upload!) {
//     t5UploadImageResults(file: $file) {
//     }
//   }
// `
