import React from 'react'
import { Row, Col } from 'react-bootstrap'

// Components
import UIPanelInfo from '../../../../../UI/Panel/Info'
import UIPanelInfoBlock from '../../../../../UI/Panel/Info/Block'
import UIPanelInfoItem from '../../../../../UI/Panel/Info/Item'
import UIFormInputSelect from '../../../../../UI/FormReload/Input/Select'
import UILoading from '../../../../../UI/Loading'
import UIError from '../../../../../UI/Error'

// Hooks
import {
  useGlobal,
  useState,
  useEffect,
  useQuery,
} from '../../../../../../hooks'

// Queries
import { GET_SAMPLE_CONDITIONS, GET_SAMPLE_INFO } from './queries'

import oe from '../../../../../../common/object/extractor'

const SampleInfoTop = ({ id, logics }) => {
  const [{ me }] = useGlobal()

  // --------------------------------------------------------------------------
  //
  // Get Info
  //
  // --------------------------------------------------------------------------

  const [value, setValueState] = useState(1)

  useEffect(() => {
    if (logics) setValueState(oe(logics, ['id_sample_condition'], 1))
  }, [logics])

  const { data, loading } = useQuery(GET_SAMPLE_INFO, {
    fetchPolicy: 'network-only',
    variables: { id, noise: me.id_laboratory },
    onErrorMessage: `No se pudo obtener la información de la muestra`,
  })

  if (loading) return <UILoading />

  if (data && data.t4SampleInfo && data.t4SampleInfo.data) {
    const { sample, component, machine, lubricant, site, client, user } =
      data.t4SampleInfo.data

    const classes = 'm-0 p-0'
    const styles = { fontSize: '12px' }

    const isDisabled = [1, 2, 4, 5, 6, 7].includes(sample.stage.id)

    const handleChange = (event) => {
      setValueState(event.target.value)
    }

    return (
      <>
        <div className="lead text-center mb-3">
          <h4 className="lead">Informe de ensayo</h4>
          <h6 className="text-muted">(Vista previa muestra Nº {sample.id})</h6>
        </div>
        <UIPanelInfo>
          <Row>
            <Col md={3}>
              <UIPanelInfoBlock className="my-0 ml-2">
                <UIPanelInfoItem
                  name="Client"
                  className={classes}
                  style={styles}
                >
                  {client.name}
                </UIPanelInfoItem>
                <UIPanelInfoItem
                  name="Faena"
                  className={classes}
                  style={styles}
                >
                  {site.name}
                </UIPanelInfoItem>
                <UIPanelInfoItem
                  name="Dirección"
                  className={classes}
                  style={styles}
                >
                  {client.address}
                </UIPanelInfoItem>
                <UIPanelInfoItem
                  name="Encargado"
                  className={classes}
                  style={styles}
                >
                  {user.name}
                </UIPanelInfoItem>
              </UIPanelInfoBlock>
            </Col>
            <Col md={3}>
              <UIPanelInfoBlock className="my-0 ml-2">
                <UIPanelInfoItem
                  name="Componente"
                  className={classes}
                  style={styles}
                >
                  {component.name}
                </UIPanelInfoItem>
                <UIPanelInfoItem
                  name="Marca"
                  className={classes}
                  style={styles}
                >
                  {component.brand}
                </UIPanelInfoItem>
                <UIPanelInfoItem
                  name="Modelo"
                  className={classes}
                  style={styles}
                >
                  {component.model}
                </UIPanelInfoItem>
                <UIPanelInfoItem
                  name="Lubricante en uso"
                  className={classes}
                  style={styles}
                >
                  {lubricant.name}
                </UIPanelInfoItem>
                <UIPanelInfoItem
                  name="Capacidad sist. lts."
                  className={classes}
                  style={styles}
                >
                  {component.liters}
                </UIPanelInfoItem>
              </UIPanelInfoBlock>
            </Col>
            <Col md={3}>
              <UIPanelInfoBlock className="my-0 ml-2">
                <UIPanelInfoItem
                  name="Instalado en"
                  className={classes}
                  style={styles}
                >
                  {machine.name}
                </UIPanelInfoItem>
                <UIPanelInfoItem
                  name="Marca"
                  className={classes}
                  style={styles}
                >
                  {machine.brand}
                </UIPanelInfoItem>
                <UIPanelInfoItem
                  name="Modelo"
                  className={classes}
                  style={styles}
                >
                  {machine.model}
                </UIPanelInfoItem>
                <UIPanelInfoItem name="TAG" className={classes} style={styles}>
                  {component.tag}
                </UIPanelInfoItem>
                <UIPanelInfoItem
                  name="N° externo"
                  className={classes}
                  style={styles}
                >
                  {sample.external_number}
                </UIPanelInfoItem>
              </UIPanelInfoBlock>
            </Col>
            <Col md={3}>
              <UIPanelInfoBlock className="my-0 ml-2">
                <UIPanelInfoItem
                  name="Condición"
                  className={classes}
                  style={styles}
                >
                  <UIFormInputSelect
                    name="id_sample_condition"
                    disabled={isDisabled}
                    value={value}
                    onChange={handleChange}
                    query={GET_SAMPLE_CONDITIONS}
                  />
                </UIPanelInfoItem>
                <UIPanelInfoItem
                  name="N° muestra"
                  className={classes}
                  style={styles}
                >
                  {sample.id}
                </UIPanelInfoItem>
                <UIPanelInfoItem
                  name="Procedimiento muestreo"
                  className={classes}
                  style={styles}
                >
                  {component.samplingProcedure}
                </UIPanelInfoItem>
                <UIPanelInfoItem
                  name="Punto muestreo"
                  className={classes}
                  style={styles}
                >
                  {component.samplingPoint}
                </UIPanelInfoItem>
              </UIPanelInfoBlock>
            </Col>
          </Row>
        </UIPanelInfo>
      </>
    )
  }

  return (
    <UIPanelInfo centered>
      <UIPanelInfoBlock>
        <UIError />
      </UIPanelInfoBlock>
    </UIPanelInfo>
  )
}

export default SampleInfoTop
