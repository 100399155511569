import React from 'react'
import { Row, Col, Form } from 'react-bootstrap'

// Global reducer
import { useState, useQuery } from '../../../../../../../hooks'

// Components
import UILoading from '../../../../../../../components/UI/Loading'
import UIButton from '../../../../../../../components/UI/Button'
import UIForm from '../../../../../../../components/UI/FormReload'
import UIFormSeparation from '../../../../../../../components/UI/FormReload/Separation'
import UITable from '../../../../../../../components/UI/Table'
import UITableHead from '../../../../../../../components/UI/Table/Head'
import UITableBody from '../../../../../../../components/UI/Table/Body'

// Queries
import { GET_MASSIVE_LABEL_INFO } from './queries'

const SampleFormInsertMassiveTabEscelTable = ({ data, onBack }) => {
  const [labels, setLabelsState] = useState({ samples: [], massive: [] })

  const { ids, id_massive } = data

  // --------------------------------------------------------------------------
  //
  // Query: Transform excel to the right format
  //
  // --------------------------------------------------------------------------

  const { data: dataSamples } = useQuery(GET_MASSIVE_LABEL_INFO, {
    variables: { ids, id_massive },
    onErrorMessage: `Error al intentar obtener la información en las muestras`,
  })

  // --------------------------------------------------------------------------
  //
  // Render
  //
  // --------------------------------------------------------------------------

  if (dataSamples && dataSamples.t2MassiveLabelInfo) {
    const { samples, massive } = dataSamples.t2MassiveLabelInfo.data

    const handleBack = () => {
      onBack()
    }

    const handleAllSamplesCheckboxChange = (event) => {
      setLabelsState((prev) => ({
        ...prev,
        samples: Array(samples.length).fill(event.target.checked),
      }))
    }

    const handleAllMassiveCheckboxChange = (event) => {
      setLabelsState((prev) => ({
        ...prev,
        massive: Array(massive.length).fill(event.target.checked),
      }))
    }

    const handleSamplesCheckboxChange = (event, index) => {
      setLabelsState((prev) => {
        prev.samples[index] = event.target.checked
        return { ...prev }
      })
    }

    const handleMassiveCheckboxChange = (event, index) => {
      setLabelsState((prev) => {
        prev.massive[index] = event.target.checked
        return { ...prev }
      })
    }

    const handleSubmit = (values) => {
      let text = ''

      // Filtering the labels to print
      const filtered_labels = [
        ...labels.samples.map((checked, index) =>
          checked ? samples[index] : false,
        ),
        ...labels.massive.map((checked, index) =>
          checked ? massive[index] : false,
        ),
      ].filter((l) => !!l)

      for (const label of filtered_labels) {
        const { sample, component, lubricant, client } = label

        text = `${text}ON
Q383,16
q799
S3
D10
ZT
JF
oR

N
I8,A,001
A33,131,0,1,1,2,N,"Número Muestra:"
A33,201,0,1,1,2,N,"Lubricante:  "
A435,77,0,1,2,2,N,"${sample.priority}"
B640,353,3,E30,3,0,117,B,"${'0'.repeat(
          12 - sample.id.toString().length,
        )}${sample.id.toString()}"
A34,269,0,1,1,2,N,"Cliente:"
A209,124,0,1,2,3,N,"${sample.id}"
A33,167,0,1,1,2,N,"TAG:  "
A208,160,0,1,2,3,N,"${component.tag}"
A455,32,0,1,1,2,N,"${sample.date}"
A209,228,0,1,2,3,N,""
A211,272,0,3,1,1,N,"${client}"
A211,293,0,3,1,1,N,""
A209,194,0,1,2,3,N,"${lubricant.name}"
P1

`
      }

      const now = new Date()
      const fileName = `Impresion_dia_${now.getDate()}_hora_${Math.round(
        now.getTime() / 1000,
      )}.txt`

      const element = document.createElement('a')
      const file = new Blob([text], {
        type: 'text/plain;charset=utf-8',
      })
      element.href = URL.createObjectURL(file)
      element.download = fileName
      document.body.appendChild(element)
      element.click()
    }

    return (
      <UIForm onSubmit={handleSubmit}>
        <Form.Group as={Row} className="my-4">
          <Col className="d-flex">
            <UIButton className="mx-2" onClick={handleBack}>
              Atrás
            </UIButton>
            <UIButton
              type="submit"
              className="mx-2"
              disabled={
                labels.samples.filter((s) => !!s).length +
                  labels.massive.filter((m) => !!m).length ===
                0
              }
            >
              Descargar etiquetas
            </UIButton>
          </Col>
        </Form.Group>
        <UIFormSeparation />
        <UITable borderless className="text-center font-weight-light my-4">
          <UITableHead>
            <tr>
              <td className="bg-stng border text-white align-middle">Act.</td>
              <td className="bg-stng border text-white align-middle">
                <small>Correlativo</small>
              </td>
              <td className="bg-stng border text-white align-middle">
                <small>Tag</small>
              </td>
              <td className="bg-stng border text-white align-middle">
                <small>Lubricante</small>
              </td>
              <td className="bg-stng border text-white align-middle">
                <small>Cliente</small>
              </td>
              <td className="bg-stng border text-white align-middle">
                <small>Prioridad</small>
              </td>
              <td className="bg-stng border text-white align-middle">
                <small>Fecha</small>
              </td>
            </tr>
          </UITableHead>
          {samples && (
            <UITableBody>
              <tr>
                <td className="py-3">
                  <input
                    type="checkbox"
                    onChange={handleAllSamplesCheckboxChange}
                  />
                </td>
                <td className="py-3">
                  <strong>Todos</strong>
                </td>
                <td colspan="5" className="py-3">
                  <strong>Resultados por correlativo</strong>
                </td>
              </tr>
              {samples.map(
                ({ sample, component, lubricant, client }, index) => (
                  <tr key={`table-body-${index}`}>
                    <td>
                      <input
                        type="checkbox"
                        checked={!!labels.samples[index]}
                        onChange={(event) =>
                          handleSamplesCheckboxChange(event, index)
                        }
                      />
                    </td>
                    <td> {sample.id} </td>
                    <td>{component.tag}</td>
                    <td>{lubricant.name}</td>
                    <td>{client}</td>
                    <td>{sample.priority}</td>
                    <td>{sample.date}</td>
                  </tr>
                ),
              )}
            </UITableBody>
          )}
          {massive && (
            <UITableBody>
              <tr>
                <td className="py-3">
                  <input
                    type="checkbox"
                    onChange={handleAllMassiveCheckboxChange}
                  />
                </td>
                <td className="py-3">
                  <strong>Todos</strong>
                </td>
                <td colspan="6" className="py-3">
                  <strong>Resultados por correlativo masivo</strong>
                </td>
              </tr>
              {massive.map(
                ({ sample, component, lubricant, client }, index) => (
                  <tr key={`table-body-${index}`}>
                    <td>
                      <input
                        type="checkbox"
                        checked={!!labels.massive[index]}
                        onChange={(event) =>
                          handleMassiveCheckboxChange(event, index)
                        }
                      />
                    </td>
                    <td> {sample.id} </td>
                    <td>{component.tag}</td>
                    <td>{lubricant.name}</td>
                    <td>{client}</td>
                    <td>{sample.priority}</td>
                    <td>{sample.date}</td>
                  </tr>
                ),
              )}
            </UITableBody>
          )}
        </UITable>
      </UIForm>
    )
  }

  return <UILoading />
}

export default SampleFormInsertMassiveTabEscelTable
