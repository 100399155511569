import { gql } from '../../../../../hooks'

export const GET_SAMPLE_LOG = gql`
  query SampleLogs($id: ID!) {
    t6SampleLogs(id: $id) {
    }
  }
`

export const NULL_SAMPLE = gql`
  mutation NullSample($id: ID!) {
    t6NullSample(id: $id) {
    }
  }
`

export const REJECT_SAMPLE = gql`
  mutation RejectSample($id: ID!) {
    t6RejectSample(id: $id) {
    }
  }
`
