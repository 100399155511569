import React from 'react'
import { Col, Row } from 'react-bootstrap'

// Components
import UIForm from '../../../UI/FormReload'
import UILoading from '../../../UI/Loading'

import TribologySamplesReportInfoTop from '../../../Tribology/Samples/Report/Info/Top'
import TribologySamplesReportInfoBottom from '../../../Tribology/Samples/Report/Info/Bottom'
import TribologySamplesReportForm from '../../../Tribology/Samples/Report/Form'
import TribologySamplesReportGraph from '../../../Tribology/Samples/Report/Graph'
import TribologySamplesReportLogics from '../../../Tribology/Samples/Report/Logics'

// Hooks
import { useQuery, useState, useHistory, useMutation } from '../../../../hooks'

// Queries
import { GET_SAMPLE_LOGIC, SAVE_SAMPLE, PROPOSE_SAMPLE } from './queries'

import oe from '../../../../common/object/extractor'

const TribologySamplesReport = ({ id }) => {
  const history = useHistory()

  //  const [logics, setLogicsState] = useState({})
  const [values, setValuesState] = useState()

  const { data, loading } = useQuery(GET_SAMPLE_LOGIC, {
    fetchPolicy: 'network-only',
    variables: { id, filters: [], noise: 1 },
    // onSuccess: ({ data }) => {
    //   if (data) setLogicsState(data)

    //   // if (data && data.response) {
    //   //   const {
    //   //     id_anomaly_type = '1',
    //   //     id_sample_condition = '1',
    //   //     id_sample_tracking_state = '1',
    //   //     recommendation = '',
    //   //   } = data.response

    //   //   setLogicsState({
    //   //     id,
    //   //     recommendation,
    //   //     anomalyType: { id: id_anomaly_type },
    //   //     trackingState: { id: id_sample_tracking_state },
    //   //     condition: { id: id_sample_condition },
    //   //   })
    //   // }
    // },
    onErrorMessage: `No se pudo cargar la lógica`,
  })

  const [t4ProposeSample] = useMutation(PROPOSE_SAMPLE, {
    fetchPolicy: 'no-cache',
    onSuccess: () => {
      history.push(`/tribology/samples/report/pdf/${id}`)
    },
  })

  const [t4SaveSample] = useMutation(SAVE_SAMPLE, {
    fetchPolicy: 'no-cache',
    onSuccess: () => {
      setValuesState()
      //      history.push(`/tribology/samples/report/pdf/${id}`)
    },
  })

  if (loading) return <UILoading />

  const { response, logic, type } =
    data && data.t4SampleLogics && data.t4SampleLogics.data

  const handleChange = (values) => {
    setValuesState(values)
  }

  const handleSubmit = (values) => {
    t4ProposeSample({
      variables: { id, values, logic },
    })
  }

  const handleSave = () => {
    t4SaveSample({ variables: { id, values, logic } })
  }

  // const handleLogicsChange = (logics) => {
  //    setLogicsState(logics)
  // }

  return (
    <>
      <Row className="m-4 text-center">
        <Col>
          <TribologySamplesReportLogics logic={logic} type={type} />
        </Col>
      </Row>
      <UIForm onSubmit={handleSubmit} onChange={handleChange}>
        <Row className="m-4">
          <Col xs={12}>
            <TribologySamplesReportInfoTop id={id} logics={response} />
          </Col>
          <Col lg={8} className="mb-3">
            <TribologySamplesReportForm
              id={id}
              onSave={handleSave}
              values={values}
            />
          </Col>
          <Col lg={4}>
            <TribologySamplesReportGraph id={id} />
          </Col>
          <Col xs={12}>
            <TribologySamplesReportInfoBottom id={id} logics={response} />
          </Col>
        </Row>
      </UIForm>
    </>
  )
}

export default TribologySamplesReport
