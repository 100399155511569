import React from 'react'

import AdministrationInfo from '../..'

// Queries
import { READ_POINTS, DELETE_POINTS } from './queries'

const AdministrationInfoSamplingPoints = ({ ...rest }) => (
  <AdministrationInfo
    queries={{ read: READ_POINTS, delete: DELETE_POINTS }}
    title="Punto de muestreo"
    {...rest}
  />
)

export default AdministrationInfoSamplingPoints
