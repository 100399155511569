import { gql } from '../../../../../hooks'

export const READ_STATES = gql`
  query AdministrationReadAnalysisStates($id: ID!) {
    aRead(path: "analysis/states", id: $id) {
    }
  }
`

export const DELETE_STATES = gql`
  mutation AdministrationDeleteAnalysisStates($id: ID!) {
    aDelete(path: "analysis/states", id: $id) {
    }
  }
`
