import React from 'react'

import { AdministrationContainerTab } from '..'

import Lubricants from './Lubricants'
import Protocols from './Protocols'
import Brands from './Brands'
import Models from './Models'
import ChangeRefillMotives from './ChangeRefillMotives'

const subtabs = [
  { name: 'Lubricantes', subtab: 'lubricants', component: Lubricants },
  {
    name: 'Motivo cambio / relleno',
    subtab: 'changeRefillMotives',
    component: ChangeRefillMotives,
  },
  { name: 'Protocolos (AFQ)', subtab: 'protocols', component: Protocols },
  { name: 'Marcas', subtab: 'brands', component: Brands },
  { name: 'Modelos', subtab: 'models', component: Models },
]

const AdministrationContainerLubricant = ({ hash }) => (
  <AdministrationContainerTab subtabs={subtabs} />
)

export default AdministrationContainerLubricant
