import React from 'react'
import { Row, Col, Form, Accordion, Card, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

// Hooks
import { useQuery } from '../../../../hooks'

// Components
import UIButton from '../../../UI/Button'
import UILoading from '../../../UI/Loading'
import UIError from '../../../UI/Error'
import UIEmpty from '../../../UI/Empty'
import UIFormSeparation from '../../../UI/FormReload/Separation'
import UITable from '../../../UI/Table'
import UITableHead from '../../../UI/Table/Head'
import UITableBody from '../../../UI/Table/Body'

import InfoHeader from '../../Container/InfoHeader'

// Excel export
// import { Download, processData } from './download'
import ButtonDownload from './download'

// Queries
import { GET_REPORT } from './queries'

const ReportTableData = ({ filter, onFilterChange }) => {
  const { id_client, id_site, id_machine_type, id_machine, from, to } = filter

  // --------------------------------------------------------------------------
  //
  // Query report
  //
  // --------------------------------------------------------------------------

  const { data, loading } = useQuery(GET_REPORT, {
    variables: { ...filter },
    onErrorMessage: `Error al intentar obtener reporte`,
  })

  // --------------------------------------------------------------------------
  //
  // Render
  //
  // --------------------------------------------------------------------------

  if (loading) return <UILoading />

  if (data && data.reportData) {
    // ------------------------------------------------------------------------
    //
    // Info Body
    //
    // ------------------------------------------------------------------------

    const { error } = data.reportData

    let InfoBody
    let showExcelButton

    // Errors?
    // ------------------------------------------------------------------------
    if (error) {
      showExcelButton = false
      InfoBody = (
        <div className="text-center  py-5 border rounded">
          <FontAwesomeIcon
            size="10x"
            color="lightgray"
            className="mr-2"
            icon={faBan}
          />

          <h5 className="text-muted font-weight-light mt-5">{error.message}</h5>
        </div>
      )
    }
    // No errors
    // ------------------------------------------------------------------------
    else {
      const { info, results } = data.reportData.data

      // Empty?
      // ----------------------------------------------------------------------
      if (!results || results.length === 0) {
        showExcelButton = false
        InfoBody = <UIEmpty />
      }
      // Not empty
      // ----------------------------------------------------------------------
      else {
        showExcelButton = true
        const classNameHeader = 'bg-stng border text-white align-middle'

        InfoBody = (
          <Accordion defaultActiveKey="0">
            {results.map(({ name, rows }, index) => (
              <Card key={`accordion-${index}`}>
                <Accordion.Toggle as={Card.Header} eventKey={`${index}`}>
                  {name}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={`${index}`}>
                  <Card.Body>
                    <UITable borderless>
                      <UITableHead className={classNameHeader}>
                        {[
                          'Tipo de equipo',
                          'Equipo',
                          'Componente',
                          'TAG',
                          'Correlativo',
                          'HK Equipo',
                          'HK Componente',
                          'HK Lubricante',
                          'Fecha muestreo',
                        ].map((name) => (
                          <small>{name}</small>
                        ))}
                      </UITableHead>
                      <UITableBody>
                        {rows.map((row) => {
                          return (
                            <tr key={`row-${row.sample_id}`}>
                              <td>{row.machine_type_name}</td>
                              <td>{row.machine_name}</td>
                              <td>{row.component_name}</td>
                              <td>{row.component_tag}</td>
                              <td>{row.sample_id}</td>
                              <td>{row.machine_continuity}</td>
                              <td>{row.component_continuity}</td>
                              <td>{row.lubricant_continuity}</td>
                              <td>{row.date_sampling}</td>
                            </tr>
                          )
                        })}
                      </UITableBody>
                    </UITable>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ))}
          </Accordion>
        )
      }
    }

    const handleBack = () => onFilterChange()

    return (
      <>
        <Form.Group as={Row} className="m-1">
          <Col className="d-flex">
            <UIButton type="submit" className="mx-2" onClick={handleBack}>
              Atrás
            </UIButton>
            {showExcelButton && <ButtonDownload filter={filter} />}
          </Col>
        </Form.Group>
        <Row className="m-1">
          <Col xs={12}>
            <UIFormSeparation />
          </Col>
          {!error && (
            <Col xs={12}>
              <InfoHeader
                info={{
                  ...data.reportData.data.info,
                  from,
                  to,
                }}
              />
            </Col>
          )}
          <Col xs={12}>{InfoBody}</Col>
        </Row>
      </>
    )
  }

  return <UIError />
}

export default ReportTableData
