import React from 'react'

// Hooks
import { useGlobal } from '../../../../../hooks'

import { AdministrationList } from '../../../List'

// Queries
import { DELETE_CHANGE_MOTIVES, LIST_CHANGE_MOTIVES } from './queries'

const queries = { list: LIST_CHANGE_MOTIVES, delete: DELETE_CHANGE_MOTIVES }

const AdministrationListComponentChangeMotives = () => {
  const [{ me }] = useGlobal()

  const no = ![1, 2].includes(me.id_role)

  return (
    <AdministrationList
      queries={queries}
      noCreate={no}
      noUpdate={no}
      noDelete={no}
    />
  )
}

export default AdministrationListComponentChangeMotives
