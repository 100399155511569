import React from 'react'
import moment from 'moment'
import ExcelJS from 'exceljs/dist/es5/exceljs.browser'
// import * as ExcelJS from 'exceljs/dist/exceljs'

// Components
import UIButton from '../../../UI/Button'

// Hooks
import { useLazyQuery } from '../../../../hooks'

// Queries
import { GET_EXCEL } from './queries'

// Config
import config from '../../../../config'

const { APP } = config

// ----------------------------------------------------------------------------
//
// Building Excel
//
// ----------------------------------------------------------------------------

const buildExcel = ({ data, filter }) => {
  const { info, results } = data
  const { from, to } = filter

  const workbook = new ExcelJS.Workbook()

  workbook.creator = 'Servicios Tribológicos'

  // ------------------------------------------------------------------------
  // Helpers
  // ------------------------------------------------------------------------

  const border = (positions = {}) => {
    const { x, y, t, r, l, b } = positions

    const style = 'thin'
    const border = {}

    if (x) {
      border.left = { style }
      border.right = { style }
    }
    if (y) {
      border.top = { style }
      border.bottom = { style }
    }
    if (t) border.top = { style }
    if (r) border.right = { style }
    if (b) border.bottom = { style }
    if (l) border.left = { style }

    if (!Object.keys(positions).length) {
      border.top = { style: 'thin' }
      border.left = { style: 'thin' }
      border.bottom = { style: 'thin' }
      border.right = { style: 'thin' }
    }

    return border
  }

  const fill = ({ argb = 'FFF7F2E0' } = {}) => ({
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb },
  })
  // ------------------------------------------------------------------------
  // Excel buiilding
  // ------------------------------------------------------------------------

  // Create sheet
  // const sheet = workbook.addWorksheet(name.replace(/[:\\\/\?\*\[\]#]/, ''))
  const sheet = workbook.addWorksheet('Muestras')

  // Insert info header
  let row
  let o = 0 // offset
  const r = 2
  const c = 2

  row = sheet.getRow(r + 0)
  row.getCell(c + 0).value = 'Periodo :'
  row.getCell(c + 0).border = border({ l: true, t: true })
  row.getCell(c + 0).fill = fill()
  row.getCell(c + 0).alignment = { horizontal: 'right' }
  row.getCell(c + 0).font = { bold: true }
  row.getCell(c + 1).value = `${from} ~ ${to}`
  row.getCell(c + 1).border = border({ r: true, t: true })
  row.getCell(c + 1).fill = fill()
  row = sheet.getRow(r + 1)
  row.getCell(c + 0).value = 'Encargado :'
  row.getCell(c + 0).border = border({ l: true, b: true })
  row.getCell(c + 0).fill = fill()
  row.getCell(c + 0).alignment = { horizontal: 'right' }
  row.getCell(c + 0).font = { bold: true }
  row.getCell(c + 1).value = info.responsible
  row.getCell(c + 1).border = border({ r: true, b: true })
  row.getCell(c + 1).fill = fill()

  row = sheet.getRow(r + 3)
  row.alignment = { vertical: 'middle', horizontal: 'center' }
  row.getCell(c + 0).value = 'N° muestra'
  row.getCell(c + 0).border = border()
  row.getCell(c + 0).fill = fill()
  row.getCell(c + 1).value = 'Fecha recepción'
  row.getCell(c + 1).border = border()
  row.getCell(c + 1).fill = fill()
  row.getCell(c + 2).value = 'Fecha ensayo'
  row.getCell(c + 2).border = border()
  row.getCell(c + 2).fill = fill()
  row.getCell(c + 3).value = 'Ensayos re-muestreados'
  row.getCell(c + 3).border = border()
  row.getCell(c + 3).fill = fill()
  row.getCell(c + 4).value = 'Repetición de ensayo'
  row.getCell(c + 4).border = border()
  row.getCell(c + 4).fill = fill()

  results.forEach((row, index) => {
    const { sample_id, date_receive, date_essay, essays, resampled } = row

    row = sheet.getRow(r + 4 + o + index)
    row.alignment = { vertical: 'middle', horizontal: 'center' }
    row.getCell(c + 0).value = {
      text: sample_id,
      // hyperlink: `https://app.stng.cl//tribology/samples/report/pdf/${sample_id}`,
      hyperlink: `${window.location.origin}/tribology/samples/report/pdf/${sample_id}`,
      tooltip: `Ver informe ${sample_id}`,
    }
    const value =
      resampled === 1
        ? 'Valor modificado'
        : resampled === 2
        ? 'Valor mantenido'
        : 'Valor original'

    row.getCell(c + 0).border = border()
    row.getCell(c + 1).value = date_receive
    row.getCell(c + 1).border = border()
    row.getCell(c + 2).value = date_essay
    row.getCell(c + 2).border = border()
    row.getCell(c + 3).value = essays
    row.getCell(c + 3).border = border()
    row.getCell(c + 4).value = value
    row.getCell(c + 4).border = border()
    if (resampled)
      row.getCell(c + 4).fill = fill({
        argb: resampled === 1 ? 'FF00FF00' : 'FF00FFFF',
      })
  })

  // Make the cells width fit automátically
  sheet.columns.forEach((column) => {
    var dataMax = 0
    column.eachCell({ includeEmpty: true }, (cell) => {
      var columnLength = cell && cell.value ? cell.value.length + 5 : 0
      if (columnLength > dataMax) {
        dataMax = columnLength
      }
    })
    column.width = dataMax < 10 ? 10 : dataMax
  })

  sheet.getColumn(c + 6).width = 100
  sheet.getColumn(c + 6).alignment = {
    vertical: 'middle',
    horizontal: 'center',
    wrapText: true,
  }
  sheet.getColumn(c + 7).width = 100
  sheet.getColumn(c + 7).alignment = {
    vertical: 'middle',
    horizontal: 'center',
    wrapText: true,
  }

  // Download excel file!
  workbook.xlsx.writeBuffer().then(function (data) {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const url = window.URL.createObjectURL(blob)
    const anchor = document.createElement('a')
    anchor.href = url
    anchor.download = 'Reporte - Re-muestreo.xlsx'
    anchor.click()
    // anchor.dispatchEvent(new MouseEvent('click')) // NOTE https://github.com/exceljs/exceljs/issues/354
    window.URL.revokeObjectURL(url)
  })
}

// ----------------------------------------------------------------------------
//
// Main render
//
// ----------------------------------------------------------------------------

const ButtonDownload = ({ filter }) => {
  const [getExcel, { loading, data }] = useLazyQuery(GET_EXCEL, {
    variables: filter,
  })

  if (data && data.reportReSampledExcel && data.reportReSampledExcel.data) {
    buildExcel({ data: data.reportReSampledExcel.data, filter })
  }

  const handleClick = () => {
    getExcel()
  }

  return (
    <UIButton onClick={handleClick} disabled={loading}>
      <img src="/excel.png" height="20" alt="Excel" />
    </UIButton>
  )
}

export default ButtonDownload
