import React from 'react'

// Hooks
import { useState, useEffect } from '../../../../../hooks'

// Components
import UIGraphLine from '../../../../UI/Graph/Line'
import UILoading from '../../../../UI/Loading'

const UIGraphLineResult = (props) => {
  const [data, setDataState] = useState()

  useEffect(() => {
    const colors = { main: '#32CD32', lmx: '#EFC700', lcx: '#EE0000' }
    const data = []

    const length = props.data.length
    const [min, max] = props.data
      .sort((a, b) => {
        if (a.x > b.x) return 1
        if (b.x > a.x) return -1
        return 0
      })
      .filter((point, index) => [0, length - 1].includes(index))
      .map(({ x }) => x)

    if (props.lsc && !isNaN(props.lsc))
      data.push({
        id: 'lsc',
        color: colors.lcx,
        // enablePoints: false,
        // tooltip: null,
        data: [
          { x: min, y: props.lsc },
          { x: max, y: props.lsc },
        ],
      })

    if (props.lsm && !isNaN(props.lsm))
      data.push({
        id: 'lsm',
        color: colors.lmx,
        data: [
          { x: min, y: props.lsm },
          { x: max, y: props.lsm },
        ],
      })

    if (props.lim && !isNaN(props.lim))
      data.push({
        id: 'lim',
        color: colors.lmx,
        data: [
          { x: min, y: props.lim },
          { x: max, y: props.lim },
        ],
      })

    if (props.lic && !isNaN(props.lic))
      data.push({
        id: 'lic',
        color: colors.lcx,
        data: [
          { x: min, y: props.lic },
          { x: max, y: props.lic },
        ],
      })

    data.push({
      id: 'data',
      color: colors.main,
      data: props.data.filter(({ y }) => !!y),
    })

    setDataState(data)
  }, [props.data, props.lsc, props.lsm, props.lim, props.lic])

  return data ? (
    <UIGraphLine
      data={data}
      enablePointLabel={true}
      pointLabel={(e) => e.label}
      theme={{
        dots: {
          text: {
            fill: '#00bf00',
            fontSize: 12,
          },
        },
      }}
      pointLabelYOffset={-10}
    />
  ) : (
    <UILoading />
  )
}

export default UIGraphLineResult
