import { gql } from '../../../../../hooks'

export const READ_SUGGESTIONS = gql`
  query AdministrationReadAnalysisSuggestions($id: ID!) {
    aRead(path: "analysis/suggestions", id: $id) {
    }
  }
`

export const DELETE_SUGGESTIONS = gql`
  mutation AdministrationDeleteAnalysisSuggestions($id: ID!) {
    aDelete(path: "analysis/suggestions", id: $id) {
    }
  }
`
