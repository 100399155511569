import { gql } from '../../../../../hooks'

export const READ_TYPES = gql`
  query AdministrationReadComponentTypes($id: ID!) {
    aRead(path: "component/types", id: $id) {
    }
  }
`

export const DELETE_TYPES = gql`
  mutation AdministrationDeleteComponentTypes($id: ID!) {
    aDelete(path: "component/types", id: $id) {
    }
  }
`
