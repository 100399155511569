import gql from 'graphql-tag'

export const GET_REPORT = gql`
  query GetReportManaging(
    $id_client: ID
    $id_site: ID
    $id_machine_type: ID
    $id_machine: ID
    $id_component: ID
    $emission_type: String
    $from: String!
    $to: String!
    $next_level: Boolean
  ) {
    reportManaging(
      id_client: $id_client
      id_site: $id_site
      id_machine_type: $id_machine_type
      id_machine: $id_machine
      id_component: $id_component
      emission_type: $emission_type
      from: $from
      to: $to
      next_level: $next_level
    )
  }
`
