import { gql } from '../../../../../hooks'

export const READ_ANOMALY_TYPES = gql`
  query AdministrationReadAnalysisAnomalyTypes($id: ID!) {
    aRead(path: "analysis/anomaly/types", id: $id) {
    }
  }
`

export const DELETE_ANOMALY_TYPES = gql`
  mutation AdministrationDeleteAnalysisAnomalyTypes($id: ID!) {
    aDelete(path: "analysis/anomaly/types", id: $id) {
    }
  }
`
