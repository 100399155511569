import React from 'react'

// Components
import AdministrationListSamplingReSampling from '../../../../Administration/List/Sampling/ReSampling'
import AdministrationInfoSamplingReSampling from '../../../../Administration/Info/Sampling/ReSampling'
import AdministrationFormSamplingReSampling from '../../../../Administration/Form/Sampling/ReSampling'

import { AdministrationContainer } from '../..'

const AdministrationContainerSamplingReSampling = () => (
  <AdministrationContainer
    form={AdministrationFormSamplingReSampling}
    info={AdministrationInfoSamplingReSampling}
    list={AdministrationListSamplingReSampling}
  />
)

export default AdministrationContainerSamplingReSampling
