import { gql } from '../../../../../hooks'

export const READ_SITES = gql`
  query AdministrationReadClientSites($id: ID!) {
    aRead(path: "client/sites", id: $id) {
    }
  }
`

export const UPDATE_SITES = gql`
  mutation AdministrationUpdateClientSites($id: ID!, $data: JSONObject) {
    aUpdate(path: "client/sites", id: $id, data: $data) {
    }
  }
`

export const CREATE_SITES = gql`
  mutation AdministrationCreateClientSites($data: JSONObject) {
    aCreate(path: "client/sites", data: $data) {
    }
  }
`

export const LIST_SITES = gql`
  query AdministrationListClientSites(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(path: "client/sites", filter: $filter, page: $page, limit: $limit) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`
