import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faRedo, faTrash } from '@fortawesome/free-solid-svg-icons'

// Hooks
import { useState, useTrigger } from '../../../../../hooks'

// Components
import UIPanelInfoTable from '../../../../UI/Panel/Info/Table'
import UIButton from '../../../../UI/Button'
import UIModal from '../../../../UI/Modal'
import UIFormInputText from '../../../../UI/FormReload/Input/Text'
import UIFormInputTextarea from '../../../../UI/FormReload/Input/Textarea'
import UIFormInputCheckbox from '../../../../UI/FormReload/Input/Checkbox'
import UIFormInputSelect from '../../../../UI/FormReload/Input/Select'

// Common
import { oe } from '../../../../../common/object/extractor'

import { AdministrationForm } from '../..'

// Queries
// Queries
import {
  READ_CLIENTS,
  CREATE_CLIENTS,
  UPDATE_CLIENTS,
  LIST_CLIENTS,
  LIST_COUNTRIES,
  LIST_REGIONS,
  LIST_CITIES,
} from './queries'

const queries = {
  read: READ_CLIENTS,
  create: CREATE_CLIENTS,
  update: UPDATE_CLIENTS,
  list: LIST_CLIENTS,
}

const CustomForm = ({ action, data, id }) => {
  const [apiKey, setApiKeyState] = useState(oe(data, ['apiKey', 'name']) || '')

  const [idCountry, setIdCountryState] = useState(81)
  const [idRegion, setIdRegionState] = useState(1664)
  const [idCity, setIdCityState] = useState(439836)

  const [modalReGenerateTrigger, setModalReGenerateTrigger] = useTrigger()
  const [modalDeleteTrigger, setModalDeleteTrigger] = useTrigger()

  const handleCountryChange = (event) => {
    setIdCountryState(event.target.value)
    setIdRegionState()
    setIdCityState()
  }

  const handleRegionChange = (event) => {
    setIdRegionState(event.target.value)
    setIdCityState()
  }

  const handleCityChange = (event) => {
    setIdCityState(event.target.value)
  }

  const generateKey = () =>
    [...Array(64)]
      .map(() => Math.floor(Math.random() * 16).toString(16))
      .join('')

  const handleGenerateAPIKey = () => {
    setApiKeyState(generateKey())
  }

  const handleReGenerateAPIKey = () => {
    setModalReGenerateTrigger()
  }

  const handleDeleteAPIKey = () => {
    setModalDeleteTrigger()
  }

  const handleAcceptDeleteModal = () => {
    setApiKeyState('')
  }

  const handleAcceptReGenerateModal = () => {
    setApiKeyState(generateKey())
  }

  return (
    <UIPanelInfoTable>
      {[
        action === 'update'
          ? [
              'ID',
              <UIFormInputText inline name="id" placeholder={id} disabled />,
            ]
          : [],
        [
          'Nombre',
          <UIFormInputText
            name="name"
            initialValue={oe(data, ['name'])}
            placeholder={oe(data, ['name'])}
            required
          />,
        ],
        [
          'Razón social',
          <UIFormInputText
            name="registered_name"
            initialValue={oe(data, ['registeredName'])}
            placeholder={oe(data, ['registeredName'])}
            required
          />,
        ],
        [
          'Activo',
          <UIFormInputCheckbox
            name="active"
            initialValue={oe(data, ['active'], true)}
          />,
        ],
        [
          'RUT',
          <UIFormInputText
            name="rut"
            initialValue={oe(data, ['rut'])}
            placeholder={oe(data, ['rut'])}
            required
          />,
        ],
        [
          'DV',
          <UIFormInputText
            name="dv"
            initialValue={oe(data, ['dv'])}
            placeholder={oe(data, ['dv'])}
            required
          />,
        ],
        [
          'Dirección',
          <UIFormInputText
            name="legal_address"
            initialValue={oe(data, ['legalAddress'])}
            placeholder={oe(data, ['legalAddress'])}
            required
          />,
        ],
        [
          'País',
          <UIFormInputSelect
            name="id_country"
            query={LIST_COUNTRIES}
            onChange={handleCountryChange}
            defaultValue={idCountry}
            required
          >
            <option>Seleccione un país</option>
          </UIFormInputSelect>,
        ],
        [
          'Región',
          <UIFormInputSelect
            name="id_region"
            query={LIST_REGIONS}
            variables={{ id: idCountry }}
            skip={!idCountry}
            onChange={handleRegionChange}
            defaultValue={idRegion}
            disabled={!idCountry}
            required
          >
            <option>Seleccione una región</option>
          </UIFormInputSelect>,
        ],
        [
          'Ciudad',
          <UIFormInputSelect
            name="id_city"
            query={LIST_CITIES}
            variables={{ id: idRegion }}
            skip={!idRegion}
            onChange={handleCityChange}
            defaultValue={idCity}
            disabled={!idRegion}
            required
          >
            <option>Seleccione una ciudad</option>
          </UIFormInputSelect>,
        ],
        [
          'Llave de acceso (token)',
          <UIFormInputTextarea
            name="api_key"
            value={apiKey}
            readOnly
            append={
              apiKey === '' ? (
                <UIButton onClick={handleGenerateAPIKey} variant="success">
                  <FontAwesomeIcon icon={faPlus} />
                </UIButton>
              ) : (
                <>
                  <UIButton onClick={handleReGenerateAPIKey} variant="warning">
                    <FontAwesomeIcon icon={faRedo} />
                  </UIButton>
                  <UIButton onClick={handleDeleteAPIKey} variant="danger">
                    <FontAwesomeIcon icon={faTrash} />
                  </UIButton>
                  <UIModal
                    trigger={modalReGenerateTrigger}
                    onAccept={handleAcceptReGenerateModal}
                    head={'Confirmación'}
                    body={<>¿Está seguro que desea re-generar la API Key?</>}
                  />
                  <UIModal
                    trigger={modalDeleteTrigger}
                    onAccept={handleAcceptDeleteModal}
                    head={'Confirmación'}
                    body={<>¿Está seguro que desea eliminar la API Key?</>}
                  />
                </>
              )
            }
          />,
        ],
      ]}
    </UIPanelInfoTable>
  )
}

const AdministrationFormClientClients = () => (
  <AdministrationForm
    name={'cliente'}
    queries={queries}
    ignoreValues={['id', 'api_key']}
  >
    <CustomForm />
  </AdministrationForm>
)

export default AdministrationFormClientClients
