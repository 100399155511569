import React from 'react'
import { Row, Col } from 'react-bootstrap'

const UIPanelInfoSeparation = () => {
  return (
    <Row>
      <Col xs="12">
        <hr />
      </Col>
    </Row>
  )
}

export default UIPanelInfoSeparation
