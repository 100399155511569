import React from 'react'

import {
  Row,
  Col,
  Form,
  Table,
  Accordion,
  Card,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComment, faQuestion, faBan } from '@fortawesome/free-solid-svg-icons'

// Hooks
import { useGlobal, useState, useQuery, useHistory } from '../../../../../hooks'

// Components
import UILoading from '../../../../UI/Loading'
import UIEmpty from '../../../../UI/Empty'
import UIError from '../../../../UI/Error'
import UIButton from '../../../../UI/Button'
import UIFormSeparation from '../../../../UI/FormReload/Separation'
import UITable from '../../../../UI/Table'
import UITableHead from '../../../../UI/Table/Head'
import UITableBody from '../../../../UI/Table/Body'

import InfoHeader from '../../../Container/InfoHeader'

// Excel export
// import { Download, processData } from './download'
// import ButtonDownload from './download'
import Graph from './graph'

// Queries
import { GET_MACHINE } from './queries'

const ReportTableFleetStateMachine = ({ id, from, to }) => {
  const [{ me }] = useGlobal()

  const { laboratory } = me

  const history = useHistory()

  // --------------------------------------------------------------------------
  //
  // Query: Get all the info
  //
  // --------------------------------------------------------------------------
  const { data, error, loading } = useQuery(GET_MACHINE, {
    variables: { id, from, to },
  })

  if (data && data.reportFleetStateMachine) {
    const { error } = data.reportFleetStateMachine

    // ------------------------------------------------------------------------
    //
    // Info Body
    //
    // ------------------------------------------------------------------------

    let InfoBody
    let showExcelButton

    // Errors?
    // ------------------------------------------------------------------------
    if (error) {
      showExcelButton = false
      InfoBody = (
        <div className="text-center  py-5 border rounded">
          <FontAwesomeIcon
            size="10x"
            color="lightgray"
            className="mr-2"
            icon={faBan}
          />

          <h5 className="text-muted font-weight-light mt-5">{error.message}</h5>
        </div>
      )
    }
    // No errors
    // ------------------------------------------------------------------------
    else {
      const { info, results } = data.reportFleetStateMachine.data

      // Empty?
      // ----------------------------------------------------------------------
      if (results.length === 0) {
        showExcelButton = false
        InfoBody = <UIEmpty />
      }
      // Not empty
      // ----------------------------------------------------------------------
      else {
        showExcelButton = true
        const classNameHeader = 'bg-stng border text-white align-middle '

        const essaysMap = [
          { id: 3, name: 'Al' },
          { id: 17, name: 'Ba' },
          { id: 19, name: 'Ca' },
          { id: 2, name: 'Cr' },
          { id: 4, name: 'Cu' },
          { id: 1, name: 'Fe' },
          { id: 5, name: 'Pb' },
          { id: 21, name: 'Mg' },
          { id: 12, name: 'Mn' },
          { id: 20, name: 'Mo' },
          { id: 6, name: 'Ni' },
          { id: 22, name: 'P' },
          { id: 14, name: 'K' },
          { id: 15, name: 'Si' },
          { id: 7, name: 'Ag' },
          { id: 13, name: 'Na' },
          { id: 8, name: 'Sn' },
          { id: 9, name: 'Ti' },
          { id: 16, name: 'Zn' },
          { id: 18, name: 'Br' },
          { id: 29, name: 'PQ' },
          { id: 24, name: 'V100' },
          { id: 23, name: 'V40' },
          { id: laboratory === 'centinela' ? 55 : 47, name: 'ISO' },
          { id: 34, name: 'Holl' },
        ]

        InfoBody = (
          <Row>
            <Col xs={12}>
              <Accordion defaultActiveKey="0">
                {results.map(({ name, components }, index) => (
                  <Card key={`accordion-${index}`}>
                    <Accordion.Toggle as={Card.Header} eventKey={`${index}`}>
                      {name}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={`${index}`}>
                      <Card.Body>
                        <Graph data={components} />
                        <UITable borderless>
                          <UITableHead className={classNameHeader}>
                            {[
                              'Nº Muestra',
                              'Equipo',
                              'Componente',
                              'Tag',
                              'Fecha Muestreo',
                              'Fecha Aprobación',
                              'Hk Equipo',
                              'Hk Compo',
                              'Hk Aceite',
                              'Cambio Componente /Reparación Externa',
                              ...essaysMap.map(({ name }) => name),
                              'Recomendación',
                            ].map((name, index) => (
                              <small key={`headers-${index}`}>{name}</small>
                            ))}
                          </UITableHead>
                          <UITableBody>
                            {components.map((component, index) => {
                              const {
                                sample_id,
                                machine_name,
                                component_type_name,
                                component_tag,
                                sample_condition_id,
                                date_sampling,
                                date_proposed,
                                component_change,
                                component_continuity,
                                machine_continuity,
                                lubricant_continuity,
                                suggestion,
                                recommendation,
                                essays,
                              } = component

                              const className =
                                sample_condition_id === 3
                                  ? 'bg-danger text-light'
                                  : sample_condition_id === 2
                                  ? 'bg-warning text-dark'
                                  : 'bg-success text-dark'

                              return (
                                <tr key={`tr-${index}`}>
                                  <td>
                                    <Link
                                      to={`/tribology/samples/report/pdf/${sample_id}`}
                                      className={`text-decoration-none text-stng`}
                                      target="_blank"
                                    >
                                      {sample_id}
                                    </Link>
                                  </td>
                                  <td>{machine_name}</td>
                                  <td className={className}>
                                    {component_type_name}
                                  </td>
                                  <td>{component_tag}</td>
                                  <td>{date_sampling}</td>
                                  <td>{date_proposed}</td>
                                  <td>{machine_continuity}</td>
                                  <td>{component_continuity}</td>
                                  <td>{lubricant_continuity}</td>
                                  <td>{component_change ? 'CC' : '--'}</td>
                                  {essaysMap.map(({ id }) => {
                                    // Special case since Centinela has 2 PQ
                                    if (id === 29) id = essays[62] ? 62 : id

                                    if (essays[id]) {
                                      let { value, lsm, lsc, lim, lic } =
                                        essays[id]

                                      const isISO =
                                        (me.laboratory === 'centinela' &&
                                          id === 55) ||
                                        ([
                                          'antofagasta',
                                          'collahuasi',
                                          'quebradablanca',
                                        ].includes(me.laboratory) &&
                                          id === 47)

                                      if (!isISO) value = parseFloat(value)

                                      const hasLsc =
                                        !isISO &&
                                        !['', null, undefined].includes(lsc) &&
                                        value > lsc
                                      const hasLsm =
                                        !isISO &&
                                        !['', null, undefined].includes(lsm) &&
                                        value > lsm
                                      const hasLim =
                                        !isISO &&
                                        !['', null, undefined].includes(lim) &&
                                        value < lim
                                      const hasLic =
                                        !isISO &&
                                        !['', null, undefined].includes(lic) &&
                                        value < lic

                                      const className =
                                        hasLsc || hasLic
                                          ? 'bg-danger text-white'
                                          : hasLsm || hasLim
                                          ? 'bg-warning'
                                          : ''

                                      return (
                                        <td
                                          className={className}
                                          key={`sample-${sample_id}-essay-${id}`}
                                        >
                                          {isNaN(value)
                                            ? value
                                            : parseFloat(value).toFixed(1)}
                                        </td>
                                      )
                                    }
                                    return (
                                      <td
                                        key={`sample-${sample_id}-essay-${id}`}
                                      ></td>
                                    )
                                  })}
                                  <td>
                                    <OverlayTrigger
                                      placement="left"
                                      overlay={
                                        <Tooltip id="tooltip-disabled">
                                          {recommendation || suggestion}
                                        </Tooltip>
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={faComment}
                                        size="lg"
                                        color="grey"
                                      />
                                    </OverlayTrigger>
                                  </td>
                                </tr>
                              )
                            })}
                          </UITableBody>
                        </UITable>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ))}
              </Accordion>
            </Col>
          </Row>
        )
      }
    }

    const handleBack = () => {
      history.push('/reports/fleet_state')
    }

    return (
      <>
        <Form.Group as={Row} className="m-1">
          <Col className="d-flex">
            <UIButton type="submit" className="mx-2" onClick={handleBack}>
              Atrás
            </UIButton>
            {/*<ButtonDownload filter={filter} />*/}
            {showExcelButton && (
              <Link
                to={{
                  pathname: `/reports/fleet_state/machine/${id}/excel`,
                  search: new URLSearchParams({ from, to }).toString(),
                }}
                className="btn btn-stng"
                target="_blank"
              >
                <img src="/excel.png" height="20" alt="Excel" />
              </Link>
            )}
          </Col>
        </Form.Group>
        <Row className="m-1">
          <Col xs={12}>
            <UIFormSeparation />
          </Col>
          {!error && (
            <Col xs={12}>
              <InfoHeader info={data.reportFleetStateMachine.data.info} />
            </Col>
          )}
          <Col xs={12}>{InfoBody}</Col>
        </Row>
      </>
    )
  }

  if (loading) return <UILoading />

  if (error) return <UIError />

  return (
    <Row>
      <Col className="text-center align-self-center mt-5">
        <FontAwesomeIcon
          size="10x"
          icon={faQuestion}
          color="lightgray"
          className="mb-5"
        />
        <h1 className="text-secondary font-weight-light">
          No se encontró la máquina
        </h1>
        <hr className="w-50" />
        <p className="text-black-50 font-weight-lighter">
          Lo sentimos, no se encontró la máquina especificada
        </p>
      </Col>
    </Row>
  )
}

export default ReportTableFleetStateMachine
