import React from 'react'

// Hooks
import { useState } from '../../../../../../../hooks'

import Filter from './Filter'
import Table from './Table'

const SampleContainerInsertMassiveTabWeb = ({ onIdChange }) => {
  const [components, setComponentsState] = useState()

  const handleComponentsChange = (components) => {
    setComponentsState(components)
  }

  const handleIdChange = (id) => {
    onIdChange(id)
  }

  return components ? (
    <Table
      components={components}
      onComponentsChange={handleComponentsChange}
      onIdChange={handleIdChange}
    />
  ) : (
    <Filter onComponentsChange={handleComponentsChange} />
  )
}

export default SampleContainerInsertMassiveTabWeb
