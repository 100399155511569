import React from 'react'
import { Row, Col } from 'react-bootstrap'

// Hooks
import { useHistory, useParams, useMemo } from '../../hooks'

// Components
import SubstanceContainerLubricant from '../../components/Substance/Container/Lubricant'
import SubstanceContainerGrease from '../../components/Substance/Container/Grease'
import SubstanceContainerWaste from '../../components/Substance/Container/Waste'
import SubstanceContainerStorehouse from '../../components/Substance/Container/Storehouse'

const tabs = [
  {
    name: 'Uso de aceites',
    tab: 'lubricant',
    component: SubstanceContainerLubricant,
  },
  {
    name: 'Uso de grasas',
    tab: 'grease',
    component: SubstanceContainerGrease,
  },
  {
    name: 'Despacho de desechos',
    tab: 'waste',
    component: SubstanceContainerWaste,
  },
  {
    name: 'Lubricantes bodega',
    tab: 'storehouse',
    component: SubstanceContainerStorehouse,
  },
]

const PageSubstance = () => {
  const params = useParams()

  const history = useHistory()

  const activeTab = useMemo(() => {
    if (!params.tab || !tabs.map(({ tab }) => tab).includes(params.tab)) {
      history.replace({
        pathname: `/substance/${tabs[0].tab}`,
      })

      return tabs[0].tab
    }

    return params.tab
  }, [params.tab])

  const Content = useMemo(
    () => tabs.filter(({ tab }) => tab === activeTab)[0].component,
    [activeTab],
  )

  const handleClick = (tab) => {
    history.push(`/substance/${tab}`)
  }

  return (
    <Row>
      <Col>
        <ul className="nav nav-tabs font-weight-light" role="tablist">
          {tabs.map(({ name, tab }, index) => (
            <li
              className="nav-item"
              role="presentation"
              key={`page-admin-${index}`}
            >
              <a
                data-toggle="tab"
                role="button"
                className={`nav-link ${tab === activeTab ? 'active' : ''}`}
                onClick={() => handleClick(tab)}
              >
                {name}
              </a>
            </li>
          ))}
        </ul>
        <div className="tab-content">
          <div className="tab-pane fade show active" role="tabpanel">
            <Content />
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default PageSubstance
