import React from 'react'

// Hooks
import { useGlobal } from '../../../../../hooks'

import { AdministrationList } from '../../../List'

// Queries
import { DELETE_USERS, LIST_USERS } from './queries'

const table = [
  { th: 'ID', td: 'id' },
  { th: 'Rol', td: ['role', 'name'] },
  { th: 'Nombre', td: { pk1: 'name', pk2: 'surname_1', delimiter: ' ' } },
]

const queries = { list: LIST_USERS, delete: DELETE_USERS }

const AdministrationListUserUsers = () => {
  const [{ me }] = useGlobal()

  const no = ![1, 2].includes(me.id_role)

  return (
    <AdministrationList
      table={table}
      queries={queries}
      noCreate={no}
      noUpdate={no}
      noDelete={no}
    />
  )
}

export default AdministrationListUserUsers
