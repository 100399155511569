//
// Based on https://codingsans.com/blog/node-config-best-practices
//

const env = process.env

// if (!['development', 'production'].includes(env.NODE_ENV))
if (!['development', 'testing', 'production'].includes(env.REACT_APP_ENV))
  throw new Error(`The enviroment '${env.REACT_APP_ENV}' is not valid`)

// ----------------------------------------------------------------------------
// Development enviroment
// ----------------------------------------------------------------------------

const development = {
  ENV: 'development',
  APP: {
    HOST: env.REACT_APP_DEV_MODULE_HOST,
    PORT: env.REACT_APP_DEV_MODULE_PORT,
    BACK: {
      HOST: env.REACT_APP_DEV_MODULE_BACK_HOST,
      PORT: env.REACT_APP_DEV_MODULE_BACK_PORT,
    },
  },
  AWS: { BUCKET: env.REACT_APP_DEV_AWS_BUCKET_NAME },
  TABLEAU: {
    HOST: env.REACT_APP_DEV_TABLEAU_HOST,
    PORT: env.REACT_APP_DEV_TABLEAU_PORT,
  },
}

// ----------------------------------------------------------------------------
// Testing enviroment
// ----------------------------------------------------------------------------

const testing = {
  ENV: 'testing',
  APP: {
    HOST: env.REACT_APP_TEST_MODULE_HOST,
    PORT: env.REACT_APP_TEST_MODULE_PORT,
    BACK: {
      HOST: env.REACT_APP_TEST_MODULE_BACK_HOST,
      PORT: env.REACT_APP_TEST_MODULE_BACK_PORT,
    },
  },
  AWS: { BUCKET: env.REACT_APP_TEST_AWS_BUCKET_NAME },
  TABLEAU: {
    HOST: env.REACT_APP_TEST_TABLEAU_HOST,
    PORT: env.REACT_APP_TEST_TABLEAU_PORT,
  },
}

// ----------------------------------------------------------------------------
// Production enviroment
// ----------------------------------------------------------------------------

const production = {
  ENV: 'production',
  APP: {
    HOST: env.REACT_APP_PROD_MODULE_HOST,
    PORT: env.REACT_APP_PROD_MODULE_PORT,
    BACK: {
      HOST: env.REACT_APP_PROD_MODULE_BACK_HOST,
      PORT: env.REACT_APP_PROD_MODULE_BACK_PORT,
    },
  },
  AWS: { BUCKET: env.REACT_APP_PROD_AWS_BUCKET_NAME },
  TABLEAU: {
    HOST: env.REACT_APP_PROD_TABLEAU_HOST,
    PORT: env.REACT_APP_PROD_TABLEAU_PORT,
  },
}

const config = {
  development,
  testing,
  production,
}

export default config[env.REACT_APP_ENV]
