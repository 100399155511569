import React from 'react'

// Hooks
import { useLocation } from '../../../../../../hooks'

// Components
import ReportTableFleetStateMachineExcel from '../../../../Table/FleetState/Machine/Excel'

const ReportContainerFleetStateExcel = ({ id }) => {
  const search = useLocation().search

  return (
    <ReportTableFleetStateMachineExcel
      filter={{ id, ...Object.fromEntries(new URLSearchParams(search)) }}
    />
  )
}

export default ReportContainerFleetStateExcel
