import React from 'react'

import { AdministrationContainerTab } from '..'

// Hooks
import { useGlobal, useMemo } from '../../../../hooks'

import Samples from './Samples'
import AnalysisMethods from './AnalysisMethods'
import Procedures from './Procedures'
import Points from './Points'
import Essays from './Essays'
import EssayClassifications from './EssayClassifications'
import EssayUnits from './EssayUnits'
import ReSamping from './ReSampling'

const AdministrationContainerLubricant = () => {
  const [{ me }] = useGlobal()

  const subtabs = useMemo(() => {
    const subtabs = [
      {
        name: 'Método análisis',
        subtab: 'analysisMethods',
        component: AnalysisMethods,
      },
      {
        name: 'Procedimiento muestreo',
        subtab: 'procedures',
        component: Procedures,
      },
      { name: 'Puntos muestreo', subtab: 'points', component: Points },
      { name: 'Ensayos', subtab: 'essays', component: Essays },
      {
        name: 'Clasificación ensayo',
        subtab: 'classifications',
        component: EssayClassifications,
      },
      { name: 'Unidad medida', subtab: 'units', component: EssayUnits },
      { name: 'Muestras', subtab: 'samples', component: Samples },
    ]

    if ([1, 2, 4].includes(me.id_role))
      subtabs.push({
        name: 'Re-muestreo',
        subtab: 're-sampling',
        component: ReSamping,
      })

    return subtabs
  })

  return <AdministrationContainerTab subtabs={subtabs} />
}

export default AdministrationContainerLubricant
