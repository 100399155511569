import { gql } from '../../../../../hooks'

export const READ_PROTOCOLS = gql`
  query AdministrationReadLubricantProtocols($id: ID!) {
    aRead(path: "lubricant/protocols", id: $id) {
    }
  }
`

export const UPDATE_PROTOCOLS = gql`
  mutation AdministrationUpdateLubricantProtocols($id: ID!, $data: JSONObject) {
    aUpdate(path: "lubricant/protocols", id: $id, data: $data) {
    }
  }
`

export const CREATE_PROTOCOLS = gql`
  mutation AdministrationCreateLubricantProtocols($data: JSONObject) {
    aCreate(path: "lubricant/protocols", data: $data) {
    }
  }
`

export const LIST_PROTOCOLS = gql`
  query AdministrationListLubricantProtocols(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(
      path: "lubricant/protocols"
      filter: $filter
      page: $page
      limit: $limit
    ) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`

export const SEARCH_CLIENT = gql`
  query AdministrationSearchComponentClient {
    aSearch(path: "client", text: "", filter: { active: 1 }) {
    }
  }
`
