import React from 'react'

import { AdministrationForm } from '../..'

// Queries
import {
  READ_ANOMALY_TYPES,
  CREATE_ANOMALY_TYPES,
  UPDATE_ANOMALY_TYPES,
  LIST_ANOMALY_TYPES,
} from './queries'

const queries = {
  read: READ_ANOMALY_TYPES,
  create: CREATE_ANOMALY_TYPES,
  update: UPDATE_ANOMALY_TYPES,
  list: LIST_ANOMALY_TYPES,
}

const AdministrationFormAnalysisAnomalyTypes = () => (
  <AdministrationForm name={'tipo de anomalía'} queries={queries} />
)

export default AdministrationFormAnalysisAnomalyTypes
