import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArchive } from '@fortawesome/free-solid-svg-icons'

const UIEmpty = ({ message }) => {
  return (
    <div className="text-center  py-5 border rounded">
      <FontAwesomeIcon
        size="10x"
        color="lightgray"
        className="mr-2"
        icon={faArchive}
      />

      {message && (
        <p className="text-muted font-weight-light mt-3">{message}</p>
      )}
    </div>
  )
}

export default UIEmpty
