import { gql } from '../../../../../hooks'

export const READ_ANALYSIS_METHODS = gql`
  query AdministrationReadSamplingAnalysisMethods($id: ID!) {
    aRead(path: "sampling/analysis/methods", id: $id) {
    }
  }
`

export const DELETE_ANALYSIS_METHODS = gql`
  mutation AdministrationDeleteSamplingAnalysisMethods($id: ID!) {
    aDelete(path: "sampling/analysis/methods", id: $id) {
    }
  }
`
