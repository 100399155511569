import React from 'react'
import { Col, Row } from 'react-bootstrap'

// Hooks
import { useState } from '../../../../hooks'

// Side info
import TribologySamplesReciptList from '../../../Tribology/Samples/Recipt/List'
import TribologySamplesReciptInfo from '../../../Tribology/Samples/Recipt/Info'

const TribologySamplesRecipt = (props) => {
  const [id, setIdState] = useState()

  const handleIdChange = (id) => {
    setIdState(id)
  }

  if (id)
    return (
      <Row className="m-4">
        <Col lg={8} className="mb-3">
          <TribologySamplesReciptList onIdChange={handleIdChange} />
        </Col>
        <Col lg={4}>
          <TribologySamplesReciptInfo id={id} />
        </Col>
      </Row>
    )

  return (
    <Row className="m-4">
      <Col lg={12} className="mb-3">
        <TribologySamplesReciptList onIdChange={handleIdChange} />
      </Col>
    </Row>
  )
}

export default TribologySamplesRecipt
