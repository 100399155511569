import React, { forwardRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

import UIButton from '../../../../UI/Button'

import {
  useRef,
  useEffect,
  useMemo,
  useCallback,
  useState,
} from '../../../../../hooks'

import Input from '..'
import { useFormContext } from '../..'

const UIFormInputPassword = forwardRef((props, ref) => {
  const {
    value: propValue,
    initialValue: propInitialValue,
    defaultValue: propDefaultValue,
    onChange: propOnChange,
    onReset: propOnReset,
    ...rest
  } = props

  const isFirstRender = useRef(true)

  const context = useFormContext()

  const [value, setValueState] = useState(
    propValue !== undefined
      ? propValue
      : propInitialValue !== undefined
      ? propInitialValue
      : propDefaultValue !== undefined
      ? propDefaultValue
      : context.initialValues[props.name] !== undefined
      ? context.initialValues[props.name]
      : context.defaultValues[props.name] != undefined
      ? context.defaultValues[props.name]
      : '',
  )

  useEffect(() => {
    if (isFirstRender.current) isFirstRender.current = false
    else setValueState(propValue)
  }, [propValue])

  const defaultValue = useMemo(
    () =>
      propDefaultValue !== undefined
        ? propDefaultValue
        : context.defaultValues[props.name] !== undefined
        ? context.defaultValues[props.name]
        : '',
    [],
  )

  const [show, setShowState] = useState(false)

  const handleChange = (event) => {
    setValueState(event.target.value)
    propOnChange && propOnChange(event)
  }

  const handleReset = () => {
    setValueState(defaultValue)
    propOnReset && propOnReset()
  }

  const handleClick = () => setShowState((prev) => !prev)

  return (
    <Input
      {...rest}
      ref={ref}
      defaultValue={defaultValue}
      value={value}
      onChange={handleChange}
      onReset={handleReset}
      type={show ? 'text' : 'password'}
      append={
        <>
          <UIButton
            active={show}
            onClick={handleClick}
            variant="outline-secondary font-weight-light"
          >
            {show ? (
              <FontAwesomeIcon color="lightgray" icon={faEyeSlash} />
            ) : (
              <FontAwesomeIcon color="lightgray" icon={faEye} />
            )}
          </UIButton>
        </>
      }
    />
  )
})

export default UIFormInputPassword
