import React from 'react'

// Components
import UIPanelInfoBlock from '../../../../UI/Panel/Info/Block'
import UIPanelInfoTable from '../../../../UI/Panel/Info/Table'
import UIFormInputText from '../../../../UI/FormReload/Input/Text'
import UIFormInputInteger from '../../../../UI/FormReload/Input/Integer'
import UIFormInputSelect from '../../../../UI/FormReload/Input/Select'

// Common
import { oe } from '../../../../../common/object/extractor'

import AdministrationInfo from '../..'

// Queries
import { READ_COLLECTIONS, DELETE_COLLECTIONS } from './queries'

const Info = (data) => (
  <UIPanelInfoBlock title="Collectiono">
    <UIPanelInfoTable>
      {[
        ['ID', oe(data, ['id'])],
        ['Cobranza', oe(data, ['name'])],
        ['Valor', oe(data, ['value'])],
        ['Unidad', oe(data, ['unit'])],
      ]}
    </UIPanelInfoTable>
  </UIPanelInfoBlock>
)

const Form = () => (
  <>
    <UIFormInputInteger label="ID" name="id" />
    <UIFormInputText label="Cobranza" name="name" />
    <UIFormInputSelect label="Unidad" name="unit">
      <option value="Pesos">Pesos</option>
      <option value="UF">UF</option>
    </UIFormInputSelect>
  </>
)

const AdministrationInfoComponentCollections = ({ ...rest }) => (
  <AdministrationInfo
    queries={{ read: READ_COLLECTIONS, delete: DELETE_COLLECTIONS }}
    views={{ form: Form, info: Info }}
    {...rest}
  />
)

export default AdministrationInfoComponentCollections
