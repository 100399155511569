import { gql } from '../../../../../hooks'

export const READ_ESSAY_UNITS = gql`
  query AdministrationReadSamplingEssayUnits($id: ID!) {
    aRead(path: "sampling/essay/units", id: $id) {
    }
  }
`

export const UPDATE_ESSAY_UNITS = gql`
  mutation AdministrationUpdateSamplingEssayUnits($id: ID!, $data: JSONObject) {
    aUpdate(path: "sampling/essay/units", id: $id, data: $data) {
    }
  }
`

export const CREATE_ESSAY_UNITS = gql`
  mutation AdministrationCreateSamplingEssayUnits($data: JSONObject) {
    aCreate(path: "sampling/essay/units", data: $data) {
    }
  }
`

export const LIST_ESSAY_UNITS = gql`
  query AdministrationListSamplingEssayUnits(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(
      path: "sampling/essay/units"
      filter: $filter
      page: $page
      limit: $limit
    ) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`
