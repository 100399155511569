import gql from 'graphql-tag'

export const GET_SHIFT_INFO = gql`
  query GetShiftInfo($filters: JSONObject!) {
    t7ShiftChangeInfo(filters: $filters) {
      data
    }
  }
`

export const ENTER_SHIFT_CHANGE = gql`
  mutation EnterShiftChange($data: JSONObject!) {
    t7EnterShiftChange(data: $data) {
      data
    }
  }
`
