import React from 'react'

// Hooks
import { useParams } from '../../../../../hooks'

import ReportContainerFleetStateMachineExcel from '../../../../../components/Report/Container/FleetState/Machine/Excel'

const PageReportsFleetStateMachineExcel = ({ auth }) => {
  const { id } = useParams()

  return <ReportContainerFleetStateMachineExcel id={id} />
}

export default PageReportsFleetStateMachineExcel
