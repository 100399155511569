import React from 'react'
import { Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

// Hooks
import { useMemo, useState } from '../../../../../hooks'

// Components
import UIFormInputText from '../../../../UI/FormReload/Input/Text'
import UIFormInputEmail from '../../../../UI/FormReload/Input/Email'
import UIFormInputInteger from '../../../../UI/FormReload/Input/Integer'
import UIFormInputSelect from '../../../../UI/FormReload/Input/Select'
import UIFormSeparation from '../../../../UI/FormReload/Separation'
import UIPanelInfoTable from '../../../../UI/Panel/Info/Table'

// Common
import { oe } from '../../../../../common/object/extractor'

import { AdministrationForm } from '../..'

// Queries
import {
  READ_USERS,
  CREATE_USERS,
  UPDATE_USERS,
  LIST_USERS,
  LIST_ROLES,
  LIST_LABORATORIES,
  LIST_CLIENTS,
  LIST_SITES,
  LIST_MACHINE_TYPES,
} from './queries'

const queries = {
  read: READ_USERS,
  create: CREATE_USERS,
  update: UPDATE_USERS,
  list: LIST_USERS,
}

const CustomForm = ({ action, data, id }) => {
  const password = useMemo(() => Math.random().toString(36).slice(2), [])

  const [idRole, setIdRoleState] = useState(oe(data, ['role', 'id'], '5'))

  const [idLaboratory, setIdLaboratoryState] = useState(
    oe(data, ['laboratories'], '0'),
  )
  const [idClient, setIdClientState] = useState(oe(data, ['clients'], '0'))
  const [idSite, setIdSiteState] = useState(oe(data, ['sites'], '0'))
  const [idMachineType, setIdMachineTypeState] = useState(
    oe(data, ['machine_types'], '0'),
  )

  const handleRoleChange = (event) => {
    setIdRoleState(event.target.value)
  }

  const handleLaboratoryChange = (event) => {
    setIdLaboratoryState(event.target.value)
    setIdClientState('0')
    setIdSiteState('0')
    setIdMachineTypeState('0')
  }

  const handleClientChange = (event) => {
    setIdClientState(event.target.value)
    setIdSiteState('0')
    setIdMachineTypeState('0')
  }

  const handleSiteChange = (event) => {
    setIdSiteState(event.target.value)
    setIdMachineTypeState('0')
  }

  const handleMachineTypeChange = (event) => {
    setIdMachineTypeState(event.target.value)
  }

  const validator = (value) => {
    if ([null, undefined, ''].includes(value)) return 'Campo requerido'

    if (!/^[0-9kK]$/.test(value)) return 'Dígito verificador inválido'
  }

  return (
    <UIPanelInfoTable>
      {[
        action === 'update'
          ? [
              'ID',
              <UIFormInputText
                name="id"
                initialValue={id}
                placeholder={id}
                disabled
              />,
            ]
          : [],
        [
          'Nombre',
          <UIFormInputText
            name="name"
            required
            initialValue={oe(data, ['name'])}
            placeholder={oe(data, ['name'])}
          />,
        ],
        [
          'Apellido paterno',
          <UIFormInputText
            name="surname_1"
            initialValue={oe(data, ['surname_1'])}
            placeholder={oe(data, ['surname_1'])}
          />,
        ],
        [
          'Apellido materno',
          <UIFormInputText
            name="surname_2"
            initialValue={oe(data, ['surname_2'])}
            placeholder={oe(data, ['surname_2'])}
          />,
        ],
        [
          'RUT',
          <UIFormInputInteger
            name="run"
            required
            initialValue={oe(data, ['run'])}
            placeholder={oe(data, ['run'])}
          />,
        ],
        [
          'DV',
          <UIFormInputInteger
            name="dv"
            required
            initialValue={oe(data, ['dv'])}
            placeholder={oe(data, ['dv'])}
            validator={validator}
          />,
        ],
        [
          'Correo',
          <UIFormInputEmail
            name="email"
            initialValue={oe(data, ['email'])}
            placeholder={oe(data, ['email'])}
            required
          />,
        ],
        [
          'Sexo',
          <UIFormInputSelect name="sex" defaultValue={oe(data, ['sex'], 'M')}>
            <option value="M">Masculino</option>
            <option value="F">Femenino</option>
          </UIFormInputSelect>,
        ],
        [
          'Nombre de usuario',
          <UIFormInputText
            name="username"
            required
            initialValue={oe(data, ['username'])}
            placeholder={oe(data, ['username'])}
          />,
        ],
        action === 'create'
          ? [
              'Contraseña',
              <Card bg={'danger'} text={'white'} className="my-2">
                <Card.Header className="mr-4">
                  <UIFormInputText
                    name="password"
                    defaultValue={password}
                    disabled
                  />
                </Card.Header>
                <Card.Body>
                  <Card.Text className="text-center">
                    <p>
                      <FontAwesomeIcon
                        className="align-self-center mr-2"
                        icon={faExclamationCircle}
                      />
                      Contraseña temporal autogenerada
                    </p>
                    <p>
                      Ésta es la única vez que la contraseña será visible.
                      Porfavor copiarla y entregarla al nuevo usuario.
                    </p>
                  </Card.Text>
                </Card.Body>
              </Card>,
            ]
          : [],
        [
          'Rol',
          <UIFormInputSelect
            name="id_role"
            onChange={handleRoleChange}
            initialValue={idRole}
            query={LIST_ROLES}
          />,
        ],
        [
          'Laboratorio',
          <UIFormInputSelect
            name="laboratories"
            onChange={handleLaboratoryChange}
            query={LIST_LABORATORIES}
            value={idLaboratory}
          >
            <option value={'0'}>Todos</option>
          </UIFormInputSelect>,
        ],
        [
          'Cliente',
          <UIFormInputSelect
            name="clients"
            onChange={handleClientChange}
            query={LIST_CLIENTS}
            value={idClient}
            skip={idLaboratory === '0'}
            variables={{ id_laboratory: idLaboratory }}
          >
            <option value={'0'}>Todos</option>
          </UIFormInputSelect>,
        ],
        [5, '5', 6, '6', 7, '7', 8, '8'].includes(idRole)
          ? [
              'Faena',
              <UIFormInputSelect
                name="sites"
                onChange={handleSiteChange}
                query={LIST_SITES}
                value={idSite}
                skip={idClient === '0'}
                variables={{ id_laboratory: idLaboratory, id_client: idClient }}
              >
                <option value={'0'}>Todos</option>
              </UIFormInputSelect>,
            ]
          : [],
        [5, '5', 6, '6'].includes(idRole)
          ? [
              'Tipo equipo',
              <UIFormInputSelect
                name="machine_types"
                onChange={handleMachineTypeChange}
                query={LIST_MACHINE_TYPES}
                value={idMachineType}
                skip={idLaboratory === '0'}
                variables={{
                  id_laboratory: idLaboratory,
                  id_client: idClient,
                  id_site: idSite,
                }}
              >
                <option value={'0'}>Todos</option>
              </UIFormInputSelect>,
            ]
          : [],
        [
          'Activo',
          <UIFormInputSelect inline name="active" initialValue={1}>
            <option value={1}>Sí</option>
            <option value={0}>No</option>
          </UIFormInputSelect>,
        ],
      ]}
    </UIPanelInfoTable>
  )
}

const AdministrationFormUserUsers = () => (
  <AdministrationForm name={'usuario'} queries={queries}>
    <CustomForm />
  </AdministrationForm>
)

export default AdministrationFormUserUsers
