import React from 'react'

// Hooks
import { useGlobal } from '../../../../../hooks'

import { AdministrationList } from '../../../List'

// Queries
import { DELETE_ESSAY_UNITS, LIST_ESSAY_UNITS } from './queries'

const queries = {
  list: LIST_ESSAY_UNITS,
  delete: DELETE_ESSAY_UNITS,
}

const AdministrationListSamplingEssayUnits = () => {
  const [{ me }] = useGlobal()

  const no = ![1, 2].includes(me.id_role)

  return (
    <AdministrationList
      queries={queries}
      noCreate={no}
      noUpdate={no}
      noDelete={no}
    />
  )
}

export default AdministrationListSamplingEssayUnits
