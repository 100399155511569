import React from 'react'

import { AdministrationList } from '../../../List'

// Queries
import { DELETE_LIMITS, LIST_LIMITS } from './queries'

const table = [
  { th: 'ID', td: 'id' },
  { th: 'Tipo protocolo', td: ['type', 'name'] },
  { th: 'Protocolo', td: 'name' },
]

const queries = { list: LIST_LIMITS, delete: DELETE_LIMITS }

const AdministrationListAnalysisLimits = () => (
  <AdministrationList table={table} queries={queries} noDelete noCreate />
)

export default AdministrationListAnalysisLimits
