import React from 'react'

import { AdministrationList } from '../../../List'

// Queries
import { DELETE_TYPES, LIST_TYPES } from './queries'

const queries = { list: LIST_TYPES, delete: DELETE_TYPES }

const AdministrationListMachineTypes = () => (
  <AdministrationList queries={queries} />
)

export default AdministrationListMachineTypes
