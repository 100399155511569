import React from 'react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

// Components
import UILoading from '../../../../UI/Loading'
import UIError from '../../../../UI/Error'
import UIPanelInfo from '../../../../UI/Panel/Info'
import UIPanelInfoBlock from '../../../../UI/Panel/Info/Block'
import UIPanelInfoItem from '../../../../UI/Panel/Info/Item'

// Global reducer
import { useQuery } from '../../../../../hooks'

// Queries
import { GET_COMPONENT_INFO } from './queries'

const SampleInfoInsertProgrammed = ({
  idComponent,
  sample: insertedSample,
}) => {
  // --------------------------------------------------------------------------
  //
  // Query get sidepanel info
  //
  // --------------------------------------------------------------------------

  const { data, loading } = useQuery(GET_COMPONENT_INFO, {
    variables: { id_component: idComponent },
    onErrorMessage: `Hubo un error y no se pudo obtener la información del componente`,
  })

  if (loading) return <UILoading />

  if (data && data.t1ComponentInfo) {
    const {
      component,
      component_type,
      component_brand,
      component_model,
      machine,
      machine_type,
      machine_brand,
      machine_model,
      site,
      client,
      lubricant,
    } = data.t1ComponentInfo.data

    const { priority, sample } = insertedSample || {}

    return (
      <UIPanelInfo>
        {sample && priority && (
          <UIPanelInfoBlock title="Datos muestra">
            <UIPanelInfoItem name="Tipo de muestra">Programada</UIPanelInfoItem>
            <UIPanelInfoItem name="N° Muestra">{sample.id}</UIPanelInfoItem>
            <UIPanelInfoItem name="Nḿuero externo">
              {sample.external_number}
            </UIPanelInfoItem>
            <UIPanelInfoItem name="Orden de trabajo">
              {sample.work_order}
            </UIPanelInfoItem>
            <UIPanelInfoItem name="Fecha muestreo">
              {sample.date_sampling}
            </UIPanelInfoItem>
            <UIPanelInfoItem name="Fecha retiro">
              {sample.date_withdraw}
            </UIPanelInfoItem>
            <UIPanelInfoItem name="Fecha ingreso">
              {sample.date_entry}
            </UIPanelInfoItem>
            <UIPanelInfoItem name="H/K Equipo">
              {sample.machine_continuity}
            </UIPanelInfoItem>
            <UIPanelInfoItem name="H/K Componente">
              {sample.component_continuity}
            </UIPanelInfoItem>
            <UIPanelInfoItem name="H/K Luricante">
              {sample.lubricant_continuity}
            </UIPanelInfoItem>
            <UIPanelInfoItem name="Cantidad rellenos">
              {sample.number_of_refills}
            </UIPanelInfoItem>
            <UIPanelInfoItem name="Prioridad">{priority.name}</UIPanelInfoItem>
            <UIPanelInfoItem name="Comentario">
              {sample.comment}
            </UIPanelInfoItem>
          </UIPanelInfoBlock>
        )}
        <UIPanelInfoBlock title="Cliente">
          <UIPanelInfoItem name="Nombre">{client.name}</UIPanelInfoItem>
          <UIPanelInfoItem name="Faena">{site.name}</UIPanelInfoItem>
        </UIPanelInfoBlock>

        <UIPanelInfoBlock title="Equipo">
          <UIPanelInfoItem name="Nombre">{machine.name}</UIPanelInfoItem>
          <UIPanelInfoItem name="Tipo">{machine_type.name}</UIPanelInfoItem>
          <UIPanelInfoItem name="Marca">{machine_brand.name}</UIPanelInfoItem>
          <UIPanelInfoItem name="Modelo">{machine_model.name}</UIPanelInfoItem>
          {/*<UIPanelInfoItem name="N° de serie">???</UIPanelInfoItem>*/}
        </UIPanelInfoBlock>

        <UIPanelInfoBlock title="Componente">
          <UIPanelInfoItem name="Código">{component.tag}</UIPanelInfoItem>
          <UIPanelInfoItem name="Nombre">{component.name}</UIPanelInfoItem>
          <UIPanelInfoItem name="Tipo">{component_type.name}</UIPanelInfoItem>
          <UIPanelInfoItem name="Marca">{component_brand.name}</UIPanelInfoItem>
          <UIPanelInfoItem name="Modelo">
            {component_model.name}
          </UIPanelInfoItem>
          <UIPanelInfoItem name="N° de serie">
            {component.serial_number}
          </UIPanelInfoItem>
        </UIPanelInfoBlock>

        <UIPanelInfoBlock title="Lubricante">
          <UIPanelInfoItem name="Nombre">{lubricant.name}</UIPanelInfoItem>
        </UIPanelInfoBlock>
      </UIPanelInfo>
    )
  }

  return <UIError />
}

export default SampleInfoInsertProgrammed
