import React from 'react'

import AdministrationInfo from '../..'

// Queries
import { READ_SUGGESTIONS, DELETE_SUGGESTIONS } from './queries'

const AdministrationInfoAnalysisSuggestions = ({ ...rest }) => (
  <AdministrationInfo
    queries={{ read: READ_SUGGESTIONS, delete: DELETE_SUGGESTIONS }}
    title="Acción"
    {...rest}
    noDuplicate
    noDelete
  />
)

export default AdministrationInfoAnalysisSuggestions
