import React from 'react'

// Hooks
import {
  useQuery,
  useEffect,
  useGlobal,
  useState,
} from '../../../../../../hooks'

// Components
import UILoading from '../../../../../UI/Loading'
import UIError from '../../../../../UI/Error'
import UIEmpty from '../../../../../UI/Empty'
import UITable from '../../../../../../components/UI/Table'
import UITableHead from '../../../../../../components/UI/Table/Head'
import UITableBody from '../../../../../../components/UI/Table/Body'

// Queries
import { GET_FILE_PARSED } from './queries'

const useFile = (file) => {
  const [textFile, setTextFileState] = useState()

  let fileReader

  const handleFileRead = (e) => {
    const content = fileReader.result.replace('\r\n', '\n').replace('\r', '\n')
    // .replace(/^[ \t]*#.*\n/, '')
    // .split('\n')
    setTextFileState(content)
  }

  const handleFileChosen = (file) => {
    fileReader = new FileReader()
    fileReader.onloadend = handleFileRead
    fileReader.readAsText(file)
  }

  useEffect(() => {
    handleFileChosen(file)
  }, [file])

  return textFile
}

const CollectionTable = ({ text, file }) => {
  const { data, loading } = useQuery(GET_FILE_PARSED, {
    variables: { text, file },
  })

  if (loading) return <UILoading />

  if (data && data.t5ResultsByParsedFile && data.t5ResultsByParsedFile.data) {
    const samples = data.t5ResultsByParsedFile.data

    if (samples.length)
      return (
        <UITable borderless className="text-center font-weight-light my-4">
          <UITableHead>
            <tr>
              <td className="bg-stng border text-white align-middle">
                <small>Correlativo</small>
              </td>
              <td className="bg-stng border text-white align-middle">
                <small>Ensayo</small>
              </td>
              <td className="bg-stng border text-white align-middle">
                <small>Valor</small>
              </td>
            </tr>
          </UITableHead>
          <UITableBody>
            {samples.map(({ id_sample, essay, value }) => (
              <tr>
                <td>{id_sample}</td>
                <td>{essay.name}</td>
                <td>{value}</td>
              </tr>
            ))}
          </UITableBody>
        </UITable>
      )

    return <UIEmpty />
  }

  return <UIError />
}

const SampleInfoResultsProgrammed = ({ file }) => {
  const text = useFile(file)

  if (text) return <CollectionTable text={text} file={file.name} />

  return <UILoading />
}

export default SampleInfoResultsProgrammed
