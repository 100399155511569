import React from 'react'

// Hooks
import { useGlobal } from '../../../../../hooks'

import AdministrationInfo from '../..'

// Queries
import {
  READ_ESSAY_CLASSIFICATIONS,
  DELETE_ESSAY_CLASSIFICATIONS,
} from './queries'

const AdministrationInfoSamplingEssayClassifications = ({ ...rest }) => {
  const [{ me }] = useGlobal()

  const no = ![1, 2].includes(me.id_role)

  return (
    <AdministrationInfo
      queries={{
        read: READ_ESSAY_CLASSIFICATIONS,
        delete: DELETE_ESSAY_CLASSIFICATIONS,
      }}
      title="Clasificación de ensayo"
      noDuplicate={no}
      noUpdate={no}
      noDelete={no}
    />
  )
}

export default AdministrationInfoSamplingEssayClassifications
