import React from 'react'
import { Row, Col, Form } from 'react-bootstrap'

// Hooks
import { useAlert } from '../../../../../../../hooks'

// Components
import UIButton from '../../../../../../../components/UI/Button'
import UIForm from '../../../../../../../components/UI/FormReload'
import UIFormInputText from '../../../../../../../components/UI/FormReload/Input/Text'
import UIFormInputInteger from '../../../../../../../components/UI/FormReload/Input/Integer'
import UIFormSeparation from '../../../../../../../components/UI/FormReload/Separation'

const SampleFormInsertMassiveExcel = ({ onDataChange }) => {
  const alert = useAlert()

  // --------------------------------------------------------------------------
  //
  // Handler
  //
  // --------------------------------------------------------------------------

  const handleSubmit = (values) => {
    let { ids, id_massive } = values

    // Correlative
    // ----------------------------------------------------------
    if (ids) {
      const isList = ids.includes(',')
      const isRange = ids.includes('-')

      if (isList && isRange) {
        alert('Sólo puede usar uno de los tres métodos', { style: 'danger' })
        return
      }

      if (isRange && (ids.match(/-/g) || []).length > 1) {
        alert('Sólo se puede tener un guión en el rango', { style: 'danger' })
        return
      }

      if (isList) {
        const nans = ids.split(',').filter((id) => isNaN(id.trim()))

        if (nans.length > 0) {
          alert(`Los siguientes valores sonn inválidos: ${nans.join(', ')}`, {
            style: 'danger',
          })
          return
        }
      }

      if (isRange) {
        const nans = ids.split('-').filter((id) => isNaN(id.trim()))

        if (nans.length > 0) {
          alert(`Los siguientes valores sonn inválidos: ${nans.join(', ')}`, {
            style: 'danger',
          })
          return
        }
      }

      if (!isList && !isRange && isNaN(ids)) {
        alert(`El correlativo no es válido`, {
          style: 'danger',
        })
        return
      }
    }

    // Correlative massive
    // ----------------------------------------------------------
    if (id_massive && isNaN(id_massive)) {
      alert(`El correlativo masivo no es válido`, {
        style: 'danger',
      })
      return
    }

    onDataChange({ ids, id_massive })
  }

  return (
    <UIForm onSubmit={handleSubmit}>
      <Form.Group as={Row} className="m-1">
        <Col className="d-flex">
          <UIButton type="submit" className="ml-auto">
            Buscar muestras
          </UIButton>
        </Col>
      </Form.Group>
      <UIFormSeparation />
      <UIFormInputText label="Correlativo" name="ids" />
      <UIFormInputInteger label="Correlativo masivo" name="id_massive" />
    </UIForm>
  )
}

export default SampleFormInsertMassiveExcel
