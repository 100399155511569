import React from 'react'

// Components
import UIDashboardQuickSight from '../../UI/Dashboard/QuickSight'

const DashboardCummins = () => {
  return <UIDashboardQuickSight id="050aed09-7382-4862-a281-44009f582877" />
}

export default DashboardCummins
