import { gql } from '../../../../../hooks'

export const READ_ESSAYS = gql`
  query AdministrationReadSamplingEssays($id: ID!) {
    aRead(path: "sampling/essays", id: $id) {
    }
  }
`

export const UPDATE_ESSAYS = gql`
  mutation AdministrationUpdateSamplingEssays($id: ID!, $data: JSONObject) {
    aUpdate(path: "sampling/essays", id: $id, data: $data) {
    }
  }
`

export const CREATE_ESSAYS = gql`
  mutation AdministrationCreateSamplingEssays($data: JSONObject) {
    aCreate(path: "sampling/essays", data: $data) {
    }
  }
`

export const LIST_ESSAYS = gql`
  query AdministrationListSamplingEssays(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(
      path: "sampling/essays"
      filter: $filter
      page: $page
      limit: $limit
    ) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`

export const SEARCH_RESULT_UNIT = gql`
  query AdministrationSearchSamplingResultUnit {
    aSearch(path: "result_unit", limit: 0, text: "") {
    }
  }
`

export const SEARCH_RESULT_TYPE = gql`
  query AdministrationSearchSamplingResultType {
    aSearch(path: "result_type", limit: 0, text: "") {
    }
  }
`

export const SEARCH_ESSAY_UNIT = gql`
   query AdministrationSearchSamplingEssayUnit {
    aSearch(path: "essay_unit", limit: 0, text: "") {
    }
  }
`

export const SEARCH_ANALYSIS_METHOD = gql`
  query AdministrationSearchSamplingAnalysisMethod {
    aSearch(path: "analysis_method", limit: 0, text: "") {
    }
  }
`

export const SEARCH_PROTOCOL_TYPE = gql`
  query AdministrationSearchSamplingProtocolType {
    aSearch(path: "protocol_type", limit: 0, text: "") {
    }
  }
`

export const SEARCH_ESSAY_CLASSIFICATION = gql`
  query AdministrationSearchSampling {
    aSearch(path: "essay_classification", limit: 0, text: "") {
    }
  }
`
