import { gql } from '../../../../../hooks'

export const READ_USERS = gql`
  query AdministrationReadUserUsers($id: ID!) {
    aRead(path: "user/users", id: $id) {
    }
  }
`

export const UPDATE_USERS = gql`
  mutation AdministrationUpdateUserUsers($id: ID!, $data: JSONObject) {
    aUpdate(path: "user/users", id: $id, data: $data) {
    }
  }
`

export const CREATE_USERS = gql`
  mutation AdministrationCreateUserUsers($data: JSONObject) {
    aCreate(path: "user/users", data: $data) {
    }
  }
`

export const LIST_USERS = gql`
  query AdministrationListUserUsers(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(path: "user/users", filter: $filter, page: $page, limit: $limit) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`

export const LIST_LABORATORIES = gql`
  query ListLaboratories {
    aLaboratories {
    }
  }
`

export const LIST_CLIENTS = gql`
  query ListClients($id_laboratory: ID!) {
    aClientsByLaboratory(id_laboratory: $id_laboratory) {
    }
  }
`

export const LIST_SITES = gql`
  query ListSites($id_laboratory: ID!, $id_client: ID!) {
    aSitesByClient(id_laboratory: $id_laboratory, id_client: $id_client) {
    }
  }
`

export const LIST_MACHINE_TYPES = gql`
  query ListMachineTypes($id_laboratory: ID!,  $id_client: ID!, $id_site: ID!) {
    aMachineTypesBySite(id_laboratory: $id_laboratory,  id_client: $id_client, id_site: $id_site) {
    }
  }
`

export const LIST_ROLES = gql`
  query ListRoles {
    aRoles {
    }
  }
`
