import React from 'react'
import { Alert } from 'react-bootstrap'

// Hooks
// import { useEffect } from '../../../hooks'

const UIAlert = (props) => {
  const {
    header,
    children,
    style = 'info',
    onClose,
    noDismissible,
    ...rest
    //  noTimeout,
  } = props

  //  useEffect(() => {
  //    if (!noTimeout) {
  //      const timer = setTimeout(() => {
  //        onClose && onClose()
  //      }, 2000)
  //      return () => clearTimeout(timer)
  //    }
  //  }, [])

  return (
    <Alert
      variant={style}
      onClose={onClose}
      dismissible={!noDismissible}
      {...rest}
    >
      {header && <Alert.Heading>{header}</Alert.Heading>}
      {children}
    </Alert>
  )
}

export default UIAlert
