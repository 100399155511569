import React from 'react'

// Components
import UIPanelInfoBlock from '../../../../UI/Panel/Info/Block'
import UIPanelInfoItem from '../../../../UI/Panel/Info/Item'
import UIPanelInfoTable from '../../../../UI/Panel/Info/Table'
import UIFormInputText from '../../../../UI/FormReload/Input/Text'
import UIFormInputInteger from '../../../../UI/FormReload/Input/Integer'
import UIFormInputSelect from '../../../../UI/FormReload/Input/Select'
import UIFormSeparation from '../../../../UI/FormReload/Separation'
import UITable from '../../../../UI/Table'
import UITableBody from '../../../../UI/Table/Body'
import UITableHead from '../../../../UI/Table/Head'

// Common
import { oe } from '../../../../../common/object/extractor'

import AdministrationInfo, { RefLink } from '../..'

// Queries
import { READ_LIMITS, DELETE_LIMITS } from './queries'
import UIPanelInfoSeparation from '../../../../UI/Panel/Info/Separation'

const Info = (data) => (
  <UIPanelInfoBlock title="Límite">
    <UIPanelInfoTable>
      {[
        ['ID', oe(data, ['id'])],
        [
          'Protocolo',
          <RefLink
            id={oe(data, ['id'])}
            tab={oe(data, ['type', 'id']) === 1 ? 'lubricant' : 'component'}
            subtab={'protocols'}
          >
            {oe(data, ['name'])}
          </RefLink>,
        ],
        ['Tipo', oe(data, ['type', 'name'])],
      ]}
    </UIPanelInfoTable>
    <UITable bordered className="mt-4">
      <UITableHead>{['Ensayo', 'LIC', 'LIM', 'LSM', 'LSC']}</UITableHead>
      <UITableBody>
        {Object.keys(oe(data, ['rules'], {})).map((id) => {
          const { lic, lim, lsm, lsc, essay } = data.rules[id]

          return (
            <tr key={essay.id}>
              <th scope="row">{essay.name}</th>
              <td>{lic}</td>
              <td>{lim}</td>
              <td>{lsm}</td>
              <td>{lsc}</td>
            </tr>
          )
        })}
      </UITableBody>
    </UITable>
  </UIPanelInfoBlock>
)

const Form = () => (
  <>
    <UIFormInputInteger label="ID protocolo" name="id" />
    <UIFormInputText label="Nombre protocolo" name="name" />
    <UIFormInputSelect label="Tipo protocolo" name="type">
      <option value={0}>Todos</option>
      <option value={1}>AFQ</option>
      <option value={2}>Metal</option>
    </UIFormInputSelect>
  </>
)

const AdministrationInfoAnalysisLimits = ({ ...rest }) => (
  <AdministrationInfo
    noDelete
    queries={{ read: READ_LIMITS, delete: DELETE_LIMITS }}
    views={{ form: Form, info: Info }}
    {...rest}
  />
)

export default AdministrationInfoAnalysisLimits
