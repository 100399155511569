import { gql } from '../../../../../hooks'

export const READ_ESSAYS = gql`
  query AdministrationReadSamplingEssays($id: ID!) {
    aRead(path: "sampling/essays", id: $id) {
    }
  }
`

export const DELETE_ESSAYS = gql`
  mutation AdministrationDeleteSamplingEssays($id: ID!) {
    aDelete(path: "sampling/essays", id: $id) {
    }
  }
`
