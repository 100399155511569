import { gql } from '../../../../../hooks'

export const READ_PROCEDURES = gql`
  query AdministrationReadSamplingProcedures($id: ID!) {
    aRead(path: "sampling/procedures", id: $id) {
    }
  }
`

export const UPDATE_PROCEDURES = gql`
  mutation AdministrationUpdateSamplingProcedures($id: ID!, $data: JSONObject) {
    aUpdate(path: "sampling/procedures", id: $id, data: $data) {
    }
  }
`

export const CREATE_PROCEDURES = gql`
  mutation AdministrationCreateSamplingProcedures($data: JSONObject) {
    aCreate(path: "sampling/procedures", data: $data) {
    }
  }
`

export const LIST_PROCEDURES = gql`
  query AdministrationListSamplingProcedures(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(
      path: "sampling/procedures"
      filter: $filter
      page: $page
      limit: $limit
    ) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`
