import React from 'react'
import { Redirect } from 'react-router-dom'

// Hooks
import { useGlobal } from '../../../../hooks'

const UIWrapperPageAuth = ({
  roles,
  laboratories,
  clients,
  sites,
  children,
}) => {
  const [{ me }] = useGlobal()

  // ------------------------------------------------------------------------
  //
  // User local authorization
  //
  // ------------------------------------------------------------------------

  if (roles || laboratories || clients || sites) {
    const checkLocalRoles = () => {
      if (roles && !roles.includes(me.id_role)) throw new Error()
    }

    const checkLocalLaboratories = () => {
      if (laboratories) {
        if ([0, ...laboratories].includes(me.laboratories)) return
        throw new Error()
      }
    }

    const checkLocalClients = () => {
      if (clients) {
        if ([0, ...clients].includes(me.clients)) return
        throw new Error()
      }
    }

    const checkLocalSites = () => {
      if (sites) {
        if ([0, ...sites].includes(me.sites)) return
        throw new Error()
      }
    }

    try {
      switch (me.id_role) {
        // Root
        case 1:
          break
        // Administrador
        case 2:
          checkLocalRoles()
          checkLocalLaboratories()
          checkLocalClients()
          checkLocalSites()
          break
        // Analista
        case 3:
          checkLocalRoles()
          checkLocalLaboratories()
          checkLocalClients()
          checkLocalSites()
          break
        // Ingeniero
        case 4:
          checkLocalRoles()
          checkLocalLaboratories()
          checkLocalClients()
          checkLocalSites()
          break
        // Cliente
        case 5:
          checkLocalRoles()
          checkLocalLaboratories()
          checkLocalClients()
          checkLocalSites()
          break
      }
    } catch (err) {
      return <Redirect to="/forbidden" />
    }
  }

  return children
}

export default UIWrapperPageAuth
