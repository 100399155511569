import { gql } from '../../../../../../hooks'

export const GET_CLIENTS_BY_LABORATORY = gql`
  query GetClientsByLaboratory($id: ID!) {
    clientsByLaboratory(id: $id) {
    }
  }
`

export const GET_LABORATORIES = gql`
  query GetLaboratories {
    laboratories {
    }
  }
`

export const SIGN_OUT = gql`
  mutation SignOut {
    signOut {
    }
  }
`

export const UPDATE_CLIENT = gql`
  mutation UpdateClient($id: ID!) {
    updateUserClient(id: $id) {
    }
  }
`

export const UPDATE_LABORATORY = gql`
  mutation UpdateLaboratory($id: ID!) {
    updateUserLaboratory(id: $id) {
    }
  }
`
