import { gql } from '../../../../../hooks'

export const READ_CHANGE_MOTIVES = gql`
  query AdministrationReadComponentChangeMotives($id: ID!) {
    aRead(path: "component/change/motives", id: $id) {
    }
  }
`

export const UPDATE_CHANGE_MOTIVES = gql`
  mutation AdministrationUpdateComponentChangeMotives(
    $id: ID!
    $data: JSONObject
  ) {
    aUpdate(path: "component/change/motives", id: $id, data: $data) {
    }
  }
`

export const CREATE_CHANGE_MOTIVES = gql`
  mutation AdministrationCreateComponentChangeMotives($data: JSONObject) {
    aCreate(path: "component/change/motives", data: $data) {
    }
  }
`

export const LIST_CHANGE_MOTIVES = gql`
  query AdministrationListComponentChangeMotives(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(
      path: "component/change/motives"
      filter: $filter
      page: $page
      limit: $limit
    ) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`
