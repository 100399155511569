import { gql } from '../../../../../hooks'

export const LIST_CLIENTS = gql`
  query ListClients($id: ID!) {
    t1ClientsByLaboratory(id: $id) {
    }
  }
`

export const LIST_SITES = gql`
  query ListSites($id: ID!) {
    t1SitesByClient(id: $id) {
    }
  }
`

// export const AUTOCOMPLETE_TAG = gql`
//   query AutocompleteTag($text: String!, $id_client: ID, $id_site: ID) {
//     t1ComponentsAutocomplete(
//       tag: $text
//       id_client: $id_client
//       id_site: $id_site
//     ) {
//     }
//   }
// `

export const COMPONENT_SEARCH = gql`
  query ComponentSearch($text: String!, $filter: JSONObject) {
    t1SearchComponent(
      text: $text
      filter: $filter
    ) {
    }
  }
`

export const GET_SAMPLE_LABEL_INFO = gql`
  query GetSampleLabelInfo($id: ID!) {
    t1SampleLabelInfo(id: $id) {
    }
  }
`

export const LIST_LUBIRCANT_CHANGE_REFILLS = gql`
  query LubricantChangeRefills {
    t1LubricantChangeRefills {
    }
  }
`

export const LIST_LUBRICANT_CHANGE_REFILL_MOTIVES = gql`
  query LubricantChangeRefillMotives {
    t1LubricantChangeRefillMotives {
    }
  }
`

export const LIST_COMPONENT_CHANGE_MOTIVES = gql`
  query ComponentChangeMotives {
    t1ComponentChangeMotives {
    }
  }
`

export const ENTER_SAMPLE = gql`
  mutation EnterSample(
    $data: JSONObject!
  ) {
    t1EnterSample(data: $data) {
    }
  }
`
// export const enter_sample = gql`
//   mutation entersample(
//     $date_sampling: string!
//     $date_withdraw: string!
//     $id_sample_priority: id
//     $comment: string
//     $id_component: id!
//     $external_number: string
//     $machine_continuity: float
//     $component_continuity: float
//     $lubricant_continuity: float
//     $number_of_refills: int
//     $id_component_change_motive: id
//     $id_lubricant_change_refill: id
//     $id_lubricant_change_refill_motive: id
//     $lubricant_change_refill_liters: float
//     $lubricant_change_refill_hours: float
//   ) {
//     t1entersample(
//       date_sampling: $date_sampling
//       date_withdraw: $date_withdraw
//       id_sample_priority: $id_sample_priority
//       comment: $comment
//       id_component: $id_component
//       external_number: $external_number
//       machine_continuity: $machine_continuity
//       component_continuity: $component_continuity
//       lubricant_continuity: $lubricant_continuity
//       number_of_refills: $number_of_refills
//       id_component_change_motive: $id_component_change_motive
//       id_lubricant_change_refill: $id_lubricant_change_refill
//       id_lubricant_change_refill_motive: $id_lubricant_change_refill_motive
//       lubricant_change_refill_liters: $lubricant_change_refill_liters
//       lubricant_change_refill_hours: $lubricant_change_refill_hours
//     ) {
//     }
//   }
// `
