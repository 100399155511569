import React from 'react'

// Components
import AdministrationListMachineModels from '../../../../Administration/List/Machine/Models'
import AdministrationInfoMachineModels from '../../../../Administration/Info/Machine/Models'
import AdministrationFormMachineModels from '../../../../Administration/Form/Machine/Models'

import { AdministrationContainer } from '../..'

const AdministrationContainerMachineModels = () => (
  <AdministrationContainer
    form={AdministrationFormMachineModels}
    info={AdministrationInfoMachineModels}
    list={AdministrationListMachineModels}
  />
)

export default AdministrationContainerMachineModels
