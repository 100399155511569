import React from 'react'

// Components
import UIPanelInfo from '../../../../UI/Panel/Info'
import UIPanelInfoBlock from '../../../../UI/Panel/Info/Block'
import UIGraphLineResult from '../../../../UI/Graph/Line/Result'
import UILoading from '../../../../UI/Loading'
import UIError from '../../../../UI/Error'

// Hooks
import { useGlobal, useQuery } from '../../../../../hooks'

// Queries
import { GET_RESULTS_BY_SAMPLE_GRAPH } from './queries'

const SampleGraphResultsProgrammed = ({ id }) => {
  const [{ me }] = useGlobal()

  // --------------------------------------------------------------------------
  //
  // Query: Results
  //
  // --------------------------------------------------------------------------

  const { data, loading } = useQuery(GET_RESULTS_BY_SAMPLE_GRAPH, {
    fetchPolicy: 'network-only',
    variables: { id, noise: me.id_laboratory },
    onErrorMessage: `No se pudo cargar los resultados de los gráficos`,
  })

  // --------------------------------------------------------------------------
  //
  // Render
  //
  // --------------------------------------------------------------------------

  // Loading
  if (loading)
    return (
      <UIPanelInfo centered>
        <UIPanelInfoBlock>
          <UILoading />
        </UIPanelInfoBlock>
      </UIPanelInfo>
    )

  if (data && data.t5ResultsBySampleGraph) {
    // ------------------------------------------------------------------------
    // Render the plots
    // ------------------------------------------------------------------------

    const has23 = !!data.t5ResultsBySampleGraph.data[23]
    const has24 = !!data.t5ResultsBySampleGraph.data[24]

    const hasLast23 = has23 && data.t5ResultsBySampleGraph.data[23].hasLast
    const hasLast24 = has24 && data.t5ResultsBySampleGraph.data[24].hasLast

    const graphs = [
      data.t5ResultsBySampleGraph.data[1],
      data.t5ResultsBySampleGraph.data[2],
      hasLast24
        ? data.t5ResultsBySampleGraph.data[24]
        : hasLast23
        ? data.t5ResultsBySampleGraph.data[23]
        : has24
        ? data.t5ResultsBySampleGraph.data[24]
        : data.t5ResultsBySampleGraph.data[23],
      data.t5ResultsBySampleGraph.data[15],
    ]

    return (
      <UIPanelInfo>
        {graphs.map((essay, index) => {
          if (essay) {
            let { id, name, lic, lim, lsm, lsc, data } = essay

            // const last = data[9]

            // Viscosidad 40 & 100
            if ([23, 24].includes(id)) {
              lic = lic
              lim = lim
              lsm = lsm
              lsc = lsc
            } else {
              lic = null
              lim = null
              lsm = lsm
              lsc = lsc
            }

            return (
              <UIPanelInfoBlock
                title={name}
                className="m-0 mt-2 p-0"
                key={`essay-${index}`}
              >
                <div
                  key={`plot-graph-${index}`}
                  style={{ width: '100%', height: '200px' }}
                >
                  <UIGraphLineResult
                    data={data}
                    lic={lic}
                    lim={lim}
                    lsm={lsm}
                    lsc={lsc}
                  />
                </div>
              </UIPanelInfoBlock>
            )
          }

          return (
            <small
              key={`plot-graph-${index}`}
              className="font-weight-light py-5 my-2 text-center text-muted border rounded"
            >
              No hay data para graficar
            </small>
          )
        })}
      </UIPanelInfo>
    )
  }

  return (
    <UIPanelInfo centered>
      <UIPanelInfoBlock>
        <UIError />
      </UIPanelInfoBlock>
    </UIPanelInfo>
  )
}

export default SampleGraphResultsProgrammed
