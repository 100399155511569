import React from 'react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

// Hooks
import { useQuery, useGlobal } from '../../../../../hooks'

// Components
import UIPanelInfo from '../../../../UI/Panel/Info'
import UIPanelInfoBlock from '../../../../UI/Panel/Info/Block'
import UIPanelInfoItem from '../../../../UI/Panel/Info/Item'
import UILoading from '../../../../UI/Loading'
import UIError from '../../../../UI/Error'

// Common
import { oe } from '../../../../../common/object/extractor'

import { RefLink } from '../../../../Administration/Info'

// Queries
import { GET_RECEIVED_SAMPLE_INFO } from './queries'

const CustomLink = (props) => {
  const [{ me }] = useGlobal()

  if ([1, 2, 4].includes(me.id_role)) {
    return <RefLink {...props} />
  } else return props.children
}

const SampleInfoResultsProgrammed = ({ id }) => {
  const [{ me }] = useGlobal()

  // --------------------------------------------------------------------------
  //
  // Query: Received samples info
  //
  // --------------------------------------------------------------------------

  const { data: dataSample, loading } = useQuery(GET_RECEIVED_SAMPLE_INFO, {
    fetchPolicy: 'network-only',
    variables: { id, noise: me.id_laboratory },
    skip: !id,
  })

  // --------------------------------------------------------------------------
  //
  // Render
  //
  // --------------------------------------------------------------------------

  // Loading
  if (loading)
    return (
      <UIPanelInfo centered>
        <UIPanelInfoBlock>
          <UILoading />
        </UIPanelInfoBlock>
      </UIPanelInfo>
    )

  // Data
  if (
    dataSample &&
    dataSample.t5ReceivedSampleInfo &&
    dataSample.t5ReceivedSampleInfo.data
  ) {
    const data = dataSample.t5ReceivedSampleInfo.data

    return (
      <UIPanelInfo>
        <UIPanelInfoBlock title="Datos muestra">
          <UIPanelInfoItem name="N° Muestra">
            <CustomLink
              id={oe(data, ['sample', 'id'])}
              tab="sampling"
              subtab="samples"
            >
              {oe(data, ['sample', 'id'])}
            </CustomLink>
          </UIPanelInfoItem>
          <UIPanelInfoItem name="Fecha muestreo">
            {oe(data, ['sample', 'date_sampling'])}
          </UIPanelInfoItem>
          <UIPanelInfoItem name="Fecha retiro">
            {oe(data, ['sample', 'date_withdraw'])}
          </UIPanelInfoItem>
          <UIPanelInfoItem name="Fecha ingreso">
            {oe(data, ['sample', 'date_entry'])}
          </UIPanelInfoItem>
          <UIPanelInfoItem name="Fecha recepción">
            {oe(data, ['sample', 'date_receive'])}
          </UIPanelInfoItem>
        </UIPanelInfoBlock>
        <UIPanelInfoBlock title="Cliente">
          <UIPanelInfoItem name="Nombre">
            <CustomLink
              id={oe(data, ['client', 'id'])}
              tab="client"
              subtab="clients"
            >
              {oe(data, ['client', 'name'])}
            </CustomLink>
          </UIPanelInfoItem>
          <UIPanelInfoItem name="Faena">
            <CustomLink
              id={oe(data, ['site', 'id'])}
              tab="client"
              subtab="sites"
            >
              {oe(data, ['site', 'name'])}
            </CustomLink>
          </UIPanelInfoItem>
        </UIPanelInfoBlock>

        <UIPanelInfoBlock title="Equipo">
          <UIPanelInfoItem name="Nombre">
            <CustomLink
              id={oe(data, ['machine', 'id'])}
              tab="machine"
              subtab="machines"
            >
              {oe(data, ['machine', 'name'])}
            </CustomLink>
          </UIPanelInfoItem>
          <UIPanelInfoItem name="Tipo">
            <CustomLink
              id={oe(data, ['machine', 'type', 'id'])}
              tab="machine"
              subtab="types"
            >
              {oe(data, ['machine', 'type', 'name'])}
            </CustomLink>
          </UIPanelInfoItem>
          <UIPanelInfoItem name="Marca">
            <CustomLink
              id={oe(data, ['machine', 'brand', 'id'])}
              tab="machine"
              subtab="brands"
            >
              {oe(data, ['machine', 'brand', 'name'])}
            </CustomLink>
          </UIPanelInfoItem>
          <UIPanelInfoItem name="Modelo">
            <CustomLink
              id={oe(data, ['machine', 'model', 'id'])}
              tab="machine"
              subtab="models"
            >
              {oe(data, ['machine', 'model', 'name'])}
            </CustomLink>
          </UIPanelInfoItem>
        </UIPanelInfoBlock>

        <UIPanelInfoBlock title="Componente">
          <UIPanelInfoItem name="Tag">
            <CustomLink
              id={oe(data, ['component', 'id'])}
              tab="component"
              subtab="components"
            >
              {oe(data, ['component', 'tag'])}
            </CustomLink>
          </UIPanelInfoItem>
          <UIPanelInfoItem name="Tipo">
            <CustomLink
              id={oe(data, ['component', 'type', 'id'])}
              tab="component"
              subtab="types"
            >
              {oe(data, ['component', 'type', 'name'])}
            </CustomLink>
          </UIPanelInfoItem>
          <UIPanelInfoItem name="Marca">
            <CustomLink
              id={oe(data, ['component', 'brand', 'id'])}
              tab="component"
              subtab="brands"
            >
              {oe(data, ['component', 'brand', 'name'])}
            </CustomLink>
          </UIPanelInfoItem>
          <UIPanelInfoItem name="Modelo">
            <CustomLink
              id={oe(data, ['component', 'model', 'id'])}
              tab="component"
              subtab="models"
            >
              {oe(data, ['component', 'model', 'name'])}
            </CustomLink>
          </UIPanelInfoItem>
          <UIPanelInfoItem name="N° de serie">
            {oe(data, ['component', 'serial_number'])}
          </UIPanelInfoItem>
        </UIPanelInfoBlock>

        <UIPanelInfoBlock title="Lubricante">
          <UIPanelInfoItem name="Nombre">
            <CustomLink
              id={oe(data, ['lubricant', 'id'])}
              tab="lubricant"
              subtab="lubricants"
            >
              {oe(data, ['lubricant', 'name'])}
            </CustomLink>
          </UIPanelInfoItem>
        </UIPanelInfoBlock>
      </UIPanelInfo>
    )
  }

  // Error
  return (
    <UIPanelInfo centered>
      <UIPanelInfoBlock>
        <UIError />
      </UIPanelInfoBlock>
    </UIPanelInfo>
  )
}

export default SampleInfoResultsProgrammed
