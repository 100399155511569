import React from 'react'
import { embedDashboard } from 'amazon-quicksight-embedding-sdk'

// Hooks
import {
  useState,
  useGlobal,
  useQuery,
  useEffect,
  useRef,
} from '../../../../hooks'

// Components
import UILoading from '../../../UI/Loading'
import UILogo from '../../../UI/Logo'
import UIPanelInfo from '../../../UI/Panel/Info'

// Queries
import { QUICKSIGHT_URL } from './queries'

const Quicksight = ({ url }) => {
  const [{ me }] = useGlobal()

  const ref = useRef()

  const [isError, setIsErrorState] = useState(false)
  const [isLoading, setIsLoadingState] = useState(true)

  useEffect(() => {
    const options = {
      url,
      container: ref.current,
      scrolling: 'no',
      // parameters: {
      //   laboratory: me.laboratory,
      // },
      height: 'AutoFit',
      width: '100%',
      iframeResizeOnSheetChange: true, // use this option in combination with height: AutoFit, to allow iframe height to resize dynamically, based on sheet height, on changing sheets.
      locale: 'es-ES',
      footerPaddingEnabled: false,
      sheetTabsDisabled: false, // use this option to enable or disable sheet tab controls in dashboard embedding
      printEnabled: false, // use this option to enable or disable print option for dashboard embedding
      undoRedoDisabled: true, // set this option to true to disable undo and redo buttons for dashboard embedding
      resetDisabled: true, // set this option to true to disable reset button for dashboard embedding
    }

    const dashboard = embedDashboard(options)

    dashboard.on('load', (d) => {
      //   console.log('Load!!!!')
      //   console.dir(d)
      setIsLoadingState(false)
    })

    dashboard.on('error', (e) => {
      console.err(e)
      setIsErrorState(true)
      setIsLoadingState(false)
    })
  }, [])
  // }, [me.laboratory])

  if (isError) return <UILogo />

  return (
    <UIPanelInfo>
      <div ref={ref} />
      {isLoading && <UILoading />}
    </UIPanelInfo>
  )
}

const DashboardQuickSight = ({ id }) => {
  const { data, loading } = useQuery(QUICKSIGHT_URL, {
    variables: { id },
    // onErrorMessage: 'Error al obtener el dashboard de Welcome',
  })

  if (loading)
    return (
      <UIPanelInfo>
        <UILoading />
      </UIPanelInfo>
    )

  if (data && data.quicksightURL && data.quicksightURL.data) {
    const { Status, EmbedUrl } = data.quicksightURL.data

    if (![200, '200'].includes(Status)) return <UILogo />

    return <Quicksight url={EmbedUrl} />
  }

  return <UILogo />
}

export default DashboardQuickSight
