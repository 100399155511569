import React from 'react'
import moment from 'moment'
import ExcelJS from 'exceljs/dist/es5/exceljs.browser'
// import * as ExcelJS from 'exceljs/dist/exceljs'

// Components
import UIButton from '../../../UI/Button'

// Hooks
import { useLazyQuery } from '../../../../hooks'

// Queries
import { GET_EXCEL } from './queries'

// Config
import config from '../../../../config'

const { APP } = config

// ----------------------------------------------------------------------------
//
// Building Excel
//
// ----------------------------------------------------------------------------

const buildExcel = ({ data, filter }) => {
  const { info, results } = data
  // const { from, to } = filter

  const workbook = new ExcelJS.Workbook()

  workbook.creator = 'Servicios Tribológicos'

  // ------------------------------------------------------------------------
  // Helpers
  // ------------------------------------------------------------------------

  const border = (positions = {}) => {
    const { x, y, t, r, l, b } = positions

    const style = 'thin'
    const border = {}

    if (x) {
      border.left = { style }
      border.right = { style }
    }
    if (y) {
      border.top = { style }
      border.bottom = { style }
    }
    if (t) border.top = { style }
    if (r) border.right = { style }
    if (b) border.bottom = { style }
    if (l) border.left = { style }

    if (!Object.keys(positions).length) {
      border.top = { style: 'thin' }
      border.left = { style: 'thin' }
      border.bottom = { style: 'thin' }
      border.right = { style: 'thin' }
    }

    return border
  }

  const fill = ({ argb = 'FFF7F2E0' } = {}) => ({
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb },
  })
  // ------------------------------------------------------------------------
  // Excel buiilding
  // ------------------------------------------------------------------------

  // Create sheet
  // const sheet = workbook.addWorksheet(name.replace(/[:\\\/\?\*\[\]#]/, ''))
  const sheet = workbook.addWorksheet('Componentes no-muestreados')

  // Insert info header
  let row
  let o = 0 // offset
  const r = 2
  const c = 2

  row = sheet.getRow(r + 0)
  row.getCell(c + 0).value = 'Cliente :'
  row.getCell(c + 0).border = border({ t: true, l: true })
  row.getCell(c + 0).fill = fill()
  row.getCell(c + 0).alignment = { horizontal: 'right' }
  row.getCell(c + 0).font = { bold: true }
  row.getCell(c + 1).value = info.client_name
  row.getCell(c + 1).border = border({ t: true, r: true })
  row.getCell(c + 1).fill = fill()

  if (info.site_name) {
    row = sheet.getRow(r + 1 + o)
    row.getCell(c + 0).value = 'Faena :'
    row.getCell(c + 0).border = border({ l: true })
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 0).alignment = { horizontal: 'right' }
    row.getCell(c + 0).font = { bold: true }
    row.getCell(c + 1).value = info.site_name
    row.getCell(c + 1).border = border({ r: true })
    row.getCell(c + 1).fill = fill()
    o += 1
  }

  if (info.machine_type_name) {
    row = sheet.getRow(r + 1 + o)
    row.getCell(c + 0).value = 'Flota :'
    row.getCell(c + 0).border = border({ l: true })
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 0).alignment = { horizontal: 'right' }
    row.getCell(c + 0).font = { bold: true }
    row.getCell(c + 1).value = info.machine_type_name
    row.getCell(c + 1).border = border({ r: true })
    row.getCell(c + 1).fill = fill()
    o += 1
  }

  if (info.machine_name) {
    row = sheet.getRow(r + 1 + o)
    row.getCell(c + 0).value = 'Equipo :'
    row.getCell(c + 0).border = border({ l: true })
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 0).alignment = { horizontal: 'right' }
    row.getCell(c + 0).font = { bold: true }
    row.getCell(c + 1).value = info.machine_name
    row.getCell(c + 1).border = border({ r: true })
    row.getCell(c + 1).fill = fill()
    o += 1
  }

  row = sheet.getRow(r + 1 + o)
  // row.getCell(c + 0).value = 'Periodo :'
  // row.getCell(c + 0).border = border({ l: true })
  // row.getCell(c + 0).fill = fill()
  // row.getCell(c + 0).alignment = { horizontal: 'right' }
  // row.getCell(c + 0).font = { bold: true }
  // row.getCell(c + 1).value = `${from} ~ ${to}`
  // row.getCell(c + 1).border = border({ r: true })
  // row.getCell(c + 1).fill = fill()
  // row = sheet.getRow(r + 2 + o)
  row.getCell(c + 0).value = 'Encargado :'
  row.getCell(c + 0).border = border({ l: true, b: true })
  row.getCell(c + 0).fill = fill()
  row.getCell(c + 0).alignment = { horizontal: 'right' }
  row.getCell(c + 0).font = { bold: true }
  row.getCell(c + 1).value = info.responsible
  row.getCell(c + 1).border = border({ r: true, b: true })
  row.getCell(c + 1).fill = fill()

  // row = sheet.getRow(r + 4 + o)
  row = sheet.getRow(r + 3 + o)
  row.alignment = { vertical: 'middle', horizontal: 'center' }
  row.getCell(c + 0).value = 'Faena'
  row.getCell(c + 0).border = border()
  row.getCell(c + 0).fill = fill()
  row.getCell(c + 1).value = 'Equipo'
  row.getCell(c + 1).border = border()
  row.getCell(c + 1).fill = fill()
  row.getCell(c + 2).value = 'Componente'
  row.getCell(c + 2).border = border()
  row.getCell(c + 2).fill = fill()
  row.getCell(c + 3).value = 'Descripción componente'
  row.getCell(c + 3).border = border()
  row.getCell(c + 3).fill = fill()
  row.getCell(c + 4).value = 'Última muestra'
  row.getCell(c + 4).border = border()
  row.getCell(c + 4).fill = fill()
  row.getCell(c + 5).value = 'Condición última muestra'
  row.getCell(c + 5).border = border()
  row.getCell(c + 5).fill = fill()
  row.getCell(c + 6).value = 'Fecha muestra'
  row.getCell(c + 6).border = border()
  row.getCell(c + 6).fill = fill()
  row.getCell(c + 7).value = 'Intervalo muestreo'
  row.getCell(c + 7).border = border()
  row.getCell(c + 7).fill = fill()
  row.getCell(c + 8).value = 'Días de atraso'
  row.getCell(c + 8).border = border()
  row.getCell(c + 8).fill = fill()

  results.forEach((row, index) => {
    const {
      sample_id,
      sample_condition_id,
      sample_condition_name,
      site_name,
      machine_name,
      component_tag,
      component_name,
      date_proposed,
      component_unsampled_interval,
      unsampled_interval_days,
      component_unsampled_interval_type,
    } = row

    const intervals = JSON.parse(component_unsampled_interval_type)

    row = sheet.getRow(r + 4 + o + index)
    row.alignment = { vertical: 'middle', horizontal: 'center' }
    row.getCell(c + 0).value = site_name
    row.getCell(c + 0).border = border()
    row.getCell(c + 1).value = machine_name
    row.getCell(c + 1).border = border()
    row.getCell(c + 2).value = component_tag
    row.getCell(c + 2).border = border()
    row.getCell(c + 3).value = component_name
    row.getCell(c + 3).border = border()
    row.getCell(c + 4).value = {
      text: sample_id,
      hyperlink: `https://app.stng.cl/tribology/samples/report/pdf/${sample_id}`,
      tooltip: `Ver informe ${sample_id}`,
    }
    row.getCell(c + 4).border = border()
    row.getCell(c + 5).value = sample_condition_name
    row.getCell(c + 5).border = border()
    switch (sample_condition_id) {
      case 1:
        row.getCell(c + 5).fill = fill({ argb: 'FF00EE00' })
        break
      case 2:
        row.getCell(c + 5).fill = fill({ argb: 'FFEEEE00' })
        break
      case 3:
        row.getCell(c + 5).fill = fill({ argb: 'FFEE0000' })
        row.getCell(c + 5).font = { color: { argb: 'FFEEEEEE' } }
        break
    }
    row.getCell(c + 6).value = moment(date_proposed).format('DD-MM-YYYY')
    row.getCell(c + 6).border = border()
    row.getCell(c + 7).value = `${component_unsampled_interval} ${
      [0, null, undefined, ''].includes(component_unsampled_interval)
        ? intervals[0]
        : intervals[1]
    }`
    row.getCell(c + 7).border = border()
    row.getCell(c + 8).value = unsampled_interval_days
    row.getCell(c + 8).border = border()
  })

  // Make the cells width fit automátically
  sheet.columns.forEach((column) => {
    var dataMax = 0
    column.eachCell({ includeEmpty: true }, (cell) => {
      var columnLength = cell && cell.value ? cell.value.length + 5 : 0
      if (columnLength > dataMax) {
        dataMax = columnLength
      }
    })
    column.width = dataMax < 10 ? 10 : dataMax
  })

  // sheet.getColumn(c + 6).width = 100
  // sheet.getColumn(c + 6).alignment = {
  //   vertical: 'middle',
  //   horizontal: 'center',
  //   wrapText: true,
  // }
  // sheet.getColumn(c + 7).width = 100
  // sheet.getColumn(c + 7).alignment = {
  //   vertical: 'middle',
  //   horizontal: 'center',
  //   wrapText: true,
  // }

  // Download excel file!
  workbook.xlsx.writeBuffer().then(function (data) {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const url = window.URL.createObjectURL(blob)
    const anchor = document.createElement('a')
    anchor.href = url
    anchor.download = 'Reporte - Sin muestrear.xlsx'
    anchor.click()
    // anchor.dispatchEvent(new MouseEvent('click')) // NOTE https://github.com/exceljs/exceljs/issues/354
    window.URL.revokeObjectURL(url)
  })
}

// ----------------------------------------------------------------------------
//
// Main render
//
// ----------------------------------------------------------------------------

const ButtonDownload = ({ filter }) => {
  const [getExcel, { loading, data }] = useLazyQuery(GET_EXCEL, {
    variables: filter,
  })

  if (data && data.reportUnSampledExcel && data.reportUnSampledExcel.data) {
    buildExcel({ data: data.reportUnSampledExcel.data, filter })
  }

  const handleClick = () => {
    getExcel()
  }

  return (
    <UIButton onClick={handleClick} disabled={loading}>
      <img src="/excel.png" height="20" alt="Excel" />
    </UIButton>
  )
}

export default ButtonDownload
