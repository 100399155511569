import React, { forwardRef, Children } from 'react'

import {
  useRef,
  useEffect,
  useQuery,
  useMemo,
  useCallback,
  useState,
  useTrigger,
} from '../../../../../hooks'

import Input from '..'
import { useFormContext } from '../..'

const CustomInput = forwardRef((props, ref) => <select ref={ref} {...props} />)

// Append the query result to the children if the case
const UIFormInputSelect = forwardRef((props, ref) => {
  const {
    value: propValue,
    initialValue: propInitialValue,
    defaultValue: propDefaultValue,
    children: propChildren,
    query: propQuery,
    variables: propVariables,
    skip: propSkip,
    onSuccess: propOnSuccess,
    onCompleted: propOnCompleted,
    onErrorMessage: propOnErrorMessage,
    onChange: propOnChange,
    onReset: propOnReset,
    idAs: propIdAs = 'id',
    nameAs: propNameAs = 'name',
    ...rest
  } = props

  const [errorTrigger, setErrorTrigger] = useTrigger()

  const [firstOption] = useMemo(
    () =>
      Children.toArray(propChildren)
        .filter(({ type }) => type === 'option')
        .map(({ props }) => props.value || ''),
    [propChildren],
  )

  const isFirstRender = useRef(true)

  const context = useFormContext()

  const [value, setValueState] = useState(
    propValue !== undefined
      ? propValue
      : propInitialValue !== undefined
      ? propInitialValue
      : propDefaultValue !== undefined
      ? propDefaultValue
      : context.initialValues[props.name] !== undefined
      ? context.initialValues[props.name]
      : context.defaultValues[props.name] != undefined
      ? context.defaultValues[props.name]
      : firstOption,
  )

  useEffect(() => {
    if (isFirstRender.current) isFirstRender.current = false
    else setValueState(propValue)
  }, [propValue])

  const defaultValue = useMemo(
    () =>
      propDefaultValue !== undefined
        ? propDefaultValue
        : context.defaultValues[props.name] !== undefined
        ? context.defaultValues[props.name]
        : firstOption,
    [],
  )

  // Getting the options
  const { data, loading } = useQuery(propQuery, {
    variables: propVariables,
    skip: propSkip || !propQuery,
    onSuccess: propOnSuccess,
    onCompleted: propOnCompleted,
    onErrorMessage: propOnErrorMessage,
  })

  const customChildren = useMemo(
    () =>
      data ? (
        <>
          {propChildren}
          {Object.values(data)[0].data.map((d) => {
            const value = d[propIdAs]
            const name = d[propNameAs]

            return (
              <option value={d[propIdAs]} key={`element-${value}`}>
                {name}
              </option>
            )
          })}
        </>
      ) : (
        propChildren
      ),
    [data],
  )

  const handleChange = (event) => {
    setValueState(event.target.value)
    propOnChange && propOnChange(event)
    // setErrorTrigger()
  }

  const handleReset = () => {
    setValueState(defaultValue)
    propOnReset && propOnReset()
  }

  const validator = (value) => {
    if (rest.required && [0, '0'].includes(value)) return 'Campo requerido'
  }

  return (
    <Input
      {...rest}
      ref={ref}
      defaultValue={defaultValue}
      value={value}
      onChange={handleChange}
      onReset={handleReset}
      disabled={rest.disabled || loading}
      customInput={CustomInput}
      children={customChildren}
      triggerErrorCheck={errorTrigger}
      validator={validator}
    />
  )
})

export default UIFormInputSelect
