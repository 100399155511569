import React from 'react'

const UIFormSeparation = ({ text }) => {
  return (
    <>
      <hr />
      <p className="text-muted font-weight-light ml-3">{text}</p>
    </>
  )
}

export default UIFormSeparation
