import { gql } from '../../../../../../hooks'

export const DELETE_SITES_CASES = gql`
  mutation AdministrationDeleteClientSitesCases($id: ID!) {
    aDelete(path: "client/sites/cases", id: $id) {
    }
  }
`

export const LIST_SITES_CASES = gql`
  query AdministrationListClientSitesCases(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(
      path: "client/sites/cases"
      filter: $filter
      page: $page
      limit: $limit
    ) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`
