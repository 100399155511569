import React from 'react'
import { Accordion, Card, Badge } from 'react-bootstrap'

// Components
import UIFormInputText from '../../../../UI/FormReload/Input/Text'
import UIFormInputTextarea from '../../../../UI/FormReload/Input/Textarea'
import UIPanelInfoTable from '../../../../UI/Panel/Info/Table'
import UITable from '../../../../UI/Table'
import UITableBody from '../../../../UI/Table/Body'
import UITableHead from '../../../../UI/Table/Head'
import UILoading from '../../../../UI/Loading'

// Hooks
import { useQuery } from '../../../../../hooks'

// Common
import { oe } from '../../../../../common/object/extractor'

import { AdministrationForm } from '../..'

// Queries
import {
  READ_STATES,
  CREATE_STATES,
  UPDATE_STATES,
  LIST_STATES,
  LIST_ESSAYS,
} from './queries'

const queries = {
  read: READ_STATES,
  create: CREATE_STATES,
  update: UPDATE_STATES,
  list: LIST_STATES,
}

const CustomForm = ({ action, data, id }) => {
  const { data: essays, loading } = useQuery(LIST_ESSAYS)

  const Syntax = ({ text }) => (
    <Badge
      variant="dark"
      style={{ fontSize: '9pt', padding: '5px 6px', margin: '0 2px' }}
    >
      {text}
    </Badge>
  )

  if (loading) return <UILoading />

  return (
    <>
      <UIPanelInfoTable>
        {[
          action === 'update'
            ? [
                'ID',
                <UIFormInputText inline name="id" placeholder={id} disabled />,
              ]
            : [],
          [
            'Descripción',
            <UIFormInputTextarea
              name="name"
              rows={8}
              initialValue={oe(data, ['name'])}
              placeholder={oe(data, ['name'])}
            />,
          ],
        ]}
      </UIPanelInfoTable>
      <Accordion>
        <Card>
          <Accordion.Toggle
            as={Card.Header}
            eventKey="0"
            style={{ cursor: 'pointer' }}
            className="text-center"
          >
            Info: Sintaxis sustituible
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <small>
                Tanto las <strong>acciones</strong> como los{' '}
                <strong>comentarios</strong> pueden contener las siguientes
                sintaxis sustituibles:
              </small>
              <UITable bordered className="mt-2">
                <UITableHead>{['Recurso', 'Sintaxis']}</UITableHead>
                <UITableBody>
                  <tr>
                    <td>Nombre ensayo</td>
                    <td>
                      <Syntax
                        text={
                          <>
                            {'{'}EN-<i>ID</i>
                            {'}'}
                          </>
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Valor ensayo</td>
                    <td>
                      <Syntax
                        text={
                          <>
                            {'{'}EV-<i>ID</i>
                            {'}'}
                          </>
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Valor ensayo previo</td>
                    <td>
                      <Syntax
                        text={
                          <>
                            {'{'}EV-P-<i>ID</i>
                            {'}'}
                          </>
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Continuidad componente</td>
                    <td>
                      <Syntax text="{HK-C}" />
                    </td>
                  </tr>
                  <tr>
                    <td>Continuidad lubricante</td>
                    <td>
                      <Syntax text="{HK-L}" />
                    </td>
                  </tr>
                  <tr>
                    <td>Continuidad equipo</td>
                    <td>
                      <Syntax text="{HK-E}" />
                    </td>
                  </tr>
                  <tr>
                    <td>Continuidad previa componente</td>
                    <td>
                      <Syntax text="{HK-P-C}" />
                    </td>
                  </tr>
                  <tr>
                    <td>Continuidad previa lubricante</td>
                    <td>
                      <Syntax text="{HK-P-L}" />
                    </td>
                  </tr>
                  <tr>
                    <td>Continuidad previa equipo</td>
                    <td>
                      <Syntax text="{HK-P-E}" />
                    </td>
                  </tr>
                </UITableBody>
              </UITable>
              <small>
                Donde
                <Syntax text={<i>ID</i>} />
                corresponde al ID del ensayo en <i>Nombre ensayo</i>,{' '}
                <i>Valor ensayo</i> y <i>Valor ensayo previo</i> :
              </small>
              <UITable bordered className="mt-2">
                <UITableHead>{['ID', 'Ensayo']}</UITableHead>
                <UITableBody>
                  {Object.keys(essays.aEssays.data).map((id_essay, index) => (
                    <tr key={`essay-${index}`}>
                      <td>
                        <Syntax text={id_essay} />
                      </td>
                      <td>{essays.aEssays.data[id_essay]}</td>
                    </tr>
                  ))}
                </UITableBody>
              </UITable>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  )
}

const AdministrationFormAnalysisStates = () => (
  <AdministrationForm name={'comentario'} queries={queries}>
    <CustomForm />
  </AdministrationForm>
)

export default AdministrationFormAnalysisStates
