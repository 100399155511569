import { gql } from '../../../../../hooks'

export const READ_PROTOCOLS = gql`
  query AdministrationReadComponentProtocols($id: ID!) {
    aRead(path: "component/protocols", id: $id) {
    }
  }
`

export const DELETE_PROTOCOLS = gql`
  mutation AdministrationDeleteComponentProtocols($id: ID!) {
    aDelete(path: "component/protocols", id: $id) {
    }
  }
`
