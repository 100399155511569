import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import {
  faCheckCircle,
  faTimesCircle,
  faPen,
  faDotCircle,
  faEquals,
} from '@fortawesome/free-solid-svg-icons'

import UIButton from '../../../../UI/Button'

// Hooks
import { useMutation, useState } from '../../../../../hooks'

// Queries
import { UPDATE_RESULTS_RESAMPLED } from './queries'

const ReSample = ({ idSample, idEssay, isResampled, value }) => {
  const [isIt, setIsIt] = useState(
    [null, undefined, 0].includes(isResampled) ? 0 : isResampled,
  )

  const [t5UpdateResultsResampled] = useMutation(UPDATE_RESULTS_RESAMPLED, {
    onErrorMessage: `No se pudo modificar el resultado`,
    onSuccess: () => setIsIt((p) => (p + 1) % 3),
  })

  const handleResample = () => {
    t5UpdateResultsResampled({
      variables: {
        id_sample: idSample,
        id_essay: idEssay,
        is_resampled: (isIt + 1) % 3,
      },
    })
  }

  return (
    <UIButton
      disabled={!value}
      variant={
        value
          ? isIt === 0
            ? 'info'
            : isIt === 1
            ? 'success'
            : 'warning'
          : 'secondary'
      }
      onClick={handleResample}
      style={{ fontSize: '11px' }}
    >
      <OverlayTrigger
        overlay={
          <Tooltip id="tooltip-disabled">
            {value
              ? isIt === 0
                ? 'Ensayo no repetido'
                : isIt === 1
                ? 'Ensayo repetido - Valor modificado'
                : 'Ensayo repetido - Valor mantenido'
              : 'Sin resultado'}
          </Tooltip>
        }
      >
        <FontAwesomeIcon
          icon={isIt === 1 ? faPen : isIt === 2 ? faEquals : faDotCircle}
        />
      </OverlayTrigger>
    </UIButton>
  )
}

export default ReSample
