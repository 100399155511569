import React from 'react'
import { Col, Row } from 'react-bootstrap'

// Hooks
import { useLocation } from '../../../../../hooks'

// Components
import ReportTableFleetStateMachine from '../../../Table/FleetState/Machine'

const ReportContainerFleetStateMachine = ({ id }) => {
  const search = useLocation().search

  const { from, to } = Object.fromEntries(new URLSearchParams(search))

  return (
    <Row>
      <Col className="mb-3">
        <ReportTableFleetStateMachine id={id} from={from} to={to} />
      </Col>
    </Row>
  )
}

export default ReportContainerFleetStateMachine
