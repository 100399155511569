import { gql } from '../../../../../hooks'

export const READ_RE_SAMPLING = gql`
  query AdministrationReadSamplingReSampling($id: ID!) {
    aRead(path: "sampling/resampling", id: $id) {
    }
  }
`

export const DELETE_RE_SAMPLING = gql`
  mutation AdministrationDeleteSamplingReSampling($id: ID!) {
    aDelete(path: "sampling/resampling", id: $id) {
    }
  }
`
