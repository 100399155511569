import React from 'react'
import { Col, Row } from 'react-bootstrap'

// Hooks
import { useState } from '../../../../../../hooks'

import TribologySamplesInsertMassiveReceptionForm from '../../../../../Tribology/Samples/Insert/Massive/Reception/Form'
import TribologySamplesInsertMassiveReceptionInfo from '../../../../../Tribology/Samples/Insert/Massive/Reception/Info'

const TribologySamplesInsertMassiveReception = (props) => {
  const [data, setDataState] = useState()

  const handleDataChange = (data) => {
    setDataState(data)
  }

  if (data)
    return (
      <Row className="my-4">
        <Col lg={6} className="mb-3">
          <TribologySamplesInsertMassiveReceptionForm
            onDataChange={handleDataChange}
          />
        </Col>
        <Col lg={6}>
          <TribologySamplesInsertMassiveReceptionInfo data={data} />
        </Col>
      </Row>
    )

  return (
    <Row className="my-4">
      <Col xs={12} className="mb-3">
        <TribologySamplesInsertMassiveReceptionForm
          onDataChange={handleDataChange}
        />
      </Col>
    </Row>
  )
}

export default TribologySamplesInsertMassiveReception
