import React from 'react'

// Components
import UIFormInputText from '../../../../../UI/FormReload/Input/Text'
import UIPanelInfoTable from '../../../../../UI/Panel/Info/Table'
import UIFormInputSelect from '../../../../../UI/FormReload/Input/Select'
import UIFormInputSearch from '../../../../../UI/FormReload/Input/Search'

// Common
import { oe } from '../../../../../../common/object/extractor'

import { AdministrationForm } from '../../..'

// Queries
import {
  READ_CLIENTS_CASES,
  CREATE_CLIENTS_CASES,
  UPDATE_CLIENTS_CASES,
  LIST_CLIENTS_CASES,
  SEARCH_CLIENT,
} from './queries'

const queries = {
  read: READ_CLIENTS_CASES,
  create: CREATE_CLIENTS_CASES,
  update: UPDATE_CLIENTS_CASES,
  list: LIST_CLIENTS_CASES,
}

const CustomForm = ({ action, data }) => (
  <UIPanelInfoTable>
    {[
      [
        'Cliente',
        <UIFormInputSearch
          name="id_client"
          query={SEARCH_CLIENT}
          initialValue={{
            value: oe(data, ['client', 'id']),
            name: oe(data, ['client', 'name']),
          }}
        />,
      ],
      [
        'Caso',
        <UIFormInputSelect
          name="id_case"
          initialValue={oe(data, ['case', 'id'], '1')}
          disabled={action === 'update'}
          required
        >
          <option value="1">Caso 1</option>
          <option value="2">Caso 2</option>
          <option value="3">Caso 3</option>
          <option value="4">Caso 4</option>
          <option value="5">Caso 5</option>
          <option value="6">Caso 6</option>
          <option value="7">Caso 7</option>
          <option value="8">Caso 8</option>
        </UIFormInputSelect>,
      ],
      [
        'Correos',
        <UIFormInputText
          text="Separados por coma"
          name="emails"
          initialValue={oe(data, ['case', 'emails'])}
          placeholder={oe(data, ['case', 'emails'])}
          required
        />,
      ],
    ]}
  </UIPanelInfoTable>
)

const AdministrationFormClientClientsCases = () => (
  <AdministrationForm name={'caso cliente'} queries={queries}>
    <CustomForm />
  </AdministrationForm>
)

export default AdministrationFormClientClientsCases
