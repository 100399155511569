import { gql } from '../../../../../hooks'

export const READ_TYPES = gql`
  query AdministrationReadMachineTypes($id: ID!) {
    aRead(path: "machine/types", id: $id) {
    }
  }
`

export const DELETE_TYPES = gql`
  mutation AdministrationDeleteMachineTypes($id: ID!) {
    aDelete(path: "machine/types", id: $id) {
    }
  }
`
