import React from 'react'
import { Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

const Logics = ({ title, rules, variant, id }) => {
  return (
    <Card>
      <Card.Header as="small" className={`alert alert-${variant} py-1 my-0`}>
        <strong>
          {title}
          {id && (
            <Link
              target="_blank"
              to={{
                pathname: `/administration/analysis/logics`,
                search: `?action=view&id=${id}`,
              }}
              className="alert-link"
            >
              {' '}
              <FontAwesomeIcon icon={faPen} />
            </Link>
          )}
        </strong>
      </Card.Header>
      {rules && (
        <Card.Body className="py-0 my-0">
          {rules &&
            Object.keys(rules).map((key) => {
              const { name, rule } = rules[key]

              return (
                <span
                  key={key}
                  className={`badge badge-${
                    [1, 4].includes(rule.id)
                      ? 'danger'
                      : [2, 3].includes(rule.id)
                      ? 'warning'
                      : 'success'
                  } py-1 px-2 m-1`}
                >
                  {name} : {rule.name}
                </span>
              )
            })}
        </Card.Body>
      )}
    </Card>
  )
}

// ----------------------------------------------------------------------------
//
// Main
//
// ----------------------------------------------------------------------------

const TribologySamplesReportLogics = ({ logic, type }) => {
  // Type:
  //
  // 0 - Normal logic
  // 1 - Exact logic
  // 2 - Approximate logic
  // 3 - Missing logic

  let title =
    type === 0
      ? 'Lógica Normal'
      : type === 1
      ? 'Lógica Exacta'
      : type === 2
      ? 'Lógica Aproximada'
      : 'Lógica no encontrada'
  let variant = [0, 1].includes(type)
    ? 'success'
    : type === 2
    ? 'warning'
    : 'danger'

  // switch (type) {
  //   case 0:
  //     title = 'Lógica Normal'
  //     variant = 'success'
  //   case 1:
  //     title = 'Lógica Exacta'
  //     variant = 'success'
  //   case 2:
  //     title = 'Lógica Aproximada'
  //     variant = 'warning'
  // }

  return (
    <Card>
      <Card.Header as="small" className={`alert alert-${variant} py-1 my-0`}>
        <strong>
          {title}
          {logic.id && (
            <Link
              target="_blank"
              to={{
                pathname: `/administration/analysis/logics`,
                search: `?action=view&id=${logic.id}`,
              }}
              className="alert-link"
            >
              {' '}
              <FontAwesomeIcon icon={faPen} />
            </Link>
          )}
        </strong>
      </Card.Header>
      {/* logic.rules && (
        <Card.Body className="py-0 my-0">
          {Object.keys(logic.rules).map((key) => {
            const { name, rule } = logic.rules[key]

            return (
              <span
                key={key}
                className={`badge badge-${
                  [1, 4].includes(rule.id)
                    ? 'danger'
                    : [2, 3].includes(rule.id)
                    ? 'warning'
                    : 'success'
                } py-1 px-2 m-1`}
              >
                {name} : {rule.name}
              </span>
            )
          })}
        </Card.Body>
      )*/}
    </Card>
  )
}

export default TribologySamplesReportLogics
