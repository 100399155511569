import React from 'react'
import { Route, Link, useLocation } from 'react-router-dom'

const translate = (word) => {
  const translation = {
    tribology: 'Tribología',
    tthermografy: 'Termografía',
    report: 'Informe',
    reports: 'Reportes',
    administration: 'Administración',
    insert: 'Ingreso',
    massive: 'Masivo',
    samples: 'Muestras',
    library: 'Biblioteca',
    recipt: 'Recepción',
    results: 'Resultados',
    list: 'Listado',
    analysis: 'Análisis',
    client: 'Cliente',
    component: 'Componente',
    lubricant: 'Lubricante',
    machine: 'Equipo',
    sampling: 'Muestreo',
    user: 'Usuarios',
    graphic: 'Gráfico',
    managing: 'Gestión',
    critic: 'Crítico',
    data: 'Datos',
    exakt: 'Exakt',
    fails: 'Fallas',
    fleet_refill: 'Relleno de Flotas',
    fleet_state: 'Estado de Flotas',
    historic_conditions: 'Histórico de Condiciones',
    invoicing: 'Facturación',
    iso_code: 'Código ISO',
    laboratory_kpi: 'KPI Laboratorio',
    samples_control: 'Control de Muestras',
    tracking: 'Seguimiento',
    working_hours: 'Horas de Trabajo',
    yamana_fleet_state: 'Estado de Flota Yamana',
    profile: 'Perfil',
    other: 'Otros',
    anomalies: 'Tipos de anomalía',
    limits: 'Límites',
    logics: 'Lógicas',
    suggestions: 'Acciones',
    states: 'Comentarios',
    clients: 'Clientes',
    clientsCases: 'Casos Cliente',
    sites: 'Faenas',
    sitesCases: 'Casos Faena',
    components: 'Componentes',
    brands: 'Marcas',
    models: 'Modelos',
    types: 'Tipos',
    changeMotives: 'Motivos de Cambio',
    protocols: 'Protocolos',
    collections: 'Cobranza',
    lubricants: 'Lubricantes',
    changeRefillMotives: 'Motivos de Cambio/Relleno',
    machines: 'Equipos',
    analysisMethods: 'Métodos de Análisis',
    procedures: 'Procedimientos de Muestreo',
    points: 'Puntos de Muestreo',
    essays: 'Ensayos',
    classifications: 'Clasificaciones de Ensayo',
    units: 'Unidades de Medida',
    users: 'Usuarios',
  }

  if (translation[word]) return translation[word]

  return word
}

const BreadcrumbsItem = ({ match, ...props }) => {
  const {
    isExact,
    url,
    params: { path },
  } = match

  const classes = ['breadcrumb-item']

  if (isExact) classes.push('active')

  return (
    <>
      <li className={classes.join(' ')}>
        {isExact ? (
          <small className="mt-1">{translate(path)}</small>
        ) : (
          <Link to={url}>
            <small>{translate(path)}</small>
          </Link>
        )}
      </li>
      <Route
        path={`${url}/:path`}
        component={(props) => <BreadcrumbsItem {...props} />}
      />
    </>
  )
}

const UIBreadCrumb = (props) => {
  // const { pathname } = props.location

  const { pathname } = useLocation()

  return (
    <>
      {pathname !== '/' && (
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb px-3 py-1">
            <Route
              path="/:path"
              component={(props) => <BreadcrumbsItem {...props} />}
            />
          </ol>
        </nav>
      )}
    </>
  )
}

export default UIBreadCrumb
