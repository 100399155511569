import React from 'react'

// Hooks
import { useLocation } from '../../../../../hooks'

// Components
import ReportTableFailsExcel from '../../../Table/Fails/Excel'

const ReportContainerFailsExcel = () => {
  const search = useLocation().search

  return (
    <ReportTableFailsExcel
      filter={Object.fromEntries(new URLSearchParams(search))}
    />
  )
}

export default ReportContainerFailsExcel
