import React from 'react'
export * from './Block'
export * from './Item'

const UIPanelInfo = ({ children, centered, style = 'stng' }) => {
  const classes = [
    'shadow-sm',
    'w-100',
    'p-3',
    'bg-white',
    'rounded',
    'border',
    `border-${style}`,
    'd-flex',
    'flex-column',
    'justify-content-center',
    'mb-3',
  ]

  if (centered) classes.push('align-items-center')

  return (
    <div className={classes.join(' ')} style={{ minHeight: '100px' }}>
      {children}
    </div>
  )
}

export default UIPanelInfo
