import React from 'react'
import { Row, Col, Form, Accordion, Card, Badge } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

// Hooks
import { useQuery } from '../../../../hooks'

// Components
import UIButton from '../../../UI/Button'
import UILoading from '../../../UI/Loading'
import UIError from '../../../UI/Error'
import UIEmpty from '../../../UI/Empty'
import UIFormSeparation from '../../../UI/FormReload/Separation'
import UITable from '../../../UI/Table'
import UITableHead from '../../../UI/Table/Head'
import UITableBody from '../../../UI/Table/Body'

import InfoHeader from '../../Container/InfoHeader'

// Excel export
import ButtonDownload from './download'

// Queries
import { GET_REPORT } from './queries'

const ReportTableUnSampled = ({ filter, onFilterChange }) => {
  // const { id_client, id_site, id_machine_type, id_machine, from, to } = filter
  const { from, to } = filter

  // --------------------------------------------------------------------------
  //
  // Query report
  //
  // --------------------------------------------------------------------------

  const { data, loading } = useQuery(GET_REPORT, {
    variables: { ...filter },
    onErrorMessage: `Error al intentar obtener reporte`,
  })

  // --------------------------------------------------------------------------
  //
  // Render
  //
  // --------------------------------------------------------------------------

  if (loading) return <UILoading />

  if (data && data.reportUnSampled) {
    const { error } = data.reportUnSampled

    // ------------------------------------------------------------------------
    //
    // Info Body
    //
    // ------------------------------------------------------------------------

    let InfoBody
    let showExcelButton

    // Errors?
    // ------------------------------------------------------------------------
    if (error) {
      showExcelButton = false
      InfoBody = (
        <div className="text-center  py-5 border rounded">
          <FontAwesomeIcon
            size="10x"
            color="lightgray"
            className="mr-2"
            icon={faBan}
          />

          <h5 className="text-muted font-weight-light mt-5">{error.message}</h5>
        </div>
      )
    }
    // No errors
    // ------------------------------------------------------------------------
    else {
      const { results } = data.reportUnSampled.data

      // Empty?
      // ----------------------------------------------------------------------
      if (results.length === 0) {
        showExcelButton = false
        InfoBody = <UIEmpty />
      }
      // Not empty
      // ----------------------------------------------------------------------
      else {
        showExcelButton = true
        const classNameHeader = 'bg-stng border text-white align-middle'

        InfoBody = (
          <UITable borderless>
            <UITableHead className={classNameHeader}>
              {[
                'Faena',
                'Equipo',
                'Componente',
                'Descripción componente',
                'Última muestra',
                'Condición última muestra',
                'Fecha muestra',
                'Intervalo muestreo',
                'Días de atraso',
              ]}
            </UITableHead>
            <UITableBody>
              {results.map((row) => {
                const intervals = JSON.parse(
                  row.component_unsampled_interval_type,
                )

                return (
                  <tr key={`row-${row.sample_id}`}>
                    <td>{row.site_name}</td>
                    <td>{row.machine_name}</td>
                    <td>{row.component_tag}</td>
                    <td>{row.component_name}</td>
                    <td>
                      <Link
                        to={`/tribology/samples/report/pdf/${row.sample_id}`}
                        className={`text-decoration-none`}
                        target="_blank"
                      >
                        {row.sample_id}
                      </Link>
                    </td>
                    <td>{row.sample_condition_name}</td>
                    <td>{moment(row.date_proposed).format('DD-MM-YYYY')}</td>
                    <td>
                      {row.component_unsampled_interval}{' '}
                      {[0, null, undefined, ''].includes(
                        row.component_unsampled_interval,
                      )
                        ? intervals[0]
                        : intervals[1]}
                    </td>
                    <td>{row.unsampled_interval_days}</td>
                  </tr>
                )
              })}
            </UITableBody>
          </UITable>
        )
      }
    }

    const handleBack = () => onFilterChange()

    return (
      <>
        <Form.Group as={Row} className="m-1">
          <Col className="d-flex">
            <UIButton type="submit" className="mx-2" onClick={handleBack}>
              Atrás
            </UIButton>
            {showExcelButton && <ButtonDownload filter={filter} />}
          </Col>
        </Form.Group>
        <Row className="m-1">
          <Col xs={12}>
            <UIFormSeparation />
          </Col>
          {!error && (
            <Col xs={12}>
              <InfoHeader
                info={{
                  ...data.reportUnSampled.data.info,
                  from,
                  to,
                }}
              />
            </Col>
          )}
          <Col xs={12}>{InfoBody}</Col>
        </Row>
      </>
    )
  }

  return <UIError />
}

export default ReportTableUnSampled
