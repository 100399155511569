import React from 'react'

// Components
import AdministrationListSamplingPoints from '../../../../Administration/List/Sampling/Points'
import AdministrationInfoSamplingPoints from '../../../../Administration/Info/Sampling/Points'
import AdministrationFormSamplingPoints from '../../../../Administration/Form/Sampling/Points'

import { AdministrationContainer } from '../..'

const AdministrationContainerSamplingPoints = () => (
  <AdministrationContainer
    form={AdministrationFormSamplingPoints}
    info={AdministrationInfoSamplingPoints}
    list={AdministrationListSamplingPoints}
  />
)

export default AdministrationContainerSamplingPoints
