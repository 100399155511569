import React from 'react'
import { Card, Container, Row, Col } from 'react-bootstrap'
import './style.scss'

// Global reducer
import {
  useMemo,
  useState,
  useGlobal,
  useMutation,
  useHistory,
} from '../../hooks'

// Components
import UIAlert from '../../components/UI/Alert'
import UIButton from '../../components/UI/Button'
import UIForm from '../../components/UI/FormReload'
import UIFormInputText from '../../components/UI/FormReload/Input/Text'
import UIFormInputPassword from '../../components/UI/FormReload/Input/Password'

// Queries
import { SIGN_IN } from './queries'

const PageLogin = () => {
  const [, dispatch] = useGlobal()

  const history = useHistory()

  // const [tries, setTriesState] = useState(0)

  const [logIn, { data: { signIn: { error } } = { signIn: { error: null } } }] =
    useMutation(SIGN_IN, {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        dispatch({ type: 'updateMe', me: data.signIn.data })

        // Force a small waiting time to ensure the render won't
        // come first, giving the dispatch function time to update
        // the global "me", which has all the user info
        // setTimeout(() => {
        //  history.push(`/`)
        // }, 1000)
      },
      onError: (error) => {
        // TODO show an alert to the user
        //  setTriesState((prev) => ++prev)
      },
    })

  // if (me) history.push(`/`)

  const handleSubmit = ({ username, password }) => {
    logIn({
      variables: {
        username,
        password,
        // tries,
      },
    })
  }

  const messages = [
    'Welcome Back!',
    'Iniciar Sesión',
    'Es Bueno Volver a Verte!',
    'Iniciar Sesión',
  ]

  // <Col
  //   md={4}
  //   style={{ backgroundColor: '#364f6b' }}
  //   className="h-100 d-none d-md-block"
  // ></Col>

  const bgColor = '47688d'
  const fgColor = 'fce38a'

  // %3Csvg viewbox='0 0 1440 320' xmlns='http://www.w3.org/2000/svg'%3E
  // %3Cdefs%3E
  // %3Cpath id='sineWave' fill='url(%23grad1)' fill-opacity='0.9' d='M0,160 C320,300,420,300,740,160 C1060,20,1120,20,1440,160 V0 H0' /%3E
  // %3ClinearGradient id='grad1' gradientTransform='rotate(90)'%3E
  // %3Cstop offset='0%25' stop-color='%23fbd758' /%3E
  // %3Cstop offset='100%25' stop-color='%23fcefbc' /%3E
  // %3C/linearGradient%3E%3C/defs%3E%3C/svg%3E

  const genWaves = ({ seconds, direction, scaleY, index, colors, d }) => ({
    // _class: `.wave${index} %7B animation: wave${index} ${seconds}s linear infinite; %7D`,
    // keyFrame: `@keyframes wave${index} %7B 0%25 %7B transform: scaleY(${scaleY}) translateX(${
    //   direction === 'left' ? 0 : 100
    // }%25); %7D 100%25 %7B transform: scaleY(${scaleY}) translateX(${
    //   direction === 'left' ? 100 : 0
    // }%25); %7D %7D`,
    use: [
      `%3Cuse class='wave${index}' href='%23path${index}'  /%3E`,
      `%3Cuse class='wave${index}' x='-100%25' href='%23path${index}' /%3E`,
    ].join(''),
    // path: `%3Cpath id='path${index}' fill='%23${colors.from}' stroke='%23${
    path: `%3Cpath id='path${index}' fill='url(%23grad${index})' fill-opacity='1' d='M${
      d.x + d.offsetX + d.bias.x1
    },${d.y + d.offsetY + d.bias.y1} C${
      d.c1.start.x1 + d.offsetX + d.bias.x1
    },${d.c1.start.y1 + d.offsetY + d.bias.y1},${
      d.c1.end.x2 + d.offsetX + d.bias.x2
    },${d.c1.end.y2 + d.offsetY + d.bias.y2},${
      d.c1.end.x + d.offsetX + d.bias.x2
    },${d.c1.end.y + d.offsetY + d.bias.y2} C${
      d.c2.start.x1 + d.offsetX + d.bias.x2
    },${d.c2.start.y1 + d.offsetY + d.bias.y2},${
      d.c2.end.x2 + d.offsetX + d.bias.x1
    },${d.c2.end.y2 + d.offsetY + d.bias.y1},${
      d.c2.end.x + d.offsetX + d.bias.x1
    },${
      d.c2.end.y + d.offsetY + d.bias.y1
    } V0 H0' /%3E%3ClinearGradient id='grad${index}' gradientTransform='rotate(90)'%3E%3Cstop offset='5%25' stop-color='%23${
      colors.from
    }' /%3E%3Cstop offset='95%25' stop-color='%23${
      colors.to
    }' /%3E%3C/linearGradient%3E`,
    // path: `%3Cpath id='path${index}' fill='url(%23grad${index})' fill-opacity='0.9' d='M0,160 C320,300,420,300,740,160 C1060,20,1120,20,1440,160 V0 H0' /%3E%3ClinearGradient id='grad${index}' gradientTransform='rotate(90)'%3E%3Cstop offset='0%25' stop-color='%23${colors.from}' /%3E%3Cstop offset='100%25' stop-color='%23${colors.to}' /%3E%3C/linearGradient%3E`,
  })

  const genBackgroundImage = useMemo(() => {
    const waves = [
      {
        seconds: 19,
        direction: 'right',
        scaleY: 1,
        colors: { from: 'fcefbc', to: 'fbd758' },
        d: {
          offsetY: Math.floor(
            Math.min(Math.max(Math.random() * 600, 350), 650),
          ), // 350
          offsetX: Math.floor(Math.random() * 700), // 0
          x: 0,
          y: 160,
          bias: { x1: 0, y1: 0, x2: 0, y2: 0 },
          c1: {
            start: { x1: 320, y1: 300 },
            end: { x2: 420, y2: 300, x: 740, y: 160 },
          },
          c2: {
            start: { x1: 1060, y1: 20 },
            end: { x2: 1120, y2: 20, x: 1440, y: 160 },
          },
        },
        // d: 'M0,160 C320,300,420,300,740,160 C1060,20,1120,20,1440,160 V0 H0',
      },
      {
        seconds: 19,
        direction: 'right',
        scaleY: 1,
        colors: { from: 'fbd758', to: 'fcefbc' },
        d: {
          offsetY: Math.floor(
            Math.min(Math.max(Math.random() * 500, 200), 350),
          ), // 350
          offsetX: Math.floor(Math.random() * 900), // 1150
          x: 0,
          y: 160,
          bias: { x1: 0, y1: 0, x2: 0, y2: 100 },
          c1: {
            start: { x1: 320, y1: 300 },
            end: { x2: 420, y2: 300, x: 740, y: 160 },
          },
          c2: {
            start: { x1: 1060, y1: 20 },
            end: { x2: 1120, y2: 20, x: 1440, y: 160 },
          },
        },
        // d: 'M0,160 C320,300,420,300,740,160 C1060,20,1120,20,1440,160 V0 H0',
      },
      {
        seconds: 19,
        direction: 'right',
        scaleY: 1,
        colors: { from: '47688d', to: '47688d' },
        d: {
          offsetY: Math.floor(Math.min(50 + Math.random() * 100, 200)), // 50
          offsetX: Math.floor(Math.random() * 600), // 350
          x: 0,
          y: 160,
          bias: { x1: 0, y1: 0, x2: 0, y2: 0 },
          c1: {
            start: { x1: 320, y1: 300 },
            end: { x2: 420, y2: 300, x: 740, y: 160 },
          },
          c2: {
            start: { x1: 1060, y1: 20 },
            end: { x2: 1120, y2: 20, x: 1440, y: 160 },
          },
        },
        // d: 'M0,160 C320,300,420,300,740,160 C1060,20,1120,20,1440,160 V0 H0',
      },
      {
        seconds: 19,
        direction: 'right',
        scaleY: 1,
        colors: { from: '364f6b', to: '364f6b' },
        d: {
          offsetY: 0,
          offsetX: Math.floor(Math.min(Math.random() * 600, 400)), // 350
          x: 0,
          y: 160,
          bias: { x1: 0, y1: 0, x2: 0, y2: 0 },
          c1: {
            start: { x1: 320, y1: 300 },
            end: { x2: 420, y2: 300, x: 740, y: 160 },
          },
          c2: {
            start: { x1: 1060, y1: 20 },
            end: { x2: 1120, y2: 20, x: 1440, y: 160 },
          },
        },
        // d: 'M0,160 C320,300,420,300,740,160 C1060,20,1120,20,1440,160 V0 H0',
      },
    ].map((values, index) => genWaves({ ...values, index }))

    return `url("data:image/svg+xml,%3Csvg viewbox='0 0 1440 1320' transform='scale(1,-1)' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3Cstyle type='text/css'%3E ${waves
      .map(({ _class }) => _class)
      .join(' ')} ${waves
      .map(({ keyFrame }) => keyFrame)
      .join(' ')} %3C/style%3E ${waves
      .map(({ path }) => path)
      .join(' ')} %3C/defs%3E ${waves
      .map(({ use }) => use)
      .join(' ')} %3C/svg%3E")`
  }, [])

  // Designs from https://www.magicpattern.design/tools/css-backgrounds
  const styles = [
    // {
    //   backgroundImage: "url('/imgs/waves/wave2.svg')",
    //   backgroundPosition: 'center',
    //   backgroundRepeat: 'no-repeat',
    //   // backgroundSize: '2500px auto',
    //   backgroundSize: 'cover',
    //   border: 'solid red 2px',
    // },
    {
      //       backgroundImage: `url("data:image/svg+xml,%3Csvg viewbox='0 0 1440 320' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3Cstyle type='text/css'%3E .wave %7B animation: wave 8s linear infinite; %7D .wave1 %7B animation: wave1 10s linear infinite; %7D .wave2 %7B animation: wave2 12s linear infinite; %7D @keyframes wave %7B 0%25 %7B transform: translateX(0%25); %7D 100%25 %7B transform: translateX(100%25); %7D %7D @keyframes wave1 %7B 0%25 %7B transform: scaleY(1.2) translateX(0%25); %7D 100%25 %7B transform: scaleY(1.2) translateX(100%25); %7D %7D @keyframes wave2 %7B 0%25 %7B transform: scaleY(.8) translateX(0%25); %7D 100%25 %7B transform: scaleY(.8) translateX(100%25); %7D %7D %3C/style%3E%3Cpath id='sineWave' fill='%230099ff' fill-opacity='0.2' d='M0,160 C320,300,420,300,740,160 C1060,20,1120,20,1440,160 V0 H0' /%3E%3C/defs%3E%3Cuse class='wave' href='%23sineWave' /%3E%3Cuse class='wave' x='-100%25' href='%23sineWave' /%3E%3Cuse class='wave1' href='%23sineWave' /%3E%3Cuse class='wave1' x='-100%25' href='%23sineWave' /%3E%3Cuse class='wave2' href='%23sineWave' /%3E%3Cuse class='wave2' x='-100%25' href='%23sineWave' /%3E%3C/svg%3E")`,
      //       backgroundImage: `url("data:image/svg+xml,%3Csvg viewbox='0 0 1440 1320' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3Cstyle type='text/css'%3E .wave %7B animation: wave 8s linear infinite; %7D .wave1 %7B animation: wave1 10s linear infinite; %7D .wave2 %7B animation: wave2 12s linear infinite; %7D .wave3 %7B animation: wave3 20s linear infinite; %7D .wave4 %7B animation: wave4 28s linear infinite; %7D @keyframes wave %7B 0%25 %7B transform: translateX(0%25); %7D 100%25 %7B transform: translateX(100%25); %7D %7D @keyframes wave1 %7B 0%25 %7B transform: scaleY(1.2) translateX(0%25); %7D 100%25 %7B transform: scaleY(1.2) translateX(100%25); %7D %7D @keyframes wave2 %7B 0%25 %7B transform: scaleY(.8) translateX(100%25); %7D 100%25 %7B transform: scaleY(.8) translateX(0%25); %7D %7D @keyframes wave3 %7B 0%25 %7B transform: scaleY(.7) translateX(0%25); %7D 100%25 %7B transform: scaleY(.7) translateX(100%25); %7D %7D @keyframes wave4 %7B 0%25 %7B transform: scaleY(2) translateX(100%25); %7D 100%25 %7B transform: scaleY(2) translateX(0%25); %7D %7D %3C/style%3E%3Cpath id='sineWave' fill='%230099ff' fill-opacity='0.2' d='M0,160 C320,300,420,300,740,160 C1060,20,1120,20,1440,160 V0 H0' /%3E%3C/defs%3E%3Cuse class='wave' href='%23sineWave' /%3E%3Cuse class='wave' x='-100%25' href='%23sineWave' /%3E%3Cuse class='wave1' href='%23sineWave' /%3E%3Cuse class='wave1' x='-100%25' href='%23sineWave' /%3E%3Cuse class='wave2' href='%23sineWave' /%3E%3Cuse class='wave2' x='-100%25' href='%23sineWave' /%3E%3Cuse class='wave3' href='%23sineWave' /%3E%3Cuse class='wave3' x='-100%25' href='%23sineWave' /%3E%3Cuse class='wave4' href='%23sineWave' /%3E%3Cuse class='wave4' x='-100%25' href='%23sineWave' /%3E%3C/svg%3E")`,
      backgroundImage: genBackgroundImage,
      backgroundSize: '1440px auto',
      backgroundColor: `#${bgColor}`,
    },
    // Zig-Zag 3D
    /*{
      backgroundColor: `${bgColor}`,
      opacity: 1,
      background: `linear-gradient(135deg, ${fgColor}55 25%, transparent 25%) -37px 0/ 74px 74px, linear-gradient(225deg, ${fgColor} 25%, transparent 25%) -37px 0/ 74px 74px, linear-gradient(315deg, ${fgColor}55 25%, transparent 25%) 0px 0/ 74px 74px, linear-gradient(45deg, ${fgColor} 25%, ${bgColor} 25%) 0px 0/ 74px 74px`,
    },
    // Rhombus
    {
      backgroundColor: `${bgColor}`,
      opacity: 1,
      backgroundImage: `linear-gradient(135deg, ${fgColor} 25%, transparent 25%), linear-gradient(225deg, ${fgColor} 25%, transparent 25%), linear-gradient(45deg, ${fgColor} 25%, transparent 25%), linear-gradient(315deg, ${fgColor} 25%, ${bgColor} 25%)`,
      backgroundPosition: `70px 0, 70px 0, 0 0, 0 0`,
      backgroundSize: `70px 70px`,
      backgroundRepeat: `repeat`,
    },
    // Diagonal
    {
      backgroundColot: `${bgColor}`,
      opacity: `1`,
      background: `repeating-linear-gradient( 45deg, ${fgColor}, ${fgColor} 18.5px, ${bgColor} 18.5px, ${bgColor} 92.5px )`,
    },
    // Diagonal v2
    {
      backgroundColot: `${bgColor}`,
      opacity: `1`,
      background: `repeating-linear-gradient( -45deg, ${fgColor}, ${fgColor} 18.5px, ${bgColor} 18.5px, ${bgColor} 92.5px )`,
    },
    // Isometric (!)
    {
      backgroundColor: `${bgColor}`,
      opacity: `1`,
      backgroundImage: ` linear-gradient(30deg, ${fgColor} 12%, transparent 12.5%, transparent 87%, ${fgColor} 87.5%, ${fgColor}), linear-gradient(150deg, ${fgColor} 12%, transparent 12.5%, transparent 87%, ${fgColor} 87.5%, ${fgColor}), linear-gradient(30deg, ${fgColor} 12%, transparent 12.5%, transparent 87%, ${fgColor} 87.5%, ${fgColor}), linear-gradient(150deg, ${fgColor} 12%, transparent 12.5%, transparent 87%, ${fgColor} 87.5%, ${fgColor}), linear-gradient(60deg, ${fgColor}77 25%, transparent 25.5%, transparent 75%, ${fgColor}77 75%, ${fgColor}77), linear-gradient(60deg, ${fgColor}77 25%, transparent 25.5%, transparent 75%, ${fgColor}77 75%, ${fgColor}77)`,
      backgroundSize: `74px 130px`,
      backgroundPosition: `0 0, 0 0, 37px 65px, 37px 65px, 0 0, 37px 65px`,
    },

    // Polka
    {
      backgroundColot: `${bgColor}`,
      opacity: `1`,
      backgroundImage: `radial-gradient(${fgColor} 1.85px, ${bgColor} 1.85px)`,
      backgroundSize: `37px 37px`,
    },
    // Polka v2
    {
      backgroundColot: `${bgColor}`,
      opacity: `1`,
      backgroundImage: ` radial-gradient(${fgColor} 1.85px, transparent 1.85px), radial-gradient(${fgColor} 1.85px, ${bgColor} 1.85px)`,
      backgroundSize: `74px 74px`,
      backgroundPosition: `0 0,37px 37px`,
    },
    // Cross
    {
      backgroundColot: `${bgColor}`,
      opacity: `1`,
      background: `radial-gradient(circle, transparent 20%, ${bgColor} 20%, ${bgColor} 80%, transparent 80%, transparent), radial-gradient(circle, transparent 20%, ${bgColor} 20%, ${bgColor} 80%, transparent 80%, transparent) 92.5px 92.5px, linear-gradient(${fgColor} 7.4px, transparent 7.4px) 0 -3.7px, linear-gradient(90deg, ${fgColor} 7.4px, ${bgColor} 7.4px) -3.7px 0`,
      backgroundSize: `185px 185px, 185px 185px, 92.5px 92.5px, 92.5px 92.5px`,
    },
    // Lines v2
    {
      backgroundColot: `${bgColor}`,
      opacity: `1`,
      backgroundImage: `linear-gradient(to right, ${fgColor}, ${fgColor} 18.5px, ${bgColor} 18.5px, ${bgColor} )`,
      backgroundSize: `70px 100%`,
    },
    // Lines
    {
      backgroundColot: `${bgColor}`,
      opacity: `1`,
      backgroundImage: `linear-gradient(0deg, ${bgColor} 50%, ${fgColor} 50%)`,
      backgroundSize: `70px 70px`,
    },
    // Diagonal v3
    {
      backgroundColot: `${bgColor}`,
      opacity: `1`,
      backgroundSize: `37px 37px`,
      backgroundImage: `repeating-linear-gradient(45deg, ${fgColor} 0, ${fgColor} 3.7px, ${bgColor} 0, ${bgColor} 50%)`,
    },
    // Boxes
    {
      backgroundColot: `${bgColor}`,
      opacity: `1`,
      backgroundImage: ` linear-gradient(${fgColor} 3.7px, transparent 3.7px), linear-gradient(to right, ${fgColor} 3.7px, ${bgColor} 3.7px)`,
      backgroundSize: `74px 74px`,
    },
    // Triangle v2
    {
      backgroundColot: `${bgColor}`,
      opacity: `1`,
      backgroundImage: `linear-gradient(-45deg, ${bgColor}, ${bgColor} 50%, ${fgColor} 50%, ${fgColor})`,
      backgroundSize: `70px 70px`,
    },*/
  ]

  return (
    <Container
      fluid
      className="h-100"
      style={styles[0] /*styles[Math.floor(Math.random() * styles.length)]*/}
    >
      <Row className="align-items-center h-100">
        <Col
          sm={{ span: 10, offset: 1 }}
          md={{ span: 8, offset: 2 }}
          lg={{ span: 6, offset: 3 }}
          xl={{ span: 4, offset: 4 }}
        >
          <Card
            className="p-sm-5 py-5 p-2 m-1 m-sm-5"
            style={{
              backgroundColor: '#364f6b',
              borderRadius: '20px',
              borderWidth: '3px',
            }}
            border="#364f6b"
          >
            <Card.Img
              variant="top"
              className="px-5"
              src="/imgs/logo/coddi-light.png"
            />

            {error && error.message && (
              <UIAlert style="danger" className="my-2">
                {error.message}
              </UIAlert>
            )}

            <Card.Body>
              <hr />
              <Card.Text className="text-center text-light">
                {messages[Math.floor(Math.random() * messages.length)]}
              </Card.Text>
              <Card.Text>
                <UIForm onSubmit={handleSubmit}>
                  <UIFormInputText
                    name="username"
                    placeholder="Nombre de usuario"
                    autoComplete="on"
                  />
                  <UIFormInputPassword
                    name="password"
                    placeholder="Contraseña"
                    containerClassName="my-1"
                    autoComplete="on"
                  />
                  <UIButton
                    variant="stng-dark"
                    className="btn btn-lg btn-block my-3 btn-login"
                    type="submit"
                  >
                    Iniciar sesión
                  </UIButton>
                </UIForm>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default PageLogin
