import { gql } from '../../../../../hooks'

export const DELETE_ESSAYS = gql`
  mutation AdministrationDeleteSamplingEssays($id: ID!) {
    aDelete(path: "sampling/essays", id: $id) {
    }
  }
`

export const LIST_ESSAYS = gql`
  query AdministrationListSamplingEssays(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(
      path: "sampling/essays"
      filter: $filter
      page: $page
      limit: $limit
    ) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`
