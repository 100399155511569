import React from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'

const UIError = ({
  error = 'Lo sentimos, ha ocurrido un error',
  title = 'Error',
  message,
  placement = 'top',
}) => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      {message ? (
        <OverlayTrigger
          trigger="click"
          key={placement}
          placement={placement}
          overlay={
            <Popover id={`popover-positioned-${placement}`}>
              <Popover.Title as="h3">{title}</Popover.Title>
              <Popover.Content>{message}</Popover.Content>
            </Popover>
          }
        >
          <small
            className="text-danger font-weight-light"
            style={{ cursor: 'help' }}
          >
            {error}
          </small>
        </OverlayTrigger>
      ) : (
        <small className="text-danger font-weight-light">{error}</small>
      )}
    </div>
  )
}

export default UIError
