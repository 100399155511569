import React from 'react'
//  import { Row, Col } from 'react-bootstrap'
import { useParams } from 'react-router-dom'

import SamplePDF from '../../../../../components/Tribology/Samples/Report/PDF'

const PageTribologySamplesReportPDF = ({ auth }) => {
  const { id } = useParams()

  return <SamplePDF id={id} />
}

export default PageTribologySamplesReportPDF
