import React from 'react'
// import { Button } from 'react-bootstrap'

// Hooks
import {
  useGlobal,
  useMutation,
  useState,
  useEffect,
} from '../../../../../../../hooks'

// Components
// import UILoading from '../../../../../../../components/UI/Loading'
import UIButton from '../../../../../../../components/UI/Button'
import UITable from '../../../../../../../components/UI/Table'
import UITableHead from '../../../../../../../components/UI/Table/Head'
import UITableBody from '../../../../../../../components/UI/Table/Body'
import UIPanelInfo from '../../../../../../../components/UI/Panel/Info'
import UIPanelInfoBlock from '../../../../../../../components/UI/Panel/Info/Block'
// import UIPanelInfoItem from '../../../../../../../components/UI/Panel/Info/Item'

// Queries
import { RECEIVE_SAMPLES_MASSIVE, GET_MASSIVE_SAMPLES } from './queries'

const SampleInfoInsertMassiveReception = ({ data }) => {
  const [{ me }] = useGlobal()

  const [isReceived, setIsReceivedState] = useState(false)

  useEffect(() => {
    setIsReceivedState(false)
  }, [data])

  // --------------------------------------------------------------------------
  //
  // Mutation: Insert samples
  //
  // --------------------------------------------------------------------------

  const [t2ReceiveSampleMassive] = useMutation(RECEIVE_SAMPLES_MASSIVE, {
    update: (cache, { data }) => {
      // Get anomalies
      // NOTE The 'id_client' variable is there to include a random variable
      // otherwise it ignores 'network-only' and uses the cache
      //
      // Related bug:
      // https://github.com/apollographql/react-apollo/issues/3315
      let { t2MassiveSamples } = cache.readQuery({
        query: GET_MASSIVE_SAMPLES,
        //        variables: { noise: me.id_laboratory }, // noise to the query
      })

      if (data && data.t2ReceiveSampleMassive)
        t2MassiveSamples.data = t2MassiveSamples.data.filter(
          ({ id }) => id !== parseInt(data.t2ReceiveSampleMassive.data),
        )

      // Write the cache
      cache.writeQuery({
        query: GET_MASSIVE_SAMPLES,
        data: {
          t2MassiveSamples,
        },
      })
    },
    onSuccessMessage: `La muestra fue recivida con éxito`,
    onSuccess: () => {
      setIsReceivedState(true)
    },
    onErrorMessage: `La muestra no pudo ser recivida`,
  })

  const handleClick = () => {
    t2ReceiveSampleMassive({ variables: { id: parseInt(data.id) } })
  }

  const { id, samples } = data

  return (
    <UIPanelInfo>
      <UIPanelInfoBlock title={`Muestras masivas (${id})`}>
        <br />
        <UITable borderless className="text-center font-weight-light my-4">
          <UITableHead>
            <tr>
              <td className="bg-stng border text-white align-middle">
                <small>Correlativo muestra</small>
              </td>
              <td className="bg-stng border text-white align-middle">
                <small>Tag</small>
              </td>
              <td className="bg-stng border text-white align-middle">
                <small>Descripción</small>
              </td>
              <td className="bg-stng border text-white align-middle">
                <small>Fecha ingreso</small>
              </td>
            </tr>
          </UITableHead>
          <UITableBody>
            {samples.map(({ id, date_entry, tag, name }) => (
              <tr key={id}>
                <td>{id}</td>
                <td>{tag}</td>
                <td>{name}</td>
                <td>{date_entry}</td>
              </tr>
            ))}
          </UITableBody>
        </UITable>
        <UIButton onClick={handleClick} disabled={isReceived}>
          Recepcionar muestras
        </UIButton>
      </UIPanelInfoBlock>
    </UIPanelInfo>
  )
}

export default SampleInfoInsertMassiveReception
