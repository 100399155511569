import React from 'react'

// Components
import UIPanelInfoBlock from '../../../../UI/Panel/Info/Block'
import UIPanelInfoItem from '../../../../UI/Panel/Info/Item'
import UIPanelInfoTable from '../../../../UI/Panel/Info/Table'
import UIFormInputText from '../../../../UI/FormReload/Input/Text'
import UIFormInputInteger from '../../../../UI/FormReload/Input/Integer'
import UIFormSeparation from '../../../../UI/FormReload/Separation'

// Common
import { oe } from '../../../../../common/object/extractor'

import AdministrationInfo, { RefLink } from '../..'

// Queries
import { READ_LOGICS, DELETE_LOGICS } from './queries'

const Info = (data) => (
  <UIPanelInfoBlock title="Lógica">
    <UIPanelInfoTable>
      {[
        ['ID', oe(data, ['id'])],
        ['Descripción', oe(data, ['name'])],
        [
          'Protocolo',
          <RefLink
            id={oe(data, ['protocol', 'id'])}
            tab={'component'}
            subtab={'protocols'}
          >
            {oe(data, ['protocol', 'name'])}
          </RefLink>,
        ],
        ['Condición', oe(data, ['condition', 'name'])],
        [
          'Tipo anomalía',
          <RefLink
            id={oe(data, ['anomaly', 'id'])}
            tab={'analysis'}
            subtab={'anomalies'}
          >
            {oe(data, ['anomaly', 'name'])}
          </RefLink>,
        ],
        ['Estado seguimiento', oe(data, ['tracking', 'name'])],
        [
          'Comentario',
          <RefLink
            id={oe(data, ['state', 'id'])}
            tab={'analysis'}
            subtab={'states'}
          >
            {oe(data, ['state', 'name'])}
          </RefLink>,
        ],
        [
          'Acción',
          <RefLink
            id={oe(data, ['suggestion', 'id'])}
            tab={'analysis'}
            subtab={'suggestions'}
          >
            {oe(data, ['suggestion', 'name'])}
          </RefLink>,
        ],
        [
          'Lógica',

          Object.keys(oe(data, ['rules'], {})).length ? (
            Object.keys(data.rules).map((id, index) => {
              const { name, rule } = data.rules[id]
              return (
                <span
                  key={`mini-rules-${data.protocol.id}-${index}`}
                  className={`badge badge-${
                    [1, 4].includes(rule.id)
                      ? 'danger'
                      : [2, 3].includes(rule.id)
                      ? 'warning'
                      : 'success'
                  } p-1 m-1`}
                  style={{ fontSize: '12px' }}
                >
                  {name} : {rule.name}
                </span>
              )
            })
          ) : (
            <span
              className={`badge badge-success p-1 m-1`}
              style={{ fontSize: '12px' }}
            >
              Normal{' '}
            </span>
          ),
        ],
      ]}
    </UIPanelInfoTable>
  </UIPanelInfoBlock>
)

const Form = () => (
  <>
    <UIFormInputInteger label="ID" name="id" />
    <UIFormInputText label="Descripción" name="name" />
    <UIFormInputInteger label="ID Protocolo" name="id_protocol" />
    <UIFormInputText label="Descripción Protocolo" name="protocol_name" />
  </>
)

const AdministrationInfoAnalysisLogics = ({ ...rest }) => (
  <AdministrationInfo
    queries={{ read: READ_LOGICS, delete: DELETE_LOGICS }}
    views={{ form: Form, info: Info }}
    {...rest}
  />
)

export default AdministrationInfoAnalysisLogics
