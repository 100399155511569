import { gql } from '../../../../../hooks'

export const READ_MODELS = gql`
  query AdministrationReadLubricantModels($id: ID!) {
    aRead(path: "lubricant/models", id: $id) {
    }
  }
`

export const DELETE_MODELS = gql`
  mutation AdministrationDeleteLubricantModels($id: ID!) {
    aDelete(path: "lubricant/models", id: $id) {
    }
  }
`

export const LIST_BRANDS = gql`
  query AdministrationMiscLubricantBrands {
    aLubricantBrands {
    }
  }
`
