import React from 'react'
import { Col, Row } from 'react-bootstrap'

import { useState, useParams } from '../../../../../hooks'

import TribologySamplesInsertIscaaList from '../../../../../components/Tribology/Samples/Insert/Iscaa/List'
import TribologySamplesInsertIscaaForm from '../../../../../components/Tribology/Samples/Insert/Iscaa/Form'
import TribologySamplesInsertIscaaInfo from '../../../../../components/Tribology/Samples/Insert/Iscaa/Info'

const PageTribologySamplesInsertIscaa = () => {
  const { id_massive } = useParams()

  if (id_massive)
    return (
      <Row className="m-4">
        <Col lg={12} className="mb-3">
          <TribologySamplesInsertIscaaForm id={id_massive} />
        </Col>
        {/*
        <Col lg={4}>
          <TribologySamplesInsertIscaaInfo id={id_massive} />
        </Col>
          */}
      </Row>
    )

  return (
    <Row className="m-4">
      <Col lg={12} className="mb-3">
        <TribologySamplesInsertIscaaList />
      </Col>
    </Row>
  )
}

export default PageTribologySamplesInsertIscaa
