import React from 'react'

const UIPanelInfoItem = ({ name, className, style, children }) => {
  const classes = `text-muted mb-0 pb-0 ${className}`
  const styles = { fontSize: '12px', ...style }

  // if (['string', 'number'].includes(typeof children))
  return (
    <div className={classes} style={styles}>
      {name && <strong>{name}:</strong>} {children}
    </div>
  )

  //  return (
  //    <div className={classes} style={styles}>
  //      {name && (
  //        <p>
  //          <strong>{name}:</strong>
  //        </p>
  //      )}{' '}
  //      {children}
  //    </div>
  //  )
}
export default UIPanelInfoItem
