import React from 'react'
import { Col, Row } from 'react-bootstrap'

// Hooks
import { useState } from '../../../../hooks'

// Components
import ReportFormFilter from '../../Form/Filter'
import ReportTableFleetState from '../../Table/FleetState'

const ReportContainerFleetState = (props) => {
  const [filter, setFilterState] = useState()

  const handleFilterChange = (filter) => {
    setFilterState(filter)
  }

  return (
    <Row>
      <Col className="mb-3">
        {filter ? (
          <ReportTableFleetState
            filter={filter}
            onFilterChange={handleFilterChange}
          />
        ) : (
          <ReportFormFilter
            onFilterChange={handleFilterChange}
            name="fleet_state"
          />
        )}
      </Col>
    </Row>
  )
}

export default ReportContainerFleetState
