import React from 'react'
import { Col, Row } from 'react-bootstrap'

// Hooks
import { useState } from '../../../../hooks'

// Side info
import TribologySamplesInsertForm from '../../../Tribology/Samples/Insert/Form'
import TribologySamplesInsertInfo from '../../../Tribology/Samples/Insert/Info'

const TribologySamplesInsert = (props) => {
  const [idComponent, setIdComponentState] = useState()
  const [sample, setSampleState] = useState()

  const handleIdComponentChange = (id_component) => {
    setIdComponentState(id_component)
  }

  const handleSampleChange = (sample) => {
    setSampleState(sample)
  }

  if (idComponent || sample)
    return (
      <>
        <Row className="my-4">
          <Col lg={8} className="mb-3">
            <TribologySamplesInsertForm
              onIdComponentChange={handleIdComponentChange}
              onSampleChange={handleSampleChange}
              sample={sample}
            />
          </Col>
          <Col lg={4}>
            <TribologySamplesInsertInfo
              idComponent={idComponent}
              sample={sample}
            />
          </Col>
        </Row>
      </>
    )

  return (
    <Row className="my-4">
      <Col xs={12} className="mb-3">
        <TribologySamplesInsertForm
          onIdComponentChange={handleIdComponentChange}
          onSampleChange={handleSampleChange}
        />
      </Col>
    </Row>
  )
}

export default TribologySamplesInsert
