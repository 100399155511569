import React, { forwardRef } from 'react'
import './style.css'

import {
  useRef,
  useEffect,
  useMemo,
  useState,
  useCallback,
  useTrigger,
} from '../../../../../../hooks'

import Input from '../..'
import { useFormContext } from '../../..'

const UIFormInputCheckboxSwitch = forwardRef((props, ref) => {
  const {
    value: propValue,
    initialValue: propInitialValue,
    defaultValue: propDefaultValue,
    on: propOn = 'On',
    off: propsOff = 'Off',
    onStyle: propOnStyle = 'danger',
    offStyle: propOffStyle = 'stng',
    width = '100px',
    height = '10px',
    onChange: propOnChange,
    onReset: propOnReset,
    ...rest
  } = props

  const isFirstRender = useRef(true)

  const context = useFormContext()

  const [checked, setCheckedState] = useState(
    propValue !== undefined
      ? !!props.value
      : propInitialValue !== undefined
      ? !!propInitialValue
      : propDefaultValue !== undefined
      ? !!propDefaultValue
      : context.initialValues[props.name] !== undefined
      ? !!context.initialValues[props.name]
      : context.defaultValues[props.name] != undefined
      ? !!context.defaultValues[props.name]
      : false,
  )

  useEffect(() => {
    if (isFirstRender.current) isFirstRender.current = false
    else setCheckedState(!!propValue)
  }, [propValue])

  const defaultChecked = useMemo(
    () =>
      propDefaultValue !== undefined
        ? !!propDefaultValue
        : context.defaultValues[props.name] !== undefined
        ? !!context.defaultValues[props.name]
        : false,
    [],
  )

  const [trigger, setTrigger] = useTrigger()

  const handleChange = (event) => {
    setCheckedState((prev) => !prev)
    propOnChange && propOnChange(event)
  }

  const handleReset = () => {
    setCheckedState(defaultChecked)
    setTrigger()
    propOnReset && propOnReset()
  }

  const className = useMemo(
    () =>
      [
        'toggle',
        'btn',
        `btn-sm`,
        checked ? `btn-${propOnStyle}` : `btn-${propOffStyle} off`,
      ].join(' '),
    [checked, propOffStyle, propOnStyle],
  )

  const onClasses = useMemo(
    () =>
      [
        'btn',
        `btn-${propOnStyle}`,
        `btn-sm`,
        'toggle-on',
        'font-weight-light',
      ].join(' '),
    [propOnStyle],
  )

  const offClasses = useMemo(
    () =>
      [
        'btn',
        `btn-${propOffStyle}`,
        `btn-sm`,
        'toggle-off',
        'font-weight-light',
      ].join(' '),
    [propOffStyle],
  )

  const CustomInput = useCallback(
    forwardRef((props, ref) => {
      const { className, style, ...rest } = props

      return (
        <div
          className={className}
          data-toggle="toggle"
          role="button"
          style={style}
          onClick={handleChange}
        >
          <input
            ref={ref}
            {...rest}
            style={style}
            type="checkbox"
            checked={checked}
          />
          <div className="toggle-group">
            <label className={onClasses}>{propOn}</label>
            <label className={offClasses}>{propsOff}</label>
            <span className="toggle-handle btn btn-light"></span>
          </div>
        </div>
      )
    }),
    [handleChange, onClasses, propOn, offClasses, propsOff],
  )

  return (
    <Input
      {...rest}
      ref={ref}
      checked={checked}
      value={checked}
      defaultValue={defaultChecked}
      customInput={CustomInput}
      className={className}
      style={{ width, height }}
      onReset={handleReset}
      triggerValueCheck={trigger}
    />
  )
})

export default UIFormInputCheckboxSwitch
