import React from 'react'

// Components
import UIGraphPie from '../../../../../../UI/Graph/Pie'

const GraphsPie = ({ data }) => {
  const total = data[0] + data[1] + data[2]

  let pieData = [
    {
      id: 'Normal',
      // id_report: 'normal',
      // label: 'Normal',
      label: `${parseFloat(((data[0] / total) * 100).toFixed(1))}%`,
      // value: parseFloat(((data[0] / total) * 100).toFixed(1)),
      value: data[0],
      color: 'rgba(40, 167, 69, 0.7)',
    },
    {
      id: 'Alerta',
      // id_report: 'alert',
      // label: 'Alerta',
      label: `${parseFloat(((data[1] / total) * 100).toFixed(1))}%`,
      // value: parseFloat(((data[1] / total) * 100).toFixed(1)),
      value: data[1],
      color: 'rgba(255, 193, 7, 0.7)',
    },
    {
      id: 'Crítico',
      // id_report: 'abnormal',
      // label: 'Crítico',
      label: `${parseFloat(((data[2] / total) * 100).toFixed(1))}%`,
      // value: parseFloat(((data[2] / total) * 100).toFixed(1)),
      value: data[2],
      color: 'rgba(220, 53, 69, 0.7)',
    },
  ]

  const options = {
    colors: (c) => pieData.filter(({ id }) => id === c.id)[0].color,
    enableRadialLabels: false,
    margin: { top: 0, right: 20, bottom: 60, left: 20 },
    legends: [
      {
        anchor: 'bottom',
        direction: 'column',
        justify: false,
        translateY: 60,
        itemWidth: 40,
        itemHeight: 17,
        symbolShape: 'circle',
      },
    ],
  }

  return <UIGraphPie data={pieData} {...options} />
}

export default GraphsPie
