import React from 'react'

import { AdministrationList } from '../../../List'

// Queries
import { LIST_SAMPLES } from './queries'

const table = [
  { th: 'ID', td: 'id' },
  { th: 'TAG', td: ['component', 'tag'] },
  { th: 'Cliente', td: ['client', 'name'] },
]

const queries = { list: LIST_SAMPLES }

const AdministrationListSamplingSamples = () => (
  <AdministrationList table={table} queries={queries} noCreate noDelete />
)

export default AdministrationListSamplingSamples
