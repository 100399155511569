//
// TODO Highlight row in list if editing element

import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPen,
  faInfo,
  faTimes,
  faPlus,
} from '@fortawesome/free-solid-svg-icons'

// Hooks
import {
  useState,
  useQuery,
  useMutation,
  useGlobal,
  useTrigger,
  useEffect,
  useHistory,
} from '../../../hooks'

// Components
import UIEmpty from '../../UI/Empty'
import UILoading from '../../UI/Loading'
import UIError from '../../UI/Error'
import UIPagination from '../../UI/Pagination'
import UITable from '../../UI/Table'
import UITableBody from '../../UI/Table/Body'
import UITableHead from '../../UI/Table/Head'
import UIButton from '../../UI/Button'
import UIModal from '../../UI/Modal'
import UIAlert from '../../UI/Alert'

// Common
import { oe } from '../../../common/object/extractor'

import { AdministrationContainerContext } from '../Container'

export const AdministrationList = ({
  queries = {},
  table = [
    { th: 'ID', td: 'id' },
    { th: 'Descripción', td: 'name' },
  ],
  // refKey is used to construct the main identifier of a row.
  // Usually it's just the primary key 'id' but we might have different names and composed primary keys,
  // in which case we concatenate them by a '-'.
  //
  // Scenarios where refKey is a:
  //  - String: The primary key is obtained directly. For example a refKey = 'id' will get "element['id']"
  //  - Array: The primary key is not in the first level of the element object. For example a refKey = ['client', 'id']
  //    will refer to "element['client']['id']"
  //  - Object : The primary key is a combination of 2 or more primary keys concatenated by '-'. Each object's key (the key name doesn't matter) represents a primary key. For example a refKey = { pk1: 'id', pk2: ['client', 'id'] } will construct "${elemet['id']}-${element['client']['id']}"
  refKey = 'id',
  noCreate,
  noUpdate,
  noView,
  noDelete,
}) => {
  const history = useHistory()

  const {
    data: { id } = {},
    action,
    filter,
    setDataState,
    setActionState,
    setTriggerReset,
    triggerRefetch,
  } = useContext(AdministrationContainerContext)

  const [{ me }] = useGlobal()

  const [modalTrigger, setModalTrigger] = useTrigger()

  // Pagination
  const [pagination, setPaginationState] = useState({ limit: 25, page: 1 })

  const [idToDelete, setIdToDelete] = useState()

  // Get samples to populate the table
  const { data, loading, error, refetch } = useQuery(queries.list, {
    fetchPolicy: 'network-only',
    variables: { filter, ...pagination },
    onSuccess: ({ pagination }) => setPaginationState({ ...pagination }),
    skip: !queries.list,
  })

  useEffect(() => data && refetch(), [me.id_laboratory])

  useEffect(() => {
    if (!loading && data) refetch()
  }, [triggerRefetch])

  // --------------------------------------------------------------------------
  //
  // Mutation
  //
  // --------------------------------------------------------------------------

  const [deleteMutation] = useMutation(queries.delete, {
    onSuccessMessage: 'El elemento se eliminó con éxito',
    onErrorMessage: 'El elemento no pudo ser eliminado',
  })

  if (loading) return <UILoading />

  if (error) {
    const { message, graphQLErrors } = error

    return (
      <UIAlert style="danger" noDismissible className="text-center">
        {oe(
          graphQLErrors[0],
          ['extensions', 'response', 'body', 'error', 'message'],
          message,
        )}
      </UIAlert>
    )
  }

  if (data && data.aList) {
    const { data: elements, pagination } = data.aList

    const handlePagination = (page) => {
      setPaginationState({ ...pagination, page })
    }

    // Modal handler
    const handleAcceptModal = () => {
      deleteMutation({ variables: { id: idToDelete } })
      if (id && String(id) === idToDelete) setDataState({ pagination })
      // setActionState('view')
      refetch()
    }
    const hancleCancelModal = () => {
      setIdToDelete()
    }

    // Handlers
    const handleDelete = (id) => {
      setIdToDelete(id)
      setModalTrigger()
    }

    const handleUpdate = (id) => {
      history.replace({
        search: `?action=update&id=${id}`,
      })
      setActionState('update')
      setDataState({ id, pagination })
      // setVariantState()
    }

    const handleView = (id) => {
      history.replace({
        search: `?action=view&id=${id}`,
      })
      setActionState('view')
      setDataState({ id, pagination })
      // setVariantState()
    }

    const handleCreate = () => {
      history.replace({
        search: `?action=create`,
      })
      setActionState('create')
      setDataState({ pagination })
      setTriggerReset()
      // setVariantState()
    }

    const style = { width: '15px' }

    const th = table.map(({ th }) => th)
    const td = table.map(({ td }) => td)

    const getElement = (element, key) =>
      Array.isArray(key) ? key.reduce((p, k) => p[k], element) : element[key]

    const constructKey = (element, key) => {
      const keyIsObject = !!(
        typeof key === 'object' &&
        !Array.isArray(key) &&
        key !== null
      )

      const keyIsArray = Array.isArray(key)

      const delimiter = (keyIsObject && key.delimiter) || '-'

      if (keyIsObject) {
        key = { ...key }
        delete key.delimiter
      }

      return keyIsObject
        ? Object.values(key)
            .map((key) => getElement(element, key))
            .join(delimiter)
        : getElement(element, key)
    }

    return (
      <>
        <UITable>
          <UITableHead>{[...th, 'Acción']}</UITableHead>
          <UITableBody>
            {!noCreate && (
              <tr>
                {Array(td.length).fill(<td></td>)}
                <td>
                  <UIButton
                    onClick={handleCreate}
                    className="ml-auto"
                    variant="outline-success"
                    active={action === 'create'}
                    title="Crear"
                  >
                    <FontAwesomeIcon
                      className="align-self-center"
                      style={style}
                      icon={faPlus}
                    />
                  </UIButton>
                </td>
              </tr>
            )}
            {elements.map((element, element_index) => {
              const leRefKey = String(constructKey(element, refKey))

              return (
                <tr
                  key={`list-row-${element_index}`}
                  style={
                    [String(id), idToDelete].includes(leRefKey)
                      ? {
                          borderTop: '2px #364f6b dashed',
                          borderBottom: '2px #364f6b dashed',
                        }
                      : undefined
                  }
                >
                  {td.map((key, td_index) => {
                    key = constructKey(element, key)

                    return !td_index ? (
                      <th scope="row" key={`cell-${element_index}-${td_index}`}>
                        {key}
                      </th>
                    ) : (
                      <td key={`cell-${element_index}-${td_index}`}>{key}</td>
                    )
                  })}
                  <td style={{ width: '150px' }}>
                    {!noDelete && (
                      <UIButton
                        variant="outline-danger"
                        className="mr-2"
                        onClick={() => handleDelete(leRefKey)}
                        active={idToDelete && String(idToDelete) === leRefKey}
                        title="Eliminar"
                      >
                        <FontAwesomeIcon
                          className="align-self-center"
                          style={style}
                          icon={faTimes}
                        />
                      </UIButton>
                    )}
                    {!noUpdate && (
                      <UIButton
                        variant="outline-stng"
                        className="mr-2"
                        onClick={() => handleUpdate(leRefKey)}
                        active={action === 'update' && String(id) === leRefKey}
                        title="Editar"
                      >
                        <FontAwesomeIcon
                          className="align-self-center"
                          style={style}
                          icon={faPen}
                        />
                      </UIButton>
                    )}
                    {!noView && (
                      <UIButton
                        variant="outline-secondary"
                        onClick={() => handleView(leRefKey)}
                        active={action === 'view' && String(id) === leRefKey}
                        title="Información"
                      >
                        <FontAwesomeIcon
                          className="align-self-center"
                          style={style}
                          icon={faInfo}
                        />
                      </UIButton>
                    )}
                  </td>
                </tr>
              )
            })}
          </UITableBody>
        </UITable>
        {elements.length === 0 && <UIEmpty />}
        <UIPagination pagination={pagination} onClick={handlePagination} />
        <UIModal
          trigger={modalTrigger}
          onAccept={handleAcceptModal}
          onCancel={hancleCancelModal}
          onClose={hancleCancelModal}
          head={'Confirmación'}
          body={<>Confirmación para eliminar el elemento</>}
          acceptText="Eliminar"
          acceptVariant="danger"
        />
      </>
    )
  }

  return <UIError />
}
