import { gql } from '../../../../../hooks'

export const READ_MODELS = gql`
  query AdministrationReadMachineModels($id: ID!) {
    aRead(path: "machine/models", id: $id) {
    }
  }
`

export const UPDATE_MODELS = gql`
  mutation AdministrationUpdateMachineModels($id: ID!, $data: JSONObject) {
    aUpdate(path: "machine/models", id: $id, data: $data) {
    }
  }
`

export const CREATE_MODELS = gql`
  mutation AdministrationCreateMachineModels($data: JSONObject) {
    aCreate(path: "machine/models", data: $data) {
    }
  }
`

export const LIST_MODELS = gql`
  query AdministrationListMachineModels(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(path: "machine/models", filter: $filter, page: $page, limit: $limit) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`

export const LIST_BRANDS = gql`
  query AdministrationMiscMachineBrands {
    aMachineBrands {
    }
  }
`

export const SEARCH_BRAND = gql`
  query AdministrationSearchMachineBrand {
    aSearch(path: "machine_brand", limit: 0, text: "") {
    }
  }
`
