import React from 'react'

// Hooks
import { useGlobal } from '../../../../../../hooks'

import { AdministrationList } from '../../../../List'

// Queries
import { DELETE_SITES_CASES, LIST_SITES_CASES } from './queries'

const table = [
  { th: 'ID', td: ['client', 'id'] },
  { th: 'Cliente', td: ['client', 'name'] },
  { th: 'Faena', td: ['site', 'name'] },
  { th: 'Tipo de equipo', td: ['machine', 'type', 'name'] },
  { th: 'Caso', td: ['case', 'name'] },
]

const queries = { list: LIST_SITES_CASES, delete: DELETE_SITES_CASES }

const AdministrationListClientSitesCases = () => {
  const [{ me }] = useGlobal()

  const no = ![1, 2].includes(me.id_role)

  return (
    <AdministrationList
      table={table}
      queries={queries}
      noCreate={no}
      noUpdate={no}
      noDelete={no}
      refKey={{
        pk1: ['site', 'id'],
        pk2: ['case', 'id'],
        pk3: ['machine', 'type', 'id'],
      }}
    />
  )
}

export default AdministrationListClientSitesCases
