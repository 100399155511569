import React from 'react'

// Components
import AdministrationListComponentTypes from '../../../../Administration/List/Component/Types'
import AdministrationInfoComponentTypes from '../../../../Administration/Info/Component/Types'
import AdministrationFormComponentTypes from '../../../../Administration/Form/Component/Types'

import { AdministrationContainer } from '../..'

const AdministrationContainerComponentTypes = () => (
  <AdministrationContainer
    form={AdministrationFormComponentTypes}
    info={AdministrationInfoComponentTypes}
    list={AdministrationListComponentTypes}
  />
)

export default AdministrationContainerComponentTypes
