import React from 'react'

import { AdministrationForm } from '../..'

// Queries
import {
  READ_CHANGE_MOTIVES,
  CREATE_CHANGE_MOTIVES,
  UPDATE_CHANGE_MOTIVES,
  LIST_CHANGE_MOTIVES,
} from './queries'

const queries = {
  read: READ_CHANGE_MOTIVES,
  create: CREATE_CHANGE_MOTIVES,
  update: UPDATE_CHANGE_MOTIVES,
  list: LIST_CHANGE_MOTIVES,
}

const AdministrationFormComponentChangeMotives = () => (
  <AdministrationForm name={'motivo de cambio'} queries={queries} />
)

export default AdministrationFormComponentChangeMotives
