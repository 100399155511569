import React from 'react'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFDownloadLink,
} from '@react-pdf/renderer'

// Hooks
import { useMemo, useRef, useState, useEffect } from '../../../../../hooks'

// Components
import UIButton from '../../../../UI/Button'

// https://github.com/diegomura/react-pdf/issues/608

import GraphsPieSmall from './Graphs/Pie/Small'
import GraphsPieBig from './Graphs/Pie/Big'

const ReportPDF = ({ data, onClick, onLoading }) => {
  let { info, results } = data

  const [isReady, setIsReady] = useState()

  const graphs = useRef({})
  const graph = useRef()

  //  const forceUpdate = useForceUpdate()

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsReady(true)
    }, 50)
    return () => clearTimeout(timer)
  }, [])

  // Font.register({
  //   family: 'Oswald',
  //   src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
  // })

  // Font.register({
  //   family: 'Roboto',
  //   fonts: [
  //     { src: '/Roboto-Regular.ttf' },
  //     { src: '/Roboto-Bold.ttf', fontWeight: 'bold' },
  //     { src: '/Roboto-Black.ttf', fontWeight: '900' },
  //   ],
  // })

  const styles = StyleSheet.create({
    page: {
      padding: 20,
      fontFamily: 'Roboto',
      fontSize: 7,
    },
    header: {
      color: 'orange',
      fontSize: 30,
      fontWeight: 'bold',
      paddingLeft: 10,
    },
    subHeader: {
      fontSize: 10,
      fontWeight: 'bold',
      paddingLeft: 10,
    },
    flexRow: {
      flex: 1,
      flexDirection: 'row',
      // fontSize: 10,
    },
  })

  // let image
  // switch (laboratory) {
  //   case 'antofagasta':
  //     image = 'logo-stng.png'
  //     break
  //   case 'collahuasi':
  //     image = 'logo_collahuasi.jpg'
  //     break
  //   case 'centinela':
  //     image = 'logo-centinela.png'
  //     // image = 'logo-stng.png'
  //     break
  //   default:
  //     image = 'logo-stng.png'
  // }

  // --------------------------------------------------------------------------
  //
  // Graph
  //
  // --------------------------------------------------------------------------

  const Graph = () => {
    const count = [0, 0, 0]

    results.forEach((result) => {
      count[0] += result.count_normal
      count[1] += result.count_alerta
      count[2] += result.count_anormal
    })

    const total = count[0] + count[1] + count[2]

    const label_normal = parseFloat(((count[0] / total) * 100).toFixed(1))
    const label_alerta = parseFloat(((count[1] / total) * 100).toFixed(1))
    const label_anormal = parseFloat(((count[2] / total) * 100).toFixed(1))

    return (
      <View
        key={results.name}
        style={{
          textAlign: 'center',
          flex: '1 0 100%',
          width: '100%',
          margin: 0,
          padding: '10 10',
        }}
      >
        <Text style={{ fontSize: 10, borderBottom: '1pt solid gray' }}>
          Resumen global
        </Text>
        <View style={{ flexDirection: 'row', marginTop: 5 }}>
          <View style={{ flex: '1 0 100%', width: '100%', height: 200 }}>
            {graph.current ? (
              <Image
                style={{
                  width: 'auto',
                }}
                src={`${graph.current}`}
              />
            ) : (
              <Text style={{ textAlign: 'right' }}>cargando</Text>
            )}
          </View>
        </View>
        <Text style={{ fontSize: 7, marginTop: 15 }}>
          Normal: {label_normal}% <Text style={{ color: 'gray' }}> | </Text>{' '}
          Alerta: {label_alerta}% <Text style={{ color: 'gray' }}> | </Text>{' '}
          Crítico: {label_anormal}%
        </Text>
      </View>
    )
  }

  // --------------------------------------------------------------------------
  //
  // Graphs
  //
  // --------------------------------------------------------------------------

  const Graphs = () => {
    return (
      <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
        {results.map((result) => {
          const { id, name, count_normal, count_alerta, count_anormal } = result

          const total = count_normal + count_alerta + count_anormal

          const label_normal = parseFloat(
            ((count_normal / total) * 100).toFixed(1),
          )
          const label_alerta = parseFloat(
            ((count_alerta / total) * 100).toFixed(1),
          )
          const label_anormal = parseFloat(
            ((count_anormal / total) * 100).toFixed(1),
          )

          return (
            <View
              key={name}
              style={{
                flex: '1 0 50%',
                width: '50%',
                textAlign: 'center',
                margin: 0,
                padding: '10 5',
              }}
            >
              <Text style={{ fontSize: 10, borderBottom: '1pt solid gray' }}>
                {name}
              </Text>
              <View style={{ flexDirection: 'row', marginTop: 5 }}>
                <View style={{ flex: '1 0 50%', width: '50%', height: 60 }}>
                  {graphs.current && graphs.current[id] ? (
                    <Image
                      style={{
                        width: 'auto',
                      }}
                      src={`${graphs.current[id]}`}
                    />
                  ) : (
                    <Text style={{ textAlign: 'right' }}>cargando</Text>
                  )}
                </View>
                <View style={{ flex: '1 0 50%', width: '50%' }}>
                  <Image
                    style={{
                      width: 'auto',
                    }}
                    src="/camion.jpg"
                  />
                </View>
              </View>
              <Text style={{ fontSize: 7, marginTop: 5 }}>
                Normal: {label_normal}%{' '}
                <Text style={{ color: 'gray' }}> | </Text> Alerta:{' '}
                {label_alerta}% <Text style={{ color: 'gray' }}> | </Text>{' '}
                Crítico: {label_anormal}%
              </Text>
            </View>
          )
        })}
      </View>
    )
  }

  // --------------------------------------------------------------------------
  //
  // Header
  //
  // --------------------------------------------------------------------------

  const Header = () => (
    <View>
      <Text style={styles.header}>Reporte de gestión</Text>
      <Text style={styles.subHeader}>Cliente: {info.header.name}</Text>
      <Text style={styles.subHeader}>Tipo de Reporte: {info.header.type}</Text>
      <Text style={styles.subHeader}>
        Periodo: {`Desde ${info.header.from} hasta ${info.header.to}`}
      </Text>
    </View>
  )

  // --------------------------------------------------------------------------
  //
  // Report document
  //
  // --------------------------------------------------------------------------

  const Report = () => (
    <Document title={`Reporte de Gestión`} author={`Servicios Tribológicos`}>
      <Page size="A4" style={styles.page}>
        <Header />
        <View style={{ marginTop: 10, width: '100%', flexDirection: 'row' }}>
          <View
            style={{
              flex: '1 0 40%',
              width: '40%',
            }}
          >
            <Graph />
          </View>
          <View
            style={{
              flex: '1 0 60%',
              width: '60%',
            }}
          >
            <Graphs />
          </View>
        </View>
      </Page>
    </Document>
  )

  // --------------------------------------------------------------------------
  //
  // Render report download button
  //
  // --------------------------------------------------------------------------

  const fileName = []

  fileName.push(info.header.emission_type)
  if (info.breadCrumbs.client_id) fileName.push(info.breadCrumbs.client_id)
  if (info.breadCrumbs.site_id) fileName.push(info.breadCrumbs.site_id)
  if (info.breadCrumbs.machine_type_id)
    fileName.push(info.breadCrumbs.machine_type_id)
  if (info.breadCrumbs.machine_id) fileName.push(info.breadCrumbs.machine_id)
  fileName.push(info.header.from)
  fileName.push(info.header.to)

  return useMemo(
    () => (
      <>
        {results.map((result) => (
          <GraphsPieSmall key={result.name} data={result} ref={graphs} />
        ))}
        <GraphsPieBig data={results} ref={graph} />
        {isReady ? (
          <PDFDownloadLink document={<Report />} fileName={fileName.join('_')}>
            {({ blob, url, loading, error }) => {
              if (loading)
                return (
                  <UIButton disable variant="secondary" className="mx-1">
                    Creando PDF ...
                  </UIButton>
                )

              onLoading(false)
              return (
                <UIButton onClick={onClick} className="mx-1">
                  Descargar PDF
                </UIButton>
              )
            }}
          </PDFDownloadLink>
        ) : (
          <UIButton disable variant="secondary" className="mx-1">
            Creando PDF ...
          </UIButton>
        )}
      </>
    ),
    [isReady],
  )
}

export default ReportPDF
