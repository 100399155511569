import React from 'react'

// Components
import AdministrationListLubricantProtocols from '../../../../Administration/List/Lubricant/Protocols'
import AdministrationInfoLubricantProtocols from '../../../../Administration/Info/Lubricant/Protocols'
import AdministrationFormLubricantProtocols from '../../../../Administration/Form/Lubricant/Protocols'

import { AdministrationContainer } from '../..'

const AdministrationContainerLubricantProtocols = () => (
  <AdministrationContainer
    form={AdministrationFormLubricantProtocols}
    info={AdministrationInfoLubricantProtocols}
    list={AdministrationListLubricantProtocols}
  />
)

export default AdministrationContainerLubricantProtocols
