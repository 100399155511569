import React from 'react'

// Hooks
// import { useParams } from '../../hooks'

// Components
import ResetPassword from '../../components/ResetPassword'

const PageResetPassword = () => {
  //  const { name } = useParams()

  return <ResetPassword />
}

export default PageResetPassword
