import React from 'react'
import moment from 'moment'
import { Row, Col, Form, Modal } from 'react-bootstrap'

// Global reducer
import {
  useAlert,
  useState,
  useGlobal,
  useMutation,
} from '../../../../../../../hooks'

// Components
import UIButton from '../../../../../../../components/UI/Button'
import UIForm from '../../../../../../../components/UI/FormReload'
import UIFormInputText from '../../../../../../../components/UI/FormReload/Input/Text'
import UIFormInputDate from '../../../../../../../components/UI/FormReload/Input//Date'
import UIFormInputNumber from '../../../../../../../components/UI/FormReload/Input/Number'
import UIFormInputSelect from '../../../../../../../components/UI/FormReload/Input/Select'
import UIFormInputCheckboxSwitch from '../../../../../../../components/UI/FormReload/Input/Checkbox/Switch'
import UIFormSeparation from '../../../../../../../components/UI/FormReload/Separation'
import UITable from '../../../../../../../components/UI/Table'
import UITableHead from '../../../../../../../components/UI/Table/Head'
import UITableBody from '../../../../../../../components/UI/Table/Body'

// Queries
import {
  ENTER_SAMPLES_MASSIVE,
  LIST_COMPONENT_CHANGE_MOTIVES,
  LIST_LUBIRCANT_CHANGE_REFILLS,
  LIST_LUBRICANT_CHANGE_REFILL_MOTIVES,
  GET_MASSIVE_SAMPLES,
} from './queries'

const SampleFormInsertMassiveTabWebTable = ({
  onIdChange,
  onComponentsChange,
  components,
}) => {
  const alert = useAlert()

  const [{ me }] = useGlobal()

  const [isReady, setIsReadyState] = useState(false)

  const [modalShow, setModalShowState] = useState({})

  const [generalValues, setGeneralValuesState] = useState([])

  const [checkAll, setCheckAllState] = useState(true)

  const [active, setActiveState] = useState(
    components.reduce((p, c) => ({ ...p, [c.component.id]: true }), {}),
  )

  // --------------------------------------------------------------------------
  //
  // Mutation: Insert samples
  //
  // --------------------------------------------------------------------------

  const [t2EnterSampleMassive] = useMutation(ENTER_SAMPLES_MASSIVE, {
    update: (cache, { data }) => {
      // Get anomalies
      // NOTE The 'id_client' variable is there to include a random variable
      // otherwise it ignores 'network-only' and uses the cache
      //
      // Related bug:
      // https://github.com/apollographql/react-apollo/issues/3315
      let { t2MassiveSamples } = cache.readQuery({
        query: GET_MASSIVE_SAMPLES,
        variables: { noise: me.id_laboratory }, // noise to the query
      })

      if (data && data.t2EnterSampleMassive && data.t2EnterSampleMassive.data)
        t2MassiveSamples.data.push(data.t2EnterSampleMassive.data)

      // Write the cache
      cache.writeQuery({
        query: GET_MASSIVE_SAMPLES,
        data: {
          t2MassiveSamples,
        },
      })
    },
    onSuccessMessage: 'Las muestras fueron ingresadas con éxito',
    onSuccess: ({ id }) => {
      onComponentsChange()
      onIdChange(id)
    },
    onErrorMessage: `La muestra no pudo ser ingresada`,
  })

  // --------------------------------------------------------------------------
  //
  // Render
  //
  // --------------------------------------------------------------------------

  const handleSubmit = (values) => {
    values = Object.keys(values).reduce(
      (p, key) =>
        [undefined, null, ''].includes(values[key])
          ? p
          : { ...p, [key]: values[key] },
      {},
    )

    // Restructuring values
    const data = {}
    for (const key in values) {
      const tmp = key.split('_')

      const id = tmp.shift()
      const name = tmp.join('_')

      if (!active[id]) continue

      if (!isNaN(id)) {
        data[id] = {
          ...data[id],
          [name]: values[key],
        }
      }
    }

    let required = true
    for (const id in active) {
      if (active[id]) {
        if (!data[id] || !data[id].date_sampling) {
          required = false
          break
        }
      }
    }

    if (required) {
      for (const key in data)
        data[key] = { ...data[key], date_withdraw: values.date_withdraw }

      t2EnterSampleMassive({ variables: { samples: data } })
      onIdChange()
    } else
      alert('El campo "fecha de muestreo" es obligatorio', { style: 'danger' })
  }

  const handleBack = () => {
    onComponentsChange()
  }

  const handleChange = (value, name, index) => {
    setGeneralValuesState((prev) => {
      if (!prev[index]) prev[index] = {}

      prev[index][name] = value

      // return prev
      return [...prev]
    })
  }

  const handleChangeAll = (value, name) => {
    setGeneralValuesState((prev) => {
      for (let i = 0; i < components.length; i++) {
        if (!prev[i]) prev[i] = {}
        prev[i] = { ...prev[i], [name]: value }
      }
      // return prev
      return [...prev]
    })
  }

  const handleActiveChange = (event, index) => {
    setActiveState((prev) => {
      prev[index] = event.target.checked
      return { ...prev }
    })
  }

  const classNameHeader = 'bg-stng border text-white align-middle'

  const handleCheckAll = () => {
    setCheckAllState((prev) => {
      setActiveState(
        Object.keys(active).reduce((p, c) => ({ ...p, [c]: !prev }), {}),
      )
      return !prev
    })
  }

  return (
    <UIForm onSubmit={handleSubmit}>
      <Form.Group as={Row} className="my-4">
        <Col className="d-flex">
          <UIButton className="mx-2" onClick={handleBack}>
            Atrás
          </UIButton>
          <UIButton className="mx-2" type="submit" disabled={!isReady}>
            Ingresar
          </UIButton>
        </Col>
      </Form.Group>
      <UIFormSeparation />
      <UITable borderless className="text-center font-weight-light my-4">
        <UITableHead>
          <tr>
            {[
              'Act.',
              'Tag',
              'Código externo',
              'Orden de Trabajo',
              'Fecha muestreo',
              'H/K Equipo',
              'H/K Componente',
              'H/K Lubricante',
              'Prioridad',
              'Comentario',
              'Motivo cambio componente',
              'Estado lubricante',
              'Motivo cambio o relleno lubricante',
              'Cantidad de litros',
              'Horas utilizadas',
            ].map((name, index) => (
              <td key={`td-${index}`} className={classNameHeader}>
                <small>{name}</small>
              </td>
            ))}
          </tr>
        </UITableHead>
        <UITableBody>
          <tr>
            <td>
              <input
                type="checkbox"
                onChange={handleCheckAll}
                checked={checkAll}
              />
            </td>
            <td></td>
            <td></td>
            <td>
              <UIFormInputText
                name={`work_order`}
                onChange={(event) => {
                  handleChangeAll(event.target.value, 'work_order')
                }}
              />
            </td>
            <td>
              <UIFormInputDate
                name="date_sampling"
                onChange={(date) => {
                  handleChangeAll(date, 'date_sampling')
                }}
              />
            </td>
            <td>
              <UIFormInputNumber
                name={`machine_continuity`}
                onChange={(event) => {
                  handleChangeAll(event.target.value, 'machine_continuity')
                }}
              />
            </td>
            <td>
              <UIFormInputNumber
                name={`component_continuity`}
                onChange={(event) => {
                  handleChangeAll(event.target.value, 'component_continuity')
                }}
              />
            </td>
            <td>
              <UIFormInputNumber
                name={`lubricant_continuity`}
                onChange={(event) => {
                  handleChangeAll(event.target.value, 'lubricant_continuity')
                }}
              />
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          {components.map(
            ({ component, machine, lubricant }, component_index) => (
              <tr key={`component_${component_index}`}>
                <td>
                  <input
                    type="checkbox"
                    checked={active[component.id]}
                    onChange={(event) => {
                      handleActiveChange(event, component.id)
                    }}
                  />
                </td>
                <td>
                  <small
                    onClick={() =>
                      setModalShowState((prev) => ({
                        ...prev,
                        [component_index]: true,
                      }))
                    }
                  >
                    {component.tag}
                  </small>
                  <Modal
                    show={modalShow[component_index]}
                    onHide={() =>
                      setModalShowState((prev) => ({
                        ...prev,
                        [component_index]: false,
                      }))
                    }
                    animation={false}
                  >
                    <Modal.Header className="text-center" closeButton>
                      <Modal.Title>{component.tag}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>
                        <strong>Tipo Equipo:</strong> {machine.type}
                      </p>
                      <p>
                        <strong>Equipo:</strong> {machine.name}
                      </p>
                      <p>
                        <strong>Marca:</strong> {machine.brand}
                      </p>
                      <p>
                        <strong>Modelo:</strong> {machine.model}
                      </p>
                      <p>
                        <strong>Componente:</strong> {component.name}
                      </p>
                      <p>
                        <strong>Tipo componente:</strong> {component.type}
                      </p>
                      <p>
                        <strong>Lubricante:</strong> {lubricant.name}
                      </p>
                    </Modal.Body>
                  </Modal>
                </td>
                <td>
                  <UIFormInputText
                    disabled={!active[component.id]}
                    name={`${component.id}_external_number`}
                  />
                </td>
                <td>
                  <UIFormInputText
                    disabled={!active[component.id]}
                    name={`${component.id}_work_order`}
                    onChange={(event) => {
                      const value =
                        generalValues[component_index] &&
                        generalValues[component_index].work_order

                      if (event.target.value !== value)
                        handleChange(
                          event.target.value,
                          `work_order`,
                          component_index,
                        )
                    }}
                    value={
                      generalValues[component_index] &&
                      generalValues[component_index].work_order
                    }
                  />
                </td>
                <td>
                  <UIFormInputDate
                    disabled={!active[component.id]}
                    name={`${component.id}_date_sampling`}
                    onChange={(date) => {
                      handleChange(date, `date_sampling`, component_index)
                    }}
                    value={
                      generalValues[component_index] &&
                      generalValues[component_index].date_sampling
                        ? moment(
                            generalValues[component_index].date_sampling,
                          ).format('YYYY/MM/DD')
                        : undefined
                    }
                  />
                </td>
                <td>
                  <UIFormInputNumber
                    disabled={!active[component.id]}
                    name={`${component.id}_machine_continuity`}
                    onChange={(event) => {
                      const value =
                        generalValues[component_index] &&
                        generalValues[component_index].machine_continuity

                      if (event.target.value !== value)
                        handleChange(
                          event.target.value,
                          `machine_continuity`,
                          component_index,
                        )
                    }}
                    value={
                      generalValues[component_index] &&
                      generalValues[component_index].machine_continuity
                    }
                  />
                </td>
                <td>
                  <UIFormInputNumber
                    disabled={!active[component.id]}
                    name={`${component.id}_component_continuity`}
                    onChange={(event) => {
                      const value =
                        generalValues[component_index] &&
                        generalValues[component_index].component_continuity

                      if (event.target.value !== value)
                        handleChange(
                          event.target.value,
                          `component_continuity`,
                          component_index,
                        )
                    }}
                    value={
                      generalValues[component_index] &&
                      generalValues[component_index].component_continuity
                    }
                  />
                </td>
                <td>
                  <UIFormInputNumber
                    disabled={!active[component.id]}
                    name={`${component.id}_lubricant_continuity`}
                    onChange={(event) => {
                      const value =
                        generalValues[component_index] &&
                        generalValues[component_index].lubricant_continuity

                      if (event.target.value !== value)
                        handleChange(
                          event.target.value,
                          `lubricant_continuity`,
                          component_index,
                        )
                    }}
                    value={
                      generalValues[component_index] &&
                      generalValues[component_index].lubricant_continuity
                    }
                  />
                </td>
                <td>
                  <UIFormInputCheckboxSwitch
                    disabled={!active[component.id]}
                    name={`${component.id}_id_sample_priority`}
                    on="U"
                    onValue={2}
                    off="N"
                    offValue={1}
                  />
                </td>
                <td>
                  <UIFormInputText
                    disabled={!active[component.id]}
                    name={`${component.id}_comment`}
                  />
                </td>
                <td>
                  <UIFormInputSelect
                    disabled={!active[component.id]}
                    name={`${component.id}_id_component_change_motive`}
                    query={LIST_COMPONENT_CHANGE_MOTIVES}
                    initialValue={1}
                  />
                </td>
                <td>
                  <UIFormInputSelect
                    disabled={!active[component.id]}
                    name={`${component.id}_id_lubricant_change_refill`}
                    query={LIST_LUBIRCANT_CHANGE_REFILLS}
                    initialValue={1}
                  />
                </td>
                <td>
                  <UIFormInputSelect
                    disabled={!active[component.id]}
                    name={`${component.id}_id_lubricant_change_refill_motive`}
                    query={LIST_LUBRICANT_CHANGE_REFILL_MOTIVES}
                    initialValue={1}
                  />
                </td>
                <td>
                  <UIFormInputNumber
                    disabled={!active[component.id]}
                    name={`${component.id}_lubricant_change_refill_liters`}
                  />
                </td>
                <td>
                  <UIFormInputNumber
                    disabled={!active[component.id]}
                    name={`${component.id}_lubricant_change_refill_hours`}
                  />
                </td>
              </tr>
            ),
          )}
        </UITableBody>
      </UITable>
      <UIFormInputDate
        label="Fecha de retiro"
        name="date_withdraw"
        showTime
        onChange={() => {
          setIsReadyState(true)
        }}
      />
    </UIForm>
  )
}

export default SampleFormInsertMassiveTabWebTable
