import { gql } from '../../../../../../hooks'

export const LIST_MASSIVE_COMPONENTS = gql`
  query ListMassiveComponents($id_massive: ID!) {
    t1MassiveIscaaComponents(id_massive: $id_massive) {
    }
  }
`

export const ENTER_MASSIVE_COMPONENTS = gql`
  mutation EnterComponentsMassive($id_massive: ID!, $ids: [ID!]!) {
    t1EnterMassiveIscaaComponents(id_massive: $id_massive, ids: $ids) {
    }
  }
`

export const ENTER_RECEIVE_MASSIVE_COMPONENTS = gql`
  mutation EnterReceiveComponentsMassive($id_massive: ID!, $ids: [ID!]!) {
    t1EnterReceiveMassiveIscaaComponents(id_massive: $id_massive, ids: $ids) {
    }
  }
`

export const ARCHIVE_MASSIVE_SAMPLES = gql`
  mutation ArchiveSamplesMassive($id_massive: ID!) {
    t1ArchiveMassiveIscaaSamples(id_massive: $id_massive) {
    }
  }
`

export const ELIMINATE_MASSIVE_SAMPLES = gql`
  mutation EliminateSamplesMassive($id_massive: ID!) {
    t1EliminateMassiveIscaaSamples(id_massive: $id_massive) {
    }
  }
`

export const ENTER_MASSIVE_SAMPLES = gql`
  mutation EnterSamplesMassive($id_massive: ID!) {
    t1EnterMassiveIscaaSamples(id_massive: $id_massive) {
    }
  }
`
