import React from 'react'
import { Col, Row } from 'react-bootstrap'

// Hooks
import { useState } from '../../../../../../hooks'

import TribologySamplesInsertMassiveWebForm from '../../../../../Tribology/Samples/Insert/Massive/Web/Form'
import TribologySamplesInsertMassiveWebInfo from '../../../../../Tribology/Samples/Insert/Massive/Web/Info'

const TribologySamplesInsertMassiveWeb = (props) => {
  const [id, setIdState] = useState()

  const handleIdChange = (id) => {
    setIdState(id)
  }

  if (id)
    return (
      <Row className="my-4">
        <Col lg={8} className="mb-3">
          <TribologySamplesInsertMassiveWebForm onIdChange={handleIdChange} />
        </Col>
        <Col lg={4}>
          <TribologySamplesInsertMassiveWebInfo id={id} />
        </Col>
      </Row>
    )

  return (
    <Row className="my-4">
      <Col xs={12} className="mb-3">
        <TribologySamplesInsertMassiveWebForm onIdChange={handleIdChange} />
      </Col>
    </Row>
  )
}

export default TribologySamplesInsertMassiveWeb
