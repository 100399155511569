import React from 'react'
import { Row, Col } from 'react-bootstrap'

// Hooks
// import { useState } from '../../../hooks'

// Components
import ProfileForm from '../Form'
import ProfileFormPassword from '../Form/Password'
// import ProfileInfofrom from '../Info'

const ProfileContainer = () => {
  // const [edit, setEditState] = useState(false)

  //  const handleEditToggle = () => {
  //    setEditState((prev) => !prev)
  //  }

  return (
    <Row>
      <Col xs={12} xl={8}>
        <ProfileForm />
      </Col>
      <Col xs={12} xl={8}>
        <ProfileFormPassword />
      </Col>
    </Row>
  )
}

export default ProfileContainer
