import React, { useContext, createContext } from 'react'

import UIAlert from '../../components/UI/Alert'

// Hooks
import { useEffect, useState, useCallback } from '../../hooks'

const styles = ['success', 'danger', 'info', 'warning']

export const AlertContext = createContext()

export const AlertProvider = ({ children }) => {
  const [alerts, setAlertsState] = useState([])

  useEffect(() => {
    if (alerts.length > 0) {
      const timer = setTimeout(
        () => setAlertsState((prev) => prev.slice(1)),
        3000,
      )
      return () => clearTimeout(timer)
    }
  }, [alerts])

  const alert = (message, options = {}) => {
    setAlertsState((prev) => [
      ...prev,
      {
        message,
        style: styles.includes(options.style) ? options.style : 'info',
      },
    ])
  }

  return (
    <AlertContext.Provider value={alert}>
      <div className="alert-fixed position-fixed mx-3">
        {alerts.map(({ message, style }, index) => (
          <UIAlert key={index} style={style}>
            {message}
          </UIAlert>
        ))}
      </div>
      {children}
    </AlertContext.Provider>
  )
}

export const useAlert = () => useContext(AlertContext)

export default useAlert
