import React, { forwardRef } from 'react'
import { InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGripLines } from '@fortawesome/free-solid-svg-icons'

import UIButton from '../../../../UI/Button'

import {
  useRef,
  useEffect,
  useMemo,
  useCallback,
  useState,
  useTrigger,
} from '../../../../../hooks'

import Input from '..'
import { useFormContext } from '../..'

// TODO add different validators for different types
const UIFormInputResult = forwardRef((props, ref) => {
  const {
    value: propValue,
    initialValue: propInitialValue,
    defaultValue: propDefaultValue,
    onChange: propOnChange,
    onReset: propOnReset,
    tooltip: propTooltip,
    ...rest
  } = props

  const isFirstRender = useRef(true)

  const context = useFormContext()

  const [value, setValueState] = useState({
    curr:
      propValue !== undefined
        ? propValue
        : propInitialValue !== undefined
        ? propInitialValue
        : propDefaultValue !== undefined
        ? propDefaultValue
        : context.initialValues[props.name] !== undefined
        ? context.initialValues[props.name]
        : context.defaultValues[props.name] != undefined
        ? context.defaultValues[props.name]
        : '',
    prev: '',
  })

  useEffect(() => {
    if (isFirstRender.current) isFirstRender.current = false
    else setValueState(propValue)
  }, [propValue])

  const defaultValue = useMemo(
    () =>
      propDefaultValue !== undefined
        ? propDefaultValue
        : context.defaultValues[props.name] !== undefined
        ? context.defaultValues[props.name]
        : '',
    [],
  )

  // This trigger will call error check
  const [trigger, setTrigger] = useTrigger()

  const handleChange = (event) => {
    setValueState((prev) => ({ ...prev, curr: event.target.value }))
    propOnChange && propOnChange(event)
  }

  const handleClickNSD = useCallback(() => {
    setValueState(({ curr, prev }) => {
      return curr !== 'NSD'
        ? { curr: 'NSD', prev: value.curr }
        : { curr: prev, prev }
    })
    setTrigger()
    propOnChange && propOnChange({ target: { value: 'NSD' } })
  }, [value.curr])

  const handleClickNA = useCallback(() => {
    setValueState(({ curr, prev }) => {
      return curr !== 'NA'
        ? { curr: 'NA', prev: value.curr }
        : { curr: prev, prev }
    })
    setTrigger()
    propOnChange && propOnChange({ target: { value: 'NA' } })
  }, [value.curr])

  const handleReset = () => {
    setValueState({ curr: defaultValue, prev: '' })
    propOnReset && propOnReset()
  }

  return (
    <Input
      {...rest}
      ref={ref}
      defaultValue={defaultValue}
      value={value.curr}
      onChange={handleChange}
      onReset={handleReset}
      triggerErrorCheck={trigger}
      prepend={
        propTooltip ? (
          <OverlayTrigger
            overlay={<Tooltip id="tooltip-disabled">{propTooltip}</Tooltip>}
          >
            <InputGroup.Text>
              <FontAwesomeIcon
                className="align-self-center"
                size="xs"
                icon={faGripLines}
              />
            </InputGroup.Text>
          </OverlayTrigger>
        ) : undefined
      }
      append={
        <>
          <UIButton
            active={value.curr === 'NSD'}
            disabled={value.curr === 'NA'}
            onClick={handleClickNSD}
            variant="outline-secondary font-weight-light"
          >
            <small>NSD</small>
          </UIButton>
          <UIButton
            active={value.curr === 'NA'}
            disabled={value.curr === 'NSD'}
            onClick={handleClickNA}
            variant="outline-secondary font-weight-light"
          >
            <small>NA</small>
          </UIButton>
        </>
      }
      disabled={props.disabled || ['NSD', 'NA'].includes(value.curr)}
    />
  )
})

export default UIFormInputResult
