import React from 'react'
import { Table } from 'react-bootstrap'

/*
 * Generic table from where more specific tables are based on
 */

const UITable = ({
  responsive = true,
  striped = true,
  notCentered,
  size = 'sm',
  scopeRow = false,
  head = [],
  body = [[]],
  children,
  className = '',
  fontSize = 'small',
  ...rest
}) => {
  const style = {
    fontSize,
  }
  const classes = [className, 'font-weight-light']

  if (!notCentered) classes.push('text-center')

  return (
    <Table
      {...rest}
      responsive={responsive}
      striped={striped}
      size={size}
      style={style}
      className={classes.join(' ')}
    >
      {children ? (
        <>{children}</>
      ) : (
        <>
          {head.length > 0 && (
            <thead>
              <tr>
                {head.map((element, index) => (
                  <th key={index} className="py-2">
                    {element}
                  </th>
                ))}
              </tr>
            </thead>
          )}
          {body.length > 0 && (
            <tbody>
              {body.map((row, index) => (
                <tr key={index}>
                  {row.map((element, index) => {
                    if (scopeRow && index === 0)
                      return (
                        <th scope="row" key={index}>
                          {element}
                        </th>
                      )
                    return <td key={index}>{element}</td>
                  })}
                </tr>
              ))}
            </tbody>
          )}
        </>
      )}
    </Table>
  )
}

export default UITable
