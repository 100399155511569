import React from 'react'

// Hooks
import { useGlobal } from '../../../../../hooks'

import AdministrationInfo from '../..'

// Queries
import { READ_ANALYSIS_METHODS, DELETE_ANALYSIS_METHODS } from './queries'

const AdministrationInfoSamplingAnalysisMethods = ({ ...rest }) => {
  const [{ me }] = useGlobal()

  const no = ![1, 2].includes(me.id_role)

  return (
    <AdministrationInfo
      queries={{ read: READ_ANALYSIS_METHODS, delete: DELETE_ANALYSIS_METHODS }}
      title="Método de análisis"
      {...rest}
      noDuplicate={no}
      noUpdate={no}
      noDelete={no}
    />
  )
}

export default AdministrationInfoSamplingAnalysisMethods
