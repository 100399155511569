import React from 'react'

// Components
import AdministrationListMachineBrands from '../../../../Administration/List/Machine/Brands'
import AdministrationInfoMachineBrands from '../../../../Administration/Info/Machine/Brands'
import AdministrationFormMachineBrands from '../../../../Administration/Form/Machine/Brands'

import { AdministrationContainer } from '../..'

const AdministrationContainerMachineBrands = () => (
  <AdministrationContainer
    form={AdministrationFormMachineBrands}
    info={AdministrationInfoMachineBrands}
    list={AdministrationListMachineBrands}
  />
)

export default AdministrationContainerMachineBrands
