import { gql } from '../../../../../hooks'

export const READ_MACHINES = gql`
  query AdministrationReadMachineMachines($id: ID!) {
    aRead(path: "machine/machines", id: $id) {
    }
  }
`

export const UPDATE_MACHINES = gql`
  mutation AdministrationUpdateMachineMachines($id: ID!, $data: JSONObject) {
    aUpdate(path: "machine/machines", id: $id, data: $data) {
    }
  }
`

export const CREATE_MACHINES = gql`
  mutation AdministrationCreateMachineMachines($data: JSONObject) {
    aCreate(path: "machine/machines", data: $data) {
    }
  }
`

export const LIST_MACHINES = gql`
  query AdministrationListMachineMachines(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(
      path: "machine/machines"
      filter: $filter
      page: $page
      limit: $limit
    ) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`

export const SEARCH_BRAND = gql`
  query AdministrationSearchMachineBrand {
    aSearch(path: "machine_brand", limit: 0, text: "") {
    }
  }
`

export const SEARCH_MODEL = gql`
  query AdministrationSearchMachineModel($id_brand: Int) {
    aSearch(path: "machine_model", filter: { id_brand: $id_brand }, limit: 0, text: "") {
    }
  }
`

export const SEARCH_MACHINE_TYPE = gql`
  query AdministrationSearchMachineType($text: String) {
    aSearch(path: "machine_type", text: $text) {
    }
  }
`

export const SEARCH_SITE = gql`
  query AdministrationSearchSite {
    aSearch(path: "site", limit: 0, text: "") {
    }
  }
`
