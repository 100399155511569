import React from 'react'
import { Col, Row } from 'react-bootstrap'

// Hooks
import { useState } from '../../../../hooks'

// Components
import ReportFormFilter from '../../Form/Filter'
import ReportTablePowerBI from '../../Table/PowerBI'

const ReportContainerPowerBI = () => {
  const [filter, setFilterState] = useState()

  const handleFilterChange = (filter) => {
    setFilterState(filter)
  }

  return (
    <Row>
      <Col className="mb-3">
        {filter ? (
          <ReportTablePowerBI
            filter={filter}
            onFilterChange={handleFilterChange}
          />
        ) : (
          <ReportFormFilter
            onFilterChange={handleFilterChange}
            name="power_bi"
          />
        )}
      </Col>
    </Row>
  )
}

export default ReportContainerPowerBI
