import React from 'react'

// Lubricants
import AdministrationListLubricantModels from '../../../../Administration/List/Lubricant/Models'
import AdministrationInfoLubricantModels from '../../../../Administration/Info/Lubricant/Models'
import AdministrationFormLubricantModels from '../../../../Administration/Form/Lubricant/Models'

import { AdministrationContainer } from '../..'

const AdministrationContainerLubricantModels = () => (
  <AdministrationContainer
    form={AdministrationFormLubricantModels}
    info={AdministrationInfoLubricantModels}
    list={AdministrationListLubricantModels}
  />
)

export default AdministrationContainerLubricantModels
