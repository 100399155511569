import React from 'react'

// Hooks
import { useGlobal } from '../../../../../hooks'

// Components
import UIPanelInfoBlock from '../../../../UI/Panel/Info/Block'
import UIPanelInfoTable from '../../../../UI/Panel/Info/Table'
import UIFormInputText from '../../../../UI/FormReload/Input/Text'
import UIFormInputInteger from '../../../../UI/FormReload/Input/Integer'

// Common
import { oe } from '../../../../../common/object/extractor'

import AdministrationInfo, { RefLink } from '../..'

// Queries
import { READ_ESSAYS, DELETE_ESSAYS } from './queries'

const Info = (data) => (
  <UIPanelInfoBlock title="Ensayo">
    <UIPanelInfoTable>
      {[
        ['ID', oe(data, ['id'])],
        ['Descripción', oe(data, ['name'])],
        [
          'Clasificación',
          <RefLink
            id={oe(data, ['classification', 'id'])}
            tab={'sampling'}
            subtab={'classifications'}
          >
            {oe(data, ['classification', 'name'])}
          </RefLink>,
        ],
        ['Tipo protocolo', oe(data, ['protocolType', 'name'])],
        [
          'Método análisis',
          <RefLink
            id={oe(data, ['analysisMethod', 'id'])}
            tab={'sampling'}
            subtab={'analysisMethods'}
          >
            {oe(data, ['analysisMethod', 'name'])}
          </RefLink>,
        ],
        [
          'Unidad ensayo',
          <RefLink
            id={oe(data, ['unit', 'id'])}
            tab={'sampling'}
            subtab={'units'}
          >
            {oe(data, ['unit', 'name'])}
          </RefLink>,
        ],
        ['Tipo resultado', oe(data, ['resultType', 'name'])],
        ['Usa comentario', oe(data, ['useComment'], false) ? 'Sí' : 'No'],
        ['Usar en lógica', oe(data, ['use_in_logics'], false) ? 'Sí' : 'No'],
      ]}
    </UIPanelInfoTable>
  </UIPanelInfoBlock>
)

const Form = () => (
  <>
    <UIFormInputInteger label="ID" name="id" />
    <UIFormInputText label="Descripción" name="name" />
    <UIFormInputInteger label="ID unidad" name="id_units" />
    <UIFormInputText label="Descripción unidad" name="name_units" />
    <UIFormInputInteger label="ID clasificación" name="id_classification" />
    <UIFormInputText
      label="Descripción clasificación"
      name="name_classification"
    />
    <UIFormInputInteger label="ID método análisis" name="id_analysis_methods" />
    <UIFormInputText
      label="Descripción método análisis"
      name="name_analysis_methods"
    />
  </>
)

const AdministrationInfoSamplingEssays = ({ ...rest }) => {
  const [{ me }] = useGlobal()

  const no = ![1, 2].includes(me.id_role)

  return (
    <AdministrationInfo
      queries={{ read: READ_ESSAYS, delete: DELETE_ESSAYS }}
      views={{ form: Form, info: Info }}
      {...rest}
      noDuplicate={no}
      noUpdate={no}
      noDelete={no}
    />
  )
}

export default AdministrationInfoSamplingEssays
