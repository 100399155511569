import React from 'react'
import { Row, Col, Form } from 'react-bootstrap'

// Hooks
import { useState, useGlobal } from '../../../../hooks'

// Components
import UIButton from '../../../UI/Button'
import UIForm from '../../../UI/FormReload'
import UIFormInputSelect from '../../../UI/FormReload/Input/Select'
import UIFormInputDate from '../../../UI/FormReload/Input/Date'
import UIFormInputNumber from '../../../UI/FormReload/Input/Number'
import UIFormSeparation from '../../../UI/FormReload/Separation'

// Queries
import {
  LIST_CLIENTS,
  LIST_SITES,
  LIST_MACHINE_TYPES,
  LIST_MACHINES,
} from './queries'

const ReportFormFilter = ({ name, onFilterChange }) => {
  const [{ me }] = useGlobal()

  const [idClient, setIdClientState] = useState()
  const [idSite, setIdSiteState] = useState()
  const [idMachineType, setIdMachineTypeState] = useState()

  // --------------------------------------------------------------------------
  //
  // Filter: Client, Site, Machine Type, Machine
  //
  // --------------------------------------------------------------------------

  const handleClientChange = (event) => {
    setIdClientState(event.target.value)
    setIdSiteState()
    setIdMachineTypeState()
  }

  const handleSiteChange = (event) => {
    setIdSiteState(event.target.value)
    setIdMachineTypeState()
  }

  const handleMachineTypeChange = (event) => {
    setIdMachineTypeState(event.target.value)
  }

  // Client Filter Input
  // -------------------
  const ClientFilterInput = () => (
    <UIFormInputSelect
      label="Cliente"
      name="id_client"
      query={LIST_CLIENTS}
      variables={{ id: me.id_laboratory }}
      onChange={handleClientChange}
      value={idClient}
      required
    >
      <option value="">Seleccione un cliente</option>
    </UIFormInputSelect>
  )

  // Site Filter Input
  // -----------------
  const SiteFilterInput = () => (
    <UIFormInputSelect
      label="Faena"
      name="id_site"
      query={LIST_SITES}
      variables={{ id: idClient }}
      skip={!idClient}
      onChange={handleSiteChange}
      value={idSite}
      disabled={!idClient}
      required={['fleet_state'].includes(name)}
    >
      <option value="">Seleccione una faena</option>
    </UIFormInputSelect>
  )

  // Machine Type Filter Input
  // -------------------------
  const MachineTypeFilterInput = () => (
    <UIFormInputSelect
      label="Tipo de equipo"
      name="id_machine_type"
      query={LIST_MACHINE_TYPES}
      variables={{ id: idSite }}
      skip={!idSite}
      onChange={handleMachineTypeChange}
      disabled={!idSite}
    >
      <option value="">Seleccione un tipo de equipo</option>
    </UIFormInputSelect>
  )

  // Machine Filter Input
  // --------------------
  const MachineFilterInput = () => (
    <UIFormInputSelect
      label="Equipo"
      name="id_machine"
      query={LIST_MACHINES}
      variables={{
        id_site: idSite,
        id_machine_type: idMachineType,
      }}
      skip={!idMachineType}
      disabled={!idMachineType}
    >
      <option value="">Seleccione un equipo</option>
    </UIFormInputSelect>
  )

  // Client, Site, Machine Type, Machine Filter
  // ------------------------------------------
  let ClientSiteMachineTypeMachineFilter

  switch (name) {
    case 'managing':
      ClientSiteMachineTypeMachineFilter = () => (
        <>
          <UIFormSeparation text="Acceso a nivel de cliente" />
          {ClientFilterInput()}
        </>
      )

      break
    case 'power_bi':
      ClientSiteMachineTypeMachineFilter = () => (
        <>
          <UIFormSeparation text="Acceso a nivel de cliente" />
          {ClientFilterInput()}
          {SiteFilterInput()}
        </>
      )
      break
    case 'fleet_state':
    case 'concatenator':
      ClientSiteMachineTypeMachineFilter = () => (
        <>
          <UIFormSeparation text="Acceso a nivel de cliente" />
          {ClientFilterInput()}
          {SiteFilterInput()}
          {MachineTypeFilterInput()}
        </>
      )

      break
    case 'laboratory_kpi':
    case 'critic':
    case 'invoicing':
    case 'data':
    case 'fails':
    case 'unsampled':
      ClientSiteMachineTypeMachineFilter = () => (
        <>
          <UIFormSeparation text="Acceso a nivel de cliente" />
          {ClientFilterInput()}
          {SiteFilterInput()}
          {MachineTypeFilterInput()}
          {MachineFilterInput()}
        </>
      )

      break

    case 're_sampled':
    default:
      ClientSiteMachineTypeMachineFilter = () => <></>
  }

  // --------------------------------------------------------------------------
  //
  // Filter: Period
  //
  // --------------------------------------------------------------------------

  // Date From Input
  // ---------------
  const DateFromInput = () => {
    return <UIFormInputDate label="Desde" name="from" required />
  }

  // Date To Input
  // ---------------
  const DateToInput = () => {
    return <UIFormInputDate label="Hasta" name="to" required />
  }

  // Emission Type Input
  // ---------------
  const EmissionTypeInput = () => (
    <UIFormInputSelect label="Tipo de emisión" name="emission_type">
      <option value="0">Por periodo</option>
      <option value="1">Últimos 5 informes</option>
      <option value="2">Últimos 10 informes</option>
      <option value="3">Condición Normal (Verde)</option>
      <option value="4">Condición Alerta (Amarillo)</option>
      <option value="5">Condición Crítica (Rojo)</option>
    </UIFormInputSelect>
  )

  // Text UF Input
  // ---------------
  const TextUFInput = () => (
    <UIFormInputNumber label="Valor UF" name="uf_value" />
  )

  // Period Filter
  // ------------
  let PeriodFilter

  switch (name) {
    case 'critic':
    case 'concatenator':
    case 'fleet_state':
    case 'data':
    case 'fails':
    case 'power_bi':
    case 'laboratory_kpi':
    case 're_sampled':
      PeriodFilter = () => (
        <>
          <UIFormSeparation text="Parametros de ingreso" />
          {DateFromInput()}
          {DateToInput()}
        </>
      )
      break
    case 'invoicing':
      PeriodFilter = () => (
        <>
          <UIFormSeparation text="Parametros de ingreso" />
          {DateFromInput()}
          {DateToInput()}
          {TextUFInput()}
        </>
      )

      break
    case 'managing':
      PeriodFilter = () => (
        <>
          <UIFormSeparation text="Parametros de ingreso" />
          {DateFromInput()}
          {DateToInput()}
          {EmissionTypeInput()}
        </>
      )

      break

    case 'unsampled':
    default:
      PeriodFilter = () => <></>
  }

  // --------------------------------------------------------------------------
  //
  // Render
  //
  // --------------------------------------------------------------------------

  const handleSubmit = (values) => {
    onFilterChange(values)
  }

  return (
    <UIForm onSubmit={handleSubmit}>
      <Form.Group as={Row} className="my-1">
        <Col className="d-flex">
          <UIButton type="submit">Buscar</UIButton>
        </Col>
      </Form.Group>
      <Row>
        <Col xs={12}>{ClientSiteMachineTypeMachineFilter()}</Col>
        <Col xs={12}>{PeriodFilter()}</Col>
      </Row>
    </UIForm>
  )
}

export default ReportFormFilter
