import React from 'react'

import { AdministrationList } from '../../../List'

// Queries
import { DELETE_SUGGESTIONS, LIST_SUGGESTIONS } from './queries'

const queries = { list: LIST_SUGGESTIONS, delete: DELETE_SUGGESTIONS }

const AdministrationListAnalysisSuggestions = () => (
  <AdministrationList queries={queries} noCreate noDelete />
)

export default AdministrationListAnalysisSuggestions
