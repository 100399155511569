import React from 'react'

import { AdministrationList } from '../../../List'

// Queries
import { DELETE_MODELS, LIST_MODELS } from './queries'

const table = [
  { th: 'ID', td: 'id' },
  { th: 'Marca', td: ['brand', 'name'] },
  { th: 'Descripción', td: 'name' },
]

const queries = { list: LIST_MODELS, delete: DELETE_MODELS }

const AdministrationListComponentModels = () => (
  <AdministrationList table={table} queries={queries} />
)

export default AdministrationListComponentModels
