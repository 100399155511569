import { gql } from '../../../../../hooks'

export const LIST_ESSAYED_SAMPLES = gql`
  query ReceivedSamples($page: Int, $limit: Int) {
    t4SamplesByStage(page: $page, limit: $limit) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`
