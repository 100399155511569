import { gql } from '../../../../../../hooks'

export const READ_CLIENTS_CASES = gql`
  query AdministrationReadClientClientsCases($id: ID!) {
    aRead(path: "client/clients/cases", id: $id) {
    }
  }
`

export const UPDATE_CLIENTS_CASES = gql`
  mutation AdministrationUpdateClientClientsCases($id: ID!, $data: JSONObject) {
    aUpdate(path: "client/clients/cases", id: $id, data: $data) {
    }
  }
`

export const CREATE_CLIENTS_CASES = gql`
  mutation AdministrationCreateClientClientsCases($data: JSONObject) {
    aCreate(path: "client/clients/cases", data: $data) {
    }
  }
`

export const LIST_CLIENTS_CASES = gql`
  query AdministrationListClientClientsCases(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(
      path: "client/clients/cases"
      filter: $filter
      page: $page
      limit: $limit
    ) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`

export const SEARCH_CLIENT = gql`
  query AdministrationSearchSite($text: String) {
    aSearch(path: "client", text: $text) {
    }
  }
`
