import React from 'react'
import { ResponsivePie } from '@nivo/pie'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const UIGraphPie = ({ data, ...options }) => {
  let commonOptions = {
    margin: { top: 40, right: 80, bottom: 80, left: 80 },
    innerRadius: 0.5,
    padAngle: 0.7,
    cornerRadius: 3,
    colors: (d) => d.color,
    borderWidth: 1,
    borderColor: { from: 'color', modifiers: [['darker', 0.2]] },
    radialLabelsSkipAngle: 10,
    // radialLabelsTextXOffset: 6,
    radialLabelsTextColor: '#333333',
    // radialLabelsLinkOffset: 0,
    // radialLabelsLinkDiagonalLength: 16,
    // radialLabelsLinkHorizontalLength: 120,
    // radialLabelsLinkStrokeWidth: 1,
    radialLabelsLinkColor: { from: 'color' },
    slicesLabelsSkipAngle: 10,
    slicesLabelsTextColor: '#333333',
    animate: true,
    //motionStiffness: 90,
    //motionDamping: 15,
    defs: [
      {
        id: 'dots',
        type: 'patternDots',
        background: 'inherit',
        color: 'rgba(255, 255, 255, 0.3)',
        size: 4,
        padding: 1,
        stagger: true,
      },
      {
        id: 'lines',
        type: 'patternLines',
        background: 'inherit',
        color: 'rgba(255, 255, 255, 0.3)',
        rotation: -45,
        lineWidth: 6,
        spacing: 10,
      },
    ],
    legends: [
      {
        //anchor: 'bottom',
        //direction: 'row',
        anchor: 'right',
        direction: 'column',
        justify: false,
        // translateY: 56,
        // translateX: 80,
        //  itemWidth: 300,
        // itemHeight: 18,
        translateX: -80,
        // translateY: 56,
        itemsSpacing: 5,
        itemWidth: 150,
        itemHeight: 15,
        itemTextColor: '#999',
        itemDirection: 'left-to-right',
        symbolSize: 18,
        symbolShape: 'circle',
        effects: [
          {
            on: 'hover',
            style: {
              itemTextColor: '#000',
            },
            // id: 'lines',
            // type: 'patternLines',
            // background: 'inherit',
            // color: 'rgba(255, 255, 255, 0.3)',
            // rotation: -45,
            // lineWidth: 6,
            // spacing: 10,
          },
        ],
        // fill : [
        //     {
        //         match: {
        //             id: 'javascript'
        //         },
        //         id: 'lines'
        //     }
        // ]
        // legends: [
        //   {
        //     anchor: 'top-right',
        //     direction: 'column',
        //     // translateY: 56,
        //     itemWidth: 100,
        //     itemHeight: 36,
        //     itemTextColor: '#999',
        //     symbolSize: 18,
        //     symbolShape: 'circle',
        //     effects: [
        //       {
        //         on: 'hover',
        //         style: {
        //           itemTextColor: '#000',
        //         },
        //       },
        //     ],
        //   },
        // ],
      },
    ],
    ...options,
  }

  return <ResponsivePie data={data} {...commonOptions} />
}

export default UIGraphPie
