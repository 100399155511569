import React from 'react'
import { Col, Row } from 'react-bootstrap'

import { useState, useParams } from '../../../hooks'

import InspectionsDesktopList from '../../../components/Inspections/Desktop/List'
import InspectionsDesktopForm from '../../../components/Inspections/Desktop/Form'
import InspectionsDesktopInfo from '../../../components/Inspections/Desktop/Info'

const PageInspectionsDesktop = () => {
  const { id_inspection } = useParams()

  if (id_inspection)
    return (
      <Row className="m-4">
        <Col lg={9} className="mb-3">
          <InspectionsDesktopForm id={id_inspection} />
        </Col>
        <Col lg={3}>
          <InspectionsDesktopInfo id={id_inspection} />
        </Col>
      </Row>
    )

  return (
    <Row className="m-4">
      <Col lg={12} className="mb-3">
        <InspectionsDesktopList />
      </Col>
    </Row>
  )
}

export default PageInspectionsDesktop
