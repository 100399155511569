import React from 'react'

const UIPanelInfoBlock = ({ title, children, className }) => {
  const classes = className ? className : `my-1`

  return (
    <>
      {title && <div className="lead border-bottom mt-2 mb-4">{title}</div>}
      {children && <div className={classes}>{children}</div>}
    </>
  )
}
export default UIPanelInfoBlock
