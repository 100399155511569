import React from 'react'

// Components
import UIPanelInfo from '../../../../../../../components/UI/Panel/Info'
import UIPanelInfoBlock from '../../../../../../../components/UI/Panel/Info/Block'
import UIPanelInfoItem from '../../../../../../../components/UI/Panel/Info/Item'

// Hooks
// import { useStyle } from '../../../../../../../hooks'

const SampleInfoInsertMassiveWeb = ({ id }) => {
  return (
    <UIPanelInfo>
      <UIPanelInfoBlock title="Muestras">
        <UIPanelInfoItem name="Correlativo masivo">{id}</UIPanelInfoItem>
      </UIPanelInfoBlock>
    </UIPanelInfo>
  )
}

export default SampleInfoInsertMassiveWeb
