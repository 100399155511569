import React from 'react'

// Hooks
import { useGlobal } from '../../../../../hooks'

import { AdministrationList } from '../../../List'

// Queries
import { DELETE_ANALYSIS_METHODS, LIST_ANALYSIS_METHODS } from './queries'

const queries = { list: LIST_ANALYSIS_METHODS, delete: DELETE_ANALYSIS_METHODS }

const AdministrationListSamplingAnalysisMethods = () => {
  const [{ me }] = useGlobal()

  const no = ![1, 2].includes(me.id_role)

  return (
    <AdministrationList
      queries={queries}
      noCreate={no}
      noUpdate={no}
      noDelete={no}
    />
  )
}

export default AdministrationListSamplingAnalysisMethods
