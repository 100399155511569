import React from 'react'
import { Col, Row } from 'react-bootstrap'
// import UILogo from '../../components/UI/Logo'
import UILoading from '../../components/UI/Loading'

const PageWelcome = () => {
  return (
    <Row className="text-center h-100 bg-stng mx-0">
      <Col xs={12} className="mt-auto">
        <img height="150px" src="/coddi-logo.png" alt="CODDI" />
      </Col>
      <Col xs={12} className="mb-auto">
        <p className="text-stng-lighter">Cargando</p>
        <UILoading />
      </Col>
    </Row>
  )
}

export default PageWelcome
