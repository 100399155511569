import React, { forwardRef } from 'react'
import { Row, Col, Form, InputGroup } from 'react-bootstrap'

import {
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from '../../../../hooks'

import { useFormContext } from '..'

import Label from './common/Label'
import Text from './common/Text'
import Feedback from './common/Feedback'

// There are two ways of handling forms in React:
// - ref + defaultValue
// - useState + onChange
// We choose the second one, this implies that refs pass to UIFormInput will be ignored
const UIFormInput = forwardRef((props = {}, ref) => {
  let {
    name: propName,
    value: propValue,
    defaultValue: propDefaultValue,
    span: propSpan,
    label: propLabel,
    required: propRequired,
    onKeyDown: propOnKeyDown,
    onBlur: propOnBlur,
    onReset: propOnReset,
    onSubmit: propOnSubmit,
    validator: propValidator,
    validateOnChange: propValidateOnChange,
    className: propClassName,
    overwriteClassName: propOverwriteClassName,
    containerClassName: propContainerClassName,
    // Input related
    text: propText,
    prepend: propPrepend,
    append: propAppend,
    customInput: PropCustomInput = forwardRef((props, ref) => (
      <input ref={ref} {...props} />
    )),
    // Noise to forse useEffect to re-run
    triggerErrorCheck: propTriggerErrorCheck,
    triggerValueCheck: propTriggerValueCheck,
    ...rest
  } = props

  const Input = useCallback(PropCustomInput, [])
  //  const Input = forwardRef((props, ref) => <input ref={ref} {...props} />)

  // Just an aux variable to avoid running some code at first render
  const isFirst = useRef(true)

  // UIForm context
  const context = useFormContext()

  const [error, setErrorState] = useState()

  // Validation
  // --------------------------------------------------------------------------

  const validateOnChange = useMemo(() => {
    propValidateOnChange !== undefined
      ? !!propValidateOnChange
      : context.validateOnChange !== undefined
      ? !!context.validateOnChange
      : false
  }, [propValidateOnChange, context.validateOnChange])

  const validator = useCallback(
    (value) => {
      if (propRequired && [null, undefined, ''].includes(value))
        return 'Campo requerido'
      if (propValidator) return propValidator(value)
    },
    [propValidator],
  )

  // Trigger value
  // --------------------------------------------------------------------------
  useEffect(() => {
    if (isFirst.current) return

    context.setValue(propName, propValue)
  }, [propTriggerValueCheck])

  // Trigger error check
  // --------------------------------------------------------------------------
  useEffect(() => {
    if (isFirst.current) return
    const error = validator(propValue)
    context.setError(propName, error)
    setErrorState(error)
  }, [propTriggerErrorCheck])

  // On value
  // --------------------------------------------------------------------------
  useEffect(() => {
    context.setValue(propName, propValue)

    if (validateOnChange && !isFirst.current) {
      const error = validator(propValue)
      context.setError(propName, error)
      setErrorState(error)
    }
  }, [propValue])

  // On submit
  // --------------------------------------------------------------------------
  useEffect(() => {
    if (isFirst.current) return

    // Make sure untouched inputs display their initial error
    if (context.errors[propName] && !error)
      setErrorState(context.errors[propName])

    propOnSubmit && propOnSubmit(context.values)
  }, [context.submit])

  // On reset
  // --------------------------------------------------------------------------
  useEffect(() => {
    if (isFirst.current) return

    const error = validator(propDefaultValue)

    context.setValue(propName, propDefaultValue)
    context.setError(propName, error)

    setErrorState() // because after reset the input should be clean

    propOnReset && propOnReset()
  }, [context.reset])

  // Update context error on first render so untouched inputs can show the error
  // --------------------------------------------------------------------------
  useEffect(() => {
    const error = validator(propValue)
    context.setError(propName, error)
    isFirst.current = false
  }, [])

  // --------------------------------------------------------------------------
  // Handler
  // --------------------------------------------------------------------------

  const handleBlur = useCallback(
    (event) => {
      const error = validator(propValue)
      context.setError(propName, error)
      setErrorState(error)

      propOnBlur && propOnBlur(event)
    },
    [propValue, propName],
  )

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        const error = validator(propValue)
        context.setError(propName, error)
        setErrorState(error)
      }
      propOnKeyDown && propOnKeyDown(event)
    },
    [propValue, propName],
  )

  // --------------------------------------------------------------------------
  // Render
  // --------------------------------------------------------------------------

  // Defines the label-input proportion. Default label: 4, input: 8.
  // If neither span nor label are defined then all the column is taken by the input
  const span = useMemo(
    () => (propSpan || propLabel ? 4 : 0),
    [propSpan, propLabel],
  )

  // Inject default classes for the input
  const className = useMemo(
    () =>
      propOverwriteClassName
        ? [error ? 'is-invalid' : '', propClassName].join(' ')
        : [
            'form-control',
            'form-control-sm',
            error ? 'is-invalid' : '',
            propClassName,
          ].join(' '),
    [error, propClassName, propOverwriteClassName],
  )

  // Inject default clases for the container
  const containerClassName = useMemo(
    () => ['m-0', propContainerClassName].join(' '),
    [propContainerClassName],
  )

  // Render!
  return (
    <Form.Group as={Row} className={containerClassName}>
      {propLabel && <Label label={propLabel} />}
      <Col lg={12 - span}>
        <InputGroup size="sm">
          {propPrepend && (
            <InputGroup.Prepend>{propPrepend}</InputGroup.Prepend>
          )}
          <Input
            autoComplete={'off'}
            onChange={() => {
              /* Avoid uncontrolled input error by providing a default onChange funtion */
            }}
            {...rest}
            ref={ref}
            className={className}
            value={propValue}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
          />
          {propAppend && <InputGroup.Append>{propAppend}</InputGroup.Append>}
        </InputGroup>
        {error && <Feedback feedback={error} />}
        {propText && <Text text={propText} />}
      </Col>
    </Form.Group>
  )
})

export default UIFormInput
