import React from 'react'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

// Components
import UIPanelInfoTable from '../../../../UI/Panel/Info/Table'
import UIFormInputText from '../../../../UI/FormReload/Input/Text'
import UIFormInputInteger from '../../../../UI/FormReload/Input/Integer'

// Common
import { oe } from '../../../../../common/object/extractor'

import { AdministrationForm } from '../..'

// Queries
import { READ_SITES, CREATE_SITES, UPDATE_SITES, LIST_SITES } from './queries'

const queries = {
  read: READ_SITES,
  create: CREATE_SITES,
  update: UPDATE_SITES,
  list: LIST_SITES,
}

const popoverTitle = (
  <Popover id="popover-basic">
    <Popover.Title as="h3">Título</Popover.Title>
    <Popover.Content>
      Nombre de la faena utilizado en el título del archivo Excel. Es un valor
      único y sensible a mayúsculas, dado que identifica de manera inequívoca a
      la faena.
    </Popover.Content>
  </Popover>
)

const popoverAcronymous = (
  <Popover id="popover-basic">
    <Popover.Title as="h3">Acrónimo</Popover.Title>
    <Popover.Content>
      Identificador de 2 letras usado para construir y validar el TAG
    </Popover.Content>
  </Popover>
)

const CustomForm = ({ action, data, id }) => (
  <UIPanelInfoTable>
    {[
      action === 'update'
        ? ['ID', <UIFormInputText inline name="id" placeholder={id} disabled />]
        : [],

      [
        'ID Cliente',
        <UIFormInputInteger
          name="id_client"
          initialValue={oe(data, ['client', 'id'])}
          placeholder={oe(data, ['client', 'id'])}
          required
        />,
      ],
      [
        'Descripción',
        <UIFormInputText
          name="name"
          initialValue={oe(data, ['name'])}
          placeholder={oe(data, ['name'])}
          required
        />,
      ],
      [
        'Latitud',
        <UIFormInputText
          name="latitude"
          initialValue={oe(data, ['latitude'])}
          placeholder={oe(data, ['latitude'])}
        />,
      ],
      [
        'Longitud',
        <UIFormInputText
          name="longitude"
          initialValue={oe(data, ['longitude'])}
          placeholder={oe(data, ['longitude'])}
        />,
      ],
      [
        <>
          Título{' '}
          <OverlayTrigger placement="right" overlay={popoverTitle}>
            <FontAwesomeIcon
              className="align-self-center"
              icon={faInfoCircle}
            />
          </OverlayTrigger>
        </>,
        <UIFormInputText
          name="massive_name"
          initialValue={oe(data, ['massive_name'])}
          placeholder={oe(data, ['massive_name'])}
        />,
      ],
      [
        <>
          Acrónimo{' '}
          <OverlayTrigger placement="right" overlay={popoverAcronymous}>
            <FontAwesomeIcon
              className="align-self-center"
              icon={faInfoCircle}
            />
          </OverlayTrigger>
        </>,

        <UIFormInputText
          name="massive_short_name"
          initialValue={oe(data, ['massive_short_name'])}
          placeholder={oe(data, ['massive_short_name'])}
        />,
      ],
    ]}
  </UIPanelInfoTable>
)

//       <Card className="mt-2" bg="light">
//         <Card.Body>
//           <small>
//             "<strong>Título</strong>" es el nombre de la faena utilizado en el
//             título del archivo Excel. Es un valor único y sensible a mayúsculas,
//             dado que identifica de manera inequívoca a la faena.
//           </small>
//         </Card.Body>
//       </Card>
//       <Card className="mt-2" bg="light">
//         <Card.Body>
//           <small>
//             "<strong>Acrónimo</strong>" es el identificador de 2 letras para
//             usado para construir y validar el TAG
//           </small>
//         </Card.Body>
//       </Card>
// )

const AdministrationFormClientSites = () => (
  <AdministrationForm name={'faena'} queries={queries}>
    <CustomForm />
  </AdministrationForm>
)

export default AdministrationFormClientSites
