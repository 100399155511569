import { gql } from '../../../../../hooks'

export const READ_LUBRICANTS = gql`
  query AdministrationReadLubricantLubricants($id: ID!) {
    aRead(path: "lubricant/lubricants", id: $id) {
    }
  }
`

export const DELETE_LUBRICANTS = gql`
  mutation AdministrationDeleteLubricantLubricants($id: ID!) {
    aDelete(path: "lubricant/lubricants", id: $id) {
    }
  }
`

export const LIST_PROTOCOLS = gql`
  query AdministrationMiscProtocols {
    aProtocols {
    }
  }
`

export const LIST_BRANDS = gql`
  query AdministrationMiscLubricantBrands {
    aLubricantBrands {
    }
  }
`

export const LIST_MODELS_BY_BRAND = gql`
  query AdministrationMiscLubricantModelsByBrand($id: ID!) {
    aLubricantModelsByBrand(id: $id) {
    }
  }
`
