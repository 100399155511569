import React from 'react'
import { Pagination } from 'react-bootstrap'

// Hooks
import { useState, useEffect } from '../../../hooks'

const UIPagination = ({
  pagination: { page = 1, number_of_pages: nop },
  onClick,
}) => {
  const [loading, setLoadingStatus] = useState(false)

  useEffect(() => setLoadingStatus(false), [page])

  const handleClick = (number) => {
    if (number !== page) {
      onClick(number)
      setLoadingStatus(true)
    }
  }

  const Item = (number) => (
    <Pagination.Item
      disabled={loading}
      key={number}
      active={number === page}
      onClick={() => handleClick(number)}
    >
      {number}
    </Pagination.Item>
  )

  const Ellipsis = (number) => (
    <Pagination.Ellipsis disabled={loading} key={number} />
  )

  let items = []
  const limit = 20
  if (nop > limit) {
    const pages_extreme = 4 // Number of items in the paginator's extreme
    const pages_central = 5 // Number of items in the paginator's center

    // Left-most items
    ;[...Array(pages_extreme).keys()]
      .map((n) => ++n)
      .forEach((number) => items.push(Item(number)))

    // Ellipsis
    if (page !== pages_extreme + 1) items.push(Ellipsis(pages_extreme + 1))

    // Center
    const c = Math.ceil(nop / 2) // Central item position
    const o = Math.ceil((pages_central - 1) / 2) // Offset to the left and right

    if (page <= pages_extreme || page > nop - pages_extreme) {
      ;[...Array(pages_central).keys()]
        .map((n) => c - o + n)
        .forEach((number) => items.push(Item(number)))
    } else {
      ;[...Array(pages_central).keys()]
        .map((n) => page - o + n)
        .filter((n) => !!(n > pages_extreme) && !!(n < nop - pages_extreme + 1))
        .forEach((number) => items.push(Item(number)))
    }

    // Ellipsis
    if (page !== nop - pages_extreme)
      items.push(Ellipsis(nop - pages_extreme))

      // Right-most items
    ;[...Array(pages_extreme).keys()]
      .map((n) => nop - pages_extreme + n + 1)
      .forEach((number) => items.push(Item(number)))
  } else {
    for (let number = 1; number <= nop; number++) {
      items.push(Item(number))
    }
  }

  return (
    <>
      <Pagination bsPrefix="pagination justify-content-center">
        {items}
      </Pagination>
      {loading && (
        <p className="text-center mt-0">
          <small className="text-muted">Cargando ...</small>
        </p>
      )}
    </>
  )
}

export default UIPagination
