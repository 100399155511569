import React from 'react'

import AdministrationInfo from '../..'

// Queries
import { READ_STATES, DELETE_STATES } from './queries'

const AdministrationInfoAnalysisStates = ({ ...rest }) => (
  <AdministrationInfo
    queries={{ read: READ_STATES, delete: DELETE_STATES }}
    title="Comentario"
    {...rest}
    noDuplicate
    noDelete
  />
)

export default AdministrationInfoAnalysisStates
