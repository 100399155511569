import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan } from '@fortawesome/free-solid-svg-icons'
import {
  Row,
  Col,
  Form,
  Accordion,
  Card,
  Collapse,
  Table,
  Tabs,
  Tab,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
// import moment from 'moment'

// Hooks
import { useState, useQuery } from '../../../../hooks'

// Components
import UIButton from '../../../UI/Button'
import UIGraphPie from '../../../UI/Graph/Pie'
import UILoading from '../../../UI/Loading'
import UIError from '../../../UI/Error'
import UIEmpty from '../../../UI/Empty'
import UIPanelInfo from '../../../UI/Panel/Info'
import UIPanelInfoBlock from '../../../UI/Panel/Info/Block'
import UIFormSeparation from '../../../UI/FormReload/Separation'
import UITable from '../../../UI/Table'
import UITableBody from '../../../UI/Table/Body'

import InfoHeader from '../../Container/InfoHeader'

// Excel export
// import ButtonDownload from './download'

// Queries
import { GET_REPORT } from './queries'

const ReportTableFails = ({ filter, onFilterChange }) => {
  const [open, setOpenState] = useState([])

  // States
  const [machineTypesKey, setMachineTypesKeyState] = useState('machine_type_0')

  const { id_client, id_site, id_machine_type, id_machine, from, to } = filter

  // --------------------------------------------------------------------------
  //
  // Query report
  //
  // --------------------------------------------------------------------------

  const { data, loading } = useQuery(GET_REPORT, {
    variables: { ...filter },
    onErrorMessage: `Error al intentar obtener reporte`,
  })

  // --------------------------------------------------------------------------
  //
  // Render
  //
  // --------------------------------------------------------------------------

  if (loading) return <UILoading />

  if (data && data.reportFails) {
    const { error } = data.reportFails

    // ------------------------------------------------------------------------
    //
    // Info Body
    //
    // ------------------------------------------------------------------------

    let InfoBody
    let Graph
    let showExcelButton

    // Errors?
    // ------------------------------------------------------------------------
    if (error) {
      showExcelButton = false
      InfoBody = (
        <div className="text-center  py-5 border rounded">
          <FontAwesomeIcon
            size="10x"
            color="lightgray"
            className="mr-2"
            icon={faBan}
          />

          <h5 className="text-muted font-weight-light mt-5">{error.message}</h5>
        </div>
      )
    }
    // No errors
    // ------------------------------------------------------------------------
    else {
      const { info, results } = data.reportFails.data

      // Empty?
      // ----------------------------------------------------------------------
      if (!results || results.length === 0) {
        showExcelButton = false
        InfoBody = <UIEmpty />
      }
      // Not empty
      // ----------------------------------------------------------------------
      else {
        showExcelButton = true

        const anomalyMap = [
          { id: 1, name: 'Normal' },
          { id: 2, name: 'Desgaste de Componentes' },
          { id: 3, name: 'Contaminación Sílice - Desgaste' },
          { id: 4, name: 'Dilución Combustible' },
          { id: 5, name: 'Contaminación Agua' },
          { id: 6, name: 'Combustión Deficiente - Desgaste' },
          { id: 7, name: 'Código ISO 4406 - Sílice' },
          { id: 8, name: 'Contaminación Lubricante' },
        ]

        const classNameHeader = 'bg-stng border text-white align-middle'

        const { condition, anomaly, rows, total } = results

        const handleClick = (id) => {
          setOpenState((prev) => {
            const newPrev = [...prev]
            newPrev[id] = !prev[id]
            return newPrev
          })
        }

        const borderStyle = {
          light: '1px solid #DDDDDD',
          dark: '1px solid #999999',
        }

        InfoBody = (
          <UITable borderless>
            <thead className={classNameHeader}>
              <tr>
                <th colSpan="1"></th>
                <th
                  colSpan="3"
                  style={{
                    borderLeft: borderStyle.light,
                    borderRight: borderStyle.light,
                  }}
                >
                  <small>Condición</small>
                </th>
                <th colSpan="8">
                  <small>Anomalía</small>
                </th>
              </tr>
            </thead>
            <thead className={classNameHeader}>
              <tr className="border-bottom">
                <th
                  style={{
                    borderLeft: borderStyle.light,
                    borderRight: borderStyle.light,
                  }}
                >
                  <small>Tipo equipo</small>
                </th>
                {/*<th
                  style={{
                    borderLeft: borderStyle.light,
                    borderRight: borderStyle.light,
                  }}
                >
                  <small>Total flota</small>
                </th>*/}
                <th>
                  <small>Normal</small>
                </th>
                <th
                  style={{
                    borderLeft: borderStyle.light,
                    borderRight: borderStyle.light,
                  }}
                >
                  <small>Alertas</small>
                </th>
                <th
                  style={{
                    borderRight: borderStyle.light,
                  }}
                >
                  <small>Críticos</small>
                </th>
                {anomalyMap.map(({ name }) => (
                  <th
                    style={{
                      borderRight: borderStyle.light,
                    }}
                  >
                    <small>{name}</small>
                  </th>
                ))}
              </tr>
            </thead>
            <UITableBody>
              {rows.map(({ name, condition, anomaly, rows, total }, index) => (
                <>
                  <tr
                    style={{
                      backgroundColor: '#F0F0F0',
                      borderTop: '1px solid #E0E0E0',
                    }}
                  >
                    <td
                      style={{
                        borderLeft: borderStyle.light,
                        borderRight: borderStyle.light,
                      }}
                    >
                      <button
                        style={{
                          backgroundColor: 'rgb(0,0,0,0)',
                          border: 'none',
                        }}
                        className="text-decoration-none"
                        onClick={() => handleClick(index)}
                      >
                        {name}
                      </button>
                    </td>
                    {/*<td
                      style={{
                        borderLeft: borderStyle.dark,
                        borderRight: borderStyle.dark,
                      }}
                    >
                      {total}
                    </td>*/}
                    <td>{condition['1'] ? condition['1'] : 0}</td>
                    <td
                      style={{
                        borderLeft: borderStyle.light,
                        borderRight: borderStyle.light,
                      }}
                    >
                      {condition['2'] ? condition['2'] : 0}
                    </td>
                    <td style={{ borderRight: borderStyle.dark }}>
                      {condition['3'] ? condition['3'] : 0}
                    </td>
                    {anomalyMap.map(({ id }) => (
                      <td style={{ borderRight: borderStyle.light }}>
                        {anomaly[id] ? anomaly[id] : 0}
                      </td>
                    ))}
                  </tr>
                  {rows.map(
                    (
                      {
                        sample_condition_id,
                        component_type_name,
                        anomaly_type_id,
                      },
                      row_index,
                    ) => {
                      return (
                        <tr
                          hidden={!open[index]}
                          style={{
                            borderTop: !row_index ? borderStyle.dark : null,
                            borderBottom:
                              row_index + 1 === rows.length
                                ? borderStyle.dark
                                : null,
                          }}
                        >
                          <td
                            style={{
                              borderLeft: borderStyle.dark,
                              borderRight: borderStyle.dark,
                            }}
                          >
                            {component_type_name}
                          </td>
                          {/*<td
                            style={{
                              borderLeft: borderStyle.dark,
                              borderRight: borderStyle.dark,
                            }}
                          ></td>*/}
                          <td>{sample_condition_id === 1 ? 1 : ''}</td>
                          <td
                            style={{
                              borderLeft: borderStyle.light,
                              borderRight: borderStyle.light,
                            }}
                          >
                            {sample_condition_id === 2 ? 1 : ''}
                          </td>
                          <td style={{ borderRight: borderStyle.dark }}>
                            {sample_condition_id === 3 ? 1 : ''}
                          </td>
                          {anomalyMap.map(({ id }) => (
                            <td className="border-right">
                              {anomaly_type_id === id ? 1 : ''}
                            </td>
                          ))}
                        </tr>
                      )
                    },
                  )}
                </>
              ))}
              <tr className="font-weight-bold table-secondary">
                <td
                  style={{
                    borderLeft: borderStyle.light,
                    borderRight: borderStyle.dark,
                  }}
                >
                  Totales
                </td>
                {/*<td
                  style={{
                    borderLeft: borderStyle.dark,
                    borderRight: borderStyle.dark,
                  }}
                >
                  {total}
                </td>*/}
                <td>{condition['1'] ? condition['1'] : 0}</td>
                <td
                  style={{
                    borderLeft: borderStyle.light,
                    borderRight: borderStyle.light,
                  }}
                >
                  {condition['2'] ? condition['2'] : 0}
                </td>
                <td style={{ borderRight: borderStyle.dark }}>
                  {condition['3'] ? condition['3'] : 0}
                </td>
                {anomalyMap.map(({ id }) => (
                  <td style={{ borderRight: borderStyle.light }}>
                    {anomaly[id] ? anomaly[id] : 0}
                  </td>
                ))}
              </tr>
            </UITableBody>
          </UITable>
        )

        Graph = () => {
          const { anomaly } = results

          let total = 0
          for (const key in anomaly) total += anomaly[key]

          const data = anomalyMap
            .map(({ id, name }) => {
              if (!anomaly[id]) return null

              return {
                id: `${parseInt(100 * (anomaly[id] / total))}%`,
                label: name,
                value: anomaly[id],
              }
            })
            .filter((element) => element)

          return (
            <UIPanelInfo>
              <UIPanelInfoBlock title="Distribución modos de falla">
                <Row>
                  <Col xs={12} style={{ height: '300px' }}>
                    <UIGraphPie
                      colors={{ scheme: 'dark2' }}
                      data={data}
                      enableSliceLabels={false}
                      margin={{ top: 30, right: 80, bottom: 30, left: -50 }}
                      legends={[
                        {
                          anchor: 'right',
                          direction: 'column',
                          justify: false,
                          translateX: -150,
                          itemsSpacing: 5,
                          itemWidth: 150,
                          itemHeight: 15,
                          itemTextColor: '#999',
                          itemDirection: 'left-to-right',
                          symbolSize: 18,
                          symbolShape: 'circle',
                          effects: [
                            {
                              on: 'hover',
                              style: {
                                itemTextColor: '#000',
                              },
                            },
                          ],
                        },
                      ]}
                    />
                  </Col>
                </Row>
              </UIPanelInfoBlock>
            </UIPanelInfo>
          )
        }
      }
    }

    const handleBack = () => onFilterChange()

    return (
      <>
        <Form.Group as={Row} className="m-1">
          <Col className="d-flex">
            <UIButton type="submit" className="mx-2" onClick={handleBack}>
              Atrás
            </UIButton>
            {/*<ButtonDownload filter={filter} />*/}
            {showExcelButton && (
              <Link
                to={{
                  pathname: `/reports/fails/excel`,
                  search: new URLSearchParams(filter).toString(),
                }}
                className="btn btn-stng"
                target="_blank"
              >
                <img src="/excel.png" height="20" alt="Excel" />
              </Link>
            )}
          </Col>
        </Form.Group>
        <Row className="m-1">
          <Col xs={12}>
            <UIFormSeparation />
          </Col>
          {!error && (
            <Col xs={12}>
              <InfoHeader
                info={{
                  ...data.reportFails.data.info,
                  from,
                  to,
                }}
              />
            </Col>
          )}
          <Col xs={12}>{InfoBody}</Col>
          {showExcelButton && (
            <Col xs={12}>
              <Graph />
            </Col>
          )}
        </Row>
      </>
    )
  }

  return <UIError />
}

export default ReportTableFails
