import { gql } from '../../../../../hooks'

export const READ_CLIENTS = gql`
  query AdministrationReadClientClients($id: ID!) {
    aRead(path: "client/clients", id: $id) {
    }
  }
`

export const DELETE_CLIENTS = gql`
  mutation AdministrationDeleteClientClients($id: ID!) {
    aDelete(path: "client/clients", id: $id) {
    }
  }
`
