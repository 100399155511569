import React from 'react'
import {
  OverlayTrigger,
  Tooltip,
  Col,
  Row,
  Form,
  Table,
  Alert,
  Badge,
} from 'react-bootstrap'

import UIForm from '../../../../../UI/FormReload'
import UIFormInputFile from '../../../../../UI/FormReload/Input/File'
import UIFormSeparation from '../../../../../UI/FormReload/Separation'
import UIButton from '../../../../../UI/Button'
import UIError from '../../../../../UI/Error'

// Hooks
import { useAlert } from '../../../../../../hooks'

const SampleFormResultsMassive = ({ onChange, file }) => {
  const alert = useAlert()

  // --------------------------------------------------------------------------
  //
  // Render
  //
  // --------------------------------------------------------------------------

  const handleChange = (event) => {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0]
      onChange(file)
    }
  }

  const handleReset = () => {
    onChange()
    alert('Archivo enviado', { style: 'success' })
  }

  return (
    <UIForm onSubmit={handleReset}>
      <Form.Group as={Row} className="my-4">
        <Col className="d-flex">
          <UIFormInputFile
            name="file_name"
            bucketKey="results/laboratory"
            onDelete={handleReset}
            onChange={handleChange}
          />
          <UIButton type="submit" disabled={!file} className="mx-2">
            Subir archivo
          </UIButton>
        </Col>
      </Form.Group>
      <UIFormSeparation />
    </UIForm>
  )

  // Error
  return <UIError />
}

export default SampleFormResultsMassive
