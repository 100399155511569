import { gql } from '../../../../../hooks'

export const READ_SUGGESTIONS = gql`
  query AdministrationReadAnalysisSuggestions($id: ID!) {
    aRead(path: "analysis/suggestions", id: $id) {
    }
  }
`

export const UPDATE_SUGGESTIONS = gql`
  mutation AdministrationUpdateAnalysisSuggestions(
    $id: ID!
    $data: JSONObject
  ) {
    aUpdate(path: "analysis/suggestions", id: $id, data: $data) {
    }
  }
`

export const CREATE_SUGGESTIONS = gql`
  mutation AdministrationCreateAnalysisSuggestions($data: JSONObject) {
    aCreate(path: "analysis/suggestions", data: $data) {
    }
  }
`

export const LIST_SUGGESTIONS = gql`
  query AdministrationListAnalysisSuggestions(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(
      path: "analysis/suggestions"
      filter: $filter
      page: $page
      limit: $limit
    ) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`

export const LIST_ESSAYS = gql`
  query AdministrationListEssays {
    aEssays {
    }
  }
`
