import React from 'react'
import { Button } from 'react-bootstrap'

const UIButton = ({
  children,
  variant = 'stng',
  size = 'sm',
  className = '',
  type = 'button',
  ...rest
}) => {
  return (
    <Button
      size={size}
      type={type}
      className={`font-weight-light ${className}`}
      variant={variant}
      {...rest}
    >
      {children}
    </Button>
  )
}

export default UIButton
