import React from 'react'

// Components
import UIPanelInfoBlock from '../../../../UI/Panel/Info/Block'
import UIPanelInfoTable from '../../../../UI/Panel/Info/Table'
import UIFormInputText from '../../../../UI/FormReload/Input/Text'
import UIFormInputInteger from '../../../../UI/FormReload/Input/Integer'

// Common
import { oe } from '../../../../../common/object/extractor'

import AdministrationInfo, { RefLink } from '../..'

// Queries
import { READ_COMPONENTS, DELETE_COMPONENTS } from './queries'

const Info = (data) => (
  <>
    <UIPanelInfoBlock title="Componente">
      <UIPanelInfoTable>
        {[
          ['ID', oe(data, ['component', 'id'])],
          ['TAG', oe(data, ['component', 'tag'])],
          ['Descripción', oe(data, ['component', 'name'])],
          ['Número de serie', oe(data, ['component', 'serial_number'])],
          [
            'Tipo componente',
            <RefLink
              id={oe(data, ['component', 'type', 'id'])}
              tab={'component'}
              subtab={'types'}
            >
              {oe(data, ['component', 'type', 'name'])}
            </RefLink>,
          ],
          [
            'Marca',
            <RefLink
              id={oe(data, ['component', 'brand', 'id'])}
              tab={'component'}
              subtab={'brands'}
            >
              {oe(data, ['component', 'brand', 'name'])}
            </RefLink>,
          ],
          [
            'Modelo',
            <RefLink
              id={oe(data, ['component', 'model', 'id'])}
              tab={'component'}
              subtab={'models'}
            >
              {oe(data, ['component', 'model', 'name'])}
            </RefLink>,
          ],
          ['Cantidad de litros', oe(data, ['component', 'liters'])],
          [
            'Punto de muestreo',
            <RefLink
              id={oe(data, ['component', 'sampling_point', 'id'])}
              tab={'sampling'}
              subtab={'points'}
            >
              {oe(data, ['component', 'sampling_point', 'name'])}
            </RefLink>,
          ],
          [
            'Procedimiento de muestreo',
            <RefLink
              id={oe(data, ['component', 'sampling_procedure', 'id'])}
              tab={'sampling'}
              subtab={'procedures'}
            >
              {oe(data, ['component', 'sampling_procedure', 'name'])}
            </RefLink>,
          ],
          [
            'Protocolo (Metal)',
            <RefLink
              id={oe(data, ['component', 'protocol', 'id'])}
              tab={'component'}
              subtab={'protocols'}
            >
              {oe(data, ['component', 'protocol', 'name'])}
            </RefLink>,
          ],
          [
            'Lubricante',
            <RefLink
              id={oe(data, ['lubricant', 'id'])}
              tab={'lubricant'}
              subtab={'lubricants'}
            >
              {oe(data, ['lubricant', 'name'])}
            </RefLink>,
          ],
          [
            'Protocolo (AFQ)',
            <RefLink
              id={oe(data, ['lubricant', 'protocol', 'id'])}
              tab={'lubricant'}
              subtab={'protocols'}
            >
              {oe(data, ['lubricant', 'protocol', 'name'])}
            </RefLink>,
          ],
          [
            'Equipo',
            <RefLink
              id={oe(data, ['machine', 'id'])}
              tab={'machine'}
              subtab={'machines'}
            >
              {oe(data, ['machine', 'name'])}
            </RefLink>,
          ],
          [
            'Tipo equipo',
            <RefLink
              id={oe(data, ['machine', 'type', 'id'])}
              tab={'machine'}
              subtab={'types'}
            >
              {oe(data, ['machine', 'type', 'name'])}
            </RefLink>,
          ],
          [
            'Faena',
            <RefLink
              id={oe(data, ['site', 'id'])}
              tab={'client'}
              subtab={'sites'}
            >
              {oe(data, ['site', 'name'])}
            </RefLink>,
          ],
          [
            'Cliente',
            <RefLink
              id={oe(data, ['client', 'id'])}
              tab={'client'}
              subtab={'clients'}
            >
              {oe(data, ['client', 'name'])}
            </RefLink>,
          ],
          [
            'Frecuencia de muestreo',
            oe(data, ['unsampled', 'active'])
              ? `${oe(data, ['unsampled', 'interval']) || '-'} ${
                  oe(data, ['unsampled', 'type', 'name'], ['-', '-'])[
                    oe(data, ['unsampled', 'interval'], '-') === 1 ? 0 : 1
                  ]
                }`
              : 'No activado',
          ],
        ]}
      </UIPanelInfoTable>
    </UIPanelInfoBlock>
  </>
)

const Form = () => (
  <>
    <UIFormInputInteger label="ID" name="id" />
    <UIFormInputText label="TAG" name="tag" />
    {/*}<UIFormInputText label="Descripción" name="name" />*/}
    <UIFormInputInteger label="ID cliente" name="client_id" />
    <UIFormInputText label="Descripción cliente" name="client_name" />
    <UIFormInputInteger label="ID faena" name="site_id" />
    <UIFormInputText label="Descripción faena" name="site_name" />
    <UIFormInputInteger label="ID equipo" name="machine_id" />
    <UIFormInputText label="Descripción equipo" name="machine_name" />
  </>
)

const AdministrationInfoComponentComponents = ({ ...rest }) => (
  <AdministrationInfo
    queries={{ read: READ_COMPONENTS, delete: DELETE_COMPONENTS }}
    views={{ form: Form, info: Info }}
    {...rest}
  />
)

export default AdministrationInfoComponentComponents
