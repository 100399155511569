import { gql } from '../../../../../../hooks'

export const READ_CLIENTS_CASES = gql`
  query AdministrationReadClientClientsCases($id: ID!) {
    aRead(path: "client/clients/cases", id: $id) {
    }
  }
`

export const DELETE_CLIENTS_CASES = gql`
  mutation AdministrationDeleteClientClientsCases($id: ID!) {
    aDelete(path: "client/clients/cases", id: $id) {
    }
  }
`
