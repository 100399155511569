import React from 'react'

// Components
import AdministrationListSamplingEssayUnits from '../../../../Administration/List/Sampling/EssayUnits'
import AdministrationInfoSamplingEssayUnits from '../../../../Administration/Info/Sampling/EssayUnits'
import AdministrationFormSamplingEssayUnits from '../../../../Administration/Form/Sampling/EssayUnits'

import { AdministrationContainer } from '../..'

const AdministrationContainerSamplingEssayUnits = () => (
  <AdministrationContainer
    form={AdministrationFormSamplingEssayUnits}
    info={AdministrationInfoSamplingEssayUnits}
    list={AdministrationListSamplingEssayUnits}
  />
)

export default AdministrationContainerSamplingEssayUnits
