import React from 'react'

import { AdministrationForm } from '../..'

// Queries
import {
  READ_ESSAY_UNITS,
  CREATE_ESSAY_UNITS,
  UPDATE_ESSAY_UNITS,
  LIST_ESSAY_UNITS,
} from './queries'

const queries = {
  read: READ_ESSAY_UNITS,
  create: CREATE_ESSAY_UNITS,
  update: UPDATE_ESSAY_UNITS,
  list: LIST_ESSAY_UNITS,
}

const AdministrationFormSamplingEssayUnits = () => (
  <AdministrationForm name={'unidad de medida'} queries={queries} />
)

export default AdministrationFormSamplingEssayUnits
