import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faUndo } from '@fortawesome/free-solid-svg-icons'

// Hooks
import { useQuery, useMutation } from '../../../hooks'

// Components
import UILoading from '../../UI/Loading'
import UIAlert from '../../UI/Alert'
import UIPanelInfo from '../../UI/Panel/Info'
import UIPanelInfoBlock from '../../UI/Panel/Info/Block'
import UIPanelInfoItem from '../../UI/Panel/Info/Item'
import UIPanelInfoTable from '../../UI/Panel/Info/Table'
import UIButton from '../../UI/Button'
import UIForm from '../../UI/FormReload'
import UIFormInputText from '../../UI/FormReload/Input/Text'
import UIFormSeparation from '../../UI/FormReload/Separation'

// Common
import { oe } from '../../../common/object/extractor'

import { AdministrationContainerContext } from '../Container'

export const AdministrationForm = ({
  queries = {},
  name,
  transformValuesOnSubmit = (values) => values,
  // ignoreEmptyValues,
  // ignoreValues = ['id'],
  // convertEmtyTo, // TODO this
  children,
}) => {
  const {
    data: { id, pagination } = {},
    filter,
    action,
    setDataState,
    setFiltersState,
    setActionState,
    forceUpdate,
    triggerReset,
    setTriggerRefetch,
  } = useContext(AdministrationContainerContext)

  const history = useHistory()

  const { data, loading, error } = useQuery(queries.read, {
    fetchPolicy: 'network-only',
    variables: { id },
    skip: !queries.read || !id,
  })

  // --------------------------------------------------------------------------
  //
  // Create mutation
  //
  // --------------------------------------------------------------------------

  const [createMutation] = useMutation(queries.create, {
    update: () => forceUpdate(),
    // onSuccessMessage: 'El elemento se creó existosamente',
    onSuccess: ({ data }) => {
      setDataState({ id: data.id, pagination })
      setActionState('view')
      setTriggerRefetch()
    },
    // onErrorMessage: 'No se pudo crear el elemento',
  })

  // --------------------------------------------------------------------------
  //
  // Update mutation
  //
  // --------------------------------------------------------------------------

  const [updateMutation] = useMutation(queries.update, {
    update: () => forceUpdate(),
    // onSuccessMessage: 'El elemento se actualizó correctamente',
    onSuccess: ({ data }) => {
      setDataState({ id: data.id, pagination })
      setActionState('view')
      setTriggerRefetch()
    },
    // onErrorMessage: 'No se pudo actualizar el elemento',
  })

  if (loading) {
    return (
      <UIPanelInfo centered>
        <UILoading />
      </UIPanelInfo>
    )
  }

  const handleCancel = () => {
    setDataState({ pagination })
    history.push({ search: undefined })
    setActionState('view')
  }

  if (error) {
    const { message, graphQLErrors } = error

    return (
      <UIPanelInfo>
        <UIPanelInfoBlock title="Error">
          <div className="mt-3">
            <UIPanelInfoItem>
              <UIAlert style="danger" noDismissible className="text-center">
                {oe(
                  graphQLErrors[0],
                  ['extensions', 'response', 'body', 'error', 'message'],
                  message,
                )}
              </UIAlert>
            </UIPanelInfoItem>
            <UIFormSeparation />
            <div className="mt-2">
              <UIButton onClick={handleCancel} className="mr-2" variant="light">
                Cancelar
              </UIButton>
            </div>
          </div>
        </UIPanelInfoBlock>
      </UIPanelInfo>
    )
  }

  const handleSubmit = (values) => {
    values = transformValuesOnSubmit(values)

    // if (ignoreEmptyValues)
    //   Object.keys(values)
    //     .filter((key) => values[key] === '')
    //     .forEach((key) => delete values[key])

    // if (ignoreValues) ignoreValues.forEach((key) => delete values[key])

    if (action === 'create') createMutation({ variables: { data: values } })
    else updateMutation({ variables: { id, data: values } })
  }

  const element = data && data.aRead && data.aRead.data

  return (
    <UIPanelInfo>
      <UIPanelInfoBlock
        title={action === 'create' ? `Nuevo ${name}` : `Editar ${name}`}
      >
        <div className="mt-3">
          <UIForm onSubmit={handleSubmit} triggerReset={triggerReset}>
            {children ? (
              React.cloneElement(children, {
                action,
                data: element,
                id,
              })
            ) : (
              <UIPanelInfoTable>
                {[
                  action === 'update'
                    ? [
                        'ID',
                        <UIFormInputText
                          name="id"
                          initialValue={id}
                          placeholder={id}
                          disabled
                        />,
                      ]
                    : [],
                  [
                    'Descripción',
                    <UIFormInputText
                      name="name"
                      placeholder={oe(element, ['name'])}
                      initialValue={oe(element, ['name'])}
                      required
                    />,
                  ],
                ]}
              </UIPanelInfoTable>
            )}
            <UIFormSeparation />
            <div className="mt-2 text-center">
              <UIButton
                onClick={handleCancel}
                variant="light"
                className="mr-2"
                title="Atrás"
              >
                <FontAwesomeIcon
                  className="align-self-center"
                  style={{ width: '15px' }}
                  icon={faUndo}
                />
              </UIButton>
              <UIButton
                type="submit"
                variant="success"
                className="mr-2"
                title={action === 'create' ? 'Crear' : 'Guardar'}
              >
                <FontAwesomeIcon
                  className="align-self-center"
                  style={{ width: '15px' }}
                  icon={faCheck}
                />
              </UIButton>
            </div>
          </UIForm>
        </div>
      </UIPanelInfoBlock>
    </UIPanelInfo>
  )
}
