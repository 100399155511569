import { gql } from '../../../../../hooks'

export const READ_COMPONENTS = gql`
  query AdministrationReadComponentComponents($id: ID!) {
    aRead(path: "component/components", id: $id) {
    }
  }
`

export const DELETE_COMPONENTS = gql`
  mutation AdministrationDeleteComponentComponents($id: ID!) {
    aDelete(path: "component/components", id: $id) {
    }
  }
`
