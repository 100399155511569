import React from 'react'

// Components
import UIDashboardQuickSight from '../../UI/Dashboard/QuickSight'

const DashboardPelambres = () => {
  return <UIDashboardQuickSight id="faf540b2-f1b6-445c-8a9c-17d6d3cf463e" />
}

export default DashboardPelambres
