import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortUp } from '@fortawesome/free-solid-svg-icons'
// import { Form } from 'react-bootstrap'

const UIFormInputHelperFeedback = ({ feedback }) => {
  return (
    <small className="text-danger ml-2">
      <FontAwesomeIcon size="xs" icon={faSortUp} /> {feedback}
    </small>
  )
}

export default UIFormInputHelperFeedback
