import React from 'react'

import { AdministrationContainerTab } from '..'

import Clients from './Clients'
import ClientsCases from './Clients/Cases'
import Sites from './Sites'
import SitesCases from './Sites/Cases'

const subtabs = [
  { name: 'Clientes', subtab: 'clients', component: Clients },
  { name: 'Casos clientes', subtab: 'clientsCases', component: ClientsCases },
  { name: 'Faenas', subtab: 'sites', component: Sites },
  { name: 'Casos faenas', subtab: 'sitesCases', component: SitesCases },
]

const AdministrationContainerClient = () => (
  <AdministrationContainerTab subtabs={subtabs} />
)

export default AdministrationContainerClient
