import React from 'react'
import { Row, Col, Form } from 'react-bootstrap'
// import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan } from '@fortawesome/free-solid-svg-icons'

// Hooks
import { useQuery } from '../../../../hooks'

// Components
import UIButton from '../../../UI/Button'
import UILoading from '../../../UI/Loading'
import UIError from '../../../UI/Error'
import UIEmpty from '../../../UI/Empty'
import UIFormSeparation from '../../../UI/FormReload/Separation'
import UITable from '../../../UI/Table'
import UITableHead from '../../../UI/Table/Head'
import UITableBody from '../../../UI/Table/Body'
// import UIPanelInfo from '../../../UI/Panel/Info'
// import UIPanelInfoSeparation from '../../../UI/Panel/Info/Separation'
// import UIPanelInfoBlock from '../../../UI/Panel/Info/Block'
// import UIPanelInfoItem from '../../../UI/Panel/Info/Item'

import InfoHeader from '../../Container/InfoHeader'

// Excel export
import ButtonDownload from './download'

// Queries
import { GET_REPORT } from './queries'

const ReportTableConcatenator = ({ filter, onFilterChange }) => {
  // const { id_client, id_site, id_machine_type, id_machine, from, to } = filter
  const { from, to } = filter

  // const handleBack = () => onFilterChange()

  // --------------------------------------------------------------------------
  //
  // Query report
  //
  // --------------------------------------------------------------------------

  const { data, loading } = useQuery(GET_REPORT, {
    variables: { ...filter },
    onErrorMessage: `Error al intentar obtener reporte`,
  })

  // return (
  //   <>
  //     <Form.Group as={Row} className="m-1">
  //       <Col className="d-flex">
  //         <UIButton type="submit" className="mx-2" onClick={handleBack}>
  //           Atrás
  //         </UIButton>
  //         {showExcelButton && <ButtonDownload filter={filter} />}
  //       </Col>
  //     </Form.Group>
  //     <Row className="m-1">
  //       <Col xs={12}>
  //         <UIFormSeparation />
  //       </Col>
  //       <Col xs={12}>
  //         <UIPanelInfo style="info">
  //           <UIPanelInfoBlock title="Importante">
  //             <UIPanelInfoItem className="text-center">
  //               Dependiendo del rango de fechas y la profundidad de la búsqueda
  //               este reporte puede tardar un tiempo no menor en generarse.
  //             </UIPanelInfoItem>
  //             <UIPanelInfoItem className="text-center">
  //               Por favor tener paciencia y no volver a generarlo mientras no
  //               haya terminado el anterior.
  //             </UIPanelInfoItem>
  //           </UIPanelInfoBlock>
  //         </UIPanelInfo>
  //       </Col>
  //     </Row>
  //   </>
  // )

  // --------------------------------------------------------------------------
  //
  // Render
  //
  // --------------------------------------------------------------------------

  if (loading) return <UILoading />

  if (data && data.reportConcatenator) {
    const { error } = data.reportConcatenator

    // ------------------------------------------------------------------------
    //
    // Info Body
    //
    // ------------------------------------------------------------------------

    let InfoBody
    let showExcelButton

    // Errors?
    // ------------------------------------------------------------------------
    if (error) {
      showExcelButton = false
      InfoBody = (
        <div className="text-center  py-5 border rounded">
          <FontAwesomeIcon
            size="10x"
            color="lightgray"
            className="mr-2"
            icon={faBan}
          />

          <h5 className="text-muted font-weight-light mt-5">{error.message}</h5>
        </div>
      )
    }
    // No errors
    // ------------------------------------------------------------------------
    else {
      const { results, headers } = data.reportConcatenator.data

      // Empty?
      // ----------------------------------------------------------------------
      if (results.length === 0) {
        showExcelButton = false
        InfoBody = <UIEmpty />
      }
      // Not empty
      // ----------------------------------------------------------------------
      else {
        showExcelButton = true
        const classNameHeader = 'bg-stng border text-white align-middle'

        InfoBody = (
          <UITable borderless>
            <UITableHead className={classNameHeader}>{headers}</UITableHead>
            <UITableBody>
              {results.map((result, index) => {
                return (
                  <tr key={`row-${index}`}>
                    <td>{result.name}</td>
                    <td>{result.count_normal}</td>
                    <td>{result.count_alert}</td>
                    <td>{result.count_anormal}</td>
                  </tr>
                )
              })}
            </UITableBody>
          </UITable>
        )
      }
    }

    const handleBack = () => onFilterChange()

    return (
      <>
        <Form.Group as={Row} className="m-1">
          <Col className="d-flex">
            <UIButton type="submit" className="mx-2" onClick={handleBack}>
              Atrás
            </UIButton>
            {showExcelButton && <ButtonDownload filter={filter} />}
          </Col>
        </Form.Group>
        <Row className="m-1">
          <Col xs={12}>
            <UIFormSeparation />
          </Col>
          {!error && (
            <Col xs={12}>
              <InfoHeader
                info={{
                  ...data.reportConcatenator.data.info,
                  from,
                  to,
                }}
              />
            </Col>
          )}
          <Col xs={12}>{InfoBody}</Col>
        </Row>
      </>
    )
  }

  return <UIError />
}

export default ReportTableConcatenator
