import { gql } from '../../../../../hooks'

export const DELETE_STATES = gql`
  mutation AdministrationDeleteAnalysisStates($id: ID!) {
    aDelete(path: "analysis/states", id: $id) {
    }
  }
`

export const LIST_STATES = gql`
  query AdministrationListAnalysisStates(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(
      path: "analysis/states"
      filter: $filter
      page: $page
      limit: $limit
    ) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`
