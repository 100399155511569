import React from 'react'

// Components
import UIFormInputText from '../../../../UI/FormReload/Input/Text'
import UIFormInputSelect from '../../../../UI/FormReload/Input/Select'
import UIPanelInfoTable from '../../../../UI/Panel/Info/Table'

// Common
import { oe } from '../../../../../common/object/extractor'

import { AdministrationForm } from '../..'

// Queries
import {
  READ_MODELS,
  CREATE_MODELS,
  UPDATE_MODELS,
  LIST_MODELS,
  LIST_BRANDS,
} from './queries'

const queries = {
  read: READ_MODELS,
  create: CREATE_MODELS,
  update: UPDATE_MODELS,
  list: LIST_MODELS,
}

const CustomForm = ({ action, data, id }) => (
  <UIPanelInfoTable>
    {[
      action === 'update'
        ? [
            'ID',
            <UIFormInputText
              name="id"
              initialValue={id}
              placeholder={id}
              disabled
            />,
          ]
        : [],
      [
        'Marca',
        <UIFormInputSelect
          name="id_brand"
          query={LIST_BRANDS}
          initialValue={oe(data, ['brand', 'id'])}
          required
        >
          <option>Seleccione una marca</option>
        </UIFormInputSelect>,
      ],
      //  action === 'update'
      //    ? [
      //        'Marca',
      //        <UIFormInputText
      //          inline
      //          name="brand"
      //          disabled
      //          placeholder={oe(data, ['brand', 'name'])}
      //        />,
      //      ]
      //    : [],

      //  action === 'create'
      //    ? [
      //        'Marca',
      //        <UIFormInputSelect
      //          inline
      //          name="id_brand"
      //          query={LIST_BRANDS}
      //          initialValue={oe(data, ['brand', 'id'])}
      //          required
      //        >
      //          <option>Seleccione una marca</option>
      //        </UIFormInputSelect>,
      //      ]
      //    : [],
      [
        'Descripción',
        <UIFormInputText
          name="name"
          initialValue={oe(data, ['name'])}
          placeholder={oe(data, ['name'])}
          required
        />,
      ],
    ]}
  </UIPanelInfoTable>
)

const AdministrationFormLubricantModels = () => (
  <AdministrationForm name={'modelo'} queries={queries}>
    <CustomForm />
  </AdministrationForm>
)

export default AdministrationFormLubricantModels
