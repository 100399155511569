import React from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import moment from 'moment'

// Hooks
import {
  useMemo,
  useQuery,
  useHistory,
  useState,
  useGlobal,
} from '../../../../../hooks'

// Components
import UIButton from '../../../../UI/Button'
import UIForm from '../../../../UI/FormReload'
import UIFormInputSelect from '../../../../UI/FormReload/Input/Select'
import UIFormInputDate from '../../../../UI/FormReload/Input/Date'
import UIFormSeparation from '../../../../UI/FormReload/Separation'
import UILoading from '../../../../UI/Loading'
import UIError from '../../../../UI/Error'
import UIEmpty from '../../../../UI/Empty'
import UITable from '../../../../UI/Table'
import UITableHead from '../../../../UI/Table/Head'
import UITableBody from '../../../../UI/Table/Body'

// Queries
import { LIST_SHIFT_CHANGES } from './queries'

const ShiftChangeFilter = () => {
  const [{ me }] = useGlobal()

  const history = useHistory()

  var today = useMemo(() => new Date())

  const [dates, setDatesState] = useState({
    from: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6),
    to: today,
  })

  const { data, loading } = useQuery(LIST_SHIFT_CHANGES, {
    fetchPolicy: 'network-only',
  })

  const handleSubmit = (values) => {
    const { id_role = 3, date_from, date_to } = values

    history.push(
      `/tribology/samples/shift_change/form?id_role=${id_role}&date_from=${date_from}&date_to=${date_to}`,
    )
  }

  if (loading) return <UILoading />

  if (data && data.t7ShiftChangeList && data.t7ShiftChangeList.data) {
    const list = data.t7ShiftChangeList.data

    const handleClick =
      ({ date_from, date_to, id_role }) =>
      () => {
        history.push(
          `/tribology/samples/shift_change/view?id_role=${id_role}&date_from=${date_from}&date_to=${date_to}`,
        )
      }

    const handleDateChange = (key) => {
      return (date) => {
        if (key === 'to') setDatesState((prev) => ({ ...prev, to: date }))
        else
          setDatesState({
            from: date,
            to: new Date(
              date.getFullYear(),
              date.getMonth(),
              date.getDate() + 6,
            ),
          })
      }
    }

    return (
      <UIForm onSubmit={handleSubmit}>
        <Form.Group as={Row} className="my-1">
          <Col className="d-flex">
            <UIButton type="submit">Generar plantilla</UIButton>
          </Col>
        </Form.Group>
        <UIFormSeparation />
        <Row>
          <Col sm={{ span: 6, offset: 2 }}>
            {[1, 2].includes(me.id_role) && (
              <>
                <UIFormInputSelect
                  label="Rol"
                  name="id_role"
                  required
                  initialValue={3}
                >
                  <option value={3}>Analista</option>
                  {me.laboratory === 'antofagasta' && (
                    <option value={4}>Ingeniero</option>
                  )}
                </UIFormInputSelect>
                <UIFormSeparation />
              </>
            )}
            <UIFormInputDate
              label="Desde"
              name="date_from"
              required
              value={dates.from}
              onChange={handleDateChange('from')}
            />
            <UIFormInputDate
              label="Hasta"
              name="date_to"
              required
              value={dates.to}
              onChange={handleDateChange('to')}
              minDate={dates.from}
            />
          </Col>
          <Col sm={12}>
            <UIFormSeparation text="Últimos 10 reportes" />
            {list && !list.length ? (
              <UIEmpty />
            ) : (
              <UITable bordered className="mt-2">
                <UITableHead>{['Rol', 'Desde', 'Hasta', '']}</UITableHead>
                <UITableBody>
                  {list.map(({ date_from, date_to, id_role }, index) => (
                    <tr key={`shift-changes-${index}`}>
                      <td>
                        {[3, '3'].includes(id_role) ? 'Analista' : 'Ingeniero'}
                      </td>
                      <td>
                        {moment(date_from, 'YYYY/MM/DD').format('DD-MM-YYYY')}
                      </td>
                      <td>
                        {moment(date_to, 'YYYY/MM/DD').format('DD-MM-YYYY')}
                      </td>
                      <td>
                        <UIButton
                          onClick={handleClick({ date_from, date_to, id_role })}
                        >
                          Ver
                        </UIButton>
                      </td>
                    </tr>
                  ))}
                </UITableBody>
              </UITable>
            )}
          </Col>
        </Row>
      </UIForm>
    )
  }

  return <UIError />
}

export default ShiftChangeFilter
