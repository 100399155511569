import React from 'react'

import { AdministrationForm } from '../..'

// Queries
import {
  READ_ESSAY_CLASSIFICATIONS,
  CREATE_ESSAY_CLASSIFICATIONS,
  UPDATE_ESSAY_CLASSIFICATIONS,
  LIST_ESSAY_CLASSIFICATIONS,
} from './queries'

const queries = {
  read: READ_ESSAY_CLASSIFICATIONS,
  create: CREATE_ESSAY_CLASSIFICATIONS,
  update: UPDATE_ESSAY_CLASSIFICATIONS,
  list: LIST_ESSAY_CLASSIFICATIONS,
}

const AdministrationFormSamplingEssayClassifications = () => (
  <AdministrationForm name={'clasificación de ensayo'} queries={queries} />
)

export default AdministrationFormSamplingEssayClassifications
