import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan } from '@fortawesome/free-solid-svg-icons'
import {
  Row,
  Col,
  Form,
  Accordion,
  Card,
  Table,
  Tabs,
  Tab,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
// import moment from 'moment'

// Hooks
import { useState, useQuery } from '../../../../hooks'

// Components
import UIButton from '../../../UI/Button'
import UIGraphLine from '../../../UI/Graph/Line'
import UILoading from '../../../UI/Loading'
import UIError from '../../../UI/Error'
import UIEmpty from '../../../UI/Empty'
import UIFormSeparation from '../../../UI/FormReload/Separation'
import UITable from '../../../UI/Table'
import UITableHead from '../../../UI/Table/Head'
import UITableBody from '../../../UI/Table/Body'

import InfoHeader from '../../Container/InfoHeader'

// Excel export
// import { Download, processData } from './download'
// import ButtonDownload from './download'

// Queries
import { GET_REPORT } from './queries'

const ReportTableFleetState = ({ filter, onFilterChange }) => {
  // States
  const [machineTypesKey, setMachineTypesKeyState] = useState('machine_type_0')

  const { id_client, id_site, id_machine_type, from, to } = filter

  // --------------------------------------------------------------------------
  //
  // Query report
  //
  // --------------------------------------------------------------------------

  const { data, loading } = useQuery(GET_REPORT, {
    variables: { ...filter },
    onError: id_site
      ? `Error al intentar obtener reporte.`
      : `Recuerde añadir Faena.`,
  })

  // --------------------------------------------------------------------------
  //
  // Render
  //
  // --------------------------------------------------------------------------

  if (loading) return <UILoading />

  if (data && data.reportFleetState) {
    const { error } = data.reportFleetState

    // ------------------------------------------------------------------------
    //
    // Info Body
    //
    // ------------------------------------------------------------------------

    let InfoBody
    let showExcelButton

    // Errors?
    // ------------------------------------------------------------------------
    if (error) {
      showExcelButton = false
      InfoBody = (
        <div className="text-center  py-5 border rounded">
          <FontAwesomeIcon
            size="10x"
            color="lightgray"
            className="mr-2"
            icon={faBan}
          />

          <h5 className="text-muted font-weight-light mt-5">{error.message}</h5>
        </div>
      )
    }
    // No errors
    // ------------------------------------------------------------------------
    else {
      const { info, results } = data.reportFleetState.data

      // Empty?
      // ----------------------------------------------------------------------
      if (results.length === 0) {
        showExcelButton = false
        InfoBody = <UIEmpty />
      }
      // Not empty
      // ----------------------------------------------------------------------
      else {
        showExcelButton = true

        const rowsNumber = Math.max(
          ...results.map(({ machines }) => machines.length),
        )

        const classNameHeader = 'bg-stng border text-white align-middle '

        InfoBody = (
          <UITable borderless>
            <UITableHead className={classNameHeader}>
              {results.map(({ name }) => (
                <small>{name}</small>
              ))}
            </UITableHead>
            <UITableBody>
              {[...Array(rowsNumber).keys()].map((index) => (
                <tr key={`tr-${index}`}>
                  {results.map(({ name, machines }) => {
                    if (!machines[index]) return <td key={`td-${name}`}></td>

                    const idCondition = machines[index].max_condition

                    const className =
                      idCondition === 3
                        ? 'bg-danger text-light'
                        : idCondition === 2
                        ? 'bg-warning text-dark'
                        : 'bg-success text-dark'

                    return (
                      <td key={`td-${name}`} className={`${className} border`}>
                        <Link
                          to={{
                            pathname: `/reports/fleet_state/machine/${machines[index].machine_id}`,
                            search: `?from=${from}&to=${to}`,
                          }}
                          className={`text-decoration-none ${className} py-1`}
                          target="_blank"
                        >
                          {machines[index].machine_name}
                        </Link>
                      </td>
                    )
                  })}
                </tr>
              ))}
            </UITableBody>
          </UITable>
        )
      }
    }

    const handleBack = () => onFilterChange()

    return (
      <>
        <Form.Group as={Row} className="m-1">
          <Col className="d-flex">
            <UIButton type="submit" className="mx-2" onClick={handleBack}>
              Atrás
            </UIButton>
            {/*<ButtonDownload filter={filter} />*/}
            {showExcelButton && (
              <Link
                to={{
                  pathname: `/reports/fleet_state/excel`,
                  search: new URLSearchParams(filter).toString(),
                }}
                className="btn btn-stng"
                target="_blank"
              >
                <img src="/excel.png" height="20" alt="Excel" />
              </Link>
            )}
          </Col>
        </Form.Group>
        <Row className="m-1">
          <Col xs={12}>
            <UIFormSeparation />
          </Col>
          {!error && (
            <Col xs={12}>
              <InfoHeader
                info={{
                  ...data.reportFleetState.data.info,
                  from,
                  to,
                }}
              />
            </Col>
          )}
          <Col xs={12}>{InfoBody}</Col>
        </Row>
      </>
    )
  }

  return <UIError />
}

export default ReportTableFleetState
