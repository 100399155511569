import React from 'react'

// Components
import AdministrationListComponentProtocols from '../../../../Administration/List/Component/Protocols'
import AdministrationInfoComponentProtocols from '../../../../Administration/Info/Component/Protocols'
import AdministrationFormComponentProtocols from '../../../../Administration/Form/Component/Protocols'

import { AdministrationContainer } from '../..'

const AdministrationContainerComponentProtocols = () => (
  <AdministrationContainer
    form={AdministrationFormComponentProtocols}
    info={AdministrationInfoComponentProtocols}
    list={AdministrationListComponentProtocols}
  />
)

export default AdministrationContainerComponentProtocols
