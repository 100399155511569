import React from 'react'
import { Tabs, Tab, Badge } from 'react-bootstrap'

// Hooks
import { useState } from '../../../../../hooks'

// Components
import UIFormInputText from '../../../../UI/FormReload/Input/Text'
import UIFormInputSearch from '../../../../UI/FormReload/Input/Search'
import UIFormInputSelect from '../../../../UI/FormReload/Input/Select'
import UIFormInputNumber from '../../../../UI/FormReload/Input/Number'
import UIFormInputInteger from '../../../../UI/FormReload/Input/Integer'
import UIFormInputCheckbox from '../../../../UI/FormReload/Input/Checkbox'
import UIFormInputCheckboxSwitch from '../../../../UI/FormReload/Input/Checkbox/Switch'
import UIPanelInfoTable from '../../../../UI/Panel/Info/Table'
import UITable from '../../../../UI/Table'
import UITableBody from '../../../../UI/Table/Body'
import UITableHead from '../../../../UI/Table/Head'
import UIAlert from '../../../../UI/Alert'

// Common
import { oe } from '../../../../../common/object/extractor'

import { AdministrationForm } from '../..'

// Queries
import {
  READ_LIMITS,
  CREATE_LIMITS,
  UPDATE_LIMITS,
  LIST_LIMITS,
  SEARCH_CLIENT,
  SEARCH_PROTOCOL,
} from './queries'

const queries = {
  read: READ_LIMITS,
  create: CREATE_LIMITS,
  update: UPDATE_LIMITS,
  list: LIST_LIMITS,
}

const CustomForm = ({ action, data }) => {
  //
  // data = { essays, rules, type, name, id }
  //  - essays = { [id_essay]: 'name_essay' }
  //  - rules = { [id_essay]: { lic, lim, lsm, lsc, essay } }
  //    - essay = { id, name }
  // - Protocol: id, name and type.name
  //

  // Initial state
  // rules = [{ id, name, active, lic, lim, lsm, lsc, essay }] for active ones (active = 1)
  // rules = [{ id, name, active }] for inactive ones (active = 0)
  const [rules, setRulesState] = useState(
    data
      ? Object.keys(data.essays).map((id) => {
          const name = data.essays[id]

          if (data.rules[id]) return { ...data.rules[id], active: 1 }
          else return { essay: { id, name }, active: 0 }
        })
      : [],
  )

  const [isCreate, setIsCreateState] = useState(true)

  const handleChange = (event, id) => {
    setRulesState(
      rules.map((rule) => {
        if (rule.essay.id === id) rule.active = event.target.checked
        return rule
      }),
    )
  }

  const handleProtocolChange = () => {
    setIsCreateState((prev) => !prev)
  }

  // TODO "name" will be deleted and "id_protocol" will be a select input
  // Also add a comment/information about what "duplicate" means (i.e. replace the limits of the picked protocol).
  // This way I can create a protocol without initial limits, and then I can use another protocol with defined limits to
  // populate the new one
  return (
    <>
      {action === 'create' && !isCreate && (
        <UIAlert noDismissible style="info">
          Al asignar estos límites a un protocolo ya existente se eliminarán{' '}
          <strong>
            <u>todos</u>
          </strong>{' '}
          los límites de éste último y se remplazarán con los aquí especificados
        </UIAlert>
      )}
      <UIPanelInfoTable>
        {[
          action === 'create'
            ? [
                'Acción',
                <UIFormInputCheckboxSwitch
                  on="Crear nuevo protocolo"
                  off="Asignar a protocolo existente"
                  onStyle="success"
                  offStyle="stng"
                  name="action"
                  value={isCreate}
                  onChange={handleProtocolChange}
                />,
              ]
            : [],
          ...(action === 'create' && isCreate
            ? [
                ['Nombre protocolo', <UIFormInputText name="name" />],
                [
                  'Cliente',
                  <UIFormInputSelect
                    name="id_client"
                    query={SEARCH_CLIENT}
                    idAs="value"
                    initialValue="0"
                  >
                    <option value="0">Genérico</option>
                  </UIFormInputSelect>,
                ],
              ]
            : [
                [
                  'Protocolo',
                  <UIFormInputSearch
                    name="id_protocol"
                    query={SEARCH_PROTOCOL}
                    variables={{ type: oe(data, ['type', 'id']) }}
                  />,
                ],
              ]),
          action === 'update'
            ? [
                'Nombre protocolo',
                <UIFormInputText
                  name="name"
                  defaultValue={oe(data, ['name'])}
                  disabled
                />,
              ]
            : [],
          [
            'Tipo protocolo',
            <>
              <UIFormInputText
                name="id_protocol_type"
                value={oe(data, ['type', 'id'])}
                hidden
                disabled
              />
              <Badge variant="stng" style={{ fontSize: '15px' }}>
                {oe(data, ['type', 'name'])}
              </Badge>
            </>,
          ],
        ]}
      </UIPanelInfoTable>
      <Tabs className="mt-4">
        <Tab eventKey="active" title="Activas">
          <UITable bordered className="mt-2">
            <UITableHead>
              {['Ensayo', 'LIC', 'LIM', 'LSM', 'LSC', 'Activo']}
            </UITableHead>
            <UITableBody>
              {rules
                .filter(({ active }) => active)
                .map(({ lic, lim, lsm, lsc, active, essay }) => (
                  <tr key={essay.id}>
                    <th scope="row" style={{ minWidth: '150px' }}>
                      {essay.name}
                    </th>
                    <td>
                      <UIFormInputNumber
                        name={`${essay.id}_lic`}
                        initialValue={lic}
                      />
                    </td>
                    <td>
                      <UIFormInputNumber
                        name={`${essay.id}_lim`}
                        initialValue={lim}
                      />
                    </td>
                    <td>
                      <UIFormInputNumber
                        name={`${essay.id}_lsm`}
                        initialValue={lsm}
                      />
                    </td>
                    <td>
                      <UIFormInputNumber
                        name={`${essay.id}_lsc`}
                        initialValue={lsc}
                      />
                    </td>
                    <td>
                      <UIFormInputCheckbox
                        name={`${essay.id}_active`}
                        onChange={(event) => handleChange(event, essay.id)}
                        initialValue={active}
                      />
                    </td>
                  </tr>
                ))}
            </UITableBody>
          </UITable>
        </Tab>
        <Tab eventKey="inactive" title="Inactivas">
          <UITable bordered className="mt-2">
            <UITableHead>{['Ensayo', 'Activo']}</UITableHead>
            <UITableBody>
              {rules
                .filter(({ active }) => !active)
                .map(({ active, essay }) => (
                  <tr key={essay.id}>
                    <th scope="row">{essay.name}</th>
                    <td>
                      <UIFormInputCheckbox
                        name={`${essay.id}_active`}
                        onChange={(event) => handleChange(event, essay.id)}
                        initialValue={active}
                      />
                    </td>
                  </tr>
                ))}
            </UITableBody>
          </UITable>
        </Tab>
      </Tabs>
    </>
  )
}

const AdministrationFormAnalysisLimits = () => {
  const handleTransformValuesOnSubmit = (values) => {
    const limits = {}
    const active = {}

    const { name, id_client, action, id_protocol, id_protocol_type, ...rest } =
      values

    for (const key in rest) {
      const [id, col] = key.split('_')

      if (col === 'active') {
        active[id] = rest[key]
      } else if (['lic', 'lim', 'lsm', 'lsc'].includes(col)) {
        if (!limits[id]) limits[id] = {}
        limits[id][col] = rest[key]
      }
    }

    return {
      limits,
      active,
      name,
      id_client,
      action: action ? 'create' : 'assign',
      id_protocol,
      id_protocol_type,
    }
  }

  return (
    <AdministrationForm
      name={'límite'}
      queries={queries}
      transformValuesOnSubmit={handleTransformValuesOnSubmit}
    >
      <CustomForm />
    </AdministrationForm>
  )
}

export default AdministrationFormAnalysisLimits
