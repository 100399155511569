import React from 'react'
import ExcelJS from 'exceljs/dist/es5/exceljs.browser'

import essaysMap from './essayMap'

// Components
import UIButton from '../../../UI/Button'

// Hooks
import { useGlobal, useLazyQuery } from '../../../../hooks'

// Queries
import { GET_EXCEL } from './queries'

// ----------------------------------------------------------------------------
//
// Building Excel
//
// ----------------------------------------------------------------------------

const buildExcel = ({ data, filter }) => {
  const [{ me }] = useGlobal()

  const { laboratory } = me

  const { info, results } = data
  const { from, to } = filter

  const workbook = new ExcelJS.Workbook()

  workbook.creator = 'Servicios Tribológicos'

  // ------------------------------------------------------------------------
  // Helpers
  // ------------------------------------------------------------------------

  const border = (positions = {}) => {
    const { x, y, t, r, l, b } = positions

    const style = 'thin'
    const border = {}

    if (x) {
      border.left = { style }
      border.right = { style }
    }
    if (y) {
      border.top = { style }
      border.bottom = { style }
    }
    if (t) border.top = { style }
    if (r) border.right = { style }
    if (b) border.bottom = { style }
    if (l) border.left = { style }

    if (!Object.keys(positions).length) {
      border.top = { style: 'thin' }
      border.left = { style: 'thin' }
      border.bottom = { style: 'thin' }
      border.right = { style: 'thin' }
    }

    return border
  }

  const fill = ({ argb = 'FFF7F2E0' } = {}) => ({
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb },
  })

  results.forEach((result) => {
    // ------------------------------------------------------------------------
    // Excel buiilding
    // ------------------------------------------------------------------------

    const { name, rows } = result

    // Create sheet
    const sheet = workbook.addWorksheet(name.replace(/[:\\\/\?\*\[\]#]/, ''))

    // Insert info header
    let row
    let o = 0 // offset
    const r = 2
    const c = 2

    // sheet.mergeCells(r + 0, c + 1, r + 0, c + 2)
    // sheet.mergeCells(r + 1, c + 1, r + 1, c + 2)
    // sheet.mergeCells(r + 2, c + 1, r + 2, c + 2)
    // sheet.mergeCells(r + 3, c + 1, r + 3, c + 2)
    // sheet.mergeCells(r + 5, c + 4, r + 5, c + 3 + numComponents)

    row = sheet.getRow(r + 0)
    row.getCell(c + 0).value = 'Cliente :'
    row.getCell(c + 0).border = border({ t: true, l: true })
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 0).alignment = { horizontal: 'right' }
    row.getCell(c + 0).font = { bold: true }
    row.getCell(c + 1).value = info.client_name
    row.getCell(c + 1).border = border({ t: true, r: true })
    row.getCell(c + 1).fill = fill()

    if (info.site_name) {
      row = sheet.getRow(r + 1 + o)
      row.getCell(c + 0).value = 'Faena :'
      row.getCell(c + 0).border = border({ l: true })
      row.getCell(c + 0).fill = fill()
      row.getCell(c + 0).alignment = { horizontal: 'right' }
      row.getCell(c + 0).font = { bold: true }
      row.getCell(c + 1).value = info.site_name
      row.getCell(c + 1).border = border({ r: true })
      row.getCell(c + 1).fill = fill()
      o += 1
    }

    if (info.machine_type_name) {
      row = sheet.getRow(r + 1 + o)
      row.getCell(c + 0).value = 'Flota :'
      row.getCell(c + 0).border = border({ l: true })
      row.getCell(c + 0).fill = fill()
      row.getCell(c + 0).alignment = { horizontal: 'right' }
      row.getCell(c + 0).font = { bold: true }
      row.getCell(c + 1).value = info.machine_type_name
      row.getCell(c + 1).border = border({ r: true })
      row.getCell(c + 1).fill = fill()
      o += 1
    }

    if (info.machine_name) {
      row = sheet.getRow(r + 1 + o)
      row.getCell(c + 0).value = 'Equipo :'
      row.getCell(c + 0).border = border({ l: true })
      row.getCell(c + 0).fill = fill()
      row.getCell(c + 0).alignment = { horizontal: 'right' }
      row.getCell(c + 0).font = { bold: true }
      row.getCell(c + 1).value = info.machine_name
      row.getCell(c + 1).border = border({ r: true })
      row.getCell(c + 1).fill = fill()
      o += 1
    }

    row = sheet.getRow(r + 1 + o)
    row.getCell(c + 0).value = 'Dirección :'
    row.getCell(c + 0).border = border({ l: true })
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 0).alignment = { horizontal: 'right' }
    row.getCell(c + 0).font = { bold: true }
    row.getCell(c + 1).value = info.legal_address
    row.getCell(c + 1).border = border({ r: true })
    row.getCell(c + 1).fill = fill()
    row = sheet.getRow(r + 2 + o)
    row.getCell(c + 0).value = 'Periodo :'
    row.getCell(c + 0).border = border({ l: true })
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 0).alignment = { horizontal: 'right' }
    row.getCell(c + 0).font = { bold: true }
    row.getCell(c + 1).value = `${from} ~ ${to}`
    row.getCell(c + 1).border = border({ r: true })
    row.getCell(c + 1).fill = fill()
    row = sheet.getRow(r + 3 + o)
    row.getCell(c + 0).value = 'Encargado :'
    row.getCell(c + 0).border = border({ l: true, b: true })
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 0).alignment = { horizontal: 'right' }
    row.getCell(c + 0).font = { bold: true }
    row.getCell(c + 1).value = info.responsible
    row.getCell(c + 1).border = border({ r: true, b: true })
    row.getCell(c + 1).fill = fill()

    row = sheet.getRow(r + 5 + o)
    row.alignment = { vertical: 'middle', horizontal: 'center' }
    row.getCell(c + 0).value = 'Tipo Equipo'
    row.getCell(c + 0).border = border()
    row.getCell(c + 0).fill = fill()
    row.getCell(c + 1).value = 'Equipo'
    row.getCell(c + 1).border = border()
    row.getCell(c + 1).fill = fill()
    row.getCell(c + 2).value = 'Desc Componente'
    row.getCell(c + 2).border = border()
    row.getCell(c + 2).fill = fill()
    row.getCell(c + 3).value = 'Tag'
    row.getCell(c + 3).border = border()
    row.getCell(c + 3).fill = fill()
    row.getCell(c + 4).value = 'N'
    row.getCell(c + 4).border = border()
    row.getCell(c + 4).fill = fill()
    row.getCell(c + 5).value = 'HK Equipo'
    row.getCell(c + 5).border = border()
    row.getCell(c + 5).fill = fill()
    row.getCell(c + 6).value = 'HK Componente'
    row.getCell(c + 6).border = border()
    row.getCell(c + 6).fill = fill()
    row.getCell(c + 7).value = 'HK Lubricante'
    row.getCell(c + 7).border = border()
    row.getCell(c + 7).fill = fill()
    row.getCell(c + 8).value = 'Fecha'
    row.getCell(c + 8).border = border()
    row.getCell(c + 8).fill = fill()

    // Essays
    essaysMap[laboratory].forEach(({ name }, index) => {
      row.getCell(c + 9 + index).value = name
      row.getCell(c + 9 + index).border = border()
      row.getCell(c + 9 + index).fill = fill()
    })

    // Insert results
    rows.forEach(
      (
        {
          sample_id,
          machine_continuity,
          component_continuity,
          lubricant_continuity,
          date_sampling,
          component_name,
          component_tag,
          machine_name,
          machine_type_name,
          essays,
        },
        index,
      ) => {
        row = sheet.getRow(r + 6 + o + index)
        row.alignment = { vertical: 'middle', horizontal: 'center' }
        row.getCell(c + 0).value = machine_type_name
        row.getCell(c + 0).border = border()
        row.getCell(c + 1).value = machine_name
        row.getCell(c + 1).border = border()
        row.getCell(c + 2).value = component_name
        row.getCell(c + 2).border = border()
        row.getCell(c + 3).value = component_tag
        row.getCell(c + 3).border = border()
        row.getCell(c + 4).value = sample_id
        row.getCell(c + 4).border = border()
        row.getCell(c + 5).value = machine_continuity
        row.getCell(c + 5).border = border()
        row.getCell(c + 6).value = component_continuity
        row.getCell(c + 6).border = border()
        row.getCell(c + 7).value = lubricant_continuity
        row.getCell(c + 7).border = border()
        row.getCell(c + 8).value = date_sampling
        row.getCell(c + 8).border = border()

        essaysMap[laboratory].forEach(({ id }, index) => {
          // Special case since Centinela has 2 PQ
          // if (id === 29) id = essays[62] ? 62 : id

          if (essays[id]) {
            let { value, lsm, lsc, lim, lic } = essays[id]

            row.getCell(c + 9 + index).value = isNaN(value)
              ? value
              : parseFloat(value).toFixed(1)

            // NOTE only if isNaN(value)
            //
            // value = parseFloat(value)

            // const hasLsc = lsc !== '' && value > lsc
            // const hasLsm = lsm !== '' && value > lsm
            // const hasLim = lim !== '' && value < lim
            // const hasLic = lic !== '' && value < lic

            // if (hasLsc || hasLic) {
            //   row.getCell(c + 9 + index).fill = fill({ argb: 'FFEE0000' })
            //   row.getCell(c + 9 + index).font = {
            //     color: { argb: 'FFEEEEEE' },
            //   }
            // } else if (hasLsm || hasLim) {
            //   row.getCell(c + 9 + index).fill = fill({ argb: 'FFEEEE00' })
            // }
          }
          row.getCell(c + 9 + index).border = border()
        })
      },
    )

    // Make the cells width fit automátically
    sheet.columns.forEach((column) => {
      var dataMax = 0
      column.eachCell({ includeEmpty: true }, (cell) => {
        var columnLength = cell && cell.value ? cell.value.length + 5 : 0
        if (columnLength > dataMax) {
          dataMax = columnLength
        }
      })
      column.width = dataMax < 10 ? 10 : dataMax
    })

    // const myBase64Image = 'data:image/png;base64,iVBORw0KG...'
    // const imageId2 = workbook.addImage({
    //   base64: myBase64Image,
    //   extension: 'png',
    // })
  })

  // Download excel file!
  workbook.xlsx.writeBuffer().then(function (data) {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const url = window.URL.createObjectURL(blob)
    const anchor = document.createElement('a')
    anchor.href = url
    anchor.download = 'Reporte - Datos.xlsx'
    anchor.click()
    // anchor.dispatchEvent(new MouseEvent('click')) // NOTE https://github.com/exceljs/exceljs/issues/354
    window.URL.revokeObjectURL(url)
  })
}

// ----------------------------------------------------------------------------
//
// Main render
//
// ----------------------------------------------------------------------------

const ButtonDownload = ({ filter }) => {
  const [getExcel, { loading, data }] = useLazyQuery(GET_EXCEL, {
    variables: filter,
  })

  if (data && data.reportDataExcel && data.reportDataExcel.data) {
    buildExcel({
      data: data.reportDataExcel.data,
      filter,
    })
  }

  const handleClick = () => {
    getExcel()
  }

  return (
    <UIButton onClick={handleClick} disabled={loading}>
      <img src="/excel.png" height="20" alt="Excel" />
    </UIButton>
  )
}

export default ButtonDownload
