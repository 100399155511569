import { gql } from '../../../../../hooks'

export const READ_ESSAY_CLASSIFICATIONS = gql`
  query AdministrationReadSamplingEssayClassifications($id: ID!) {
    aRead(path: "sampling/essay/classifications", id: $id) {
    }
  }
`

export const UPDATE_ESSAY_CLASSIFICATIONS = gql`
  mutation AdministrationUpdateSamplingEssayClassifications(
    $id: ID!
    $data: JSONObject
  ) {
    aUpdate(path: "sampling/essay/classifications", id: $id, data: $data) {
    }
  }
`

export const CREATE_ESSAY_CLASSIFICATIONS = gql`
  mutation AdministrationCreateSamplingEssayClassifications($data: JSONObject) {
    aCreate(path: "sampling/essay/classifications", data: $data) {
    }
  }
`

export const LIST_ESSAY_CLASSIFICATIONS = gql`
  query AdministrationListSamplingEssayClassifications(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(
      path: "sampling/essay/classifications"
      filter: $filter
      page: $page
      limit: $limit
    ) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`
