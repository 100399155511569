import React from 'react'

// Hooks
import { useGlobal } from '../../../../../../hooks'

// Components
import UIPanelInfoBlock from '../../../../../UI/Panel/Info/Block'
import UIPanelInfoTable from '../../../../../UI/Panel/Info/Table'
import UIFormInputText from '../../../../../UI/FormReload/Input/Text'
import UIFormInputInteger from '../../../../../UI/FormReload/Input/Integer'

// Common
import oe from '../../../../../../common/object/extractor'

import AdministrationInfo, { RefLink } from '../../..'

// Queries
import { READ_SITES_CASES, DELETE_SITES_CASES } from './queries'

const Info = (data) => (
  <>
    <UIPanelInfoBlock title="Faena-Caso">
      <UIPanelInfoTable>
        {[
          [
            'Cliente',
            <RefLink
              id={oe(data, ['client', 'id'])}
              tab={'client'}
              subtab={'clients'}
            >
              {oe(data, ['client', 'name'])}
            </RefLink>,
          ],
          [
            'Faena',
            <RefLink
              id={oe(data, ['site', 'id'])}
              tab={'client'}
              subtab={'sites'}
            >
              {oe(data, ['site', 'name'])}
            </RefLink>,
          ],
          [
            'Tipo de Equipo',
            oe(data, ['machine', 'type', 'name']) ? (
              <RefLink
                id={oe(data, ['machine', 'type', 'id'])}
                tab={'machine'}
                subtab={'types'}
              >
                {oe(data, ['machine', 'type', 'name'])}
              </RefLink>
            ) : (
              '--'
            ),
          ],
          ['Caso', oe(data, ['case', 'name'])],
          ['Correos', oe(data, ['case', 'emails'])],
        ]}
      </UIPanelInfoTable>
    </UIPanelInfoBlock>
  </>
)

const Form = () => (
  <>
    <UIFormInputInteger label="ID Faena" name="site_id" />
    <UIFormInputText label="Nombre Faena" name="site_name" />
    <UIFormInputInteger label="ID Cliente" name="client_id" />
    <UIFormInputText label="Nombre Cliente" name="client_name" />
    <UIFormInputInteger label="ID Caso" name="case_id" />
    <UIFormInputText label="Nombre Caso" name="case_name" />
  </>
)

const AdministrationInfoClientSitesCases = ({ ...rest }) => {
  const [{ me }] = useGlobal()

  const no = ![1, 2].includes(me.id_role)

  return (
    <AdministrationInfo
      queries={{ read: READ_SITES_CASES, delete: DELETE_SITES_CASES }}
      views={{ form: Form, info: Info }}
      {...rest}
      noDuplicate={no}
      noUpdate={no}
      noDelete={no}
    />
  )
}

export default AdministrationInfoClientSitesCases
