import React from 'react'

/*
 * mask={['id', 'client', ...]}
 */
const UITableBody = ({
  mask = [],
  children,
  fontSize = 'small',
  scopeRow = false,
}) => {
  //const style = {
  // fontSize,
  //}

  //  const classes = []

  return (
    <tbody>
      {mask.length === 0 ? (
        <>{children}</>
      ) : !Array.isArray(children) ? (
        <>{children}</>
      ) : (
        <>
          {children.map((row, rowIndex) => (
            <tr key={`row-${rowIndex}`}>
              {mask.map((key, keyIndex) => {
                if (scopeRow && keyIndex === 0)
                  return (
                    <th key={`element-${rowIndex}-${keyIndex}`} scope="row">
                      {row[key]}
                    </th>
                  )
                return (
                  <td key={`element-${rowIndex}-${keyIndex}`}>{row[key]}</td>
                )
              })}
            </tr>
          ))}
        </>
      )}
    </tbody>
  )
}

export default UITableBody
