import React from 'react'
import { Col, Row, Form } from 'react-bootstrap'

import UIForm from '../../../../UI/FormReload'
import UIFormInputInteger from '../../../../UI/FormReload/Input/Integer'
import UIFormInputDate from '../../../../UI/FormReload/Input/Date'
import UIFormInputSelect from '../../../../UI/FormReload/Input/Select'
import UIFormSeparation from '../../../../UI/FormReload/Separation'
import UIButton from '../../../../UI/Button'

// Hooks
import {
  useState,
  useGlobal,
  useEffect,
  useCallback,
} from '../../../../../hooks'

// Queries
import { LIST_CLIENTS, LIST_SITES, LIST_STAGES } from './queries'

const TribologySamplesSearchForm = ({ onFilterChange }) => {
  const [{ me }] = useGlobal()

  const [idClient, setIdClient] = useState('')
  const [idSite, setIdSite] = useState('')

  // --------------------------------------------------------------------------
  //
  // Render
  //
  // --------------------------------------------------------------------------

  const handleClientChange = (event) => {
    setIdClient(event.target.value)
    setIdSite('')
  }

  const handleSiteChange = (event) => {
    setIdSite(event.target.value)
  }

  useEffect(() => {
    setIdClient('')
    setIdSite('')
  }, [me.id_laboratory])

  const handleSubmit = (values) => {
    if (values.id_client === '') delete values.id_site

    const filter = Object.keys(values).reduce(
      (p, key) =>
        [null, undefined, ''].includes(values[key])
          ? p
          : { ...p, [key]: values[key] },
      {},
    )

    onFilterChange(filter)
  }

  const validator = useCallback((value) => {
    if (
      ![null, undefined, ''].includes(value) &&
      !/^\s*[0-9]+((\s*,?\s*[0-9]+)+|-[0-9]+)?\s*$/.test(value)
    )
      return 'Sólo números enteros, comas (,), espacios, tabs y guiones (-)'
  }, [])

  return (
    <UIForm onSubmit={handleSubmit}>
      {/* Upper buttons */}
      <Form.Group as={Row} className="my-4">
        <Col className="d-flex">
          <UIButton type="submit">Filtrar lista</UIButton>
        </Col>
      </Form.Group>
      <UIFormSeparation />
      <UIFormInputInteger label="Correlativo" name="id" validator={validator} />
      <UIFormSeparation />
      <UIFormInputInteger
        label="Correlativo Externo"
        name="external_number"
        validator={validator}
      />
      <UIFormSeparation />
      <UIFormInputSelect label="Estado" name="id_stage" query={LIST_STAGES}>
        <option value="">Seleccione un estado</option>
      </UIFormInputSelect>
      <UIFormInputSelect
        label="Cliente"
        name="id_client"
        query={LIST_CLIENTS}
        variables={{ id: me.id_laboratory }}
        value={idClient}
        onChange={handleClientChange}
      >
        <option value="">Seleccione un cliente</option>
      </UIFormInputSelect>
      <UIFormInputSelect
        label="Faena"
        name="id_site"
        query={LIST_SITES}
        variables={{ id: idClient }}
        disabled={!idClient}
        value={idSite}
        onChange={handleSiteChange}
      >
        <option value="">Seleccione una faena</option>
      </UIFormInputSelect>
      <UIFormInputDate label="Fecha" name="date" />
      <br />
    </UIForm>
  )
}

export default TribologySamplesSearchForm
