import React, { createContext } from 'react'
import { Col, Row, ButtonGroup } from 'react-bootstrap'

// Components
import UIButton from '../../UI/Button'

// Hooks
import {
  useState,
  useContext,
  useHistory,
  useParams,
  useMemo,
  useTrigger,
  useLocation,
} from '../../../hooks'

export const AdministrationContainerTab = ({ subtabs }) => {
  const params = useParams()

  const history = useHistory()

  const activeSubtab = useMemo(() => {
    if (
      !params.subtab ||
      !subtabs.map(({ subtab }) => subtab).includes(params.subtab)
    ) {
      history.replace({
        pathname: `/administration/${params.tab}/${subtabs[0].subtab}`,
      })

      return subtabs[0].subtab
    }

    return params.subtab
  }, [params.subtab])

  const Content = useMemo(
    () => subtabs.filter(({ subtab }) => subtab === activeSubtab)[0].component,
    [activeSubtab],
  )

  const handleClick = (subtab) => {
    history.push(`/administration/${params.tab}/${subtab}`)
  }

  return (
    <>
      <div className="d-flex flex-column">
        <ButtonGroup className="mt-3">
          {subtabs.map(({ name, subtab }) => (
            <UIButton
              onClick={() => handleClick(subtab)}
              className={subtab === activeSubtab ? 'active' : null}
              key={`subtab-button-${subtab}`}
            >
              {name}
            </UIButton>
          ))}
        </ButtonGroup>
      </div>
      <Content />
    </>
  )
}

export const AdministrationContainerContext = createContext()

export const AdministrationContainer = ({
  form: Form,
  info: Info,
  list: List,
}) => {
  const { search } = useLocation()

  const searchQuery = useMemo(() => new URLSearchParams(search), [search])

  const [action, setActionState] = useState(
    ['view', 'update', 'create'].includes(searchQuery.get('action'))
      ? searchQuery.get('action')
      : 'view',
  )

  const [data, setDataState] = useState(
    searchQuery.get('id') ? { id: searchQuery.get('id') } : {},
  )
  const [filter, setFilterState] = useState()
  const [variant, setVariantState] = useState()
  const [noise, forceUpdate] = useTrigger()
  const [triggerReset, setTriggerReset] = useTrigger()
  const [triggerSubmit, setTriggerSubmit] = useTrigger()
  const [triggerRefetch, setTriggerRefetch] = useTrigger()

  return (
    <AdministrationContainerContext.Provider
      value={{
        action,
        data,
        filter,
        variant,
        setActionState,
        setDataState,
        setFilterState,
        setVariantState,
        forceUpdate,
        noise,
        triggerReset,
        triggerSubmit,
        triggerRefetch,
        setTriggerReset,
        setTriggerSubmit,
        setTriggerRefetch,
      }}
    >
      <Row className="m-4">
        <Col lg={7} className="mb-3">
          <List />
        </Col>
        <Col lg={5}>
          {['create', 'update'].includes(action) ? <Form /> : <Info />}
        </Col>
      </Row>
    </AdministrationContainerContext.Provider>
  )
}
