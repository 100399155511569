import React from 'react'

// Components
import TribologySamplesShiftChange from '../../../../components/Tribology/Samples/ShiftChange'

const PageTribologySamplesShiftChange = () => {
  return <TribologySamplesShiftChange />
}

export default PageTribologySamplesShiftChange
