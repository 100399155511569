import { gql } from '../../../../../hooks'

export const DELETE_MACHINES = gql`
  mutation AdministrationDeleteMachineMachines($id: ID!) {
    aDelete(path: "machine/machines", id: $id) {
    }
  }
`

export const LIST_MACHINES = gql`
  query AdministrationListMachineMachines(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(
      path: "machine/machines"
      filter: $filter
      page: $page
      limit: $limit
    ) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`
