import React from 'react'

const UITableHead = ({ children, style, className = '' }) => {
  style = {
    fontSize: 'small',
    ...style,
  }

  const classes = [`py-2`, 'font-weight-normal', className]

  return (
    <thead style={style}>
      {Array.isArray(children) ? (
        <tr>
          {children.map((element, index) => (
            <th key={index} className={classes.join(' ')}>
              {element}
            </th>
          ))}
        </tr>
      ) : (
        <>{children}</>
      )}
    </thead>
  )
}

export default UITableHead
