import React from 'react'

// Components
import UIFormInputText from '../../../../UI/FormReload/Input/Text'
import UIFormInputNumber from '../../../../UI/FormReload/Input/Number'
import UIFormInputSearch from '../../../../UI/FormReload/Input/Search'
import UIFormInputSelect from '../../../../UI/FormReload/Input/Select'
import UIFormInputCheckbox from '../../../../UI/FormReload/Input/Checkbox'
import UIPanelInfoTable from '../../../../UI/Panel/Info/Table'

// Common
import { oe } from '../../../../../common/object/extractor'

// Hooks
import { useState } from '../../../../../hooks'

import { AdministrationForm } from '../..'

// Queries
import {
  READ_COMPONENTS,
  CREATE_COMPONENTS,
  UPDATE_COMPONENTS,
  LIST_COMPONENTS,
  SEARCH_BRAND,
  SEARCH_MODEL,
  SEARCH_COMPONENT_TYPE,
  SEARCH_SITE,
  SEARCH_MACHINE_TYPE,
  SEARCH_MACHINE,
  SEARCH_LUBRICANT,
  SEARCH_PROTOCOL,
  SEARCH_SAMPLING_POINT,
  SEARCH_SAMPLING_PROCEDURE,
  SEARCH_COLLECTION,
} from './queries'

const queries = {
  read: READ_COMPONENTS,
  create: CREATE_COMPONENTS,
  update: UPDATE_COMPONENTS,
  list: LIST_COMPONENTS,
}

const CustomForm = ({ action, data, id }) => {
  const [idBrand, setIdBrand] = useState()
  const [idSite, setIdSite] = useState()
  const [idMachineType, setIdMachineType] = useState()
  const [isUnsampledActive, setIsUnsampledActive] = useState(
    oe(data, ['unsampled', 'active']),
  )

  const handleBrandChange = (event) => {
    setIdBrand(parseInt(event.target.value))
  }

  const handleSiteClick = (id) => {
    setIdSite(id)
  }

  const handleMachineTypeClick = (id) => {
    setIdMachineType(id)
  }

  const handleUnsampledActive = () => {
    setIsUnsampledActive((prev) => !prev)
  }

  return (
    <UIPanelInfoTable>
      {[
        action === 'update'
          ? [
              'ID',
              <UIFormInputText
                name="id"
                initialValue={id}
                placeholder={id}
                disabled
              />,
            ]
          : [],
        [
          'TAG',
          <UIFormInputText
            name="tag"
            initialValue={oe(data, ['component', 'tag'])}
            placeholder={oe(data, ['component', 'tag'])}
            required
          />,
        ],
        [
          'Descripción',
          <UIFormInputText
            name="name"
            initialValue={oe(data, ['component', 'name'])}
            placeholder={oe(data, ['component', 'name'])}
            required
          />,
        ],
        [
          'Número de serie',
          <UIFormInputText
            name="serial_number"
            initialValue={oe(data, ['component', 'serial_number'])}
            placeholder={oe(data, ['component', 'serial_number'])}
          />,
        ],
        [
          'Cantidad de litros',
          <UIFormInputNumber
            name="liters"
            initialValue={oe(data, ['component', 'liters'])}
            placeholder={oe(data, ['component', 'liters'])}
          />,
        ],
        [
          'Marca',
          <UIFormInputSelect
            name="id_component_brand"
            query={SEARCH_BRAND}
            idAs="value"
            initialValue={oe(data, ['component', 'brand', 'id'])}
            onChange={handleBrandChange}
            required
          >
            <option value={0}>Seleccione una opción</option>
          </UIFormInputSelect>,
        ],
        [
          'Modelo',
          <UIFormInputSelect
            name="id_component_model"
            query={SEARCH_MODEL}
            variables={{ id_brand: idBrand }}
            idAs="value"
            initialValue={oe(data, ['component', 'model', 'id'])}
            required
          >
            <option value={0}>Seleccione una opción</option>
          </UIFormInputSelect>,
        ],
        [
          'Tipo componente',
          <UIFormInputSearch
            name="id_component_type"
            query={SEARCH_COMPONENT_TYPE}
            initialValue={{
              value: oe(data, ['component', 'type', 'id']),
              name: oe(data, ['component', 'type', 'name']),
            }}
            required
          />,
        ],
        [
          'Faena',
          <UIFormInputSearch
            name="id_site"
            query={SEARCH_SITE}
            onClick={handleSiteClick}
            initialValue={{
              value: oe(data, ['site', 'id']),
              name: oe(data, ['site', 'name']),
            }}
          />,
        ],
        [
          'Tipo equipo',
          <UIFormInputSearch
            name="id_machine_type"
            query={SEARCH_MACHINE_TYPE}
            variables={{ id_site: idSite }}
            initialValue={{
              value: oe(data, ['machine_type', 'id']),
              name: oe(data, ['machine_type', 'name']),
            }}
            onClick={handleMachineTypeClick}
          />,
        ],
        [
          'Equipo',
          <UIFormInputSearch
            name="id_machine"
            query={SEARCH_MACHINE}
            variables={{ id_machine_type: idMachineType, id_site: idSite }}
            initialValue={{
              value: oe(data, ['machine', 'id']),
              name: oe(data, ['machine', 'name']),
            }}
            required
          />,
        ],
        [
          'Lubricante',
          <UIFormInputSearch
            name="id_lubricant"
            query={SEARCH_LUBRICANT}
            initialValue={{
              value: oe(data, ['lubricant', 'id']),
              name: oe(data, ['lubricant', 'name']),
            }}
            required
          />,
        ],
        [
          'Protocolo',
          <UIFormInputSearch
            name="id_protocol"
            query={SEARCH_PROTOCOL}
            initialValue={{
              value: oe(data, ['component', 'protocol', 'id']),
              name: oe(data, ['component', 'protocol', 'name']),
            }}
            required
          />,
        ],
        [
          'Punto muestreo',
          <UIFormInputSelect
            name="id_sampling_point"
            query={SEARCH_SAMPLING_POINT}
            idAs="value"
            initialValue={oe(data, ['component', 'sampling_point', 'id'])}
            required
          >
            <option value={0}>Seleccione una opción</option>
          </UIFormInputSelect>,
        ],
        [
          'Procedimiento muestreo',
          <UIFormInputSelect
            name="id_sampling_procedure"
            query={SEARCH_SAMPLING_PROCEDURE}
            idAs="value"
            initialValue={oe(data, ['component', 'sampling_procedure', 'id'])}
            required
          >
            <option value={0}>Seleccione una opción</option>
          </UIFormInputSelect>,
        ],
        [
          'Cobranza',
          <UIFormInputSearch
            name="id_collection"
            query={SEARCH_COLLECTION}
            initialValue={{
              value: oe(data, ['component', 'collection', 'id']),
              name: oe(data, ['component', 'collection', 'name']),
            }}
            required
          />,
        ],
        [
          'Activar frecuencia muestreo',
          <UIFormInputCheckbox
            name="unsampled_active"
            initialValue={oe(data, ['unsampled', 'active'])}
            onClick={handleUnsampledActive}
          />,
        ],
        [
          'Frecuencia muestreo',
          <UIFormInputNumber
            name="unsampled_interval"
            initialValue={oe(data, ['unsampled', 'interval'])}
            placeholder={oe(data, ['unsampled', 'interval'])}
            disabled={!isUnsampledActive}
            required={isUnsampledActive}
          />,
        ],
        [
          'Unidad frecuencia muestreo',
          <UIFormInputSelect
            name="id_unsampled_interval_type"
            initialValue={oe(data, ['unsampled', 'type', 'id'])}
            disabled={!isUnsampledActive}
            required={isUnsampledActive}
          >
            <option value={0}>Seleccione una opción</option>
            <option value={1}>Días</option>
            <option value={2}>Semanas</option>
            <option value={3}>Meses</option>
            <option value={4}>Años</option>
          </UIFormInputSelect>,
        ],
      ]}
    </UIPanelInfoTable>
  )
}

const AdministrationFormComponentComponents = () => (
  <AdministrationForm name={'componente'} queries={queries}>
    <CustomForm />
  </AdministrationForm>
)

export default AdministrationFormComponentComponents
