import React from 'react'

import { AdministrationForm } from '../..'

// Queries
import {
  READ_ANALYSIS_METHODS,
  CREATE_ANALYSIS_METHODS,
  UPDATE_ANALYSIS_METHODS,
  LIST_ANALYSIS_METHODS,
} from './queries'

const queries = {
  read: READ_ANALYSIS_METHODS,
  create: CREATE_ANALYSIS_METHODS,
  update: UPDATE_ANALYSIS_METHODS,
  list: LIST_ANALYSIS_METHODS,
}

const AdministrationFormSamplingAnalysisMethods = () => (
  <AdministrationForm name={' método de análisis'} queries={queries} />
)

export default AdministrationFormSamplingAnalysisMethods
