import { gql } from '../../../../../hooks'

export const READ_LUBRICANTS = gql`
  query AdministrationReadLubricantLubricants($id: ID!) {
    aRead(path: "lubricant/lubricants", id: $id) {
    }
  }
`

export const UPDATE_LUBRICANTS = gql`
  mutation AdministrationUpdateLubricantLubricants(
    $id: ID!
    $data: JSONObject
  ) {
    aUpdate(path: "lubricant/lubricants", id: $id, data: $data) {
    }
  }
`

export const CREATE_LUBRICANTS = gql`
  mutation AdministrationCreateLubricantLubricants($data: JSONObject) {
    aCreate(path: "lubricant/lubricants", data: $data) {
    }
  }
`

export const LIST_LUBRICANTS = gql`
  query AdministrationListLubricantLubricants(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(
      path: "lubricant/lubricants"
      filter: $filter
      page: $page
      limit: $limit
    ) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`

export const LIST_PROTOCOLS = gql`
  query AdministrationMiscProtocols {
    aProtocols {
    }
  }
`

export const LIST_BRANDS = gql`
  query AdministrationMiscLubricantBrands {
    aLubricantBrands {
    }
  }
`

export const LIST_MODELS_BY_BRAND = gql`
  query AdministrationMiscLubricantModelsByBrand($id: ID!) {
    aLubricantModelsByBrand(id: $id) {
    }
  }
`

export const SEARCH_PROTOCOL = gql`
  query AdministrationSearchProtocol($text: String) {
    aSearch(path: "protocol", filter: { type: 1 }, text: $text) {
    }
  }
`
