import { gql } from '../../../../../hooks'

export const READ_MACHINES = gql`
  query AdministrationReadMachineMachines($id: ID!) {
    aRead(path: "machine/machines", id: $id) {
    }
  }
`

export const DELETE_MACHINES = gql`
  mutation AdministrationDeleteMachineMachines($id: ID!) {
    aDelete(path: "machine/machines", id: $id) {
    }
  }
`
