import { gql } from '../../../../../hooks'

export const READ_CHANGE_REFILL_MOTIVES = gql`
  query AdministrationReadLubricantChangeRefillMotives($id: ID!) {
    aRead(path: "lubricant/change/refill/motives", id: $id) {
    }
  }
`

export const DELETE_CHANGE_REFILL_MOTIVES = gql`
  mutation AdministrationDeleteLubricantChangeRefillMotives($id: ID!) {
    aDelete(path: "lubricant/change/refill/motives", id: $id) {
    }
  }
`
