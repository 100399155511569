import { gql } from '../../../../../../../hooks/useQuery'

export const LIST_CLIENTS = gql`
  query ListClients($id: ID!) {
    t2ClientsByLaboratory(id: $id) {
    }
  }
`

export const GET_SAMPLE_MASSIVE_EXCEL_INFO = gql`
  query GetSampleMassiveExcelInfo($data: JSONObject!) {
    t2SampleMassiveExcelInfo(data: $data) {
    }
  }
`

export const ENTER_SAMPLES_MASSIVE_EXCEL = gql`
  mutation EnterSampleMassiveExcel($samples: [JSONObject]!) {
    t2EnterSampleMassiveExcel(samples: $samples) {
    }
  }
`

export const GET_MASSIVE_SAMPLES = gql`
  query GetMassiveSamples {
    t2MassiveSamples {
    }
  }
`
