import React from 'react'

import { AdministrationForm } from '../..'
import UIFormInputSelect from '../../../../UI/FormReload/Input/Select'
import UIFormInputText from '../../../../UI/FormReload/Input/Text'
import UIFormInputNumber from '../../../../UI/FormReload/Input/Number'
import UIPanelInfoTable from '../../../../UI/Panel/Info/Table'

// Common
import { oe } from '../../../../../common/object/extractor'

// Queries
import {
  READ_COLLECTIONS,
  CREATE_COLLECTIONS,
  UPDATE_COLLECTIONS,
  LIST_COLLECTIONS,
} from './queries'

const queries = {
  read: READ_COLLECTIONS,
  create: CREATE_COLLECTIONS,
  update: UPDATE_COLLECTIONS,
  list: LIST_COLLECTIONS,
}

const CustomForm = ({ action, data, id }) => {
  return (
    <UIPanelInfoTable>
      {[
        action === 'update'
          ? [
              'ID',
              <UIFormInputText
                name="id"
                initialValue={id}
                placeholder={id}
                disabled
              />,
            ]
          : [],
        [
          'Cobranza',
          <UIFormInputText
            name="name"
            initialValue={oe(data, ['name'])}
            placeholder={oe(data, ['name'])}
            required
          />,
        ],
        [
          'Valor',
          <UIFormInputNumber
            name="value"
            initialValue={oe(data, ['value'])}
            placeholder={oe(data, ['value'])}
            required
          />,
        ],
        [
          'Marca',
          <UIFormInputSelect
            inline
            name="unit"
            initialValue={oe(data, ['unit'])}
            required
          >
            <option value="Pesos">Pesos</option>
            <option value="UF">UF</option>
          </UIFormInputSelect>,
        ],
      ]}
    </UIPanelInfoTable>
  )
}

const AdministrationFormComponentCollections = () => (
  <AdministrationForm name={'cobranza'} queries={queries}>
    <CustomForm />
  </AdministrationForm>
)

export default AdministrationFormComponentCollections
