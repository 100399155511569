import gql from 'graphql-tag'

export const GET_REPORT = gql`
  query GetReportUnSampled(
    $id_client: ID
    $id_site: ID
    $id_machine_type: ID
    $id_machine: ID
  ) {
    reportUnSampled(
      id_client: $id_client
      id_site: $id_site
      id_machine_type: $id_machine_type
      id_machine: $id_machine
    )
  }
`

export const GET_EXCEL = gql`
  query GetReportUnSampledExcel(
    $id_client: ID
    $id_site: ID
    $id_machine_type: ID
    $id_machine: ID
  ) {
    reportUnSampledExcel(
      id_client: $id_client
      id_site: $id_site
      id_machine_type: $id_machine_type
      id_machine: $id_machine
    )
  }
`
