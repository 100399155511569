/*
 * case 'changeTheme':
 *  return { ...state, theme: action.newTheme }
 */

const reducer = (state, action) => {
  switch (action.type) {
    // Alerts
    // ------------------------------------------------------------------------
    case 'addAlert':
      return {
        ...state,
        alerts: [...state.alerts, action.newAlert],
      }

    case 'removeAlert':
      const alerts = [...state.alerts]
      alerts.splice(action.index, 1)
      return {
        ...state,
        alerts,
      }

    // Logging
    // ------------------------------------------------------------------------
    case 'logIn':
      return { ...state, isLoggedIn: true }

    case 'logOut':
      return { ...state, isLoggedIn: false }

    // Breadcrumb
    // ------------------------------------------------------------------------
    case 'setBreadCrumbs':
      return { ...state, breadCrumbs: action.breadCrumbs }

    // Menu
    // ------------------------------------------------------------------------
    case 'toggleSideMenu':
      return { ...state, isSideMenuShown: !state.isSideMenuShown }

    // Me
    // ------------------------------------------------------------------------
    case 'updateLaboratory':
      return {
        ...state,
        me: {
          ...state.me,
          id_laboratory: action.id,
          laboratory: action.value,
          id_client: 1,
        },
      }

    case 'updateClient':
      return { ...state, me: { ...state.me, id_client: action.id } }

    case 'updateMe':
      //return { ...state, me: { ...state.me, ...action.me } }
      return { ...state, me: action.me }

    // Style
    // ------------------------------------------------------------------------
    case 'updateStyle':
      //return { ...state, me: { ...state.me, ...action.me } }
      return { ...state, style: action.style }

    default:
      return state
  }
}

export default reducer
