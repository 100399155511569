import React from 'react'

// Components
import AdministrationListClientSites from '../../../../Administration/List/Client/Sites'
import AdministrationInfoClientSites from '../../../../Administration/Info/Client/Sites'
import AdministrationFormClientSites from '../../../../Administration/Form/Client/Sites'

import { AdministrationContainer } from '../..'

const AdministrationContainerClientSites = () => (
  <AdministrationContainer
    form={AdministrationFormClientSites}
    info={AdministrationInfoClientSites}
    list={AdministrationListClientSites}
  />
)

export default AdministrationContainerClientSites
