import React from 'react'

// Components
import AdministrationListAnalysisStates from '../../../../Administration/List/Analysis/States'
import AdministrationInfoAnalysisStates from '../../../../Administration/Info/Analysis/States'
import AdministrationFormAnalysisStates from '../../../../Administration/Form/Analysis/States'

import { AdministrationContainer } from '../..'

const AdministrationContainerAnalysisStates = () => (
  <AdministrationContainer
    form={AdministrationFormAnalysisStates}
    info={AdministrationInfoAnalysisStates}
    list={AdministrationListAnalysisStates}
  />
)

export default AdministrationContainerAnalysisStates
