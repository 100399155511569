import React from 'react'

// Components
import UIFormInputText from '../../../../UI/FormReload/Input/Text'
import UIFormInputSelect from '../../../../UI/FormReload/Input/Select'
import UIPanelInfoTable from '../../../../UI/Panel/Info/Table'
import UIPanelInfoAlert from '../../../../UI/Alert'

// Common
import { oe } from '../../../../../common/object/extractor'

import { AdministrationForm } from '../..'

// Queries
import {
  READ_PROTOCOLS,
  CREATE_PROTOCOLS,
  UPDATE_PROTOCOLS,
  LIST_PROTOCOLS,
  SEARCH_CLIENT,
} from './queries'

const queries = {
  read: READ_PROTOCOLS,
  create: CREATE_PROTOCOLS,
  update: UPDATE_PROTOCOLS,
  list: LIST_PROTOCOLS,
}

const CustomForm = ({ action, data, id }) => (
  <>
    {action === 'create' && id && (
      <UIPanelInfoAlert noDismissible>
        <strong>Importante:</strong> Al duplicar un protocolo se están
        duplicando indirectamente sus límites también
      </UIPanelInfoAlert>
    )}
    <UIPanelInfoTable>
      {[
        action === 'create'
          ? [
              'ID Referencia',
              <UIFormInputText
                name="id_ref"
                initialValue={id}
                placeholder={id}
                disabled
              />,
            ]
          : [],

        action === 'update'
          ? [
              'ID',
              <UIFormInputText
                name="id"
                initialValue={id}
                placeholder={id}
                disabled
              />,
            ]
          : [],

        [
          'Cliente',
          <UIFormInputSelect
            name="id_client"
            query={SEARCH_CLIENT}
            idAs="value"
            initialValue={oe(data, ['client', 'id'], 0) || 0}
          >
            <option value={0}>Genérico</option>
          </UIFormInputSelect>,
        ],
        [
          'Descripción',
          <UIFormInputText
            name="name"
            initialValue={oe(data, ['name'])}
            placeholder={oe(data, ['name'])}
          />,
        ],
      ]}
    </UIPanelInfoTable>
  </>
)

const AdministrationFormComponentProtocols = () => (
  <AdministrationForm name={'protocolo (Metal)'} queries={queries}>
    <CustomForm />
  </AdministrationForm>
)

export default AdministrationFormComponentProtocols
