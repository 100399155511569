import React, { forwardRef } from 'react'
import { InputGroup, ListGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import {
  useRef,
  useEffect,
  useMemo,
  useCallback,
  useState,
  useQuery,
  useTrigger,
} from '../../../../../hooks'

import Input from '..'
import { useFormContext } from '../..'

const checkObject = (variable) => {
  if (variable !== undefined) {
    if (
      !(
        typeof variable === 'object' &&
        variable !== null &&
        !Array.isArray(variable)
      )
    ) {
      variable = {}
    } else {
      const { name = '', value = '' } = variable
      variable = { name, value }
    }
  }

  return variable
}

const UIFormInputSearch = forwardRef((props, ref) => {
  let {
    value: propValue,
    initialValue: propInitialValue,
    defaultValue: propDefaultValue,
    onChange: propOnChange,
    onReset: propOnReset,
    onClick: propOnClick,
    query: propQuery,
    variables: propVariables = {},
    returnName: propReturnName = false,
    ...rest
  } = props

  propValue = checkObject(propValue)
  propInitialValue = checkObject(propInitialValue)
  propDefaultValue = checkObject(propDefaultValue)

  const isFirstRender = useRef(true)

  const context = useFormContext()

  const [errorTrigger, setErrorTrigger] = useTrigger()

  const [_input, setInputState] = useState(
    propValue !== undefined
      ? propValue
      : propInitialValue !== undefined
      ? propInitialValue
      : propDefaultValue !== undefined
      ? propDefaultValue
      : context.initialValues[props.name] !== undefined
      ? context.initialValues[props.name]
      : context.defaultValues[props.name] != undefined
      ? context.defaultValues[props.name]
      : { name: '', value: '' },
  )

  useEffect(() => {
    if (isFirstRender.current) isFirstRender.current = false
    else setValueState(propValue)
  }, [propValue])

  const defaultValue = useMemo(
    () =>
      propDefaultValue !== undefined
        ? propDefaultValue
        : context.defaultValues[props.name] !== undefined
        ? context.defaultValues[props.name]
        : { name: '', value: '' },
    [],
  )

  const [showSuggestion, setShowSuggestionState] = useState(false)
  const [text, setTextState] = useState(_input.name)

  // Start querying after 3 miliseconds
  useEffect(() => {
    const timer = setTimeout(() => {
      if (showSuggestion) setTextState(_input.name)
    }, 300)
    return () => {
      clearTimeout(timer)
    }
  }, [_input.name])

  const { data, loading, error } = useQuery(propQuery, {
    fetchPolicy: 'no-cache',
    variables: { text, ...propVariables },
    skip: !propQuery || text === '',
  })

  const handleChange = (event) => {
    // setInputState((prev) => ({ ...prev, name: event.target.value }))
    setInputState({ name: event.target.value })
    setShowSuggestionState(true)
    propOnChange && propOnChange(event)
  }

  const handleReset = () => {
    setInputState(defaultValue)
    setShowSuggestionState(false)
    setTextState(defaultValue.name)
    propOnReset && propOnReset()
  }

  const handleClick = (event, name, value) => {
    setInputState({ name, value })
    setShowSuggestionState(false)
    setErrorTrigger()
    propOnClick && propOnClick(propReturnName ? { id: value, name } : value)
  }

  const CustomInput = useCallback(
    forwardRef((props, ref) => {
      const { value, _input, data, loading, error, showSuggestion, ...rest } =
        props

      const suggestions = data && Object.values(data)[0].data

      return (
        <>
          <InputGroup size="sm">
            <InputGroup.Prepend>
              <span className="input-group-text">
                <FontAwesomeIcon
                  className="align-self-center"
                  size="xs"
                  icon={faSearch}
                />
              </span>
            </InputGroup.Prepend>
            <input ref={ref} value={_input.name} {...rest} />
          </InputGroup>
          {loading && <small className="muted">Cargando...</small>}
          {error && (
            <small className="muted">Error al cargar sugerencias</small>
          )}
          {showSuggestion && suggestions && (
            <ListGroup className="w-100 mt-1">
              {!suggestions.length && (
                <i>
                  <small className="muted">Sin resultados</small>
                </i>
              )}
              {suggestions.map(({ name, value }) => (
                <ListGroup.Item
                  key={value}
                  className="py-1 px-2"
                  action
                  variant="light"
                  onClick={(event) => handleClick(event, name, value)}
                >
                  {name}
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </>
      )
    }),
    [],
  )

  return (
    <Input
      {...rest}
      ref={ref}
      defaultValue={defaultValue}
      value={_input.value}
      _input={_input}
      customInput={CustomInput}
      onChange={handleChange}
      onReset={handleReset}
      className={'form-control form-control-sm'}
      data={data}
      loading={loading}
      error={error}
      showSuggestion={showSuggestion}
      triggerErrorCheck={errorTrigger}
    />
  )
})

export default UIFormInputSearch
