import { gql } from '../../../../../hooks'

export const READ_MODELS = gql`
  query AdministrationReadComponentModels($id: ID!) {
    aRead(path: "component/models", id: $id) {
    }
  }
`

export const UPDATE_MODELS = gql`
  mutation AdministrationUpdateComponentModels($id: ID!, $data: JSONObject) {
    aUpdate(path: "component/models", id: $id, data: $data) {
    }
  }
`

export const CREATE_MODELS = gql`
  mutation AdministrationCreateComponentModels($data: JSONObject) {
    aCreate(path: "component/models", data: $data) {
    }
  }
`

export const LIST_MODELS = gql`
  query AdministrationListComponentModels(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(
      path: "component/models"
      filter: $filter
      page: $page
      limit: $limit
    ) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`

export const LIST_BRANDS = gql`
  query AdministrationMiscComponentBrands {
    aComponentBrands {
    }
  }
`

export const SEARCH_BRAND = gql`
  query AdministrationSearchComponentBrand($text: String) {
    aSearch(path: "component_brand", text: $text) {
    }
  }
`
