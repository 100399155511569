import React from 'react'

// Components
import AdministrationListAnalysisLimits from '../../../../Administration/List/Analysis/Limits'
import AdministrationInfoAnalysisLimits from '../../../../Administration/Info/Analysis/Limits'
import AdministrationFormAnalysisLimits from '../../../../Administration/Form/Analysis/Limits'

import { AdministrationContainer } from '../..'

const AdministrationContainerAnalysisLimits = () => (
  <AdministrationContainer
    form={AdministrationFormAnalysisLimits}
    info={AdministrationInfoAnalysisLimits}
    list={AdministrationListAnalysisLimits}
  />
)

export default AdministrationContainerAnalysisLimits
