import React from 'react'
import { ResponsiveLine } from '@nivo/line'

// Hooks
import { useGlobal } from '../../../../hooks'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const UIGraphLine = ({ data, ...options }) => {
  const [{ me }] = useGlobal()

  options = {
    margin: { top: 10, right: 10, bottom: 70, left: 30 },
    xScale: { type: 'linear', min: 'auto', max: 'auto' },
    yScale: {
      type: 'linear',
      min: 'auto',
      max: 'auto',
      stacked: false,
      reverse: false,
    },
    curve: 'monotoneX',
    // axisTop: null,
    // axisRight: null,
    // axisBottom: {
    //   orient: 'bottom',
    //   tickSize: 5,
    //   tickPadding: 5,
    //   tickRotation: 0,
    //   //  // legend: 'transportation',
    //   //  legendOffset: 36,
    //   legendPosition: 'middle',
    //   tickValues: data
    //     .filter(({ id }) => id === 'data')[0]
    //     .data.map(({ continuity }) => `f${continuity.lubricant}`),
    // },
    // gridXValues: data
    //   .filter(({ id }) => id === 'data')[0]
    //   .data.map(({ continuity }) => `f${continuity.lubricant}`),
    axisLeft: {
      tickValues: 7,
      //  orient: 'left',
      //  tickSize: 5,
      //    tickPadding: 5,
      //  tickRotation: 0,
      //  // legend: 'count',
      //  legendOffset: -40,
      //  legendPosition: 'middle',
    },
    colors: (c) => c.color,
    lineWidth: 1,
    pointSize: 7,
    pointColor: { from: 'color', modifiers: [] },
    //    pointBorderColor: { from: 'serieColor', modifiers: [] },
    tooltip: ({ point }) => (
      <strong
        style={{
          color: point.serieColor,
          backgroundColor: 'rgba(250, 250, 250, 0.9)',
          border: `1px solid ${point.serieColor}`,
          borderRadius: '5px',
        }}
        className="px-2 py-1"
      >
        {point.data.y}
      </strong>
    ),
    crosshairType: 'cross',
    useMesh: true,
    legends: [],
    // enableGridX: true,
    // gridXValues: ['a', 'b', 'c'],
    ...options,
  }

  if (me.laboratory === 'centinela') {
    let horoumeter_map = data.filter(({ id }) => id === 'data')

    if (horoumeter_map.length) {
      horoumeter_map = horoumeter_map[0].data.reduce((p, c) => {
        const { x, continuity } = c
        return { ...p, [x]: continuity.lubricant }
      }, {})

      options.axisBottom = {
        format: (value) => horoumeter_map[value],
      }
    }
  }

  // point:
  // borderColor: "#FFFF00"
  // color: "#FFFF00"
  // data: {x: 16, y: 250, xFormatted: 16, yFormatted: 250}
  // id: "lms.1"
  // index: 15
  // serieColor: "#FFFF00"
  // serieId: "lms"
  // x: 429
  // y: 115
  return <ResponsiveLine data={data} {...options} />
}

export default UIGraphLine
