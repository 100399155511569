import React from 'react'
import { Tabs, Tab, Row, Col } from 'react-bootstrap'

// Hooks
import { useParams } from '../../../../../hooks'

// Components
import TribologySamplesInsertMassiveWeb from '../../../../../components/Tribology/Samples/Insert/Massive/Web'
import TribologySamplesInsertMassiveExcel from '../../../../../components/Tribology/Samples/Insert/Massive/Excel'
import TribologySamplesInsertMassiveReception from '../../../../../components/Tribology/Samples/Insert/Massive/Reception'
import TribologySamplesInsertMassivePrint from '../../../../../components/Tribology/Samples/Insert/Massive/Print'

const PageTribologySamplesInsertMassive = ({ auth }) => {
  const { tab } = useParams()

  const defaultKey = ['web', 'excel', 'reception', 'print'].includes(tab)
    ? tab
    : 'web'

  return (
    <Row>
      <Col>
        <Tabs
          defaultActiveKey={`tab-${defaultKey}`}
          className="font-weight-light"
        >
          <Tab eventKey="tab-web" title="Ingreso vía Web">
            <TribologySamplesInsertMassiveWeb />
          </Tab>
          <Tab eventKey="tab-excel" title="Ingreso vía Excel">
            <TribologySamplesInsertMassiveExcel />
          </Tab>
          <Tab eventKey="tab-reception" title="Recepción masiva pendientes">
            <TribologySamplesInsertMassiveReception />
          </Tab>
          <Tab eventKey="tab-print" title="Impresión masiva etiquetas">
            <TribologySamplesInsertMassivePrint />
          </Tab>
        </Tabs>
      </Col>
    </Row>
  )
}

export default PageTribologySamplesInsertMassive
