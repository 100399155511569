import { gql } from '../../../../hooks'

export const RESET_TABLEAU_TOKEN = gql`
  query TableauToken {
    tableauToken {
    }
  }
`

export const QUICKSIGHT_URL = gql`
  query QuicksightURL($id: ID!) {
    quicksightURL(id: $id) {
    }
  }
`
