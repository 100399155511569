import React from 'react'

// Components
import AdministrationListUserUsers from '../../../../Administration/List/User/Users'
import AdministrationInfoUserUsers from '../../../../Administration/Info/User/Users'
import AdministrationFormUserUsers from '../../../../Administration/Form/User/Users'

import { AdministrationContainer } from '../..'

const AdministrationContainerUserUsers = () => (
  <AdministrationContainer
    form={AdministrationFormUserUsers}
    info={AdministrationInfoUserUsers}
    list={AdministrationListUserUsers}
  />
)

export default AdministrationContainerUserUsers
