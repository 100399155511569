import React from 'react'

// Hooks
import { useState, useMutation, useTrigger } from '../../../../hooks'

// Components
import UIPanelInfo from '../../../UI/Panel/Info'
import UIPanelInfoSeparation from '../../../UI/Panel/Info/Separation'
import UIPanelInfoBlock from '../../../UI/Panel/Info/Block'
import UIButton from '../../../UI/Button'
import UIForm from '../../../UI/FormReload'
import UIFormInputPassword from '../../../UI/FormReload/Input/Password'

// Queries
import { CHANGE_PASSWORD } from './queries'

const ProfileForm = () => {
  const [password, setPasswordState] = useState()
  const [passwordVerify, setPasswordVerifyState] = useState()

  const [triggerReset, setTriggerReset] = useTrigger()

  const [resetPassword] = useMutation(CHANGE_PASSWORD, {
    onSuccessMessage: 'Contraseña actualizada con éxito',
    onErrorMessage: 'No se pudo actualizar la contraseña',
  })

  const handleSubmit = (values) => {
    resetPassword({
      variables: {
        password: values.current_password,
        new_password: values.new_password,
      },
    })
    setPasswordState()
    setPasswordVerifyState()
    setTriggerReset()
  }

  const handlePasswordChange = (event) => {
    setPasswordState(event.target.value)
  }

  const handlePasswordVerifyChange = (event) => {
    setPasswordVerifyState(event.target.value)
  }

  const validator = (value) => {
    if ([null, undefined, ''].includes(value)) return 'Valor requerido'

    if (/[^a-zA-Z0-9-!\?+,\.=\$]/.test(value))
      return 'Sólo valores alfanuméricos y símbolos ! ? - + , . = $'

    if (
      !/[a-z]/.test(value) ||
      !/[A-Z]/.test(value) ||
      !/[0-9]/.test(value) ||
      !/[-!\?+,\.=\$]/.test(value)
    )
      return 'Se requiere al menos una minúsculas, una mayúsculas, un número y un símbolo'

    if (String(value).length < 8 || String(value).length > 20)
      return 'El largo debe ser entre 8 y 20 caracteres'
  }

  return (
    <UIPanelInfo style={'danger'}>
      <UIPanelInfoBlock title="Cambio de contraseña">
        <UIForm onSubmit={handleSubmit} triggerReset={triggerReset}>
          <UIFormInputPassword
            label="Contraseña actual"
            name="current_password"
            autoComplete="off"
          />
          <UIFormInputPassword
            label="Nueva contraseña"
            onChange={handlePasswordChange}
            name="new_password"
            autoComplete="off"
            validator={validator}
          />
          <UIFormInputPassword
            label="Repetir nueva contraseña"
            onChange={handlePasswordVerifyChange}
            name="validate_password"
            autoComplete="off"
            text={
              password && passwordVerify && password !== passwordVerify
                ? 'Las contraseñas deben ser iguales'
                : null
            }
          />
          <UIPanelInfoSeparation />
          <UIPanelInfoBlock>
            <UIButton
              type="submit"
              variant="outline-danger"
              disabled={
                !password || !passwordVerify || password !== passwordVerify
              }
            >
              Cambiar contraseña
            </UIButton>
          </UIPanelInfoBlock>
        </UIForm>
      </UIPanelInfoBlock>
    </UIPanelInfo>
  )
}

export default ProfileForm
