import React from 'react'
import { Col, Row } from 'react-bootstrap'

import { useState } from '../../../../../hooks'

import TribologySamplesResultsMassiveForm from '../../../../../components/Tribology/Samples/Results/Massive/Form'
import TribologySamplesResultsMassiveInfo from '../../../../../components/Tribology/Samples/Results/Massive/Info'

const PageTribologySamplesResultsMassive = () => {
  const [file, setFileState] = useState()

  const handleChange = (file) => {
    setFileState(file)
  }

  return (
    <Row className="m-3">
      <Col lg={12}>
        <TribologySamplesResultsMassiveForm
          onChange={handleChange}
          file={file}
        />
        {file && <TribologySamplesResultsMassiveInfo file={file} />}
      </Col>
    </Row>
  )
}

export default PageTribologySamplesResultsMassive
