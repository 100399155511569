import React from 'react'
// import ReactDOMServer from 'react-dom/server'
import { Row, Col } from 'react-bootstrap'
// import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion } from '@fortawesome/free-solid-svg-icons'

// Hooks
import { useQuery } from '../../../../../hooks'

// Components
import UILoading from '../../../../UI/Loading'
import UIError from '../../../../UI/Error'

import Report from './report'

// Queries
import { GET_REPORT } from './queries'

const SamplePDF = ({ id }) => {
  // --------------------------------------------------------------------------
  //
  // Query: Get all the info
  //
  // --------------------------------------------------------------------------
  const { data, loading } = useQuery(GET_REPORT, {
    fetchPolicy: 'network-only',
    variables: { id },
    skip: !id,
  })

  if (data && data.t4Report && data.t4Report.data) {
    return (
      <Row>
        <Col xs={12}>
          <Report data={data.t4Report.data} />
        </Col>
      </Row>
    )
  }

  if (loading) return <UILoading />

  if (!id) return <UIError />

  return (
    <Row>
      <Col className="text-center align-self-center mt-5">
        <FontAwesomeIcon
          size="10x"
          icon={faQuestion}
          color="lightgray"
          className="mb-5"
        />
        <h1 className="text-secondary font-weight-light">
          No se encontró el informe
        </h1>
        <hr className="w-50" />
        <p className="text-black-50 font-weight-lighter">
          Lo sentimos, no se encontró ningún informe con el correlativo de
          muestra especificado
        </p>
      </Col>
    </Row>
  )
}

export default SamplePDF
