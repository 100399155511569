import { gql } from '../../../../../hooks'

export const READ_ANOMALY_TYPES = gql`
  query AdministrationReadAnalysisAnomalyTypes($id: ID!) {
    aRead(path: "analysis/anomaly/types", id: $id) {
    }
  }
`

export const UPDATE_ANOMALY_TYPES = gql`
  mutation AdministrationUpdateAnalysisAnomalyTypes(
    $id: ID!
    $data: JSONObject
  ) {
    aUpdate(path: "analysis/anomaly/types", id: $id, data: $data) {
    }
  }
`

export const CREATE_ANOMALY_TYPES = gql`
  mutation AdministrationCreateAnalysisAnomalyTypes($data: JSONObject) {
    aCreate(path: "analysis/anomaly/types", data: $data) {
    }
  }
`

export const LIST_ANOMALY_TYPES = gql`
  query AdministrationListAnalysisAnomalyTypes(
    $page: Int
    $limit: Int
    $filter: JSONObject
  ) {
    aList(
      path: "analysis/anomaly/types"
      filter: $filter
      page: $page
      limit: $limit
    ) {
      pagination {
        page
        limit
        number_of_pages
      }
    }
  }
`
