import React from 'react'

// Components
import UIFormInputText from '../../../../UI/FormReload/Input/Text'
import UIFormInputSearch from '../../../../UI/FormReload/Input/Search'
import UIFormInputSelect from '../../../../UI/FormReload/Input/Select'
import UIPanelInfoTable from '../../../../UI/Panel/Info/Table'

// Common
import { oe } from '../../../../../common/object/extractor'

// Hooks
import { useState } from '../../../../../hooks'

import { AdministrationForm } from '../..'

// Queries
import {
  READ_MACHINES,
  CREATE_MACHINES,
  UPDATE_MACHINES,
  LIST_MACHINES,
  SEARCH_BRAND,
  SEARCH_MODEL,
  SEARCH_MACHINE_TYPE,
  SEARCH_SITE,
} from './queries'

const queries = {
  read: READ_MACHINES,
  create: CREATE_MACHINES,
  update: UPDATE_MACHINES,
  list: LIST_MACHINES,
}

const CustomForm = ({ action, data, id }) => {
  const [idBrand, setIdBrandState] = useState(
    oe(data, ['brand', 'id'], undefined),
  )

  const handleBrandChange = (event) => {
    const value = event.target.value
    setIdBrandState(isNaN(value) ? undefined : parseInt(value))
  }

  return (
    <UIPanelInfoTable>
      {[
        action === 'update'
          ? [
              'ID',
              <UIFormInputText
                name="id"
                initialValue={id}
                placeholder={id}
                disabled
              />,
            ]
          : [],

        [
          'Descripción',
          <UIFormInputText
            name="name"
            initialValue={oe(data, ['name'])}
            placeholder={oe(data, ['name'])}
            required
          />,
        ],
        // [
        //   'ID Faena',
        //   <UIFormInputInteger
        //     inline
        //     name="id_site"
        //     initialValue={oe(data, ['site', 'id'])}
        //     placeholder={oe(data, ['site', 'id'])}
        //     required
        //   />,
        // ],
        [
          'Faena',
          <UIFormInputSelect
            name="id_site"
            query={SEARCH_SITE}
            idAs="value"
            initialValue={oe(data, ['site', 'id'])}
            placeholder={oe(data, ['site', 'id'])}
            required
          >
            <option value={0}>Seleccione una faena</option>
          </UIFormInputSelect>,
        ],
        // [
        //   'ID Tipo de equipo',
        //   <UIFormInputInteger
        //     inline
        //     name="id_machine_type"
        //     initialValue={oe(data, ['type', 'id'])}
        //     placeholder={oe(data, ['type', 'id'])}
        //     required
        //   />,
        // ],
        [
          'Tipo de equipo',
          <UIFormInputSearch
            name="id_machine_type"
            query={SEARCH_MACHINE_TYPE}
            initialValue={{
              value: oe(data, ['type', 'id']),
              name: oe(data, ['type', 'name']),
            }}
            required
          />,
        ],
        // [
        //   'ID Marca',
        //   <UIFormInputInteger
        //     inline
        //     name="id_machine_brand"
        //     initialValue={oe(data, ['brand', 'id'])}
        //     placeholder={oe(data, ['brand', 'id'])}
        //     required
        //   />,
        // ],
        [
          'Marca',
          <UIFormInputSelect
            name="id_machine_brand"
            query={SEARCH_BRAND}
            idAs="value"
            initialValue={oe(data, ['brand', 'id'])}
            required
            onClick={handleBrandChange}
          >
            <option value={0}>Seleccione una marca</option>
          </UIFormInputSelect>,
        ],
        // [
        //   'ID Modelo',
        //   <UIFormInputInteger
        //     inline
        //     name="id_machine_model"
        //     initialValue={oe(data, ['model', 'id'])}
        //     placeholder={oe(data, ['model', 'id'])}
        //     required
        //   />,
        // ],
        [
          'Modelo',
          <UIFormInputSelect
            name="id_machine_model"
            query={SEARCH_MODEL}
            idAs="value"
            initialValue={oe(data, ['model', 'id'])}
            required
            variables={{ id_brand: idBrand }}
            disabled={!idBrand}
          >
            <option value={0}>Seleccione un modelo</option>
          </UIFormInputSelect>,
        ],
      ]}
    </UIPanelInfoTable>
  )
}

const AdministrationFormMachineMachines = () => (
  <AdministrationForm name={'equipo'} queries={queries}>
    <CustomForm />
  </AdministrationForm>
)

export default AdministrationFormMachineMachines
